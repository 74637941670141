import {
    CREATE_CAR_REQUEST, CREATE_CAR_SUCCESS, CREATE_CAR_FAILURE } from "../../actions/car/car.actions";

const initialState = {
    message: '',
    car: {},
    isFetching: false,
    error: false,
};

export default function carReducer (state = initialState, action) {
    switch (action.type) {
        case CREATE_CAR_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
                error: false
            });
        }

        case CREATE_CAR_SUCCESS: {
            return Object.assign({}, state, {
                user: action.data,
                isFetching: false,
            });
        }
        case CREATE_CAR_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }
        default:
            return state
    }
}
