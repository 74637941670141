import React, {Component} from 'react';
import {connect} from 'react-redux';
import Table from "reactstrap/es/Table";
import PalletBalance from "./PalletBalance";
import Loader from "components/Loader/Loader";
import {translate} from 'services/translationService';
import {updatePalletBalance} from "redux/actions/shipments/palletBalance.actions";

class OrderAddressPalletBalance extends Component {

    constructor(props) {
        super(props);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
    }

    onSubmitHandler(formData) {
        this.props.dispatch(updatePalletBalance(this.props.orderId, formData));
    }

    render() {
        const balanceValue = this.props.palletBalance[this.props.name];
        const name = this.props.name;

        return ( <Table hover>
            <tbody>
            <tr title={translate("Current pallet balance. Negative value means that Janta Logistics owe pallets. Positive value means they owe pallets to Janta Logistics.")}>
                <th>{translate("Pallet balance")}</th>
                <td>
                    {this.props.isFetching ?
                        <Loader/> :
                        <PalletBalance
                            balanceValue={balanceValue}
                            name={name}
                            onSubmitHandler={this.onSubmitHandler}
                        ></PalletBalance>
                    }
                </td>
            </tr>
            </tbody>
        </Table>
        )
    }
}

OrderAddressPalletBalance.defaultProps = {
    palletBalance: {},
    isFetching: false,
    error: false
};

function mapStateToProps(state) {
    const palletBalance = state.palletBalanceReducer.palletBalance;
    return {
        palletBalance: Object.keys(palletBalance).length > 0 ? palletBalance : state.orderReducer.order.shipment.details,
        isFetching: state.palletBalanceReducer.isFetching,
        error: state.palletBalanceReducer.error
    };
}

export default connect(
    mapStateToProps
)(OrderAddressPalletBalance);