import React from "react";
import {Card, CardBody, Col, Row, Table} from "reactstrap";
import {translate} from 'services/translationService';
import './OrderListElement.scss';
import {stringHelper} from 'helpers/stringHelper';
import {Link} from "react-router-dom";
import Badge from "reactstrap/es/Badge";
import CustomInput from "reactstrap/es/CustomInput";
import { store } from 'helpers';
import loggedUserService from "../../../services/loggedUserService";

class OrderListElement extends React.Component {

    constructor(props) {
        super(props);
        this.getRelatedOrdersBadge = this.getRelatedOrdersBadge.bind(this);
    }

    getRelatedOrdersBadge(relatedOrderData) {
        const packageNumber = relatedOrderData.shipmentNumber;
        return <Link className="no-link" key={relatedOrderData.orderId} to={{
                pathname: `/orders/${relatedOrderData.orderId}`,
            }}>
            {(relatedOrderData.routePointShipmentStatus === 'CLOSED')?  <Badge color="secondary">{packageNumber}</Badge>
                : <Badge color="primary">{packageNumber}</Badge>
            }
            </Link>
    }

    render() {
        const {orderData, selectedOrders, toggleOrderSelect} = this.props;
        const id = orderData.id;
        const canceled = orderData.canceled;
        return (

                <div className="shipmentTable">
                    <Row>
                        <Col xs={12}>
                            <Card className={canceled?'canceled':''}>
                                <CardBody>
                                    <div className={"order-select"}>
                                        <CustomInput type="switch" id={`order-select-${id}`}
                                                     onChange={() => {toggleOrderSelect({orderId:id, orderName:orderData.shipment.number})}}
                                                     checked={id in selectedOrders}
                                        />
                                    </div>
                                    <Link className="no-link" to={{
                                        pathname: `/orders/${id}`,
                                        state: orderData
                                    }}>
                                    <Table bordered responsive>
                                        <thead>
                                        <tr>
                                            <th>{translate('Number')}</th>
                                            <th>{translate('Pickup date')}</th>
                                            <th>{translate('Packages no.')}</th>
                                            <th>{translate('Car number')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                {orderData.shipment.number}
                                                {canceled && <Badge color="warning">{ translate("Canceled")}</Badge>}
                                            </td>
                                            <td>{orderData.pickupDate}</td>
                                            <td>{orderData.shipment.packages.length}</td>
                                            <td>{orderData.carRegistrationNumber}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    <Table bordered striped={true} responsive>
                                        <tbody>
                                        <tr>
                                            <td className={'title'}>{translate('Description')}:</td>
                                            <td colSpan={3}>{orderData.shipment.description}</td>
                                        </tr>
                                        <tr>
                                            <td className={'title'}>{translate('Pickup Address')}:</td>
                                            <td colSpan={3}>{stringHelper.addressToString(orderData.pickupAddress)}</td>
                                        </tr>
                                        <tr>
                                            <td className={'title'}>{translate('Delivery Address')}:</td>
                                            <td colSpan={3}>{stringHelper.addressToString(orderData.deliveryAddress)}</td>
                                        </tr>
                                        <tr>
                                            <td className={'title'}>{translate('Driver')}:</td>
                                            <td>{stringHelper.personToString(orderData.carrierDriver)}</td>
                                            <td className={'title'}>{translate('Manager')}:</td>
                                            <td>{stringHelper.personToString(orderData.carrierManager)}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    </Link>
                                    {orderData.partOfRoute && <div className="related-orders">
                                        <span>{translate("See orders from the same route")}
                                            {orderData.relatedOrders.map(relatedOrder=>this.getRelatedOrdersBadge(relatedOrder))}
                                        </span>
                                    </div>}
                                    {(loggedUserService.hasShipmentsConversationsAccess()) &&
                                        <div className="d-flex justify-content-center pt-1">
                                            <shipment-conversation host={process.env.REACT_APP_SHIPMENTS_API_URL}
                                                                   token={localStorage.getItem('priceApiToken')}
                                                                   apptype="2"
                                                                   lang={store.getState().translationReducer.locale}
                                                                   shipment={orderData.shipment.id}
                                                                   shipmentnumber={orderData.shipment.number}
                                                                   userid={JSON.parse(localStorage.getItem('user')).id}></shipment-conversation>
                                        </div>}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>

        )
    }
}

export default OrderListElement;
