import React from "react";
import {translate} from "../../../services/translationService";

const RejectWarning = ({relatedOrders}) => {
    const warningText = translate("Rejecting this order you also reject other orders from this route:");
    let orderInfo = "";
    relatedOrders.forEach(relatedOrder => {
        orderInfo += relatedOrder.shipmentNumber;
        orderInfo += ' | ';
    });
    orderInfo = orderInfo.slice(0, -3);
    return (
        <div className={"w-100 alert-danger p-3 mb-2 text-center"}>
            <span>{warningText}</span><br/>
            <span>{orderInfo}</span>
        </div>
    )
};

export default RejectWarning;