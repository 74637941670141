import React from "react";
import {Badge, UncontrolledPopover, PopoverBody} from "reactstrap";
import {translate} from 'services/translationService';
import "./style.scss";

const ALL_STATUSES = {
    "new": {
        "id": "new",
        "name": "new",
        "description": "account created, waiting for user to confirm email"
    },
    "firstLogin": {
        "id": "firstLogin",
        "name": "pending",
        "description": "email confirmed, when all required documents will be uploaded admin will confirm account"
    },
    "pending": {
        "id": "pending",
        "name": "pending",
        "description": "email confirmed, when all required documents will be uploaded admin will confirm account"
    },
    "active": {
        "id": "active",
        "name": "active",
        "description": "user fully activated"
    }
};

const statusName = (status) => {
    return translate(ALL_STATUSES[status].name);
};

const getStatusDescription = (status) => {
    return translate(ALL_STATUSES[status].description);
};

const UserStatus = ({id, status}) => {
    return (
        <div className="userStatus">
            <Badge className={status}  id={`status${id}`} pill >
                { statusName(status) }
            </Badge>
            <UncontrolledPopover trigger="legacy" placement="bottom" target={`status${id}`}>
                <PopoverBody>
                    {getStatusDescription(status)}
                </PopoverBody>
            </UncontrolledPopover>
        </div>
)


};


export default UserStatus;
