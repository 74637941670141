import React from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import {translate} from 'services/translationService';
import DocumentDetail from './DocumentDetail';
import AddDocument from './AddDocument';
import {connect} from "react-redux";

class DocumentTypeCard extends React.Component {

    render() {
        const {documents, documentType} = this.props;
        const required = documentType.isRequired;
        return (
            <Card className="document-type-card" key={this.props.key}>
                <CardHeader className={required ? "required":null}>
                        <h5>{translate(documentType.name)}</h5>
                        <div className="document-type-info">
                            { (documentType.description) ? (
                                <p>{translate(documentType.description)}</p>
                                ) : ""
                            }
                            { (documentType.maxExpirationTime) ? (
                                <p>{translate("Max expiration time")}: {documentType.maxExpirationTime}</p>
                            ) : <></>
                            }
                        </div>
                </CardHeader>
                <CardBody>
                    {
                        documents.map( (document, i) => {
                            return <DocumentDetail document={document} key={i} userId={this.props.userId} />
                        })
                    }
                    <AddDocument documentType={documentType} userId={this.props.userId} />
                </CardBody>
            </Card>
        )
    }
}

export default connect()(DocumentTypeCard);
