import React, {Component, useEffect} from 'react';
import {HashRouter, Route, Switch} from "react-router-dom";
import {connect} from 'react-redux'

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css"

import {getTranslations} from 'redux/actions/translation.actions.js';
import DissmisableAlert from './components/DissmisableAlert/DissmisableAlert.jsx';

import {PrivateRoute} from './components';
import HomePage from "views/HomePage.jsx";
import Login from 'views/NotLogged/Login/Login.jsx';
import {getBillingPeriodsInfo, getCountriesInfo} from "./redux/actions/dictionary";
import {getConfig} from "./redux/actions/config";
import ForgotPassword from "./views/NotLogged/ForgotPassword/ForgotPassword";
import ResetPassword from "./views/NotLogged/ResetPassword/ResetPassword";
import Register from "./views/NotLogged/Register/Register";
import RegisterInfo from "./views/NotLogged/Register/RegisterInfo";
import ActivateAccount from "./views/NotLogged/ActivateAccount/ActivateAccount";
import DeliveryFile from "./views/DeliveryFile/DeliveryFile";
import InvoiceFile from "./views/Invoices/InvoiceFile";
import DocumentFile from "./components/Documents/DocumentFile";
import GDPRpage from "./views/Pages/GDPR/GDPRpage";
import DeletedAccount from "./views/NotLogged/DeletedAccount/DeletedAccount";
import loggedUserService from "./services/loggedUserService";

class App extends Component {
    constructor(props) {
        super(props);
        this.isShipmentConversationsWebComponentLoaded = false
    }

    setShipmentConversationWebComponentLoaded = (value) => {
        this.isShipmentConversationsWebComponentLoaded = value;
    }
    componentDidMount() {
        const {dispatch} = this.props;
        if ( localStorage.getItem("locale") != null && localStorage.getItem('locale') !== 'en' && !(this.props.translate.translations) ) {
            dispatch(getTranslations(localStorage.getItem('locale')));
        }
        dispatch(getCountriesInfo());
        dispatch(getConfig());
        dispatch(getBillingPeriodsInfo());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.isShipmentConversationsWebComponentLoaded &&  loggedUserService.isLogged() && loggedUserService.hasShipmentsConversationsAccess()) {
            const shipmentConversationScript = document.createElement('script');
            shipmentConversationScript.src = `${process.env.REACT_APP_WEBCOMPONENTS_URL}/shipmentConversation.umd.js`;
            shipmentConversationScript.async = true;
            document.body.appendChild(shipmentConversationScript);
            this.setShipmentConversationWebComponentLoaded(true);
        }
    }

    render() {
    return (
        (this.props.translate.translations || this.props.translate.locale === "en") ?
            <div className="App">

                <div className="container">
                        <DissmisableAlert />
                </div>
                <div>
                <HashRouter>
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/forgot-password" component={ForgotPassword} />
                        <Route path="/reset-password/:token" component={ResetPassword} />
                        <Route path="/confirm-account/:token" component={ActivateAccount} />
                        <Route path="/register" component={Register} />
                        <Route path="/register-info" component={RegisterInfo} />
                        <Route path="/gdpr" component={GDPRpage} />
                        <Route path="/deleted" component={DeletedAccount} />
                        <PrivateRoute path="/delivery/document/(\d+)" component={DeliveryFile} name="DeliveryFile" />
                        <PrivateRoute path="/carrier/document/(\d+)" component={DocumentFile} name="CarrierDocumentFile" documentType="carriers" />
                        <PrivateRoute path="/account/document/(\d+)" component={DocumentFile} name="AccountDocumentFile" documentType="accounts" />
                        <PrivateRoute path="/invoice/document/(\d+)" component={InvoiceFile} name="InvoiceFile"  />
                        <PrivateRoute path="/(.+)" component={HomePage} name="Dashboard" />
                        <PrivateRoute path="/" component={HomePage} name="start" />
                    </Switch>
                </HashRouter>
                </div>
            </div>
         : ""
    );
  }
}

const mapStateToProps = state => ({
    alert: state.alertReducer,
    translate: state.translationReducer,
});

export default connect(mapStateToProps)(App);

