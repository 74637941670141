import {
  DOCUMENTS_TYPES_FAILURE,
  DOCUMENTS_TYPES_REQUEST,
  DOCUMENTS_TYPES_SUCCESS
} from "../../actions";

const initialState = {
  documentsTypes: [],
  isFetching: true,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENTS_TYPES_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }

    case DOCUMENTS_TYPES_SUCCESS: {
      return Object.assign({}, state, {
        documentsTypes: action.data,
        isFetching: false,
      });
    }

    case DOCUMENTS_TYPES_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: true
      });
    }
    default:
      return state
  }
}
