import {translate} from 'services/translationService';

export const billingPeriodsForSelect = (billingPeriods) => {
    return billingPeriods.map(billingPeriod => {
        return {
            id: billingPeriod.days,
            name: billingPeriod.days + " " + translate('days') + " - " + billingPeriod.skonto
        }
    });
};
