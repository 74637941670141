import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {history} from "../../../helpers";
import {priceApiService} from "../../../services/priceApiService";
import {translate} from "../../../services/translationService";

export const FETCH_DEDICATED_ALIASES_REQUEST = 'FETCH_DEDICATED_ALIASES_REQUEST';
export const FETCH_DEDICATED_ALIASES_SUCCESS = 'FETCH_DEDICATED_ALIASES_SUCCESS';
export const FETCH_DEDICATED_ALIASES_FAILURE = 'FETCH_DEDICATED_ALIASES_FAILURE';
export const ADD_DEDICATED_ALIASES_REQUEST = 'ADD_DEDICATED_ALIASES_REQUEST';
export const ADD_DEDICATED_ALIASES_SUCCESS = 'ADD_DEDICATED_ALIASES_SUCCESS';
export const ADD_DEDICATED_ALIASES_FAILURE = 'ADD_DEDICATED_ALIASES_FAILURE';
export const DELETE_DEDICATED_ALIASES_REQUEST = 'DELETE_DEDICATED_ALIASES_REQUEST';
export const DELETE_DEDICATED_ALIASES_SUCCESS = 'DELETE_DEDICATED_ALIASES_SUCCESS';
export const DELETE_DEDICATED_ALIASES_FAILURE = 'DELETE_DEDICATED_ALIASES_FAILURE';

export function getDedicatedAliases(params) {
    return dispatch => {
        dispatch(request({}));
        const {countryFrom, countryTo} = params;
        const url = `aliases/${countryFrom}/${countryTo}`;
        priceApiService.getDedicated(url)
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                history.push('/dashboard');
            });
    };

    function request(data) { return { type: FETCH_DEDICATED_ALIASES_REQUEST, data } }
    function success(data) { return { type: FETCH_DEDICATED_ALIASES_SUCCESS, data } }
    function failure(error) { return { type: FETCH_DEDICATED_ALIASES_FAILURE, error } }
}

export function addDedicatedAliases(params) {
  return dispatch => {
    dispatch(request({}));
    const url = `aliases`;
    const {aliasCountryFrom, aliasCountryTo, countryFrom, countryTo} = params;
    const addAliasData = {
      "country_from": aliasCountryFrom,
      "country_to": aliasCountryTo,
      "alias_for_country_from": countryFrom,
      "alias_for_country_to": countryTo
    }
    priceApiService.updateDedicated(url, addAliasData)
      .then(
        response => {
          dispatch(success(response.data));
          dispatch(alertActions.success(translate("Alias successfully created")));
        })
      .catch(errorMessage => {
        dispatch(failure({}));
        dispatch(alertActions.error(translate("Failed to create alias")));
        history.push('/dashboard');
      });
  };

  function request(data) { return { type: ADD_DEDICATED_ALIASES_REQUEST, data } }
  function success(data) { return { type: ADD_DEDICATED_ALIASES_SUCCESS, data } }
  function failure(error) { return { type: ADD_DEDICATED_ALIASES_FAILURE, error } }
}

export function deleteDedicatedAlias(params) {
  return dispatch => {
    dispatch(request({}));
    const url = `aliases`;
    const {country_from, country_to} = params;
    const deleteAliasData = {
      country_from,
      country_to
    }
    priceApiService.deleteDedicated(url, deleteAliasData)
      .then(
        response => {
          dispatch(success(response.data));
          dispatch(alertActions.success("Alias successfully deleted"));
        })
      .catch(errorMessage => {
        dispatch(failure({}));
        dispatch(alertActions.error(translate("Failed to delete alias")));
        history.push('/dashboard');
      });
  };

  function request(data) { return { type: DELETE_DEDICATED_ALIASES_REQUEST, data } }
  function success(data) { return { type: DELETE_DEDICATED_ALIASES_SUCCESS, data } }
  function failure(error) { return { type: DELETE_DEDICATED_ALIASES_FAILURE, error } }
}