import React from "react";
import {translate} from 'services/translationService';
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import ModalFooter from "reactstrap/es/ModalFooter";
import {Field, Form, reduxForm} from "redux-form";
import FieldSelect from "../../components/FormFields/FieldSelect/FieldSelect";
import {remarkCategoriesForSelect} from "../../helpers/formHelper";
import FieldInput from "../../components/FormFields/FieldInput/FieldInput";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {multipleRemarksValidation} from "./multipleValidation";
import PreviewPhoto from "../../components/FormFields/AddPhoto/PreviewPhoto";
import AddPhoto from "../../components/FormFields/AddPhoto/AddPhoto";

class MultipleAddRemarkModal extends React.Component {

    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.preparePackages = this.preparePackages.bind(this);
        this.setFileMultiple = this.setFileMultiple.bind(this);
        this.clearFileMultiple = this.clearFileMultiple.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.skipChanges = this.skipChanges.bind(this);
        this.markBox = this.markBox.bind(this);
        this.state = {
            forceRerender:false,
            confirmModal:false
        }
    }

    componentDidMount() {
        if (this.props.remarkCategories.length > 0) {
            this.props.initialize({ shipment_remark_category_id:
                    remarkCategoriesForSelect(this.props.remarkCategories)[0].id.toString()
            });
        }
    }

    onFormSubmit(formData) {
        const {orderId, setRemarkData, orderData} = this.props;
        setRemarkData(formData, orderId);
    }

    preparePackages() {
        const {orderId, packagesData} = this.props;
        if (orderId){
            return packagesData.filter(singlePackage=>singlePackage.orderId === orderId)[0].packages;
        } else {
            return []
        }
    }

    setFileMultiple(file, index) {
        const {orderId, remarksData, setRemarkFiles} = this.props;
        let newFiles = remarksData[orderId].files;
        newFiles[index] = file;
        setRemarkFiles(newFiles, orderId)
        this.setState({forceRerender: !this.state.forceRender})
    }

    clearFileMultiple(index) {
        const {orderId, remarksData, setRemarkFiles} = this.props;
        let newFiles = remarksData[orderId].files;
        newFiles[index] = null;
        setRemarkFiles(newFiles, orderId)
        this.setState({forceRerender: !this.state.forceRender})
    }

    closeModal(){
        if (!this.props.pristine){
            this.setState({
                confirmModal: true
            })
        } else {
            this.props.clearModal()
        }
    }

    skipChanges(){
        this.setState({
            confirmModal: false
        })
        this.props.clearModal();
    }

    markBox(boxId){
        this.props.toggleBox(this.props.orderId, boxId);
        this.setState({forceRerender:!this.state.forceRerender})
    }


    render() {
        const {orderId, remarkCategories, invalid, remarksData} = this.props;
        const files = orderId ? remarksData[orderId].files : [];
        const packages = this.preparePackages();
        const shipmentName = remarksData && !!orderId ? remarksData[orderId].orderName : "";
        return (
            <div className="add-remark">
                <Modal isOpen={!!orderId} size="lg" scrollable={true} className={"add-remark-modal"}>
                    <ModalHeader toggle={this.closeModal}>
                        {translate("Shipment Remarks details:")}<i className="fa fa-search"></i>{" "}{shipmentName}
                    </ModalHeader>
                    <Form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Field
                                    name="shipment_remark_category_id"
                                    options={remarkCategoriesForSelect(remarkCategories)}
                                    label={translate("Remark category")}
                                    component={FieldSelect}
                                    props={{emptyValue: false}}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Field
                                    label={translate('Remark content')}
                                    component={FieldInput}
                                    type="textarea"
                                    name="description"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} className="form-group">
                                <div className="labelContainer">{translate("Selected parcels")}:</div>
                            </Col>
                            <Col md={9}>
                                <div className="order-boxes header-boxes">
                                    <div></div>
                                    <div>{translate("Package number")}</div>
                                    <div>{translate("Height")}</div>
                                    <div>{translate("Width")}</div>
                                    <div>{translate("Length")}</div>
                                    <div>{translate("Weight")}</div>
                                </div>
                                {packages.map((box, i) => <div
                                    className={remarksData[orderId].packages.includes(box.id) ? "order-boxes  selected" : "order-boxes"}
                                    key={i}
                                    onClick={()=>{this.markBox(box.id)}}
                                  >
                                      <div><span className={remarksData[orderId].packages.includes(box.id) ? "checked" : "unchecked"}/></div>
                                      <div>{box.number}</div>
                                      <div>{box.height}</div>
                                      <div>{box.width}</div>
                                      <div>{box.length}</div>
                                      <div>{box.weight}</div>
                                  </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className={"text-center"}>{translate("Add photos:")}</div>
                                <div className={"photos-container"}>
                                    {files.map((file, index)=>{
                                        return file ?  <PreviewPhoto file={file}  key={index}  clearFile={()=>this.clearFileMultiple(index)}/> :
                                            <AddPhoto key={index} index={index}  sefFile={this.setFileMultiple}/>
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" className="btn-round" disabled={invalid}>
                            {translate("Save and close")}
                        </Button>
                    </ModalFooter>
                    </Form>
            </Modal>
                <Modal isOpen={this.state.confirmModal} size="sm" scrollable={false} className={"confirm-modal"}>
                    <ModalBody>
                        {translate("Remarks were changed. Do you want to quit?")}
                    </ModalBody>
                        <ModalFooter>
                            <Button color="success" className="btn-round"
                                    onClick={this.skipChanges}>
                                {translate("Yes")}
                            </Button>
                            <Button color="warning" className="btn-round"
                                    onClick={()=>this.setState({confirmModal: false})}>
                                {translate("No")}
                            </Button>
                        </ModalFooter>
                </Modal>
            </div>
        )
    }
}

MultipleAddRemarkModal.defaultProps = {
    remarkCategories: [],
}


function mapStateToProps(state) {
    return {
        isFetching: state.shipmentRemarkReducer.isFetching,
        error: state.shipmentRemarkReducer.error,
        remarkCategories: state.remarkCategoryReducer.remarkCategories
    };
}

const decoratedComponent = connect(mapStateToProps)(MultipleAddRemarkModal);

export default reduxForm({
    form: 'multipleShipmentRemarksForm',
    enableReinitialize: true,
    validate: multipleRemarksValidation,
})(decoratedComponent);