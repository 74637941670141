import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {Form, Field, FormSection, reduxForm, change, initialize} from "redux-form";
import {translate} from "../../../services/translationService";
import FieldInput from "../../../components/FormFields/FieldInput/FieldInput";
import Address from "../../../components/FormSections/Address";
import Button from "reactstrap/es/Button";
import CardTitle from "reactstrap/es/CardTitle";
import FieldSelect from "../../../components/FormFields/FieldSelect/FieldSelect";
import {carrierValidationCreate} from "./validation";
import {Link} from "react-router-dom";
import {createCarrier} from "../../../redux/actions/carrier";
import WelcomeHeader from "../components/WelcomeHeader/WelcomeHeader";
import Footer from "../../../components/Footer/Footer";
import Loader from "components/Loader/Loader";
import {languagesForSelect} from "../../../helpers/languageSelect";
import {billingPeriodsForSelect} from "helpers/billingPeriods";
import FieldCheckbox from "../../../components/FormFields/FieldCheckbox/FieldCheckbox";
import {translateFormLabels} from "../../../helpers/translateFormLabels";
import "../style.scss"
import {loadReCaptcha, ReCaptcha} from 'react-recaptcha-v3'
import {isCordova} from "../../../helpers/cordova";
import MD5 from "crypto-js/md5";
import {history} from "../../../helpers";

class Register extends Component {

    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.state = {
            submitted: false,
            labels: translateFormLabels(),
            captchaToken: null
        };
        this.formData = null;
    }


    componentDidMount() {
        if (!isCordova()) {
            loadReCaptcha((process.env.REACT_APP_RECAPTCHA_KEY));
        }
    }

    componentWillMount() {
        this.props.initialize({carrier: {address: {country: 'DE'}}});
        this.props.initialize({account: {language: 'de'}});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (localStorage.getItem('registerFormValuesChanged')) {
            localStorage.removeItem('registerFormValuesChanged');
            const {dispatch} = this.props;
            const storedFormValues = localStorage.getItem('registerFormValues');
            if (storedFormValues) {
                const parsedFormValues = JSON.parse(storedFormValues);
                dispatch(initialize('registerForm', parsedFormValues));
                localStorage.removeItem('registerFormValues');
            }
        }
        if (prevProps.locale !== this.props.locale) {
            this.setState({labels: translateFormLabels()});
        }
    }

    onFormSubmit(formData) {

        this.setState({submitted: true});
        this.formData = formData;

        if (isCordova()) {
            this.verifyCallback(null);
        } else {
            this.updateToken();
        }
    }

    static checkIfLoggedIn(props) {
        if (props.loggedIn) {
            history.push('/dashboard');
        }
    }

    verifyCallback = (recaptchaToken) => {
        const {dispatch} = this.props;
        if (!isCordova()) {
            dispatch(change('registerForm', 'recaptcha', recaptchaToken));
        }
        if (this.state.submitted || isCordova()) {
            let formData = this.formData;

            if (isCordova()) {
                formData.recaptcha = MD5(formData.carrier.address.email + process.env.REACT_APP_RECAPTCHA_KEY).toString();
            } else {
                formData.recaptcha = recaptchaToken;
            }
            //default account role - required
            formData.account.roles = ['ROLE_MANAGER', 'ROLE_DEFAULT_MANAGER'];
            if (!formData.carrier.isVatPayer) {
                formData.carrier.isVatPayer = '1';
            }
            dispatch(createCarrier(formData));
        }
    }

    updateToken = () => {
        this.recaptcha.execute();
        //fires verifyCallback after execution
    }

    render() {
        const {labels, captchaToken} = this.state;
        const {isFetching} = this.props;
        Register.checkIfLoggedIn(this.props);
        const siteKey = process.env.REACT_APP_RECAPTCHA_KEY;
        return (
            <div className="Site-content">
                {!isCordova() && <ReCaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={siteKey}
                    action='submit'
                    verifyCallback={this.verifyCallback}
                />}
                <WelcomeHeader title={translate("Carrier registration")}/>
                <div className="container">
                    <div className="app flex-row align-items-center">
                        <Form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
                            <Card className="card-register">
                                <CardTitle>
                                    <div className={"separator"}><h5>{translate("Carrier data:")}</h5></div>
                                </CardTitle>
                                <CardBody>
                                    <FormSection name="carrier">
                                        <Row>
                                            <Col sm={6}>
                                                <Field
                                                    name="name"
                                                    type="text"
                                                    label={labels.carrierName}
                                                    component={FieldInput}
                                                />
                                            </Col>
                                        </Row>
                                        <FormSection name="address">
                                            <Row>
                                                <Col sm={6}>
                                                    <Field
                                                        name="phone"
                                                        type="text"
                                                        label={labels.carrierPhone}
                                                        component={FieldInput}
                                                    />
                                                </Col>
                                                <Col sm={6}>
                                                    <Field
                                                        name="email"
                                                        type="email"
                                                        label={labels.carrierEmail}
                                                        component={FieldInput}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormSection>

                                        <FormSection name="address">
                                            <Address formName={'registerForm.carrier'}/>
                                        </FormSection>
                                    </FormSection>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardTitle>
                                    <div className={"separator"}><h5>{translate("User's account data:")}</h5>
                                    </div>
                                </CardTitle>
                                <CardBody>
                                    <FormSection name="account">
                                        <Row>
                                            <Col sm={6}>
                                                <Field
                                                    name="name"
                                                    type="text"
                                                    label={labels.accountName}
                                                    component={FieldInput}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Field
                                                    name="surname"
                                                    label={labels.accountSurname}
                                                    component={FieldInput}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <Field
                                                    name="address.phone"
                                                    label={labels.accountPhone}
                                                    type="text"
                                                    component={FieldInput}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Field
                                                    name="email"
                                                    label={labels.accountEmail}
                                                    type="email"
                                                    component={FieldInput}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <Field
                                                    name="language"
                                                    options={languagesForSelect()}
                                                    label={labels.accountLanguage}
                                                    component={FieldSelect}
                                                    props={{emptyValue: false}}
                                                />
                                            </Col>
                                        </Row>
                                    </FormSection>

                                </CardBody>
                            </Card>
                            <Card>
                                <CardTitle>
                                    <div className={"separator"}><h5>{translate("Carrier banking data")}</h5>
                                    </div>
                                </CardTitle>
                                <CardBody>
                                    <FormSection name="carrier">
                                        <Row>
                                            <Col sm={6}>
                                                <Field
                                                    name="bankName"
                                                    label={labels.carrierBankName}
                                                    component={FieldInput}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Field
                                                    name="iban"
                                                    label={labels.carrierIban}
                                                    component={FieldInput}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <Field
                                                    name="taxNumberUe"
                                                    label={labels.carrierTaxNumberUe}
                                                    component={FieldInput}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Field
                                                    name="taxNumberDe"
                                                    label={labels.carrierTaxNumberDe}
                                                    component={FieldInput}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <Field
                                                    name="isVatPayer"
                                                    options={[
                                                        {id: 1, name: translate("Yes")},
                                                        {id: 0, name: translate("No")}
                                                    ]}
                                                    label={labels.carrierIsVatPayer}
                                                    component={FieldSelect}
                                                    props={{emptyValue: false}}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Field
                                                    name="billingPeriod"
                                                    options={billingPeriodsForSelect(this.props.billingPeriods)}
                                                    label={labels.carrierBillingPeriod}
                                                    component={FieldSelect}
                                                    props={{emptyValue: false}}
                                                />
                                            </Col>
                                        </Row>
                                    </FormSection>

                                    <Row>
                                        <Col sm={6}>
                                            <Field
                                                name="GTCchecked"
                                                component={FieldCheckbox}
                                                label={labels.GTCchecked}
                                            />
                                            {!isCordova() && <Field
                                                name="recaptcha"
                                                value={captchaToken}
                                                component={(props) => {
                                                    return (
                                                        <div>
                                                            <input type="hidden" {...props}/>
                                                        </div>
                                                    )
                                                }
                                                }
                                            />}
                                            <div className="row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-9 gtc-link">
                                                    <a href="https://www.janta-logistics.de/uploads/GTC.pdf" target="_blank" rel="noopener noreferrer">{translate("Show GTC")}</a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="update ml-auto mr-auto">
                                            <Button color="primary" className={"register-button"} round="true">
                                                {translate("Request registration")}
                                            </Button>
                                            {isFetching &&
                                                <Loader/>
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="update ml-auto mr-auto mt-2">
                                            <Link to={"/login"}><p>{translate('Back to login page')}</p></Link>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

Register.defaultProps = {
    isFetching: null,
    error: false,
    billingPeriods: [],
};

const mapStateToProps = state => ({
    isFetching: state.carrierReducer.isFetching,
    loggedIn: state.authReducer.loggedIn,
    billingPeriods: state.billingPeriodReducer.billingPeriods,
    locale: state.translationReducer.locale,
    form: state.form
});

const decoratedComponent = connect(mapStateToProps)(Register);

export default reduxForm({
    form: 'registerForm',
    validate: carrierValidationCreate
})(decoratedComponent);

