import React from "react";
import {translate} from "services/translationService";
import {Button, Input} from "reactstrap";
import {alertActions} from "../../../../redux/actions";
import {priceApiService} from "../../../../services/priceApiService";
import Loader from "../../../../components/Loader/Loader";
import {connect} from "react-redux";

class SaturdayOptionsForm extends React.Component {

    constructor(props) {
        super(props);
        let surchargesInputs = {};
        props.initialSurcharges.forEach(surcharge => surchargesInputs[surcharge.service_code] = surcharge.price)
        this.state = {
            surchargesInputs,
            isUploading: false
        }
        this.uploadSurcharges = this.uploadSurcharges.bind(this);
        this.updateWeightOptions = this.updateWeightOptions.bind(this);
    }

    uploadSurcharges() {
        let newInputValues = {...this.state.surchargesInputs};
        const {countryFrom, countryTo, dispatch} = this.props;
        let weightSurchargesData = [];
        for (let code in newInputValues){
            const price = Math.abs(parseFloat(newInputValues[code]));
                weightSurchargesData.push({
                    country_from: countryFrom,
                    country_to: countryTo,
                    service_code:parseInt(code),
                    price: price
                })
        }

        this.setState({
            isUploading: true
        });
        priceApiService.uploadSurchargesData('forSaturdayDelivery', weightSurchargesData).then(response => {
            dispatch(alertActions.success(translate("Surcharges for saturday options uploaded successfully")));
            this.setState({
                isUploading: false
            });
        }).catch((apiError) => {
            console.log(apiError);
            dispatch(alertActions.error(translate("Failed to upload surcharges, please retry")));
            this.setState({
                isUploading: false
            });
        })
    }

    updateWeightOptions(e) {
        const  inputIndex = e.target.name.split('-')[1];
        const inputValue = e.target.value;
        let newInputValues = {...this.state.surchargesInputs};
        newInputValues[inputIndex] = inputValue;
        this.setState({
            surchargesInputs: newInputValues
        })
    }


    render() {
        const {initialSurcharges} = this.props;
        const {surchargesInputs, isUploading} = this.state;
        return <div className="cargo-options-form">

            <div className="cargo-options-title surcharges-title">
                <div>{translate("Service")}</div>
                <div className="centered">{translate("Price")}</div>
            </div>
            {initialSurcharges.map((surcharge, key) => <div className="cargo-options-row saturday" key={key}>
                <div>{surcharge.service_name}</div>
                <div className={"option-input-group"}>
                    <Input
                        name={`saturday-${surcharge.service_code}`}
                        value={surchargesInputs[surcharge.service_code]}
                        onChange={this.updateWeightOptions}
                        type="number"
                        step="0.01"
                        min="0"
                    />
                    <span>€</span>
                </div>
            </div>)}
            <div className="button-container saturday">
                <Button color="primary" size={"sm"} onClick={this.uploadSurcharges}>
                    {isUploading ? <Loader /> : translate("Save")}
                </Button>
            </div>
        </div>
    }
}

SaturdayOptionsForm.defaultProps = {
    surcharges: [],
};

export default connect()(SaturdayOptionsForm);