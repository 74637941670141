import { apiService } from "services/apiService";
import { alertActions } from "redux/actions";
import getErrorText from "services/errorTextService";

export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';

export function getInvoices(params) {
    return dispatch => {
        dispatch(request({}));
        apiService.get('/api/credit-notes', params)
            .then(
                response => {
                    dispatch(success(response));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_INVOICES_REQUEST, data } }
    function success(data) { return { type: FETCH_INVOICES_SUCCESS, data } }
    function failure(error) { return { type: FETCH_INVOICES_FAILURE, error } }
}
