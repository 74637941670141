import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row} from "reactstrap";
import {history} from 'helpers';
import {translate} from 'services/translationService';
import SelectAccount from "../../../components/SelectAccount/SelectAccount.jsx";
import SelectCountry from "./SelectCountry.jsx";
import './searchPanel.scss';
import {paginationActions} from "../../../redux/actions/pagination.actions";
import {ORDER_PAGINATOR} from "../OrderListPaginated/OrderListPaginated";

class OrderSearchPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            advanced: false
        };
        this.toggleSearchPanel = this.toggleSearchPanel.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.clearForm = this.clearForm.bind(this);
    }

    toggleSearchPanel(e) {
        e.preventDefault();
        this.setState({
            advanced: !this.state.advanced
        });
    }

    onFormSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        let queryString = "?";
        let formObject = {};
        for (const [key, value] of formData.entries()) {
            if (value) {
                formObject[key] = value;
                queryString += key + "=" + value + "&";
            }
        }
        this.props.dispatch(paginationActions.resetPagination(ORDER_PAGINATOR));
        queryString  = queryString.substr(0, queryString.length-1);
        history.push("/orders/"+this.props.type  + queryString);
    }

    clearForm() {
        document.getElementById('searchForm').reset();
        this.props.dispatch(paginationActions.resetPagination(ORDER_PAGINATOR));
        history.push("/orders/"+this.props.type);
    }

    componentDidMount() {
        document.getElementById('searchForm').reset();
        this.props.dispatch(paginationActions.resetPagination(ORDER_PAGINATOR));
        const showAdvancedOptions = () => {
            return ( (Object.keys(this.props.searchParamsFromQuery).length === 1 && !this.props.searchParamsFromQuery.search) ||
                Object.keys(this.props.searchParamsFromQuery).length > 1
            );
        }
        if (showAdvancedOptions()) {
            this.setState({
                advanced: true
            });
        }
    }


    render() {
        const {advanced } = this.state;
        const searchParams = this.props.searchParamsFromQuery;

        return (
            <form onSubmit={this.onFormSubmit} id="searchForm">
                {(advanced) ?
                    <div className="search-panel advanced-search">
                        <Row>
                            <Col>
                                <span onClick={this.toggleSearchPanel}
                                      className="link">{translate('Show simple search')} </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{translate('Search in shipment or reference number')}</Label>
                                    <Input type="text" name="search" defaultValue={searchParams.search}/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{translate('Car registration number')} </Label>
                                    <Input type="text" name="carRegistrationNumber"
                                           defaultValue={searchParams.carRegistrationNumber}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{translate('Pickup date range')}</Label>
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            {translate('From')}
                                        </InputGroupText>
                                        <Input
                                            type="date"
                                            name="pickupDateFrom"
                                            defaultValue={searchParams.pickupDateFrom}
                                        />
                                        <InputGroupText>{translate('To')}</InputGroupText>
                                        <Input
                                            type="date"
                                            name="pickupDateTo"
                                            defaultValue={searchParams.pickupDateTo}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label>{translate('People assigment')}</Label>
                                    <InputGroup size="sm">
                                        <InputGroupText>{translate('Manager')}</InputGroupText>
                                        <SelectAccount userRole="ROLE_MANAGER" name="manager"
                                                       withEmptyValue={true}
                                                       defaultValue={searchParams.manager}/>
                                        <InputGroupText>{translate('Driver')}</InputGroupText>
                                        <SelectAccount userRole="ROLE_DRIVER" name="driver"
                                                       withEmptyValue={true}
                                                       defaultValue={searchParams.driver}/>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{translate('Pickup Address')} </Label>
                                    <InputGroup size="sm">
                                        <InputGroupText>{translate('Country')}</InputGroupText>
                                        <SelectCountry name="countryCodeFrom"
                                                       defaultValue={searchParams.countryCodeFrom}/>
                                        <InputGroupText>{translate('City, post code or street name')}</InputGroupText>
                                        <Input type="text" name="pickupAddress"
                                               placeholder={translate('City, post code or street name')}
                                               defaultValue={searchParams.pickupAddress}/>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{translate('Delivery Address')} </Label>
                                    <InputGroup size="sm">
                                        <InputGroupText>{translate('Country')}</InputGroupText>
                                        <SelectCountry name="countryCodeTo" defaultValue={searchParams.countryCodeTo}/>

                                        <InputGroupText>{translate('City, post code or street name')}</InputGroupText>
                                        <Input type="text" name="deliveryAddress"
                                               placeholder={translate('City, post code or street name')}
                                               defaultValue={searchParams.deliveryAddress}/>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row>
                            <Col className="text-right">
                                <span onClick={this.clearForm} className="link mr-2">{translate('Reset search data')}</span>
                                <Button type="submit">{translate('Search')} <i
                                    className="nc-icon nc-zoom-split"/></Button>
                            </Col>
                        </Row>
                    </div>
                    :
                    <div className="search-panel simple-search">
                        <Row>
                            <Col>
                                <span onClick={this.toggleSearchPanel}
                                      className="link">{translate('Show advanced search')} </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <InputGroup>
                                    <Input type="text" name="search" defaultValue={searchParams.search}
                                           placeholder={translate('Search in shipment or reference number')}/>
                                    <Button type="submit">{translate('Search')} <i
                                        className="nc-icon nc-zoom-split"/></Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                }
            </form>
        );
    }
}

OrderSearchPanel.defaultProps = {
    pageParams: {}
};

const mapStateToProps = state => ({
    pageParams: state.paginationReducer.pageParams,
});

export default connect(mapStateToProps)(OrderSearchPanel);
