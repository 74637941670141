import React from "react";
import {Button} from "reactstrap";
import {translate} from 'services/translationService';

const InvoiceSingleRow = ({invoice, key}) => {

    return (
        <tr id={invoice.id} >
            <td>{ invoice.number }</td>
            <td>{ invoice.createdAt }</td>
            <td>{ invoice.price } / { invoice.price + invoice.taxValue } &euro;	</td>
            <td>{ invoice.status.toLowerCase() }</td>
            <td>
                <Button
                    color="primary"
                    size="sm"
                    onClick={ () => window.open(`#/invoice/document/${invoice.id}`) }>
                    {translate('get PDF')}
                </Button>
            </td>
        </tr>
    )
};

export default InvoiceSingleRow;
