import React from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import {translate} from 'services/translationService';
import {Badge, Modal, ModalBody, ModalHeader, Table} from "reactstrap";
import BodyTypeBadge from "views/Cars/BodyTypeBadge";
import {apiService} from "../../services/apiService";
import Loader from "../../components/Loader/Loader";
import {getDriverNamesForCar} from "../../helpers/driverHelper";
import MediaQuery from "react-responsive";

class ConnectCarToOrderModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            cars:[]
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        apiService.get('/api/vehicles/active')
            .then(
                response => {
                    this.setState({
                        cars: response.data,
                        isFetching: false,
                    });
                })
            .catch(errorMessage => {
                this.setState({
                    cars: [],
                    isFetching: false,
                });
                console.log(errorMessage);
            });
    }

    toggle() {
        this.props.toggleModal();
    }

    renderCarRow(car, i) {
        return(
            <tr key={i}>
                <td>{car.brand.name} {car.model.name} </td>
                <td>{car.registrationNumber}</td>
                <MediaQuery query='(min-device-width: 860px)'>
                    <td>{car.container ? <BodyTypeBadge specification={car.container}/> :
                        <Badge color="info" pill>{translate("Truck with trailer")}</Badge>}</td>
                    <td>{car.drivers.length > 0 ?
                        getDriverNamesForCar(car):""}
                        </td>
                </MediaQuery>
                <td>
                    <Button onClick={()=>{this.props.assignToOrder({
                        vehicleId:car.id
                    })}}>
                        {translate("Connect")}
                    </Button>
                </td>
            </tr>
        )
    }

    render() {
        const {cars, isFetching} = this.state;
        const {order, order:{partOfRoute}} = this.props;
        if (isFetching) {
            return <Loader/>
        }
        return (
            <div className="cars-list">
                <Modal isOpen={this.props.showModal} toggle={this.toggle} size="lg" scrollable={true}>
                    <ModalHeader toggle={this.toggle}>{translate("Assign a car to order: ")}
                    </ModalHeader>
                    <ModalBody>
                        {partOfRoute && <div className="multiple-orders-warning">
                          <p>{translate("Warning! Selected car will be assigned to all orders in current route:")}</p>
                          <div className="related-orders-list">
                            {order.relatedOrders.map((relatedOrder, key)=><Badge color="primary" key={key}>{relatedOrder.shipmentNumber}</Badge>)}
                          </div>
                        </div> }
                        { (cars.length) ?
                        <Table className="car-list-table">
                            <thead>
                            <tr>
                                <th>{translate('Brand / Model')}</th>
                                <th>{translate('Registration')}</th>
                                <MediaQuery query='(min-device-width: 860px)'>
                                    <th>{translate('Body type')}</th>
                                    <th>{translate('Drivers')}</th>
                                </MediaQuery>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                cars.map((car, i) => {
                                    return this.renderCarRow(car, i);
                                })
                            }
                            </tbody>
                        </Table>
                            : <p>{translate("There are no available cars right now")}</p> }
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}


export default ConnectCarToOrderModal
