import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authActions } from 'redux/actions';
import { translate } from 'services/translationService';
import Loader from "components/Loader/Loader";
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';
import {Link} from "react-router-dom";
import WelcomeHeader from "../components/WelcomeHeader/WelcomeHeader";
import {Logo} from "../components/Logo/Logo";
import Footer from "../../../components/Footer/Footer";
import {history} from "../../../helpers";

class ForgotPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      submitted: false,
      fetching: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email } = this.state;
    const { dispatch } = this.props;
    if (email) {
      const emailObject = {email: email};
      dispatch(authActions.forgotPassword(emailObject));
    }
  }

  isValidEmail() {
    return this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) != null;
  }

  static checkIfLoggedIn(props) {
    if (props.loggedIn) {
      history.push('/dashboard');
    }
  }

  render() {
    const { fetching } = this.props;
    const { email, submitted } = this.state;
    ForgotPassword.checkIfLoggedIn(this.props);

    return (
    <div className="Site-content">
    <WelcomeHeader title={translate("Password reset")}/>
    <div className="jumbotron">
      <div className="flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h5 style={{color: '#9A9A9A'}}>{translate('Please provide your valid e-mail address:')}</h5>
                    <form name="form" onSubmit={this.handleSubmit}>
                      <div className={'form-group' + (submitted && !this.isValidEmail() ? ' has-error' : '')}>
                        <label htmlFor="email" className="control-label">{ translate("User's email") } </label>
                        <input type="text" className="form-control" name="email" value={email} onChange={this.handleChange} />
                      </div>
                      <div className="form-group">
                        <button className="btn btn-primary" disabled={!(email && this.isValidEmail())}> { translate('Reset password') } </button>
                        {fetching &&
                            <Loader />
                        }
                        <Link to={"/login"}><p className="text-right" >{translate('Back to login page')}</p></Link>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
        <Logo />
      </div>
    </div>
    <Footer />
    </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.authReducer.loggedIn,
  fetching: state.authReducer.fetching,
  resetPasswordRequested: state.authReducer.resetPasswordRequested
});

export default connect(mapStateToProps)(ForgotPassword);
