import React from "react";
import {connect} from "react-redux";
import {Col, Label, Row, Table} from "reactstrap";
import {translate} from 'services/translationService';
import Loader from "../../../../components/Loader/Loader";
import {getVehicle} from "../../../../redux/actions/car";
import VehicleLocation from "../CarLocation/VehicleLocation";
import mapService from "../../../../services/mapService";

class MyCarSoloTruckDetails extends React.Component {

    componentDidMount() {
        const {car, dispatch} = this.props;
        dispatch(getVehicle(car.id));
    }

    render() {
        const {soloTruck, isFetching} = this.props;
        if (isFetching) {
            return (
                <div className="content">
                    <Loader/>
                </div>
            )
        }
        const showLocation = mapService.showCarPosition(soloTruck);
        return <div className="content">
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={6}>
                            <h5><i className="nc-icon nc-alert-circle-i"/> {translate('Solo Truck')}: </h5>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Table size="sm" borderless>
                                <tbody>
                                <tr>
                                    <th>
                                        <label>{translate('Model / Brand')}: </label>
                                    </th>
                                    <td>
                                        {soloTruck.brand.name} {soloTruck.model.name}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <label>{translate('Register number')}: </label>
                                    </th>
                                    <td>
                                        {soloTruck.registrationNumber}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <Label>{translate("Production Year")}</Label>
                                    </th>
                                    <td>
                                        {soloTruck.productionYear}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                {showLocation && <Col md={12}>
                    <VehicleLocation carData={soloTruck}/>
                </Col>}
            </Row>
        </div>
    }
}

MyCarSoloTruckDetails.defaultProps = {
    isFetching: true,
    soloTruck: {},
};

const mapStateToProps = (state) => {
    return {
        soloTruck: state.vehicleReducer.vehicle,
        isFetching: state.vehicleReducer.isFetching,
    }
};

export default connect(mapStateToProps)(MyCarSoloTruckDetails);


