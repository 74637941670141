//  STATUS_DEFAULT = 0;
//  STATUS_CANCELED = 2; // 1
//  STATUS_DHL_MUST_BE_CANCELED_MANUALLY = 4; // 2 - canceled but can't be canceled automatically by API in UPS/DPD
//  STATUS_PICKUP = 8;
//  STATUS_DELIVERED = 16; // 3
//  STATUS_PAYED = 32; // 4
//  STATUS_CONFIRMED = 64;
//  STATUS_DELETED = 128;
//  STATUS_DPD_UNAVAILABLE = 256;
//  STATUS_DPD_CAN_ORDER = 512;
//  STATUS_DPD_ORDERED = 1024;
//  STATUS_CLOSED_FOR_CARRIER = 8192;

const orderService = {
    isNextOrderSet: (relatedOrders) => {
        if (relatedOrders === null || relatedOrders.length === 0) {
            return false
        }
        let isSet = false;
        relatedOrders.forEach(relatedOrder => {
            if (relatedOrder.isNext) {
                isSet = true;
            }
        })
        return isSet;
    },

    getNextOrderId: (relatedOrders) => {
        if (relatedOrders === null || relatedOrders.length === 0) {
            return null
        }
        let nextOrderId = null;
        relatedOrders.forEach(relatedOrder => {
            if (relatedOrder.isNext) {
                nextOrderId = relatedOrder.orderId || null;
            }
        })
        return nextOrderId;
    },

    getNextOrderNumber: (relatedOrders) => {
        if (relatedOrders === null || relatedOrders.length === 0) {
            return null
        }
        let nextOrderNumber = "";
        relatedOrders.forEach(relatedOrder => {
            if (relatedOrder.isNext) {
                nextOrderNumber = relatedOrder.shipmentNumber;
            }
        })
        return nextOrderNumber;
    },
    mapPurposeCodeToStatusId: (purposeCode) => {
        switch (purposeCode){
            case 1:
                return '4';
            case 2:
                return '2';
            case 3:
                return '20';
            default:
                return '2';
        }
    },
    getDocumentClass: (document) => {
        {
            let docClass = "delivery-document"
            switch (document.document_type){
                case 2:
                    docClass+= " delivery-confirmation";break;
                case 3:
                    docClass+= " pickup-confirmation";break;
                case 4:
                default:
                    docClass+= " warehouse-confirmation"
            }
            return docClass
        }
    }
};

export default orderService;
