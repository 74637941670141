import React from "react";
import {stringHelper} from 'helpers/stringHelper';
import RoleBadge from "../RoleBadge";
import UserStatus from "../UserStatus/UserStatus";
import MediaQuery from 'react-responsive';
import {Link} from "react-router-dom";
import loggedUserService from "../../../services/loggedUserService";
import {Button} from "reactstrap";
import {translate} from "../../../services/translationService";
import CustomInput from "reactstrap/es/CustomInput";
import classnames from "classnames";
import {updateDefaultAdmin} from "../../../redux/actions";


const UserSingleRow = ({account, toggleDeleteModal, assignCar, dispatch, pageParams}) => {

    const getCarInfo = () => {
        const vehicleSet = account.vehicleSet;
        return vehicleSet.truckTrailer ?
            <span>{vehicleSet.car.modelName} {vehicleSet.car.brandName} {vehicleSet.car.registrationNumber} / {vehicleSet.truckTrailer.registrationNumber}</span> :
            <span>{vehicleSet.car.modelName} {vehicleSet.car.brandName} {vehicleSet.car.registrationNumber}</span>
    };

      const setDefaultAdmin = (accountId, pageParams) => {
        dispatch(updateDefaultAdmin(accountId));
      }

    const disableDelete = account.id === loggedUserService.getUserId() || account.hasOwnProperty('vehicleSet');
    const isAdmin = account.hasOwnProperty('isDefaultAdmin') && account.status === 'active';
    return (
        <tr id={account.id} className={classnames({
          'user-row':account.status,
          'user-row-disabled':!account.status,
          'default-admin':account.isDefaultAdmin
        })}>
            <td>{stringHelper.personToString(account)}</td>
            <MediaQuery query='(min-device-width: 860px)'>
                <td><RoleBadge roles={account.roles}/></td>
              {isAdmin ?
                <td><CustomInput type="switch" id={`admin-switch-${account.id}`}
                   checked={account.isDefaultAdmin}
                   onChange={() => {setDefaultAdmin(account.id, pageParams)}}
                   disabled={account.isDefaultAdmin}
                /></td> : <td></td>}
            </MediaQuery>
            <td><a href={`tel:${account.address.phone}`}>{account.address.phone}</a></td>
            <MediaQuery query='(min-device-width: 860px)'>
                <td>{account.vehicleSet ? getCarInfo() :
                    <Button className="btn btn-success btn-sm" onClick={()=>assignCar(account)}>
                        {translate("Add car")}
                    </Button>}
                </td>
                <td><a href={`mailto:${account.email}`}> {account.email} </a></td>
                <td><UserStatus status={account.status} id={account.id}/></td>
            </MediaQuery>
            <td>
                <Link to={`/user/${account.id}`}>
                    <i className="nc-icon nc-zoom-split"/>
                </Link>{"    "}
                {disableDelete ? "" : <Link to="#">
                    <i onClick={() => {
                        toggleDeleteModal(account)
                    }} className="nc-icon nc-simple-remove delete-icon"/>
                </Link>}
            </td>
        </tr>
    )
};

export default UserSingleRow;
