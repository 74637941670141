import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import {translate} from 'services/translationService';

import './style.scss';

const FieldSelect = ({
                         input,
                         inputId,
                         label,
                         meta: { touched, error, warning },
                         labelClassName,
                         inputClassName,
                         className,
                         options,
                         disabled,
                         emptyValue,
                         isOptional,
                         selectedValue,
                         value,
                         placeholder,
                     }) => (
    <FormGroup className={`form-select ${className}`}>
        {
            label &&
            <div className={`${labelClassName} labelContainer`}>
                <Label htmlFor={inputId}>{label}</Label>
            </div>
        }
        <div className={inputClassName}>
            <Input
                {...input}
                placeholder={placeholder || label}
                type="select"
                disabled={disabled}
                className={touched && (error || warning) ? 'input-invalid' : ''}
                {...(selectedValue && { value: selectedValue })}
                {...(value && { value })}
            >
                {emptyValue && <option />}
                {isOptional && <option value={0}>{translate("Select if needed")}</option>}
                {
                    options.map(option => (
                        <option
                            key={option.id}
                            value={option.id}
                            disabled={option.disabled || false}
                        >
                            {option.name ? option.name : option.label}
                        </option>
                    ))
                }
            </Input>
            {
                touched && (
                    (error && <span className="error invalid-feedback ml-2">{translate(error)}</span>) ||
                    (warning && <span className="warning invalid-feedback ml-2">{translate(warning)}</span>)
                )
            }
        </div>
    </FormGroup>
);

FieldSelect.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    inputId: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    disabled: PropTypes.bool,
    emptyValue: PropTypes.bool,
    isOptional: PropTypes.bool,
    selectedValue: PropTypes.any,
    value: PropTypes.any,
};

FieldSelect.defaultProps = {
    placeholder: '',
    inputId: '',
    label: '',
    className: 'row',
    labelClassName: 'col-md-3',
    inputClassName: 'col',
    disabled: false,
    emptyValue: true,
    isOptional: false,
    selectedValue: null,
    value: null,
};

export default FieldSelect;
