import React from "react";

const PrivatePolicyPl = () => {

    return (
        <div>
            <h2>Polityka prywatności</h2>
            <h3>1. Ochrona danych w skrócie</h3>
            <h4>Informacje ogólne</h4>
            <p>
                Poniższe informacje przedstawiają ogólny zarys tego, co dzieje się z Twoimi
                danymi osobowymi podczas odwiedzania tej strony internetowej. Dane osobowe
                to wszelkie informacje, za pomocą których można Cię osobiście
                zidentyfikować. Szczegółowe informacje na temat ochrony danych znajdują się
                w niniejszej Polityce prywatności.
            </p>

            <h4>Gromadzenie danych na tej stronie internetowej</h4>
            <h4> Kto jest odpowiedzialny za gromadzenie danych na tej stronie?</h4>
            <p>
                Przetwarzaniem danych na tej stronie internetowej zajmuje się administrator
                strony. Jego dane kontaktowe można znaleźć w Impressum na tej stronie.
            </p>

            <h4>Jak gromadzimy Twoje dane?</h4>
            <p>
                Z jednej strony Twoje dane są zbierane w momencie ich przekazania do nas.
                Mogą to być na przykład dane wprowadzone w formularzu kontaktowym. Inne
                dane gromadzone są automatycznie podczas odwiedzania tej strony za
                pośrednictwem naszych systemów informatycznych. Są to przede wszystkim dane
                techniczne (np. przeglądarka internetowa, system operacyjny lub data
                wyświetlenia strony). Dane te są zbierane automatycznie po wejściu na naszą
                stronę internetową.
            </p>

            <h4>
                Do czego wykorzystujemy Twoje dane?
            </h4>
            <p>
                Część danych jest gromadzona w celu zapewnienia bezbłędnego działania
                strony internetowej. Inne dane mogą zostać wykorzystane do analizy
                zachowania użytkownika.
            </p>

            <h4>Jakie masz prawa dotyczące swoich danych?</h4>
            <p>
                W każdej chwili masz prawo do uzyskania bezpłatnych informacji o
                pochodzeniu, odbiorcy i celu przechowywanych danych osobowych. Masz również
                prawo do żądania sprostowania lub usunięcia tych danych. W tym celu, a
                także w przypadku dalszych pytań dotyczących ochrony danych osobowych,
                możesz w każdej chwili skontaktować się z nami pod adresem podanym w
                Impressum.
            </p>
            <p>
                Ponadto przysługuje Ci prawo do zażalenia we właściwym organie nadzorczym.
            </p>

            <h3>2. Hosting</h3>
            <h4>Hosting zewnętrzny</h4>
            <p>
                Ta strona jest hostowana przez zewnętrznego usługodawcę (dostawcę usług
                hostingowych). Dane osobowe gromadzone na tej stronie są przechowywane na
                serwerach dostawcy usług hostingowych. Mogą to być przede wszystkim adresy
                IP, zapytania o kontakt, metadane i dane komunikacyjne, dane z umowy, dane
                kontaktowe, nazwy, dostęp do stron internetowych i inne dane generowane za
                pośrednictwem strony internetowej.
            </p>
            <p>
                Korzystanie z usług hostingowych odbywa się w celu wykonania umowy z
                naszymi potencjalnymi i obecnymi klientami (art. 6 ust. 1 lit. b RODO) oraz
                w interesie bezpiecznego, szybkiego i skutecznego świadczenia naszej oferty
                online przez profesjonalnego dostawcę (art. 6 ust. 1 lit. f RODO).
            </p>
            <p>
                Nasz dostawca usług hostingowych będzie przetwarzał Twoje dane tylko w
                zakresie niezbędnym do wypełnienia obowiązków związanych ze świadczeniem
                usług i przestrzegał naszych instrukcji w odniesieniu do takich danych.
            </p>

            <h3>3. Informacje ogólne i informacje obowiązkowe</h3>
            <h4>Ochrona danych</h4>
            <p>
                Administratorzy tych stron bardzo poważnie podchodzą do ochrony Twoich
                danych osobowych. Twoje dane osobowe traktujemy poufnie i zgodnie z
                ustawowymi przepisami o ochronie danych i niniejszą Polityką prywatności.
            </p>
            <p>
                Podczas korzystania z tej strony internetowej gromadzone będą różne Twoje
                dane osobowe. Dane osobowe to wszelkie informacje, za pomocą których można
                Cię osobiście zidentyfikować. Niniejsza Polityka prywatności wyjaśnia,
                jakie dane zbieramy i do czego je wykorzystujemy. Wyjaśnia również, w jaki
                sposób i w jakim celu to się dzieje.
            </p>
            <p>
                Należy pamiętać, że przekazywanie danych w internecie (np. podczas
                komunikacji mailowej) może mieć luki w zabezpieczeniach. Pełna ochrona
                danych przed dostępem osób trzecich jest niemożliwa.
            </p>

            <h4>Informacja na temat podmiotu odpowiedzialnego</h4>
            <p>
                Podmiotem odpowiedzialnym za przetwarzanie danych na tej stronie jest:
            </p>
            <address>
                Janta GmbH
                Im Riebeisen 31
                71404 Korb
                Telefon: +49 (0)7151 - 135 16 90
                E-Mail: info@janta-logistics.de
            </address>
            <p>
                Podmiot odpowiedzialny to osoba fizyczna lub prawna, która samodzielnie lub
                w porozumieniu z innymi decyduje o celach i sposobach przetwarzania danych
                osobowych (np. imiona i nazwiska, adresy mailowe, itp.).
            </p>

            <h4>Cofnięcie zgody na przetwarzanie danych</h4>
            <p>
                Wiele procesów przetwarzania danych jest możliwych tylko za Twoją wyraźną
                zgodą. Udzieloną zgodę możesz odwołać w każdej chwili. W tym celu wystarczy
                wysłać nam nieformalne powiadomienie mailem. Zgodność z prawem
                przetwarzania danych prowadzonego do momentu odwołania pozostaje
                nienaruszona odwołaniem.
            </p>

            <h4> Prawo do sprzeciwu wobec gromadzenia danych w szczególnych przypadkach
                oraz reklamy bezpośredniej (art. 21 RODO)</h4>
            <p>
                JEŚLI PRZETWARZANIE DANYCH OPIERA SIĘ NA ART. 6 UST. 1 lLIT. E LUB F RODO,
                MASZ PRAWO W KAŻDEJ CHWILI DO ZŁOŻENIA SPRZECIWU WOBEC GROMADZENIA SWOICH
                DANYCH OSOBOWYCH Z PRZYCZYN WYNIKAJĄCYCH Z TWOJEJ SZCZEGÓLNEJ SYTUACJI;
                DOTYCZY TO RÓWNIEŻ OPIERAJĄCEGO SIĘ NA NINIEJSZYCH PRZEPISACH PROFILOWANIA.
                WŁAŚCIWA PODSTAWA PRAWNA, NA KTÓREJ OPIERA SIĘ PRZETWARZANIE, ZNAJDUJĘ SIĘ
                W NINIEJSZEJ POLITYCE PRYWATNOŚCI. W PRZYPADKU ZGŁOSZENIA SPRZECIWU NIE
                BĘDZIEMY JUŻ PRZETWARZAĆ TWOICH DANYCH OSOBOWYCH, KTÓRYCH TO DOTYCZY, CHYBA
                ŻE MOŻEMY UDOWODNIĆ WAŻNE I GODNE OCHRONY POWODY PRZETWARZANIA, KTÓRE
                PRZEWAŻAJĄ NAD TWOIMI INTERESAMI, PRAWAMI I WOLNOŚCIAMI, LUB ŻE
                PRZETWARZANIE SŁUŻY DOCHODZENIU, WYKONYWANIU LUB OBRONIE ROSZCZEŃ PRAWNYCH
                (SPRZECIW NA PODSTAWIE ART. 21 UST. 1 RODO).
            </p>
            <p>
                JEŻELI TWOJE DANE OSOBOWE PRZETWARZANE SĄ DO CELÓW MARKETINGU
                BEZPOŚREDNIEGO, MASZ PRAWO W KAŻDEJ CHWILI ZŁOŻYĆ SPRZECIW WOBEC
                PRZETWARZANIA SWOICH DANYCH OSOBOWYCH DO CELÓW TAKIEJ REKLAMY; DOTYCZY TO
                RÓWNIEŻ PROFILOWANIA, O ILE JEST ONO ZWIĄZANE Z TAKIM MARKETINGIEM
                BEZPOŚREDNIM. W PRZYPADKU ZGŁOSZENIA SPRZECIWU DANE OSOBOWE NIE BĘDĄ JUŻ
                WYKORZYSTYWANE DO CELÓW MARKETINGU BEZPOŚREDNIEGO (SPRZECIW NA PODSTAWIE
                ART. 21 UST. 2 RODO).
            </p>

            <h4>Prawo do zażalenia we właściwym organie nadzorczym</h4>
            <p>
                W przypadku naruszenia przepisów RODO poszkodowane osoby mają prawo do
                zażalenia we właściwym organie nadzorczym, w szczególności w państwie
                członkowskim ich zwykłego pobytu, miejsca pracy lub miejsca, w którym
                nastąpiło domniemane naruszenie. Prawo do zażalenia nie narusza innych
                administracyjnych lub sądowych środków odwoławczych.
            </p>

            <h4>Prawo do możliwości przenoszenia danych</h4>
            <p>
                Masz prawo do tego, aby dane, które przetwarzamy automatycznie na podstawie
                Twojej zgody lub w ramach realizacji umowy, były przekazywane Tobie lub
                osobom trzecim we wspólnym formacie nadającym się do odczytu maszynowego.
                Jeśli zwrócisz się o bezpośrednie przekazanie danych innej osobie
                odpowiedzialnej, nastąpi to tylko wtedy, gdy będzie to technicznie
                wykonalne.
            </p>

            <h4>Szyfrowanie SSL lub TLS</h4>
            <p>
                Ta strona używa szyfrowania SSL lub TLS ze względów bezpieczeństwa oraz
                celem ochrony przekazywania poufnych treści, takich jak zamówienia lub
                zapytania przesłanych do nas, jako operatorów serwisu. Szyfrowane
                połączenie rozpoznasz po tym, że adres przeglądarki „http: //” zmienia się
                na „https: //”, oraz przez symbol blokady w adresie przeglądarce.
            </p>
            <p>
                Gdy aktywowane jest szyfrowanie SSL lub TLS, dane, które nam przekazujesz
                nie mogą zostać odczytane przez osoby trzecie.
            </p>

            <h4>Informacja, usuwanie oraz sprostowanie</h4>
            <p>
                W zakresie obowiązujących przepisów masz prawo zawsze do do uzyskania
                bezpłatnych informacji o swoich zapisanych danych osobowych, ich
                pochodzeniu i odbiorcach, oraz celu przetwarzania danych, a także do
                sprostowania lub usuwania tych danych. W tym celu, a także w przypadku
                dalszych pytań dotyczących ochrony danych osobowych, możesz w każdej chwili
                skontaktować się z nami pod adresem podanym w Impressum.
            </p>

            <h4>Prawo do ograniczenia przetwarzania danych</h4>
            <ul>
                Masz prawo żądać ograniczenia przetwarzania swoich danych osobowych. W tym
                celu możesz w każdej chwili skontaktować się z nami pod adresem podanym w
                Impressum. Prawo do ograniczenia przetwarzania danych istnieje w
                następujących przypadkach:
                <li>
                    Jeśli kwestionujesz prawidłowość przechowywanych u nas danych osobowych,
                    zazwyczaj potrzebujemy czasu, aby to sprawdzić. Na czas sprawdzania masz
                    prawo wnioskować o ograniczenie przetwarzania swoich danych osobowych.
                </li>
                <li>
                    Jeśli przetwarzanie Twoich danych osobowych było/jest niezgodne z prawem,
                    możesz złożyć wniosek o ograniczenie przetwarzania danych zamiast ich
                    usuwania.
                </li>
                <li>
                    Jeśli nie potrzebujemy już Twoich danych osobowych, ale są one potrzebne
                    do dochodzenia, obrony lub dochodzenia roszczeń prawnych, masz prawo żądać
                    ograniczenia przetwarzania swoich danych osobowych zamiast ich usuwania.
                </li>
                <li>
                    W przypadku wniesienia sprzeciwu zgodnie z art. 21 ust. 1 RODO, należy
                    wziąć pod uwagę interesy własne i naszej firmy. Dopóki nie jest jeszcze
                    jasne, czyje interesy przeważają, masz prawo żądać ograniczenia
                    przetwarzania swoich danych osobowych.
                </li>
            </ul>
                <p>
                    Jeżeli ograniczyłeś przetwarzanie swoich danych osobowych, dane te mogą być
                    przetwarzane – z wyjątkiem ich przechowywania – wyłącznie za Twoją zgodą
                    lub w celu dochodzenia, wykonania lub obrony roszczeń prawnych, lub celem
                    ochrony praw innej osoby fizycznej lub prawnej, lub też ze względu na ważny
                    interes publiczny Unii Europejskiej lub państwa członkowskiego.
                </p>

            <h3>4. Gromadzenie danych na tej stronie internetowej</h3>
            <h4>Pliki dzienników serwera </h4>
            <ul>
                Dostawca stron automatycznie gromadzi i zapisuje informacje w tak zwanych
                plikach dzienników serwera, które Twoja przeglądarka automatycznie wysyła
                do nas. Są to:
                <li>Typ i wersja przeglądarki</li>
                <li>Używany system operacyjny</li>
                <li>URL wywołującego</li>
                <li>Nazwa hosta komputera uzyskującego dostęp</li>
                <li>Czas żądania serwera</li>
                <li>Adres IP</li>
            </ul>
            <p>
                Dane te nie są scalane z innymi źródłami danych. Gromadzenie tych danych
                odbywa się na podstawie art. 6 ust. 1 lit. f RODO.
            </p>
            <p>
                Administrator strony ma uzasadniony interes w technicznie bezbłędnej
                prezentacji i optymalizacji swojej strony internetowej – w tym celu pliki
                dzienników serwera muszą być rejestrowane.
            </p>

            <h4>Formularz kontaktowy </h4>
                <p>
                    Jeśli wyślesz do nas zapytanie za pośrednictwem formularza kontaktowego,
                    Twoje dane z formularza zapytania, w tym dane kontaktowe, które tam
                    podałeś, zostaną zapisane w celu przetworzenia zapytania oraz w przypadku
                    dalszych pytań. Nie udostępnimy tych danych bez Twojej zgody.
                </p>
                <p>
                    Przetwarzanie tych danych odbywa się na podstawie art. 6 ust. 1 lit. b
                    RODO, jeżeli zapytanie dotyczy wykonania umowy lub jest niezbędne do
                    przeprowadzenia czynności przed zawarciem umowy. We wszystkich pozostałych
                    przypadkach przetwarzanie opiera się na naszym uzasadnionym interesie
                    skutecznego przetwarzania skierowanych do nas zapytań (art. 6 ust. 1 lit. f
                    RODO) lub Twojej zgodzie (art. 6 ust. 1 lit. a RODO), jeżeli zostałeś o nią
                    poproszony.
                </p>
                <p>
                    Dane wprowadzone przez Ciebie w formularzu kontaktowym pozostają u nas do
                    momentu, gdy zażądasz od nas usunięcia, cofniesz zgodę na ich
                    przechowywanie lub jeśli cel przechowywania danych przestanie obowiązywać
                    (np. po zakończeniu przetwarzania zapytania). Obowiązkowe przepisy ustawowe
                    – w szczególności okresy przechowywania – pozostają nienaruszone.
                </p>

            <h4>Zapytanie przez e-mail, telefon lub faks</h4>
                <p>
                    Jeśli skontaktujesz się z nami za pośrednictwem maila, telefonu lub faksu,
                    Twoje zapytanie, w tym wszystkie wynikające z niego dane osobowe (imię i
                    nazwisko, treść zapytania), będą przez nas przechowywane i przetwarzane w
                    celu przetworzenia Twojego zapytania. Nie udostępnimy tych danych bez
                    Twojej zgody.
                </p>
                <p>
                    Przetwarzanie tych danych odbywa się na podstawie art. 6 ust. 1 lit. b
                    RODO, jeżeli zapytanie dotyczy wykonania umowy lub jest niezbędne do
                    przeprowadzenia czynności przed zawarciem umowy. We wszystkich pozostałych
                    przypadkach przetwarzanie opiera się na naszym uzasadnionym interesie
                    skutecznego przetwarzania skierowanych do nas zapytań (art. 6 ust. 1 lit. f
                    RODO) lub Twojej zgodzie (art. 6 ust. 1 lit. a RODO), jeżeli zostałeś o nią
                    poproszony.
                </p>
                <p>
                    Dane wprowadzone przez Ciebie w formularzu kontaktowym pozostają u nas do
                    momentu, gdy zażądasz od nas usunięcia, cofniesz zgodę na ich
                    przechowywanie lub jeśli cel przechowywania danych przestanie obowiązywać
                    (np. po zakończeniu przetwarzania zapytania). Obowiązkowe przepisy ustawowe
                    – w szczególności okresy przechowywania – pozostają nienaruszone.
                </p>


            <h4>Rejestracja na tej stronie internetowej</h4>
                <p>
                    Możesz zarejestrować się na tej stronie, aby korzystać z dodatkowych
                    funkcji na stronie. Danych wprowadzonych w tym celu używamy wyłącznie w
                    celu skorzystania z odpowiedniej oferty lub usługi, dla których się
                    zarejestrowałeś. Obowiązkowe informacje wymagane podczas rejestracji muszą
                    być podane w całości. W przeciwnym razie odrzucimy rejestrację.
                </p>
                <p>
                    W przypadku ważnych zmian, takich jak zakres oferty lub konieczne zmiany
                    techniczne, korzystamy z adresu mailowego podanego podczas rejestracji, aby
                    Cię o tym poinformować.
                </p>
                <p>
                    Przetwarzanie danych wprowadzonych podczas rejestracji odbywa się w celu
                    realizacji stosunku użytkowania ustanowionego przez rejestrację oraz, w
                    razie potrzeby, w celu zawarcia kolejnych umów (art. 6 ust. 1 lit. b RODO).
                </p>
                <p>
                    Dane zgromadzone podczas rejestracji będą przez nas przechowywane, dopóki
                    będziesz zarejestrowany się na tej stronie, a następnie zostaną usunięte.
                    Ustawowe okresy przechowywania pozostają nienaruszone.
                </p>

            <h4>Przetwarzanie danych (danych dotyczących klientów i umów)</h4>
                <p>
                    Gromadzimy, przetwarzamy i wykorzystujemy dane osobowe tylko w zakresie
                    niezbędnym do utworzenia, projektowania zawartości lub zmiany stosunku
                    prawnego (dane główne). Odbywa się to na podstawie art. 6 ust. 1 lit. b
                    RODO, który zezwala na przetwarzanie danych do celów realizacji umowy lub
                    przeprowadzenia czynności przed zawarciem umowy. Dane osobowe dotyczące
                    korzystania z tej strony internetowej (dane użytkowania) gromadzimy,
                    przetwarzamy i wykorzystujemy wyłącznie w zakresie niezbędnym do
                    umożliwienia użytkownikowi korzystania z usługi lub wystawienia mu faktury
                    za korzystanie z niej.
                </p>
                <p>
                    Zgromadzone dane klienta zostaną usunięte po zakończeniu zamówienia lub
                    zakończeniu relacji biznesowej. Ustawowe okresy przechowywania pozostają
                    nienaruszone.
                </p>

            <h3>5. Usługi własne</h3>
            <h4>Przetwarzanie danych kandydatów</h4>
                <p>
                    Oferujemy Ci możliwość aplikowania do nas (np. mailowo, pocztą lub poprzez
                    formularz aplikacyjny online). Poniżej poinformujemy Cię o zakresie, celu i
                    wykorzystaniu twoich danych osobowych zebranych podczas procesu
                    aplikacyjnego. Zapewniamy, że gromadzenie, przetwarzanie i wykorzystywanie
                    Twoich danych nastąpi zgodne z obowiązującym prawem o ochronie danych i
                    wszystkimi innymi przepisami prawnymi oraz że Twoje informacje będą
                    traktowane jako ściśle poufne.
                </p>

            <h4>Zakres i cel gromadzenia danych</h4>
                <p>
                    Jeśli wyślesz nam podanie o pracę, to będziemy przetwarzać Twoje związane z
                    tym dane osobowe (np. dane kontaktowe i komunikacyjne, dokumenty
                    aplikacyjne, notatki związane z rozmowami kwalifikacyjnymi, itp.), o ile
                    będzie to konieczne do podjęcia decyzji o nawiązaniu stosunku pracy.
                    Podstawą prawną tego jest § 26 nowej ustawy BDSG zgodnie z
                    prawem niemieckim (rozpoczęcie stosunku pracy), art. 6 ust. 1 lit. b RODO
                    (ogólne rozpoczęcie umowy) oraz – jeżeli wyraziłeś zgodę – art. 6 ust. 1
                    lit. RODO. Zgoda może zostać odwołana w każdej chwili. Twoje dane osobowe
                    będą przekazywane w naszej firmie tylko osobom zaangażowanym w
                    przetwarzanie Twojego podania.
                </p>
                <p>
                    W przypadku pozytywnego rozpatrzenia podania, przekazane przez Ciebie dane
                    będą przechowywane w naszych systemach przetwarzania danych na podstawie §
                    26 nowej BDSG <em>[jw.] </em>i art. 6 ust. 1 lit. b RODO w celu realizacji
                    stosunku pracy.
                </p>

            <h4>Okres przechowywania danych</h4>
                <p>
                    Jeśli nie będziemy mogli zaoferować Ci pracy, odrzucisz ofertę pracy lub
                    wycofasz swoje podanie o pracę, zastrzegamy sobie prawo do przechowywania
                    dostarczonych przez Ciebie danych w oparciu o nasze uzasadnione interesy
                    (art. 6 ust. 1 lit. RODO) do 6 miesięcy od momentu zakończenia procesu
                    aplikacyjnego (odrzucenie lub wycofanie podania). Następnie dane zostaną
                    usunięte, a fizyczne dokumenty aplikacyjne zniszczone. Przechowywanie służy
                    w szczególności do dostarczenia dowodów w przypadku sporu prawnego. Jeżeli
                    jest oczywiste, że dane będą wymagane po upływie 6-miesięcznego okresu (np.
                    z powodu zbliżającego się lub toczącego się sporu prawnego), usunięcie
                    nastąpi tylko wtedy, gdy cel dalszego przechowywania danych przestanie
                    obowiązywać.
                </p>
                <p>
                    Dłuższe przechowywanie może mieć miejsce również wtedy, jeśli wyraziłeś na
                    to zgodę (art. 6 ust. 1 lit. a RODO) lub gdy prawne obowiązki w zakresie
                    przechowywania uniemożliwiają usunięcie.
                </p>

        </div>
    )
};

export default PrivatePolicyPl;
