import React from 'react';
import PropTypes from 'prop-types';

import { dateTime, weekDayDate, timeInterval } from 'utils/formatters';
import CargoOptionBadge from 'components/CargoOptionBadge/CargoOptionBadge';
import {translate} from "../../services/translationService";
import {history} from "../../helpers";

export const InquiryElement = ({inquiry, type}) => {
  const {
    shipmentData: {
      pickupDate,
      pickupTimeOpen,
      pickupTimeClose,
      deliveryDate,
      deliveryTimeOpen,
      deliveryTimeClose,
      pickupCountry,
      pickupPostCode,
      deliveryCountry,
      deliveryPostCode
    },
    id,
    createTime,
    numberOfBoxes,
    totalWeight,
    cargoOptionsList,
    accepted,
    hasUnreadMessages,
  } = inquiry;

  const renderCargoOptions = () => {
   return cargoOptionsList.map((cargoOption, index) => <CargoOptionBadge key={index} cargoOption={cargoOption} />)
  }

  return <div className="inquiries__row" onClick={()=>history.push(`/inquiry/${id}`)}>
      <div className="inquiries__column inquiries__create-date">
        {dateTime(createTime)}
      </div>
      <div className="inquiries__column">
        <i className="nc-icon nc-calendar-60 date-icon" />
        <span className="inquiries-date">{weekDayDate(pickupDate)}</span>
        <span className="inquiries-time">{` ${timeInterval(pickupTimeOpen, pickupTimeClose)}`}</span>
      </div>
      <div className="inquiries__column">
        <i className="nc-icon nc-calendar-60 date-icon" />
        <span className="inquiries-date">{weekDayDate(deliveryDate)}</span>
        <span className="inquiries-time">{` ${timeInterval(deliveryTimeOpen, deliveryTimeClose)}`}</span>
      </div>
      <div className="inquiries__column inquiries__pickup-place">
        <span className="label"><i className="nc-icon nc-pin-3" />{translate("From")}{": "}</span>
        {pickupCountry} {pickupPostCode}
      </div>
      <div className="inquiries__column inquiries__delivery-place">
        <span className="label"><i className="nc-icon nc-pin-3" />{translate("To")}{": "}</span>
        {deliveryCountry} {deliveryPostCode}
      </div>
      <div className="inquiries__column inquiries__cargo-options">
        {renderCargoOptions()}
      </div>
      <div className="inquiries__column inquiries__boxes">
        <span className="label"><i className="nc-icon nc-box-2" />{translate("coli")}{": "}</span>
        {numberOfBoxes}
      </div>
      <div className="inquiries__column inquiries__weight">
        <span className="label">{translate("Weight")}{": "}</span>
        {totalWeight} kg
      </div>
        {type === 'closed' && <div className="inquiries__column inquiries__status">
          {accepted ?
              <i className="nc-icon nc-check-2 status-check" title={translate("Offer accepted")}> </i>
              : <i className="nc-icon nc-simple-remove status-cross" title={translate("Offer rejected")}> </i>
          }
        </div>}
      {/*  Obsługa nieprzeczytanych wiadomości*/}
      {/*{type !== 'new' && (*/}
      {/*  <div className="inquiries__column">*/}
      {/*    {hasUnreadMessages ?*/}
      {/*      (*/}
      {/*        <div className="inquiries__message">*/}
      {/*          <i className="nc-icon nc-email-85 message-icon" />*/}
      {/*          <span className="message-text">{translate('new message')}</span>*/}
      {/*        </div>*/}
      {/*      ) :*/}
      {/*      (*/}
      {/*        <div className="inquiries__message">*/}
      {/*          <span className="message-text">{translate('no messages')}</span>*/}
      {/*        </div>*/}
      {/*      )*/}
      {/*    }*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
}

InquiryElement.propTypes = {
  inquiry: PropTypes.shape({
    shipmentData: PropTypes.shape({
      pickupDate: PropTypes.string,
      pickupTimeOpen: PropTypes.string,
      pickupTimeClose: PropTypes.string,
      deliveryDate: PropTypes.string,
      deliveryTimeOpen: PropTypes.string,
      deliveryTimeClose: PropTypes.string,
      pickupCountry: PropTypes.string,
      pickupPostCode: PropTypes.string,
      deliveryCountry: PropTypes.string,
      deliveryPostCod: PropTypes.string,
    }),
    createTime: PropTypes.string,
    numberOfBoxes: PropTypes.number,
    totalWeight: PropTypes.number,
    cargoOptionsList: PropTypes.array,
    hasUnreadMessages: PropTypes.bool,
  })
}
