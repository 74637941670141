import React from "react";
import {Button, Col, Row} from "reactstrap";
import {connect} from 'react-redux'
import {translate} from 'services/translationService';
import {getCarSet} from "redux/actions/car";
import Loader from "components/Loader/Loader";
import "../../style.scss";
import {apiService} from "../../../../services/apiService";
import {alertActions, getTruckTrailers} from "../../../../redux/actions";
import getErrorText from "../../../../services/errorTextService";
import TruckTrailersModal from "../TruckTrailersModal";
import {history} from "../../../../helpers";
import StorageSpecification from "./StorageSpecification";
import VehicleDetails from "./VehicleDetails";
import SetSpecification from "./SetSpecification";
import IsActiveInfo from "./Components/IsActiveInfo";
import mapService from "../../../../services/mapService";
import VehicleLocation from "../CarLocation/VehicleLocation";
import HelperButton from "../../../../components/Helper/HelperButton";

class CarSet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            trailersListVisible: false,
        };
        this.carSetId = this.props.match.params.carSetId;
        this.unlinkSet = this.unlinkSet.bind(this);
        this.linkSet = this.linkSet.bind(this);
        this.connectToSet = this.connectToSet.bind(this);
        this.toggleTrailersModal = this.toggleTrailersModal.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.setActive = this.setActive.bind(this);
    }

    componentDidMount() {
        this.refreshData();
    }

    refreshData(){
        this.props.dispatch(getCarSet(this.carSetId));
            this.props.dispatch(getTruckTrailers({
                page: 1,
                perPage: 100,
                isConnectedToSet: 0
            }));
    }

    toggleTrailersModal() {
        this.setState({
            trailersListVisible: !this.state.trailersListVisible
        });
    }

    connectToSet(trailerId, maxWeight) {
        const {carSet, dispatch} = this.props;
        apiService.put('api/vehicles/sets/' + carSet.id, {
            maxWeight: maxWeight,
            car: {
                id: carSet.car.id,
            },
            truckTrailer: {
                id: trailerId
            }
        })
            .then(response => {
                dispatch(alertActions.success(translate("Trailer added successfully")));
                this.toggleTrailersModal();
                this.refreshData();
            }).catch(errorMessage => {
            this.toggleTrailersModal();
            dispatch(alertActions.error(getErrorText(errorMessage)));
        })
    }

    unlinkSet() {
        const {dispatch} = this.props;
        apiService.put('/api/vehicles/sets/' + this.carSetId + '/unlink')
            .then(() => {
                dispatch(alertActions.success(translate("Car set unlinked successfully")));
                history.push('/cars');
            })
            .catch((apiError) => {
                dispatch(alertActions.error(getErrorText(apiError)));
            });
    }

    linkSet() {
        this.toggleTrailersModal();
    }

    setActive(active) {
        const {dispatch} = this.props;
        const info = active ? translate("Car set activated successfully") : translate("Car set deactivated successfully");
        apiService.put('/api/vehicles/sets/' + this.carSetId + '/setActive', { active: active })
            .then(() => {
                dispatch(alertActions.success(info));
                history.push('/cars');
            })
            .catch((apiError) => {
                dispatch(alertActions.error(getErrorText(apiError)));
            });
    }


    render() {
        const {isFetching, carSet, isFetchingTrailers, truckTrailers} = this.props;
        if ((isFetching || isFetchingTrailers)){
            return <Loader/>
        }
        const showLocation = mapService.showCarPosition(carSet.car);
        return (
            <div className="content">
                <div style={{position: 'relative'}} className={"car-set-helper-wrapper"}>
                    <HelperButton helperId="carrier-car-set-details" />
                </div>
                        <>
                        <Row>
                            <Col md={5}>
                                <IsActiveInfo isActive={carSet.isActive} action={this.setActive} />
                            </Col>
                            <Col md={7}>
                                {carSet.truckTrailer ?
                                            <SetSpecification
                                                carSet = {carSet}
                                                title = {translate('Vehicle set specification')}
                                            />
                                    : ""
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                              <VehicleDetails
                                  title={translate('Car details')}
                                  vehicle={carSet.car}
                                  showDriversModal={this.toggleDriversModal}
                                  carSet={carSet}
                              />
                            </Col>
                            {carSet.truckTrailer ?
                                <Col md={7}>
                                    <Row>
                                        <Col md={2} className="unlink-container">
                                            <Button className="unlink-button" color="danger" onClick={this.unlinkSet}>
                                                <i
                                                    className="nc-icon nc-lock-circle-open"/> <br/>
                                                {translate("unlink")}
                                            </Button>
                                        </Col>
                                        <Col md={10}>
                                            <VehicleDetails
                                                title={translate('Trailer details')}
                                                vehicle={carSet.truckTrailer}
                                                carSet={carSet}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                :
                                <Col md={2}>
                                    <Row className="link-container">
                                        <Button className="link-button" color="info" onClick={this.linkSet}>
                                            <i className="nc-icon nc-simple-add"/> <br/>
                                            {translate("link trailer")}
                                        </Button>
                                    </Row>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col md={5}>
                                <StorageSpecification
                                    container = {carSet.car.container}
                                    title = {translate('Car storage specification')}
                                />
                            </Col>

                            <Col md={7}>
                                {carSet.truckTrailer ?
                                    <Row>
                                    <Col md={2}>
                                    </Col>
                                    <Col md={10}>
                                        <StorageSpecification
                                            container = {carSet.truckTrailer.container}
                                            title = {translate('Trailer storage specification')}
                                        />
                                    </Col>
                                    </Row>
                                    : ""
                                }
                            </Col>
                        </Row>
                        <Row>
                            {showLocation && <Col md={12}>
                                <VehicleLocation carData={carSet.car}/>
                            </Col>}
                        </Row>

                            <TruckTrailersModal
                                toggleTrailersModal={this.toggleTrailersModal}
                                showModal={this.state.trailersListVisible}
                                trailers={truckTrailers}
                                isFetching={isFetchingTrailers}
                                connectToSet={this.connectToSet}
                            />
                        </>
            </div>
        );
    }
}

CarSet.defaultProps = {
    isFetching: true,
    error: false,
    truckTrailers: {},
    errorTrailers: false,
    carSet: {},
    isFetchingTrailers: true,
};

const mapStateToProps = (state) => {
    return {
        carSet: state.carSetReducer.carSet,
        isFetching: state.carSetReducer.isFetching,
        error: state.carSetReducer.error,
        truckTrailers: state.truckTrailersReducer.truckTrailers,
        errorTrailers: state.truckTrailersReducer.error,
        isFetchingTrailers: state.truckTrailersReducer.isFetching,
    }
}
export default connect(mapStateToProps)(CarSet);
