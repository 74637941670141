import {apiService} from "services/apiService";
import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";

export const FETCH_HELPER_TEXT_REQUEST = 'FETCH_HELPER_TEXT_REQUEST';
export const FETCH_HELPER_TEXT_SUCCESS = 'FETCH_HELPER_TEXT_SUCCESS';
export const FETCH_HELPER_TEXT_FAILURE = 'FETCH_HELPER_TEXT_FAILURE';


export function getHelperText(textId) {
    return dispatch => {
        dispatch(request({}));
        apiService.get('/api/helper-text/' + textId)
            .then(
                response => {
                    dispatch(success({data:response.data, textId}));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_HELPER_TEXT_REQUEST, data } }
    function success(data) { return { type: FETCH_HELPER_TEXT_SUCCESS, data } }
    function failure(error) { return { type: FETCH_HELPER_TEXT_FAILURE, error } }
}
