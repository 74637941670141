import React from "react";
import {translate} from 'services/translationService';
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {connect} from "react-redux";
import ModalFooter from "reactstrap/es/ModalFooter";
import {Field, reduxForm} from "redux-form";
import {remarkValidation} from "../../Users/User/validation";
import FieldSelect from "../../../components/FormFields/FieldSelect/FieldSelect";
import {prepareRemarkData, remarkCategoriesForSelect, shipmentLifecyclesForSelect} from "../../../helpers/formHelper";
import FieldInput from "../../../components/FormFields/FieldInput/FieldInput";
import FieldMultiSelect from "../../../components/FormFields/FieldMultiSelect/FieldMultiSelect";
import {postShipmentRemark} from "../../../redux/actions";
import AddPhoto from "../../../components/FormFields/AddPhoto/AddPhoto";
import PreviewPhoto from "../../../components/FormFields/AddPhoto/PreviewPhoto";

class AddRemarkModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [null, null, null, null, null]
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.setFile = this.setFile.bind(this);
    }

    componentDidMount() {
        if (this.props.remarkCategories.length > 0) {
            this.props.initialize({ shipment_remark_category_id:
                    remarkCategoriesForSelect(this.props.remarkCategories)[0].id.toString()
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.remarkCategories.length !== prevProps.remarkCategories.length) {
            this.props.initialize({ shipment_remark_category_id:
                    remarkCategoriesForSelect(this.props.remarkCategories)[0].id.toString()
            });
        }
    }

    onFormSubmit(formData) {
        const {dispatch, orderId, toggleModal} = this.props;
        dispatch(postShipmentRemark(orderId, prepareRemarkData(formData, this.state.files)));
        toggleModal();
    }

    setFile(file, index) {
        let newFiles = this.state.files;
        newFiles[index] = file
        this.setState({
            files: newFiles
        })
    }

    clearFile(index) {
        let newFiles = this.state.files;
        newFiles[index] = null
        this.setState({
            files: newFiles
        })
    }

    render() {
        const {showModal, toggleModal, remarkCategories, lifeCycles, packages, invalid} = this.props;
        const {files} = this.state;
        return (
            <div className="add-remark">
                <Modal isOpen={showModal} toggle={toggleModal} size="lg" scrollable={true} className={"add-remark-modal"}>
                    <ModalHeader toggle={toggleModal}>{translate("Shipment Remarks details")}
                    </ModalHeader>
                    <form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Field
                                    name="shipment_remark_category_id"
                                    options={remarkCategoriesForSelect(remarkCategories)}
                                    label={translate("Remark category")}
                                    component={FieldSelect}
                                    props={{emptyValue: false}}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Field
                                    name="shipment_lifecycle_id"
                                    options={shipmentLifecyclesForSelect(lifeCycles)}
                                    disabled={lifeCycles.length === 0}
                                    label={translate("Shipment status")}
                                    component={FieldSelect}
                                    props={{isOptional:true, emptyValue:false}}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Field
                                    label={translate('Remark content')}
                                    component={FieldInput}
                                    type="textarea"
                                    name="description"
                                />
                            </Col>
                        </Row>                        <Row>
                            <Col md={12}>
                                <Field
                                    name="packages"
                                    label={translate("Packages")}
                                    options={packages}
                                    textField="number"
                                    valueField="id"
                                    component={FieldMultiSelect}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className={"text-center"}>{translate("Add photos:")}</div>
                                <div className={"photos-container"}>
                                    {files.map((file, index)=>{
                                        return file ?  <PreviewPhoto file={file}  key={index}  clearFile={()=>this.clearFile(index)}/> :
                                            <AddPhoto key={index} index={index}  sefFile={this.setFile}/>
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" className="btn-round" disabled={invalid}>
                            {translate("Add remark")}
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
            </div>
        )
    }
}

AddRemarkModal.defaultProps = {
    isFetching: null,
    error: false,
    remarkCategories: [],
}

function mapStateToProps(state) {
    return {
        isFetching: state.shipmentRemarkReducer.isFetching,
        error: state.shipmentRemarkReducer.error,
        remarkCategories: state.remarkCategoryReducer.remarkCategories
    };
}

const decoratedComponent = connect(mapStateToProps)(AddRemarkModal);

export default reduxForm({
    form: 'createRemarkForm',
    validate: remarkValidation,
})(decoratedComponent);