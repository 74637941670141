import React from "react";
import {Row} from "reactstrap";
import dashboardTiles from './DashboardTiles';
import loggedUserService from "../../services/loggedUserService";
import InactiveCarrier from "./InactiveCarrier"
import SingleTile from "./SingleTile";
import PrivatePolicy from "./PrivatePolicy/PrivatePolicy";

class Dashboard extends React.Component {

    render() {
        const activeCarrier = loggedUserService.isCarrierActive();
        return (
            <div className="content">
                {loggedUserService.getUserData().status === 'firstLogin' ?
                    <PrivatePolicy/>
                    : ""
                }
                <Row>
                    {activeCarrier ?
                        dashboardTiles().map((dashboardTile, key) =>
                            <SingleTile dashboardTile={dashboardTile} key={key}/>)
                        :
                        <InactiveCarrier/>
                    }
                </Row>
            </div>
        );
    }
}

export default Dashboard;

