import React from "react";

const PrivatePolicyEn = () => {

    return (
        <div>
            <h2>Privacy Policy</h2>
            <h3>1. An overview of data protection</h3>
            <h4>General information</h4>
            <p>
                The following information will provide you with an easy to navigate overview of what will happen with your personal data when you visit this website. Personal data is any information by which you can be personally identified. Detailed information on data protection can be found in this Privacy Policy.
            </p>

            <h4>Data collection on this website</h4>
            <h4>Who is responsible for the data collection on this website?</h4>
            <p>
                The data on this website is processed by the operator of the website. His contact details can be found in the Contact data on this website.
            </p>

            <h4>How do we collect your data?</h4>
            <p>
                On the one hand, your data is collected when it is sent to us. This may, for instance, be information you enter into our contact form. Our IT systems automatically collect other data when you visit our website. This data is primarily technical information (e.g. web browser, operating system or time the site was accessed). This information is collected automatically when you access this website.
            </p>

            <h4>What are the purposes we use your data for?</h4>

            <p>
                Part of the data is collected in order to ensure the flawless operation of the website. Other data may be used to analyse your user patterns.
            </p>

            <h4>What rights do you have regarding your data?</h4>
            <p>
                You have the right at any time to obtain free information about the origin, recipient and purpose of the stored personal data. You also have the right to request the rectification or deletion of such data. For this purpose, and if you have any further questions about data protection, you can contact us at any time at the address given in the Contact data. Furthermore, you have the right to appeal to the competent supervisory authority.
            </p>
            <p>
                Moreover, under certain circumstances, you have the right to demand the restriction of the processing of your personal data. Details on this can be found in the Privacy Policy under "Right to Restriction of Data Processing".
            </p>

            <h3>2. Hosting</h3>
            <h4>External Hosting</h4>
            <p>
                This website is hosted by an external service provider (hoster). Personal data collected on this website is stored on the host's servers. This may include IP addresses, contact requests, meta and communication information, contract information, contact information, names, website accesses, and other information generated by a website.
                Hosting services are used for the purpose of fulfilling the contract with our potential and existing customers (Art. 6(1) lit. b GDPR) and in the interest of a secure, fast and efficient provision of our online services by a professional provider (Art. 6(1) lit. f GDPR).
            </p>
            <p>
                Our hoster will only process your data to the extent that this is necessary to fulfil its performance obligations and follow our instructions with regard to this data.
            </p>

            <h3>3. General information and mandatory information</h3>
            <h4>Data protection</h4>
            <p>
                The operators of this website and its pages take the protection of your personal data very seriously. Hence, we handle your personal data as confidential information and in compliance with the statutory data protection regulations and this Privacy Policy.
            </p>
            <p>
                Whenever you use this website, a variety of personal information will be collected. Personal data is any information by which you can be personally identified. This Privacy Policy explains which data we collect as well as the purposes we use this data for. It also explains how and for which purpose the information is collected.
                Please note that data transmission on the Internet (e.g. during an e-mail communication) may have security gaps. It is not possible to fully protect your data against access by third parties.
            </p>

            <h4>Information about the responsible party</h4>
            <p>
                The data processing controller on this website is:
            </p>
            <address>
                Janta GmbH
                Im Riebeisen 31
                71404 Korb
                Telefon: +49 (0)7151 - 135 16 90
                E-Mail: info@janta-logistics.de
            </address>
            <p>
                The controller is the natural or legal person who, alone or in consultation with others, decides on the purposes and means of processing personal data (e.g. names, e-mail addresses, etc.).
            </p>

            <h4>Revocation of your consent to the data processing </h4>
            <p>
                Many data processing operations are only possible with your express consent. You can revoke your consent at any time. For this purpose, an informal e-mail notification to us is sufficient. The legality of the data processing carried out until the revocation remains unaffected by the revocation.
            </p>

            <h4>Right to object to the data collection in special cases; right to object to direct advertising (Art. 21 GDPR)</h4>
            <p>
                IN THE EVENT THAT DATA IS PROCESSED ON THE BASIS OF ART. 6(1) LIT. E OR F GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR PARTICULAR SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, CAN BE FOUND IN THIS PRIVACY POLICY. IF YOU FILE AN OBJECTION, WE WILL NO LONGER PROCESS YOUR PERSONAL DATA CONCERNED UNLESS WE CAN PROVE COMPELLING PROTECTION WORTHY REASONS FOR THE PROCESSING OF YOUR DATA, WHICH OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL CLAIMS (OBJECTION PURSUANT TO ART. 21(1) GDPR).
                IF YOUR PERSONAL DATA IS PROCESSED FOR THE PURPOSE OF DIRECT ADVERTISING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF YOUR PERSONAL DATA FOR THE PURPOSE OF SUCH ADVERTISING; THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21(2) GDPR).
            </p>

            <h4>Right to appeal to the competent supervisory authority</h4>
            <p>
                In the event of violations of the GDPR, data subjects are entitled to file a complaint to the supervisory authority, in particular in the member state where they usually maintain their domicile, place of work or at the place where the alleged violation occurred. The right of appeal shall remain irrespective of other administrative or judicial remedies.
            </p>

            <h4>Right to data portability</h4>
            <p>
                You have the right to demand that we hand over any data we automatically process on the basis of your consent or in order to fulfil a contract to you or a third party in a commonly used, machine readable format. If you demand the direct transfer of the data to another controller, this will be done only if it is technically feasible.
            </p>

            <h4>SSL or TLS encryption</h4>
            <p>
                For security reasons and to protect the transmission of confidential content, such as orders or inquiries you submit to us as the website operator, this website uses either an SSL or a TLS encryption. You can recognise an encrypted connection by checking whether the address line of the browser switches from „http://“ to „https://“ and also by the appearance of the lock icon in the browser line.
                When SSL or TLS encryption is activated, the data you provide to us cannot be read by third parties.
            </p>
            <p>
                When SSL or TLS encryption is activated, the data you provide to us cannot be read by third parties.
            </p>

            <h4>Information, rectification and eradication</h4>
            <p>
                To the extent permitted by applicable law, you always have the right to obtain free information about your stored personal data, its origin and recipients, and the purpose of data processing, as well as to correct or delete such data. For this purpose, and if you have any further questions about data protection, you can contact us at any time at the address given in the Contact data.
            </p>

            <h4>Right to limit data processing</h4>
            <ul>
                You have the right to demand the restriction of your data processing. For this purpose, you can contact us at any time at the address given in the Contact data. The right to limit data processing exists in the following cases:
                <li>
                    If you question the accuracy of the personal information we store about you, we usually need time to verify this. You have the right to request the restriction of your data processing for the duration of the check.
                </li>
                <li>
                    If the processing of your personal data was/is unlawful, you may request the restriction of your data processing instead of being deleted
                </li>
                <li>
                    If we no longer need your personal data, but it is needed to pursue, defend or enforce legal claims, you have the right to the restriction of your personal data processing instead of deleting it.
                </li>
                <li>
                    If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our rights will have to be weighed against each other. As long as it has not been determined whose interests prevail, you have the right to demand a restriction of the processing of your personal data.
                </li>
            </ul>
            <p>
                If you have restricted the processing of your personal data, these data – with the exception of their storage – may be processed only subject to your consent or to claim, exercise or defend legal entitlements or to protect the rights of other natural persons or legal entities or for important public interest reasons cited by the European Union or a member state of the EU.
            </p>

            <h3>4. Data collection on this website</h3>
            <h4>Server log files</h4>
            <ul>
                The provider of this website and its pages automatically collects and stores information in so-called server log files, which your browser sends to us automatically. The information is:
                <li>Type and version of browser</li>
                <li>Operating system used</li>
                <li>Referrer URL</li>
                <li>Hostname of the accessing computer</li>
                <li>Time of the server inquiry</li>
                <li>IP address</li>
            </ul>
            <p>
                This data will not be merged with other data sources.</p>
            <p>
                This data is collected on the basis of Art. 6(1) lit. f GDPR. The website operator has a justified interest in the technically error-free presentation and optimisation of his website – for this purpose, the server log files must be recorded.
            </p>

            <h4>Contact form</h4>
            <p>
                If you send us enquiries via the contact form, your details from the enquiry form, including the contact data you provided, will be stored by us for the purpose of processing the enquiry and in the event of follow-up questions. These data will not be passed on without your consent
            </p>
            <p>
                This data is processed on the basis of Art. 6(1) lit. b GDPR if your enquiry is related to the fulfilment of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the enquiries addressed to us (Art. 6(1) lit. f GDPR) or on your consent (Art. 6(1) lit. a GDPR) if this has been requested
            </p>
            <p>
                The data entered by you in the contact form will remain with us until you request its deletion, revoke your consent to storage or the purpose for data storage no longer applies (e.g. after your request has been processed). Mandatory legal provisions – in particular retention periods – remain unaffected.
            </p>

            <h4>Request by e-mail, telephone or fax</h4>
            <p>
                If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name, request) will be stored and processed by us for the purpose of processing your request. We do not pass these data on without your consent.
            </p>
            <p>
                The processing of these data is based on Art. 6(1) lit. b GDPR, if your request is related to the execution of a contract or if it is necessary to carry out some pre-contractual measures. In all other cases, the processing is based on your consent (Article 6 (1) lit. a GDPR) and/or on our legitimate interests (Article 6 (1) (f) GDPR), since we have a legitimate interest in the effective processing of requests addressed to us.
            </p>
            <p>
                The data entered by you in the contact form will remain with us until you request its deletion, revoke your consent to storage or the purpose for data storage no longer applies (e.g. after your request has been processed). Mandatory legal provisions – in particular retention periods – remain unaffected.
            </p>


            <h4>Registration on this website</h4>
            <p>
                You can register on this website to use additional functions on the site. We use the data entered for this reason only for the purpose of using the relevant offer or service for which you have registered. The mandatory information requested during the registration must be provided in full. Otherwise we will refuse the registration.
            </p>
            <p>
                In the case of important changes, for example in the scope of our services or in the case of technically necessary changes, we will use the e-mail address provided during the registration to inform you in this way.
            </p>
            <p>
                The processing of the data entered during the registration is carried out for the purpose of implementing the user relationship established by the registration and, if necessary, for initiating further contracts (Art. 6(1) lit. b GDPR).
            </p>
            <p>
                The data collected during the registration will be stored by us as long as you are registered on this website and will subsequently be deleted. Legal retention periods remain unaffected.
            </p>

            <h4>Processing of data (customer and contract data)</h4>
            <p>
                We collect, process and use personal data only to the extent necessary for the establishment, content or modification of the legal relationship (key data). This is done on the basis of Art. 6(1) lit. b GDPR, which permits the processing of data for the fulfilment of a contract or pre-contractual measures. We collect, process and use personal data relating to the use of this website (usage data) only to the extent necessary to enable or bill the user for the use of the service.
            </p>
            <p>
                The collected customer data will be deleted after completion of the order or termination of the business relationship. Legal retention periods remain unaffected.
            </p>

            <h3>5. Own services</h3>
            <h4>Processing of candidates' data</h4>
            <p>
                We offer you the opportunity to apply to us (e.g. by e-mail, post or online application form). In the following we will inform you about the scope, purpose and use of your personal data collected during the application process. We assure you that your data will be collected, processed and used in accordance with applicable data protection law and all other statutory provisions and that your data will be treated strictly confidentially.
            </p>

            <h4>Scope and purpose of data collection</h4>
            <p>
                If you send us an application, we will process your associated personal data (e.g. contact and communication data, application documents, notes taken during job interviews, etc.) provided it is necessary for a decision on the establishment of an employment relationship. The legal basis for this is § 26 of the new BDSG [German abbr. for the Federal Data Protection Act] according to German law (initiation of an employment relationship), Art. 6(1) lit. b GDPR (general contract initiation) and – if you have given your consent – Art. 6(1) lit. a GDPR. The consent may be revoked at any time. Your personal data will only be passed on within our company to people who are involved in the processing of your application.
            </p>
            <p>
                If the application is successful, the data submitted by you will be stored in our data processing systems on the basis of § 26 of the new BDSG [ditto] and Art. 6(1) lit. b GDPR for the purpose of carrying out the employment relationship.
            </p>

            <h4>Storage period of the data</h4>
            <p>
                If we are unable to make you a job offer, you reject a job offer or withdraw your application, we reserve the right to retain the data you have submitted on the basis of our legitimate interests (Art. 6 (1) lit. f GDPR) for up to 6 months from the end of the application procedure (rejection or withdrawal of the application). The data will then be deleted and the physical application documents destroyed. The storage serves in particular to provide evidence in the event of a legal dispute. If it is evident that the data will be required after expiry of the 6-month period (e.g. due to an impending or pending legal dispute), deletion will only take place when the purpose for further storage no longer applies.             A longer storage can also take place if you have given your consent (Art. 6(1) lit. a GDPR) or if legal storage obligations prevent the deletion.
            </p>
        </div>
    )
    };

export default PrivatePolicyEn;
