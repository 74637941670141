import React from "react";
import {connect} from "react-redux";
import {formValues} from "redux-form";
import {apiService} from "services/apiService";
import {selectSpecification} from 'redux/actions/car/specification.actions';
import SingleSpecification from "./SingleSpecification";
import "./style.scss";


class SpecificationsList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            specifications: [],
            filteredSpecifications: [],
        };
        this.getSpecifications = this.getSpecifications.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
    }

    componentDidMount() {
        this.getSpecifications();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.vehicleType !== this.props.vehicleType) {
            this.getSpecifications();
        }
        if (prevProps.filters !== this.props.filters) {
            this.applyFilters();
        }
    }

    getSpecifications() {
        if (this.props.vehicleType) {
            apiService.get('api/vehicles/container-specifications/' + this.props.vehicleType)
                .then(response => {
                    let specifications = response.data;
                    specifications.forEach(specification => {
                        delete(specification.maxWeightReal);
                        delete(specification.containerTotalWeight);
                    });
                    this.setState({
                        specifications: response.data
                    });
                    this.applyFilters();
                })
                .catch(errorMessage => {
                    console.log(errorMessage);
                });
        }
    }

    applyFilters() {
        let specifications = this.state.specifications;
        if(this.props.filters) {
           Object.entries(this.props.filters).forEach(([key, value]) => {
                   specifications = specifications.filter(spec => {
                       if (key === 'bodyType') {
                           return spec[value] === 1;
                       }
                       else if(value === true){
                           return spec[key] === 1;
                       }
                       else {
                           return true
                       }
                   });
               });
        }
        this.setState({
            filteredSpecifications: specifications
        })
    }

    selectSpecification(specification) {
        this.props.dispatch(selectSpecification(specification));


    }


    render() {
        return (
                <div className="specification-list">
                    {this.state.filteredSpecifications.map(specification => {
                        return <SingleSpecification
                            specification={specification}
                            selected={this.props.specification.id === specification.id}
                            anySelected={this.props.specification.id}
                            clickEvent={this.selectSpecification.bind(this, specification)}
                            key={specification.id}
                        />
                    })
                    }
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        specification: state.specificationReducer.specification,
    };
}

const decoratedComponent = connect(mapStateToProps)(SpecificationsList);

export default formValues('vehicleType', 'filters')(decoratedComponent);