import {
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    TOGGLE_DEFAULT_ADMIN,
    REVERSE_DEFAULT_ADMIN,
    CONFIRM_DEFAULT_ADMIN
} from 'redux/actions/users/users.actions.js';

const initialState = {
    message: '',
    accounts: {},
    meta:{},
    isFetching: true,
    error: false,
    lastToggled:null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
                lastToggled:null
            });
        }

        case FETCH_USERS_SUCCESS: {
            const defaultAdmin = action.data.data.find(account=>('isDefaultAdmin' in account && account.isDefaultAdmin));
            return Object.assign({}, state, {
                accounts: action.data.data,
                meta: action.data.meta,
                isFetching: false,
                lastToggled: defaultAdmin ? defaultAdmin.id : null
            });
        }

        case FETCH_USERS_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true,
                lastToggled:null
            });
        }

        case TOGGLE_DEFAULT_ADMIN: {
            const newToggled = action.data;
            const updatedAccounts = [...state.accounts].map( account => {
                const updatedAccount = {...account};
                if ('isDefaultAdmin' in account ){
                    updatedAccount['isDefaultAdmin'] = account.id === newToggled
                }
                return updatedAccount
            })
            return {...state, accounts:updatedAccounts}
        }

        case CONFIRM_DEFAULT_ADMIN: {
            const newToggled = action.data;
            return {...state, lastToggled:newToggled}
        }

        case REVERSE_DEFAULT_ADMIN: {
            const lastToggled = state.lastToggled;
            const updatedAccounts = [...state.accounts].map( account => {
                const updatedAccount = {...account};
                if ('isDefaultAdmin' in account ){
                    updatedAccount['isDefaultAdmin'] = account.id === lastToggled
                }
                return updatedAccount
            })
            return {...state, accounts:updatedAccounts, lastToggled}
        }

        default:
            return state
    }
}
