
const carrierValidator = {
    name: new RegExp('^[a-zA-Z0-9\\s-]+$'),
    bankName: new RegExp('^[a-zA-Z0-9\\s-]+$'),
    iban: new RegExp('^[a-zA-Z0-9\\s-]+$'),
    swift: new RegExp('^[a-zA-Z0-9\\s-]+$'),
    taxNumberUe: new RegExp('^[a-zA-Z0-9-]+$'),
    taxNumberDe: new RegExp('^[a-zA-Z0-9/-]+$'),
    address: {
        phone: new RegExp('([(+]*[0-9]+[()+. -]*)'),
        contact: new RegExp('^[a-zA-Z0-9\\s-]+$'),
        email: new RegExp('.+@.+\\..+')
    }
}

const validate = (values) => {
    const errors = {
        address: {
            street: ['', '', '']
        }
    };

    if (!values.name) {
        errors.name = 'Name is required';
    } else if (carrierValidator.name.test(values.name) === false) {
        errors.name = 'Name can contain only letters, numbers and spaces ';
    }

    if (!values.address) {
        errors.address.street = 'Street is required';
        errors.address.city = 'City is required';
        errors.address.postCode = 'Post Code is required';
        errors.address.country = 'Country is required';
        errors.address.email = 'Email is required';
    } else {
        if (!values.address.street || !values.address.street[0]) {
            errors.address.street[0] = 'Street is required';
        }
        if (!values.address.city) {
            errors.address.city = 'City is required';
        }
        if (!values.address.postCode) {
            errors.address.postCode = 'Post Code is required';
        }
        if (!values.address.country) {
            errors.address.country = 'Country is required';
        }
        if (!values.address.email) {
            errors.address.email = 'Email is required';
        }
        if ((values.address.contact) && (!carrierValidator.address.contact.test(values.address.contact))) {
            errors.address.contact = 'Contact can contain only letters, numbers and spaces';
        }
        if ((values.address.phone)&&(!carrierValidator.address.phone.test(values.address.phone))) {
            errors.address.phone = 'Please enter a valid phone number';
        }
        if ((values.address.email)&&(!carrierValidator.address.email.test(values.address.email))) {
            errors.address.email = 'Please enter a valid email number';
        }
    }

    if (carrierValidator.bankName.test(values.bankName) === false) {
        errors.bankName = 'Bank Name can contain only letters, numbers and spaces ';
    }

    if (carrierValidator.iban.test(values.iban) === false) {
        errors.iban = 'IBAN number can contain only letters, numbers and spaces ';
    }

    if (carrierValidator.swift.test(values.swift) === false) {
        errors.swift = 'SWIFT number can contain only letters, numbers and spaces ';
    }

    if (carrierValidator.taxNumberUe.test(values.taxNumberUe) === false) {
        errors.taxNumberUe = 'EU VAT-ID can contain only letters and numbers';
    }

    if (carrierValidator.taxNumberDe.test(values.taxNumberDe) === false) {
        errors.taxNumberDe = "Tax number can contain only letters,numbers,minuses and /";

    }

    return errors;
};


const carrierValidationUpdate = (values) => {
    return validate(values);
};

export {carrierValidationUpdate};
