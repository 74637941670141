import {translate} from 'services/translationService';

export function languagesForSelect() {
    return [
        {
            id:'pl',
            name: translate('Polish')
        },
        {
            id:'en',
            name: translate('English')
        },
        {
            id:'de',
            name: translate('German')
        },
    ]
}
