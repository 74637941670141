import React from "react";
import {connect} from "react-redux";
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {translate} from 'services/translationService';
import MediaQuery from 'react-responsive';
import {deleteUserDocument, deleteCarrierDocument} from "../../redux/actions";

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

class DocumentDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDeleteModal: false
        };
        this.showFile = this.showFile.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
    }

    toggleDeleteModal() {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal
        });
    }

    deleteFile() {
        const {dispatch, document} = this.props;

        this.toggleDeleteModal();
        if (document.documentType.assignedToType === 'account')  {
            dispatch(deleteUserDocument(document.id));
        } else {
            dispatch(deleteCarrierDocument(document.id));
        }
    }

    showFile() {
        if(typeof(window.cordova)==='undefined'){
            let previewUrl = `#/${this.props.document.documentType.assignedToType}/document/${this.props.document.id}`;
            window.open(previewUrl);
        }else{
            let documentUrl = `${BASE_URL}/api/${this.props.document.documentType.assignedToType}s/documents/${this.props.document.id}?token=`+localStorage.getItem('authToken');
            window.cordova.InAppBrowser.open(documentUrl, '_system', 'location=yes');
        }
    }

    render() {
        const {document} = this.props;
        return (
        <div className="document-detail">
            <Row>
                <MediaQuery query='(min-device-width: 540px)'>
                    <Col sm={4}>
                        <i className="nc-icon nc-paper icon-big"/>
                    </Col>
                </MediaQuery>
                <Col sm={8}>
                    <label>{translate('Created')}:</label> {document.creationDate} <br/>
                    <label>{translate('Valid from')}: </label> {document.validFrom} <br/>
                    <label>{translate('Expires')}: </label> {document.expirationDate} <br/>
                    <label>{translate('Note')}: </label> {document.note} <br/>
                </Col>
            </Row>'
            <Row>
                <div className="mx-auto">
                    <Button color="primary" onClick={this.showFile}> {translate("Show")} </Button>
                    <Button color="secondary" onClick={this.toggleDeleteModal}> {translate("Delete")} </Button>
                </div>
            </Row>
            <hr/>


            <Modal isOpen={this.state.showDeleteModal} toggle={this.toggle}>
                <ModalHeader
                    toggle={this.toggleDeleteModal}>{translate("Delete carrier document")}</ModalHeader>
                <ModalBody>
                    {translate("Do you really want to delete this document?")}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                            onClick={this.toggleDeleteModal}>{translate("Cancel")}</Button>
                    <Button color="primary"
                            onClick={this.deleteFile}>{translate("Delete")}</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
        )
    }
}


export default connect()(DocumentDetail);