import React, {useEffect, useState} from 'react';
import {Button, Col, FormGroup, Input, InputGroup, InputGroupText, Row} from "reactstrap";
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types';

import SelectCountry from "../OrderList/OrderSearcher/SelectCountry";
import { translate } from 'services/translationService';
import { history } from 'helpers';

const FILTER_STATE_KEY = 'inquiriesSearchAdvanced'

export const InquiriesFilter = ({ type, resetPage, searchParams }) => {

  const hasSearchParams = !isEmpty(searchParams)

  const [isSearchVisible, setIsSearchVisible] = useState(window.localStorage.getItem(FILTER_STATE_KEY) === 'visible' || hasSearchParams)

  const toggleSearchPanel = () => {
    isSearchVisible && hasSearchParams && clearForm()
    window.localStorage.setItem(FILTER_STATE_KEY, isSearchVisible ? 'hide' : 'visible' )
    setIsSearchVisible(!isSearchVisible)
  }

  const clearForm = () => {
    document.getElementById('searchForm').reset();
    resetPage();
    history.push(`/inquiries/${type}`);
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let queryString = "?";
    let formObject = {};
    for (const [key, value] of formData.entries()) {
      if (value) {
        formObject[key] = value;
        queryString += key + "=" + value + "&";
      }
    }
    resetPage();
    queryString  = queryString.substr(0, queryString.length-1);
    history.push("/inquiries/"+type  + queryString);
  }

  if (!isSearchVisible) {
    return (
      <div className="search-panel">
        <span onClick={toggleSearchPanel} className="link">
          {translate('Show search')}
        </span>
      </div>
    )
  }

  return (
    <form onSubmit={onFormSubmit} id="searchForm">
      <div className="search-panel advanced-search">
        <Row>
          <Col>
            <span onClick={toggleSearchPanel} className="link">
              {translate('Hide search')}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h6>{translate('Pickup')}</h6>
            <FormGroup>
              <InputGroup size="sm">
                <InputGroupText>
                  {translate('From')}
                </InputGroupText>
                <Input
                  type="date"
                  name="pickupDateFrom"
                  defaultValue={searchParams.pickupDateFrom}
                />
                <InputGroupText>{translate('To')}</InputGroupText>
                <Input
                  type="date"
                  name="pickupDateTo"
                  defaultValue={searchParams.pickupDateTo}
                />
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <InputGroup size="sm">
                <InputGroupText>{translate('Country')}</InputGroupText>
                <SelectCountry name="pickupCountryCode" defaultValue={searchParams.pickupCountryCode}/>
                <InputGroupText>{translate('Post code')}</InputGroupText>
                <Input type="text" name="pickupPostCode"
                       placeholder={translate('Post code')}
                       defaultValue={searchParams.pickupPostCode}/>
              </InputGroup>
            </FormGroup>
          </Col>

          <Col md={6}>
            <h6>{translate('Delivery')}</h6>
            <FormGroup>
              <InputGroup size="sm">
                <InputGroupText>
                  {translate('From')}
                </InputGroupText>
                <Input
                  type="date"
                  name="deliveryDateFrom"
                  defaultValue={searchParams.deliveryDateFrom}
                />
                <InputGroupText>{translate('To')}</InputGroupText>
                <Input
                  type="date"
                  name="deliveryDateTo"
                  defaultValue={searchParams.deliveryDateTo}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup size="sm">
                <InputGroupText>{translate('Country')}</InputGroupText>
                <SelectCountry name="deliveryCountryCode" defaultValue={searchParams.deliveryCountryCode}/>

                <InputGroupText>{translate('Post code')}</InputGroupText>
                <Input type="text" name="deliveryPostCode"
                       placeholder={translate('Post code')}
                       defaultValue={searchParams.deliveryPostCode}/>
              </InputGroup>
            </FormGroup>
          </Col>

        </Row>

        <Row>
          <Col className="text-right">
            <span onClick={clearForm} className="link mr-2">{translate('Reset search data')}</span>
            <Button type="submit">{translate('Search')} <i
              className="nc-icon nc-zoom-split"/></Button>
          </Col>
        </Row>
      </div>
    </form>
  )
}

InquiriesFilter.propTypes = {
  type: PropTypes.string,
  resetPage: PropTypes.func.isRequired,
  searchParams: PropTypes.object,
}

export default InquiriesFilter;
