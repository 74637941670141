
export const fileHelper = {
    blobToText,
    extractFileNameFromHeader
};

async function blobToText(blob) {
    const result = await (new Response(blob)).text();
    return result;
}

function extractFileNameFromHeader(header){
    const content = header['content-disposition'];
    const filename = content.substring(content.indexOf("filename=") + 10, content.length - 1);
    return filename;
}
