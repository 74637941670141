import React, {Component} from 'react';
import {translate} from "../../../services/translationService";
import "./styles.scss"
import {Input} from "reactstrap";
import {getCountryName} from "../../../constants/countries";
import {getInputErrorClass} from "../../../helpers/formHelper";

class DeliveryRules extends Component {

  constructor(props) {
    super(props);
    this.updateDeliveryRules = this.updateDeliveryRules.bind(this)
  }

  updateDeliveryRules(e) {
    let newInputs = {...this.props.configInputs};
    const inoutIsCheckbox = e.target.type === 'checkbox';
    const inputValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const inputName = e.target.name;
    if (inoutIsCheckbox) {
      const countryCode = inputName.split('-')[1];
      newInputs.avoidCountries[countryCode] = inputValue;
    } else {
      newInputs[inputName] = inputValue;
    }
    this.props.updateInputs(newInputs)
  }

  render() {
    const {configInputs, avoidCountries} = this.props;
    return <>
      <div className="centered"><h5 className="cooperation-title">{translate("Delivery rules")}</h5></div>
      <div className="delivery-input">
        <span>{translate("Min days after pickup")}</span>
        <Input
          name="minDaysAfterPickupForDelivery"
          id="minDaysAfterPickupForDelivery"
          type="number"
          step="1"
          min="0"
          value={configInputs["minDaysAfterPickupForDelivery"]}
          className={getInputErrorClass(configInputs["minDaysAfterPickupForDelivery"])}
          onChange={this.updateDeliveryRules}
        />
      </div>
      <div className="delivery-input">
        <span>{translate("Max days after pickup")}</span>
        <Input
          name="maxDaysAfterPickupForDelivery"
          id="maxDaysAfterPickupForDelivery"
          type="number"
          step="1"
          min="0"
          value={configInputs["maxDaysAfterPickupForDelivery"]}
          className={getInputErrorClass(configInputs["maxDaysAfterPickupForDelivery"])}
          onChange={this.updateDeliveryRules}
        />
      </div>
      {avoidCountries.length > 0 && <>
        <div className="centered mt-2"><h5 className="cooperation-title">{translate("Avoid countries")}</h5></div>
        {avoidCountries.map((country, key) => <div className="services-row" key={key}>
          <div>
            <Input
              name={`avoidCountries-${country.country_code}`}
              id={`avoidCountries-${country.country_code}`}
              checked={configInputs.avoidCountries[country.country_code]}
              type="checkbox"
              onChange={this.updateDeliveryRules}
            />
          </div>
          <div>{getCountryName(country.country_code)}</div>
        </div>)}
      </>}
    </>
  }
}

export default DeliveryRules