import React from "react";
import {connect} from "react-redux";
import MediaQuery from 'react-responsive';
import {Button, Col, Row, Table} from "reactstrap";
import {translate} from 'services/translationService';
import Paginator from "components/Paginator/Paginator";
import Loader from "components/Loader/Loader";
import {alertActions, getCarSets, getSoloTrucks, getUsers, paginationActions} from "../../redux/actions";
import RoleBadge from "../Users/RoleBadge";
import AssignCarModal from "./Car/AssignCarModal";
import {apiService} from "../../services/apiService";
import {getCars} from "../../redux/actions/car/cars.actions";
import getErrorText from "../../services/errorTextService";
import {pageDidChange} from "../../helpers/paginator";

class Drivers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAssignCarModal: false,
            selectedDriver:null
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePerPageChange = this.handlePerPageChange.bind(this);
        this.toggleAssignCarModal = this.toggleAssignCarModal.bind(this);
        this.connectCarToDriver = this.connectCarToDriver.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (pageDidChange(prevProps.pageParams,  this.props.pageParams))
        {
            this.props.dispatch(getUsers({
                perPage: this.props.pageParams.perPage,
                page: this.props.pageParams.page
            }));
        }
    }

    fetchData() {
        this.props.dispatch(getUsers({
            role: "ROLE_DRIVER",
            page: this.props.pageParams.page,
            perPage: this.props.pageParams.perPage
        }));
        this.props.dispatch(getCars('NO_TRUCK_TRAILER',{
            MaxOneDriverAssigned:true,
            perPage:999
        }));
    }

    toggleAssignCarModal() {
        this.setState({
            showAssignCarModal: !this.state.showAssignCarModal
        });
    }

    handlePageChange(pageNumber, indexStart) {
        this.props.dispatch(paginationActions.pushChangePage('truckTrailersPaginator',pageNumber, indexStart));
    }

    handlePerPageChange(perPage) {
        this.props.dispatch(paginationActions.changePerPage('truckTrailersPaginator',perPage));
    }

    assignCar(driver) {
        this.setState({
            showAssignCarModal: true,
            selectedDriver: driver
        });
    }

    connectCarToDriver(car) {
        apiService.post('api/vehicles/drivers', {
            car: {
                id: car.id,
            },
            driver: {
                id: this.state.selectedDriver.id
            }
        })
            .then(response => {
                this.setState({
                    showAssignCarModal: false,
                    selectedDriver: null
                });
                this.props.dispatch(alertActions.success(translate('Car added successfully!')));
                this.fetchData();
                this.props.dispatch(getCarSets({
                        page: this.props.carSetsPageParams.page,
                        perPage: this.props.carSetsPageParams.perPage
                    }
                ));
                this.props.dispatch(getSoloTrucks({
                    page: this.props.soloTruckPageParams.page,
                    perPage: this.props.soloTruckPageParams.perPage
                }));
                this.props.dispatch(getCars('NO_TRUCK_TRAILER',{
                    MaxOneDriverAssigned:true,
                    perPage:999
                }))
            }).catch(error => {
            this.setState({
                showAssignCarModal: false,
                selectedDriver: null
            });
            this.props.dispatch(alertActions.error(getErrorText(error)));
        });
    }

    renderDriverRow(driver, i) {
        return(
            <tr className="car-row" key={i}>
                <td>{driver.name} {driver.surname} </td>
                <td>{driver.vehicleSet?
                    <span>{driver.vehicleSet.car.brandName} {driver.vehicleSet.car.modelName} {driver.vehicleSet.car.registrationNumber}</span> :
                    <Button className="btn btn-success btn-sm ml-4" onClick={()=>this.assignCar(driver)}>
                        {translate("Add car")}
                    </Button> }
                </td>
                <td><RoleBadge roles={driver.roles}/></td>
            </tr>
        )
    }

    render() {
        if (this.props.isFetching) {
            return (
                <div className="content">
                    <Loader/>
                </div>
            )
        }
        const {drivers, meta, cars, isFetchingCars, carsError} = this.props;
        const {selectedDriver} = this.state;
        const pagesCount = Math.ceil(meta.total / meta.perPage);
        const page = this.props.pageParams.page;
        const indexStart = this.props.pageParams.indexStart;
        return (
            <div className="content">
                <Row>
                    <Col md={6}>
                        <h4>{translate("Drivers")}</h4>
                        <Table>
                            <thead>
                            <tr>
                                <th>{translate('Name')}</th>
                                <th>{translate('Assigned Car')}</th>
                                <th>{translate('Role')}</th>
                            </tr>
                            </thead>
                            <tbody>
                                {drivers.map((driver, i) => {
                                    return this.renderDriverRow(driver, i)
                                })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                {pagesCount > 1 && <MediaQuery minDeviceWidth={860}>
                    {(matches) => {
                        let maxPages = matches ? 10 : 3;

                        return <Paginator
                            pageChangeHandler={this.handlePageChange}
                            perPageHandler={this.handlePerPageChange}
                            pagesCount={pagesCount}
                            currentPage={page}
                            perPage={this.props.pageParams.perPage}
                            indexStart={indexStart}
                            maxPages={maxPages}
                        />
                    }}
                </MediaQuery>}
                <AssignCarModal
                    toggleModal={this.toggleAssignCarModal}
                    showModal={this.state.showAssignCarModal}
                    cars={cars}
                    driver={selectedDriver}
                    isFetching={isFetchingCars}
                    error={carsError}
                    connectToDriver = {this.connectCarToDriver}
                />
            </div>
        )
    }
}


Drivers.defaultProps = {
    drivers: [],
    meta: {},
    isFetching: true,
    error: false,
    cars:[],
    isFetchingCars: true,
    carsError: null
};

const mapStateToProps = (state) => {
    return {
        drivers: state.usersReducer.accounts,
        isFetching: state.usersReducer.isFetching,
        error: state.usersReducer.error,
        meta: state.usersReducer.meta,
        pageParams: state.paginationReducer.userPaginator,
        carSetsPageParams: state.paginationReducer.carSetsPaginator,
        soloTruckPageParams: state.paginationReducer.soloTrucksPaginator,
        cars: state.carsReducer.cars,
        isFetchingCars: state.carsReducer.isFetching,
        carsError: state.carsReducer.error,
    };
};


export default connect(mapStateToProps)(Drivers);
