export function sortArrayOfObjects(array, key, direction = 'asc') {
  return array.slice().sort((a, b) => {
    const aValue = a[key].toString().toLowerCase();
    const bValue = b[key].toString().toLowerCase();

    if (aValue < bValue) {
      return direction === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
}