import {updateSyncErrors} from "redux-form";
import {createApiSyncError} from "../../../helpers/ApiSyncErrors";
import getErrorText from "../../../services/errorTextService";
import { apiService } from "services/apiService";
import {alertActions} from "redux/actions";
import { translate } from 'services/translationService';
import {getInquiry} from './inquiry.actions'
import {history} from "../../../helpers";

export const CREATE_OFFER_REQUEST = 'CREATE_OFFER_REQUEST';
export const CREATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS';
export const CREATE_OFFER_FAILURE = 'CREATE_OFFER_FAILURE';


export function createInquiryOffer(inquiryId, offer) {
  return dispatch => {
    dispatch(request());
    apiService.post(`/api/inquiries/${inquiryId}/offer`, offer)
      .then(
        response => {
          dispatch(success(response.data));
          dispatch(alertActions.success(translate('Offer successfully created!')));
          history.push('/inquiries/pending');
        })
      .catch(errorMessage => {
          dispatch(updateSyncErrors('createOfferForm',createApiSyncError(errorMessage)));
          dispatch(failure(getErrorText(errorMessage)));
          dispatch(alertActions.error(getErrorText(errorMessage)));
        }
      )
  };

  function request() { return { type: CREATE_OFFER_REQUEST,  } }
  function success(data) { return { type: CREATE_OFFER_SUCCESS, data } }
  function failure(error) { return { type: CREATE_OFFER_FAILURE, error } }
}
