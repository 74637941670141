import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Card, CardBody, CardTitle, Table} from "reactstrap";
import Loader from "components/Loader/Loader";
import {translate} from 'services/translationService';
import loggedUserService from "services/loggedUserService";
import './OrderDetails.scss';
import {Link} from "react-router-dom";
import CustomInput from "reactstrap/es/CustomInput";
import {apiService} from "../../services/apiService";
import {alertActions} from "../../redux/actions";
import HelperButton from "../../components/Helper/HelperButton";

class RouteDetails extends Component {

    constructor(props) {
        super(props);
        const routeOptions = this.props.order.routeOptions;
        this.state = {
            routeOptions,
            isUpdatingOptions:false
        }
        this.changeNoExtraLoading = this.changeNoExtraLoading.bind(this);
    }

    async changeNoExtraLoading(){
        await this.setState({
            isUpdatingOptions:true,
            routeOptions: {
                ...this.state.routeOptions,
                noExtraLoading: !this.state.routeOptions.noExtraLoading
            }
        });
        const {routeOptions} = this.state;
        const routeId = this.props.order.routeId;
        const carrierId = loggedUserService.getCarrierId();
        apiService.put(`/api/maps/routes/${routeId}/carriers/${carrierId}/options`, routeOptions)
            .then(response => {
                this.setState({
                    isUpdatingOptions: false,
                    routeOptions: {
                        ...this.state.routeOptions,
                        noExtraLoading: response.data.noExtraLoading
                    }
                });
            }).catch(errorMessage => {
                console.log(errorMessage);
            this.setState({
                isUpdatingOptions: false,
            });
            this.props.dispatch(alertActions.error(translate("Error during route update")));
        })
    }

    render() {
        const {showRoute, order} = this.props;
        const {isUpdatingOptions, routeOptions} = this.state;
        return (
            <Card>
                <CardTitle>
                    <div className={"separator"}>{translate("Route details")}</div>
                    <HelperButton helperId="carrier-order-details-route" style={{top:'5px',right:'5px'}}/>
                </CardTitle>
                <CardBody className="table-full-width">
                    <Table hover>
                        <tbody>
                        <tr>
                            <th>{translate("Route details")}</th>
                            <td>{showRoute ? <Button color="success" size="sm" onClick={this.props.toggleRouteModal}>
                                {translate("Show Route")}
                            </Button> : translate("No route assigned")}
                            </td>
                        </tr>
                        {showRoute && <>
                            <tr>
                                <th>{translate("Related orders")}</th>
                                <td>
                                    {order.relatedOrders.map((relatedOrder, key) => {
                                        return <Link to={`/orders/${relatedOrder.orderId}`} key={key} className="button-link no-link">
                                           <i className="nc-icon nc-zoom-split mr-1"></i>{relatedOrder.shipmentNumber}
                                        </Link>
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <th>{translate("No extra loading on route")}</th>
                                <td>
                                    {isUpdatingOptions ? <Loader /> : <CustomInput type="switch" id="option-switch-1"
                                                 checked={routeOptions.noExtraLoading}
                                                 onChange={() => {
                                                     this.changeNoExtraLoading()
                                                 }}
                                    />}
                                </td>
                            </tr>
                        </>
                        }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        );
    }
}

export default connect()(RouteDetails);

