import React, {useEffect, useRef, useState} from 'react';
import {translate} from "../../services/translationService";
import {Badge, Table} from "reactstrap";
import {getMadeByFullName} from "../../services/offerService";
import {dateTime} from "../../utils/formatters";
import {useInterval} from "../../hooks/useInterval";
import {apiService} from "../../services/apiService";

const InquiryMessages = ({messages, inquiryId}) => {

    const listRef = useRef(null);
    const [messagesList, setMessagesList] = useState(messages);

    useInterval(()=>{
        apiService.get('/api/inquiries/' + inquiryId)
            .then(
                response => {
                    const inquiry = response.data;
                    if ('messages' in inquiry.offer){
                        const newMessages = inquiry.offer.messages;
                        if(newMessages.length !== messagesList.length){
                            setMessagesList(newMessages)
                        }
                    }
                })
            .catch(errorMessage => {
                console.log('error refreshing inquiry')
            });
    }, 10000)

    const ReadIcon = ({message}) => {

        let iconClassName = "message-icon";
        if ((message.isResponse && message.isReadByCarrier) || (message.isReadByCustomer)) {
            iconClassName += " message-read"
        }
        return <div className={iconClassName}>
            <i className={"nc-icon nc-check-2"}/>
        </div>
    }

    useEffect(()=>{
        const {scrollHeight} = listRef.current;
        if (listRef.current !== null &&  scrollHeight > 0){
            listRef.current.scrollTo(0, scrollHeight);
        }
    })

    const Message = ({message}) => {
        const {content, isResponse, sendBy, sentAt, price} = message
        const className = isResponse ? "message response" : "message";
        return <div className={className}>
            <div className={"message-box"}>
                <Table className="message-table">
                    <tbody>
                    <tr>
                        {isResponse ? <th></th> :
                            <th style={{fontSize: "11px"}}>
                                <strong>{getMadeByFullName(sendBy)}</strong>
                            </th>
                        }
                        <td className={"message-date"}>{dateTime(sentAt)}</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>{content}</th>
                    </tr>
                    {price && <tr>
                        <th colSpan={2}>{translate("New price")}{" "}<Badge color={"primary"}>{`${price} €`}</Badge>
                        </th>
                    </tr>}
                    </tbody>
                </Table>
            </div>
            {/*<ReadIcon message={message} isResponse={isResponse}/>*/}
        </div>
    }

    return <div className="chat-messages" ref={listRef}>
        {messagesList.map((message, key) => <Message message={message} key={key}/>)}
    </div>
}

export default InquiryMessages