function getErrorText(responseData) {
    console.log({
        'responseData': responseData
    });
    if (!responseData || !responseData.hasOwnProperty('data') || !responseData.data.hasOwnProperty('message')){
        return '';
    }
    let errorInfo = responseData.data.message;
    console.log({
        'errorInfo':errorInfo
    });
    if (!errorInfo) {
        return ""
    }
    let message = '';
    if (!Array.isArray(errorInfo.error)) {
        errorInfo = errorInfo.error;
        if (typeof errorInfo === "string" || errorInfo instanceof String) {
            return errorInfo;
        } else {
            let messages = Object.values(errorInfo);
            messages.forEach(msg => {
                message = message.concat(msg,'\n');
            });
        }
    }
    if (Array.isArray(errorInfo.error)) {
        const errors = Object.keys(errorInfo);
            errors.forEach((error) => {
                errorInfo[error].forEach((errorMessage) => {
                    message = message.concat(errorMessage,'\n');
                });
            });
    }

    return message;
};

export default getErrorText;
