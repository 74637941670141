
const validate = (values) => {
    const errors = {};

    if (!values.registrationNumber) {
        errors.registrationNumber = 'Registration number is required';
    }

    if (!values.productionYear) {
        errors.productionYear = 'Production year is required';
    } else if (isNaN(values.productionYear)) {
        errors.productionYear = 'Production year has to be number';
    }

    if (!values.brand) {
        errors.brand = 'Brand is required';
    }

    if (!values.model) {
        errors.model = 'Model is required';
    }

    return errors;
};

const editCarValidation = (values) => {
    const errors = validate(values);

    return errors;
};

export { editCarValidation };
