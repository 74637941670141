import { store } from 'helpers';

export function translate( phrase ) {
    const translationObject = (store.getState().translationReducer.translations) ? store.getState().translationReducer.translations : {};
    const language = store.getState().translationReducer.locale;


    if ( language !== 'en' && phrase in translationObject) {
        return translationObject[phrase];
    } else {
        return phrase;
    }
}
