export const SELECT_SPECIFICATION = 'SELECT_SPECIFICATION';
export const CLEAR_SPECIFICATION = 'CLEAR_SPECIFICATION';


export function selectSpecification(specification) {
    return dispatch => {
         dispatch(success(specification));
    };
    function success(data) { return { type: SELECT_SPECIFICATION, data } }
}

export function clearSpecification() {
    return dispatch => {
        dispatch(clear());
    };
    function clear(data) { return { type: CLEAR_SPECIFICATION} }
}

