import React from "react";
import {connect} from "react-redux";
import {alertActions, getApiNotificationsPreferences} from "../../../../redux/actions";
import Loader from "../../../../components/Loader/Loader";
import CustomInput from "reactstrap/es/CustomInput";
import _ from "lodash";
import {Button} from "reactstrap";
import {translate} from "../../../../services/translationService";
import {apiService} from "../../../../services/apiService";
import {notificationApiService} from "../../../../services/notificationApiService";


class NotificationForm extends React.Component {

    constructor(props) {
        super(props);
        this.updatePreferenceForm = this.updatePreferenceForm.bind(this);
        this.submitPreferenceForm = this.submitPreferenceForm.bind(this);
        this.state = {
            notificationSetups:{},
            isUpdating:false
        };
    }

    componentDidMount() {
        this.props.dispatch(getApiNotificationsPreferences())
    }

    async componentDidUpdate(prevProps) {
        const {notificationPreferences} = this.props;
        if (JSON.stringify(notificationPreferences) !== JSON.stringify(prevProps.notificationPreferences) ||
            (this.props.isLoading !== prevProps.isLoading && notificationPreferences)){
            let newSetups = {...this.state.notificationSetups}
            notificationPreferences.forEach(setup => {
                    newSetups[setup.id_notification] = {
                        email: setup.email,
                        name: setup.name,
                        push: setup.push,
                        simple: setup.simple,
                        webpush: setup.webpush,
                    }
                })
           await this.setState({notificationSetups:newSetups});
        }
    }

    updatePreferenceForm(e) {
        const setupId = e.target.id.split('-')[2];
        const setupName = e.target.id.split('-')[0];
        const setupValue = e.target.checked;
        let modifiedSetups = {...this.state.notificationSetups};
        modifiedSetups[setupId] = {
            ...modifiedSetups[setupId],
            [setupName]:setupValue
        }
        this.setState({notificationSetups:modifiedSetups})
    }

    submitPreferenceForm() {
        const {notificationSetups} = this.state;
        const {toggle} = this.props;
            let preferencesData = {
                email: this.props.userData.email,
                notifications_preferences: []
                }
             for (const preferenceId in notificationSetups){
                 preferencesData.notifications_preferences.push({
                     id_notification:parseInt(preferenceId),
                     simple: notificationSetups[preferenceId].simple,
                     email: notificationSetups[preferenceId].email,
                     push: notificationSetups[preferenceId].push,
                     webpush: notificationSetups[preferenceId].webpush
                 })
             }
        this.setState({isUpdating:true});
        notificationApiService.post(`api/preferences`, preferencesData)
            .then(response => {
                this.props.dispatch(alertActions.success(translate("Notification preferences updated")));
                toggle();
            }).catch(errorMessage => {
            console.log(errorMessage);
            this.props.dispatch(alertActions.error(translate("Error notification preferences update")));
            this.setState({isUpdating:false});
            toggle();
        })
    }

    render() {
        const {isLoading, notificationPreferences} = this.props;
        const {notificationSetups, isUpdating} = this.state;
        return (isLoading || _.isEmpty(notificationSetups)) ? <Loader /> :<div className="setting-content">
            <div className="setting-container">
                <div className="single-setting">
                    <div className="setting-header">Notification</div>
                    <div className="setting-header " title={translate("System notification")}>System</div>
                    <div className="setting-header" title={translate("E-mail notification")}>E-mail</div>
                    <div className="setting-header" title={translate("Web browser notification")}>Web push</div>
                    <div className="setting-header" title={translate("Mobile notification")}>Push</div>
                </div>
                {notificationPreferences.map((preference, key) => <div className="single-setting" key={key}>
                    <div>{preference.name}</div>
                    <div>{<CustomInput type="switch" id={`simple-switch-${preference.id_notification}`}
                                       checked={notificationSetups[preference.id_notification].simple}
                                       disabled={preference.simple === null}
                                       onChange={this.updatePreferenceForm}
                                       className={preference.simple === null ? "disabled" : ""}
                    />
                    }</div>
                    <div>{<CustomInput type="switch" id={`email-switch-${preference.id_notification}`}
                                       checked={notificationSetups[preference.id_notification].email}
                                       disabled={preference.email === null}
                                       onChange={this.updatePreferenceForm}
                    />
                    }</div>
                    <div>{<CustomInput type="switch" id={`webpush-switch-${preference.id_notification}`}
                                       checked={notificationSetups[preference.id_notification].webpush}
                                       disabled={preference.webpush === null}
                                       onChange={this.updatePreferenceForm}
                    />
                    }</div>
                    <div> {<CustomInput type="switch" id={`push-switch-${preference.id_notification}`}
                                        checked={notificationSetups[preference.id_notification].push}
                                        disabled={preference.push === null}
                                        onChange={this.updatePreferenceForm}
                    />
                    }</div>
                </div>)}
            </div>
            <div className="settings-bottom">
                <Button onClick={this.submitPreferenceForm} className="btn btn-primary">
                    {isUpdating ? <Loader /> : translate("Save preferences")}
                </Button>
            </div>
        </div>
        }
}

const mapStateToProps = state => ({
    notificationPreferences: state.apiPreferencesReducer.preferences,
    isLoading:state.apiPreferencesReducer.isFetching,
    userData:state.userReducer.user
});

export default connect(mapStateToProps)(NotificationForm);
