import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row} from "reactstrap";
import {translate} from 'services/translationService';
import './style.scss';
import {paginationActions} from "../../redux/actions";
import {clearSearchParamsForCarsList} from "../../helpers/carsListHelper";

class CarsSearchPanel extends Component {

    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.paginatorType = "";
        switch (this.props.listType) {
            case 'carSets':
            default:
                this.paginatorType = "carSetsPaginator";
                break;
            case 'soloTrucks':
                this.paginatorType = "soloTrucksPaginator";
                break;
            case 'truckTrailers':
                this.paginatorType = "truckTrailersPaginator";
        }
    }

    onFormSubmit(e) {
        e.preventDefault();
        const {setSearchParams} = this.props;
        const formData = new FormData(e.target);
        let searchParams = {};
        for (let pair of formData.entries()) {
            searchParams[pair[0]] = pair[1] === "on" ? 1 : pair[1]
        }
        ;
        this.props.dispatch(paginationActions.resetPagination(this.paginatorType));
        setSearchParams(searchParams);
    }

    clearForm() {
        document.getElementById(`carsSearchForm-${this.props.listType}`).reset();
        let searchParams = {};
        this.props.dispatch(paginationActions.resetPagination(this.paginatorType));
        this.props.setSearchParams(searchParams);
        clearSearchParamsForCarsList(this.props.listType);
    }

    renderSearchBar() {
    const {searchParams} = this.props;
    return  <InputGroup className={"cars-search-bar"}>
        <InputGroupAddon addonType="prepend">
            <Button title={translate("Clear search")}
                    onClick={this.clearForm}>
                x</Button>
        </InputGroupAddon>
        <Input type="text" name="search"
               placeholder={translate('Search in brand, model or registration number')}
               defaultValue={searchParams.search}
        />
        <Button type="submit">{translate('Search')} <i
            className="nc-icon nc-zoom-split"/></Button>
    </InputGroup>
    }


    render() {
        const {searchParams, showAdvancedSearch} = this.props;

        return (
            <form onSubmit={this.onFormSubmit} id={`carsSearchForm-${this.props.listType}`}>
                <div className="cars-search-panel">
                    {this.props.toggleAdvanced != null ? <Row>
                        <Col>
                                <span onClick={this.props.toggleAdvanced}
                                      className="link">{!showAdvancedSearch ? translate('Show advanced search') : translate('Hide advanced search')}
                                </span>
                        </Col>
                    </Row>:""}
                    {showAdvancedSearch ?
                        <Row>
                            <Col md={8}>
                                {this.renderSearchBar()}
                                <div className="car-form-section body-type">
                                    <span className="body-type-label">{translate("Body Type")}:</span>
                                    <FormGroup check inline>
                                        <Label check><Input name="cargoOptionBodyType" type="radio" value="tilt"
                                                            defaultChecked={searchParams.cargoOptionBodyType === "tilt"}/>{translate("Tilt")}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input name="cargoOptionBodyType" type="radio" value="box"
                                                            defaultChecked={searchParams.cargoOptionBodyType === "box"}/>{translate("Box")}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input name="cargoOptionBodyType" type="radio"
                                                            value="platform"
                                                            defaultChecked={searchParams.cargoOptionBodyType === "platform"}/>{translate("Platform")}
                                        </Label>
                                    </FormGroup>
                                </div>
                                <FormGroup check className="car-form-section features">
                                    <span className="body-type-label">{translate("Features:")}</span>
                                    <Label check>
                                        <Input type="checkbox" className="invalid-feedback"
                                               name="cargoOptionADR"
                                               defaultChecked={searchParams.cargoOptionADR}/>
                                        <span className="form-check-sign"/>{translate("ADR")}
                                    </Label>
                                    <Label check>
                                        <Input type="checkbox" className="invalid-feedback"
                                               name="cargoOptionCooler"
                                               defaultChecked={searchParams.cargoOptionCooler}/>
                                        <span className="form-check-sign"/>{translate("Cooler")}
                                    </Label>
                                    <Label check>
                                        <Input type="checkbox" className="invalid-feedback"
                                               name="cargoOptionLift"
                                               defaultChecked={searchParams.cargoOptionLift}/>
                                        <span className="form-check-sign"/>{translate("Lift")}
                                    </Label>
                                    <Label check>
                                        <Input type="checkbox" className="invalid-feedback"
                                               name="cargoOptionEdscha"
                                               defaultChecked={searchParams.cargoOptionEdscha}/>
                                        <span className="form-check-sign"/>{translate("Edscha")}
                                    </Label>
                                    <Label check>
                                        <Input type="checkbox" className="invalid-feedback"
                                               name="cargoOptionPalletTruck"
                                               defaultChecked={searchParams.cargoOptionPalletTruck}/>
                                        <span className="form-check-sign"/>{translate("Pallet Truck")}
                                    </Label>
                                    <Label check>
                                        <Input type="checkbox" className="invalid-feedback"
                                               name="cargoOptionForklift"
                                               defaultChecked={searchParams.cargoOptionForklift}/>
                                        <span className="form-check-sign"/>{translate("Forklift")}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row> :
                        <Row>
                            <Col md={6}>
                                {this.renderSearchBar()}
                            </Col>
                        </Row>
                    }
                </div>
            </form>
        );
    }
}

CarsSearchPanel.defaultProps = {
    pageParams: {}
};

const mapStateToProps = state => ({
    pageParams: state.paginationReducer.pageParams,
});

export default connect(mapStateToProps)(CarsSearchPanel);
