import {
    CARRIER_IS_ACTIVE,
    USER_IS_MANAGER,
    USER_IS_ACTIVE,
    USER_IS_DRIVER,
    USER_HAS_INQUIRIES_ACCESS,
    USER_HAS_COOPERATION_ACCESS
} from "../constants/routesRestrictions";

const loggedUserService = {
    getToken: () => localStorage.getItem('authToken') || null,
    getUserData: () => JSON.parse(localStorage.getItem('user')),
    isLogged: () => Boolean(loggedUserService.getToken()),
    isCarrierActive: () => Boolean(loggedUserService.getUserData().carrierIsActive),
    hasCooperationAccess: () => Boolean(loggedUserService.getUserData().hasCooperationAccess),
    getUserId: () => {
        if (!loggedUserService.isLogged()) {
            return null;
        }
        return loggedUserService.getUserData().id;
    },
    getCarrierId: () => {
        if (!loggedUserService.isLogged()) {
            return null;
        }
        return loggedUserService.getUserData().carrierId;
    },
    getFulName: () => {
        if (!loggedUserService.isLogged()) {
            return null;
        }
        return loggedUserService.getUserData().name + ' ' + loggedUserService.getUserData().surname;
    },
    getCarrierName: () => {
        if (!loggedUserService.isLogged()) {
            return null;
        }
        return loggedUserService.getUserData().carrierName;
    },
    /* roles:   010 driver
                100 manager
                110 d + m
    */

    isDriver: () => Boolean(loggedUserService.getUserData().roles & 2),
    isDriverOnly: () => loggedUserService.getUserData().roles === 2,
    isManager: () => Boolean(loggedUserService.getUserData().roles & 4),
    isUserActive: () => Boolean(loggedUserService.getUserData().status === 'active'),
    hasInquiriesAccess: () => Boolean(loggedUserService.getUserData().hasInquiriesAccess),
    hasShipmentsConversationsAccess: () => Boolean(loggedUserService.getUserData().hasShipmentsConversationsAccess),

    restrictRoutesForUser: (routes) => {
        const isAdmin = loggedUserService.isManager();
        const isDriver = loggedUserService.isDriver();
        const isCarrierActive = loggedUserService.isCarrierActive();
        const isUserActive = loggedUserService.isUserActive();
        const hasInquiriesAccess  = loggedUserService.hasInquiriesAccess();
        const hasCooperationAccess  = loggedUserService.hasCooperationAccess();

        return routes.filter((route) => {
            if (!route.hasOwnProperty('restrictions')) {
                return true
            }
            let restricted = true;
            if (route.restrictions.indexOf(CARRIER_IS_ACTIVE) > -1) {
                restricted = restricted && isCarrierActive;
            }
            if (route.restrictions.indexOf(USER_IS_MANAGER) > -1) {
                restricted = restricted && isAdmin;
            }
            if (route.restrictions.indexOf(USER_IS_DRIVER) > -1) {
                restricted = restricted && isDriver;
            }
            if (route.restrictions.indexOf(USER_IS_ACTIVE) > -1) {
                restricted = restricted && isUserActive;
            }
            if (route.restrictions.indexOf(USER_HAS_INQUIRIES_ACCESS) > -1) {
                restricted = restricted && hasInquiriesAccess ;
            }
            if (route.restrictions.indexOf(USER_HAS_COOPERATION_ACCESS) > -1) {
                restricted = restricted && hasCooperationAccess ;
            }
            return restricted
        })
    }
};

export default loggedUserService;
