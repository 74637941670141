import {apiService} from "services/apiService";
import {alertActions} from "redux/actions";
import {translate} from "../../../services/translationService";
import getErrorText from "../../../services/errorTextService";
import {getRoute} from "../map";

export const FETCH_LIFECYCLES_REQUEST = 'FETCH_LIFECYCLES_REQUEST';
export const FETCH_LIFECYCLES_SUCCESS = 'FETCH_LIFECYCLES_SUCCESS';
export const FETCH_LIFECYCLES_FAILURE = 'FETCH_LIFECYCLES_FAILURE';
export const ADD_LIFECYCLE_REQUEST = 'ADD_LIFECYCLE_REQUEST';
export const ADD_LIFECYCLE_SUCCESS = 'ADD_LIFECYCLE_SUCCESS';
export const ADD_LIFECYCLE_FAILURE = 'ADD_LIFECYCLE_FAILURE';

export function getLifecycles(order) {
    return dispatch => {
        dispatch(request({}));
        const id = order.shipment.id;
        apiService.get(`/api/shipments/${id}/lifecycles`)
            .then(
                response => {
                    dispatch(success(response));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_LIFECYCLES_REQUEST, data } }
    function success(data) { return { type: FETCH_LIFECYCLES_SUCCESS, data } }
    function failure(error) { return { type: FETCH_LIFECYCLES_FAILURE, error } }
}

export function postLifecycle(order, data) {
    return dispatch => {
        dispatch(request({}));
        const id = order.shipment.id;
        apiService.post(`/api/shipments/${id}/lifecycles`,data)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(translate('Data successfully saved!')));
                    const routeId = order.hasOwnProperty('routeId') ? order.routeId : null;
                    if (routeId) {
                        dispatch(getRoute(routeId));
                    }
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: ADD_LIFECYCLE_REQUEST, data } }
    function success(data) { return { type: ADD_LIFECYCLE_SUCCESS, data } }
    function failure(error) { return { type: ADD_LIFECYCLE_FAILURE, error } }
}
