import {Field, Form, formValueSelector, reduxForm} from "redux-form";
import React from "react";
import {translate} from "services/translationService";
import {countriesForSelect} from "helpers/countries"
import FieldSelect from "components/FormFields/FieldSelect/FieldSelect";
import {connect} from "react-redux";
import {addDedicatedAliases, deleteDedicatedAlias, getDedicatedAliases} from "../../../redux/actions";
import Loader from "../../../components/Loader/Loader";
import {getCountryName} from "../../../constants/countries";
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {sortArrayOfObjects} from "../../../utils/sort";

class Aliases extends React.Component {

  constructor(props) {
    super(props);
    this.renderAliasElement = this.renderAliasElement.bind(this);
    this.toggleAddModal = this.toggleAddModal.bind(this);
    this.addAlias = this.addAlias.bind(this);
    this.blockAddAlias = this.blockAddAlias.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);
    this.deleteAlias = this.deleteAlias.bind(this);
    this.state = {
      sortedAliases:[],
      showAddModal: false,
      showDeleteModal: false,
      aliasToDelete: null
    }
   }

   componentDidUpdate(prevProps, prevState) {
     const {countryFrom, countryTo, isUpdatingAliases, isDeletingAlias, isFetchingAliases, aliases} = this.props;
     if (prevProps.isFetchingAliases === true && isFetchingAliases === false){
        const updatedAliases = [...aliases].map(alias=> {
          return {...alias,
            countryFromName :getCountryName(alias.alias_for_country_from)
       }})

       this.setState({
         sortedAliases: sortArrayOfObjects(updatedAliases,'countryFromName'),
       })
       return;
     }

     if (prevProps.countryFrom !== countryFrom || prevProps.countryTo !== countryTo){
        this.props.dispatch(getDedicatedAliases({countryFrom,countryTo}));
        return;
     }
     if (prevProps.isUpdatingAliases === true && isUpdatingAliases === false){
       this.props.dispatch(getDedicatedAliases({countryFrom,countryTo}))
       this.setState({
         showAddModal:false,
       });
       return;
     }
     if (prevProps.isDeletingAlias === true && isDeletingAlias === false){
       this.props.dispatch(getDedicatedAliases({countryFrom,countryTo}))
       this.setState({
         showDeleteModal: false,
         aliasToDelete: null
       })
     }
   }

  toggleAddModal() {
    this.setState({
      showAddModal:!this.state.showAddModal
    })
  }

  showDeleteModal(alias) {
    this.setState({
      showDeleteModal:true,
      aliasToDelete:alias
    })
  }

  hideDeleteModal() {
    this.setState({
      showDeleteModal:false,
      aliasToDelete:null
    })
  }

  addAlias() {
    const {aliasCountryFrom, aliasCountryTo, countryFrom, countryTo} = this.props;
    this.props.dispatch(addDedicatedAliases({aliasCountryFrom, aliasCountryTo, countryFrom, countryTo}));
  }

  deleteAlias(){
    const {country_from, country_to} = this.state.aliasToDelete;
    this.props.dispatch(deleteDedicatedAlias({country_from, country_to}));
  }

  blockAddAlias() {
    const {aliasCountryFrom, aliasCountryTo, countryFrom, countryTo} = this.props;
    const {sortedAliases} = this.state;
    return  (aliasCountryFrom === countryFrom && aliasCountryTo === countryTo) ||
      sortedAliases.some(alias => alias.country_from === aliasCountryFrom && alias.country_to === aliasCountryTo);
  }

  renderAliasElement(alias, key){
    return <div className="alias-element" key={key}>
      <span>{getCountryName(alias.country_from)}<i className="fa arrow-right" />{getCountryName(alias.country_to)}</span>
      <i className="nc-icon nc-simple-remove remove-alias" onClick={()=>this.showDeleteModal(alias)}
         title={translate("Delete alias")} />
    </div>
  }

  render() {
    const {isFetchingAliases, aliasCountryFrom, aliasCountryTo, isUpdatingAliases, isDeletingAlias} = this.props;
    const {aliasToDelete, sortedAliases} = this.state;
    if (isFetchingAliases) return <Loader />
    return <div className="aliases-form">
      <div className="aliases-list">
        {sortedAliases.map((alias,key)=>this.renderAliasElement(alias, key))}
      </div>
      <div className="centered add-alias"><h5>{translate("Add new alias")}</h5></div>
        <Form onSubmit={()=>{}}>
          <Row className="form-row">
            <Col md={6}>
              <Field
                inputId="aliasCountryFrom"
                name="aliasCountryFrom"
                options={countriesForSelect(this.props.countries)}
                label={translate("Country from")}
                component={FieldSelect}
                props={{emptyValue: false}}
              />
            </Col>
            <Col md={6}>
              <Field
                inputId="aliasCountryTo"
                name="aliasCountryTo"
                options={countriesForSelect(this.props.countries)}
                label={translate("Country to")}
                component={FieldSelect}
                props={{emptyValue: false}}
              />
            </Col>
          </Row>
        </Form>
      <div className="aliases-button centered">
        <Button color="primary" size={"sm"} onClick={this.toggleAddModal} disabled={sortedAliases && this.blockAddAlias()}>
          {translate("Create alias")}
        </Button>
      </div>
      <Modal isOpen={this.state.showAddModal} toggle={this.toggleAddModal} size="sm" className={"add-alias-modal"}>
        <ModalHeader toggle={this.toggleAddModal}>
          {translate("New alias creation")}
        </ModalHeader>
        <ModalBody>
          <p>{translate("You are creating new alias")}:</p>
          <div className="alias-new-element">{getCountryName(aliasCountryFrom)}<i className="fa arrow-right" />{getCountryName(aliasCountryTo)}</div>
          <p>{translate("Do you want to proceed? Existing prices will be deleted.")}</p>
          <div className="alias-button-container">
            <Button color="primary" size={"sm"} onClick={this.addAlias} >
              {isUpdatingAliases ? <Loader /> : translate("Create alias")}
            </Button>
            <Button color="secondary" size={"sm"} onClick={this.toggleAddModal} disabled={isUpdatingAliases}>
              {translate("Cancel")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={this.state.showDeleteModal} size="sm" className={"add-alias-modal"}>
        <ModalHeader toggle={this.hideDeleteModal}>
          {translate("Delete existing alias")}
        </ModalHeader>
        <ModalBody>
          <p><i className="fa exclamation" />{translate("You are deleting alias")}:</p>
          <div className="alias-new-element">
            {aliasToDelete && <>{getCountryName(aliasToDelete.country_from)}
              <i className="fa arrow-right" />
              {getCountryName(aliasToDelete.country_to)}</>}
          </div>
          <p>{translate("Do you want to proceed? Alias will be permanently deleted.")}</p>
          <div className="alias-button-container">
            <Button color="danger" size={"sm"} onClick={this.deleteAlias} >
              {isDeletingAlias ? <Loader /> : translate("Delete alias")}
            </Button>
            <Button color="secondary" size={"sm"} onClick={this.hideDeleteModal} disabled={isUpdatingAliases}>
              {translate("Cancel")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  }
}

Aliases.defaultProps = {
  aliases: [],
  isFetchingAliases: false,
  isUpdatingAliases: false,
  isDeletingAlias: false,
  error: false,
};

const aliasCountrySelector = formValueSelector('aliasCountryForm');
const selector = formValueSelector('countriesSelectorForm');

Aliases = reduxForm({
  form: 'aliasCountryForm',
  enableReinitialize: true
})(Aliases);

const mapStateToProps = (state) => {
  return {
    countryFrom: selector(state, 'countryFrom'),
    countryTo: selector(state, 'countryTo'),
    aliasCountryFrom: aliasCountrySelector(state, 'aliasCountryFrom'),
    aliasCountryTo: aliasCountrySelector(state, 'aliasCountryTo'),
    aliases: state.dedicatedAliasesReducer.aliases,
    isFetchingAliases: state.dedicatedAliasesReducer.isFetchingAliases,
    isUpdatingAliases: state.dedicatedAliasesReducer.isUpdatingAliases,
    isDeletingAlias: state.dedicatedAliasesReducer.isDeletingAlias,
    error: state.dedicatedAliasesReducer.error,
  };
};

export default connect(mapStateToProps)(Aliases);