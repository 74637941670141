import {
    FETCH_API_PREFERENCES_FAILURE,
    FETCH_API_PREFERENCES_REQUEST,
    FETCH_API_PREFERENCES_SUCCESS
} from "../../actions";

const initialState = {
    preferences: [],
    isFetching: true,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_API_PREFERENCES_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
            });
        }

        case FETCH_API_PREFERENCES_SUCCESS: {
            return Object.assign({}, state, {
                preferences: action.data.data,
                isFetching: false,
            });
        }

        case FETCH_API_PREFERENCES_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }
        default:
            return state
    }
}
