import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {connect} from 'react-redux';
import {getUserDocuments} from 'redux/actions/users';
import {translate} from 'services/translationService';
import DocumentTypeCard from 'components/Documents/DocumentTypeCard';
import Badge from "reactstrap/es/Badge";
import Loader from "../../../../../components/Loader/Loader";
import CardHeader from "reactstrap/es/CardHeader";
import HelperButton from "../../../../../components/Helper/HelperButton";

class UserDocuments extends React.Component {

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getUserDocuments(this.props.userId));
    }

    getDocumentsByType(documentType, documents) {
        return documents.filter((document) => {
            return document.documentType.id === documentType.id;
        })
    }

    render() {
        const { isFetching, userDocuments, documentTypes, error } = this.props;

        return (
            <Card>
                <CardHeader className="card-user">
                    <h4><i className="nc-icon nc-briefcase-24" /> {translate('Documents')} {"    "}
                        <Badge color="warning">{translate('Required')}</Badge>{"  "}
                        <Badge color="secondary">{translate('Additional')}</Badge>{"  "}
                    </h4>
                    <hr />
                </CardHeader >
                <CardBody>
                    <Row>
                        {
                            (!isFetching && !error && documentTypes.length > 0) ?
                                documentTypes.map((documentType, i) => {
                                    return (
                                        <Col lg={3} md={4} key={i}>
                                            <DocumentTypeCard documentType={documentType} userId={this.props.userId} documents={this.getDocumentsByType(documentType, userDocuments)} key={i}/>
                                        </Col>
                                    )
                                })
                                :
                                <Loader/>
                        }
                    </Row>
                </CardBody>
                <HelperButton helperId="carrier-user-documents" />
            </Card>
        )
    }
}

UserDocuments.defaultProps = {
    userDocuments: [],
    documentTypes: [],
    isFetching: null,
    error: false,
};

const mapStateToProps = state => ({
    userDocuments: state.userDocumentReducer.userDocuments,
    documentTypes: state.userDocumentReducer.documentTypes,
    isFetching: state.userDocumentReducer.isFetching,
    error: state.userDocumentReducer.error,
});

export default connect(mapStateToProps)(UserDocuments);
