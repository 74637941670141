import React from "react";
import {translate} from "../../../services/translationService";
import PriceFormat from "../../../components/PriceFormat/PriceFormat";

const CashWarning = ({shipment}) => {
    return (
        <div
            id={"cashOnDeliveryReminder"}
            className={"w-100 alert-warning p-3 mb-2 text-center"}
        >
            {translate("Have you taken cash for delivery ? You should got:")}
            <strong>
                <PriceFormat price={shipment.codAmount} currency={shipment.currency} />
            </strong>
        </div>
    )
};

export default CashWarning;