import React from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardTitle} from "reactstrap";
import UploadDocument from "./UploadDocument";
import {translate} from "services/translationService";
import './DeliveryDocument.scss';
import {getOrderDocuments} from "../../../redux/actions/documents/orderDocuments.actions";
import DocumentsList from "./DocumentsList";
import Loader from "../../../components/Loader/Loader";

class DeliveryDocuments extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(getOrderDocuments(this.props.order.id));
  }


  render() {
    const {id,number} = this.props.order.shipment;
    const orderId = this.props.order.id;
    const relatedOrders = this.props.order.relatedOrders || null;
    const {orderDocuments, isFetchingDocuments, error} = this.props;

    return <Card>
      <CardTitle>
        <div className={"separator"}>{translate("Documents")}</div>
      </CardTitle>
      <CardBody className="delivery-documents-content">
        <div className="documents-list">
          {isFetchingDocuments ? <Loader />
            : <DocumentsList orderDocuments={orderDocuments} />
          }
        </div>
        <div className="document-upload">
          <UploadDocument
            shipmentId={id}
            orderId={orderId}
            relatedOrders={relatedOrders}
            shipmentNumber={number}
          />
        </div>
      </CardBody>
    </Card>
  }
}

DeliveryDocuments.defaultProps = {
  orderDocuments: [],
  isFetchingDocuments: null,
  error: false,
};

function mapStateToProps(state) {
  return {
    orderDocuments: state.orderDocumentsReducer.orderDocuments,
    isFetchingDocuments: state.orderDocumentsReducer.isFetchingDocuments,
    error: state.orderDocumentsReducer.error,
  };
}

export default connect(mapStateToProps)(DeliveryDocuments);
