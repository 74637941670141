import {
    FETCH_CARS_REQUEST,
    FETCH_CARS_SUCCESS,
    FETCH_CARS_FAILURE
} from 'redux/actions/car/cars.actions.js';

const initialState = {
    message: '',
    cars: [],
    meta:{},
    isFetching: true,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CARS_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
            });
        }

        case FETCH_CARS_SUCCESS: {
            return Object.assign({}, state, {
                cars: action.data.data,
                meta: action.data.meta,
                isFetching: false,
            });
        }

        case FETCH_CARS_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }
        default:
            return state
    }
}
