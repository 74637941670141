import React, {Component} from 'react';
import './OrderDetails.scss';
import Table from "reactstrap/es/Table";
import {getCountryName} from "constants/countries";
import {translate} from 'services/translationService';
import {stringHelper} from "../../helpers/stringHelper";

class OrderDetailsAddress extends Component {


    render() {
        const address = this.props.address;
        const {typeOfCode, pickupCode, deliveryCode} = this.props;
        const addressUrl = `https://maps.google.com/?q=${stringHelper.streetToString(address.street)}, ${address.city}, ${address.postCode}`;
        return (
                <Table hover>
                    <tbody>
                    <tr>
                        <th>{translate("Name")}</th>
                        <td>{address.name}</td>
                    </tr>
                    <tr>
                        <th>{translate("Street")}</th>
                        <td>
                            <a href={addressUrl} target="_blank" rel="noopener noreferrer">{stringHelper.streetToString(address.street)}</a>
                            <a href={addressUrl} className="btn btn-success btn-sm ml-4" target="_blank" rel="noopener noreferrer">
                                <i className="nc-icon nc-map-big mr-1"></i>
                                Google Maps
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <th>{translate("Post Code")} / {translate("City")} / {translate("Country")} </th>
                        <td>{address.postCode} {address.city} {getCountryName(address.country)}</td>
                    </tr>
                    {(address.hasOwnProperty('phone') || address.hasOwnProperty('email')) &&
                        <tr>
                            <th>{translate("Phone")} /{translate("Email")} </th>
                            <td>{address.phone} / {address.email} </td>
                        </tr>
                    }

                        {pickupCode && <tr>
                            <th>{translate(typeOfCode)} </th>
                            <td>{pickupCode.toString().padStart(10,'0').replace(/(.{3})/g, "$1 ")} </td>
                        </tr>}

                        {deliveryCode && <tr>
                            <th>{translate(typeOfCode)} </th>
                            <td>{deliveryCode.toString().padStart(10,'0').replace(/(.{3})/g, "$1 ")} </td>
                        </tr>}
                      
                    </tbody>
                </Table>
        )
    }
}

export default OrderDetailsAddress;

