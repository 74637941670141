import React from "react";
import { connect } from "react-redux";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import queryString from 'query-string';
import { isEmpty } from 'lodash'
import Loader from "components/Loader/Loader";
import Fracht from "views/Cooperation/Fracht/Fracht.jsx";
import Customers from "views/Cooperation/Customers/Customers";
import Dedicated from "views/Cooperation/Dedicated/Dedicated";
import Invitations from "views/Cooperation/Invitations/Invitations";
import "./styles.scss"
import {translate} from "../../services/translationService";
import FrachtConfig from "./FrachtConfig/FrachtConfig";

class Cooperation extends React.Component {
  constructor(props) {
    super(props);
    const searchParams = queryString.parse(this.props.location.search);
    let initialActiveTab;
    if (!isEmpty(searchParams) && 'code' in searchParams ){
      initialActiveTab = "3";
      localStorage.setItem("cooperationTab", "3");
    } else {
      initialActiveTab = localStorage.getItem("cooperationTab");
    }
    if (initialActiveTab == null) {
      localStorage.setItem("cooperationTab", "1");
      initialActiveTab = "1";
    }

    this.state = {
      activeTab: initialActiveTab,
    };

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    this.setState({
      activeTab: tab,
    });
    localStorage.setItem("cooperationTab", tab);
  }

  render() {
    const { activeTab } = this.state;
    if (this.props.isFetching) {
      return (
        <div className="content">
          <Loader />
        </div>
      );
    }

    return (
      <div className="content">
        <Row>
          <Col md={12} className="mt-3">
            <Nav tabs className={"cooperation-tabs"}>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    this.toggleTab("1");
                  }}
                >
                  Fracht
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    this.toggleTab("2");
                  }}
                >
                  Dedicated
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    this.toggleTab("3");
                  }}
                >
                  {translate("Invitations")}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => {
                    this.toggleTab("4");
                  }}
                >
                  {translate("Customers")}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "5" })}
                  onClick={() => {
                    this.toggleTab("5");
                  }}
                >
                  {translate("Fracht Config")}
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Fracht />
          </TabPane>

          <TabPane tabId="2">
            <Dedicated />
          </TabPane>

          
          <TabPane tabId="3">
            <Invitations/>
          </TabPane>

          <TabPane tabId="4">
            <Customers />
          </TabPane>

          <TabPane tabId="5">
            <FrachtConfig />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.countryReducer.countries,
  isFetching: state.countryReducer.isFetching,
  error: state.countryReducer.error,
})

export default connect(mapStateToProps)(Cooperation);
