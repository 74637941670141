import {Badge} from "reactstrap";
import React from "react";
import {translate} from 'services/translationService';

export const containerFeatures = ['hasLift', 'hasCooler', 'hasForklift', 'hasPalletTruck', 'hasAdr'];

export function getContainerFeatures(container) {

    const translatedFeatures = {
        hasLift: translate("Lift"),
        hasCooler: translate("Cooler"),
        hasForklift: translate("Forklift"),
        hasPalletTruck: translate("Pallet Truck"),
        hasAdr: translate("ADR")
    };

    return containerFeatures.map((feature, key) => {
        return <span key={key}>{container[feature] === 1 ?
            <Badge color="primary">{translatedFeatures[feature]} </Badge> : ""}</span>
    })
}


export function containerHasFeatures(container) {
    let containerHasFeatures = false;
    containerFeatures.forEach(feature => {
        if (container[feature] === 1) {
            containerHasFeatures = true
        }
    });
    return containerHasFeatures;
}