import {
    FETCH_INVOICES_REQUEST,
    FETCH_INVOICES_SUCCESS,
    FETCH_INVOICES_FAILURE
} from 'redux/actions/invoices/invoices.actions.js';

const initialState = {
    invoices: {},
    meta: {},
    isFetching: true,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INVOICES_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
            });
        }

        case FETCH_INVOICES_SUCCESS: {
            return Object.assign({}, state, {
                invoices: action.data.data,
                meta: action.data.meta,
                isFetching: false,
            });
        }

        case FETCH_INVOICES_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }
        default:
            return state
    }
}
