import {apiService} from "services/apiService";
import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";

export const FETCH_REMARK_CATEGORIES_REQUEST = 'FETCH_REMARK_CATEGORIES_REQUEST';
export const FETCH_REMARK_CATEGORIES_SUCCESS = 'FETCH_REMARK_CATEGORIES_SUCCESS';
export const FETCH_REMARK_CATEGORIES_FAILURE = 'FETCH_REMARK_CATEGORIES_FAILURE';


export function getRemarkCategories() {
    return dispatch => {
        dispatch(request({}));
        apiService.get('/api/dictionaries/shipment-remark-categories')
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_REMARK_CATEGORIES_REQUEST, data } }
    function success(data) { return { type: FETCH_REMARK_CATEGORIES_SUCCESS, data } }
    function failure(error) { return { type: FETCH_REMARK_CATEGORIES_FAILURE, error } }
}
