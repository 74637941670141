import {formValueSelector, reduxForm} from "redux-form";
import React from "react";
import {connect} from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import {translate} from "../../../../services/translationService";
import LengthOptionsForm from "./LengthOptionsForm";
import {getSurchargesByLength} from "../../../../redux/actions";

class LengthOptions extends React.Component {

    componentDidUpdate(prevProps) {
        const {countryFromValue, countryToValue, dispatch} = this.props;
        if ((prevProps.countryFromValue !== countryFromValue) || (prevProps.countryToValue !== countryToValue)) {
            const surchargeData = {
                countyFrom: countryFromValue,
                countryTo: countryToValue
            }
            dispatch(getSurchargesByLength(surchargeData));
        }
    }

    render() {
        const {countryFromValue, countryToValue, surcharges, isFetching, dispatch} = this.props;
        return <>
            <div className="centered">
                <h5
                  className="cooperation-title">{`${translate("Length surcharges")} ${countryFromValue} - ${countryToValue}`} </h5>
            </div>
            {isFetching ? <Loader /> :<LengthOptionsForm
              surcharges={surcharges}
              countryFrom={countryFromValue}
              countryTo={countryToValue}
              dispatch={dispatch}
            />}
        </>
    }
}

LengthOptions.defaultProps = {
    surcharges: [],
    isFetching: null,
    error: false,
};

const selector = formValueSelector('countriesSelectorForm');

const mapStateToProps = (state) => {
    return {
        countryFromValue: selector(state, 'countryFrom'),
        countryToValue: selector(state, 'countryTo'),
        surcharges: state.lengthSurchargesReducer.surcharges,
        isFetching: state.lengthSurchargesReducer.isFetching,
        error: state.lengthSurchargesReducer.error,
    };
};

export default connect(mapStateToProps)(LengthOptions);