import {Field, Form, reduxForm} from "redux-form";
import React from "react";
import {translate} from "services/translationService";
import {countriesForSelect} from "helpers/countries"
import FieldSelect from "components/FormFields/FieldSelect/FieldSelect";
import {Col, Row} from "reactstrap";

class CountriesSelector extends React.Component {

  constructor(props) {
    super(props);
   }

  render() {

    return <Form className="cooperation-form" onSubmit={()=>{}}>
      <Row>
        <Col md={6}>
          <Field
            inputId="countryFrom"
            name="countryFrom"
            options={countriesForSelect(this.props.countries)}
            label={translate("Country from")}
            component={FieldSelect}
            props={{emptyValue: false}}
          />
        </Col>
        <Col md={6}>
          <Field
            inputId="countryTo"
            name="countryTo"
            options={countriesForSelect(this.props.countries)}
            label={translate("Country to")}
            component={FieldSelect}
            props={{emptyValue: false}}
          />
        </Col>
      </Row>
     </Form>
  }
}

CountriesSelector = reduxForm({
  form: 'countriesSelectorForm',
  enableReinitialize: true
})(CountriesSelector);

export default CountriesSelector;
