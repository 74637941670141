import React, {useRef} from 'react';
import {translate} from "../../services/translationService";
import {Badge, Table} from "reactstrap";
import {getMadeByFullName} from "../../services/offerService";
import {dateTime, fullDate} from "../../utils/formatters";
import InquiryMessages from "./InquiryMessages";
import InquiryMessageForm from "./InquiryMessageForm";

const InquiryOffer = ({inquiryData}) => {
    const {
        id:inquiryId,
        offer: {
            madeBy,
            createdAt,
            currentPrice,
            firstPrice,
            deliveryDate,
            comment,
            messages,
            id: offerId
        }
    } = inquiryData;

    const PriceBadge = ({priceValue, current = false}) => {
        return <Badge color={current ? "primary" : "danger"}
                      style={{textDecoration: current ? "none" : "line-through"}}>
            {`${priceValue} €`}
        </Badge>
    }

    return <div className="offer-container">
        <div className={"offer-details"}>
            <div className={"separator"}>
                <span>{translate("Offer data")}</span>
            </div>
            <Table hover className="offer-table">
                <tbody>
                <tr>
                    <th>{translate("Created by")}</th>
                    <td>{getMadeByFullName(madeBy)}</td>
                    <th>{translate("Added on")}</th>
                    <td>{dateTime(createdAt)}</td>
                </tr>
                <tr>
                    <th>{translate("Price")}</th>
                    <td>
                        <PriceBadge priceValue={currentPrice} current={true}/>
                        {currentPrice !== firstPrice && <PriceBadge priceValue={firstPrice}/>}
                    </td>
                    <th>{translate("Delivery date")}</th>
                    <td>{fullDate(deliveryDate)}</td>
                </tr>
                {comment && <tr>
                    <th>{translate("Comment")}</th>
                    <td colSpan={3}>{comment}</td>
                </tr>}
                </tbody>
            </Table>
            <div className={"separator"}>
                <span>{translate("Messages")}</span>
            </div>
            <div className="offer-chat-container" >
                <InquiryMessages messages={messages} inquiryId={inquiryId}/>
                {(inquiryData.state === 0) &&<InquiryMessageForm offerId={offerId} inquiryId={inquiryId}/>}
            </div>
        </div>
    </div>
}

export default InquiryOffer