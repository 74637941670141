import axios from 'axios';
import {store} from 'helpers';
import {authActions} from "../redux/actions";
import {translate} from "./translationService";
import {message} from "react-widgets/lib/util/PropTypes";
import ApiNotificationsReducer from "../redux/reducers/apiNotifications/apiNotifications.reducer";

const BASE_URL = `${process.env.REACT_APP_DOCUMENTS_API_URL}`;

export const documentsApiService = {
    get,
    post,
    put,
    downloadFile,
    uploadFile,
    getErrorText,
    getSuccessText
};

function getApiDocumentsConfig(url) {
    const config = {
        url: BASE_URL + url,
    };
    const token = localStorage.getItem('documentsApiToken') || null;
    const language = store.getState().translationReducer.locale || null;
    config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-language': language
    };
    return config;
}

function getUploadFileConfig(url, formData) {
    const config = {
        url: BASE_URL + url,
        data: formData
    };
    const token = localStorage.getItem('documentsApiToken') || null;
    const language = store.getState().translationReducer.locale || null;
    config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        'X-language': language
    };
    return config;
}

async function apiRequest(config) {
    try {
        const response = await axios(config);

        return response.statusText === 'OK' || response.status === 204
            ? Promise.resolve(response.data)
            : Promise.reject(response.data);

    } catch (error) {
        if (error.response.status === 401) {
            store.dispatch(authActions.logout());
        }
        return Promise.reject(error.response);
    }
}

async function downloadOrderDocument(config) {
    try {
        const response = await axios(config);

        if (response.status === 200)
        {
            let fileType;
            console.log(response.headers);
            switch (response.headers['content-type']){
                case 'image/jpg':
                case 'image/jpeg':
                case 'image/png':
                    fileType = 'image';
                    break;
                case 'application/pdf':
                    fileType = 'pdf';
                    break;
                default:
                    fileType = 'image'
            }
            return {
                blob: response.data,
                fileType,
                fileSize: response.headers['content-length'],
            }
        }

    } catch (error) {
        console.log({
            'error':error.response
        });
        return Promise.reject(error.response);
    }
}


async function uploadOrderDocument(config) {
    try {
        const response = await axios(config);
        if (response.status === 201)
        {
            return response.data;
        }

    } catch (error) {
        return Promise.reject(error.response);
    }
}


function get(url, data) {
    const config = {
        method: 'get',
        ...getApiDocumentsConfig(url),
        params: data
    };
    return apiRequest(config);
}

function post(url, data) {
    const config = {
        method: 'post',
        ...getApiDocumentsConfig(url),
        data
    };
    return apiRequest(config);
}

function put(url, data = {}) {
    const config = {
        method: 'put',
        ...getApiDocumentsConfig(url),
        data
    };
    return apiRequest(config);
}

function downloadFile(url) {
    const config = {
        method: 'get',
        ...getApiDocumentsConfig(url),
        responseType: 'arraybuffer'
    };
    return downloadOrderDocument(config);
}

function uploadFile(url, formData) {
    const config = {
        method: 'post',
        ...getUploadFileConfig(url, formData),
    };
    return uploadOrderDocument(config);
}

function getErrorText(apiResponse) {
    if (apiResponse.status === 404){
        return translate("We could not complete the operation, please retry")
    } else {
        const errorObject = apiResponse.data.messages.error;
        if (errorObject && typeof errorObject === 'object' ){
            for(let errorType in errorObject){
                let errorContent = errorObject[errorType];
                if (Array.isArray(errorContent) && errorContent.length > 0){
                    return errorContent[0];
                }
            }
        } else if (errorObject && Array.isArray(errorObject)){
            return errorObject[0]
        }
        return ""
    }
}

function getSuccessText(apiResponse){
    if ('messages' in apiResponse && Array.isArray(apiResponse.messages.success) && apiResponse.messages.success.length >0 ){
        return apiResponse.messages.success[0]
        }
    return "OK"
}