export const history = {
    replace: (url) => {
        document.location.hash = url.replace(/^\/+/, '');
    },
    push: (url) => {
        document.location.hash = url.replace(/^\/+/, '');
    },
    goBack: () => {
        if (typeof window !== 'undefined') {
            window.history.back();
        }
    },
    length: () => (typeof window !== 'undefined') ? window.history.length : 0
}