import { apiService } from "services/apiService";
import { alertActions } from "redux/actions";
import getErrorText from "services/errorTextService";
import {translate} from "../../../services/translationService";

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const TOGGLE_DEFAULT_ADMIN = 'TOGGLE_DEFAULT_ADMIN';
export const CONFIRM_DEFAULT_ADMIN = 'CONFIRM_DEFAULT_ADMIN';
export const REVERSE_DEFAULT_ADMIN = 'REVERSE_DEFAULT_ADMIN';

export function getUsers(params) {
    return dispatch => {
        dispatch(request({}));
        apiService.get('/api/accounts', params)
            .then(
                response => {
                    dispatch(success(response));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_USERS_REQUEST, data } }
    function success(data) { return { type: FETCH_USERS_SUCCESS, data } }
    function failure(error) { return { type: FETCH_USERS_FAILURE, error } }
}

export function updateDefaultAdmin(accountId) {
  return dispatch => {
    dispatch(toggle(accountId));
    apiService.put('/api/accounts/default/' +  accountId)
      .then(
        response => {
          dispatch(confirm(accountId));
          dispatch(alertActions.success(translate("Default manager set successfully")));
        })
      .catch(errorMessage => {
        dispatch(reverse());
        dispatch(alertActions.error(translate("Default manager was not set")));
      });
  };

  function toggle(data) { return { type: TOGGLE_DEFAULT_ADMIN, data } }
  function confirm(data) { return { type: CONFIRM_DEFAULT_ADMIN, data } }
  function reverse(data) { return { type: REVERSE_DEFAULT_ADMIN, data } }
}
