import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import {translate} from 'services/translationService';

import './style.scss';

const FieldCheckbox = ({
                         input,
                         inputId,
                         label,
                         meta: { touched, error, warning },
                         type,
                         className,
                         disabled,
                         placeholder,
                           inputClassName,
                            extraText
                     }) => (
    <FormGroup check className={className}>
        <div className="col-md-3" > <span></span>
        </div>

        <div className={inputClassName} >
            <Label check >
                <Input
                    {...input}
                    placeholder={placeholder}
                    type={type}
                    id={inputId}
                    checked={input.value}
                    disabled={disabled}
                    className={touched && (error || warning) ? 'input-invalid form-control' : 'form-control'}
                />
                <span className="form-check-sign" />
                {label ?label : ""}
            </Label>
            {
                touched &&
                (
                    (error && <span className="error invalid-feedback ml-2">{translate(error)}</span>) ||
                    (warning && <span className="warning invalid-feedback ml-2">{translate(warning)}</span>)
                )
            }
    </div>
    </FormGroup>
);

FieldCheckbox.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    inputId: PropTypes.string,
    disabled: PropTypes.bool,
    emptyValue: PropTypes.bool,
    selectedValue: PropTypes.any,
    value: PropTypes.any,
};

FieldCheckbox.defaultProps = {
    type: 'checkbox',
    placeholder: '',
    inputId: '',
    label: '',
    className: 'row custom-checkbox form-group',
    labelClassName: '',
    inputClassName: 'col-md-9',
    disabled: false,
    inputValue: '',
};

export default FieldCheckbox;
