import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {translate} from "../../../services/translationService";
import "./styles.scss";
import InvitationsList from "./InvitationsList";
import AcceptInvitation from "./AcceptInvitation";
import SendInvitation from "./SendInvitation";
import HelperButton from "../../../components/Helper/HelperButton";

class Invitations extends React.Component {
  render() {
    return (
      <div className="content">
        <Row>
          <Col md={12}>
            <Card className="firstCard">
              <CardBody>
                <div className="centered">
                  <h5 className="invitations-title">{translate("Accepting an invitation")}</h5>
                </div>
                <AcceptInvitation />
              </CardBody>
              <HelperButton helperId="carrier-invitations-acceptance" />
            </Card>
          </Col>
          <Col md={12}>
            <Card className="secondCard">
              <CardBody>
                <div className="centered">
                  <h5 className="invitations-title">{translate("Sending an invitation")}</h5>
                </div>
                <SendInvitation />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <div className="centered">
                  <h5 className="invitations-title">{translate("Sent Invitations")}</h5>
                </div>
                <InvitationsList />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Invitations;
