import {Col, Row} from "reactstrap";
import {Field} from "redux-form";
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import React from "react";
import {translate} from 'services/translationService';
import {connect} from "react-redux";
import {translateFormLabels} from "../../helpers/translateFormLabels";

class AddressContact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            labels: translateFormLabels()
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale){
            this.setState({labels: translateFormLabels()});
        }
    }

    render() {
        const {disabled} = this.props;
        const {labels} = this.state;
        return (
            <div>
            <h5>{ translate("Contact data") }</h5>
                        <Row>
                        <Col sm={6}>
                            <Field
                            name = "contact"
                            label = {labels.addressContactContact}
                            disabled = {disabled}
                            component={FieldInput}
                            />
                        </Col>
                        <Col sm={6}>
                             <Field
                              name = "phone"
                              label = {labels.addressContactPhone}
                              disabled = {disabled}
                              component={FieldInput}
                             />
                        </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Field
                                    name = "email"
                                    label = {labels.addressContactEmail}
                                    disabled = {disabled}
                                    component={FieldInput}
                                />
                            </Col>
                        </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    locale: state.translationReducer.locale
});

export default connect(mapStateToProps)(AddressContact);
