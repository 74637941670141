import React, {Component} from 'react';
import { Offline, Online } from "react-detect-offline";
import "./style.scss";
import {translate} from "../../services/translationService";

const POLL_URL = `${process.env.REACT_APP_API_URL}`;

class OfflineIndicator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wasOffline: false,
        }
        this.toggleWasOffline =this.toggleWasOffline.bind(this);
    }

    toggleWasOffline(){
        if (!this.state.wasOffline){
            setTimeout(()=>this.setState({
                wasOffline:false
            }), 5000);
        }
        this.setState({
            wasOffline: !this.state.wasOffline
        })
    }

    render() {
        const {wasOffline} = this.state
        return <>
            {wasOffline && <Online onChange={this.toggleWasOffline} polling={{interval:10000, url:POLL_URL}}>
                <div className="offline-indicator has-connection" onClick={this.toggleWasOffline}>
                    <i className="nc-icon nc-simple-remove delete-icon close-info"/>
                    <i className="nc-icon nc-globe"/>
                    <span>{translate("Internet connection restored")}</span>
                </div>
            </Online>}
            <Offline onChange={this.toggleWasOffline} polling={{interval:10000, url:POLL_URL}}>
                <div className="offline-indicator">
                    <i className="nc-icon nc-globe"/>
                    <span>{translate("Warning - No internet connection!")}</span>
                </div>
            </Offline>
        </>;
    }
}

export default OfflineIndicator;