import React, {Component} from 'react';
import {Button, Input} from "reactstrap";
import {translate} from "../../../../services/translationService";
import {getInputErrorClassInRow} from "../../../../helpers/formHelper";
import Loader from "../../../../components/Loader/Loader";
import {priceApiService} from "../../../../services/priceApiService";
import {alertActions} from "../../../../redux/actions";

class LengthOptionsForm extends Component {

  constructor(props) {
    super(props);
    let lengthOptionsInputs = [];
    props.surcharges.forEach((surcharge, i) => lengthOptionsInputs.push({
      max_height: surcharge.max_height,
      max_width: surcharge.max_width,
      max_length: surcharge.max_length,
      min_length: surcharge.min_length,
      price: surcharge.price,
    }));
    this.state = {
      lengthOptionsInputs: lengthOptionsInputs,
      isUploading: false,
      formIsValid: lengthOptionsInputs.length > 0
    }
    this.updateLengthOptions = this.updateLengthOptions.bind(this)
    this.addOptionsRow = this.addOptionsRow.bind(this)
    this.deleteOptionsRow = this.deleteOptionsRow.bind(this)
    this.uploadSurcharges = this.uploadSurcharges.bind(this)
    this.formRef = React.createRef();
  }

  uploadSurcharges() {
    let newInputValues = [...this.state.lengthOptionsInputs];
    const {countryFrom, countryTo, dispatch} = this.props;
    let lengthSurchargesData = [];
    newInputValues.forEach(surcharge => {
      lengthSurchargesData.push({
        min_length: Math.abs(parseInt(surcharge.min_length)),
        max_length: Math.abs(parseInt(surcharge.max_length)),
        max_width: Math.abs(parseInt(surcharge.max_width)),
        max_height: Math.abs(parseInt(surcharge.max_height)),
        price: Math.abs(parseFloat(surcharge.price)),
      })
    })
    this.setState({
      isUploading: true
    });
    priceApiService.uploadSurchargesData(`byLength/${countryFrom}/${countryTo}`,
      lengthSurchargesData).then(response => {
      dispatch(alertActions.success(translate("Surcharges for length uploaded successfully")));
      this.setState({
        isUploading: false
      });
    }).catch((apiError) => {
      console.log(apiError);
      dispatch(alertActions.error(translate("Failed to upload surcharges, please retry")));
      this.setState({
        isUploading: false
      });
    })
  }

  updateLengthOptions(e) {
    setTimeout(() => {
      let errorInputs = this.formRef.current.querySelectorAll('.input-error');
      let errorRows = this.formRef.current.querySelectorAll('.row-error');
      let formHasErrors = errorRows.length > 0 || errorInputs.length > 0;
      this.setState({formIsValid: !formHasErrors})
    }, 100);
    let updatedOptionsRow = [...this.state.lengthOptionsInputs]
    let inputValue = e.target.value;
    const row = e.target.name.split('-')[2];
    const type = e.target.name.split('-')[3];
    if (type !== 'price') {
      inputValue = inputValue.replace(/[.,]/g, '');
    }
    updatedOptionsRow[row][type] = inputValue
    this.setState({lengthOptionsInputs: updatedOptionsRow})
  }

  addOptionsRow() {
    let updatedOptionsRow = [...this.state.lengthOptionsInputs]
    const emptyRow = {
      min_length: "0",
      max_length: "0",
      max_width: "0",
      max_height: "0",
      price: "0",
    }

    updatedOptionsRow.push(emptyRow);
    this.setState({lengthOptionsInputs: updatedOptionsRow})
  }

  deleteOptionsRow(row) {
    let updatedOptionsRow = [...this.state.lengthOptionsInputs]
    if (updatedOptionsRow.length > 1) {
      updatedOptionsRow.splice(row, 1);
    } else {
      updatedOptionsRow = [{
        factor: "0",
        min_length: "0",
        max_length: "0",
        max_width: "0",
        max_height: "0"
      }]
    }
    setTimeout(() => {
      this.setState({lengthOptionsInputs: updatedOptionsRow})
    }, 100);
  }

  renderLengthOptionsInputs(optionsObject, row) {
    const {lengthOptionsInputs} = this.state;
    return <>
      <Input
        name={`length-options-${row}-min_length`}
        id={`length-options-${row}-min_length`}
        value={optionsObject.min_length}
        onChange={this.updateLengthOptions}
        type="number"
        step="1"
        min="0"
        className={getInputErrorClassInRow(optionsObject, 'min_length')}
      />
      <Input
        name={`length-options-${row}-max_length`}
        id={`length-options-${row}-max_length`}
        value={optionsObject.max_length}
        onChange={this.updateLengthOptions}
        type="number"
        step="1"
        min="0"
        className={getInputErrorClassInRow(optionsObject, 'max_length')}
      />
      <Input
        name={`length-options-${row}-max_width`}
        id={`length-options-${row}-max_width`}
        value={optionsObject.max_width}
        onChange={this.updateLengthOptions}
        type="number"
        step="1"
        min="0"
        className={getInputErrorClassInRow(optionsObject, 'max_width')}
      />
      <Input
        name={`length-options-${row}-max_height`}
        id={`length-options-${row}-max_height`}
        value={optionsObject.max_height}
        onChange={this.updateLengthOptions}
        type="number"
        step="1"
        min="0"
        className={getInputErrorClassInRow(optionsObject, 'max_height')}
      />
      <Input
        name={`length-options-${row}-price`}
        id={`length-options-${row}-price`}
        value={optionsObject.price}
        onChange={this.updateLengthOptions}
        type="number"
        step="1"
        min="0"
        className={getInputErrorClassInRow(optionsObject, 'price')}
      />
      {<span className="round-button delete-button" title={translate("Delete row")}>
        <i className="nc-icon nc-simple-delete" onClick={() => {
          if (lengthOptionsInputs.length > 1) {
            this.deleteOptionsRow(row)
          }
        }} />
      </span>}
    </>
  }

  render() {
    const {lengthOptionsInputs, isUploading, formIsValid} = this.state;
    return <>
      <div className="length-options-form" ref={this.formRef}>
        <div className="length-options-header">
          <div>{translate("Min length") + " [cm]"}</div>
          <div>{translate("Max length") + " [cm]"}</div>
          <div>{translate("Max width") + " [cm]"}</div>
          <div>{translate("Max height") + " [cm]"}</div>
          <div>{translate("Price") + " [€]"}</div>
          <div></div>
        </div>
        <div className="additional-factor-form">
          {lengthOptionsInputs.map((optionsObject, key) => <div className="length-options-row" key={key}>
            {this.renderLengthOptionsInputs(optionsObject, key)}
          </div>)
          }
        </div>
        {(lengthOptionsInputs.length < 11) && <div className={"add-factors"} onClick={this.addOptionsRow}>
            <span className="round-button add-button">
              <i className="nc-icon nc-simple-add" />
            </span>
          <span>{translate("Add surcharge")}</span></div>}
        {<div className="button-container">
          <Button color="primary" size={"sm"} onClick={this.uploadSurcharges} disabled={!formIsValid}>
            {isUploading ? <Loader /> : translate("Save")}
          </Button>
        </div>}
      </div>
    </>
  }
}

export default LengthOptionsForm