import React from "react";
import {Button, Card, CardBody, Col, Label, Row, Table} from "reactstrap";
import {translate} from 'services/translationService';
import "../../style.scss";
import {getDriverNamesForCar} from "../../../../helpers/driverHelper";
import AssignDriverModal from "../AssignDriverModal";
import {connect} from "react-redux";
import {getUsers} from "../../../../redux/actions/users";

class VehicleDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAssignDriverModal: false,
            driversListVisible: false,
            isFetching: true,
            error: false,
            drivers:[]
        };
        this.toggleDriversModal = this.toggleDriversModal.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getUsers({
            role: "ROLE_DRIVER",
            perPage:999
        }));
    }

    toggleDriversModal() {
        this.setState({
            driversListVisible: !this.state.driversListVisible
        });
    }

    renderDriversInformation(vehicle) {
        const numberOfDrivers = vehicle.drivers.length;
        return <div className={"drivers-information"}>
            {numberOfDrivers > 0 ? <span className = "drivers-names">{getDriverNamesForCar(vehicle)}
                                </span> : ""}
                        <Button className="btn btn-success btn-sm"
                                onClick={this.toggleDriversModal}>
                                {translate("Change")}
                        </Button>
        </div>
    }

    render() {
        const {vehicle, title, isFetching, error} = this.props;
        return <div>
            <Card className="vehicle-details">
                <CardBody>
                    <Row>
                        <Col md={12}>
                            <h5><i
                                className="nc-icon nc-alert-circle-i"/> {title}
                            </h5>
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <Table size="sm" borderless>
                                <tbody>
                                <tr>
                                    <th>
                                        <label>{translate('Model / Brand')}: </label>
                                    </th>
                                    <td className="halfWidth">
                                        {vehicle.brand.name} {vehicle.model.name}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <label>{translate('Register number')}: </label>
                                    </th>
                                    <td>
                                        {vehicle.registrationNumber}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <Label>{translate("Production Year")}</Label>
                                    </th>
                                    <td>
                                        {vehicle.productionYear}
                                    </td>
                                </tr>
                                {vehicle.type !== 'TRUCK_TRAILER' ?
                                    <tr>
                                        <th>
                                            <Label>{translate("Drivers")}</Label>
                                        </th>
                                        <td>
                                            {this.renderDriversInformation(vehicle)}
                                        </td>
                                    </tr> : <tr></tr>}
                                </tbody>
                            </Table>
                        </Col>

                    </Row>
                </CardBody>
            </Card>
            {this.props.drivers.length && <AssignDriverModal
                toggleModal={this.toggleDriversModal}
                showModal={this.state.driversListVisible}
                drivers={this.props.drivers}
                car={vehicle}
                carSet={this.props.carSet ? this.props.carSet : null}
                isFetching={isFetching}
                error={error}
            />}
        </div>
    }
}

VehicleDetails.defaultProps = {
    drivers: [],
    isFetching: true,
    error: false,
};

const mapStateToProps = (state) => {
    return {
        drivers: state.usersReducer.accounts,
        isFetching: state.usersReducer.isFetching,
        error: state.usersReducer.error,
    };
};

export default connect(mapStateToProps)(VehicleDetails);
