import React from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import {translate} from 'services/translationService';
import {Modal, ModalBody, ModalHeader, Table} from "reactstrap";
import BodyTypeBadge from "../BodyTypeBadge";
import MediaQuery from "react-responsive";

class AssignCarModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            linkingEnabled: true
        };
        this.toggle = this.toggle.bind(this);
        this.sortCars = this.sortCars.bind(this);
    }

    toggle() {
        this.props.toggleModal();
    }

    sortCars(cars) {
        return cars.sort((a,b)=>{
            if (a.drivers.length === 0 && b.drivers.length > 0) {
                return -1;
            } else if (a.drivers.length > 0 && b.drivers.length === 0) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    renderCarRow(car, i) {
        const {drivers} = car;
        let existingDriver = drivers.length > 0 ? drivers[0] : null;
        return(
            <tr key={i}>
                <td>{car.brand.name} {car.model.name} </td>
                <td>{car.registrationNumber}</td>
                <MediaQuery query='(min-device-width: 860px)'>
                    <td>{existingDriver ? `${existingDriver.name} ${existingDriver.surname}` : "-"}</td>
                    <td>{car.productionYear}</td>
                    <td>{car.container ? <BodyTypeBadge specification={car.container}/>:
                        <span>{translate("Solo Truck")}</span>}</td>
                    <td>{car.container ?
                        <span>{car.container.length} / {car.container.width} / {car.container.height}</span>:"-"}
                        </td>
                </MediaQuery>
                <td>
                    <Button onClick={()=>{this.props.connectToDriver(car)}}>
                        {translate("Connect")}
                    </Button>
                </td>
            </tr>
        )
    }

    render() {
        const {cars, driver, isFetching, error} = this.props;
        const sortedCars = this.sortCars([...cars]);
        return (
            <div className="cars-list">
                <Modal isOpen={this.props.showModal} toggle={this.toggle} size="lg" scrollable={true}>
                    <ModalHeader toggle={this.toggle}>{translate("Assign a car to driver: ")}
                        <MediaQuery query='(max-device-width: 859px)'>
                            <br />
                        </MediaQuery>
                        {driver ? <strong>{" "}{driver.name} {driver.surname}</strong>:""}</ModalHeader>
                    <ModalBody>
                        { (!isFetching && !error && cars.length) ?
                        <Table className="car-list-table">
                            <thead>
                            <tr>
                                <th>{translate('Brand / Model')}</th>
                                <th>{translate('Registration')}</th>
                                <MediaQuery query='(min-device-width: 860px)'>
                                    <th>{translate("Driver")}</th>
                                    <th>{translate('Production year')}</th>
                                    <th>{translate('Body type')}</th>
                                    <th>{translate('Dimensions')}</th>
                                </MediaQuery>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                sortedCars.map((car, i) => {
                                    return this.renderCarRow(car, i);
                                })
                            }
                            </tbody>
                        </Table>
                            : <p>{translate("There are no available cars right now")}</p> }
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}


export default AssignCarModal

