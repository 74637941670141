import React from "react";
import {Field, reduxForm} from 'redux-form';
import {Col, Row, Button} from "reactstrap";
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import FieldDropzone from "components/FormFields/FieldDropzone/FieldDropzone";
import {translate} from 'services/translationService';
import {getConfig} from "helpers/configHelper";
import {addCarrierDocument, addUserDocument} from "../../redux/actions";
import {addDocumentValidation} from "./addDocumentValdation";

class AddDocumentForm extends React.Component {

    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.props.change("documentTypeId", this.props.documentType.id);
    }

    closeModal() {
        this.props.onFinish();
    }

    onFormSubmit(formData) {
        let objectFormData = new FormData();
        Object.keys(formData).forEach(key => {
            objectFormData.append(key,formData[key]);
        });
        if (this.props.documentType.assignedToType === 'account') {
            this.props.dispatch(addUserDocument(this.props.userId, objectFormData))
        } else{
            this.props.dispatch(addCarrierDocument(objectFormData));
        }
        this.closeModal();
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
                <Row>
                    <Col>
                        <Field
                            name="document"
                            component={FieldDropzone}
                            maxSize={parseInt(getConfig("maxFileSize")) || 5242880}
                            accept="application/pdf"
                            />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Field
                            name="validFrom"
                            type="date"
                            label= {translate("Valid from")}
                            component={FieldInput}
                        />
                    </Col>
                </Row>
                    <Row>
                        <Col>
                            <Field
                                name="expirationDate"
                                type="date"
                                label={translate("Expiration date")}
                                component={FieldInput}
                            />
                        </Col>
                    </Row>
                    <Row>

                        <Col >
                            <Field
                                name="note"
                                label={translate("Note")}
                                component={FieldInput}
                            />
                        </Col>
                    </Row>


                    <Row>
                        <div className="update ml-auto mr-auto">
                            <Button color="primary">{translate("Add Document")}</Button>
                        </div>
                    </Row>
            </form>
        )
    }
}

export default reduxForm({
    form: 'AddDocumentForm',
    validate: addDocumentValidation
})(AddDocumentForm);
