import loggedUserService from "./loggedUserService";

const mapService = {
    createMarker: (car, map) => {
        const title = `${car.brand.name} ${car.model.name} ${car.registrationNumber}`
        let marker = new window.google.maps.Marker({
            title,
            draggable:true,
        });
        marker.setMap(map);
        return marker;
    },
    showCarPosition: (car) => {
        const positionIsSet = car.lat !== undefined && car.lng !== undefined ;
        const isManager = loggedUserService.isManager();
        return positionIsSet || isManager;
    }
};

export default mapService;
