import React, {Component} from 'react';
import {connect} from 'react-redux';
import {apiFileService} from "services/apiFileService";
import {fileHelper} from "helpers/fileHelper";
import {alertActions} from "redux/actions";
import {translate} from 'services/translationService';
import { Document, Page } from 'react-pdf';
import {Row, Button, Container} from "reactstrap";
import {history} from "../../helpers";

class DocumentFile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: false,
            errorText: null,
            blob: null,
            fileName: null
        };
        this.documentId = document.location.hash.match("(\\d+)")[0];
        this.handleLoadingError = this.handleLoadingError.bind(this);
        this.renderFile = this.renderFile.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.windowClose = this.windowClose.bind(this);
    }

    componentDidMount() {
        apiFileService.downloadFile(`/api/${this.props.documentType}/documents/${this.documentId}`)
            .then(apiServiceResponse => {
                const blob = new Blob([apiServiceResponse.blob], {type: apiServiceResponse.fileType});
                this.setState({
                    isLoading: false,
                    error: false,
                    blob: blob,
                    fileName: apiServiceResponse.fileName
                });
            })
            .catch(response => {
                fileHelper.blobToText(response.data).then(value => {
                    let errorText = JSON.parse(value).message.error;
                    this.setState({
                        errorText:errorText,
                        error: true,
                        isLoading: false,
                    });
                });
            });
    }

    handleLoadingError(props){
        const {dispatch} = props;
        history.push('/');
        dispatch(alertActions.error(this.state.errorText))
    }

    renderFile(fileUrl){
        if (this.state.blob.type === 'image'){
            return <img src={fileUrl} alt={"Carrier Document"}/>
        }
        return (
            <Document file={fileUrl}>
                <Page
                    pageNumber={1}
                    renderAnnotationLayer={false}
                />
            </Document>
        )
    }

    downloadFile(){
        const {blob,fileName} = this.state;

        if (blob) {
            let a = window.document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    windowClose(){
        window.close();
    }

    render() {
        const {isLoading, error, blob} = this.state;
        error && this.handleLoadingError(this.props);

        let fileUrl = blob && URL.createObjectURL(blob);
        return (
            <div>
                {isLoading ?
                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="Loading"/>
                    :
                    fileUrl && <Container>
                        <div>
                            <Row className={"justify-content-center"}>
                                <Button
                                    color="primary"
                                    onClick={this.downloadFile}>
                                    {translate("Download File")}
                                </Button>
                                <Button
                                    outline
                                    color="primary"
                                    onClick={this.windowClose}>
                                    {translate("Close Window")}
                                </Button>
                            </Row>
                            <Row className={"justify-content-center"}>
                                {this.renderFile(fileUrl)}
                            </Row>
                        </div>
                    </Container>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(DocumentFile);
