import React from "react";
import {connect} from 'react-redux';
import ReactDropzone from "react-dropzone";
import Button from "reactstrap/es/Button";
import {alertActions, getOrder} from "redux/actions";
import {getConfig} from "helpers/configHelper";
import {translate} from 'services/translationService';
import "./DeliveryDocument.scss";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";
import loggedUserService from "../../../services/loggedUserService";
import {history} from "../../../helpers";
import orderService from "../../../services/orderService";
import Loader from "../../../components/Loader/Loader";
import {documentsApiService} from "../../../services/documentsApiService";

class UploadDocument extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonEnabled: false,
            file: null,
            driverWarning: false,
            docType:""
        };
        this.isDriverOnly = loggedUserService.isDriverOnly();
        this.nextOrderIsSet = orderService.isNextOrderSet(this.props.relatedOrders);
        this.onDrop = this.onDrop.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.toggleDriverWarning = this.toggleDriverWarning.bind(this);
        this.docTypeChange = this.docTypeChange.bind(this);
        this.selectClassName = this.selectClassName.bind(this);
    }

    docTypeChange(e) {
        const docTypeValue  = e.target.value;
        const {file} = this.state;
        const buttonEnabled = docTypeValue !== "" && file !== null;
        this.setState({docType:docTypeValue, buttonEnabled})
    }

    selectClassName(){
        const classSuffix = this.state.docType !== "" ? "" : " not-selected"
        return "form-control"  +  classSuffix
    }

    toggleDriverWarning() {
        this.setState({
            driverWarning: !this.state.driverWarning
        })
    }

    uploadFile() {
        const {dispatch, shipmentId, orderId, shipmentNumber} = this.props;
        const {docType} = this.state;
        const fileData = {
            document_type:docType,
            assign_to_carrier_order:orderId,
            assign_to_shipment:shipmentId,
            shipment_number:shipmentNumber
        }
        let formData = new FormData();
        formData.append('documents[]', this.state.file);
        formData.append('file_data', JSON.stringify(fileData));

        documentsApiService.uploadFile(`api/documents`, formData)
            .then((apiServiceResponse) => {
                if (apiServiceResponse.status === true) {
                    dispatch(alertActions.success(documentsApiService.getSuccessText(apiServiceResponse)));
                    if (this.isDriverOnly && this.nextOrderIsSet) {
                        const nextOrderId = orderService.getNextOrderId(this.props.relatedOrders);
                        dispatch(getOrder(nextOrderId));
                        history.push(`/orders/${nextOrderId}`);
                    } else if (this.isDriverOnly && !this.nextOrderIsSet) {
                        history.push('/orders/ongoing');
                    } else {
                        dispatch(getOrder(orderId))
                    }
                }
            })
            .catch((apiError) => {
                dispatch(alertActions.error(documentsApiService.getErrorText(apiError)));
            });
    }

    onDrop(files) {
        if (files[0] !== null) {
            const isDocTypeSet = this.state.docType !== '';
            this.setState({
                buttonEnabled: isDocTypeSet,
                file: files[0]
            })
        }
    }

    render() {
        const {driverWarning} = this.state;
        const {relatedOrders, isFetching, documentsTypes} = this.props;
        const uploadAction = this.isDriverOnly ? this.toggleDriverWarning : this.uploadFile;
        const maxSize = parseInt(getConfig('maxFileSize'));
        const accept = "image/jpeg, application/pdf";
        const acceptedFileTypes = ".jpeg, .pdf"
        if (isFetching){
            return <Loader />
        }
        return <>
                <ReactDropzone
                    onDrop={this.onDrop}
                    multiple={false}
                    maxSize={maxSize}
                    accept={accept}
                >
                    {({getRootProps, getInputProps, isDragReject, rejectedFiles, acceptedFiles}) => {
                        const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                        return (<div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="dropzone">
                                <p>{translate("Drag your document or click here to add file")}
                                    <br/><em>{translate("Possible file type: ") + acceptedFileTypes} <br/>
                                        {translate("Max file size: ") + (maxSize / 1048576).toFixed(0) + " MB"}</em>
                                </p>
                                <i className="nc-icon nc-cloud-upload-94"/>
                                {(isDragReject) && <p className="rejected">{translate("File type not accepted")}</p>}
                                {isFileTooLarge && <p className="rejected">{translate("File is too large")}</p>}
                                {(rejectedFiles.length > 0) &&
                                <p className="rejected">{translate("File type not accepted") + " / " + translate("File is too large")}</p>}
                                {acceptedFiles[0] &&
                                <div className="fileName">
                                    <i className="nc-icon nc-share-66"></i>
                                    <p className="accepted">{acceptedFiles[0].name}</p>
                                </div>
                                }
                            </div>
                        </div>)
                    }}
                </ReactDropzone>
                <div className="upload-controls">
                    <select id="document-type-select" className={this.selectClassName()} name="language" onChange={this.docTypeChange} defaultValue="" >
                        <option value="" disabled={true}> { translate('Select document type') } </option>
                        {documentsTypes.map((doc,key)=><option key={key} value={doc.id}>{doc.name}</option>)}
                    </select>
                    <Button color="primary"
                             disabled={!this.state.buttonEnabled}
                             onClick={uploadAction}
                             style={{marginBottom: '8px'}}
                    >
                        <span>{translate("Upload File")}</span>
                    </Button>
                </div>
                <Modal isOpen={driverWarning} toggle={this.toggleDriverWarning}>
                    <ModalHeader><strong>{translate("Delivery document upload warning")}</strong></ModalHeader>
                    <ModalBody>
                        <p>{translate("Once the delivery document is loaded current order is no longer accessible. You will not be able to modify or see the order. This order will be accessible by manager only.")}</p>
                        {this.nextOrderIsSet && <p>
                            {translate("You will be redirected to order:")}&nbsp;
                            <strong>{orderService.getNextOrderNumber(relatedOrders)}</strong>
                        </p>}
                        <strong>{translate("Do you want to proceed?")}</strong>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.uploadFile}>{translate("Upload File")}</Button>{' '}
                        <Button color="secondary" onClick={this.toggleDriverWarning}>{translate("Cancel")}</Button>
                    </ModalFooter>
                </Modal>
        </>
    }
}

UploadDocument.defaultProps = {
    documentsTypes: [],
    isFetching: null,
    error: false,
};

function mapStateToProps(state) {
    return {
        documentsTypes: state.documentTypesReducer.documentsTypes,
        isFetching: state.documentTypesReducer.isFetching,
        error: state.documentTypesReducer.error,
    };
}

export default connect(mapStateToProps)(UploadDocument);
