import React, { Component } from 'react';
import { connect } from 'react-redux';
import {alertActions, authActions} from 'redux/actions';
import { translate } from 'services/translationService';
import Loader from "components/Loader/Loader";
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';
import {Link} from "react-router-dom";
import WelcomeHeader from "../components/WelcomeHeader/WelcomeHeader";
import {Logo} from "../components/Logo/Logo";
import Footer from "../../../components/Footer/Footer";
import "./style.scss"
import {history} from "../../../helpers";

class ActivateAccount extends Component {

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      repeatPassword: '',
      submitted: false,
      fetching: false,
    };

    const {token} = this.props.match.params;
    this.token = token;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

    handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { password } = this.state;
    const { dispatch } = this.props;
    if (this.isValidPassword() && this.isRepeatPasswordIdentical()) {
      dispatch(authActions.resetPassword({
        token: this.token,
        password: password
      }));
    }
  }

  isValidPassword() {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    return regex.test(this.state.password);
  }

  isRepeatPasswordIdentical(){
    return this.state.password === this.state.repeatPassword;
  }

  static checkIfLoggedIn(props) {
    if (props.loggedIn) {
      const { dispatch } = props;
      dispatch(alertActions.error(translate('Logged user cannot activate account')));
      history.push('/dashboard');
    }
  }

  render() {
    const { fetching } = this.props;
    const { password, repeatPassword, submitted } = this.state;
    ActivateAccount.checkIfLoggedIn(this.props);

    return (
    <div className="Site-content">
    <WelcomeHeader title={translate("JANTA Freight App Account Activation")}/>
     <div className="jumbotron">
      <div className="flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h5 style={{color: '#6d6d6d'}}>{translate("Please enter your password")}</h5>
                    <form name="form" onSubmit={this.handleSubmit}>
                      <div className={'form-group' + (submitted && !this.isValidPassword() ? ' has-error' : '')}>
                        <label htmlFor="password" className="control-label">{ translate("Password") } </label>
                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                        {submitted && !this.isValidPassword() &&
                        <div className="invalid-feedback">{ translate('Password must be between 8-20 characters, include digit and capital letter') }</div>
                        }
                      </div>
                      <div className={'form-group' + (submitted && !this.isRepeatPasswordIdentical() ? ' has-error' : '')}>
                        <label htmlFor="repeatPassword" className="control-label">{ translate("Confirm password") } </label>
                        <input type="password" className="form-control" name="repeatPassword" value={repeatPassword} onChange={this.handleChange} />
                        {submitted && !this.isRepeatPasswordIdentical() &&
                        <div className="invalid-feedback">{ translate('Repeated password is not identical') }</div>
                        }
                      </div>
                      <div className="form-group">
                        <div className="text-center">
                          <button className="btn btn-primary" disabled={!(password && repeatPassword)}> { translate('Activate account') } </button>
                          {fetching &&
                              <Loader />
                        }
                         </div>
                        <Link to={"/login"}><p className="link-to-login">{translate('Back to login page')}</p></Link>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
        <Logo />
      </div>
    </div>
      <Footer />
    </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.authReducer.loggedIn,
  fetching: state.authReducer.fetching,
  resetPasswordRequested: state.authReducer.resetPasswordRequested
});

export default connect(mapStateToProps)(ActivateAccount);
