import React from "react";
import {Field, FormSection, reduxForm} from 'redux-form';
import {Col, Row} from "reactstrap";
import {userValidation} from '../validation.js'
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import {translate} from 'services/translationService';
import Button from "components/CustomButton/CustomButton.jsx";
import Address from 'components/FormSections/Address';
import RoleMultiSelect from '../RoleMultiSelect';
import {languagesForSelect} from "../../../../helpers/languageSelect";
import FieldSelect from "../../../../components/FormFields/FieldSelect/FieldSelect";
import {translateFormLabels} from "../../../../helpers/translateFormLabels";
import {connect} from "react-redux";


class CreateUserForm extends React.Component {

    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.state = {
            labels:  translateFormLabels()
        };
    }

    componentWillMount () {
        this.props.initialize({ roles: ['ROLE_DRIVER'] });
        this.props.initialize({ address: {country: 'DE' }});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale){
            this.setState({labels: translateFormLabels()});
        }
    }

    onFormSubmit(formData) {
        this.props.onSubmitHandler(formData);
    }

    render() {
        const {labels} = this.state;
        return (
            <form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
                <Row>
                    <Col sm={6}>
                        <Field
                            name="name"
                            type="text"
                            label={labels.accountName}
                            component={FieldInput}
                        />
                    </Col>

                    <Col sm={6}>
                        <Field
                            name="surname"
                            label={labels.accountSurname}
                            component={FieldInput}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <Field
                            name="email"
                            label={labels.accountEmail}
                            type="email"
                            component={FieldInput}
                        />
                    </Col>
                    <Col sm={6}>
                        <Field
                            name="documentNumber"
                            label={labels.accountDocumentNumber}
                            component={FieldInput}
                        />
                    </Col>
                </Row>
                    <Row>
                        <FormSection name="address">
                            <Col sm={6}>
                                <Field
                                    name="phone"
                                    label = {labels.accountPhone}
                                    type="text"
                                    component={FieldInput}
                                />
                            </Col>
                        </FormSection>
                        <Col sm={6}>
                            <Field
                                name="language"
                                options={languagesForSelect()}
                                label={labels.accountLanguage}
                                component={FieldSelect}
                                props={{emptyValue: false}}
                            />
                        </Col>
                    </Row>

                <hr />
                <FormSection name="address">
                    <Address formName={'userForm'}/>
                </FormSection>

                <hr />
                <Row>
                    <Col md={12}>
                        <h5>{translate('Management user information')}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <RoleMultiSelect />
                    </Col>
                </Row>

                <Row>
                    <div className="update ml-auto mr-auto">
                        <Button color="primary" round>{translate("Create User")}</Button>
                    </div>
                </Row>

            </form>
        )
    }
}

const mapStateToProps = state => ({
    locale: state.translationReducer.locale
});

const decoratedComponent = connect(mapStateToProps)(CreateUserForm);

export default reduxForm({
    form: 'createUserForm',
    validate: userValidation,
})(decoratedComponent);

