import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./styles.scss"
import {Button, Input} from "reactstrap";
import {getDistanceLabel} from "../../../helpers/formHelper";
import Loader from "../../../components/Loader/Loader";
import {translate} from "../../../services/translationService";
import MediaQuery from "react-responsive";
import classnames from "classnames";

class PrimaryPricesForm extends Component {

  constructor(props) {
    super(props);
    let pricesInputs = {};
    let accordion = {};
    props.initialPrices.forEach(service => {
        pricesInputs[service.service_code] = {};
        for (let maxDistance in service.prices) {
          pricesInputs[service.service_code][maxDistance] = {...service.prices[maxDistance], isValid: true}
        }
        accordion[service.service_code] = false;
      }
    )

    this.state = {
      pricesInputs,
      accordion,
      isUpdating: false,
      formIsValid: true,
    }
    this.getDistances = this.getDistances.bind(this);
    this.changePriceInput = this.changePriceInput.bind(this);
    this.uploadSurcharges = this.uploadSurcharges.bind(this);
    this.toggleHeader = this.toggleHeader.bind(this);
  }

  changePriceInput(e) {
    const {name} = e.target;
    const [serviceCode, distance, type] = name.split('-');
    let value;
    let isValid = true;
    if (type === 'const') {
      value = e.target.checked
    } else {
      value = e.target.value;
      if (value === "" || parseFloat(value) < 0) {
        isValid = false;
      }
    }
    let newInputValues = {...this.state.pricesInputs};
    newInputValues[serviceCode][distance][type] = value;
    newInputValues[serviceCode][distance].isValid = isValid;
    this.setState({pricesInputs: newInputValues, formIsValid: isValid});

  }

  uploadSurcharges() {
    let updatedPrices = [...this.props.initialPrices];
    const {pricesInputs} = this.state;
    const {updatePrices} = this.props;
    updatedPrices.forEach(service => {
      service.prices = {...pricesInputs[service.service_code]};
      for (let distance in service.prices) {
        service.prices[distance] = {
          ...service.prices[distance],
          price: parseFloat(service.prices[distance].price),
          max_distance: service.prices[distance].max_distance.toString()
        }
        delete service.prices[distance].isValid;
      }
    })
    updatePrices(updatedPrices)
  }

  getDistances(serviceCode) {
    return Object.keys(this.state.pricesInputs[serviceCode]);
  }

  toggleHeader(serviceCode) {
    let newAccordion = {...this.state.accordion};
    for (let serviceCode in newAccordion){
      newAccordion[serviceCode] = false;
    }
    newAccordion[serviceCode] = !this.state.accordion[serviceCode];

    this.setState({accordion:newAccordion})
  }

  render() {
    const {initialPrices, isUpdating} = this.props;
    const {pricesInputs, formIsValid, accordion} = this.state;
    const getLabel = (service) => service.hasOwnProperty('short_name') ? service.short_name : service.service_name;

    const isServiceValid = (service) => {
      let isServiceValid = true;
      for (const dist in pricesInputs[service.service_code]) {
        if (!pricesInputs[service.service_code][dist].isValid)   isServiceValid = false
      }
      return isServiceValid;
    }

    return <div>
      <MediaQuery query='(min-device-width: 860px)'>
        <div className="primary-prices-form">
          {initialPrices.map((service, index) => <div className="primary-prices-row" key={index}>
            <div className="car-type" title={getLabel(service)}>{getLabel(service)}</div>
            {this.getDistances(service.service_code).map((distance, index) => <div className="price-form" key={index}>
              <div className={"distance-label"}>{getDistanceLabel(distance)}</div>
              <div className="price-inputs">
                <Input type="checkbox" name={`${service.service_code}-${distance}-const`}
                       checked={distance >= 200 ? false : pricesInputs[service.service_code][distance].const}
                       onChange={this.changePriceInput}
                       title={translate("Fixed price")}
                       style={{"display": distance >= 200 ? "none" : "block"}}
                />
                <Input type="number" id={`${service.service_code}-${distance}`}
                       name={`${service.service_code}-${distance}-price`}
                       value={pricesInputs[service.service_code][distance].price}
                       onChange={this.changePriceInput}
                       step="0.01"
                       min="0"
                       className={pricesInputs[service.service_code][distance].isValid ? "" : "invalid"}
                       title={pricesInputs[service.service_code][distance].isValid ? "" :
                         translate("Enter valid number")
                       }
                />
              </div>
            </div>)}
          </div>)
          }
        </div>
        <div className="primary-button-container">
          <Button color="primary" size={"sm"} onClick={this.uploadSurcharges} disabled={!formIsValid}>
            {isUpdating ? <Loader /> : translate("Save")}
          </Button>
        </div>
      </MediaQuery>
      <MediaQuery query='(max-device-width: 859px)'>
        <div className={"primary-prices-accordion"}>
          {initialPrices.map((service, index) => <div key={index}>
            <div className={`car-type-header ${accordion[service.service_code] ? 'open':''} `}
                 onClick={() => this.toggleHeader(service.service_code)}
                 >
              <span className={classnames({invalid:!isServiceValid(service)})}>{getLabel(service)}</span>
              <i className={`nc-icon nc-minimal-up ${accordion[service.service_code] ? 'open':''}`}></i>
            </div>

            <div className={`prices-container${accordion[service.service_code] ? '' : ' hidden'}`}  key={index + 99}>
              {this.getDistances(service.service_code).map((distance, index) => <div className="price-form" key={index}>
                <div className={"distance-label"}>{getDistanceLabel(distance)}</div>
                <div className="price-inputs">
                  <Input type="checkbox" name={`${service.service_code}-${distance}-const`}
                         checked={distance >= 200 ? false : pricesInputs[service.service_code][distance].const}
                         onChange={this.changePriceInput}
                         style={{"display": distance >= 200 ? "none" : "block"}}
                  />
                  <Input type="number" id={`${service.service_code}-${distance}`}
                         name={`${service.service_code}-${distance}-price`}
                         value={pricesInputs[service.service_code][distance].price}
                         onChange={this.changePriceInput}
                         step="0.01"
                         min="0"
                         className={pricesInputs[service.service_code][distance].isValid ? "" : "invalid"}
                  />
                </div>
              </div>)}
            </div>
          </div>)}
        </div>
        <div className="primary-button-container">
          <Button color="primary" size={"sm"} onClick={this.uploadSurcharges} disabled={!formIsValid}>
            {isUpdating ? <Loader /> : translate("Save")}
          </Button>
        </div>
      </MediaQuery>
    </div>
  }
}

PrimaryPricesForm.propTypes = {
  initialPrices: PropTypes.array,
  countryFrom: PropTypes.string,
  countryTo: PropTypes.string,
};

export default PrimaryPricesForm;
