/*eslint-disable*/
import React from "react";
import { Container, Row, Col } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Language from "../Language/Language";
import "./style.scss";
import loggedUserService from "../../services/loggedUserService";
import {translate} from "../../services/translationService";
import {getAppVersionInfo} from "../../helpers/cordova";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid}>
          <Row>
            <Col xs={6}>
             <div>
                &copy; {1900 + new Date().getYear()}, <a href="https://www.janta-logistics.de" target="_blank">Janta Logistics</a>, {getAppVersionInfo()}
              </div>
            </Col>
            <Col xs={6}>
              {!loggedUserService.isLogged()&&<Language/>}
              {loggedUserService.isLogged()&&<a href="https://janta-logistics.de/datenschutzerklaerung/" target="_blank">{translate("General Terms and Conditions")}</a>}
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
