import loggedUserService from "../services/loggedUserService";
import {translate} from "../services/translationService";

export function getExpiringDocumentWarningText(data) {
    let expiringDocuments = [];
    const remainingDays = (date) => {
        const now = new Date();
        const expirationDate = new Date(date);
        const differenceInDays = Math.ceil((expirationDate.getTime() - now.getTime()) / (1000 * 3600 * 24));
        return differenceInDays;
    };
    const addExpiringDocuments = (documents) => {
        documents.forEach(document => expiringDocuments = [...expiringDocuments,
            {
                type: translate(document.documentType.name),
                expiresIn: remainingDays(document.expirationDate)
            }
        ]);
    };
    if (loggedUserService.isDriver()) {
        const {accountDocuments} = data;
        if (accountDocuments.length > 0) {
            addExpiringDocuments(accountDocuments)
        }
    }
    if (loggedUserService.isManager()) {
        const {carrierDocuments} = data;
        if (carrierDocuments.length > 0) {
            addExpiringDocuments(carrierDocuments)
        }
    }
    if (expiringDocuments.length === 0) {
        return ""
    }
    let documentWarningText = translate("The following documents are expiring:");
    expiringDocuments.forEach(document => documentWarningText += " " + document.type + " (" + translate("in") + " "
        + document.expiresIn + " " + translate("days") + "),");
    documentWarningText = documentWarningText.slice(0, -1);
    documentWarningText += ". ";
    documentWarningText += translate("Please upload new versions.") + "\n";
    return documentWarningText;
}


