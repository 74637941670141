import {Field, Form, formValueSelector, reduxForm, change} from "redux-form";
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import React from "react";
import {translate} from "services/translationService";
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Loader from "components/Loader/Loader";
import {validateDownloadByPostCode} from "./validation";
import {priceApiService} from "../../../services/priceApiService";
import {fullDate} from "../../../utils/formatters";
import {alertActions, getPricesByCodesList} from "../../../redux/actions";
import {connect} from "react-redux";
import {apiFileService} from "../../../services/apiFileService";
import MediaQuery from 'react-responsive';
import SelectFile from "../../../components/FormFields/SelectFile/SelectFile";

class PostCodeImportForm extends React.Component {

  constructor(props) {
    super(props);
    this.downloadFile = this.downloadFile.bind(this);
    this.uploadFileByPostCode = this.uploadFileByPostCode.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      downloadingFile: false,
      uploadingFile: false,
      showModal:false,
      downloadingList:false,
      uploadFile:null
    }
  }

  toggleModal() {
    this.setState({
      showModal:!this.state.showModal
    })
  }

  componentDidMount() {
      this.props.dispatch(getPricesByCodesList());
  }

  uploadFileByPostCode(file) {
    const {postCodeValue, countryToValue, countryFromValue, dispatch} = this.props;
    const queryParams = {
      countryFrom: countryFromValue,
      countryTo: countryToValue,
      postCode: postCodeValue
    }
    if (postCodeValue === undefined){
      dispatch(change('postCodeImportForm','postCode', postCodeValue, true ));
      return;
    }
    this.setState({
      uploadingFile: true
    });
    apiFileService.uploadPriceListByPostCodes(queryParams, file).then(response => {
      dispatch(alertActions.success(translate('Prices file uploaded successfully')));
      dispatch(getPricesByCodesList());
      this.setState({
        uploadingFile: false
      });
    }).catch((apiError) => {
      console.log(apiError);
      dispatch(alertActions.error(translate('An upload error occurred, please check your file and retry')));
      this.setState({
        uploadingFile: false
      });
    })
  }

  downloadFile(priceData, downloadExample = false) {
    const {dispatch} = this.props;
    this.setState({
      downloadingFile: true
    });
    let requestData = {
      countryFrom:priceData.country_from,
      countryTo:priceData.country_to,
      postCode:priceData.post_code_from
    }
    priceApiService.getPricesByPostCode(requestData, downloadExample).then(response => {
      let blob = new Blob([response], {type: 'text/csv;charset=utf-8;'});
      let a = window.document.createElement("a");
      let filename = `prices_by_post_code_${requestData.countryFrom}_${requestData.countryTo}_${requestData.postCode}_${fullDate()}.csv`;
      if (downloadExample){
        filename = `prices_by_post_code_${requestData.countryFrom}_${requestData.countryTo}_${requestData.postCode}_example.csv`
      }
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.setState({
        downloadingFile: false
      });
    })
      .catch((apiError) => {
        console.log(apiError);
        dispatch(alertActions.error(translate('Prices file cannot be downloaded now, try later')));
        this.setState({
          downloadingFile: false
        });
      })
  }

  render() {

    const {uploadingFile} = this.state;
    const {countryFromValue, countryToValue, isFetching, pricesByCodes, downloadingFile} = this.props;

    return <>
    <Form className="cooperation-form" onSubmit={()=>{}}>
     <Row>
        <Col md={12}>
          <div className="prices-title">
          <Button color="primary" size={"sm"} onClick={this.toggleModal} disabled={isFetching}>
            {isFetching ? <Loader /> : translate("List of prices")}
          </Button>
            <Button color="primary" outline size={"sm"} onClick={()=>this.downloadFile({
              country_from:'DE', country_to:'DE',post_code_from:'10115'
            }, true)} disabled={downloadingFile}>
              {translate("Example file")}
            </Button>
          <div className="centered">
            <h5 className="cooperation-title">{`${translate("Prices by post codes")} ${countryFromValue} - ${countryToValue}`} </h5>
          </div>
          </div>
        </Col>
     </Row>
     <Row>
        <Col md={6}>
          <Field
              inputId="postCode"
              name="postCode"
              type="text"
              label={translate("Start Post Code")}
              component={FieldInput}
              placeholder=""
              labelClassName="col-md-6"
          />
        </Col>
        <Col md={6}>
          <div className="button-container">
            {uploadingFile ? <Loader />
              : <SelectFile
                handleFile={this.uploadFileByPostCode}
                name={"upload-by-post-code"}
                accept={"text/csv"}
                disabled={this.props.invalid}
              />}
          </div>
        </Col>
      </Row>
    </Form>
      <Modal isOpen={this.state.showModal} toggle={this.toggleModal} size="lg" scrollable={true} className={"prices-import-modal"}>
        <ModalHeader toggle={this.toggleModal}>
          {translate("List of uploaded prices by post codes")}
        </ModalHeader>
        <ModalBody>
          <MediaQuery query='(min-device-width: 769px)'>
          <div className="prices-codes-list">
            <div className="prices-codes-row header">
              <div>{translate("Country from")}</div>
              <div>{translate("Country to")}</div>
              <div>{translate("Start Post Code")}</div>
              <div>{translate("Updated at")}</div>
              <div></div>
            </div>
            {
              pricesByCodes.map((price,i)=><div className="prices-codes-row" key={i}>
                    <div>{price.country_from}</div>
                    <div>{price.country_to}</div>
                    <div>{price.post_code_from}</div>
                    <div>{price.update_time}</div>
                    <div>
                      <Button color="primary" size={"sm"} onClick={()=>this.downloadFile(price)} disabled={downloadingFile}>
                        {translate("Download")}
                      </Button>
                    </div>
                  </div>
                )
            }
          </div>
          </MediaQuery>
          <MediaQuery query='(max-device-width: 768px)'>
            <div className="centered"><h5>{translate("Tap to download:")}</h5></div>
            <div className="prices-codes-list">
              {
                pricesByCodes.map((price,i)=><div className="prices-codes-row" key={i} onClick={()=>this.downloadFile(price)}>
                  <div>{translate("Country from")}: <strong>{price.country_from}</strong></div>
                      <div>{translate("Country to")}: <strong>{price.country_to}</strong></div>
                      <div>{translate("Post code")}: <strong>{price.post_code_from}</strong></div>
                      <div>{translate("Updated at")}{'\n'}<strong>{price.update_time}</strong></div>
                    </div>
                )
              }
            </div>
          </MediaQuery>
        </ModalBody>
      </Modal>
    </>
  }
}

PostCodeImportForm = reduxForm({
  form: 'postCodeImportForm',
  validate: validateDownloadByPostCode,
  enableReinitialize: true
})(PostCodeImportForm);

const selector = formValueSelector('postCodeImportForm');
const countriesSelector = formValueSelector('countriesSelectorForm');

const mapStateToProps = (state) => {
  return {
          postCodeValue: selector(state, 'postCode'),
          countryToValue: countriesSelector(state, 'countryTo'),
          countryFromValue: countriesSelector(state, 'countryFrom'),
          pricesByCodes: state.pricesByCodesListReducer.pricesByCodes,
          isFetching: state.pricesByCodesListReducer.isFetching,
          error: state.pricesByCodesListReducer.error
  }
}

PostCodeImportForm = connect(mapStateToProps)(PostCodeImportForm)

export default PostCodeImportForm;
