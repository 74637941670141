import React, {Component} from 'react';
import {translate} from "../../../services/translationService";
import "./styles.scss"
import {Input} from "reactstrap";
import {getInputErrorClass, getInputErrorClassInRow} from "../../../helpers/formHelper";

class WeightFactors extends Component {

  constructor(props) {
    super(props);
    this.updateWeightFactor = this.updateWeightFactor.bind(this)
    this.addFactorsRow = this.addFactorsRow.bind(this)
    this.deleteFactorsRow = this.deleteFactorsRow.bind(this)
  }

  updateWeightFactor(e){
    let newInputs = {...this.props.configInputs};
    const inputValue = e.target.value;
    if (e.target.name === 'primaryFactor') {
      newInputs['primaryFactor'] = inputValue;
    } else {
      const row = e.target.name.split('-')[1];
      const type = e.target.name.split('-')[2];
      newInputs['additionalFactors'][row][type] = inputValue
    }
    this.props.updateInputs(newInputs)
  }

  addFactorsRow(){
    let newInputs = {...this.props.configInputs};
    const emptyRow = {
      factor:"",
      min_length:"",
      max_length:"",
      max_width:"",
      max_height:""
    }
    newInputs['additionalFactors'].push(emptyRow);
    this.props.updateInputs(newInputs)
  }

  deleteFactorsRow(row){
    let newInputs = {...this.props.configInputs};
    if(newInputs.additionalFactors.length > 1){
      newInputs['additionalFactors'].splice(row, 1);
    } else {
      newInputs['additionalFactors'] = [{
        factor:"0",
        min_length:"0",
        max_length:"0",
        max_width:"0",
        max_height:"0"
      }]
    }
    this.props.updateInputs(newInputs)
  }


  renderAdditionalFactorInputs(factorsObject, row){
    const {configInputs} = this.props;
    return <>
      <Input
        name={`additionalFactors-${row}-min_length`}
        id={`additionalFactors-${row}-min_length`}
        value={configInputs.additionalFactors[row].min_length}
        onChange={this.updateWeightFactor}
        type="number"
        step="1"
        min="0"
        className={getInputErrorClassInRow(configInputs.additionalFactors[row], 'min_length')}
      />
      <Input
        name={`additionalFactors-${row}-max_length`}
        id={`additionalFactors-${row}-max_length`}
        value={configInputs.additionalFactors[row].max_length}
        onChange={this.updateWeightFactor}
        type="number"
        step="1"
        min="0"
        className={getInputErrorClassInRow(configInputs.additionalFactors[row],'max_length')}
      />
      <Input
        name={`additionalFactors-${row}-max_width`}
        id={`additionalFactors-${row}-max_width`}
        value={configInputs.additionalFactors[row].max_width}
        onChange={this.updateWeightFactor}
        type="number"
        step="1"
        min="0"
        className={getInputErrorClassInRow(configInputs.additionalFactors[row],'max_width')}
      />
      <Input
        name={`additionalFactors-${row}-max_height`}
        id={`additionalFactors-${row}-max_height`}
        value={configInputs.additionalFactors[row].max_height}
        onChange={this.updateWeightFactor}
        type="number"
        step="1"
        min="0"
        className={getInputErrorClassInRow(configInputs.additionalFactors[row],'max_height')}
      />
      <Input
        name={`additionalFactors-${row}-factor`}
        id={`additionalFactors-${row}-factor`}
        value={configInputs.additionalFactors[row].factor}
        onChange={this.updateWeightFactor}
        type="number"
        step="1"
        min="0"
        className={getInputErrorClassInRow(configInputs.additionalFactors[row],'factor')}
      />
      {<span className="round-button delete-button" title={translate("Delete row")}>
        <i className="nc-icon nc-simple-delete" onClick={()=>this.deleteFactorsRow(row)} />
      </span>}
    </>
  }

  render() {

    const {configInputs} = this.props;
    return <>
      <div className="centered"><h5 className="cooperation-title">{translate("Weight factors rules")}</h5></div>
      <div className="primary-input">
        <span>{translate("Primary factor")}</span>
        <Input
          name="primaryFactor"
          id="primaryFactor"
          type="number"
          step="1"
          min="0"
          value={configInputs["primaryFactor"]}
          onChange={this.updateWeightFactor}
          className={getInputErrorClass(configInputs["primaryFactor"])}
        />
      </div>
      <div className="additional-factors">
        <div className="fracht-subtitle">{translate("Additional factors")}</div>
        <div className="additional-factor-header">
          <div>{translate("Min length") + " [cm]"}</div>
          <div>{translate("Max length") + " [cm]"}</div>
          <div>{translate("Max width") + " [cm]"}</div>
          <div>{translate("Max height") + " [cm]"}</div>
          <div>{translate("Factor") + " [cm]"}</div>
          <div></div>
        </div>
        <div className="additional-factor-form">
          {configInputs.additionalFactors.map((factorsObject,key) =><div className="additional-factor-row" key={key}>
            {this.renderAdditionalFactorInputs(factorsObject, key)}
          </div>)
          }
        </div>
          {(configInputs.additionalFactors.length < 5) && <div className={"add-factors"} onClick={this.addFactorsRow}>
            <span className="round-button add-button">
              <i className="nc-icon nc-simple-add" />
            </span>
            <span>{translate("Add factors")}</span></div>}
      </div>
    </>
  }
}

export default WeightFactors