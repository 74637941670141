import React from "react";
import {Input} from "reactstrap";
import {translate} from 'services/translationService';
import {connect} from "react-redux";

class SelectCountry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedValue: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.renderCountriesList = this.renderCountriesList.bind(this);
        this.renderCountryOption = this.renderCountryOption.bind(this);
    }

    componentDidMount() {
        this.setState({
            selectedValue: this.props.defaultValue,
        })
    }

    handleChange(selectedOption) {
        this.setState({selectedValue: selectedOption.value});
    }

    renderCountriesList()
    {
        if (!this.props.isFetching) {
            return (
                this.props.countries.map(this.renderCountryOption)
            )
        }
    }

    renderCountryOption(countryObject, key) {
        return (
            <option value={countryObject.code} key={key} >
                {countryObject.name}
            </option>
        )
    }

    render() {
        return(
            <Input type="select" name={this.props.name} value={this.state.selectedValue} onChange={this.handleChange}>
                <option value=""> {translate('Select')} </option>
                {this.renderCountriesList()}
            </Input>
        )
    }
}

SelectCountry.defaultProps = {
    countries: [],
    isFetching: null,
};


const mapStateToProps = state => ({
    countries: state.countryReducer.countries,
    isFetching: state.countryReducer.isFetching
});


export default connect(mapStateToProps
)(SelectCountry);

