import React from "react";

const PriceFormat  = ({price, currency}) => {
        price = isNaN(price) ? 0.0 : price;
        return <span>
            {price.toLocaleString(
                'fullwide',
                {
                    style: 'currency',
                    currency,
                    useGrouping: true
                })}
        </span>
};

export default PriceFormat;