import React from "react";
import {Card, CardBody, CardFooter, CardTitle, Col, Row} from "reactstrap";
import {translate} from "../../services/translationService";

class InactiveCarrier extends React.Component {

    render() {
        return (
            <Card className="card-stats m-auto">
                <CardBody >
                    <Row>
                        <Col xs={5} md={4} xl={1}>
                            <div className="icon-big text-center">
                                <i className="nc-icon nc-alert-circle-i text-warning"/>
                            </div>
                        </Col>
                        <Col xs={7} md={8} xl={11}>
                            <div className="numbers text-center">
                                <CardTitle tag="p">{translate('Your carrier account is inactive')}</CardTitle>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <hr/>
                    <p className="text-center">{translate('Upload required documents and fill yor company data and wait for activation')}</p>
                </CardFooter>
            </Card>
        );
    }
}
export default InactiveCarrier;
