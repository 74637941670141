import React from "react";
import {connect} from "react-redux";
import {getCustomers} from "../../../redux/actions/surcharges/customer.actions";
import Loader from "../../../components/Loader/Loader";
import "./styles.scss";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {translate} from "../../../services/translationService";
import {isEmpty} from "lodash";
import {priceApiService} from "../../../services/priceApiService";
import {alertActions} from "../../../redux/actions";
import MediaQuery from "react-responsive";
import HelperButton from "../../../components/Helper/HelperButton";

class Customers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      discounts:  {},
      isUpdating:false,
      formIsValid:true,
      searchText:"",
      sortedCustomers:[]
    };
    this.discountChange = this.discountChange.bind(this);
    this.submitCustomers = this.submitCustomers.bind(this);
    this.applySearch = this.applySearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getCustomers());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const sortCustomersByName = (customers) => {
      return customers.sort((cust1, cust2) => {
        const name1 = cust1.name.toUpperCase();
        const name2 = cust2.name.toUpperCase();
        if (name1 < name2) {
          return -1;
        }
        if (name1 > name2) {
          return 1;
        }
        return 0;
      });
    }

    const {customers} = this.props;
    if (prevProps.customers.length !== customers.length){
      let discountObject = {}
      customers.forEach(customer=>{
        discountObject[customer.id] = {
          value : customer.discount.toString(),
          isValid : true,
          isVisible : true,
        };
      });
      this.setState({
        discounts: discountObject,
        filteredDiscounts:discountObject,
        searchText:"",
        sortedCustomers:sortCustomersByName(customers)
      });
    }
  }

  discountChange(e) {
    const value = e.target.value;
    const newDiscounts = {...this.state.discounts};
    const newValue =  value.replace(/([^0-9]+)/g, '');
    if (newValue.length <= 2){
      newDiscounts[e.target.name].value = newValue;
    }
    const isValid = value.length > 0;
    newDiscounts[e.target.name].isValid = isValid;
    this.setState({discounts:newDiscounts, formIsValid:isValid});
  }

  applySearch(e) {
    const showCustomer = (customer, searchValue) => {
      const name = customer.name ? customer.name.toLowerCase() : '';
      const email = customer.email ? customer.email.toLowerCase() : '';
      return name.indexOf(searchValue.toLowerCase()) > -1 || email.indexOf(searchValue.toLowerCase()) > -1;
    }
    const searchText = e.target.value;
    const {customers}= this.props;
    let newState = {
      searchText,
      discounts: {...this.state.discounts}
    }
    if (searchText.length > 2){
      customers.forEach(customer=>{
        newState.discounts[customer.id].isVisible = showCustomer(customer, searchText)
      })
    } else {
      customers.forEach(customer=>{
        newState.discounts[customer.id].isVisible = true
      })
    }
    this.setState(newState)
  }

  handleClear(){
    let newDiscounts = {...this.state.discounts};
    for (let customerId in newDiscounts){
      newDiscounts[customerId].isVisible = true;
    }
    let newState = {
      searchText:"",
      discounts: newDiscounts
    }
    this.setState(newState)
  }

  submitCustomers(){
    let customersToUpdate = [];
    const originalCustomers =  this.props.customers;
    const currentDiscounts = this.state.discounts;
    originalCustomers.forEach(customer=>{
      let inputValue = parseFloat(currentDiscounts[customer.id].value);
      if(customer.discount !== inputValue){
          let updatedCustomer = {
            id_customer:customer.id,
            discount:inputValue
          };
          customersToUpdate.push(updatedCustomer)
      }
    })
    this.setState({isUpdating:true});
    priceApiService.updateCustomers(customersToUpdate)
      .then(response=>{
        this.setState({isUpdating:false});
        this.props.dispatch(alertActions.success(response.messages.success[0]));
      })
      .catch(error=>{
        this.props.dispatch(alertActions.error(translate("Failed to upload discounts")));
        this.setState({isUpdating:false});
      });
  }

  render() {
    const {isFetching} = this.props;
    const {discounts, isUpdating, formIsValid, searchText, sortedCustomers} = this.state;
    const noDiscounts = isEmpty(discounts);
    if (isFetching) {
      return (
        <div className="content">
          <Loader />
        </div>
      );
    }

    if (noDiscounts) {
      return <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <div className="centered">
                  <h5 className="customers-title">{translate("There are no linked customers")}</h5>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
    }

    return <div className="content">
      <Row>
        <Col md={12}>
          <Card className="customer-card">
            <CardBody>
              <div className="centered customer-title-bar">
                <div className="search-container">
                  <input value={searchText}
                         onChange={this.applySearch}
                         placeholder={translate("search text (min. 3 chars)")}
                  />
                  {searchText.length > 0 && (
                    <span className="clear-button" onClick={this.handleClear}>x</span>
                  )}
                </div>
                <h5 className="customers-title">{translate("Discount settings")}</h5>
              </div>
              <div className="customers-list">
              <MediaQuery query='(min-device-width: 860px)'>
                <div className="customers-row customers-title">
                  <div>{translate("Customer name")}</div>
                  <div>{translate("Contact person")}</div>
                  <div>{translate("Email")}</div>
                  <div>{translate("Discount")}<span>{'\u00A0'}%</span></div>
                </div>
                {sortedCustomers.map((customer, key) => discounts[customer.id].isVisible ? <div className={"customers-row"} key={key}>
                  <div>{customer.name}</div>
                  <div>{customer.contactPerson}</div>
                  <div>{customer.email}</div>
                  <div><input value={discounts[customer.id].value} name={customer.id}
                              onChange={this.discountChange}
                              className={(discounts[customer.id].isValid) ? "" : "invalid-input"}
                  />
                  </div>
                </div> : null)}
              </MediaQuery>
              <MediaQuery query='(max-device-width: 859px)'>
                {sortedCustomers.map((customer, key) => discounts[customer.id].isVisible ? <div className={"customer-card"} key={key}>
                    <div className="customer-card-title">{translate("Customer name")}</div>
                    <div>{customer.name}</div>
                    <div className="customer-card-title">{translate("Contact person")}</div>
                    <div>{customer.contactPerson ? customer.contactPerson : "-"}</div>
                    <div className="customer-card-title">{translate("Email")}</div>
                    <div>{customer.email}</div>
                    <div className="discount-row">
                      <div className="customer-card-title">{translate("Discount")}<span>{'\u00A0'}%</span></div>
                      <div><input value={discounts[customer.id].value} name={customer.id}
                                  onChange={this.discountChange}
                                  className={(discounts[customer.id].isValid) ? "" : "invalid-input"}
                      />
                      </div>
                    </div>

                </div> : null)}
              </MediaQuery>
              </div>
              <div className="primary-button-container">
                <Button color="primary" size={"sm"} onClick={this.submitCustomers} disabled={!formIsValid}>
                  {isUpdating ? <Loader /> : translate("Save")}
                </Button>
              </div>
              <HelperButton helperId="carrier-customers-list" />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  }
}


const mapStateToProps = (state) => ({
  customers: state.customersReducer.customers,
  isFetching: state.customersReducer.isFetching,
});

export default connect(mapStateToProps)(Customers);