import {Field, Form, formValueSelector, reduxForm} from "redux-form";
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import React from "react";
import {translate} from "services/translationService";
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Loader from "components/Loader/Loader";
import {validateFuelSurcharge} from "../validation";
import {connect} from "react-redux";
import {alertActions, getFuelSurcharge} from "../../../../redux/actions";
import {priceApiService} from "../../../../services/priceApiService";

class FuelSurchargeForm extends React.Component {

  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.addSurcharge = this.addSurcharge.bind(this);
    this.canFuelBeAdded = this.canFuelBeAdded.bind(this);
    this.state = {
      downloadingFile: false,
      isAddingSurcharge: false,
      showModal:false,
      downloadingList:false,
      uploadFile:null
    }
  }

  toggleModal() {
    this.setState({
      showModal:!this.state.showModal
    })
  }

  canFuelBeAdded = () => {
    const value = this.props.fuelSurchargeValue;
    if (typeof value === 'undefined' || isNaN(parseInt(value))) {
      return false;
    }
    return !(parseInt(value) < -100 || parseInt(value) > 100);
  }

  addSurcharge = () => {
    const {fuelSurchargeValue, countryToValue, countryFromValue, dispatch} = this.props;
    const fuelParam = {
      value: parseInt(fuelSurchargeValue)
    }
    this.setState({
      isAddingSurcharge: true
    });
    priceApiService.uploadSurchargesData(`fuel/${countryFromValue}/${countryToValue}`, fuelParam).then(response => {
      dispatch(alertActions.success(translate("Fuel surcharge added successfully")));
      this.setState({
        isAddingSurcharge: false
      });
      const surchargeData = {
        countyFrom: countryFromValue,
        countryTo: countryToValue
      }
      dispatch(getFuelSurcharge(surchargeData));
    }).catch((apiError) => {
      console.log(apiError);
      dispatch(alertActions.error(translate("Failed to add fuel surcharge")));
      this.setState({
        isAddingSurcharge: false
      });
    })
  }

  componentDidUpdate(prevProps) {
    const {countryFromValue, countryToValue, dispatch} = this.props;
    if ((prevProps.countryFromValue !== countryFromValue) || (prevProps.countryToValue !== countryToValue)) {
      const surchargeData = {
        countyFrom: countryFromValue,
        countryTo: countryToValue
      }
      dispatch(getFuelSurcharge(surchargeData));
    }
  }

  render() {

    const {isAddingSurcharge} = this.state;
    const {countryFromValue, countryToValue, isFetching, fuelSurchargeList} = this.props;
    const fuelPlaceholder = fuelSurchargeList.length > 0 ? fuelSurchargeList[0].value.toString() : "";
    const uploadIsPossible = !isAddingSurcharge && this.canFuelBeAdded()

    return <>
    <Form className="cooperation-form fuel-surcharge" onSubmit={()=>{}}>
     <Row>
        <Col md={12}>
          <div className="prices-title">
            <Button color="primary" size={"sm"} onClick={this.toggleModal} disabled={isFetching}>
              {isAddingSurcharge ? <Loader /> : translate("History of surcharges")}
            </Button>
          <div className="centered">
            <h5 className="cooperation-title">{`${translate("Fuel surcharge")} ${countryFromValue} - ${countryToValue}`} </h5>
          </div>
          </div>
        </Col>
     </Row>
     <Row>
        <Col md={6}>
          <Field
            inputId="fuelSurcharge"
            name="fuelSurcharge"
            type="number"
            label={translate("Fuel surcharge") + " €"}
            component={FieldInput}
            placeholder={fuelPlaceholder}
            labelClassName="col-md-6"
          />
        </Col>
        <Col md={6}>
          <div className="button-container">
            <Button color="primary" size={"sm"} onClick={this.addSurcharge} disabled={!uploadIsPossible}>
              {isFetching ? <Loader /> : translate("Add surcharge")}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
      <Modal isOpen={this.state.showModal} toggle={this.toggleModal} size="lg" scrollable={true} className={"fuel-surcharge-modal"}>
        <ModalHeader toggle={this.toggleModal}>
          {translate("List of uploaded surcharges")}
        </ModalHeader>
        <ModalBody>
          <div className="fuel-surcharge-modal">
            <div className="fuel-surcharge-row header">
              <div>{translate("Value (€)")}</div>
              <div>{translate("Valid from ")}</div>
            </div>
            {this.props.fuelSurchargeList.map((surcharge,i)=><div className="fuel-surcharge-row" key={i}>
              <div>{surcharge.value}</div>
              <div>{surcharge.created_at}</div>
          </div>)}
          </div>
        </ModalBody>
      </Modal>
    </>
  }
}

FuelSurchargeForm = reduxForm({
  form: 'fuelSurchargeForm',
  validate: validateFuelSurcharge,
  enableReinitialize: true
})(FuelSurchargeForm);

const countriesSelector = formValueSelector('countriesSelectorForm');
const selector = formValueSelector('fuelSurchargeForm');

const mapStateToProps = (state) => {
  return {
          countryToValue: countriesSelector(state, 'countryTo'),
          countryFromValue: countriesSelector(state, 'countryFrom'),
          fuelSurchargeValue: selector(state, 'fuelSurcharge'),
          fuelSurchargeList: state.fuelSurchargesReducer.surcharges,
          isFetching: state.fuelSurchargesReducer.isFetching,
          error: state.fuelSurchargesReducer.error
  }
}

FuelSurchargeForm = connect(mapStateToProps)(FuelSurchargeForm)

export default FuelSurchargeForm;
