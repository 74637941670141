import React from "react";
import {Card, CardBody, Label, Col, Row, Table, UncontrolledPopover, PopoverBody} from "reactstrap";
import { connect } from 'react-redux'
import { translate } from 'services/translationService';
import {getVehicle} from "redux/actions/car";
import Loader from "components/Loader/Loader";
import BodyTypeBadge from "../../BodyTypeBadge";
import CheckIcon from "../../CheckIcon";


class TruckTrailer extends React.Component {

    constructor(props) {
        super(props);
        this.trailerId = this.props.match.params.trailerId;
    }

    componentDidMount() {
        this.props.dispatch(getVehicle(this.trailerId))
    }


    render() {
        const {isFetching, truckTrailer} = this.props;
        return (
            <div className="content">
                {!(isFetching) && truckTrailer.hasOwnProperty('container') ? (
                    <Row>
                        <Col md={12}>
                            <Card className="vehicle-details">
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <h5><i className="nc-icon nc-alert-circle-i" /> {translate('Truck Trailer details') } </h5>
                                            <hr />
                                        </Col>
                                        <Col md={4}>
                                            <Table size="sm" borderless>
                                                <tbody>
                                                <tr>
                                                    <th>
                                                        <label>{translate('Model / Brand')}: </label>
                                                    </th>
                                                    <td>
                                                        {truckTrailer.brand.name} {truckTrailer.model.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label>{translate('Register number')}: </label>
                                                    </th>
                                                    <td>
                                                        {truckTrailer.registrationNumber}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <Label>{translate("Production Year")}</Label>
                                                    </th>
                                                    <td>
                                                        {truckTrailer.productionYear}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>

                                        <Col md={4}>
                                            <Table size="sm" borderless>
                                                <tbody>
                                                <tr>
                                                    <th>
                                                        <label>{translate('Body type')}: </label>
                                                    </th>
                                                    <td>
                                                        <BodyTypeBadge specification={truckTrailer.container}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label>{translate('Is part of set')}: </label>
                                                    </th>
                                                    <td>
                                                        <CheckIcon checkPresent={truckTrailer.isPartOfSet} />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md={12}>
                            <Card className="container-details">
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <h5><i className="nc-icon nc-alert-circle-i" /> {translate('Detailed specification') } </h5>
                                            <hr />
                                        </Col>
                                        <Col md={4}>
                                            <Table size="sm" borderless>
                                                <tbody>
                                                <tr>
                                                    <th>
                                                        <label>{translate('Dimensions (L/W/H)')}: </label>
                                                    </th>
                                                    <td>
                                                        {truckTrailer.container.length} / {truckTrailer.container.width} / {truckTrailer.container.height} cm
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label id="wheel_arch" style={{cursor: "help"}}>{translate('Wheel Arch')}: </label>
                                                        <UncontrolledPopover trigger="legacy" placement="top" target="wheel_arch">
                                                            <PopoverBody>
                                                                {translate("wheel arch distance to front / wheel arch height / wheel arch length / wheel arch width")}
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                    </th>
                                                    <td>
                                                        {truckTrailer.container.wheelArchDistanceToFront} / {truckTrailer.container.wheelArchHeight} / {truckTrailer.container.wheelArchLength} / {truckTrailer.container.wheelArchWidth}
                                                         cm
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <Label>{translate("Doors (width/height)")}</Label>
                                                    </th>
                                                    <td>{truckTrailer.container.doorWidth} {truckTrailer.container.doorHeight} cm</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col md={4}>
                                            <Table size="sm" borderless>
                                                <tbody>
                                                <tr>
                                                    <th>
                                                        <label>{translate('Max weight')}: </label>
                                                    </th>
                                                    <td>
                                                        {truckTrailer.maxWeight} kg
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label id="maxPressure" style={{cursor: "help"}}>{translate('Max Pressure')}: </label>
                                                        <UncontrolledPopover trigger="legacy" placement="top" target="maxPressure">
                                                            <PopoverBody>
                                                                {translate("Max pressure for m2 in kg")}
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                    </th>
                                                    <td>
                                                        {truckTrailer.container.maxPressure} kg/m2
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col md={4}>
                                            <Table size="sm" borderless>
                                                <tbody>
                                                <tr>
                                                    <th><Label>{translate('Lift')}: </Label></th>
                                                    <td><CheckIcon checkPresent={truckTrailer.container.hasLift} /></td>
                                                </tr>
                                                <tr>
                                                    <th><Label>{translate('Cooler')}</Label> </th>
                                                    <td><CheckIcon checkPresent={truckTrailer.container.hasCooler} /></td>
                                                </tr>
                                                <tr>
                                                    <th><Label>{translate('Forklift')}</Label></th>
                                                    <td><CheckIcon checkPresent={truckTrailer.container.hasForklift}/></td>
                                                </tr>
                                                <tr>
                                                    <th><Label>{translate('Pallet Truck')}</Label></th>
                                                    <td><CheckIcon checkPresent={truckTrailer.container.hasPalletTruck}/></td>
                                                </tr>
                                                <tr>
                                                    <th><Label>{translate('ADR')}</Label></th>
                                                    <td><CheckIcon checkPresent={truckTrailer.container.hasAdr}/></td>
                                                </tr>
                                                <tr>
                                                    <th><Label>{translate('Edscha')}</Label></th>
                                                    <td><CheckIcon checkPresent={truckTrailer.container.isEdscha}/></td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Loader />
                )}
            </div>
        );
    }
}

TruckTrailer.defaultProps = {
    isFetching: true,
    truckTrailer: {}
};

const mapStateToProps = (state) => {
    return {
        truckTrailer: state.vehicleReducer.vehicle,
        isFetching: state.vehicleReducer.isFetching,
        error: state.vehicleReducer.error,
    }
};
export default connect(mapStateToProps)(TruckTrailer);
