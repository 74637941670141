import {formValueSelector, reduxForm} from "redux-form";
import React from "react";
import {connect} from "react-redux";
import {getSurchargesByHeight} from "../../../../redux/actions";
import Loader from "../../../../components/Loader/Loader";
import {translate} from "../../../../services/translationService";
import HeightOptionsForm from "./HeightOptionsForm";

class HeightOptions extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        const {countryFromValue, countryToValue, dispatch} = this.props;
        if ((prevProps.countryFromValue !== countryFromValue) || (prevProps.countryToValue !== countryToValue)) {
            const surchargeData = {
                countyFrom: countryFromValue,
                countryTo: countryToValue
            }
            dispatch(getSurchargesByHeight(surchargeData));
        }
    }

    render() {
        const {countryFromValue, countryToValue, surcharges, isFetching} = this.props;

        return <>
            <div className="centered">
                <h5 className="cooperation-title">{`${translate("Height surcharges")} ${countryFromValue} - ${countryToValue}`} </h5>
            </div>
            {isFetching ? <Loader /> :
                <HeightOptionsForm
                    initialSurcharges={surcharges}
                    countryFrom={countryFromValue}
                    countryTo={countryToValue}
                />
            }
        </>
    }
}

HeightOptions.defaultProps = {
    surcharges: [],
    isFetching: null,
    error: false,
};

const selector = formValueSelector('countriesSelectorForm');

const mapStateToProps = (state) => {
    return {
        countryFromValue: selector(state, 'countryFrom'),
        countryToValue: selector(state, 'countryTo'),
        surcharges: state.heightSurchargesReducer.surcharges,
        isFetching: state.heightSurchargesReducer.isFetching,
        error: state.heightSurchargesReducer.error,
    };
};

export default connect(mapStateToProps)(HeightOptions);