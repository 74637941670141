import React from "react";
import {Field, FormSection} from "redux-form";
import {Col, PopoverBody, Row, UncontrolledPopover} from "reactstrap";
import {translate} from "services/translationService";
import "../Specifications/style.scss";
import FieldInput from "../../../../components/FormFields/FieldInput/FieldInput";
import FieldCheckbox from "../../../../components/FormFields/FieldCheckbox/FieldCheckbox";

class EditSpecification extends React.Component {

    render() {
        return (
            <div className="specification-container">
                <FormSection name="container">
                    <Row>
                        <Col md={12}>
                            <span className="specification-header">{translate("Dimensions")}</span>
                        </Col>
                    </Row>
                    <Row className={"car-form-row"}>
                        <Col md={2} xs={12}>
                            <span className={"label"}>{translate("L/W/H")}</span>
                            <div className="d-flex flex-row align-items-center flex-fill">
                                <Field
                                    name="length"
                                    type="text"
                                    component={FieldInput}
                                />
                                <Field
                                    name="width"
                                    type="text"
                                    component={FieldInput}
                                />
                                <Field
                                    name="height"
                                    type="text"
                                    component={FieldInput}
                                />
                                cm
                            </div>
                        </Col>
                        <Col md={2} xs={12}>
                            <span className={"label"}>{translate("Doors")} {translate("(W/H)")}</span>
                            <div className="d-flex flex-row align-items-center flex-fill">
                                <Field
                                    name="doorWidth"
                                    type="text"
                                    component={FieldInput}
                                />
                                <Field
                                    name="doorHeight"
                                    type="text"
                                    component={FieldInput}
                                />
                                cm
                            </div>
                        </Col>
                        <Col md={2} xs={12}>

                            <span className="label" id="wheel_arch"
                                  style={{cursor: "help"}}>{translate("Wheel Arch")}</span>
                            <UncontrolledPopover trigger="legacy" placement="top" target="wheel_arch">
                                <PopoverBody>
                                    {translate("wheel arch distance to front / wheel arch height / wheel arch length / wheel arch width")}
                                </PopoverBody>
                            </UncontrolledPopover>
                            <div className="d-flex flex-row align-items-center flex-fill">
                                <Field
                                    name="wheelArchDistanceToFront"
                                    type="text"
                                    component={FieldInput}
                                />
                                <Field
                                    name="wheelArchHeight"
                                    type="text"
                                    component={FieldInput}
                                />
                                <Field
                                    name="wheelArchLength"
                                    type="text"
                                    component={FieldInput}
                                />
                                <Field
                                    name="wheelArchWidth"
                                    type="text"
                                    component={FieldInput}
                                />
                            </div>
                        </Col>
                        <Col md={2} xs={12}>
                            <span className="label">{translate("Max pressure")}</span>
                            <div className="d-flex flex-row align-items-center flex-fill">
                                <Field
                                    name="maxPressure"
                                    type="text"
                                    component={FieldInput}
                                /> kg/m2
                            </div>
                        </Col>
                        <Col md={1} xs={6}>
                            <span className="label" id="max-weight" style={{cursor: "help"}}>{translate("Max Weight")}</span>
                            <UncontrolledPopover trigger="legacy" placement="top" target="max-weight">
                                <PopoverBody>
                                    {translate("Max load weight")}
                                </PopoverBody>
                            </UncontrolledPopover>
                            <Field
                                name="maxWeight"
                                type="text"
                                component={FieldInput}
                            />
                        </Col>
                        <Col md={1} xs={6}>
                            <span className="label" id="real-weight" style={{cursor: "help"}}>{translate("Real Weight")}</span>
                            <UncontrolledPopover trigger="legacy" placement="top" target="real-weight">
                                <PopoverBody>
                                    {translate("Real load weight (considering vehicle special equipment")}
                                </PopoverBody>
                            </UncontrolledPopover>
                            <Field
                                name="maxWeightReal"
                                type="text"
                                component={FieldInput}
                            />
                        </Col>
                        <Col md={1} xs={6}>
                            <span className="label" id="tot-weight" style={{cursor: "help"}}>{translate("Tot. Weight")}</span>
                            <UncontrolledPopover trigger="legacy" placement="top" target="tot-weight">
                                <PopoverBody>
                                    {translate("Gross vehicle mass")}
                                </PopoverBody>
                            </UncontrolledPopover>
                            <Field
                                name="containerTotalWeight"
                                type="text"
                                component={FieldInput}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                       <span className="specification-header">
                        {translate("Additional Options")}
                       </span>
                        </Col>
                    </Row>
                    <Row className={"car-form-row"}>
                        <Col md={3} xs={12}>
                            <Field
                                name="hasForklift"
                                label={translate("Forklift")}
                                component={FieldCheckbox}
                            />
                        </Col>
                        <Col md={3} xs={12}>
                            <Field
                                name="hasPalletTruck"
                                label={translate("Pallet Truck")}
                                component={FieldCheckbox}
                            />
                        </Col>
                        <Col md={3} xs={12}>
                            <Field
                                name="hasAdr"
                                label={translate("ADR")}
                                component={FieldCheckbox}
                            />
                        </Col>
                    </Row>
                </FormSection>
            </div>
        )
    }
}

export default EditSpecification;
