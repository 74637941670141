import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import CardHeader from "reactstrap/es/CardHeader";
import Map from "../../../../components/GoogleMap/Map";
import "./VehicleLocation.scss"
import {translate} from "../../../../services/translationService";
import mapService from "../../../../services/mapService";
import Button from "reactstrap/es/Button";
import CustomInput from "reactstrap/es/CustomInput";
import {apiService} from "../../../../services/apiService";
import {connect} from "react-redux";
import {alertActions} from "../../../../redux/actions";
import Loader from "../../../../components/Loader/Loader";
import {reduxForm} from "redux-form";
import {LocationValidation} from "../../../OrderDetails/OrderStatus/LocationValdation";
import VehicleLocationForm from "./VehicleLocationForm";
import loggedUserService from "../../../../services/loggedUserService";

class VehicleLocation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            updatedLocation: false,
            isSaving: false,
            showAddressForm: false
        }
        this.isManager = loggedUserService.isManager();
        this.map = {};
        this.marker = {};
        this.location = {
            lat: this.props.carData.hasOwnProperty('lat') ? this.props.carData.lat : null,
            lng: this.props.carData.hasOwnProperty('lng') ? this.props.carData.lng : null
        };
        this.onMapLoad = this.onMapLoad.bind(this);
        this.draggedMarker = this.draggedMarker.bind(this);
        this.clickedMap = this.clickedMap.bind(this);
        this.saveLocation = this.saveLocation.bind(this);
        this.setLocation = this.setLocation.bind(this);
    }

    componentDidMount() {
    }

    draggedMarker(e) {
        this.location = {
            lat: this.marker.getPosition().lat(),
            lng: this.marker.getPosition().lng()
        };
        const {updatedLocation} = this.state;
        if (!updatedLocation) {
            this.setState({
                updatedLocation: true
            })
        }
    }

    clickedMap(e) {
        this.location = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        }
        if (Object.keys(this.marker).length === 0) {
            const car = this.props.carData;
            this.marker = mapService.createMarker(car, this.map);
            this.marker.addListener('dragend', this.draggedMarker);
        }
        this.marker.setPosition(this.location);
        const {updatedLocation} = this.state;
        if (!updatedLocation) {
            this.setState({
                updatedLocation: true
            })
        }
    }

    onMapLoad(directionsService, directionsRenderer, map) {
        this.map = map;
        if (this.isManager){
            map.addListener('click', this.clickedMap);
        }
        const car = this.props.carData;
        if (this.location.lat && this.location.lng) {
            this.marker = mapService.createMarker(car, this.map);
            this.marker.setPosition(this.location);
            this.map.setCenter(this.location);
            if (this.isManager){
                this.marker.addListener('dragend', this.draggedMarker);
            }
        }
    }

    setLocation(lat, lng) {
        this.location.lat = parseFloat(lat);
        this.location.lng = parseFloat(lng);
        this.setState({
            updatedLocation: (lat != null && lng != null)
        });
        if (!this.marker.hasOwnProperty('setPosition')){
            const car = this.props.carData;
            this.marker = mapService.createMarker(car, this.map);
        }
        this.marker.setPosition(this.location);
        this.map.setCenter(this.location);
    }

    saveLocation() {
        this.setState({
            isSaving: true
        });
        const car = this.props.carData;
        const locationData = {
            lat: this.location.lat,
            lng: this.location.lng
        }
        apiService.put(`api/vehicles/${car.id}/location`, locationData).then(
            response => {
                this.setState({
                    isSaving: false
                });
                this.props.dispatch(alertActions.success(translate("Location updated")));
                this.map.setCenter(this.location);
            })
            .catch(error => {
                this.props.dispatch(alertActions.error(translate("Error during location saving")));
                this.setState({
                    isSaving: false
                });
            });
    }

    render() {
        const {showAddressForm} = this.state;
        return <Row>
            <Col md={12}>
                <Card className="vehicle-location">
                    <CardHeader>
                        <Row>
                            <Col md={5}>
                                <div className="location-controls">
                                    <h5>
                                        <i className="nc-icon nc-pin-3"/>{" "}
                                        {translate("Vehicle location")}
                                        {" "}
                                    </h5>
                                    {this.isManager && <><
                                        Button color="primary" size="sm" disabled={!this.state.updatedLocation}
                                                                 onClick={this.saveLocation}>
                                        {this.state.isSaving ? <Loader/> : translate("Save")}
                                    </Button>
                                        <CustomInput type="switch" id="address-switch"
                                                     checked={this.state.showAddressForm}
                                                     onChange={() => {
                                                         this.setState({showAddressForm: !this.state.showAddressForm})
                                                     }}
                                                     label={translate("Enter address")}/>
                                    </>}
                                </div>
                            </Col>
                        </Row>
                        <hr/>
                    </CardHeader>
                    <CardBody>
                        {showAddressForm && <div className={"vehicle-location-form"}>
                            <VehicleLocationForm
                                countries={this.props.countries}
                                initialValues={this.props.initialValues}
                                setLocation={this.setLocation}
                            />
                        </div>}
                        <div className="vehicle-location-display">
                            <Map
                                id="vehicleLocation"
                                options={{
                                    center: {lat: 50.785418, lng: 10.242753},
                                    zoom: 10,
                                    scaleControl: true,
                                    streetViewControl: false,
                                    mapTypeControl: false
                                }}
                                onMapLoad={this.onMapLoad}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    }
}

VehicleLocation.defaultProps = {
    countries: {},
    isFetching: null,
    error: false,
};

const mapStateToProps = state => ({
    countries: state.countryReducer.countries,
    isFetching: state.countryReducer.isFetching,
    error: state.countryReducer.error,
    initialValues: {
        country: "DE",
    }
});

export default connect(mapStateToProps)(VehicleLocation);
