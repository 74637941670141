import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {history} from "../../../helpers";
import {apiService} from "../../../services/apiService";

export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

export function getCustomers() {
    return dispatch => {
        dispatch(request({}));
        apiService.get('api/customers')
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                history.push('/dashboard');
            });
    };

    function request(data) { return { type: FETCH_CUSTOMERS_REQUEST, data } }
    function success(data) { return { type: FETCH_CUSTOMERS_SUCCESS, data } }
    function failure(error) { return { type: FETCH_CUSTOMERS_FAILURE, error } }
}
