import React from "react";
import {Card, CardBody, Col, Label, PopoverBody, Row, Table, UncontrolledPopover} from "reactstrap";
import {translate} from 'services/translationService';
import "../../style.scss";
import {getContainerFeatures, containerHasFeatures} from "../../../../helpers/containerHelper";
import BodyTypeBadge from "../../BodyTypeBadge";

class StorageSpecification extends React.Component {

    render() {
        const {container, title} = this.props;
        return ((container !== undefined) ? <Card className="storage-details">
            <CardBody>
                <Row>
                    <Col md={12}>
                        <h5><i
                            className="nc-icon nc-alert-circle-i"/> {title}
                        </h5>
                        <hr/>
                        <Table size="sm" borderless>
                            <tbody>
                            <tr>
                                <th>
                                    <label>{translate('Dimensions (L/W/H)')}: </label>
                                </th>
                                <td className="halfWidth">
                                    {container.length} / {container.width} / {container.height} cm
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label id="wheel_arch"
                                           style={{cursor: "help"}}>{translate('Wheel Arch')}: </label>
                                    <UncontrolledPopover trigger="legacy" placement="top"
                                                         target="wheel_arch">
                                        <PopoverBody>
                                            {translate("wheel arch distance to front / wheel arch height / wheel arch length / wheel arch width")}
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </th>
                                <td>
                                    {container.wheelArchDistanceToFront} / {container.wheelArchHeight} / {container.wheelArchLength} / {container.wheelArchWidth}
                                    cm
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <Label>{translate("Doors (width/height)")}</Label>
                                </th>
                                <td>{container.doorWidth} / {container.doorHeight} cm</td>
                            </tr>
                            <tr>
                                <th>
                                    <Label>{translate("Max weight")}</Label>
                                </th>
                                <td>{container.maxWeight} kg</td>
                            </tr>
                            <tr>
                                <th>
                                    <label id="maxPressure"
                                           style={{cursor: "help"}}>{translate('Max Pressure')}: </label>
                                    <UncontrolledPopover trigger="legacy" placement="top"
                                                         target="maxPressure">
                                        <PopoverBody>
                                            {translate("Max pressure for m2 in kg")}
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </th>
                                <td>
                                    {container.maxPressure} kg/m2
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <Label>{translate("Body Type")}:</Label>
                                </th>
                                <td><BodyTypeBadge specification={container}/></td>
                            </tr>
                            {containerHasFeatures(container) && <tr>
                                <th>
                                    <Label>{translate("Container features")}</Label>
                                </th>
                                <td>
                                    {getContainerFeatures(container)}
                                </td>
                            </tr>}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </CardBody>
        </Card> :
            "")
    }
}

export default StorageSpecification;
