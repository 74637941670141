import { authConstants } from 'constants/authConstants.js';

let token = localStorage.getItem('authToken');
const initialState = token ? {
        loggedIn: true,
        token
    } :
    {
       /* loggedIn: false,
        loggingIn: false,*/
    };

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case authConstants.LOGIN_REQUEST:
            return {
                loggedIn: false,
                loggingIn: true,
                token: action.token
            };
        case authConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                loggingIn: false,
                token: action.token
            };
        case authConstants.LOGIN_FAILURE:
            return {};
        case authConstants.LOGOUT:
            return {
                loggedIn: false,
                loggingIn: false,
                token: null
            };
        case authConstants.RESET_PASSWORD_REQUEST:
            return {
                fetching: true,
            };
        case authConstants.RESET_PASSWORD_SUCCESS:
            return {
                fetching: false,
                resetPasswordRequested: true
            };
        case authConstants.RESET_PASSWORD_FAILURE:
            return {
                fetching: false,
                resetPasswordRequested: false
            };
        case authConstants.NEW_PASSWORD_REQUEST:
            return {
                fetching: true,
            };
        case authConstants.NEW_PASSWORD_SUCCESS:
            return {
                fetching: false,
                newPasswordRequested: true
            };
        case authConstants.NEW_PASSWORD_FAILURE:
            return {
                fetching: false,
                newPasswordRequested: false
            };
        default:
            return state
    }
}
