import {apiService} from "services/apiService";
import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";

export const FETCH_INQUIRIES_REQUEST = 'FETCH_INQUIRIES_REQUEST';
export const FETCH_INQUIRIES_SUCCESS = 'FETCH_INQUIRIES_SUCCESS';
export const FETCH_INQUIRIES_FAILURE = 'FETCH_INQUIRIES_FAILURE';

export const INQUIRIES_TYPES = {
  NEW: 'new',
  PENDING: 'pending',
  CLOSED: "closed",
};


export function getInquiries(type, params) {
  return dispatch => {
    dispatch(request({}));
    let options = {};

     switch (type) {
       case INQUIRIES_TYPES.NEW:
         options = {status: 0, hasOffers: false}
         break;
      case INQUIRIES_TYPES.PENDING:
        options = {status: 0, hasOffers: true}
        break;
       case INQUIRIES_TYPES.CLOSED:
         options = {status: 2, hasOffers: true}
         break
       default:
          options = {};
    }

    const queryParams = {...params, ...options}

    apiService.get('/api/inquiries', queryParams)
      .then(
        response => {
          dispatch(success(response));
        })
      .catch(errorMessage => {
        dispatch(failure(getErrorText(errorMessage)));
        dispatch(alertActions.error(getErrorText(errorMessage)));
      });
  };

  function request(data) { return { type: FETCH_INQUIRIES_REQUEST, data } }
  function success(data) { return { type: FETCH_INQUIRIES_SUCCESS, data } }
  function failure(error) { return { type: FETCH_INQUIRIES_FAILURE, error } }
}
