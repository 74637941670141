import React from "react";
import "./style.scss";

class WelcomeHeader extends React.Component {

    render() {

        return (
            <div className="container">
                <div className="banner">
                    <div className={"title"}><p>{this.props.title}</p></div>
                </div>
            </div>
        );
    }
}

export default WelcomeHeader;
