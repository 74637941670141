import React from "react";
import {Badge} from "reactstrap";
import {translate} from 'services/translationService';


const BodyTypeBadge = ({specification}) => {
    let styleClass = "";
    let bodyType = "";
    if (specification.isTilt) {
        styleClass = "tilt-badge";
        bodyType = translate("Tilt");
    }
    if (specification.isBox) {
        styleClass = "box-badge";
        bodyType = translate("Box Body");
    }
    if (specification.isPlatform) {
        styleClass = "platform-badge";
        bodyType = translate("Platform");
    }
    return (
        <Badge className={styleClass} pill>
            {bodyType}
        </Badge>);

};


export default BodyTypeBadge;
