import {authConstants} from 'constants/authConstants.js';
import {authService} from 'services';
import {alertActions} from 'redux/actions';
import {history} from 'helpers';
import loggedUserService from 'services/loggedUserService';
import {translate} from "../../services/translationService";
import getErrorText from "../../services/errorTextService";

export const authActions = {
    login,
    logout,
    forgotPassword,
    resetPassword
};

function login(email, password) {
    return dispatch => {
        dispatch(request({}));

        authService.login(email, password)
            .then(
                data => {
                    dispatch(success(data));
                    if(!loggedUserService.isCarrierActive()){
                        dispatch(alertActions.warning(translate('Your Carrier Account is inactive, available options are limited')));
                    }
                    dispatch(alertActions.clear());
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) {
        return {type: authConstants.LOGIN_REQUEST, data}
    }

    function success(data) {
        return {type: authConstants.LOGIN_SUCCESS, data}
    }

    function failure(error) {
        return {type: authConstants.LOGIN_FAILURE, error}
    }
}

function logout() {
    return dispatch => {
        dispatch(logoutAction());
        authService.logout();
    };
    function logoutAction(){
        return {type: authConstants.LOGOUT}
    }
}

function forgotPassword(email) {
    return dispatch => {
        const forgotPasswordTime = localStorage.getItem('forgotPasswordTime');
        if (forgotPasswordTime && (Date.now() - Date.parse(forgotPasswordTime) < 60000)) {
            dispatch(failure(''));
            dispatch(alertActions.error('Password reset already requested'));
            history.push('/login');
        } else {
            dispatch(request({}));
            const time = new Date();
            localStorage.setItem('forgotPasswordTime', time.toISOString());
            authService.forgotPassword(email)
                .then(
                    data => {
                        dispatch(success(data));
                        dispatch(alertActions.success(data.messages[0]));
                        history.push('/');
                    })
                .catch(errorMessage => {
                    dispatch(failure(getErrorText(errorMessage)));
                    dispatch(alertActions.error(getErrorText(errorMessage)));
                });
        }
    };

    function request(data) {
        return {type: authConstants.RESET_PASSWORD_REQUEST, data}
    }

    function success(data) {
        return {type: authConstants.RESET_PASSWORD_SUCCESS, data}
    }

    function failure(error) {
        return {type: authConstants.RESET_PASSWORD_FAILURE, error}
    }
}

function resetPassword(password) {
    return dispatch => {
            dispatch(request({}));
            authService.resetPassword(password)
                .then(
                    data => {
                        dispatch(success(data));
                        dispatch(alertActions.success(data.messages[0]));
                        history.push('/');
                    })
                .catch(errorMessage => {
                    dispatch(failure(getErrorText(errorMessage)));
                    dispatch(alertActions.error(getErrorText(errorMessage)));
                    history.push('/login');
                });
        };

    function request(data) {
        return {type: authConstants.NEW_PASSWORD_REQUEST, data}
    }

    function success(data) {
        return {type: authConstants.NEW_PASSWORD_SUCCESS, data}
    }

    function failure(error) {
        return {type: authConstants.NEW_PASSWORD_FAILURE, error}
    }
}
