import React from "react";
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import { connect } from 'react-redux'
import { createUser } from 'redux/actions/users/user.actions';
import { translate } from 'services/translationService';
import CreateUserForm from './CreateUserForm';
import {hasProvinces} from "../../../../helpers/countries";
import {parseUserRoles} from '../helpers.js';
import Loader from "components/Loader/Loader";
import "../style.scss";


class CreateUser extends React.Component {

    constructor(props) {
        super(props);
        this.userPageSubmitTrigger = this.userPageSubmitTrigger.bind(this);
    }

    userPageSubmitTrigger(formData) {
        parseUserRoles(formData);

        const {dispatch, countries} = this.props;
        const country = formData.address.country;
        if (!hasProvinces(countries, country)){
            delete formData.address.state_province;
        }
        dispatch(createUser(formData));
    }


    render() {
        const {isFetching} = this.props;
        return (
            <div className="content">

                <Row>
                    <Col md={12}>
                        <Card className="card-user">
                            <CardHeader>
                                <CardTitle>
                                    <h4><i className="nc-icon nc-simple-add" /> { translate('New User') } </h4>
                                    <hr />
                                </CardTitle>
                            </CardHeader>
                                <CardBody>
                                    <div className="outerForm">
                                        <CreateUserForm
                                            onSubmitHandler={this.userPageSubmitTrigger}
                                        />
                                        <div className={isFetching ? "formOverlay" : "formOverlay invisible "}>
                                           <Loader />
                                        </div>
                                    </div>

                                </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

CreateUser.defaultProps = {
    countries:{},
    isFetching: false
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        isFetching: state.userReducer.isFetching,
        error: state.userReducer.error,
        countries: state.countryReducer.countries
    }
}
export default connect(mapStateToProps)(CreateUser);
