import {translate} from "../../services/translationService";

const documentValidator = {
    validFrom: new RegExp('([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))'),
    expirationDate: new RegExp('([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))'),
}

const validate = (values) => {
    const errors = {};

    if (!values.document) {
        errors.document = translate('You have to upload document!');
    }

    if (values.validFrom && documentValidator.validFrom.test(values.validFrom) === false) {
        errors.validFrom = translate('Date should be in format: yyyy-mm-dd');
    }

    if (values.expirationDate && documentValidator.expirationDate.test(values.expirationDate) === false) {
        errors.expirationDate = translate('Date should be in format: yyyy-mm-dd');
    }

    if (values.validFrom && values.expirationDate) {
        let validFrom = new Date(values.validFrom);
        let expirationDate = new Date(values.expirationDate);
        if (validFrom > expirationDate) {
            errors.expirationDate = translate('Expiration date should be further than valid from date');
        }
    }

    return errors;
};

const addDocumentValidation = (values) => {
    return validate(values);
};

export {addDocumentValidation};
