import React from "react";
import {Card, CardBody, CardHeader, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import {translate} from 'services/translationService';
import {apiService} from "../../../../services/apiService";
import {alertActions, authActions} from "../../../../redux/actions";
import getErrorText from "../../../../services/errorTextService";
import Loader from "../../../../components/Loader/Loader";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {history} from 'helpers';
import HelperButton from "../../../../components/Helper/HelperButton";

class DeleteUser extends React.Component {

    constructor(props) {
        super(props);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.checkDelete = this.checkDelete.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.renderDeleteModalContent = this.renderDeleteModalContent.bind(this);
        this.state = {
            showDeleteModal:  false,
            isCheckingDelete: false,
            isDeleting: false,
            deleteStatus:null
        };
    }

    toggleDeleteModal() {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
        });
    }

    checkDelete() {
        const {dispatch} = this.props;
        this.setState(
          {isCheckingDelete:true}
        )
        apiService.get('api/accounts/check_delete')
          .then((response) => {
              this.setState({isCheckingDelete:false, deleteStatus:response.data, showDeleteModal:true})
          })
          .catch((apiError) => {
              dispatch(alertActions.error(getErrorText(apiError)));
              this.setState({isCheckingDelete:false})
          });
    }

    deleteAccount() {
        const {dispatch} = this.props;
        this.setState({isDeleting:true})
        apiService.put('api/accounts/remove')
          .then((response) => {
              this.setState({isDeleting:false});
              dispatch(authActions.logout());
              history.push("/deleted");
          })
          .catch((apiError) => {
              dispatch(alertActions.error(getErrorText(apiError)));
              this.setState({isDeleting:false, showDeleteModal:  false})
          });
    }

    renderDeleteModalContent() {
        const {deleteStatus} = this.state;
        if (!deleteStatus) return <span></span>
        const {deleteUser, deleteCarrier} = deleteStatus;
        if (!deleteCarrier && !deleteUser) return <div>
            <p className="delete-account-info">{translate("You cannot delete your account as you are the default manager.")}</p>
            <p className="delete-account-info">{translate("Please transfer this privilege to another manager before deleting your account.")}</p>
            <p className="delete-account-info">{translate("Alternatively you can also delete all others managers' accounts to proceed with deleting.")}</p>
            <p style={{textAlign:"center", marginBottom:0}}>
                <Link to={`/users`}>
                    <Button color="primary" size={"sm"}>
                        {translate("Go to users management")}
                    </Button>
                </Link>
            </p>
        </div>
        if (deleteCarrier) return <div>
            <p className="delete-account-info"><i className="nc-icon nc-alert-circle-i delete-warning-icon"/>
                {translate("By deleting this account, you will also delete the company data and drivers' accounts data associated with it.")}
            </p>
        </div>
        if (deleteUser) return <div>
            <p className="delete-account-info"><i className="nc-icon nc-alert-circle-i delete-warning-icon"/>
                {translate("Are you sure? This operation cannot be undone.")}
            </p>
        </div>
        return <div></div>
    }

    render() {
        const {isCheckingDelete, deleteStatus, isDeleting} = this.state;
        return <>
          <Card className="card-user">
              <CardHeader>
                  <CardTitle>
                      <h4><i className="nc-icon nc-simple-remove" />{translate('Delete account')}</h4>
                      <hr />
                  </CardTitle>
              </CardHeader>
              <CardBody className="password-container">
                  <Row>
                      <div className="delete-section-content">
                          {translate('Deleting your account will also cause removal of all files and data connected with your account.')}
                          <br />
                          {translate('Only data necessary to process orders related to this account will be kept in the system.')}
                      </div>
                  </Row>
                  <Row>
                      <div className="update ml-auto mr-auto">
                          <Button color="warning" round onClick={this.checkDelete} style={{minWidth:"120px"}}>
                              {isCheckingDelete? <Loader /> : translate("Proceed")}
                          </Button>
                      </div>
                  </Row>
              </CardBody>
              <HelperButton helperId="carrier-user-delete" />
          </Card>
        <Modal isOpen={this.state.showDeleteModal} toggle={this.toggleDeleteModal}>
            <ModalHeader
              toggle={this.toggleDeleteModal}>{translate("Delete account")}
            </ModalHeader>
            <ModalBody>
                {this.renderDeleteModalContent()}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => this.toggleDeleteModal(null)}>
                    {translate("Cancel")}
                </Button>
                {(deleteStatus && (deleteStatus.deleteUser || deleteStatus.deleteCarrier)) && <Button color="danger" onClick={this.deleteAccount} style={{minWidth:"140px"}}>
                    {isDeleting ? <Loader /> : translate("Delete Account")}
                </Button>}
            </ModalFooter>
        </Modal>
        </>
    }
}

export default connect()(DeleteUser)
