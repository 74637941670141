export const searchParamsDidChange = (prevSearchParams, currentSearchParams) => {
    let prevProps = Object.getOwnPropertyNames(prevSearchParams);
    let currentProps = Object.getOwnPropertyNames(currentSearchParams);

    if (prevProps.length !== currentProps.length) {
        return true;
    }

    for (var i = 0; i < prevProps.length; i++) {
        var propName = prevProps[i];

        if (prevSearchParams[propName] !== currentSearchParams[propName]) {
            return true;
        }
    }
    return false;
};
