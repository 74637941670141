import {formValueSelector} from "redux-form";
import React from "react";
import {connect} from "react-redux";
import {
    getDedicatedSurchargesForSaturday,
    getDedicatedSurchargesForSunday,
    updateDedicatedSurchargesForSaturday,
    updateDedicatedSurchargesForSunday,
} from "../../../redux/actions";
import SurchargesForm from "./SurchargesForm";
import Loader from "../../../components/Loader/Loader";

class Surcharges extends React.Component {

    constructor(props) {
        super(props);
        this.updateSundayPrices = this.updateSundayPrices.bind(this);
        this.updateSaturdayPrices = this.updateSaturdayPrices.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {countryFromValue, countryToValue, dispatch} = this.props;
        if ((prevProps.countryFromValue !== countryFromValue) || (prevProps.countryToValue !== countryToValue)) {
            const pricesData = {
                countryFrom: countryFromValue,
                countryTo: countryToValue
            }
            dispatch(getDedicatedSurchargesForSaturday(pricesData));
            dispatch(getDedicatedSurchargesForSunday(pricesData));
        }
    }

    updateSundayPrices (formData)  {
        const {dispatch, sundaySurcharges} = this.props;
        let updatedSurcharges = sundaySurcharges.map((surcharge)=>{
            return {...surcharge, price: parseFloat(formData[surcharge.service_code])}
        });
        dispatch(updateDedicatedSurchargesForSunday(updatedSurcharges));
    }

    updateSaturdayPrices (formData)  {
        const {dispatch, saturdaySurcharges} = this.props;
        let updatedSurcharges = saturdaySurcharges.map((surcharge)=>{
            return {...surcharge, price: parseFloat(formData[surcharge.service_code])}
        });
        dispatch(updateDedicatedSurchargesForSaturday(updatedSurcharges));
    }

    render() {
        const {countryFromValue, countryToValue, isFetchingSunday, isFetchingSaturday, sundaySurcharges,
            saturdaySurcharges,  isUpdatingSunday, isUpdatingSaturday} = this.props;
        return <div className="surcharges-layout">
            {isFetchingSunday || isFetchingSaturday ? <Loader /> : <>
            <SurchargesForm
              countryFrom={countryFromValue}
              countryTo={countryToValue}
              initialPrices={saturdaySurcharges}
              isUpdating={isUpdatingSaturday}
              updatePrices={this.updateSaturdayPrices}
              type={"saturday"}
            />
            <SurchargesForm
              countryFrom={countryFromValue}
              countryTo={countryToValue}
              initialPrices={sundaySurcharges}
              isUpdating={isUpdatingSunday}
              updatePrices={this.updateSundayPrices}
              type={"sunday"}
            />
            </>}
        </div>
    }
}

Surcharges.defaultProps = {
    sundaySurcharges: [],
    saturdaySurcharges: [],
    isFetchingSunday: null,
    isFetchingSaturday: null,
    error: false,
};

const selector = formValueSelector('countriesSelectorForm');

const mapStateToProps = (state) => {
    return {
        countryFromValue: selector(state, 'countryFrom'),
        countryToValue: selector(state, 'countryTo'),
        sundaySurcharges: state.dedicatedSurchargesReducer.sundaySurcharges,
        saturdaySurcharges: state.dedicatedSurchargesReducer.saturdaySurcharges,
        isFetchingSunday: state.dedicatedSurchargesReducer.isFetchingSunday,
        isFetchingSaturday: state.dedicatedSurchargesReducer.isFetchingSaturday,
        error: state.dedicatedSurchargesReducer.error,
        isUpdatingSunday: state.dedicatedSurchargesReducer.isUpdatingSunday,
        isUpdatingSaturday: state.dedicatedSurchargesReducer.isUpdatingSaturday,
    };
};

export default connect(mapStateToProps)(Surcharges);