import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Jumbotron} from 'reactstrap';
import {translate} from "../../../services/translationService";
import {Link} from "react-router-dom";
import WelcomeHeader from "../components/WelcomeHeader/WelcomeHeader";
import {Logo} from "../components/Logo/Logo";
import Footer from "../../../components/Footer/Footer";
import {history} from "../../../helpers";

class RegisterInfo extends Component {

    componentDidMount() {
        if (!this.props.carrier.carrierDto && !this.props.carrier.accountDto) {
            history.push('/login');
        }
    }

    render() {
        const carrier = this.props.carrier.carrierDto;
        const account = this.props.carrier.accountDto;

        return (
            (carrier && account) ?
            <div className="Site-content">
                <WelcomeHeader title={translate("Carrier registration")}/>
                <Jumbotron className="text-center m-5 bg-light">
                    <h2 className="display-5">{translate('Your company ')} <strong>{carrier.name}</strong> {translate('has been created.')}</h2>
                        <p className="my-2">{translate('To be able to login you have to confirm your email address with click on link in confirmation email.')} </p>
                        <p className="my-2">{translate('Activation email was send to address: ')} <strong>{account.email}</strong></p>
                    <Link to="/login">← {translate("Back to login page")}</Link>
                </Jumbotron>
                <Logo />
                <Footer />
            </div>
                : ""
        );
    }
}

const mapStateToProps = state => ({
    carrier: state.carrierReducer.carrier,
});

export default connect(mapStateToProps)(RegisterInfo);

