import {PUSH_CHANGE_PAGE, RESET_PAGINATION, PUSH_CHANGE_PER_PAGE} from "../actions/pagination.actions";

const initialState = {
    userPaginator: {
        perPage: 10,
        page: 1,
        indexStart: 1
    },
    notificationPaginator: {
        perPage: 10,
        page: 1,
        indexStart: 1
    },
    orderPaginator: {
        perPage: 5,
        page: 1,
        indexStart: 1
    },
    invoicePaginator: {
        perPage: 15,
        page: 1,
        indexStart: 1
    },
    carSetsPaginator: {
        perPage: 10,
        page: 1,
        indexStart: 1
    },
    soloTrucksPaginator: {
        perPage: 10,
        page: 1,
        indexStart: 1
    },
    truckTrailersPaginator: {
        perPage: 10,
        page: 1,
        indexStart: 1
    },
    inquiriesPaginator: {
        perPage: 3,
        page: 1,
        indexStart: 1
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RESET_PAGINATION: {
            return Object.assign({}, state, {
                [action.paginator]: {
                    perPage: state[action.paginator].perPage,
                    page: 1,
                    indexStart: 1
                }
            });
        }

        case PUSH_CHANGE_PAGE: {
            return Object.assign({}, state, {
                [action.paginator]: {
                    perPage: state[action.paginator].perPage,
                    page: action.pageNumber,
                    indexStart: action.indexStart
                }
            });
        }

        case PUSH_CHANGE_PER_PAGE: {
            return Object.assign({}, state, {
                [action.paginator]: {
                    perPage: parseInt(action.perPage),
                    page: state[action.paginator].page,
                    indexStart: state[action.paginator].indexStart
                }
            });
        }

        default:
            return state
    }
}
