import {
    FETCH_DEDICATED_ALIASES_FAILURE,
    FETCH_DEDICATED_ALIASES_REQUEST,
    FETCH_DEDICATED_ALIASES_SUCCESS,
    ADD_DEDICATED_ALIASES_FAILURE,
    ADD_DEDICATED_ALIASES_REQUEST,
    ADD_DEDICATED_ALIASES_SUCCESS,
    DELETE_DEDICATED_ALIASES_REQUEST,
    DELETE_DEDICATED_ALIASES_SUCCESS,
    DELETE_DEDICATED_ALIASES_FAILURE
} from "../../actions";

const initialState = {
    aliases: [],
    isFetchingAliases: true,
    isUpdatingAliases:false,
    isDeletingAlias:false,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DEDICATED_ALIASES_REQUEST: {
            return Object.assign({}, state, {
                isFetchingAliases: true,
                error:false
            });
        }

        case FETCH_DEDICATED_ALIASES_SUCCESS: {
            return Object.assign({}, state, {
                aliases: action.data,
                isFetchingAliases: false,
            });
        }

        case FETCH_DEDICATED_ALIASES_FAILURE: {
            return Object.assign({}, state, {
                isFetchingAliases: false,
                error: true
            });
        }

        case ADD_DEDICATED_ALIASES_REQUEST: {
            return Object.assign({}, state, {
                isUpdatingAliases: true,
                error:false
            });
        }

        case ADD_DEDICATED_ALIASES_SUCCESS: {
            return Object.assign({}, state, {
                isUpdatingAliases: false,
            });
        }

        case ADD_DEDICATED_ALIASES_FAILURE: {
            return Object.assign({}, state, {
                isUpdatingAliases: false,
                error: true
            });
        }

        case DELETE_DEDICATED_ALIASES_REQUEST: {
            return Object.assign({}, state, {
                isDeletingAlias: true,
                error:false
            });
        }

        case DELETE_DEDICATED_ALIASES_SUCCESS: {
            return Object.assign({}, state, {
                isDeletingAlias: false,
            });
        }

        case DELETE_DEDICATED_ALIASES_FAILURE: {
            return Object.assign({}, state, {
                isDeletingAlias: false,
                error: true
            });
        }

        default:
            return state
    }
}
