import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Badge, Card, CardBody, CardTitle, Col, Row, Table} from "reactstrap";
import OrderDetailsPackages from "./OrderDetailsPackages";
import OrderDetailsAddress from "./OrderDetailsAddress";
import OrderAddressPalletBalance from "./OrderAddressPalletBalance";
import OrderStatus from "./OrderStatus/OrderStatus";
import DeliveryDocument from "./DeliveryDocument/DeliveryDocument";
import Loader from "components/Loader/Loader";
import {translate} from 'services/translationService';
import {getOrder, updateOrder} from "redux/actions/orders";
import AccountAssigment from "./AccountAssigment";
import {statusService} from "./statusService";
import loggedUserService from "services/loggedUserService";
import './OrderDetails.scss';
import PriceFormat from "../../components/PriceFormat/PriceFormat";
import ConnectCarToOrderModal from "./ConnectCarToOrderModal";
import VehicleAssigment from "./VehicleAssigment";
import routeService from "../../services/routeService";
import ShowRouteModal from "./RouteModal/ShowRouteModal";
import RouteDetails from "./RouteDetails";
import ShipmentRemarksList from "./ShipmentRemarks/ShipmentRemarksList";
import {alertActions} from "../../redux/actions";
import DeliveryDocuments from "./DeliveryDocument/DeliveryDocuments";
import HelperButton from "../../components/Helper/HelperButton";
import { store } from 'helpers';

class OrderDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showConnectCarModal: false,
            showRouteModal: false,
            routeOptions:{}
        };
        this.toggleConnectCarToOrderModal = this.toggleConnectCarToOrderModal.bind(this);
        this.updateOrderHandler = this.updateOrderHandler.bind(this);
        this.toggleRouteModal = this.toggleRouteModal.bind(this);
        this.renderShipmentOptions = this.renderShipmentOptions.bind(this);

    }
    componentDidMount() {
        const {orderId} = this.props.match.params;
        const {dispatch} = this.props;
        dispatch(getOrder(orderId));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {orderId} = this.props.match.params;
        if(orderId !== prevProps.match.params.orderId){
            const {dispatch} = this.props;
            dispatch(getOrder(orderId));
            this.setState({
                showConnectCarModal: false,
                showRouteModal: false,
                routeOptions:{}
            })
        }
    }

    updateOrderHandler(formData) {
        const {dispatch} = this.props;
        if (formData.vehicleId === null) {
            dispatch(alertActions.success(translate("Vehicle was unlinked from the order")));
        } else {
            dispatch(alertActions.success(translate("Vehicle was linked to the order")));
        }
            dispatch(updateOrder(this.props.order.id, formData));
        this.setState({
            showConnectCarModal: false,
        });
    }

    toggleConnectCarToOrderModal() {
        this.setState({
            showConnectCarModal: !this.state.showConnectCarModal
        },);
    }

    toggleRouteModal() {
        this.setState({
            showRouteModal: !this.state.showRouteModal
        });
    }

    renderShipmentOptions(){
        const {order:{shipment:{cargoOptions}}} = this.props;
        const optionNames = {
            adr:'Adr',
            cooler:'Cooler',
            edscha:'Edscha',
            forkLift:'Fork lift',
            lift:'Lift',
            palletTruck:'Pallet truck',
            sideLoading:'Side loading'
        }
        let cargoOptionTypes = Object.keys(cargoOptions)

        return  <div className = {"options-container"}>
            {cargoOptionTypes.map((option, key) => <Badge pill
                      key={key}
                      color={cargoOptions[option] ? "primary":"secondary"}
                      title={translate(cargoOptions[option] ? "Active":"Inactive")}
                      className={"cargo-options-pill"}>
                {translate(optionNames[option])}
            </Badge>)}
        </div>
    }

    render() {
        const {isFetching, error, order} = this.props;
        const {showConnectCarModal, showRouteModal} = this.state;
        const showRoute = routeService.showRouteButton(order);
        const packages = order.hasOwnProperty('shipment') ? order.shipment.packages : null;
        const canEdit = !statusService.isOrderClosed(order.status) && !order.canceled;
        const vehicle = order.hasOwnProperty('vehicle') ? order.vehicle : null;
        const remarks = order.hasOwnProperty('remarks') ? order.remarks : null;
        return (
            <div className="orderDetailsContent">
                {!(isFetching) && !(error) && order.hasOwnProperty('shipment')?
                    <div>
                        {(loggedUserService.hasShipmentsConversationsAccess()) ?
                            <Row>
                                <Col lg="12" className="pb-1 d-flex justify-content-end">
                                    <shipment-conversation host={process.env.REACT_APP_SHIPMENTS_API_URL}
                                                           token={localStorage.getItem('priceApiToken')} apptype="2"
                                                           lang={store.getState().translationReducer.locale}
                                                           shipment={order.shipment.id}
                                                           shipmentnumber={order.shipment.number}
                                                           userid={JSON.parse(localStorage.getItem('user')).id}></shipment-conversation>
                                </Col>
                            </Row>
                            :''}

                        <Row>
                            <Col lg="6">
                                <OrderStatus order={order} canEdit={canEdit}/>
                                <HelperButton helperId="carrier-order-details-status"
                                              style={{top: '5px', right: '20px'}}/>
                            </Col>
                            <Col lg="6">
                                <DeliveryDocuments order={order} canEdit={canEdit}/>
                                <HelperButton helperId="carrier-order-details-documents"
                                              style={{top: '5px', right: '20px'}}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <Card>
                                    <CardTitle>
                                        <div className={"separator"}>{translate("General Information")}</div>
                                        <HelperButton helperId="carrier-order-details-general"
                                                      style={{top: '5px', right: '5px'}}/>
                                    </CardTitle>
                                    <CardBody className="table-full-width">
                                        <Table hover>
                                            <tbody>
                                            <tr>
                                                <th>{translate("Shipment Number")}</th>
                                                <td>{order.shipment.number}</td>
                                            </tr>
                                            <tr>
                                                <th>{translate("Description")}</th>
                                                <td>{order.shipment.description}</td>
                                            </tr>
                                            <tr>
                                                <th>{translate("Reference number")}</th>
                                                <td>{'referenceNumber' in order ? order.referenceNumber : "-"}</td>
                                            </tr>
                                            <tr>
                                                <th>{translate("Pickup date")}</th>
                                                <td>{order.pickupDate} | <strong>{order.pickupReadyTime} - {order.pickupCloseTime} </strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{translate("Delivery date")}</th>
                                                <td>{order.deliveryDate} | <strong>{order.shipment.deliveryTimeBegin} - {order.shipment.deliveryTimeEnd} </strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{translate("Assigned Vehicle & Drivers")}</th>
                                                <td>
                                                    <VehicleAssigment
                                                        vehicle={vehicle}
                                                        canEdit={canEdit}
                                                        order={order}
                                                        showModal={this.toggleConnectCarToOrderModal}
                                                        unlinkCar={this.updateOrderHandler}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{translate("Manager's name")}</th>
                                                <td>
                                                    <AccountAssigment
                                                        account={order.carrierManager}
                                                        name="managerId"
                                                        userRole="ROLE_MANAGER"
                                                        onSubmitHandler={this.updateOrderHandler}
                                                        canEdit={canEdit & loggedUserService.isManager()}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg="6">
                                <Card>
                                    <CardTitle>
                                        <div className={"separator"}>{translate("Additional Information")}</div>
                                        <HelperButton helperId="carrier-order-details-additional"
                                                      style={{top: '5px', right: '5px'}}/>
                                    </CardTitle>
                                    <CardBody className="table-full-width">
                                        <Table hover>
                                            <tbody>
                                            <tr>
                                                <th>{translate("Insurance value")}</th>
                                                <td>{order.shipment.insuranceValue}</td>
                                            </tr>
                                            <tr>
                                                <th>{translate("Service type")}</th>
                                                <td>{order.shipment.serviceName}</td>
                                            </tr>
                                            <tr>
                                                <th colSpan={2}>
                                                    {translate("Cargo options")}
                                                    {this.renderShipmentOptions()}
                                                </th>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>

                                <RouteDetails
                                    showRoute={showRoute}
                                    order={order}
                                    toggleRouteModal={this.toggleRouteModal}
                                />

                                <Card
                                    className={(isNaN(order.shipment.codAmount) || order.shipment.codAmount <= 0) ? "d-none" : ""}>
                                    <CardTitle>
                                        <div className={"separator"}>{translate("Cash on delivery")}</div>
                                        <HelperButton helperId="carrier-order-details-cash"
                                                      style={{top: '5px', right: '5px'}}/>
                                    </CardTitle>
                                    <CardBody className="table-full-width">
                                        <Table hover>
                                            <tbody>
                                            {loggedUserService.isManager() ?
                                                <>
                                                    <tr>
                                                        <th>{translate("Name")}</th>
                                                        <td>{order.shipment.codName}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{translate("Account number")}</th>
                                                        <td>{order.shipment.codBankAccountNumber}</td>
                                                    </tr>
                                                </>
                                                : <></>}
                                            <tr>
                                                <th>{translate("Amount")}</th>
                                                <td>
                                                    <PriceFormat
                                                        price={order.shipment.codAmount}
                                                        currency={this.props.order.shipment.currency}
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="6">
                                <Card>
                                    <CardTitle>
                                        <div className={"separator"}>{translate("Pickup Address")}</div>
                                        <HelperButton helperId="carrier-order-details-pickup"
                                                      style={{top: '5px', right: '5px'}}/>
                                    </CardTitle>
                                    <CardBody className="table-full-width">
                                        <OrderDetailsAddress address={order.pickupAddress}
                                                             pickupCode={order.pickupCode ? order.pickupCode : null}
                                                             typeOfCode={"Pick-up code"}/>
                                        <OrderAddressPalletBalance
                                            orderId={order.id}
                                            name={"pickupPalletBalance"}
                                        ></OrderAddressPalletBalance>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg="6">
                                <Card>
                                    <CardTitle>
                                        <div className={"separator"}>{translate("Delivery Address")}</div>
                                        <HelperButton helperId="carrier-order-details-delivery"
                                                      style={{top: '5px', right: '5px'}}/>
                                    </CardTitle>
                                    <CardBody className="table-full-width">
                                        <OrderDetailsAddress address={order.deliveryAddress}
                                                             deliveryCode={order.deliveryCode ? order.deliveryCode : null}
                                                             typeOfCode={"Delivery code"}/>
                                        <OrderAddressPalletBalance
                                            orderId={order.id}
                                            name={"deliveryPalletBalance"}
                                        ></OrderAddressPalletBalance>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {remarks && <Row className="d-flex justify-content-center">
                            <Col lg={10} md={12}>
                                <Card>
                                    <CardTitle>
                                        <div
                                            className={"separator"}>{translate("Shipment remarks")} ({remarks.length}):
                                        </div>
                                        <HelperButton helperId="carrier-order-details-remarks"
                                                      style={{top: '5px', right: '5px'}}/>
                                    </CardTitle>
                                    <CardBody className="">
                                        <ShipmentRemarksList remarks={remarks}/>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>}

                        <Row className="d-flex justify-content-center">
                            <Col lg={10} md={12}>
                                <Card>
                                    <CardTitle>
                                        <div className={"separator"}>{translate("Packages")} ({packages.length}):</div>
                                        <HelperButton helperId="carrier-order-details-packages"
                                                      style={{top: '5px', right: '5px'}}/>
                                    </CardTitle>
                                    <CardBody className="">
                                        <OrderDetailsPackages boxes={order.shipment.packages}/>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                        {loggedUserService.isManager() && <ConnectCarToOrderModal
                            toggleModal={this.toggleConnectCarToOrderModal}
                            showModal={showConnectCarModal}
                            assignToOrder={this.updateOrderHandler}
                            order={order}
                        />}
                        {showRoute && <ShowRouteModal
                            toggleModal={this.toggleRouteModal}
                            showModal={showRouteModal}
                            order={order}
                        />}
                    </div>
                    :
                    <Loader/>
                }
            </div>
        );
    }
}

OrderDetails.defaultProps = {
    order: null,
    isFetching: true,
    error: false
};

function mapStateToProps(state) {
    return {
        order: state.orderReducer.order,
        isFetching: state.orderReducer.isFetching,
        error: state.orderReducer.error
    };
}

export default connect(
    mapStateToProps,
)(OrderDetails);
