import {formValueSelector} from "redux-form";
import React from "react";
import {connect} from "react-redux";
import Loader from "../../../components/Loader/Loader";
import {translate} from "../../../services/translationService";
import {
    getDedicatedOptions,
    updateDedicatedOptions
} from "../../../redux/actions/dedicatedPrices/dedicatedOptions.actions";
import {Button, FormGroup, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import DedicatedOptionsForm from "./DedicatedOptionsForm";
import MediaQuery from "react-responsive";

class DedicatedOptions extends React.Component {

    constructor(props) {
        super(props);
        let initialActiveTab = localStorage.getItem("dedicatedTab");
        if (initialActiveTab == null) {
            localStorage.setItem("dedicatedTab", "1");
            initialActiveTab = "1";
        }
        this.state = {
            dedicatedTab: initialActiveTab,
            optionsTabs:[],
            optionsCodes:[],
            optionsInputs:{},
            formIsValid:true
        };
        this.updatePrices = this.updatePrices.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.changeInput = this.changeInput.bind(this);
    }

    toggleTab(tab) {
        this.setState({
            dedicatedTab: tab,
        });
        localStorage.setItem("dedicatedTab", tab);
    }


    componentDidUpdate(prevProps) {
        const {countryFromValue, countryToValue, dispatch, options} = this.props;
        if ((prevProps.countryFromValue !== countryFromValue) || (prevProps.countryToValue !== countryToValue)) {
            const pricesData = {
                countryFrom: countryFromValue,
                countryTo: countryToValue
            }
            dispatch(getDedicatedOptions(pricesData));
        }
        if (prevProps.isFetchingOptions === true && this.props.isFetchingOptions === false){
            let optionsInputs = {};
            let optionsCategoriesTempList = {};
            let optionsCodesTempList = {};
            let optionsCodes = []
            let optionsTabs = [];
                options.forEach(option => {
                    if (!optionsCategoriesTempList.hasOwnProperty(option.service_code)) {
                        optionsCategoriesTempList[option.service_code] = option.service_name;
                        optionsTabs.push({
                            service_code: option.service_code,
                            short_name: option.short_name,
                            is_valid:true
                        })
                    }
                    if (!optionsCodesTempList.hasOwnProperty(option.option_code)) {
                        optionsCodesTempList[option.option_code] = option.option_name;
                        optionsCodes.push({
                            option_code: option.option_code,
                            option_name: option.option_name
                        })
                    }

                    if (!optionsInputs.hasOwnProperty(option.service_code)) {
                        optionsInputs[option.service_code] = {}
                    }
                    optionsInputs[option.service_code][option.option_code] = {}
                    for (let maxDistance in option.prices) {
                        optionsInputs[option.service_code][option.option_code][maxDistance] = {...option.prices[maxDistance], isValid: true}
                    }
                })
            this.setState({optionsTabs, optionsInputs, optionsCodes});
        }
    }

    changeInput (e) {
        const {name} = e.target;
        const [serviceCode, option, distance, type] = name.split('-');
        let value;
        let isValid = true;
        if (type === 'const') {
            value = e.target.checked
        } else {
            value = e.target.value;
            if (value === "" || parseFloat(value) < 0) {
                isValid = false;
            }
        }
        let newInputValues = {...this.state.optionsInputs};
        newInputValues[serviceCode][option][distance][type] = value;
        newInputValues[serviceCode][option][distance].isValid = isValid;
        let newOptionTabs = this.state.optionsTabs.map(tab=> tab.service_code === serviceCode ?
          {...tab, is_valid:isValid} : tab
        );
        this.setState({pricesInputs: newInputValues, formIsValid: isValid, optionsTabs:newOptionTabs});
    }

    updatePrices ()  {
        const {dispatch, options} = this.props;
        const {optionsInputs} = this.state;
        const newOptions = [...options]
        newOptions.forEach(option => {
            option.prices = {...optionsInputs[option.service_code][option.option_code]};
            for (let distance in option.prices) {
                option.prices[distance] = {
                    ...option.prices[distance],
                    price: isNaN(parseFloat(option.prices[distance].price)) ? 0 : parseFloat(option.prices[distance].price),
                    max_distance: option.prices[distance].max_distance.toString()
                }
                delete option.prices[distance].isValid;
            }
        })
        dispatch(updateDedicatedOptions(newOptions));
    }

    render() {
        const {countryFromValue, countryToValue, options, isFetchingOptions, isUpdatingOptions} = this.props;
        const {optionsTabs, dedicatedTab, optionsInputs, optionsCodes, formIsValid} = this.state;
        if (isFetchingOptions) return <Loader />

        return <>
            <div className="centered">
                <h5 className="cooperation-title">{translate("Cargo Options surcharges")}</h5>
                <h5 className="cooperation-title">{`${countryFromValue} - ${countryToValue}`}</h5>
            </div>
            <MediaQuery query='(min-device-width: 860px)'>
            <Nav tabs className={"dedicated-tabs"}>
                {optionsTabs.map((tab, index)=> <NavItem key={index}>
                      <NavLink
                        className={classnames({active: dedicatedTab === tab.service_code, invalid: !tab.is_valid})}
                        onClick={() => {
                            this.toggleTab(tab.service_code);
                        }}
                      >
                          {tab.short_name}
                      </NavLink>
                  </NavItem>
                )}
            </Nav>
            <TabContent activeTab={dedicatedTab}>
                {optionsTabs.map((tab, index)=> <TabPane tabId={tab.service_code} key={index}>
                    <DedicatedOptionsForm
                      changeInput={this.changeInput}
                      code={tab.service_code}
                      inputs={optionsInputs}
                      optionCodes={optionsCodes}
                    />
                  </TabPane>
                )}
            </TabContent>
            </MediaQuery>
            <MediaQuery query='(max-device-width: 859px)'>
                <FormGroup>
                    <Label for="optionCode">{translate("Select vehicle type")}</Label>
                    <Input type="select" name="optionCode" id="optionCode"
                           value={dedicatedTab} onChange={(e) => {
                            this.toggleTab(e.target.value);
                        }
                    }>
                        {optionsTabs.map((tab, index)=> <option key={index} value={tab.service_code}
                           className={classnames({ invalid: !tab.is_valid})}>
                            {tab.short_name}
                        </option>)}
                    </Input>
                </FormGroup>
                <DedicatedOptionsForm
                  changeInput={this.changeInput}
                  code={dedicatedTab}
                  inputs={optionsInputs}
                  optionCodes={optionsCodes}
                />
            </MediaQuery>

            <div className="primary-button-container">
                <Button color="primary" size={"sm"} onClick={this.updatePrices} disabled={!formIsValid}>
                    {isUpdatingOptions ? <Loader /> : translate("Save")}
                </Button>
            </div>

        </>
    }
}

DedicatedOptions.defaultProps = {
    options: [],
    isFetchingOptions: null,
    isUpdatingOptions: null,
    error: false,
};

const selector = formValueSelector('countriesSelectorForm');

const mapStateToProps = (state) => {
    return {
        countryFromValue: selector(state, 'countryFrom'),
        countryToValue: selector(state, 'countryTo'),
        options: state.dedicatedOptionsReducer.options,
        isFetchingOptions: state.dedicatedOptionsReducer.isFetchingOptions,
        error: state.dedicatedOptionsReducer.error,
        isUpdatingOptions: state.dedicatedOptionsReducer.isUpdatingOptions
    };
};

export default connect(mapStateToProps)(DedicatedOptions);