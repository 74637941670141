import React from "react";
import { Alert  } from 'reactstrap';
import { connect } from 'react-redux';
import { alertActions } from 'redux/actions/alert.actions.js';
import "./style.scss";

class DissmisableAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = { visible: true };
        this.onDismiss = this.onDismiss.bind(this);
        this.dismissTimeout = null; // Hold timeout reference
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (Object.keys(prevProps.alert).length === 0 && Object.keys(this.props.alert).length > 0) {
            this.clearDismissTimeout(); // Clear existing timeout
            this.dismissTimeout = setTimeout(this.onDismiss, 5000); // Set new timeout
        }
    }

    componentWillUnmount() {
        this.clearDismissTimeout(); // Cleanup on unmount
    }

    clearDismissTimeout() {
        if (this.dismissTimeout) {
            clearTimeout(this.dismissTimeout);
            this.dismissTimeout = null;
        }
    }

    onDismiss() {
        this.props.dispatch(alertActions.clear());
    }

    render() {
        const alert = this.props.alert;
        return (
            <div>
                <Alert className={' ' + alert.type + ' fixed-top'} isOpen={!!(alert && alert.message)} toggle={this.onDismiss}>
                    {alert.message}
                </Alert>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    alert: state.alertReducer,
});

export default connect(mapStateToProps)(DissmisableAlert);
