const locationValidator = {
    email: new RegExp('.+@.+\\..+'),
    // lat: new RegExp(/^-?\d{1,2}\.\d{4,}$/),
    // lng: new RegExp(/^-?\d{1,2}\.\d{4,}$/)
}

const validate = (values) => {
    const errors = {
        address: {
        }
    };

    if (!values.address) {
        errors.address.address = 'Address is required';
        errors.address.name = 'Company name is required';
        errors.address.city = 'City is required';
        errors.address.lat = 'Latitude is required';
        errors.address.lng = 'Longitude is required';
    } else {
        if (!values.address.address) {
            errors.address.address = 'Address is required';
        }
        if (!values.address.name) {
            errors.address.name = 'Company name is required';
        }
        if (!values.address.city) {
            errors.address.city = 'City is required';
        }
        if (!values.address.lat) {
            errors.address.lat = 'Latitude is required';
        }
        if (!values.address.lng) {
            errors.address.lng = 'Longitude is required';
        }
        if ((values.address.email)&&(!locationValidator.email.test(values.address.email))) {
            errors.address.email = 'Please enter a valid email address';
        }
        // if ((values.address.lat)&&(!locationValidator.lat.test(values.address.lat))) {
        //     errors.address.lat = 'Latitude must be in format 99.999999';
        // }
        // if ((values.address.lng)&&(!locationValidator.lng.test(values.address.lng))) {
        //     errors.address.lng = 'Longitude must be in format 99.999999';
        // }
    }

    return errors;
};

const LocationValidation = (values) => {
    return validate(values);
};

export {LocationValidation};
