import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {notificationApiService} from "../../../services/notificationApiService";

export const FETCH_API_NOTIFICATIONS_REQUEST = 'FETCH_API_NOTIFICATIONS_REQUEST';
export const FETCH_API_NOTIFICATIONS_SUCCESS = 'FETCH_API_NOTIFICATIONS_SUCCESS';
export const FETCH_API_NOTIFICATIONS_FAILURE = 'FETCH_API_NOTIFICATIONS_FAILURE';
export const FETCH_API_PREFERENCES_REQUEST = 'FETCH_API_PREFERENCES_REQUEST';
export const FETCH_API_PREFERENCES_SUCCESS = 'FETCH_API_PREFERENCES_SUCCESS';
export const FETCH_API_PREFERENCES_FAILURE = 'FETCH_API_PREFERENCES_FAILURE';

export function getApiNotifications(params = {page:1}) {
    return dispatch => {
        dispatch(request({}));

        notificationApiService.get('api/simple/notifications', params)
            .then(
                response => {
                    dispatch(success(response));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_API_NOTIFICATIONS_REQUEST, data } }
    function success(data) { return { type: FETCH_API_NOTIFICATIONS_SUCCESS, data } }
    function failure(error) { return { type: FETCH_API_NOTIFICATIONS_FAILURE, error } }
}
export function getApiNotificationsPreferences() {
    return dispatch => {
        dispatch(request({}));
        notificationApiService.get('api/preferences')
            .then(
                response => {
                    dispatch(success(response));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_API_PREFERENCES_REQUEST, data } }
    function success(data) { return { type: FETCH_API_PREFERENCES_SUCCESS, data } }
    function failure(error) { return { type: FETCH_API_PREFERENCES_FAILURE, error } }
}