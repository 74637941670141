import React from "react";
import {Card, CardBody, CardFooter, CardTitle, Row, Col} from "reactstrap";
import {Link} from "react-router-dom";
import {translate} from "services/translationService";
import Stats from "components/Stats/Stats.jsx";
import {apiService} from "../../services/apiService";
import getErrorText from "../../services/errorTextService";
import {notificationApiService} from "../../services/notificationApiService";

class SingleTile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            quantity: 0
        }
    }

    componentDidMount() {
        if (this.props.dashboardTile.apiUrl){
            apiService.get(this.props.dashboardTile.apiUrl).then(
                response => {
                    this.setState({
                        isLoading: false,
                        quantity: response.data.count
                    });
                })
                .catch(errorMessage => {
                    console.log(getErrorText(errorMessage));
                });
        } else {
            notificationApiService.get(this.props.dashboardTile.notificationApiUrl).then(
                response => {
                    this.setState({
                        isLoading: false,
                        quantity: response.data
                    });
                })
                .catch(errorMessage => {
                    console.log(getErrorText(errorMessage));
                });
        }
    }

    render() {
        const {dashboardTile} = this.props;
        return (

            <Col xs={12} sm={6} md={6} lg={3} key={dashboardTile.cardIndex}>
                <Link to={dashboardTile.linkTo}>
                <Card className="card-stats">
                    <CardBody title={dashboardTile.tag}>
                        <Row>
                            <Col xs={5} md={4}>
                                <div className="icon-big text-center">
                                    <i className={dashboardTile.iconClass}/>
                                </div>
                            </Col>
                            <Col xs={7} md={8}>
                                <div className="numbers">
                                    <p className="card-category">{translate(dashboardTile.cardTitle)}</p>
                                    <CardTitle tag="p">
                                        {(this.state.isLoading) ?
                                            <img
                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                                alt=""/>
                                            :
                                            this.state.quantity
                                        }
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <hr/>
                            <Stats>
                                {[
                                    {
                                        i: "fa fa-refresh",
                                        t: translate(dashboardTile.linkText)
                                    }
                                ]}
                            </Stats>
                    </CardFooter>
                </Card>
                </Link>
            </Col>)
    }
}

export default SingleTile;

