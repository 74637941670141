import React from "react";
import {connect} from "react-redux";
import OrderListPaginated from "./OrderListPaginated/OrderListPaginated";
import OrderListActions from "./OrderListActions/OrderListActions";
import {orderHelper} from './orderHelper';
import OrderSearchPanel from "./OrderSearcher/OrderSerachPanel"
import queryString from 'query-string';
import "./OrdersList.scss"
import HelperButton from "../../components/Helper/HelperButton";

class OrdersList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOrders: {}
        }
        this.toggleOrderSelect = this.toggleOrderSelect.bind(this);
        this.clearOrders = this.clearOrders.bind(this);
    }

    getOrderListTypeFromRoute = () =>{
        let {match} = this.props;
        return orderHelper.getOrderTypeListFromRouteMatch(match);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.pageParams.page !== this.props.pageParams.page){
            this.setState({selectedOrders:{}})
        }
    }

    toggleOrderSelect = ({orderId, orderName}) => {
        let currentOrders = {...this.state.selectedOrders}
        if (orderId in currentOrders){
            delete currentOrders[orderId];
        } else {
            currentOrders[orderId] = orderName;
        }
        this.setState({selectedOrders:currentOrders});
    }

    clearOrders = () => {
        this.setState({selectedOrders:{}});
    }

    render() {
        const searchParams = queryString.parse(this.props.location.search);
        let type = this.getOrderListTypeFromRoute();
        const {selectedOrders} = this.state;
        return (
            <div className="content" style={{position:"relative"}}>
                <div className={"order-list-header"}>
                    <OrderSearchPanel type={type} searchParamsFromQuery={searchParams} />
                    {Object.keys(selectedOrders).length > 0 && <OrderListActions
                        selectedOrders={selectedOrders}
                        clearOrders={this.clearOrders}
                    />}
                </div>
                <OrderListPaginated
                    type={type}
                    searchParamsFromQuery={searchParams}
                    toggleOrderSelect={this.toggleOrderSelect}
                    selectedOrders={selectedOrders}
                />
                <HelperButton helperId={`carrier-order-list-${type}`} style={{marginRight:'20px'}}/>
            </div>
        )
    }
}

OrdersList.defaultProps = {
    pageParams: {}
};

const mapStateToProps = state => ({
    pageParams: state.paginationReducer.orderPaginator,
});

export default connect(mapStateToProps)(OrdersList);
