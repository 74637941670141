import {
    FETCH_CAR_SETS_REQUEST,
    FETCH_CAR_SETS_SUCCESS,
    FETCH_CAR_SETS_FAILURE
} from 'redux/actions/car/carSets.actions.js';

const initialState = {
    message: '',
    carSets: [],
    meta:{},
    isFetching: true,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CAR_SETS_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
            });
        }

        case FETCH_CAR_SETS_SUCCESS: {
            return Object.assign({}, state, {
                carSets: action.data.data,
                meta: action.data.meta,
                isFetching: false,
            });
        }

        case FETCH_CAR_SETS_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }
        default:
            return state
    }
}
