
const carrierNewValidator = {
    carrier: {
        name: new RegExp('^[^!@#$%^&*()]+$'),
        bankName: new RegExp('^[^!@#$%^&*()]+$'),
        iban: new RegExp('^[a-zA-Z0-9\\s-]+$'),
        swift: new RegExp('^[a-zA-Z0-9\\s-]+$'),
        taxNumberUe: new RegExp('^[a-zA-Z0-9-]+$'),
        taxNumberDe: new RegExp('^[a-zA-Z0-9/-]+$'),
        address: {
            phone: new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s./0-9]*$'),
            contact: new RegExp('^[a-zA-Z0-9\\s-]+$'),
            email: new RegExp('.+@.+\\..+')
        }
    },
    account: {
        name: new RegExp('^[^0-9]+$'),
        surname: new RegExp('^[^0-9]+$'),
        email: new RegExp('^([\\w.%+-]+)@([\\w-]+\\.)+([\\w]{2,})$')
    }
};

const validate = (values) => {
    const errors = {
        carrier: {
            address: {
                street: ['', '', '']
            }
        },
        account: {}
    };
    if (!values.hasOwnProperty('carrier')) {
        values = {...values, carrier: {}}
    }
    if (!values.hasOwnProperty('account')) {
        values = {...values, account: {}}
    }

    if (!values.carrier.name) {
        errors.carrier.name = 'Name is required';
    } else if (carrierNewValidator.carrier.name.test(values.carrier.name) === false) {
        errors.carrier.name = 'Name can contain only letters, numbers and spaces ';
    }

    if (!values.carrier.address) {
        errors.carrier.address.street = 'Street is required';
        errors.carrier.address.city = 'City is required';
        errors.carrier.address.postCode = 'Post Code is required';
        errors.carrier.address.country = 'Country is required';
        errors.carrier.address.email = 'Email is required';
    } else {
        if (!values.carrier.address.street || !values.carrier.address.street[0]) {
            errors.carrier.address.street[0] = 'Street is required';
        }
        if (!values.carrier.address.city) {
            errors.carrier.address.city = 'City is required';
        }
        if (!values.carrier.address.postCode) {
            errors.carrier.address.postCode = 'Post Code is required';
        }
        if (!values.carrier.address.country) {
            errors.carrier.address.country = 'Country is required';
        }
        if (!values.carrier.address.email) {
            errors.carrier.address.email = 'Email is required';
        }

        if (values.carrier.address.phone) {
            if (carrierNewValidator.carrier.address.phone.test(values.carrier.address.phone) === false) {
                errors.carrier.address.phone = 'Valid phone number is required';
            }
        }

        if (values.carrier.address.email) {
            if (carrierNewValidator.carrier.address.email.test(values.carrier.address.email) === false) {
                errors.carrier.address.email = 'Valid email address is required';
            }
        }
    }

    if (carrierNewValidator.carrier.bankName.test(values.carrier.bankName) === false) {
        errors.carrier.bankName = 'Bank Name can contain only letters, numbers and spaces ';
    }

    if (carrierNewValidator.carrier.iban.test(values.carrier.iban) === false) {
        errors.carrier.iban = 'IBAN number can contain only letters, numbers and spaces ';
    }

    if (carrierNewValidator.carrier.swift.test(values.carrier.swift) === false) {
        errors.carrier.swift = 'SWIFT number can contain only letters, numbers and spaces ';
    }

    if (!values.carrier.taxNumberUe) {
        errors.carrier.taxNumberUe = 'EU VAT-ID number is required';
    } else if (carrierNewValidator.carrier.taxNumberUe.test(values.carrier.taxNumberUe) === false) {
        errors.carrier.taxNumberUe = 'EU VAT-ID can contain only letters and numbers';
    }

    if (carrierNewValidator.carrier.taxNumberDe.test(values.carrier.taxNumberDe) === false) {
        errors.carrier.taxNumberDe = "Tax number can contain only letters,numbers,minuses and /";

    }

    if (!values.account.name) {
        errors.account.name = 'Name is required';
    } else if (carrierNewValidator.account.name.test(values.account.name) === false) {
        errors.account.name = 'Name cannot contain numbers';
    }
    if (!values.account.surname) {
        errors.account.surname = 'Name is required';
    } else if (carrierNewValidator.account.surname.test(values.account.surname) === false) {
        errors.account.surname = 'Name cannot contain numbers';
    }
    if (!values.account.email) {
        errors.account.email = 'Email is required';
    } else if (carrierNewValidator.account.email.test(values.account.email) === false) {
        errors.account.email = 'Valid email address is required';
    }

    if (!values.GTCchecked) {
        errors.GTCchecked = "You have to accept General Terms and Conditions";
    }

    return errors;
};

const carrierValidationCreate = (values) => {
    return validate(values);
};

export {carrierValidationCreate};
