import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./styles.scss"
import {Input} from "reactstrap";
import {getDistanceLabel} from "../../../helpers/formHelper";
import {translate} from "../../../services/translationService";
import MediaQuery from "react-responsive";
import classnames from "classnames";

class DedicatedOptionsForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accordion: {}
    }
    this.toggleHeader = this.toggleHeader.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.optionCodes !== this.props.optionCodes) {
      let accordion = {}
      this.props.optionCodes.forEach(code=> accordion[code.option_code] = false)
      this.setState({accordion})
    }
  }


  toggleHeader(optionCode) {
    let newAccordion = {...this.state.accordion};
    for (let serviceCode in newAccordion) {
      newAccordion[serviceCode] = false;
    }
    newAccordion[optionCode] = !this.state.accordion[optionCode];
    this.setState({accordion: newAccordion})
  }

  render() {
    const {changeInput, inputs, code, optionCodes} = this.props;
    const {accordion} = this.state;

    const isOptionValid = (option) => {
      let optionIsValid = true;
      for (const dist in inputs[code][option.option_code]) {
        if (!inputs[code][option.option_code][dist].isValid) optionIsValid = false
      }
      return optionIsValid;
    }

    return <>
      <MediaQuery query='(min-device-width: 860px)'>
        <div className="primary-prices-form dedicated-form">
          {optionCodes.map((option, index) => <div className="primary-prices-row" key={index}>
            <div className="car-type" title={option.option_name}>{option.option_name}</div>
            {Object.keys(inputs[code][option.option_code]).map((distance, index) => <div
              className="price-form primary-option"
              key={index}>
              <div className={"distance-label"}>{getDistanceLabel(distance)}</div>
              <div className="price-inputs">
                <Input type="checkbox" name={`${code}-${option.option_code}-${distance}-const`}
                       checked={distance >= 200 ? false : inputs[code][option.option_code][distance].const}
                       onChange={changeInput}
                       title={translate("Fixed price")}
                       style={{"display": distance >= 200 ? "none" : "block"}}
                />
                <Input type="number"
                       name={`${code}-${option.option_code}-${distance}-price`}
                       value={inputs[code][option.option_code][distance].price}
                       onChange={changeInput}
                       step="0.01"
                       min="0"
                       className={inputs[code][option.option_code][distance].isValid ? "" : "invalid"}
                       title={inputs[code][option.option_code][distance].isValid ? "" :
                         translate("Enter valid number")
                       }
                />
              </div>
            </div>)}
          </div>)
          }
        </div>
      </MediaQuery>
      <MediaQuery query='(max-device-width: 859px)'>
        <div className={"primary-prices-accordion"}>
          {optionCodes.map((option, index) => <div key={index}>
            <div className={`car-type-header ${accordion[option.option_code] ? 'open' : ''} `}
                 onClick={() => this.toggleHeader(option.option_code)}
            >
              <span className={classnames({invalid:!isOptionValid(option)})}>{option.option_name}</span>
              <i className={`nc-icon nc-minimal-up ${accordion[option.option_code] ? 'open' : ''}`}></i>
            </div>

            <div className={`prices-container${accordion[option.option_code] ? '' : ' hidden'}`}  key={index + 99}>
              {Object.keys(inputs[code][option.option_code]).map((distance, index) => <div className="price-form" key={index}>
                <div className={"distance-label"}>{getDistanceLabel(distance)}</div>
                <div className="price-inputs">
                  <Input type="checkbox" name={`${code}-${option.option_code}-${distance}-const`}
                         checked={inputs[code][option.option_code][distance].const}
                         onChange={changeInput}
                         title={translate("Fixed price")}
                  />
                  <Input type="number"
                         name={`${code}-${option.option_code}-${distance}-price`}
                         value={inputs[code][option.option_code][distance].price}
                         onChange={changeInput}
                         step="0.01"
                         min="0"
                         className={inputs[code][option.option_code][distance].isValid ? "" : "invalid"}
                         title={inputs[code][option.option_code][distance].isValid ? "" :
                           translate("Enter valid number")
                         }
                  />
                </div>
              </div>)}
            </div>
          </div>)}
        </div>
      </MediaQuery>
    </>
  }
}

DedicatedOptionsForm.propTypes = {
  changeInput: PropTypes.func,
  code: PropTypes.string,
  inputs: PropTypes.object,
  optionsCodes: PropTypes.object
};

export default DedicatedOptionsForm;
