export const isCordova = () => {
    return !(typeof (window.cordova) === 'undefined');
}

export const getAppVersionInfo = () => {
    if (isCordova() && typeof (window.cordova) !== 'undefined' && typeof (window.cordova.getAppVersion) !== 'undefined') {
        let appVersionNumber = '';
        let appVersionCode = '';
        window.cordova.getAppVersion.getVersionNumber().then(function (versionNumber) {
            appVersionNumber = versionNumber;
        });
        window.cordova.getAppVersion.getVersionCode().then(function (versionCode) {
            appVersionCode = versionCode;
        });
        return `${appVersionNumber} (${appVersionCode})`;

    } else {
        return ` (v ${process.env.REACT_APP_VERSION})`;
    }
}
export const openFileFromUrl = (url) => {
    try {
        var path = window.cordova.file.dataDirectory;

        var req = new XMLHttpRequest();
        req.open('GET', url, false);
        req.send(null);
        var contentType = req.getResponseHeader("Content-Type");
        var fileTransfer = new window.FileTransfer();
        var ext = contentType.split('/')[1];

        var filePath = path + 'tmp.' + ext;
        fileTransfer.download(
            url,
            filePath,
            function (entry) {
                window.cordova.plugins.fileOpener2.open(filePath, contentType);
            },
            function (error) {
                window.open(url);
            }
        );
    }catch(e){
        window.open(url);
    }
}