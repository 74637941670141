import {translate} from "../../services/translationService";

const validateMultiple = (values) => {
    const errors = {
    };
    if (!values.description) {
        errors.description = translate('Please enter remark description');
    }
    return errors;
};


const multipleRemarksValidation = (values) => {
    return validateMultiple(values);
};

export {multipleRemarksValidation};
