
const status = {
    default: 0,
    canceled: 2,
    pickup: 8,
    delivered: 16,
    payed: 32,
    confirmed: 64,
    deleted: 128,
    closedForCarrier: 8192,
    rejectedByCarrier:16384
};

export const statusService = {
    isOrderClosed: (orderStatus) => {
        return (orderStatus & status.closedForCarrier) === status.closedForCarrier;
    }
}
