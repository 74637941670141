import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';

class GDPRpage extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <h1>JANTA Kurier GDPR</h1>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default GDPRpage;
