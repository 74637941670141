import React from "react";
import {connect} from "react-redux";
import {translate} from "services/translationService";
import './DeliveryDocument.scss';
import {dateTime} from "../../../utils/formatters";
import orderService from "../../../services/orderService";
import {isCordova, openFileFromUrl} from "../../../helpers/cordova";
import {documentsApiService} from "../../../services/documentsApiService";
import {alertActions} from "../../../redux/actions";

class DocumentsList extends React.Component {
    constructor(props) {
        super(props);
        this.downloadDocument = this.downloadDocument.bind(this);
    }

    downloadDocument(doc) {
        if (isCordova()) {
            openFileFromUrl(`${process.env.REACT_APP_DOCUMENTS_API_URL}/api/documents/${doc.ulid}`);
        } else {
            documentsApiService.downloadFile(`api/documents/${doc.ulid}`)
                .then(apiServiceResponse => {
                    const blob = new Blob([apiServiceResponse.blob], {type: apiServiceResponse.fileType});
                    let a = window.document.createElement("a");
                    a.href = URL.createObjectURL(blob);
                    a.download = doc.document_type_name + '.' + doc.document_mime.split('/')[1];
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                })
                .catch((apiError) => {
                    this.props.dispatch(alertActions.error(documentsApiService.getErrorText(apiError)));
                });
        }
    }

    render() {
        const {orderDocuments} = this.props;
        if (orderDocuments.length === 0) return <div>{translate("No documents uploaded")}</div>

        return <>
            <div className="delivery-document-header">
                <div>{translate("Date")}</div>
                <div>{translate("Type")}</div>
            </div>
            {orderDocuments.map((doc, index) =>
                <div className={orderService.getDocumentClass(doc)} key={index} onClick={() => this.downloadDocument(doc)}>
                    <div>{dateTime(doc.create_time)}</div>
                    <div className="document-type">{doc.document_type_name}<i className="nc-icon nc-cloud-download-93"/></div>
                </div>)}
        </>
    }
}

export default connect()(DocumentsList);
