import React from "react";
import {Badge} from "reactstrap";
import {translate} from 'services/translationService';

const InquiryShipmentBadges = ({shipmentData}) => {

    const GetSingleBadge = ({badge}) => {
        return <Badge className="shipmentDataBadge" color='warning'>
            {badge}
        </Badge>
    }

    let badgesList = [];

    if (shipmentData.cashOnDeliveryAmount > 0) {
        badgesList.push(`${translate("Cash on delivery")}: ${shipmentData.cashOnDeliveryAmount}€`)
    }
    if (shipmentData.shipmentInsuranceValue > 0) {
        badgesList.push(`${translate("Insurance value")}: ${shipmentData.shipmentInsuranceValue}€`)
    }
    if (shipmentData.returnOfDocuments) {
        badgesList.push(translate("Return of documents"))
    }
    if (shipmentData.saturdayDelivery) {
        badgesList.push(translate("Saturday delivery"))
    }
    if (shipmentData.sundayDelivery) {
        badgesList.push(translate("Sunday delivery"))
    }

    return <div className="badges-container">
        {badgesList.map((badge, key) => <GetSingleBadge badge={badge} key={key}/>)}
    </div>
}
export default InquiryShipmentBadges;
