import {
    FETCH_VEHICLE_REQUEST,
    FETCH_VEHICLE_SUCCESS,
    FETCH_VEHICLE_FAILURE,
    UPDATE_VEHICLE_SUCCESS,
    UPDATE_VEHICLE_FAILURE,
    UPDATE_VEHICLE_REQUEST
} from 'redux/actions/car/vehicle.actions.js';

const initialState = {
    message: '',
    vehicle: {},
    meta:{},
    isFetching: true,
    isUpdating:false,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VEHICLE_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
                error: false,
            });
        }

        case FETCH_VEHICLE_SUCCESS: {
            return Object.assign({}, state, {
                vehicle: action.data.data,
                meta: action.data.meta,
                isFetching: false,
                error: false,
            });
        }

        case FETCH_VEHICLE_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }

        case UPDATE_VEHICLE_REQUEST: {
            return Object.assign({}, state, {
                isUpdating: true,
                error: false
            });
        }

        case UPDATE_VEHICLE_SUCCESS: {
            return Object.assign({}, state, {
                vehicle: action.data.data,
                meta: action.data.meta,
                isUpdating: false,
                error: true
            });
        }

        case UPDATE_VEHICLE_FAILURE: {
            return Object.assign({}, state, {
                isUpdating: false,
                error: true
            });
        }

        default:
            return state
    }
}
