import Dashboard from "views/Dashboard/Dashboard.jsx"
import UserPage from "views/Users/User/Details/UserPage"
import Carrier from "views/Carrier/Carrier.jsx";
import OrdersList from "views/OrderList/OrdersList";
import Users from "views/Users/List/Users";
import Invoices from "views/Invoices/Invoices";
import Cars from "views/Cars/Cars";
import {translate} from 'services/translationService';
import loggedUserService from '../services/loggedUserService';
import {
    CARRIER_IS_ACTIVE,
    USER_IS_MANAGER,
    USER_IS_ACTIVE,
    USER_IS_DRIVER,
    USER_HAS_INQUIRIES_ACCESS, USER_HAS_COOPERATION_ACCESS
} from "../constants/routesRestrictions";
import MyCar from "../views/Cars/Car/MyCar/MyCar";
import InvoicingData from "../views/InvocingData/InvoicingData";
import InquiriesList from "../views/InquiriesList/Inquiries";
import Cooperation from "../views/Cooperation/Cooperation";
import Code from "../views/Code/Code";

const sidebarRoutes = () =>{
    const allSidebarRoutes = [
        {
            path: "/code",
            name: translate("Confirmation code"),
            icon: "nc-icon nc-lock-circle-open",
            component: Code
        },
        {
            path: "/dashboard",
            name: translate("Dashboard"),
            icon: "nc-icon nc-layout-11",
            component: Dashboard
        },
        {
            path: "/orders/new",
            name: translate("New orders"),
            icon: "nc-icon nc-alert-circle-i",
            component: OrdersList,
            restrictions:[CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            path: "/orders/ongoing",
            name: translate("Orders in transit"),
            icon: "nc-icon nc-delivery-fast",
            component: OrdersList,
            restrictions:[CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            path: "/orders/delivered",
            name: translate("Orders delivered"),
            icon: "nc-icon nc-check-2",
            component: OrdersList,
            restrictions:[CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            path: "/orders/processed",
            name: translate("Orders processed"),
            icon: "nc-icon nc-single-copy-04",
            component: OrdersList,
            restrictions:[USER_IS_MANAGER, CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            path: "/orders/all",
            name: translate("All orders"),
            icon: "nc-icon nc-paper",
            component: OrdersList,
            restrictions:[USER_IS_MANAGER, CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            path: "/inquiries/new",
            name: translate("Inquiries"),
            icon: "nc-icon nc-globe",
            component: InquiriesList,
            restrictions:[USER_IS_MANAGER, CARRIER_IS_ACTIVE, USER_IS_ACTIVE, USER_HAS_INQUIRIES_ACCESS]
        },
        {
            path: "/carrier",
            name: translate("Company info"),
            icon: "nc-icon nc-shop",
            component: Carrier
        },
        {
            path: "/cars",
            name: translate("Car fleet"),
            icon: "nc-icon nc-delivery-fast",
            component: Cars,
            restrictions:[USER_IS_MANAGER, CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            path: "/mycar",
            name: translate("My Car"),
            icon: "nc-icon nc-bus-front-12",
            component: MyCar,
            restrictions:[USER_IS_ACTIVE, USER_IS_DRIVER, CARRIER_IS_ACTIVE]
        },
        {
            path: "/users",
            name: translate("Company users"),
            icon: "nc-icon nc-badge",
            component: Users,
            restrictions:[USER_IS_MANAGER, CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            path: "/cooperation",
            name: translate("Cooperation"),
            icon: "nc-icon nc-refresh-69",
            component: Cooperation,
            restrictions:[USER_IS_MANAGER, CARRIER_IS_ACTIVE, USER_IS_ACTIVE, USER_HAS_COOPERATION_ACCESS]
        },
        {
            path: "/invoice-data",
            name: translate("Invoicing data"),
            icon: "nc-icon nc-credit-card",
            component: InvoicingData,
            restrictions:[USER_IS_MANAGER, CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            path: "/invoices",
            name: translate("Invoices"),
            icon: "nc-icon nc-money-coins",
            component: Invoices,
            restrictions:[USER_IS_MANAGER, CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            path: "/user-page",
            name: translate("My Profile"),
            icon: "nc-icon nc-single-02",
            component: UserPage
        },
    ];
    return loggedUserService.restrictRoutesForUser(allSidebarRoutes);
};

export default sidebarRoutes;
