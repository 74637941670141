import axios from 'axios';
import {store} from 'helpers';
import {authActions} from "../redux/actions";

const BASE_URL = `${process.env.REACT_APP_NOTIFICATION_API_URL}`;

export const notificationApiService = {
    get,
    post,
    put,
};

function getApiNotificationConfig(url) {
    const config = {
        url: BASE_URL + url,
    };
    const token = localStorage.getItem('notificationApiToken') || null;
    const language = store.getState().translationReducer.locale || null;
    config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-language': language
    };
    return config;
}

async function apiRequest(config) {
    try {
        const response = await axios(config);

        return response.statusText === 'OK' || response.status === 204
            ? Promise.resolve(response.data)
            : Promise.reject(response.data);

    } catch (error) {
        if (error.response.status === 401) {
            store.dispatch(authActions.logout());
        }
        return Promise.reject(error.response);
    }
}


function get(url, data) {
    const config = {
        method: 'get',
        ...getApiNotificationConfig(url),
        params: data
    };
    return apiRequest(config);
}

function post(url, data) {
    const config = {
        method: 'post',
        ...getApiNotificationConfig(url),
        data
    };
    return apiRequest(config);
}

function put(url, data = {}) {
    const config = {
        method: 'put',
        ...getApiNotificationConfig(url),
        data
    };
    return apiRequest(config);
}
