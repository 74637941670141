import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {history} from "../../../helpers";
import {priceApiService} from "../../../services/priceApiService";
import {translate} from "../../../services/translationService";

export const FETCH_SURCHARGE_SATURDAY_REQUEST = 'FETCH_SURCHARGE_SATURDAY_REQUEST';
export const FETCH_SURCHARGE_SATURDAY_SUCCESS = 'FETCH_SURCHARGE_SATURDAY_SUCCESS';
export const FETCH_SURCHARGE_SATURDAY_FAILURE = 'FETCH_SURCHARGE_SATURDAY_FAILURE';

export const UPDATE_SURCHARGE_SATURDAY_REQUEST = 'UPDATE_SURCHARGE_SATURDAY_REQUEST';
export const UPDATE_SURCHARGE_SATURDAY_SUCCESS = 'UPDATE_SURCHARGE_SATURDAY_SUCCESS';
export const UPDATE_SURCHARGE_SATURDAY_FAILURE = 'UPDATE_SURCHARGE_SATURDAY_FAILURE';

export const FETCH_SURCHARGE_SUNDAY_REQUEST = 'FETCH_SURCHARGE_SUNDAY_REQUEST';
export const FETCH_SURCHARGE_SUNDAY_SUCCESS = 'FETCH_SURCHARGE_SUNDAY_SUCCESS';
export const FETCH_SURCHARGE_SUNDAY_FAILURE = 'FETCH_SURCHARGE_SUNDAY_FAILURE';

export const UPDATE_SURCHARGE_SUNDAY_REQUEST = 'UPDATE_SURCHARGE_SUNDAY_REQUEST';
export const UPDATE_SURCHARGE_SUNDAY_SUCCESS = 'UPDATE_SURCHARGE_SUNDAY_SUCCESS';
export const UPDATE_SURCHARGE_SUNDAY_FAILURE = 'UPDATE_SURCHARGE_SUNDAY_FAILURE';

export function getDedicatedSurchargesForSaturday(params) {
    return dispatch => {
        dispatch(request({}));
        const {countryFrom, countryTo} = params;
        const url = `Surcharges/forSaturdayDelivery/${countryFrom}/${countryTo}`;
        priceApiService.getDedicated(url)
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                history.push('/dashboard');
            });
    };

    function request(data) { return { type: FETCH_SURCHARGE_SATURDAY_REQUEST, data } }
    function success(data) { return { type: FETCH_SURCHARGE_SATURDAY_SUCCESS, data } }
    function failure(error) { return { type: FETCH_SURCHARGE_SATURDAY_FAILURE, error } }
}

export function updateDedicatedSurchargesForSaturday(pricesData) {
  return dispatch => {
    dispatch(request({}));
    const url = `Surcharges/forSaturdayDelivery`;
    priceApiService.updateDedicated(url, pricesData)
      .then(
        response => {
          dispatch(success(response.data));
          dispatch(alertActions.success(translate("Saturday surcharges were updated successfully")));
        })
      .catch(errorMessage => {
        dispatch(failure(getErrorText(errorMessage)));
        dispatch(alertActions.error(translate("Saturday surcharges were not updated, please try again")));
        history.push('/dashboard');
      });
  };

  function request(data) { return { type: UPDATE_SURCHARGE_SATURDAY_REQUEST, data } }
  function success(data) { return { type: UPDATE_SURCHARGE_SATURDAY_SUCCESS, data } }
  function failure(error) { return { type: UPDATE_SURCHARGE_SATURDAY_FAILURE, error } }
}

export function getDedicatedSurchargesForSunday(params) {
  return dispatch => {
    dispatch(request({}));
    const {countryFrom, countryTo} = params;
    const url = `Surcharges/forSundayDelivery/${countryFrom}/${countryTo}`;
    priceApiService.getDedicated(url)
      .then(
        response => {
          dispatch(success(response.data));
        })
      .catch(errorMessage => {
        dispatch(failure(getErrorText(errorMessage)));
        dispatch(alertActions.error(getErrorText(errorMessage)));
        history.push('/dashboard');
      });
  };

  function request(data) { return { type: FETCH_SURCHARGE_SUNDAY_REQUEST, data } }
  function success(data) { return { type: FETCH_SURCHARGE_SUNDAY_SUCCESS, data } }
  function failure(error) { return { type: FETCH_SURCHARGE_SUNDAY_FAILURE, error } }
}

export function updateDedicatedSurchargesForSunday(pricesData) {
  return dispatch => {
    dispatch(request({}));
    const url = `Surcharges/forSundayDelivery`;
    priceApiService.updateDedicated(url, pricesData)
      .then(
        response => {
          dispatch(success(response.data));
          dispatch(alertActions.success(translate("Sunday surcharges were updated successfully")));
        })
      .catch(errorMessage => {
        dispatch(failure(getErrorText(errorMessage)));
        dispatch(alertActions.error(translate("Sunday surcharges were not updated, please try again")));
        history.push('/dashboard');
      });
  };

  function request(data) { return { type: UPDATE_SURCHARGE_SUNDAY_REQUEST, data } }
  function success(data) { return { type: UPDATE_SURCHARGE_SUNDAY_SUCCESS, data } }
  function failure(error) { return { type: UPDATE_SURCHARGE_SUNDAY_FAILURE, error } }
}
