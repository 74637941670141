import React from "react";
import {connect} from "react-redux";
import {translate} from 'services/translationService';
import {getOrders} from 'redux/actions/orders';
import OrderListElement from "../OrderListElement/OrderListElement";
import Paginator from "components/Paginator/Paginator";
import Loader from "components/Loader/Loader";
import MediaQuery from "react-responsive";
import {paginationActions} from "../../../redux/actions/pagination.actions";
import {pageDidChange} from "../../../helpers/paginator";
import {searchParamsDidChange} from "../../../helpers/search";
export const ORDER_PAGINATOR = 'orderPaginator';

class OrderListPaginated extends React.Component {

    constructor(props) {
        super(props);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePerPageChange = this.handlePerPageChange.bind(this);
        this.dispatch = this.props.dispatch;
    }

    componentDidMount() {
        this.dispatch(getOrders(this.props.type, {...this.props.pageParams, ...this.props.searchParamsFromQuery }));
    }

    componentDidUpdate(prevProps){
        if (pageDidChange(prevProps.pageParams,  this.props.pageParams)
            || searchParamsDidChange(prevProps.searchParamsFromQuery, this.props.searchParamsFromQuery)) {
            this.dispatch(getOrders(this.props.type, {...this.props.pageParams, ...this.props.searchParamsFromQuery }))
        }
    }

    handlePageChange(pageNumber, indexStart) {
        this.dispatch(paginationActions.pushChangePage(ORDER_PAGINATOR, pageNumber, indexStart));
    }

    handlePerPageChange(perPage) {
        this.dispatch(paginationActions.changePerPage(ORDER_PAGINATOR, perPage));
    }

    render() {
        if (this.props.isFetching) {
            return (
                <Loader />
            )
        }
        const ordersApiResponse = this.props.orders;
        const pagesCount = Math.ceil(ordersApiResponse.meta.total / ordersApiResponse.meta.perPage);
        const page = this.props.pageParams.page;
        const indexStart = this.props.pageParams.indexStart;
        const {toggleOrderSelect, selectedOrders} = this.props;

        return <div className="ordersListPaginator">
            {(ordersApiResponse.data.length === 0) &&
            <div>{translate("No orders found")}</div>
            }

            {(ordersApiResponse.data.map((orderData, i) => {
                    return <OrderListElement
                            orderData={orderData}
                            key={i}
                            toggleOrderSelect={toggleOrderSelect}
                            selectedOrders={selectedOrders}
                    />
                })
            )}
            {pagesCount > 1 && <MediaQuery minDeviceWidth={860}>
                {(matches) => {
                    let maxPages = matches ? 10 : 3;

                    return <Paginator
                        pageChangeHandler={this.handlePageChange}
                        perPageHandler={this.handlePerPageChange}
                        pagesCount={pagesCount}
                        currentPage={page}
                        perPage={this.props.pageParams.perPage}
                        indexStart={indexStart}
                        maxPages={maxPages}
                    />
                }}
            </MediaQuery>
            }
        </div>;
    }
}

OrderListPaginated.defaultProps = {
    orders: {},
    isFetching: null,
    error: false,
    pageParams: {}
};

const mapStateToProps = state => ({
    orders: state.ordersReducer.orders,
    isFetching: state.ordersReducer.isFetching,
    error: state.ordersReducer.error,
    pageParams: state.paginationReducer.orderPaginator,
});

export default connect(mapStateToProps)(OrderListPaginated);
