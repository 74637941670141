export const countriesForSelect = (countries) => {
    return countries.map(country => {
        return {
            id: country.code,
            name: country.name
        }
    });
};

export const provincesForSelect = (countries, countryCode) => {
    if (Array.isArray(countries)) {
        const country = countries.find(
            (country) => {
                return country.hasOwnProperty('districts') && country.code === countryCode
            });
        return country.districts.map(district => {
            return {
                id: district.code,
                name: district.name
            }
        });
    } else {
        return null;
    }
};

export const hasProvinces = (countries, countryCode) => {
    if (countryCode === null || !Array.isArray(countries)) return false;
    const hasProvinces = countries.find(
        (country) => {
            return country.hasOwnProperty('districts') && country.code === countryCode
        });
    return hasProvinces !== undefined;
};
