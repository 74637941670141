import React, {Component} from 'react';
import './OrderDetails.scss';
import {translate} from 'services/translationService';
import MediaQuery from 'react-responsive';
import {Table} from 'reactstrap';

class OrderDetailsPackages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visiblePackageDetails: null
        };
        this.showPackageDetails = this.showPackageDetails.bind(this);
        this.showGitterbox = [...this.props.boxes].filter(box=>box.gitterbox).length > 0;
    }

    showPackageDetails = (index) => {
        this.setState({
            visiblePackageDetails: index
        })
    }

    renderBoxesInfo() {
        const boxes = [...this.props.boxes];

        return boxes.map((box, index) => {
            return (
                <tbody key={index}>
                <tr onClick={() => this.showPackageDetails(index)}>
                    <td>{index + 1}</td>
                    <MediaQuery query='(min-device-width: 860px)'>
                        <td>{box.number}</td>
                        <td>{box.height} cm</td>
                        <td>{box.width} cm</td>
                        <td>{box.length} cm</td>
                    </MediaQuery>
                    <MediaQuery query='(max-device-width: 860px)'>
                        <td>{box.height} x {box.width} x {box.length} cm</td>
                    </MediaQuery>
                    <td>{box.weight} kg</td>
                    <MediaQuery query='(max-device-width: 860px)'>
                        <td><i className={`nc-icon nc-minimal-${this.state.visiblePackageDetails === index ? 'up' : 'down'}`} /></td>
                    </MediaQuery>
                    <MediaQuery query='(min-device-width: 860px)'>
                        <td>{box.largePackage ? "yes" : "no"}</td>
                        <td>{box.laidable ? "yes" : "no"}</td>
                        {this.showGitterbox && <td>{box.gitterbox ? "yes" : "no"}</td>}
                    </MediaQuery>


                </tr>
                <MediaQuery query='(max-device-width: 860px)'>
                    <tr style={{display: this.state.visiblePackageDetails === index ? '' : 'none'}}>
                        <td></td>
                        <td>{translate("Package number")}</td>
                        <td colSpan="2">{box.number}</td>
                    </tr>
                    <tr style={{display: this.state.visiblePackageDetails === index ? '' : 'none'}}>
                        <td></td>
                        <td>{translate("Oversize")}</td>
                        <td colSpan="2">{box.largePackage ? "yes" : "no"}</td>
                    </tr>
                    <tr style={{display: this.state.visiblePackageDetails === index ? '' : 'none'}}>
                        <td></td>
                        <td>{translate("Stackable")}</td>
                        <td colSpan="2">{box.laidable ? "yes" : "no"} </td>
                    </tr>
                    {this.showGitterbox && <tr style={{display: this.state.visiblePackageDetails === index ? '' : 'none'}}>
                        <td></td>
                        <td>{translate("Gitterbox")}</td>
                        <td colSpan="2">{box.gitterbox ? "yes" : "no"}</td>
                    </tr>}
                </MediaQuery>
                </tbody>
            )
        });
    }

    render() {
        return (
            <Table size="sm" className="full-width responsive-font">
                <thead>
                <tr>
                    <th></th>
                    <MediaQuery query='(min-device-width: 860px)'>
                        <th>{translate("Package number")}</th>
                        <th>{translate("Height")}</th>
                        <th>{translate("Width")}</th>
                        <th>{translate("Length")}</th>
                    </MediaQuery>
                    <MediaQuery query='(max-device-width: 860px)'>
                        <th> {translate('Dimensions')}</th>
                    </MediaQuery>
                    <th>{translate("Weight")}</th>
                    <MediaQuery query='(max-device-width: 860px)'>
                        <th></th>
                    </MediaQuery>
                    <MediaQuery query='(min-device-width: 860px)'>
                        <th>{translate("Oversize")}</th>
                        <th>{translate("Stackable")}</th>
                        {this.showGitterbox && <th>{translate("Gitterbox")}</th>}
                    </MediaQuery>
                </tr>
                </thead>
                {this.renderBoxesInfo()}
            </Table>
        );
    }
}

export default OrderDetailsPackages;
