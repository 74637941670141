import React from "react";
import {Input} from "reactstrap";
import {apiService} from "services/apiService";
import {stringHelper} from "helpers/stringHelper";
import {translate} from 'services/translationService';

class SelectAccount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedValue: '',
            accounts: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.renderAccountsList = this.renderAccountsList.bind(this);
        this.fetchAccountList = this.fetchAccountList.bind(this);
    }

    fetchAccountList() {
        const params = {
            role:this.props.userRole,
            status:'active',
            perPage:999
        };
        apiService.get('/api/accounts', params)
            .then(
                response => {
                   this.setState({
                       accounts: response.data,
                       selectedValue: this.props.defaultValue,
                   });
                })
            .catch(errorMessage => {
                console.log(errorMessage);
            });
    }

    componentDidMount() {
        this.fetchAccountList();
    }

    renderAccountsList() {
        const accounts = this.state.accounts;
        return (
            accounts.map((account,key) => {
                return (
                    <option value={account.id} key={key}>{stringHelper.personToString(account)}</option>
                )
            })
        )
    }

    handleChange(selectedOption) {
        this.setState({selectedValue: selectedOption.value});
    }

    render() {
        const {name, withEmptyValue} = this.props;
        return(
            <Input type="select" name={name} value={this.state.selectedValue} onChange={this.handleChange}>
                {(withEmptyValue) ?
                    <option value=""> {translate('Select')} </option>
                : '' }
                {this.renderAccountsList()}
            </Input>
        )
    }
}

export default SelectAccount;
