import { apiService } from "services/apiService";
import { alertActions } from "redux/actions";
import getErrorText from "../../services/errorTextService";

export const FETCH_TRANSLATION = 'FETCH_TRANSLATION';
export const FETCH_TRANSLATION_SUCCESS = 'FETCH_TRANSLATION_SUCCESS';
export const FETCH_TRANSLATION_FAILURE = 'FETCH_TRANSLATION_FAILURE';

export function getTranslations(locale) {
    return dispatch => {
        dispatch(request({locale}));

        apiService.get('/api/translations/' + locale)
            .then(
                response => {
                    const translations  = response.data;
                    dispatch(success({locale, translations }));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };


    function request(data) {
        return {type: FETCH_TRANSLATION, data}
    }

    function success(data) {
        return {type: FETCH_TRANSLATION_SUCCESS, data}
    }

    function failure(error) {
        return {type: FETCH_TRANSLATION_FAILURE, error}
    }
}

export function postTranslations() {

}
