import React from "react";
import CargoOptionBadge from "../../components/CargoOptionBadge/CargoOptionBadge";


const InquiryOptionsBadge = ({cargoOptions}) => {

    const inquiryOptions = {
        adr:'ADR',
        cooler:'Cooler',
        edscha:'Edscha',
        forklift:'Forklift',
        lift:'Lift',
        palletTruck:'Pallet Truck'
    }
     let badgesTexts = [];

    Object.keys(cargoOptions).forEach(option => {
        if (cargoOptions[option] === true ){
            badgesTexts.push(inquiryOptions[option])
        }
    })

    return <div className="badges-container">
             {badgesTexts.map((badgeText, key) => <CargoOptionBadge cargoOption={badgeText} key={key} />)}
        </div>
}


export default InquiryOptionsBadge;
