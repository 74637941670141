import React from "react";
import {connect} from "react-redux";
import MediaQuery from 'react-responsive';
import {Col, Row, Table} from "reactstrap";
import {translate} from 'services/translationService';
import Paginator from "components/Paginator/Paginator";
import Loader from "components/Loader/Loader";
import {getSoloTrucks} from "../../redux/actions/car";
import {paginationActions} from "../../redux/actions";
import {Link} from "react-router-dom";
import CheckIcon from "./CheckIcon";
import {getDriverNamesForCar} from "../../helpers/driverHelper";
import {pageDidChange} from "../../helpers/paginator";
import CarsSearchPanel from "./CarsSearchPanel";
import {getSearchParamsForCarsList, storeSearchParamsForCarsList} from "../../helpers/carsListHelper";

class SoloTrucks extends React.Component {

    constructor(props) {
        super(props);
        const storedSearchParams = getSearchParamsForCarsList('soloTrucks');
        this.state = {
            searchParams:{
                search:"",
                ...storedSearchParams
            },
            showAdvancedSearch:!(storedSearchParams==null)
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePerPageChange = this.handlePerPageChange.bind(this);
        this.setSearchParams = this.setSearchParams.bind(this);
        this.didSearchParamsChange = this.didSearchParamsChange.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getSoloTrucks({
           // isConnectedToSet: 0,
            page: this.props.pageParams.page,
            perPage: this.props.pageParams.perPage
        }));
    }

    componentDidUpdate(prevProps, prevState) {
        if (pageDidChange(prevProps.pageParams,  this.props.pageParams))
        {
            this.props.dispatch(getSoloTrucks({
                ...this.state.searchParams,
                perPage: this.props.pageParams.perPage,
                page: this.props.pageParams.page
            }));
        }
        if(this.didSearchParamsChange(prevState)){
            this.props.dispatch(getSoloTrucks({
                ...this.state.searchParams,
                perPage: this.props.pageParams.perPage,
                page: this.props.pageParams.page
            }));
        }
    }

    didSearchParamsChange(prevState){
        return JSON.stringify(this.state.searchParams) !== JSON.stringify(prevState.searchParams);
    }

    setSearchParams(searchParams) {
        this.setState({
            searchParams
        });
        storeSearchParamsForCarsList('soloTrucks',searchParams);
    }


    toggleDeleteModal(soloTruck) {
        this.props.onDelete(soloTruck);
    }

    handlePageChange(pageNumber, indexStart) {
        this.props.dispatch(paginationActions.pushChangePage('soloTrucksPaginator',pageNumber, indexStart));
    }

    handlePerPageChange(perPage) {
        this.props.dispatch(paginationActions.changePerPage('soloTrucksPaginator',perPage));
    }

    renderSoloTruckRow(soloTruck, i) {
        return(
            <tr className="car-row" key={i}>
                <td>{soloTruck.brand.name} {soloTruck.model.name} </td>
                <td>{soloTruck.registrationNumber}</td>
                <MediaQuery query='(min-device-width: 860px)'>
                    <td>{soloTruck.productionYear}</td>
                    <td>{soloTruck.drivers.length > 0 ? getDriverNamesForCar(soloTruck):
                        <span className={"check-trailer"}><CheckIcon checkPresent={false}/></span>
                    }
                    </td>
                </MediaQuery>
                <td>
                    <Link to={`/solo/${soloTruck.id}`} className="ml-2">
                        <i className="nc-icon nc-zoom-split"/>
                    </Link>
                    <Link to={`/car/edit/${soloTruck.id}`} className="ml-2">
                        <i className="nc-icon nc-settings-gear-65 setup-icon"/>
                    </Link>
                    {!(soloTruck.isPartOfSet) ?
                        <Link to="#" className="ml-2">
                            <i onClick={() => {
                                this.toggleDeleteModal(soloTruck)
                            }} className="nc-icon nc-simple-remove delete-icon"/>
                        </Link>
                    : ""
                    }
                </td>
            </tr>
        )
    }


    render() {
        if (this.props.isFetching) {
            return (
                <div className="content">
                    <Loader/>
                </div>
            )
        }
        const {soloTrucks, meta} = this.props;
        const pagesCount = Math.ceil(meta.total / meta.perPage);
        const page = this.props.pageParams.page;
        const indexStart = this.props.pageParams.indexStart;
        return (
            <div className="content">
                <Row>
                    <Col md={12}>
                        <h4>Solo Trucks</h4>
                        <CarsSearchPanel
                            listType={"soloTrucks"}
                            showAdvancedSearch={false}
                            toggleAdvanced={null}
                            setSearchParams={this.setSearchParams}
                            searchParams={this.state.searchParams}
                        />
                        <Table>
                            <thead>
                            <tr>
                                <th>{translate('Brand / Model')}</th>
                                <th>{translate('Registration')}</th>
                                <MediaQuery query='(min-device-width: 860px)'>
                                    <th>{translate('Production year')}</th>
                                    <th>{translate('Assigned Driver')}</th>
                                </MediaQuery>
                                <th>{translate("Action")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {soloTrucks.map((soloTruck, i) => {
                                return this.renderSoloTruckRow(soloTruck, i)
                            })
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                {pagesCount > 1 && <MediaQuery minDeviceWidth={860}>
                    {(matches) => {
                        let maxPages = matches ? 10 : 3;

                        return <Paginator
                            pageChangeHandler={this.handlePageChange}
                            perPageHandler={this.handlePerPageChange}
                            pagesCount={pagesCount}
                            currentPage={page}
                            perPage={this.props.pageParams.perPage}
                            indexStart={indexStart}
                            maxPages={maxPages}
                        />
                    }}
                </MediaQuery>}
            </div>
        )
    }
}


SoloTrucks.defaultProps = {
    soloTrucks: [],
    meta: {},
    isFetching: null,
    error: false,
};

const mapStateToProps = (state) => {
    return {
        soloTrucks: state.soloTrucksReducer.soloTrucks,
        isFetching: state.soloTrucksReducer.isFetching,
        error: state.soloTrucksReducer.error,
        meta: state.soloTrucksReducer.meta,
        pageParams: state.paginationReducer.soloTrucksPaginator,
    };
};


export default connect(mapStateToProps)(SoloTrucks);
