import React from "react";
import {connect} from "react-redux";
import MediaQuery from 'react-responsive';
import {Col, Row, Table} from "reactstrap";
import {translate} from 'services/translationService';
import Paginator from "components/Paginator/Paginator";
import Loader from "components/Loader/Loader";
import {getTruckTrailers} from "../../redux/actions/car";
import {paginationActions} from "../../redux/actions";
import BodyTypeBadge from "./BodyTypeBadge";
import {Link} from "react-router-dom";
import {pageDidChange} from "../../helpers/paginator";
import CarsSearchPanel from "./CarsSearchPanel";
import {getSearchParamsForCarsList, storeSearchParamsForCarsList} from "../../helpers/carsListHelper";

class TruckTrailers extends React.Component {

    constructor(props) {
        super(props);
        const storedSearchParams = getSearchParamsForCarsList('truckTrailers');
        this.state = {
            searchParams:{
                search:"",
                ...storedSearchParams
            },
            showAdvancedSearch:!(storedSearchParams==null)
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePerPageChange = this.handlePerPageChange.bind(this);
        this.setSearchParams = this.setSearchParams.bind(this);
        this.didSearchParamsChange = this.didSearchParamsChange.bind(this);
        this.toggleAdvanced = this.toggleAdvanced.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getTruckTrailers({
            //isConnectedToSet: true,
            page: this.props.pageParams.page,
            perPage: this.props.pageParams.perPage
        }));
    }

    componentDidUpdate(prevProps, prevState) {
        if (pageDidChange(prevProps.pageParams,  this.props.pageParams))
        {
            this.props.dispatch(getTruckTrailers({
                ...this.state.searchParams,
                perPage: this.props.pageParams.perPage,
                page: this.props.pageParams.page
            }));
        }
        if(this.didSearchParamsChange(prevState)){
            this.props.dispatch(getTruckTrailers({
                ...this.state.searchParams,
                perPage: this.props.pageParams.perPage,
                page: this.props.pageParams.page
            }));
        }
    }

    didSearchParamsChange(prevState){
        return JSON.stringify(this.state.searchParams) !== JSON.stringify(prevState.searchParams);
    }


    handlePageChange(pageNumber, indexStart) {
        this.props.dispatch(paginationActions.pushChangePage('truckTrailersPaginator',pageNumber, indexStart));
    }

    handlePerPageChange(perPage) {
        this.props.dispatch(paginationActions.changePerPage('truckTrailersPaginator',perPage));
    }

    toggleDeleteModal(truckTrailer) {
        this.props.onDelete(truckTrailer);
    }

    toggleAdvanced(){
        this.setState({
            showAdvancedSearch:!this.state.showAdvancedSearch
        })
    }

    setSearchParams(searchParams) {
        this.setState({
            searchParams
        });
        storeSearchParamsForCarsList('truckTrailers',searchParams);
    }

    renderTruckTrailerRow(truckTrailer, i) {
        return(
            <tr className="car-row" key={i}>
                <td>{truckTrailer.brand.name} {truckTrailer.model.name} </td>
                <td>{truckTrailer.registrationNumber}</td>
                <MediaQuery query='(min-device-width: 860px)'>
                    <td>{truckTrailer.productionYear}</td>
                    <td>{<BodyTypeBadge specification={truckTrailer.container}/>}</td>
                    <td>{truckTrailer.container.length} / {truckTrailer.container.width} / {truckTrailer.container.height}</td>
                </MediaQuery>
                <td>
                    <Link to={`/trailer/${truckTrailer.id}`}>
                        <i className="nc-icon nc-zoom-split"/>
                    </Link>
                    <Link to={`/trailer/edit/${truckTrailer.id}`} className="ml-2">
                        <i className="nc-icon nc-settings-gear-65 setup-icon"/>
                    </Link>
                    {!(truckTrailer.isPartOfSet) ?
                        <Link to="#" className="ml-2">
                            <i onClick={() => {
                                this.toggleDeleteModal(truckTrailer)
                            }} className="nc-icon nc-simple-remove delete-icon"/>
                        </Link>
                        : ""
                    }
                </td>
            </tr>
        )
    }

    render() {
        if (this.props.isFetching) {
            return (
                <div className="content">
                    <Loader/>
                </div>
            )
        }
        const {truckTrailers, meta} = this.props;
        const pagesCount = Math.ceil(meta.total / meta.perPage);
        const page = this.props.pageParams.page;
        const indexStart = this.props.pageParams.indexStart;
        return (
            <div className="content">
                <Row>
                    <Col md={12}>
                        <h4>{translate("Truck Trailers")}</h4>
                        <CarsSearchPanel
                            listType={"truckTrailers"}
                            showAdvancedSearch={this.state.showAdvancedSearch}
                            toggleAdvanced={this.toggleAdvanced}
                            setSearchParams={this.setSearchParams}
                            searchParams={this.state.searchParams}
                        />
                        <Table>
                            <thead>
                            <tr>
                                <th>{translate('Brand / Model')}</th>
                                <th>{translate('Registration')}</th>
                                <MediaQuery query='(min-device-width: 860px)'>
                                    <th>{translate('Production year')}</th>
                                    <th>{translate('Body type')}</th>
                                    <th>{translate('Dimensions')}</th>
                                </MediaQuery>
                                    <th>{translate("Action")}</th>
                            </tr>
                            </thead>
                            <tbody>
                                {truckTrailers.map((truckTrailer, i) => {
                                    return this.renderTruckTrailerRow(truckTrailer, i)
                                })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                {pagesCount > 1 && <MediaQuery minDeviceWidth={860}>
                    {(matches) => {
                        let maxPages = matches ? 10 : 3;

                        return <Paginator
                            pageChangeHandler={this.handlePageChange}
                            perPageHandler={this.handlePerPageChange}
                            pagesCount={pagesCount}
                            currentPage={page}
                            perPage={this.props.pageParams.perPage}
                            indexStart={indexStart}
                            maxPages={maxPages}
                        />
                    }}
                </MediaQuery>}
            </div>
        )
    }
}


TruckTrailers.defaultProps = {
    truckTrailers: [],
    meta: {},
    isFetching: null,
    error: false,
};

const mapStateToProps = (state) => {
    return {
        truckTrailers: state.truckTrailersReducer.truckTrailers,
        isFetching: state.truckTrailersReducer.isFetching,
        error: state.truckTrailersReducer.error,
        meta: state.truckTrailersReducer.meta,
        pageParams: state.paginationReducer.truckTrailersPaginator,

    };
};


export default connect(mapStateToProps)(TruckTrailers);
