import React from "react";

class CodeInput extends React.Component{
    constructor(props) {
        super(props);
        this.codeInput = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.code === '------'){
            this.codeInput.current.focus();
        }
    }

    render() {
        const {focused, code, onClick} = this.props;
        const style = focused ? {borderWidth: "3px", borderColor: "darkred"} : {}
        return <div className={"code-display"} style={style} onClick={onClick} tabIndex={-1} ref={this.codeInput}>
            {typeof code === 'string' ?
                [...code].map((digit, key) => <span className="code-digit" key={key}>{digit}</span>) : ""
            }
        </div>
    }
}

export default CodeInput;


