import React from "react";
import {translate} from 'services/translationService';
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import ModalFooter from "reactstrap/es/ModalFooter";
import AddressFormSection from "../OrderDetails/OrderStatus/AddressFormSection";

class MultipleAddRemarkModal extends React.Component {

    render() {
        const {isOpen, clearModal, addressValue} = this.props;
        return (
                <Modal isOpen={isOpen} toggle={clearModal} size="lg" scrollable={true} className={"multiple-remark-modal"}>
                    <ModalHeader toggle={clearModal}>
                        {translate("Enter manual address:")}
                    </ModalHeader>
                    <ModalBody>
                      <AddressFormSection
                        addressValue={addressValue}
                        targetFormName={'multipleStatusForm'}
                      />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success"
                                className="btn-round"
                                disabled={!(addressValue.lat && addressValue.lng)}
                                onClick={clearModal}
                        >
                            {translate("OK")}
                        </Button>
                    </ModalFooter>
            </Modal>
        )
    }
}

export default MultipleAddRemarkModal;