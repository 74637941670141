import { apiService } from "services/apiService";
import {alertActions, getTranslations} from "redux/actions";
import loggedUserService from 'services/loggedUserService';
import { translate } from 'services/translationService';
import getErrorText from "../../../services/errorTextService";
import {history} from 'helpers';
import {updateSyncErrors} from "redux-form";
import {createApiSyncError} from "../../../helpers/ApiSyncErrors";

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export function getUserInfo(userId = loggedUserService.getUserId()) {
    return dispatch => {
        dispatch(request({}));
        apiService.get('/api/accounts/'+ userId)
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_USER_REQUEST, data } }
    function success(data) { return { type: FETCH_USER_SUCCESS, data } }
    function failure(error) { return { type: FETCH_USER_FAILURE, error } }
}

export function updateUserInfo(user) {
    return dispatch => {
        dispatch(request());
        apiService.put('/api/accounts/' + user.id, user)
            .then(
                response => {
                    dispatch(success(response.data));
                    dispatch(alertActions.success(translate('Data successfully saved!')));
                    if (user.id === loggedUserService.getUserId()) {
                        dispatch(getTranslations(user.language));
                    }
                })
            .catch(errorMessage => {
                dispatch(updateSyncErrors('userForm',createApiSyncError(errorMessage)));
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                }
            )
    };

    function request() { return { type: UPDATE_USER_REQUEST,  } }
    function success(data) { return { type: UPDATE_USER_SUCCESS, data } }
    function failure(error) { return { type: UPDATE_USER_FAILURE, error } }
}

export function createUser(user) {
    return dispatch => {
        dispatch(request());
        apiService.post('/api/accounts', user)
            .then(
                response => {
                    dispatch(success(response.data));
                    dispatch(alertActions.success(translate('User successfully created!')));
                    history.push('/user/'+response.data.id);
                })
            .catch(errorMessage => {
                    dispatch(updateSyncErrors('createUserForm',createApiSyncError(errorMessage)));
                    dispatch(failure(getErrorText(errorMessage)));
                    dispatch(alertActions.error(getErrorText(errorMessage)));
                }
            )
    };

    function request() { return { type: CREATE_USER_REQUEST,  } }
    function success(data) { return { type: CREATE_USER_SUCCESS, data } }
    function failure(error) { return { type: CREATE_USER_FAILURE, error } }
}
