import React, {Component} from 'react';
import {connect} from 'react-redux';
import {apiFileService} from "services/apiFileService";
import {fileHelper} from "../../helpers/fileHelper";
import {alertActions} from "../../redux/actions";
import { Document, Page } from 'react-pdf';
import {Container, Button, Row} from "reactstrap";
import Loader from "components/Loader/Loader";
import {translate} from "../../services/translationService";
import {history} from "../../helpers";

class InvoiceFile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: false,
            errorText: null,
            blob: null,
            fileName: null
        };
        this.invoiceId = window.location.hash.match("(\\d+)")[0];
        this.renderFile = this.renderFile.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.windowClose = this.windowClose.bind(this);
    }

    componentDidMount() {
        apiFileService.downloadFile(`/api/credit-notes/${this.invoiceId}`)
            .then(apiServiceResponse => {
                const blob = new Blob([apiServiceResponse.blob], {type: apiServiceResponse.fileType});
                this.setState({
                    isLoading: false,
                    error: false,
                    blob: blob,
                    fileName: apiServiceResponse.fileName
                });
            })
            .catch(response => {
                fileHelper.blobToText(response.data).then(value => {
                    let errorText = JSON.parse(value).message.error;
                    this.setState({
                        errorText:errorText,
                        error: true,
                        isLoading: false,
                    });
                    this.props.dispatch(alertActions.error(errorText))
                    history.push('/dashboard');
                });
            });
    }

    renderFile(fileUrl){
        if (this.state.blob.type === 'image'){
            return <img src={fileUrl} alt={"Credit Note Document"}/>
        }
        return (
            <Document file={fileUrl}>
                <Page
                    pageNumber={1}
                    renderAnnotationLayer={false}
                />
            </Document>
        )
    }

    downloadFile(){
        const {blob,fileName} = this.state;

        if (blob) {
            let a = window.document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    windowClose(){
        window.close();
    }

    render() {
        const {isLoading, blob} = this.state;
        let fileUrl = blob && URL.createObjectURL(blob);
        return (
            <div>
                {isLoading ?
                    <Loader />
                    :
                    fileUrl && <Container>
                        <div>
                            <Row className={"justify-content-center"}>
                                <Button
                                    color="primary"
                                    onClick={this.downloadFile}>
                                    { translate("Download File") }
                                </Button>
                                <Button
                                    outline
                                    color="primary"
                                    onClick={this.windowClose}>
                                    { translate("Close Window") }
                                </Button>
                            </Row>
                            <Row className={"justify-content-center"}>
                                {this.renderFile(fileUrl)}
                            </Row>
                        </div>
                    </Container>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(InvoiceFile);
