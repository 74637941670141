import axios from 'axios';
import {store} from 'helpers';
import {authActions} from "../redux/actions";
import {authService} from 'services';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

export const apiService = {
    get,
    post,
    put,
    apiDelete,
    sendActivation,
    getRefreshToken
};

const routes = {
    activateUser: `/api/send-activation/`
};

const pathsWithoutAccessToken = [
    "login",
    "translations",
    "dictionaries",
    "config",
    "reset-password",
    "forgot-password",
    "register"
];

const SECS_BEFORE_TOKEN_REFRESH = parseInt(`${process.env.REACT_APP_SECS_BEFORE_TOKEN_REFRESH}`);

let isRefreshing = false;

function sendActivation(userId) {
    return get(routes.activateUser + userId);
}

function getGenericConfig(url) {
    const config = {
        url: BASE_URL + url,
    };
    const token = localStorage.getItem('authToken') || null;
    const language = store.getState().translationReducer.locale || null;
    config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-language': language
    };
    return config;
}

function shouldNotRefresh(config){
    if (config === null) return false;
    let pathWithoutToken = false;
    pathsWithoutAccessToken.forEach((path)=>{
        if (config.url.includes(path)){
            pathWithoutToken = true;
        }
    });
    const now = Math.floor(new Date().getTime() / 1000);
    const tokenExpTime = localStorage.getItem('tokenExp') || now;
    const secondsLeftToRefresh = Math.floor(tokenExpTime - now);

    return pathWithoutToken || secondsLeftToRefresh > SECS_BEFORE_TOKEN_REFRESH;
}

async function getRefreshToken(config = null) {
    if (isRefreshing || shouldNotRefresh(config)){
        return;
    }
    isRefreshing = true;
    const refreshToken = localStorage.getItem('refreshToken') || null;
    try {
        const res = await axios.post(BASE_URL + '/auth/refresh-token',
            {
                "refreshToken": refreshToken
            });
        if (res.status === 200) {
            authService.storeUser(res.data);
        }
    } catch (error) {
        isRefreshing = false;
        store.dispatch(authActions.logout());
        }
    isRefreshing = false;
}

async function apiRequest(config) {

    await getRefreshToken(config);

    try {
        const response = await axios(config);

        return response.statusText === 'OK' || response.status === 204
            ? Promise.resolve(response.data)
            : Promise.reject(response.data);

    } catch (error) {
        if (error.response.status === 401) {
            store.dispatch(authActions.logout());
        }
        return Promise.reject(error.response);
    }
}


function get(url, data) {
    const config = {
        method: 'get',
        ...getGenericConfig(url),
        params: data
    };
    return apiRequest(config);
}

function post(url, data) {
    const config = {
        method: 'post',
        ...getGenericConfig(url),
        data
    };
    return apiRequest(config);
}

function put(url, data) {
    const config = {
        method: 'put',
        ...getGenericConfig(url),
        data
    };
    return apiRequest(config);
}

function apiDelete(url) {
    const config = {
        method: 'delete',
        ...getGenericConfig(url),
    };
    return apiRequest(config);
}
