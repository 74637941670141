import React from "react";
import { connect } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { Card, CardBody, Col, Row } from "reactstrap";
import PostCodeImportForm from "./PostCodeImportForm";
import "./styles.scss";
import { translate } from "../../../services/translationService";
import DistanceImportForm from "./DistanceImportForm";
import CountriesSelector from "../CountriesSelector";
import CargoOptions from "./SurchargesByOption/CargoOptions";
import WeightOptions from "./SurchargesByWeight/WeightOptions";
import HeightOptions from "./SurchargesByHeight/HeightOptions";
import SaturdayOptions from "./SurchargesByDay/SaturdayOptions";
import { formValueSelector } from "redux-form";
import HelperButton from "../../../components/Helper/HelperButton";
import FuelSurchargeForm from "./FuelSurcharge/FuelSurchargeForm";
import TollSurchargeForm from "./TollSurcharge/TollSurchargeForm";
import LengthOptions from "./SurchargesByLength/LengthOptions";

class Fracht extends React.Component {

  render() {
    const { countryToValue, countryFromValue } = this.props;
    if (this.props.isFetching) {
      return (
        <div className="content">
          <Loader />
        </div>
      );
    }
    const { countries } = this.props;
    const showWeightOptions = countryToValue === countryFromValue;
    return (
      <div className="content">
        <Card className="firstCard">
          <HelperButton helperId="carrier-countries-selector" />
          <CardBody >
            <Row >
              <Col md={3} ></Col>
              <Col md={6} >
                <div className="centered">
                  <h5 className="cooperation-title">
                    {translate("Select countries")}
                  </h5>
                </div>
                <CountriesSelector
                  countries={countries}
                  initialValues={this.props.initialValues}
                />
              </Col>
              <Col md={3}></Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <HelperButton helperId="carrier-upload-surcharges" />
          <CardBody>
            <Row>
              <Col md={6}>
                <PostCodeImportForm />
              </Col>
              <Col md={6}>
                <DistanceImportForm />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <HelperButton helperId="carrier-transport-options" />
          <CardBody>
            <Row>
              <Col md={12}>
                <CargoOptions />
              </Col>
            </Row>
          </CardBody>
        </Card>
        {showWeightOptions && (
          <Card>
            <HelperButton helperId="carrier-weight-options" />
            <CardBody>
              <Row>
                <Col md={12}>
                  <WeightOptions />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        <Card>
          <HelperButton helperId="carrier-height-options" />
          <CardBody>
            <Row>
              <Col md={12}>
                <HeightOptions />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <HelperButton helperId="carrier-length-options" />
          <CardBody>
            <Row>
              <Col md={12}>
                <LengthOptions />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <HelperButton helperId="carrier-saturday-options" />
          <CardBody>
            <Row>
              <Col md={6}>
                <SaturdayOptions />
              </Col>
              <Col md={6}></Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <HelperButton helperId="carrier-fuel-surcharge" />
          <CardBody>
            <Row>
              <Col md={6}>
                <FuelSurchargeForm />
              </Col>
              <Col md={6}>
                <TollSurchargeForm />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

Fracht.defaultProps = {
  countries: {},
  isFetching: null,
  error: false,
};

const countriesSelector = formValueSelector("countriesSelectorForm");

const mapStateToProps = (state) => ({
  countries: state.countryReducer.countries,
  isFetching: state.countryReducer.isFetching,
  error: state.countryReducer.error,
  initialValues: {
    countryFrom: "DE",
    countryTo: "DE",
  },
  countryToValue: countriesSelector(state, "countryTo"),
  countryFromValue: countriesSelector(state, "countryFrom"),
});

export default connect(mapStateToProps)(Fracht);
