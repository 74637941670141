import React, {Component} from 'react';
import {translate} from 'services/translationService';
import {Table} from 'reactstrap';
import MediaQuery from "react-responsive";

class InquiryDetailsPackages extends Component {

    renderBoxesInfo() {
        const boxes = [...this.props.boxes];

        return boxes.map((box, index) => <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{box.height} cm</td>
                    <td>{box.width} cm</td>
                    <td>{box.length} cm</td>
                     <td>{box.weight} kg</td>
                    <MediaQuery query='(min-device-width: 860px)'>
                        <td>{box.laidable ? translate("Yes") : translate("No")}</td>
                        <td>{box.gitterbox ? translate("Yes") : translate("No")}</td>
                    </MediaQuery>
                </tr>
        )
    }

    getTotalWeight() {
        return [...this.props.boxes].reduce((sum, box)=>{
            return sum + box.weight
        },0 );
    }

    render() {
        return (
            <Table size="sm" className="full-width responsive-font" hover>
                <thead>
                <tr>
                    <th></th>
                    <th>{translate("Height")}</th>
                    <th>{translate("Width")}</th>
                    <th>{translate("Length")}</th>
                    <th>{translate("Weight")}</th>
                    <MediaQuery query='(min-device-width: 860px)'>
                        <th>{translate("Stackable")}</th>
                        <th>{translate("Gitterbox")}</th>
                    </MediaQuery>

                </tr>
                </thead>
                <tbody>
                     {this.renderBoxesInfo()}
                     <tr>
                         <th colSpan={3}></th>
                         <th>{translate("Total Colli Weight")}</th>
                         <th> {this.getTotalWeight()+" kg"}</th>
                         <MediaQuery query='(min-device-width: 860px)'>
                            <th colSpan={2}></th>
                         </MediaQuery>
                     </tr>
                </tbody>
            </Table>
        );
    }
}

export default InquiryDetailsPackages;
