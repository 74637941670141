import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./styles.scss"
import {translate} from "../../../services/translationService";
import {Button, Input} from "reactstrap";
import MediaQuery from "react-responsive";
import Loader from "../../../components/Loader/Loader";

class StopForm extends Component {

  constructor(props) {
    super(props);
    let surchargesInputs = {};
    props.initialPrices.forEach(surcharge => surchargesInputs[surcharge.service_code] = {
       pickup:surcharge.pickup_price_net,
       delivery:surcharge.delivery_price_net,
    })
      this.state = {
      surchargesInputs,
      isUploading: false
    }
    this.updateSurcharges = this.updateSurcharges.bind(this);
    this.canSave = this.canSave.bind(this);
    this.isValueInvalid = this.isValueInvalid.bind(this);
  }

  updateSurcharges(e) {
    const inputIndex = e.target.name.split('-')[0];
    const inputType = e.target.name.split('-')[1];
    const inputValue = e.target.value;
    let newInputValues = {...this.state.surchargesInputs};
    newInputValues[inputIndex] = {
      pickup: inputType === 'pickup_price_net' ? inputValue : this.state.surchargesInputs[inputIndex]['pickup'],
      delivery: inputType === 'delivery_price_net' ? inputValue : this.state.surchargesInputs[inputIndex]['delivery'],
    };
    this.setState({
      surchargesInputs: newInputValues
    })
  }

  isValueInvalid(value) { return (value === "" || parseFloat(value) < 0); }

  canSave(){
    const {surchargesInputs} = this.state;
    let isValid = true;
    for (const surchargeCode in surchargesInputs){
          const deliveryValue =  surchargesInputs[surchargeCode]['delivery'];
          const pickupValue =  surchargesInputs[surchargeCode]['pickup'];
          if (this.isValueInvalid(pickupValue) || this.isValueInvalid(deliveryValue)) isValid = false;
    }
    return isValid;
  }

  render() {
    const {countryFrom, countryTo, initialPrices, isUpdating, updatePrices} = this.props;
    const {surchargesInputs} = this.state;
    return <div>
      <h5 className="cooperation-title stop-by-title">{`${translate( "Surcharge by stop on route")} ${countryFrom} - ${countryTo}`} </h5>
      <div className="stop-by-form">
        <div className="stop-by-title">
          <div>{translate("Service")}</div>
          <div className="centered">{translate("Pickup Price")}</div>
          <div className="centered">{translate("Delivery Price")}</div>
        </div>
        {initialPrices.map((surcharge, key) => <div className="stop-by-row" key={key}>
          <MediaQuery maxDeviceWidth={859}>
            {(matches) => matches ?
              <div>{surcharge.hasOwnProperty('short_name') ? surcharge.short_name : surcharge.service_name}</div> :
              <div>{surcharge.service_name}</div>}
          </MediaQuery>
          <div className={"option-input-group"}>
            <Input
              name={`${surcharge.service_code}-pickup_price_net`}
              value={surchargesInputs[surcharge.service_code]['pickup']}
              onChange={this.updateSurcharges}
              type="number"
              step="0.01"
              min="0"
              className={this.isValueInvalid(surchargesInputs[surcharge.service_code]['pickup']) ? "invalid" :""}
              title={this.isValueInvalid(surchargesInputs[surcharge.service_code]['pickup']) ? translate("Enter valid number") : "" }
            />
            <span>€</span>
          </div>
          <div className={"option-input-group"}>
            <Input
              name={`${surcharge.service_code}-delivery_price_net`}
              value={surchargesInputs[surcharge.service_code]['delivery']}
              onChange={this.updateSurcharges}
              type="number"
              step="0.01"
              min="0"
              className={this.isValueInvalid(surchargesInputs[surcharge.service_code]['delivery']) ? "invalid" :""}
              title={this.isValueInvalid(surchargesInputs[surcharge.service_code]['delivery']) ? translate("Enter valid number") : "" }
            />
            <span>€</span>
          </div>
        </div>)}
        <div className="button-container">
          <Button color="primary" size={"sm"} onClick={()=>updatePrices(surchargesInputs)}  disabled={!this.canSave()}>
            {isUpdating ? <Loader /> : translate("Save")}
          </Button>
        </div>
      </div>
    </div>
  }
}

StopForm.propTypes = {
  initialPrices: PropTypes.array,
  countryFrom: PropTypes.string,
  countryTo: PropTypes.string,
  isUpdating:PropTypes.bool,
  updatePrices:PropTypes.func,
};

export default StopForm;
