import axios from 'axios';
import {fileHelper} from "../helpers/fileHelper";

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
const PRICE_API_URL = `${process.env.REACT_APP_PRICE_API_URL}`;

export const apiFileService = {
    uploadFile,
    downloadFile,
    uploadPriceListByDistance,
    uploadPriceListByPostCodes,
    uploadTolls
};

function getFileConfig(url) {
    const config = {
        url: BASE_URL + url,
        responseType: 'blob',
    };
    const token = localStorage.getItem('authToken') || null;
    config.headers = {
        Authorization: `Bearer ${token}`,
    };
    return config;
}

function getUploadFileConfig(url, formData) {
    const config = {
        url: BASE_URL + url,
        data: formData
    };
    const token = localStorage.getItem('authToken') || null;
    config.headers = {
        Authorization: `Bearer ${token}`,
        ['Content-Type']:'multipart/form-data'
    };
    return config;
}

async function apiDownloadFileRequest(config) {
    try {
        const response = await axios(config);

        if (response.status === 200)
        {
            let fileName = fileHelper.extractFileNameFromHeader(response.headers);
            let fileType = 'unknown';
            switch (response.headers['content-type']){
                case 'image/jpg':
                case 'image/jpeg':
                case 'image/png':
                    fileType = 'image';
                    break;
                case 'application/pdf':
                    fileType = 'pdf';
                    break;
                default:
                    fileType = 'image'
            }

            return {
                blob: response.data,
                fileType,
                fileName,
                fileSize: response.data.length
            }
        }

    } catch (error) {
        console.log({
            'error':error.response
        });
        return Promise.reject(error.response);
    }
}

async function apiUploadFileRequest(config) {
    try {
        const response = await axios(config);
/* ToDo response.status === 201 */
        if (response.status === 200)
        {
            return response.data;
        }

    } catch (error) {
        return Promise.reject(error.response);
    }
}

function downloadFile(url, data) {
    const config = {
        method: 'get',
        ...getFileConfig(url),
        params: data
    };
    return apiDownloadFileRequest(config);
}

function uploadFile(url, formData) {
    const config = {
        method: 'post',
        ...getUploadFileConfig(url, formData),
    };
    return apiUploadFileRequest(config);
}

function uploadTolls({countryFrom, countryTo}, file){

    let formData = new FormData();
    formData.append('surcharge', file, 'tolls.csv')

    const config = {
        url: PRICE_API_URL + `/api/Services/Freight/Surcharges/toll/${countryFrom}/${countryTo}`,
        method: 'post',
        data: formData,
        params: {
            country_from:countryFrom,
            country_to:countryTo,
        },
        headers: {
            Authorization: `Bearer ${localStorage.getItem('cooperationApiToken') || null}`,
        }
    };

    return apiUploadFileRequest(config);
}

function uploadPriceListByDistance(pricesData, file){

    let formData = new FormData();
    formData.append('prices', file, 'prices.csv')

    const config = {
        url: PRICE_API_URL + 'api/Services/Freight/PricesByDistance/import',
        method: 'post',
        data: formData,
        params: {
            country_from:pricesData.countryFrom,
            country_to:pricesData.countryTo,
        },
        headers: {
            Authorization: `Bearer ${localStorage.getItem('cooperationApiToken') || null}`,
        }
    };

    return apiUploadFileRequest(config);
}


function uploadPriceListByPostCodes(pricesData, file){

    let formData = new FormData();
    formData.append('prices', file, 'prices.csv')

    const config = {
        url: PRICE_API_URL + 'api/Services/Freight/PricesByCodes/import',
        method: 'post',
        data: formData,
        params: {
            country_from:pricesData.countryFrom,
            country_to:pricesData.countryTo,
            postcode_from:pricesData.postCode
        },
        headers: {
            Authorization: `Bearer ${localStorage.getItem('cooperationApiToken') || null}`,
        }
    };

    return apiUploadFileRequest(config);
}