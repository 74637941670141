import {translate} from "../../../services/translationService";

const cooperationValidator = {
    postCode: new RegExp('^[a-zA-Z0-9\\s-]+$'),
}

export const validateDownloadByPostCode = (values) => {
    const errors = {
    };
    if (!values.postCode) {
        errors.postCode = 'Post Code is required';
    } else if (cooperationValidator.postCode.test(values.postCode) === false) {
        errors.postCode = 'Post code can contain only letters, numbers, spaces and minus';
    }
    if (!values.countryFrom) {
        errors.countryFrom = 'Country is required';
    }
    if (!values.countryTo) {
        errors.countryTo = 'Country is required';
    }

    return errors;
};

export const validateFuelSurcharge = (values) => {
    const errors = {
    };
    if (!values.fuelSurcharge) {
        errors.fuelSurcharge = translate('Number is required');
    } else if ((parseInt(values.fuelSurcharge) < -100) || ((parseInt(values.fuelSurcharge)) > 100)) {
    errors.fuelSurcharge = translate('Value must be between -100 and 100');
  }
       return errors;
};
