import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/de'
import {store} from "../helpers";

const formats = {
  date: 'DD-MM-YYYY',
  weekDayDate: 'dd DD-MM-YY',
  time: 'HH:mm',
  dateTime: 'DD-MM-YY HH:mm',
  fullDateTime: 'YYYY-MM-DD HH:mm:ss',
  isoDate: 'YYYY-MM-DD'
}

export const formatDate = (format) => (date) =>{
  const language = store.getState().translationReducer.locale;
  moment.locale(language);
  return date ? moment(date).format(format) : moment().format(format);
}


export const fullDate = (date) => formatDate(formats.date)(date);
export const dateTime = (date) => formatDate(formats.dateTime)(date);
export const weekDayDate = (date) => formatDate(formats.weekDayDate)(date);
export const isoDate = (date) => formatDate(formats.isoDate)(date);
export const fullDateTime = (date) => formatDate(formats.fullDateTime)(date);
export const time = (date) => formatDate(formats.time)(date);

export const combineDateAndTime = (dateStr, timeStr) => {
  const combinedDateTime = moment(`${dateStr} ${timeStr}`, "YYYY-MM-DD HH:mm");
  return combinedDateTime.format("YYYY-MM-DD HH:mm:ss");
}

export const timeInterval = (timeFrom, timeTo) => {
  return `${timeFrom.slice(0, -3)} - ${timeTo.slice(0, -3)}`
}

export const arrayToQueryParam = (numbersInArray, paramName) => numbersInArray.reduce(
  (res, id, index)=>{
    return index === 0 ? `?${paramName}[]=${id}` : res + `&${paramName}[]=${id}`;
  }, ''
)