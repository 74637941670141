import {
    FETCH_INQUIRY_REQUEST,
    FETCH_INQUIRY_SUCCESS,
    FETCH_INQUIRY_FAILURE,
} from 'redux/actions/inquiries/inquiry.actions.js';

const initialState = {
    inquiry: {},
    isFetching: true,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INQUIRY_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
                error:false
            });
        }

        case FETCH_INQUIRY_SUCCESS: {
            return Object.assign({}, state, {
                inquiry: action.data,
                isFetching: false,
            });
        }

        case FETCH_INQUIRY_FAILURE:{
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }

        default:
            return state
    }
}
