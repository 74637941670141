import React from "react";
import {Card, CardBody, Col, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, Form, Input} from "reactstrap";
import {translate} from 'services/translationService';
import "../../style.scss";
import ModalFooter from "reactstrap/es/ModalFooter";
import {apiService} from "../../../../services/apiService";
import {alertActions} from "../../../../redux/actions";
import getErrorText from "../../../../services/errorTextService";
import {connect} from "react-redux";
import {history} from "../../../../helpers";

class SetSpecification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            updateFormOpen: false,
            maxWeight: this.props.carSet.maxWeight,
            editEnabled: true
        };
        this.toggleUpdateSetForm = this.toggleUpdateSetForm.bind(this);
        this.handleWeightChange = this.handleWeightChange.bind(this);
        this.updateSet = this.updateSet.bind(this);
    }

    toggleUpdateSetForm() {
        this.setState({
            updateFormOpen: !this.state.updateFormOpen
        });
    }


    handleWeightChange(event) {
        const enteredWeight = event.target.value.replace(/([^0-9]+)/gi, '');
        this.setState({
            maxWeight: enteredWeight,
            editEnabled: enteredWeight > 0
        })
    }

    updateSet() {
        this.toggleUpdateSetForm();
        const {carSet, dispatch} = this.props;
        apiService.put('api/vehicles/sets/' + carSet.id, {
            maxWeight: this.state.maxWeight,
            car: {
                id: carSet.car.id,
            },
            truckTrailer: {
                id: carSet.truckTrailer.id
            }
        })
            .then(response => {
                dispatch(alertActions.success(translate("Set data updated successfully")));
                history.push('/cars');
            }).catch(errorMessage => {
            dispatch(alertActions.error(getErrorText(errorMessage)));
        })
    }

    render() {
        const {carSet} = this.props;
        const totalLength =
            (carSet.car.hasOwnProperty('container') ? carSet.car.container.length : 0) +
            (carSet.truckTrailer.hasOwnProperty('container') ? carSet.truckTrailer.container.length : 0);
        const maxWeight = carSet.maxWeight;
        return <>
        <Row>
                    <Col md={2}>
                    </Col>
                    <Col md={10}>
                        <Card className="storage-details">
                        <CardBody>
                        <Row>
                            <Col md={8}>
                                <h5>
                                    <i className="nc-icon nc-alert-circle-i"/> {translate('Set specification')}
                                </h5>
                            </Col>
                            <Col md={4}>
                                <Button className="button-edit" color="info" onClick={this.toggleUpdateSetForm}>
                                    {translate("Edit")}
                                </Button>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col md={12}>
                                <Table size="sm" borderless>
                                    <tbody>
                                    <tr>
                                        <th>
                                            <label>{translate('Vehicle set length')}: </label>
                                        </th>
                                        <td className="halfWidth">
                                            {totalLength} cm
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <Label>{translate("Vehicle set max weight")}</Label>
                                        </th>
                                        <td>{maxWeight} kg</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        </CardBody>
                        </Card>
                    </Col>
            </Row>
            <Modal id="set-data-update" isOpen={this.state.updateFormOpen} toggle={this.toggleUpdateSetForm}>
                <ModalHeader toggle={this.toggleUpdateSetForm}>
                    {translate("Edit set data:")}
                </ModalHeader>
                <ModalBody>
                    <Form inline className="text-center">
                        <label>{translate("Max weight")}: </label>
                        <Input type="text" name="maxWeigh" value={this.state.maxWeight}
                               onChange={this.handleWeightChange}/>
                        <span> kg</span>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={this.updateSet} disabled={!this.state.editEnabled}>
                        {translate("OK")} </Button>
                    <Button onClick={this.toggleUpdateSetForm}>{translate("Cancel")}</Button>
                </ModalFooter>
            </Modal>
        </>
    }
}

export default connect()(SetSpecification);


