import {translate} from "../services/translationService";

export function translateFormLabels() {
    return {
        invoicingDataName: translate("Invoicing data name"),
        carrierName: translate('Carrier Name'),
        carrierPhone: translate("Carrier Phone"),
        carrierEmail: translate("Carrier Email"),
        carrierBankName: translate("Bank Name"),
        carrierIban: translate("IBAN"),
        carrierTaxNumberUe: translate("EU VAT-ID"),
        carrierTaxNumberDe: translate("Tax Number"),
        carrierIsVatPayer: translate("Is Vat Payer"),
        carrierBillingPeriod: translate("Billing time"),
        accountName: translate("First Name"),
        accountSurname: translate("Last Name"),
        accountPhone: translate("Phone"),
        accountEmail: translate("Email"),
        accountLanguage: translate("Language"),
        accountDocumentNumber: translate("Employee number"),
        GTCchecked:translate("Please confirm Janta GTC"),
        addressStreet:translate("Street"),
        addressZip:translate("ZIP Code"),
        addressCity:translate("City"),
        addressCountry:translate("Country"),
        addressProvince:translate("Province"),
        addressContactContact:translate("Contact name"),
        addressContactPhone:translate("Phone number"),
        addressContactEmail:translate("email"),
        passwordCurrent:translate("Current password"),
        passwordNew:translate("New password"),
    };
}
