import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';



const AddPhoto = ({index, sefFile}) =><div>
    <label htmlFor={`add-photo-${index}`}>
        <div className={"add-photo-box"}>
            <i className="nc-icon nc-camera-compact" />
        </div>
    </label>
    <input
        accept="image/png, image/jpeg, image/webp"
        type="file"
        autoComplete="off"
        tabIndex="-1"
        onChange={(e)=>sefFile(e.target.files[0], index)}
        id={`add-photo-${index}`}
    />
</div>

AddPhoto.propTypes = {
    index: PropTypes.number.isRequired,
    sefFile: PropTypes.func.isRequired
};


export default AddPhoto;
