import React from "react";
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {connect} from 'react-redux';
import {getVehicle, updateVehicle} from "../../../../redux/actions/car/vehicle.actions";
import {translate} from 'services/translationService';
import Loader from "components/Loader/Loader";
import EditCarForm from "./EditCarForm";

class EditCar extends React.Component {

    constructor(props) {
        super(props);
        this.vehicleId = this.props.match.params.vehicleId;
        this.editCarSubmitTrigger = this.editCarSubmitTrigger.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getVehicle(this.vehicleId));
    }

    editCarSubmitTrigger(formData) {
        let formDataToSend = Object.assign({}, formData);
        formDataToSend.type = this.props.vehicle.type.toUpperCase();
        delete formDataToSend.filters;
        this.props.dispatch(updateVehicle(this.vehicleId, formDataToSend));
    }

    render() {
        const {isFetching, vehicle} = this.props;
        if (isFetching) {
            return (
                <div className="content">
                    <Loader/>
                </div>
            )
        }
        const values = {
            container: vehicle.container,
            registrationNumber: vehicle.registrationNumber,
            productionYear: vehicle.productionYear,
            brand: vehicle.brand,
            model: vehicle.model,
        };
        return (
            <div className="content">

                <Row>
                    <Col md={12}>
                        <Card className="card-user">
                            <CardHeader>
                                <CardTitle>
                                    <h4><i
                                        className="nc-icon nc-settings-gear-65"/> {translate('Change your vehicle data')}
                                    </h4>
                                    <hr/>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="outerForm">
                                    <EditCarForm
                                        onSubmitHandler={this.editCarSubmitTrigger}
                                        initialValues={values}
                                        vehicleType={vehicle.type.toLowerCase()}
                                    />
                                    <div className={isFetching ? "formOverlay" : "formOverlay invisible "}>
                                        <Loader/>
                                    </div>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

EditCar.defaultProps = {
    selectedSpecification: {},
    isFetching: true
};

const mapStateToProps = (state) => {
    return {
        vehicle: state.vehicleReducer.vehicle,
        isFetching: state.vehicleReducer.isFetching,
        selectedSpecification: state.specificationReducer.specification,
    }
};
export default connect(mapStateToProps)(EditCar);
