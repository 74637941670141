import React from "react";
import {translate} from 'services/translationService';
import {Button, Modal, ModalBody, ModalHeader, Table} from "reactstrap";
import {connect} from "react-redux";
import Loader from "../../../components/Loader/Loader";
import {getShipmentRemark} from "../../../redux/actions";
import ModalFooter from "reactstrap/es/ModalFooter";
import MediaQuery from 'react-responsive';

class ShowRemarkModal extends React.Component {

    constructor(props) {
        super(props);
        this.showPicture = this.showPicture.bind(this);
    }

    showPicture(src) {
        window.open(src);
    }

    componentDidMount() {
        const {remarkId, dispatch} = this.props;
        dispatch(getShipmentRemark(remarkId));
    }

    componentDidUpdate(prevProps) {
        const {dispatch, remarkId} = this.props;
        if (prevProps.remarkId !== this.props.remarkId){
            dispatch(getShipmentRemark(remarkId));
        }
    }

    render() {
        const {remark, isFetching, toggleModal} = this.props;
        const hasPictures = remark.hasOwnProperty('images');

        return (
            <div className="remark-details">
                <Modal isOpen={this.props.showModal} toggle={toggleModal} size="lg" scrollable={true} className={"remark-details-modal"}>
                    <ModalHeader toggle={toggleModal}>{translate("Shipment Remarks details")}
                    </ModalHeader>
                    <ModalBody>
                        {(isFetching || Object.keys(remark).length === 0) ? <Loader/> :
                        <div className={"remark-table"}>
                            <div className={"remark-basic-data"}>
                                <div>
                                    <div className="comment-title">{translate("Remark category")}</div>
                                    <div>{remark.categoryName}</div>
                                </div>
                                <div>
                                    <div className="comment-title">{translate("Added by")}</div>
                                    <div>{remark.addedBy}</div>
                                </div>
                            </div>
                            <div className="comment-title">{translate("Comment")}</div>
                            <div className="comment-text">{remark.description}</div>
                            {hasPictures &&<>
                                <div className={"comment-title"}>{translate("Attached pictures")}</div>
                                <div className={"pictures-container"}>
                                    {remark.images.map((imageSrc, key)=>{
                                        return <>
                                        <MediaQuery minDeviceWidth={769}>
                                            <img src={imageSrc} key={key} alt={`remark image ${key}`}
                                            onClick={()=>this.showPicture(imageSrc)} />
                                        </MediaQuery>
                                        <MediaQuery maxDeviceWidth={768}>
                                            <img src={imageSrc} key={key} alt={`remark image ${key}`} />
                                        </MediaQuery>
                                    </>})}
                                </div>
                            </>}
                        </div>
                        }
                    <ModalFooter>
                        <Button color="success" onClick={toggleModal}>
                            {translate("Close")} </Button>
                    </ModalFooter>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

ShowRemarkModal.defaultProps = {
    isFetching: null,
    error: false,
    remark: {},
    remarkId: null
}

function mapStateToProps(state) {
    return {
        remark:state.shipmentRemarkReducer.remark,
        isFetching: state.shipmentRemarkReducer.isFetching,
        error: state.shipmentRemarkReducer.error
    };
}

export default connect(
    mapStateToProps,
)(ShowRemarkModal);
