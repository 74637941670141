import React from "react";
import {Badge} from "reactstrap";
import {translate} from 'services/translationService';

const roleName = (role) => {
    return role.substring(5).toLowerCase();
};

const RoleBadge = ({roles}) => {

  const skippedRole = 'ROLE_DEFAULT_MANAGER';

  return (

            roles.filter(role=>role !== skippedRole).map((role, i) => {
                return (<Badge className={roleName(role)} pill key={i}>
                    { translate(roleName(role)) }
                </Badge>);

        })
    )
};


export default RoleBadge;
