import React from "react";
import {Field} from "redux-form";
import {translate} from 'services/translationService';
import FieldMultiSelect from "components/FormFields/FieldMultiSelect/FieldMultiSelect";

const roleMultiSelect  =() => {
    return (

            <Field
                name="roles"
                label={translate("Roles")}
                options={
                    [
                        { value: 'ROLE_MANAGER', text: translate('Manager') },
                        { value: 'ROLE_DRIVER', text: translate('Driver') },
                    ]
                }
                textField="text"
                valueField="value"
                component={FieldMultiSelect}
            />

    )
}

export default roleMultiSelect;
