import {apiService} from "services/apiService";
import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";

export const FETCH_MULTIPLE_ORDERS_REQUEST = 'FETCH_MULTIPLE_ORDERS_REQUEST';
export const FETCH_MULTIPLE_ORDERS_SUCCESS = 'FETCH_MULTIPLE_ORDERS_SUCCESS';
export const FETCH_MULTIPLE_ORDERS_FAILURE = 'FETCH_MULTIPLE_ORDERS_FAILURE';

export function getMultipleOrders(searchParams) {
    return dispatch => {
        dispatch(request([]));

        apiService.get('api/orders/multiple' + searchParams)
            .then(
                response => {
                    dispatch(success(response));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_MULTIPLE_ORDERS_REQUEST, data } }
    function success(data) { return { type: FETCH_MULTIPLE_ORDERS_SUCCESS, data } }
    function failure(error) { return { type: FETCH_MULTIPLE_ORDERS_FAILURE, error } }
}
