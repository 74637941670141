import {formValueSelector} from "redux-form";
import React from "react";
import {connect} from "react-redux";
import {getDedicatedStop, updateDedicatedStop,} from "../../../redux/actions";
import Loader from "../../../components/Loader/Loader";
import StopForm from "./StopForm";

class DedicatedStop extends React.Component {

    constructor(props) {
        super(props);
        this.updateStopPrices = this.updateStopPrices.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {countryFromValue, countryToValue, dispatch} = this.props;
        if ((prevProps.countryFromValue !== countryFromValue) || (prevProps.countryToValue !== countryToValue)) {
            const params = {
                countryFrom: countryFromValue,
                countryTo: countryToValue
            }
            dispatch(getDedicatedStop(params));
        }
    }

    updateStopPrices (formData)  {
        const {dispatch, stopSurcharges} = this.props;
        let updatedSurcharges = stopSurcharges.map((surcharge)=>{
            let updatedSurcharge = {...surcharge};
            delete updatedSurcharge['service_name'];
            delete updatedSurcharge['short_name'];
            return {...updatedSurcharge,
                pickup_price_net: parseFloat(formData[surcharge.service_code]['pickup']),
                delivery_price_net:parseFloat(formData[surcharge.service_code]['delivery'])
            }
        });
        dispatch(updateDedicatedStop(updatedSurcharges));
    }

    render() {
        const {countryFromValue, countryToValue, isFetchingByStop, stopSurcharges, isUpdatingByStop} = this.props;
        return <div className="surcharges-layout">
            {isFetchingByStop ? <Loader /> : <>
            <StopForm
              countryFrom={countryFromValue}
              countryTo={countryToValue}
              initialPrices={stopSurcharges}
              isUpdating={isUpdatingByStop}
              updatePrices={this.updateStopPrices}
            />
            </>}
        </div>
    }
}

DedicatedStop.defaultProps = {
    stopSurcharges: [],
    isFetchingByStop: null,
    isUpdatingByStop: null,
    error: false,
};

const selector = formValueSelector('countriesSelectorForm');

const mapStateToProps = (state) => {
    return {
        countryFromValue: selector(state, 'countryFrom'),
        countryToValue: selector(state, 'countryTo'),
        stopSurcharges: state.dedicatedByStopReducer.stopSurcharges,
        isFetchingByStop: state.dedicatedByStopReducer.isFetchingByStop,
        isUpdatingByStop: state.dedicatedByStopReducer.isUpdatingByStop,
        error: state.dedicatedSurchargesReducer.error,
    };
};

export default connect(mapStateToProps)(DedicatedStop);