import React from "react";
import image from "assets/img/Janta_Logo_transparent.svg"

export const Logo = () =>  {
        return (
            <div className="container">
                <div className="banner">
                    <img src={image} alt="JANTA logo"onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src='https://janta-logistics.de/wp-content/themes/janta-new/img/janta-logo.svg';
                    }}  />
                </div>
            </div>
        );
    };
