import React, {Component} from 'react';
import {translate} from "../../../services/translationService";
import "./styles.scss"
import {Input} from "reactstrap";
import {getInputErrorClassInSet} from "../../../helpers/formHelper";

class ContainerRules extends Component {

  constructor(props) {
    super(props);
    this.updateContainerRules = this.updateContainerRules.bind(this)
    this.renderContainerRulesSet = this.renderContainerRulesSet.bind(this)
  }

  updateContainerRules(e){
    let newInputs = {...this.props.configInputs};
    newInputs.containersToCapacityAlgorithm = [...this.props.configInputs.containersToCapacityAlgorithm];
    const inputValue = e.target.value;
    const inputName = e.target.name;
    const type = inputName.split('-')[0];
    const row = inputName.split('-')[1];
    const field = inputName.split('-')[2];
    newInputs.containersToCapacityAlgorithm[row][type][field] = inputValue
    this.props.updateInputs(newInputs)
  }

  renderContainerRulesSet(setIndex, rulesType){
    const {configInputs} = this.props;
    const typeName =  rulesType === 'car' ? translate("Car") : translate ("Truck trailer")
    return <div className={"containers-set-inputs"}>
      <div className="container-type container-type-title">
        {typeName}
      </div>
      <div className="containers-label">{translate("Length") + " [cm]"}</div>
      <div className="containers-label">{translate("Width") + " [cm]"}</div>
      <div className="containers-label">{translate("Height") + " [cm]"}</div>
      <div className="containers-label">{translate("Capacity") + " [kg]"}</div>
      <div className="space-type-1">{translate("Cargo Space")}</div>
      <Input
        name={`${rulesType}-${setIndex}-length`}
        id={`${rulesType}-${setIndex}-length`}
        value={configInputs.containersToCapacityAlgorithm[setIndex][rulesType].length}
        className={getInputErrorClassInSet(configInputs.containersToCapacityAlgorithm[setIndex][rulesType], 'length')}
        onChange={this.updateContainerRules}
        type="number"
        step="1"
        min="0"
      />
      <Input
        name={`${rulesType}-${setIndex}-width`}
        id={`${rulesType}-${setIndex}-width`}
        value={configInputs.containersToCapacityAlgorithm[setIndex][rulesType].width}
        className={getInputErrorClassInSet(configInputs.containersToCapacityAlgorithm[setIndex][rulesType], 'width')}
        onChange={this.updateContainerRules}
        type="number"
        step="1"
        min="0"
      />
      <Input
        name={`${rulesType}-${setIndex}-height`}
        id={`${rulesType}-${setIndex}-height`}
        value={configInputs.containersToCapacityAlgorithm[setIndex][rulesType].height}
        className={getInputErrorClassInSet(configInputs.containersToCapacityAlgorithm[setIndex][rulesType], 'height')}
        onChange={this.updateContainerRules}
        type="number"
        step="1"
        min="0"
      />
      <Input
        name={`${rulesType}-${setIndex}-capacity`}
        id={`${rulesType}-${setIndex}-capacity`}
        value={configInputs.containersToCapacityAlgorithm[setIndex][rulesType].capacity}
        className={getInputErrorClassInSet(configInputs.containersToCapacityAlgorithm[setIndex][rulesType], 'capacity')}
        onChange={this.updateContainerRules}
        type="number"
        step="1"
        min="0"
      />
      <div className="containers-empty"></div>
      <div className="containers-label">{translate("Length") + " [cm]"}</div>
      <div className="containers-label">{translate("Width") + " [cm]"}</div>
      <div className="containers-label">{translate("Height") + " [cm]"}</div>
      <div className="containers-label">{translate("Distance to front") + " [cm]"}</div>
      <div className="space-type-2">{translate("Wheel arch")}</div>
      <Input
        name={`${rulesType}-${setIndex}-wheel_arch_length`}
        id={`${rulesType}-${setIndex}-wheel_arch_length`}
        value={configInputs.containersToCapacityAlgorithm[setIndex][rulesType].wheel_arch_length}
        className={getInputErrorClassInSet(configInputs.containersToCapacityAlgorithm[setIndex][rulesType], 'wheel_arch_length')}
        onChange={this.updateContainerRules}
        type="number"
        step="1"
        min="0"
      />
      <Input
        name={`${rulesType}-${setIndex}-wheel_arch_width`}
        id={`${rulesType}-${setIndex}-wheel_arch_width`}
        value={configInputs.containersToCapacityAlgorithm[setIndex][rulesType].wheel_arch_width}
        className={getInputErrorClassInSet(configInputs.containersToCapacityAlgorithm[setIndex][rulesType], 'wheel_arch_width')}
        onChange={this.updateContainerRules}
        type="number"
        step="1"
        min="0"
      />
      <Input
        name={`${rulesType}-${setIndex}-wheel_arch_height`}
        id={`${rulesType}-${setIndex}-wheel_arch_height`}
        value={configInputs.containersToCapacityAlgorithm[setIndex][rulesType].wheel_arch_height}
        className={getInputErrorClassInSet(configInputs.containersToCapacityAlgorithm[setIndex][rulesType], 'wheel_arch_height')}
        onChange={this.updateContainerRules}
        type="number"
        step="1"
        min="0"
      />
      <Input
        name={`${rulesType}-${setIndex}-wheel_arch_distance_to_front`}
        id={`${rulesType}-${setIndex}-wheel_arch_distance_to_front`}
        value={configInputs.containersToCapacityAlgorithm[setIndex][rulesType].wheel_arch_distance_to_front}
        className={getInputErrorClassInSet(configInputs.containersToCapacityAlgorithm[setIndex][rulesType], 'wheel_arch_distance_to_front')}
        onChange={this.updateContainerRules}
        type="number"
        step="1"
        min="0"
      />
    </div>
  }

  render() {
    return <>
      <div className="centered"><h5 className="cooperation-title">{translate("Container rules")}</h5></div>
      <div className={"container-set-title"}>Set 1</div>
      {this.renderContainerRulesSet(0, 'car')}
      {this.renderContainerRulesSet(0, 'truck_trailer')}
      <div className={"container-set-title"}>Set 2</div>
      {this.renderContainerRulesSet(1, 'car')}
      {this.renderContainerRulesSet(1, 'truck_trailer')}
    </>
  }
}

export default ContainerRules