import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {history} from "../../../helpers";
import {priceApiService} from "../../../services/priceApiService";
import {translate} from "../../../services/translationService";
import loggedUserService from "../../../services/loggedUserService";

export const FETCH_INVITATIONS_REQUEST = 'FETCH_INVITATIONS_REQUEST';
export const FETCH_INVITATIONS_SUCCESS = 'FETCH_INVITATIONS_SUCCESS';
export const FETCH_INVITATIONS_FAILURE = 'FETCH_INVITATIONS_FAILURE';

export const ACCEPT_INVITATION_REQUEST = 'ACCEPT_INVITATION_REQUEST';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_FAILURE = 'ACCEPT_INVITATION_FAILURE';

export const ADD_INVITATION_REQUEST = 'ADD_INVITATION_REQUEST';
export const ADD_INVITATION_SUCCESS = 'ADD_INVITATION_SUCCESS';
export const ADD_INVITATION_FAILURE = 'ADD_INVITATION_FAILURE';

export function getInvitations() {
    return dispatch => {
        dispatch(request({}));
        priceApiService.getInvitations()
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                history.push('/dashboard');
            });
    };

    function request(data) { return { type: FETCH_INVITATIONS_REQUEST, data } }
    function success(data) { return { type: FETCH_INVITATIONS_SUCCESS, data } }
    function failure(error) { return { type: FETCH_INVITATIONS_FAILURE, error } }
}

export function acceptInvitation(uuid) {
  return dispatch => {
    dispatch(request({}));
    priceApiService.acceptInvitation({uuid, name:loggedUserService.getFulName()})
      .then(
        response => {
          dispatch(success(response.data));
          dispatch(alertActions.success(translate("Invitation was accepted successfully")));
        })
      .catch(errorMessage => {
        dispatch(failure(getErrorText(errorMessage)));
        dispatch(alertActions.error(translate("Invitation code hase been already used or it does not exist")));
      });
  };

  function request(data) { return { type: ACCEPT_INVITATION_REQUEST, data } }
  function success(data) { return { type: ACCEPT_INVITATION_SUCCESS, data } }
  function failure(error) { return { type: ACCEPT_INVITATION_FAILURE, error } }
}

export function sendInvitation(email, language) {
  return dispatch => {
    dispatch(request({}));
    priceApiService.sendInvitation({email, name:loggedUserService.getFulName(), lang:language})
      .then(
        response => {
          dispatch(success(response.data));
          dispatch(alertActions.success(translate("Invitation was sent successfully")));
        })
      .catch(errorMessage => {
        dispatch(failure(getErrorText(errorMessage)));
        dispatch(alertActions.error(translate("Invitation was not sent, please try again")));
        history.push('/dashboard');
      });
  };

  function request(data) { return { type: ADD_INVITATION_REQUEST, data } }
  function success(data) { return { type: ADD_INVITATION_SUCCESS, data } }
  function failure(error) { return { type: ADD_INVITATION_FAILURE, error } }
}