import {apiService} from "services/apiService";
import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";

export const UPDATE_PALLET_BALANCE_REQUEST = 'UPDATE_PALLET_BALANCE_REQUEST';
export const UPDATE_PALLET_BALANCE_SUCCESS = 'UPDATE_PALLET_BALANCE_SUCCESS';
export const UPDATE_PALLET_BALANCE_FAILURE = 'UPDATE_PALLET_BALANCE_FAILURE';

export function updatePalletBalance(id, formData) {
    return dispatch => {
        dispatch(request({}));

        apiService.put('/api/orders/' + id+'/pallet_balance', formData)
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: UPDATE_PALLET_BALANCE_REQUEST, data } }
    function success(data) { return { type: UPDATE_PALLET_BALANCE_SUCCESS, data } }
    function failure(error) { return { type: UPDATE_PALLET_BALANCE_FAILURE, error } }
}