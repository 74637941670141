export const authConstants = {
    LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',

    LOGOUT: 'AUTH_LOGOUT',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    NEW_PASSWORD_REQUEST: 'NEW_PASSWORD_REQUEST',
    NEW_PASSWORD_SUCCESS: 'NEW_PASSWORD_SUCCESS',
    NEW_PASSWORD_FAILURE: 'NEW_PASSWORD_FAILURE',
};
