export function createApiSyncError(error) {
    let syncErrorObject = {
        account: {},
        carrier: {}
    };
    let errors = error.data.message.error;

    Object.keys(errors).forEach(errorKey => {
        if (errorKey === 'taxNumberUe') {
            syncErrorObject.carrier.taxNumberUe = errors[errorKey];
        }
        if (errorKey === 'email') {
            syncErrorObject.account.email = errors[errorKey];
            syncErrorObject.email = errors[errorKey];
        }
    });
    return syncErrorObject;
}
