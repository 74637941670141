import {apiService} from "services/apiService";
import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";

export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

export const ORDERS_TYPES = {
    ALL: 'all',
    NEW: 'new',
    ONGOING: 'ongoing',
    DELIVERED: "delivered",
    PROCESSED: "processed"
};

export function getOrders(type, params) {
    return dispatch => {
        dispatch(request({}));

        apiService.get('/api/orders/list/' + type, params)
            .then(
                response => {
                    dispatch(success(response));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_ORDERS_REQUEST, data } }
    function success(data) { return { type: FETCH_ORDERS_SUCCESS, data } }
    function failure(error) { return { type: FETCH_ORDERS_FAILURE, error } }
}
