import {isEmpty, isNull} from "lodash";

export const remarkCategoriesForSelect = (remarkCategories) => {
  return remarkCategories.map(category => {
    return {
      id: category.id,
      name: category.categoryName
    }
  });
};

export const shipmentLifecyclesForSelect = (shipmentLifecycles) => {
  return shipmentLifecycles.map(lifecycle => {
    return {
      id: lifecycle.id,
      name: `${lifecycle.shipmentStatus.code} ${lifecycle.shipmentStatus.text} (${lifecycle.dateAdd})`,
    }
  });
};

export const prepareRemarkData = (formData, files) => {
  if (formData.hasOwnProperty('packages')) {
    formData.packages = [...formData.packages].map(box => box.id);
  }
  if (formData.shipment_lifecycle_id === '0') {
    delete formData.shipment_lifecycle_id;
  }
  let objectFormData = new FormData();
  Object.keys(formData).forEach(key => {
    if (Array.isArray(formData[key])) {
      formData[key].forEach((element, index) => {
        objectFormData.append(`${key}[${index}]`, element);
      });
    } else {
      objectFormData.append(key, formData[key]);
    }
  });

  files.forEach((file, index) => {
    if (file) {
      objectFormData.append(`picture_${index + 1}`, file, file.name)
    }
  })

  return objectFormData;
}

export const prepareRemarkDataForMultiple = (remarkData, lifeCycleId) => {
  let objectFormData = new FormData();
  objectFormData.append('shipment_remark_category_id', remarkData.shipment_remark_category_id);
  objectFormData.append('shipment_lifecycle_id', lifeCycleId);
  objectFormData.append('description', remarkData.description);
  remarkData.packages.forEach((packageId, index) => {
    objectFormData.append(`packages[${index}]`, packageId);
  })
  remarkData.files.forEach((file, index) => {
    if (file) {
      objectFormData.append(`picture_${index + 1}`, file, file.name)
    }
  })
  return objectFormData;
}

export const getRemarkDataForShipmentId = (remarksData, shipmentId) => {
  for (const orderId in remarksData) {
    if (remarksData[orderId].shipmentId === shipmentId) {
      return [orderId, remarksData[orderId]]
    }
  }
  return [null, null];
}

export const getDistanceLabel = (distanceString) => {
  const distance = parseInt(distanceString)
  switch (true) {
    case (distance === 30):
      return "0 - 30 km";
    case (distance === 50):
      return "30.1 - 50 km";
    case (distance <= 350):
      return `${distance - 50}.1 - ${distance} km`
    case (distance > 350):
    default:
      return "> 350 km:";
  }
}

const freightInputStructure = {
  primaryFactor: "",
  additionalFactors: [
    {
      min_length: "0",
      max_length: "0",
      max_width: "0",
      max_height: "0",
      factor: "0"
    }
  ],
  pallets: [
    {
      length: "0",
      width: "0",
      weight: "0",
      laidable_weight_limit: "0",
      is_gitterbox: false
    }
  ],
  maxWeightPerService: {},
  availableServices: {},
  maxCurrentMeterWeight: "",
  containersToCapacityAlgorithm: [
    {
      car: {
        length: "0",
        width: "0",
        height: "0",
        capacity: "0",
        wheel_arch_distance_to_front: "0",
        wheel_arch_length: "0",
        wheel_arch_width: "0",
        wheel_arch_height: "0"
      },
      truck_trailer: {
        length: "0",
        width: "0",
        height: "0",
        capacity: "0",
        wheel_arch_distance_to_front: "0",
        wheel_arch_length: "0",
        wheel_arch_width: "0",
        wheel_arch_height: "0"
      }
   },
    {
      car: {
        length: "0",
        width: "0",
        height: "0",
        capacity: "0",
        wheel_arch_distance_to_front: "0",
        wheel_arch_length: "0",
        wheel_arch_width: "0",
        wheel_arch_height: "0"
      },
      truck_trailer: {
        length: "0",
        width: "0",
        height: "0",
        capacity: "0",
        wheel_arch_distance_to_front: "0",
        wheel_arch_length: "0",
        wheel_arch_width: "0",
        wheel_arch_height: "0"
      }
    }
  ],
  minDaysAfterPickupForDelivery:"",
  maxDaysAfterPickupForDelivery:"",
  avoidCountries:{},
  maxHeightWithoutSurcharge:"",
  liftCargoOptionLimits:{
    maxLength: "0",
    maxWidth: "0",
    maxWeight: "0",
  },
  laidableOptionLimits:{
    maxLength: "0",
    maxWidth: "0",
    maxHeight: "0",
  }
}

export const createFrachtConfigInputs = (frachtConfig = null) => {
  const createConfigItem = (configItem, itemPattern) => {
    let newItem = {};
    for (let field in itemPattern) {
      newItem[field] = configItem[field];
    }
    return newItem;
  }

  if (isNull(frachtConfig)) {
    return freightInputStructure
  }
  let frachtInputs = {...freightInputStructure};
  frachtInputs.availableServices = {};
  frachtInputs.maxWeightPerService = {};
  frachtInputs.containersToCapacityAlgorithm = [];
  frachtInputs.pallets = [];
  frachtInputs.additionalFactors = [];

  frachtInputs.primaryFactor = frachtConfig.primaryFactor;
  const additionalFactorPattern = {...freightInputStructure.additionalFactors[0]};
  if (isEmpty(frachtConfig.additionalFactors)) {
    frachtInputs.additionalFactors = [];
    frachtInputs.additionalFactors[0] = additionalFactorPattern;
  } else {
    frachtConfig.additionalFactors.forEach((factorsItem, key) => {
      frachtInputs.additionalFactors[key] = createConfigItem(factorsItem, additionalFactorPattern)
    })
  }
  const palletPattern = {...freightInputStructure.pallets[0]};
  if (isEmpty(frachtConfig.pallets)) {
    frachtInputs.pallets = [];
    frachtInputs.pallets[0] = palletPattern;
  } else {
    frachtConfig.pallets.forEach((palletItem, key) => {
      frachtInputs.pallets[key] = createConfigItem(palletItem, palletPattern)
    })
  }
  frachtInputs.maxWeightPerService = {};
  frachtConfig.availableServices.forEach(service => {
    frachtInputs.maxWeightPerService[service.service_code] = frachtConfig.maxWeightPerService.hasOwnProperty(service.service_code) ?
      frachtConfig.maxWeightPerService[service.service_code].max_weight : "";
    const requiredService = frachtConfig.availableServices.find(availableService=>service.service_code === availableService.service_code)
    frachtInputs.availableServices[service.service_code] = requiredService.available;
  });

  frachtInputs.maxCurrentMeterWeight = frachtConfig.maxCurrentMeterWeight;

  //backend can return up to 2 configs for containersToCapacityAlgorithm;
  const missingContainersConfig = 2 - frachtConfig.containersToCapacityAlgorithm.length;

  frachtConfig.containersToCapacityAlgorithm.forEach(capacityConfig=>{
    frachtInputs.containersToCapacityAlgorithm.push(capacityConfig);
  });
  for (let i = 0; i < missingContainersConfig; i++) {
    frachtInputs.containersToCapacityAlgorithm.push({...freightInputStructure.containersToCapacityAlgorithm[i]})
  }

  frachtInputs.minDaysAfterPickupForDelivery = frachtConfig.minDaysAfterPickupForDelivery;
  frachtInputs.maxDaysAfterPickupForDelivery = frachtConfig.maxDaysAfterPickupForDelivery;
  frachtInputs.avoidCountries = {}
  frachtConfig.avoidCountries.forEach(country => frachtInputs.avoidCountries[country.country_code] = country.avoid
  )
  frachtInputs.maxHeightWithoutSurcharge = frachtConfig.maxHeightWithoutSurcharge;
  frachtInputs.laidableOptionLimits = frachtConfig.laidableOptionLimits;
  frachtInputs.liftCargoOptionLimits = frachtConfig.liftCargoOptionLimits;

  return frachtInputs
}

export const getInputErrorClass  = (inputValue) => {
  if (inputValue.length === 0 || isNaN(Number(inputValue)) || Number(inputValue) < 0){
      return "input-error"
    }
    else {
      return ""
    }
}

export const getInputErrorClassInRow  = (rowValue, keyName) => {
  let errorClass = "";
  let isInvalid = false;
  if (rowValue[keyName].length === 0 || isNaN(Number(rowValue[keyName])) || Number(rowValue[keyName]) < 0) {
    isInvalid = true
  }
  let rowSum = 0;
  let rowHasZero = false;
  for (const inputKey in rowValue){
    if (typeof rowValue[inputKey] === 'boolean' ){
      continue
    }
    if (rowValue[inputKey] === '0' || rowValue[inputKey] === 0){
      rowHasZero = true;
    } else if (rowValue[inputKey] !== '') {
      rowSum += parseInt(rowValue[inputKey])
    }
  }
  if (isInvalid){
    errorClass = "input-error ";
  }
  if (rowHasZero && rowSum > 0){
    errorClass+= "row-error";
  }
  return errorClass
}

export const getInputErrorClassInSet = (setValue, keyName) => {
  const cargoKeys = ['capacity','height','length','width'];
  const wheelArchKeys = ['wheel_arch_distance_to_front','wheel_arch_height','wheel_arch_length','wheel_arch_width'];
  const isCargo = cargoKeys.includes(keyName);
  const isWheelArch = wheelArchKeys.includes(keyName);

  let errorClass = "";
  let isInvalid = false;
  if (setValue[keyName].length === 0 || isNaN(Number(setValue[keyName])) || Number(setValue[keyName]) < 0) {
    isInvalid = true
  }

  let cargoRowSum = 0;
  let cargoRowHasZero = false;
  cargoKeys.forEach(inputKey => {
    if (setValue[inputKey] === '0'){
      cargoRowHasZero = true;
    } else {
      cargoRowSum += parseInt(setValue[inputKey])
    }
  })
  let wheelArchRowSum = 0;
  let wheelRowHasZero = false;
  wheelArchKeys.forEach(inputKey=> {
    if (setValue[inputKey] === '0'){
      wheelRowHasZero = true;
    } else {
      wheelArchRowSum += parseInt(setValue[inputKey])
    }
  })

  if (isInvalid){
    errorClass = "input-error ";
  }
  if (isWheelArch && wheelRowHasZero && wheelArchRowSum !== 0){
    errorClass+= "row-error";
  }
  if (isCargo && cargoRowHasZero && cargoRowSum !== 0){
    errorClass+= "row-error";
  }

  if (isCargo && cargoRowSum === 0 && wheelArchRowSum !== 0){
    errorClass+= "row-error";
  }
  return errorClass
}

export const createFrachtConfigObject = (configIntputs) => {

  const convertCountries = (countryObject) => {
    let avoidCountries = [];
    for (let countryCode in countryObject){
      avoidCountries.push({
        country_code:countryCode,
        avoid:countryObject[countryCode]
      })
    }
    return avoidCountries;
  }

  const convertServices = (servicesObject) => {
    let availableServices = [];
    for (let serviceCode in servicesObject){
      availableServices.push({
        service_code:serviceCode,
        available:servicesObject[serviceCode]
      })
    }
    return availableServices;
  }

  const getContainersToCapacityArray = (containersData) => {
    let containersToCapacity = [];
    containersData.forEach(dataSet =>{
      if (isEmptyRow(dataSet.car)){
        return
      }
      let containersElement = {};
      containersElement.car = convertToInt(dataSet.car)
      if (!isEmptyRow(dataSet.truck_trailer)){
        containersElement.truck_trailer = convertToInt(dataSet.truck_trailer)
      }
      containersToCapacity.push(containersElement)
    })
    return containersToCapacity;
  }

  const getMaxWeightPerService = (maxWeightData) => {
    let maxWeightPerService = [];
    for (let serviceCode in  maxWeightData){
      let maxWeightElement = {
        service_code: serviceCode,
        max_weight: parseInt(maxWeightData[serviceCode])
      }
      maxWeightPerService.push(maxWeightElement)
    }
    return maxWeightPerService;
  }

  const isEmptyRow = (rowObject) => {
    let counter = 0;
    for (let rowObjectField in rowObject){
      if (typeof rowObject[rowObjectField] === 'boolean') continue;
        counter += parseFloat(rowObject[rowObjectField]);
    }
    return counter === 0;
  }

  const convertToInt = (inputRow) => {
    for (let field in inputRow){
      if (typeof inputRow[field] === 'boolean') continue;
        inputRow[field] = parseInt(inputRow[field]);
    }
    return inputRow
  }

  const getArrayConfigData = (fieldName) => {
    const initialInputData = configIntputs[fieldName];
    let arrayConfigData = [];
    initialInputData.forEach(inputRow=> {
        let outputRow = {...inputRow}
        if (isEmptyRow(outputRow)){
          return
        }
        arrayConfigData.push(convertToInt({...outputRow}))
    })
    return arrayConfigData
  }
  let frachtConfigObject = {
    maxHeightWithoutSurcharge: parseInt(configIntputs.maxHeightWithoutSurcharge),
    liftCargoOptionLimits: convertToInt(configIntputs.liftCargoOptionLimits),
    laidableOptionLimits:convertToInt(configIntputs.laidableOptionLimits),
    primaryFactor:parseInt(configIntputs.primaryFactor),
    additionalFactors:getArrayConfigData('additionalFactors'),
    maxWeightPerService:getMaxWeightPerService(configIntputs.maxWeightPerService),
    maxCurrentMeterWeight:parseFloat(configIntputs.maxCurrentMeterWeight),
    containersToCapacityAlgorithm:getContainersToCapacityArray(configIntputs.containersToCapacityAlgorithm),
    avoidCountries:convertCountries(configIntputs.avoidCountries),
    availableServices:convertServices(configIntputs.availableServices),
    minDaysAfterPickupForDelivery: parseInt(configIntputs.minDaysAfterPickupForDelivery),
    maxDaysAfterPickupForDelivery: parseInt(configIntputs.maxDaysAfterPickupForDelivery),
    pallets: getArrayConfigData('pallets'),
  };

  return frachtConfigObject
}
