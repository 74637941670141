import React from "react";
import {connect} from "react-redux";
import {Field, FormSection, formValues} from "redux-form";
import {Col, PopoverBody, Row, UncontrolledPopover, Button} from "reactstrap";
import {translate} from "services/translationService";
import CheckIcon from "../../CheckIcon";
import BodyTypeBadge from "../../BodyTypeBadge";
import "./style.scss";
import FieldInput from "../../../../components/FormFields/FieldInput/FieldInput";
import FieldCheckbox from "../../../../components/FormFields/FieldCheckbox/FieldCheckbox";


class SingleSpecification extends React.Component {

    constructor(props) {
        super(props);
        this.renderDetails = this.renderDetails.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.required = this.required.bind(this);
        this.positiveNumber = this.positiveNumber.bind(this);
    }

    required(value) {
        return value ? undefined : translate("Required");
    }

    positiveNumber(value) {
        return parseInt(value) > 0 ? undefined : "min 1";
    }

    renderForm() {
        const selectedSpecification = this.props.selectedSpecification;
        return(
            <div className="specification-container">
            <FormSection name="container">
                <Row>
                    <Col md={12}>
                        <span className="specification-name">
                            <span>{this.props.specification.name}</span>
                            <BodyTypeBadge specification={this.props.specification}/>
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <span className="specification-header">{translate("Dimensions")}</span>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} xs={6}>
                        <span className={"label"}>{translate("L/W/H")}</span>
                        <div className="d-flex flex-row align-items-center flex-fill">
                                <Field
                                    name="length"
                                    type="text"
                                    component={FieldInput}
                                    className="flex-column"
                                    />
                                <Field
                                    name="width"
                                    type="text"
                                    component={FieldInput}
                                    className="flex-column"
                                />
                                <Field
                                    name="height"
                                    type="text"
                                    component={FieldInput}
                                    className="flex-column"
                                />
                                cm
                        </div>
                    </Col>
                    <Col md={2} xs={6}>
                        <span className={"label"}>{translate("Doors")} {translate("(W/H)")}</span>
                        <div className="d-flex flex-row align-items-center flex-fill">
                                <Field
                                    name="doorWidth"
                                    type="text"
                                    component={FieldInput}
                                />
                                <Field
                                    name="doorHeight"
                                    type="text"
                                    component={FieldInput}
                                />
                                cm
                        </div>
                    </Col>
                    <Col md={2} xs={6}>

                        <span className="label" id="wheel_arch" style={{cursor: "help"}}>{translate("Wheel Arch")}</span>
                        <UncontrolledPopover trigger="legacy" placement="top" target="wheel_arch">
                            <PopoverBody>
                                {translate("wheel arch distance to front / wheel arch height / wheel arch length / wheel arch width")}
                            </PopoverBody>
                        </UncontrolledPopover>
                        <div className="d-flex flex-row align-items-center flex-fill">
                            <Field
                                name="wheelArchDistanceToFront"
                                type="text"
                                meta={{initial: selectedSpecification.wheelArchDistanceToFront}}
                                component={FieldInput}
                            />
                            <Field
                                name="wheelArchHeight"
                                type="text"
                                component={FieldInput}
                            />
                            <Field
                                name="wheelArchLength"
                                type="text"
                                component={FieldInput}
                            />
                            <Field
                                name="wheelArchWidth"
                                type="text"
                                component={FieldInput}
                            />
                        </div>
                    </Col>
                    <Col md={2} xs={6}>
                        <span className="label">{translate("Max pressure")}</span>
                        <div className="d-flex flex-row align-items-center flex-fill">
                            <Field
                                name="maxPressure"
                                type="text"
                                component={FieldInput}
                            /> kg/m2
                        </div>
                    </Col>
                    <Col md={1} xs={6}>
                        <span className="label" id="max-weight" style={{cursor: "help"}}>{translate("Max Weight")}</span>
                        <UncontrolledPopover trigger="legacy" placement="top" target="max-weight">
                            <PopoverBody>
                                {translate("Max load weight")}
                            </PopoverBody>
                        </UncontrolledPopover>
                        <Field
                            name="maxWeight"
                            type="text"
                            component={FieldInput}
                        />
                    </Col>
                    <Col md={1} xs={6}>
                        <span className="label" id="real-weight" style={{cursor: "help"}}>{translate("Real Weight")}</span>
                        <UncontrolledPopover trigger="legacy" placement="top" target="real-weight">
                            <PopoverBody>
                                {translate("Real load weight (considering vehicle special equipment")}
                            </PopoverBody>
                        </UncontrolledPopover>
                        <Field
                            name="maxWeightReal"
                            type="text"
                            component={FieldInput}
                            validate={[this.required, this.positiveNumber]}
                        />
                    </Col>
                    <Col md={1} xs={6}>
                        <span className="label" id="tot-weight" style={{cursor: "help"}}>{translate("Tot. Weight")}</span>
                        <UncontrolledPopover trigger="legacy" placement="top" target="tot-weight">
                            <PopoverBody>
                                {translate("Gross vehicle mass")}
                            </PopoverBody>
                        </UncontrolledPopover>
                        <Field
                            name="containerTotalWeight"
                            type="text"
                            component={FieldInput}
                            validate={[this.required, this.positiveNumber]}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                       <span className="specification-header">
                        {translate("Additional Options")}
                       </span>
                    </Col>
                </Row>
                <Row>
                    {/*<Col md={2} xs={6}>*/}
                    {/*    <Field*/}
                    {/*        name="hasLift"*/}
                    {/*        label={translate("Lift")}*/}
                    {/*        component={FieldCheckbox}*/}

                    {/*    />*/}
                    {/*</Col>*/}
                    {/*<Col md={2} xs={6}>*/}
                    {/*    <Field*/}
                    {/*        name="hasCooler"*/}
                    {/*        label={translate("Cooler")}*/}
                    {/*        component={FieldCheckbox}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                    <Col md={3} xs={6}>
                        <Field
                            name="hasForklift"
                            label={translate("Forklift")}
                            component={FieldCheckbox}
                        />
                    </Col>
                    <Col md={3} xs={6}>
                        <Field
                            name="hasPalletTruck"
                            label={translate("Pallet Truck")}
                            component={FieldCheckbox}
                        />
                    </Col>
                    <Col md={3} xs={6}>
                        <Field
                            name="hasAdr"
                            label={translate("ADR")}
                            component={FieldCheckbox}
                        />
                    </Col>
                    {/*<Col md={2} xs={6}>*/}
                    {/*    <Field*/}
                    {/*        name="isEdscha"*/}
                    {/*        label={translate("Edscha")}*/}
                    {/*        component={FieldCheckbox}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                </Row>
            </FormSection>
            </div>
        )
    }

    renderDetails() {
        const {specification, anySelected} = this.props;
        return (
            <div className={`specification-container ${anySelected ? "not-selected" : ""}`} onClick={this.props.clickEvent}>
                <Row>
                    <Col md={12}>
                        <span className="specification-name">
                            <span>{specification.name}</span>
                            <BodyTypeBadge specification={specification}/>
                            <Button size="sm" color="primary">{translate("Select")}</Button>
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <span className="specification-header">{translate("Dimensions")}</span>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} xs={6}>
                        <span className={"label"}>{translate("L/W/H")}</span>
                        {specification.length} / {specification.width} / {specification.height} cm
                    </Col>
                    <Col md={2} xs={6}>
                        <span className={"label"}>{translate("Doors")} {translate("(W/H)")}</span>
                        {specification.doorWidth} / {specification.doorHeight} cm
                    </Col>
                    <Col md={2} xs={6}>
                        <span className="label">{translate("Wheel Arch")}</span>
                        {specification.wheelArchDistanceToFront}/{specification.wheelArchHeight}/{specification.wheelArchLength}/{specification.wheelArchWidth}
                    </Col>
                    <Col md={2} xs={6}>
                        <span className="label">{translate("Max pressure")}</span>
                        {specification.maxPressure} kg/m2
                    </Col>
                    <Col md={1} xs={6}>
                        <span className="label">{translate("Max Weight")}</span>
                        {specification.maxWeight} kg
                    </Col>
                    <Col md={1} xs={6}>
                        <span className="label">{translate("Real Weight")}</span>
                        {specification.maxWeightReal} kg
                    </Col>
                    <Col md={1} xs={6}>
                        <span className="label">{translate("Tot. Weight")}</span>
                        {specification.containerTotalWeight} kg
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                       <span className="specification-header">
                        {translate("Additional Options")}
                       </span>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} xs={6}>
                        {translate("Lift")} <CheckIcon checkPresent={specification.hasLift} />
                    </Col>
                    <Col md={2} xs={6}>
                        {translate("Cooler")} <CheckIcon checkPresent={specification.hasCooler} />
                    </Col>
                    <Col md={2} xs={6}>
                        {translate("Forklift")} <CheckIcon checkPresent={specification.hasForklift} />
                    </Col>
                    <Col md={2} xs={6}>
                        {translate("Pallet Truck")} <CheckIcon checkPresent={specification.hasPalletTruck} />
                    </Col>
                    <Col md={2} xs={6}>
                        {translate("ADR")} <CheckIcon checkPresent={specification.hasAdr} />
                    </Col>
                    <Col md={2} xs={6}>
                        {translate("Edscha")} <CheckIcon checkPresent={specification.isEdscha} />
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        const { selected} = this.props;
        return (
               selected ? this.renderForm() : this.renderDetails()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedSpecification: state.specificationReducer.specification,
    }
}

export default connect(mapStateToProps)(formValues('vehicleType')(SingleSpecification));
