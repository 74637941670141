export const PUSH_CHANGE_PAGE = 'PUSH_CHANGE_PAGE';
export const RESET_PAGINATION = 'RESET_PAGINATION';
export const PUSH_CHANGE_PER_PAGE = 'PUSH_CHANGE_PER_PAGE';

export const paginationActions = {
   pushChangePage,
   resetPagination,
    changePerPage,
};

function pushChangePage(paginator, pageNumber, indexStart) {
    return {type: PUSH_CHANGE_PAGE, paginator, pageNumber, indexStart};
}

function resetPagination(paginator) {
    return {type: RESET_PAGINATION, paginator};
}

function changePerPage(paginator, perPage) {
    return {type: PUSH_CHANGE_PER_PAGE, paginator, perPage};
}
