import React from "react";
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import { connect } from 'react-redux'
import { getUserInfo, updateUserInfo } from 'redux/actions/users/user.actions';
import { translate } from 'services/translationService';
import loggedUserService from 'services/loggedUserService';
import {hasProvinces} from "../../../../helpers/countries";
import {parseUserRoles} from '../helpers.js';
import UserDocuments from "./Documents/UserDocuments";
import UserForm from './UserForm';
import UserDetails from './UserDetails';
import Loader from "components/Loader/Loader";
import "../style.scss";
import userDataService from "../../../../services/userDataService";
import PasswordForm from "./PasswordForm";
import DeleteUser from "./DeleteUser";
import HelperButton from "../../../../components/Helper/HelperButton";


class User extends React.Component {

    constructor(props) {
        super(props);
        this.userPageSubmitTrigger = this.userPageSubmitTrigger.bind(this);
        this.userId = (this.props.match.params.userId ) ? this.props.match.params.userId : loggedUserService.getUserId();
    }

    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(getUserInfo(this.userId));
    }

    userPageSubmitTrigger(formData) {
        parseUserRoles(formData);
        const {dispatch, countries} = this.props;
        const country = formData.address.country;
        if (!hasProvinces(countries, country)){
            delete formData.address.state_province;
        }
        dispatch(updateUserInfo(formData));
    }


    render() {
        const {isFetching, user} = this.props;
        let isDriver = false;
        if (user.hasOwnProperty('roles')){
            isDriver = userDataService.isDriver(user.roles);
            user.roles = user.roles.filter(role => role !== "ROLE_DEFAULT_MANAGER")
        }
        return (
            <div className="content">
                {!(isFetching) && user.address ? (
                <UserDetails user={user}/>
                    ) : (
                    <Loader />
                )}
                <Row>
                    <Col md={12}>
                        <Card className="card-user">
                            <CardHeader>
                                <CardTitle>
                                    <h4><i className="nc-icon nc-badge" /> { translate('User`s data') } </h4>
                                    <hr />
                                </CardTitle>
                            </CardHeader>
                            {!(isFetching) && user ? (
                                <CardBody>
                                    <UserForm
                                        initialValues={user}
                                        onSubmitHandler={this.userPageSubmitTrigger}
                                    />

                                </CardBody>
                            ) : (
                                <CardBody>
                                        <Loader />
                                </CardBody>
                            )
                            }
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PasswordForm userId={this.userId}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DeleteUser />
                    </Col>
                </Row>
                { isDriver &&
                <Row>
                    <Col>
                        <UserDocuments userId={this.userId}/>
                    </Col>
                </Row>
                }
            </div>
        );
    }
}

User.defaultProps = {
    countries:{},
    isFetching: true
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        isFetching: state.userReducer.isFetching,
        error: state.userReducer.error,
        countries: state.countryReducer.countries
    }
}
export default connect(mapStateToProps)(User);
