import {FETCH_USER_FAILURE, FETCH_USER_REQUEST, FETCH_USER_SUCCESS,
    UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE,
    CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE } from "../../actions/users/user.actions";

const initialState = {
    message: '',
    user: {},
    isFetching: false,
    error: false,
};

export function userReducer (state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
            });
        }

        case FETCH_USER_SUCCESS: {
            return Object.assign({}, state, {
                user: action.data,
                isFetching: false,
            });
        }

        case FETCH_USER_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }

        case UPDATE_USER_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
                error: false
            });
        }

        case UPDATE_USER_SUCCESS: {
            return Object.assign({}, state, {
                user: action.data,
                isFetching: false,
            });
        }
        case UPDATE_USER_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }

        case CREATE_USER_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
                error: false
            });
        }

        case CREATE_USER_SUCCESS: {
            return Object.assign({}, state, {
                user: action.data,
                isFetching: false,
            });
        }
        case CREATE_USER_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }
        default:
            return state
    }
}
