import { combineReducers } from 'redux';
//import { routerReducer as routing } from 'react-router-redux';
import { alertReducer } from './alert.reducer';
import { authReducer } from './authentication.reducer';
import { userReducer } from './users/user.reducer';
import { translationReducer } from './translation.reducer';
import { reducer as formReducer } from 'redux-form';
import carrierReducer from './carrier/carrier.reducer';
import carrierDocumentsReducer from './carrier/documents.reducer';
import multipleOrdersReducer from  './orders/multipleOrders.reducer';
import ordersReducer from './orders/orders.reducer';
import orderReducer from './orders/order.reducer';
import countryReducer from './dictionary/country.reducer';
import billingPeriodReducer from './dictionary/billingPeriod.reducer';
import statusesReducer from './shipments/statuses.reducer';
import multipleStatusesReducer from './shipments/multipleStatuses.reducer';
import lifeCyclesReducer from './shipments/lifecycles.reducer';
import palletBalanceReducer from './shipments/palletBalance.reducer';
import usersReducer from './users/users.reducer';
import userDocumentReducer from './users/documents.reducer';
import paginationReducer from './pagination.reducer';
import apiNotificationsReducer from './apiNotifications/apiNotifications.reducer';
import apiPreferencesReducer from './apiNotifications/apiPreferences.reducer';
import configReducer from './config/config.reducer';
import invoicesReducer from './invoices/invoices.reducer';
import specificationReducer from './car/specification.reducer';
import carReducer from './car/car.reducer';
import carSetsReducer from './car/carSets.reducer';
import carSetReducer from './car/carSet.reducer';
import truckTrailersReducer from './car/truckTrailers.reducer';
import soloTrucksReducer from './car/soloTrucks.reducer';
import vehicleReducer from './car/vehicle.reducer';
import carsReducer from './car/cars.reducer';
import routeReducer from './map/route.reducer'
import invoicingDataReducer from './invoicing-data/invoicing-data.reducer'
import inquiryReducer from './inquiries/inquiry.reducer'
import inquiriesReducer from './inquiries/inquiries.reducer'
import inquiryOfferReducer from './inquiries/inquiryOffer.reducer'
import inquiryOfferMessageReducer from './inquiries/inquiryOfferMessage.reducer'
import shipmentRemarkReducer from './shipments/shipmentRemark.reducer'
import remarkCategoryReducer from './dictionary/remarkCategory.reducer'
import cargoOptionsReducer from './surcharges/cargoOptions.reducer'
import customersReducer from './surcharges/customers.reducer'
import pricesByCodesListReducer from './surcharges/pricesByCodesList.reducer'
import pricesByDistanceListReducer from './surcharges/pricesByDistanceList.reducer'
import weightSurchargesReducer from './surcharges/weightSurcharges.reducer'
import heightSurchargesReducer from './surcharges/heightSurcharges.reducer'
import lengthSurchargesReducer from './surcharges/lengthSurcharges.reducer'
import saturdaySurchargesReducer from './surcharges/saturdaySurcharges.reducer'
import sundaySurchargesReducer from './surcharges/sundaySurcharges.reducer'
import fuelSurchargesReducer from './surcharges/fuelSurcharges.reducer'
import tollSurchargesReducer from './surcharges/tollSurcharges.reducer'
import dedicatedPricesReducer from './dedicatedPrices/dedicatedPrices.reducer'
import dedicatedSurchargesReducer from './dedicatedPrices/dedicatedSurcharges.reducer'
import dedicatedOptionsReducer from './dedicatedPrices/dedicatedOptions.reducer'
import invitationsReducer from './invitation/invitations.reducer'
import dedicatedByStopReducer from "./dedicatedPrices/dedicatedByStop.reducer";
import documentTypesReducer from "./documents/documentTypes.reducer";
import orderDocumentsReducer from "./documents/orderDocuments.reducer";
import dedicatedAliasesReducer from './dedicatedPrices/dedicatedAliases.reducer'
import frachtConfigReducer from './frachtConfiguration/frachtConfig.reducer'
import helperTextReducer from './dictionary/helperText.reducer'

const rootReducer = combineReducers({
    alertReducer,
    authReducer,
    userReducer,
    carrierReducer,
    carrierDocumentsReducer,
    translationReducer,
    ordersReducer,
    orderReducer,
    multipleOrdersReducer,
    countryReducer,
    billingPeriodReducer,
    statusesReducer,
    multipleStatusesReducer,
    lifeCyclesReducer,
    form: formReducer,
    usersReducer,
    userDocumentReducer,
    paginationReducer,
    // notificationsReducer,
    apiNotificationsReducer,
    apiPreferencesReducer,
    configReducer,
    invoicesReducer,
    invoicingDataReducer,
    specificationReducer,
    carReducer,
    carSetsReducer,
    truckTrailersReducer,
    soloTrucksReducer,
    vehicleReducer,
    carSetReducer,
    palletBalanceReducer,
    carsReducer,
    routeReducer,
    inquiryReducer,
    inquiriesReducer,
    inquiryOfferReducer,
    inquiryOfferMessageReducer,
    shipmentRemarkReducer,
    remarkCategoryReducer,
    cargoOptionsReducer,
    customersReducer,
    pricesByCodesListReducer,
    pricesByDistanceListReducer,
    weightSurchargesReducer,
    heightSurchargesReducer,
    lengthSurchargesReducer,
    saturdaySurchargesReducer,
    sundaySurchargesReducer,
    fuelSurchargesReducer,
    tollSurchargesReducer,
    dedicatedPricesReducer,
    dedicatedSurchargesReducer,
    dedicatedOptionsReducer,
    invitationsReducer,
    dedicatedByStopReducer,
    documentTypesReducer,
    orderDocumentsReducer,
    dedicatedAliasesReducer,
    frachtConfigReducer,
    helperTextReducer
});

export default rootReducer;
