import {FETCH_TRANSLATION, FETCH_TRANSLATION_SUCCESS, FETCH_TRANSLATION_FAILURE} from "../actions/translation.actions";

const initialState = {
    locale: localStorage.getItem('locale') ? localStorage.getItem('locale') : 'en',
  //  translations: {}
}


export function translationReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_TRANSLATION:
            return {
                isFetching: true,
            };
        case FETCH_TRANSLATION_SUCCESS:
            localStorage.setItem('locale', action.data.locale);
            return {
                translations: action.data.translations,
                locale: action.data.locale,
                isFetching: false
            };
        case FETCH_TRANSLATION_FAILURE:
            return {};
        default:
            return state
    }
}

