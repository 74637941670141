import { apiService } from "services/apiService";
import { alertActions } from "redux/actions";
import getErrorText from "services/errorTextService";
import {history} from "../../../helpers";
import {translate} from "../../../services/translationService";

export const FETCH_INVOICING_DATA_REQUEST = 'FETCH_INVOICING_DATA_REQUEST';
export const FETCH_INVOICING_DATA_SUCCESS = 'FETCH_INVOICING_DATA_SUCCESS';
export const FETCH_INVOICING_DATA_FAILURE = 'FETCH_INVOICING_DATA_FAILURE';

export const CREATE_INVOICING_DATA_REQUEST = 'CREATE_INVOICING_DATA_REQUEST';
export const CREATE_INVOICING_DATA_SUCCESS = 'CREATE_INVOICING_DATA_SUCCESS';
export const CREATE_INVOICING_DATA_FAILURE = 'CREATE_INVOICING_DATA_FAILURE';

export const UPDATE_INVOICING_DATA_REQUEST = 'UPDATE_INVOICING_DATA_REQUEST';
export const UPDATE_INVOICING_DATA_SUCCESS = 'UPDATE_INVOICING_DATA_SUCCESS';
export const UPDATE_INVOICING_DATA_FAILURE = 'UPDATE_INVOICING_DATA_FAILURE';

export const DELETE_INVOICING_DATA_REQUEST = 'DELETE_INVOICING_DATA_REQUEST';
export const DELETE_INVOICING_DATA_SUCCESS = 'DELETE_INVOICING_DATA_SUCCESS';
export const DELETE_INVOICING_DATA_FAILURE = 'DELETE_INVOICING_DATA_FAILURE';

export function getInvoicingData() {
    return dispatch => {
        dispatch(request({}));
        apiService.get('/api/carriers/invoicing_data')
            .then(
                response => {
                    dispatch(success(response));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_INVOICING_DATA_REQUEST, data } }
    function success(data) { return { type: FETCH_INVOICING_DATA_SUCCESS, data } }
    function failure(error) { return { type: FETCH_INVOICING_DATA_FAILURE, error } }
}


export function addInvoicingData(data) {
    return dispatch => {
        dispatch(request({}));
        apiService.post('/api/carriers/invoicing_data',data)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(translate('Invoicing data successfully added')));
                    history.push('/invoice-data');
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)==="" ?
                    "Invoicing data were not added please try again" : getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: CREATE_INVOICING_DATA_REQUEST, data } }
    function success(data) { return { type: CREATE_INVOICING_DATA_SUCCESS, data } }
    function failure(error) { return { type: CREATE_INVOICING_DATA_FAILURE, error } }
}

export function updateInvoicingData(data) {
    return dispatch => {
        dispatch(request({}));
        apiService.put('/api/carriers/invoicing_data', data)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(translate('Invoicing data successfully updated')));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)==="" ?
                    "Invoicing data were not changed please try again" : getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: UPDATE_INVOICING_DATA_REQUEST, data } }
    function success(data) { return { type: UPDATE_INVOICING_DATA_SUCCESS, data } }
    function failure(error) { return { type: UPDATE_INVOICING_DATA_FAILURE, error } }
}

export function deleteInvoicingData(id) {
    return dispatch => {
        dispatch(request({}));
        apiService.apiDelete('/api/carriers/invoicing_data/' + id)
            .then(
                response => {
                    dispatch(success(id));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: DELETE_INVOICING_DATA_REQUEST, data } }
    function success(data) { return { type: DELETE_INVOICING_DATA_SUCCESS, data } }
    function failure(error) { return { type: DELETE_INVOICING_DATA_FAILURE, error } }
}