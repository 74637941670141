import React from "react";
import {connect} from 'react-redux';
import _ from "lodash";
import {change, Field, FormSection, formValueSelector, reduxForm} from 'redux-form';
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import FieldSelect from "components/FormFields/FieldSelect/FieldSelect";
import Button from "components/CustomButton/CustomButton.jsx";
import {translate} from 'services/translationService';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {getStatuses, postLifecycle} from "redux/actions";
import PalletWarning from "./PalletWarning";
import CashWarning from "./CashWarning";
import ModalFooter from "reactstrap/es/ModalFooter";
import MediaQuery from "react-responsive";
import './OrderStatusForm.scss';
import AddressFormSection from "./AddressFormSection";
import {LocationValidation} from "./LocationValdation";
import RejectWarning from "./RejectWarning";
import {combineDateAndTime, isoDate, time} from "../../../utils/formatters";

class OrderStatusForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showWarehouses: false,
            showPalletDeliveryReminder: false,
            showPalletPickupReminder: false,
            showRejectReminder: false,
            showCashReminder:false,
            showAddressForm:false,
            allowManualDate:false
        };
        this.order = this.props.order;
        this.warehouseStatusesList = [];
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.prepareWarehouseStatusesList = this.prepareWarehouseStatusesList.bind(this);
        this.getDefaultWarehouseStatusId = this.getDefaultWarehouseStatusId.bind(this);
        this.onChangeShipmentSelect = this.onChangeShipmentSelect.bind(this);
        this.onChangeWarehouseSelect = this.onChangeWarehouseSelect.bind(this);
        this.isFormReady = this.isFormReady.bind(this);
    }

    componentDidMount() {
        const orderId = this.props.order.id;
        this.props.dispatch(getStatuses(orderId));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isFetching === true && this.props.isFetching === false){
            this.prepareWarehouseStatusesList();
            const selectedShipmentId = this.props.shipmentStatusIdValue;
            const shipmentStatuses = Object.values(this.props.shipmentStatuses);
            const selectedShipmentStatus = shipmentStatuses.filter(shipmentStatus=>shipmentStatus.id === parseInt(selectedShipmentId));
            this.setState({
                showAddressForm: this.warehouseStatusesList > 0 ? this.warehouseStatusesList[0].isCustomAddress : false,
                allowManualDate:selectedShipmentStatus.length > 0 ? selectedShipmentStatus[0].allowManualDate : false
            })
        }
    }

    onChangeShipmentSelect(event, newValue) {
        const {codAmount} = this.props.order.shipment;
        const shipmentStatuses = Object.values(this.props.shipmentStatuses);
        const selectedShipmentStatus = shipmentStatuses.filter(shipmentStatus=>shipmentStatus.id === parseInt(newValue));
        const newAllowManualDate = (selectedShipmentStatus[0].allowManualDate);
        const currentAllowManualDate = this.state.allowManualDate;
        if (newAllowManualDate !== currentAllowManualDate){
            this.props.dispatch(change('orderStatusForm', 'creationDate',isoDate(new Date())));
            this.props.dispatch(change('orderStatusForm', 'creationTime', time(new Date())))
        }
        
        this.setState({
            showWarehouses: selectedShipmentStatus.length > 0 ? selectedShipmentStatus[0].warehouseDependent : false,
            showPalletDeliveryReminder: selectedShipmentStatus[0].showPalletDeliveryReminder,
            showPalletPickupReminder: selectedShipmentStatus[0].showPalletPickupReminder,
            showCashReminder:selectedShipmentStatus[0].showCashReminder && codAmount > 0,
            showRejectReminder: (selectedShipmentStatus[0].showRejectReminder && this.order.hasOwnProperty('relatedOrders')),
            allowManualDate:newAllowManualDate
        })
    }

    onChangeWarehouseSelect(event, newValue) {
        const selectedWarehouseStatus = this.warehouseStatusesList.filter(warehouseStatus=>warehouseStatus.id === parseInt(newValue));
            this.setState({
                showAddressForm: selectedWarehouseStatus.length > 0 ? selectedWarehouseStatus[0].isCustomAddress : false,
            })
    }

    onFormSubmit(formData) {
        formData.shipmentStatusId = formData.shipmentStatusId ? formData.shipmentStatusId : "1";
        formData.additionalStatusId = formData.additionalStatusId ? formData.additionalStatusId : this.getDefaultWarehouseStatusId();
        if (!this.state.showWarehouses){
            delete formData.additionalStatusId;
            delete formData.address;
        } else if (!this.state.showAddressForm){
            delete formData.address;
        }
        const {dispatch} = this.props;
        let postData = {...formData}
        postData.dateAdd = combineDateAndTime(formData.creationDate, formData.creationTime);
        delete postData.creationDate;
        delete postData.creationTime;
        dispatch(postLifecycle(this.order, postData));
        this.props.toggleLifecycleForm();
    }

    toggle() {
        this.props.toggleLifecycleForm();
    }

    prepareStatusesList() {
        let statuses = Object.values(this.props.shipmentStatuses);
        let newStat = statuses.reduce((obj, status) => {
            obj.push({
                id: status.id,
                code: eval(status.carrierCode),
                name: `${status.carrierCode ? status.carrierCode : " "}. ${translate(status.text)}`,
                color: status.color,
                warehouseDependent:status.warehouseDependent
            });
            return obj;
        }, []);
        const sortedObjs = _.sortBy(newStat, 'code');
        return sortedObjs;
    }

    prepareWarehouseStatusesList(){
        let warehouseStatusesList = [];
        let statuses = Object.values(this.props.shipmentStatuses);
        const warehouseStatuses = statuses.find(warehouseStatus=>warehouseStatus.warehouseDependent).warehouseStatuses;
        warehouseStatuses.forEach(warehouseStatus=>warehouseStatusesList.push({
            id:warehouseStatus.id,
            name:warehouseStatus.text,
            isCustomAddress:warehouseStatus.isCustomAddress
        }));
        this.warehouseStatusesList = warehouseStatusesList;
    }

    getDefaultWarehouseStatusId(){
        return (this.warehouseStatusesList.length > 0) ?  this.warehouseStatusesList[0].id.toString() : ""
    }

    isFormReady(){
        const {showAddressForm, showWarehouses} = this.state;
        const {addressValue} = this.props;
        if (!(showAddressForm && showWarehouses)) {
            return true
        } else if (addressValue) {
            return  (addressValue.hasOwnProperty('lat') && addressValue.hasOwnProperty('lng'))
        } else {
            return false
        }
    }

    render() {
        const {isFetching, error, order, addressValue} = this.props;
        const {showWarehouses, showPalletDeliveryReminder, showPalletPickupReminder,
            showCashReminder, showAddressForm, showRejectReminder, allowManualDate} = this.state;
        if (isFetching || error){
            return null
        }
        const showPalletWarning = showPalletDeliveryReminder || showPalletPickupReminder;
        const modalSize = (showWarehouses && showAddressForm) ? 'xl' : 'lg';
        return <Modal isOpen={this.props.showModal} toggle={this.toggle} size={modalSize}>
                <form>
                <ModalHeader toggle={this.toggle}>{translate("Add a shipment status")}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                        {showPalletWarning && <PalletWarning showPalletDeliveryReminder={showPalletDeliveryReminder} /> }
                        {showCashReminder && <CashWarning shipment = {order.shipment} /> }
                        {showRejectReminder && <RejectWarning relatedOrders = {order.relatedOrders} /> }
                        <Field
                            name="shipmentStatusId"
                            options={this.prepareStatusesList()}
                            onChange={this.onChangeShipmentSelect}
                            label={translate("Shipment Status")}
                            component={FieldSelect}
                            props={{emptyValue: false}}
                        />
                        {showWarehouses && <Field
                            name="additionalStatusId"
                            options={this.warehouseStatusesList}
                            onChange={this.onChangeWarehouseSelect}
                            label={translate("Warehouse")}
                            component={FieldSelect}
                            props={{emptyValue: false}}
                        />}
                            {(showWarehouses && showAddressForm) &&  <MediaQuery query='(max-device-width: 859px)'>
                            <FormSection name="address">
                                <AddressFormSection
                                    addressValue={addressValue}
                                    targetFormName={'orderStatusForm'}
                                />
                            </FormSection>
                        </MediaQuery>}
                        <Field
                            inputId="signedBy"
                            name="signedBy"
                            type="text"
                            label={translate("Signed by")}
                            component={FieldInput}
                            placeholder=""
                        />
                        <Field
                            inputId="comment"
                            name="comment"
                            type="text"
                            label={translate("Comment")}
                            component={FieldInput}
                            placeholder=""
                        />
                            <div className={"date-time-row"}>
                                <Field
                                  inputId="creationDate"
                                  name="creationDate"
                                  type="date"
                                  label={translate("Date")}
                                  component={FieldInput}
                                  disabled={!allowManualDate}
                                />
                                <Field
                                  inputId="creationTime"
                                  name="creationTime"
                                  type="time"
                                  label={translate("Time")}
                                  component={FieldInput}
                                  disabled={!allowManualDate}
                                />
                            </div>
                        </Col>
                            {(showWarehouses && showAddressForm) &&  <MediaQuery query='(min-device-width: 860px)'>
                            <Col>
                                <FormSection name="address">
                                    <AddressFormSection
                                        addressValue={addressValue}
                                        targetFormName={'orderStatusForm'}
                                    />
                                </FormSection>
                            </Col>
                            </MediaQuery>}
                    </Row>
                </ModalBody>
                <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}> {translate("Cancel")}</Button>
                        <Button color="primary" disabled={!this.isFormReady()} onClick={this.props.handleSubmit(this.onFormSubmit)}>
                            {translate("Add status")}
                        </Button>{' '}
                </ModalFooter>
                </form>
            </Modal>
    }
}

OrderStatusForm.defaultProps = {
    shipmentStatuses: {},
    isFetching: null,
    error: false,
};

function mapStateToProps(state) {
    const shipmentStatusIdValue = selector(state, 'shipmentStatusId');
    const additionalStatusIdValue = selector(state, 'additionalStatusId');
    const addressValue = selector(state, 'address');
    return {
        isFetching: state.statusesReducer.isFetching,
        error: state.statusesReducer.error,
        shipmentStatuses: state.statusesReducer.statuses,
        initialValues: {
            shipmentStatusId:'30',
            additionalStatusId:"",
            address:{
                country:"DE"
            },
            creationDate:isoDate(new Date()),
            creationTime:time(new Date()),
        },
        shipmentStatusIdValue,
        additionalStatusIdValue,
        addressValue
    };
}

const connectedReduxForm = reduxForm({
    form: 'orderStatusForm',
    validate: LocationValidation,
    enableReinitialize : true
})(OrderStatusForm);

const selector = formValueSelector('orderStatusForm');

export default connect(mapStateToProps)(connectedReduxForm)