import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {documentsApiService} from "../../../services/documentsApiService";
import {arrayToQueryParam} from "../../../utils/formatters";

export const DOCUMENTS_TYPES_REQUEST = 'DOCUMENTS_TYPES_REQUEST';
export const DOCUMENTS_TYPES_SUCCESS = 'DOCUMENTS_TYPES_SUCCESS';
export const DOCUMENTS_TYPES_FAILURE = 'DOCUMENTS_TYPES_FAILURE';

export const requiredDocumentTypes = [2,3,4]

export function getDocumentTypes() {
    return dispatch => {
        dispatch(request({}));
        const queryParam = arrayToQueryParam(requiredDocumentTypes, 'id');
        documentsApiService.get('api/documents_types' + queryParam)
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: DOCUMENTS_TYPES_REQUEST, data } }
    function success(data) { return { type: DOCUMENTS_TYPES_SUCCESS, data } }
    function failure(error) { return { type: DOCUMENTS_TYPES_FAILURE, error } }
}
