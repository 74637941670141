import React, {Component} from 'react';
import {connect} from 'react-redux';
import './MultipleOrders.scss';
import queryString from "query-string";
import {history} from 'helpers';
import Loader from "../../components/Loader/Loader";
import {addMultipleStatuses, getMultipleOrders} from "../../redux/actions";
import {translate} from "../../services/translationService";
import {change, Field, Form, FormSection, formValueSelector, reduxForm, updateSyncErrors} from "redux-form";
import FieldSelect from "../../components/FormFields/FieldSelect/FieldSelect";
import {Button, Col, Input, Row} from "reactstrap";
import FieldInput from "../../components/FormFields/FieldInput/FieldInput";
import _ from "lodash";
import {isAddressOk, multipleOrdersValidation} from "./validation";
import MultipleAddRemarkModal from "./MultipleAddRemarkModal";
import loggedUserService from "../../services/loggedUserService";
import MultipleAddressModal from "./MultipleAddressModal";
import MediaQuery from "react-responsive";
import {combineDateAndTime, isoDate, time} from "../../utils/formatters";
import HelperButton from "../../components/Helper/HelperButton";

class MultipleOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            packagesToggler: {},
            remarksData:{},
            selectedOrderId:null,
            showWarehouses: false,
            showAddressModal:false,
            allowManualDate:false
        };
        this.dispatch = this.props.dispatch;
        this.warehouseStatusesList = [];
        this.stateOptions = [];
        this.toggleBoxes = this.toggleBoxes.bind(this);
        this.prepareStatusesList = this.prepareStatusesList.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.showRemarkModal = this.showRemarkModal.bind(this);
        this.clearRemarkModal = this.clearRemarkModal.bind(this);
        this.setRemarkData = this.setRemarkData.bind(this);
        this.toggleBox = this.toggleBox.bind(this);
        this.setRemarkFiles = this.setRemarkFiles.bind(this);
        this.onChangeWarehouseSelect = this.onChangeWarehouseSelect.bind(this);
        this.onChangeShipmentSelect = this.onChangeShipmentSelect.bind(this);
        this.getDefaultWarehouseStatusId = this.getDefaultWarehouseStatusId.bind(this);
        this.clearAddressModal = this.clearAddressModal.bind(this);
        this.isCustomAddressSelected = this.isCustomAddressSelected.bind(this);
    }

    componentDidMount() {
        const searchQuery = this.props.location.search;
        const searchParams = queryString.parse(this.props.location.search);
        if (searchQuery === "" || searchParams["ordersIds[]"] === undefined) {
            history.push('/');
        }
        this.dispatch(getMultipleOrders(searchQuery));
        const statusId = searchParams["statusId"];
        const {dispatch} = this.props;
        if (statusId){
            dispatch(change('multipleStatusForm', 'shipmentStatusId', statusId));
        } else {
            dispatch(change('multipleStatusForm', 'shipmentStatusId', "30"));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevIsFetching = prevProps.isFetching;
        const {multipleOrders, isFetching, shipmentStatuses, shipmentStatusId} = this.props;
        if (prevIsFetching === true && isFetching === false && multipleOrders.length > 0){
            let ordersObject = {
            }
            multipleOrders.forEach(order=>{
                ordersObject[order.orderId] = {
                    orderName:order.number,
                    shipmentId:order.shipmentId,
                    hasRemarks:false,
                    shipment_remark_category_id:"1",
                    description:null,
                    packages:[],
                    files:[null, null, null, null, null]
                }
            })
            this.setState({remarksData:ordersObject});
            let statuses = shipmentStatuses;
            let warehouseStatusesList = [];
            const warehouseStatuses = statuses.find(warehouseStatus=>warehouseStatus.warehouseDependent).warehouseStatuses;
            warehouseStatuses.forEach(warehouseStatus=>warehouseStatusesList.push({
                id:warehouseStatus.id,
                name:warehouseStatus.text,
                isCustomAddress:warehouseStatus.isCustomAddress
            }));
            this.warehouseStatusesList = warehouseStatusesList;
            this.stateOptions = this.prepareStatusesList();
            const selectedShipmentStatus = statuses.find(status=>status.id === parseInt(shipmentStatusId));
            this.setState({allowManualDate:selectedShipmentStatus.allowManualDate})
        }
    }

    prepareStatusesList() {
        let statuses = this.props.shipmentStatuses;
        let newStat = statuses.reduce((obj, status) => {
            obj.push({
                id: status.id,
                code: eval(status.carrierCode),
                name: `${status.carrierCode ? status.carrierCode : " "}. ${translate(status.text)}`,
            });
            return obj;
        }, []);
        const sortedObjs = _.sortBy(newStat, 'code');
        return sortedObjs;
    }

    getDefaultWarehouseStatusId(){
        return (this.warehouseStatusesList.length > 0) ?  this.warehouseStatusesList[0].id.toString() : ""
    }

    toggleBoxes(shipmentNumber) {
        let currentBoxes = this.state.packagesToggler;
        if(shipmentNumber in currentBoxes){
            currentBoxes[shipmentNumber] = !currentBoxes[shipmentNumber];
        } else {
            currentBoxes[shipmentNumber] = true;
        }
        this.setState({packagesToggler:currentBoxes})
    }

    toggleBox(orderId, packageId){
        const {remarksData} = this.state;
        let packagesData =  remarksData[orderId].packages;
        if(packagesData.includes(packageId)) {
            packagesData.splice(packagesData.indexOf(packageId),1);
        } else {
            packagesData.push(packageId)
        }
        this.setState({remarksData:{
                ...this.state.remarksData,
                [orderId]:{
                    ...this.state.remarksData[orderId],
                    packages:packagesData
                }
            }});
    }

    showRemarkModal(orderId) {
        this.setState({selectedOrderId:orderId})
    }

    clearRemarkModal() {
        this.setState({selectedOrderId:null})
    }

    clearAddressModal() {
        this.setState({showAddressModal:false})
    }

    setRemarkData(formData,orderId) {
        this.setState({remarksData:{
            ...this.state.remarksData,
                [orderId]:{
                    ...this.state.remarksData[orderId],
                    shipment_remark_category_id:formData.shipment_remark_category_id,
                    description:formData.description,
                    hasRemarks:true,
                }
            }});
        this.setState({selectedOrderId:null});
    }

    setRemarkFiles(files, orderId) {
        this.setState({remarksData:{
                ...this.state.remarksData,
                [orderId]:{
                    ...this.state.remarksData[orderId],
                    files,
                }
            }});
    }

    isCustomAddressSelected(id) {
        if (id === 'undefined') return false;
        const selectedWarehouseStatus = this.warehouseStatusesList.filter(warehouseStatus=>warehouseStatus.id === parseInt(id));
        if (selectedWarehouseStatus.length > 0){
            return selectedWarehouseStatus[0].isCustomAddress
        }
        return false
    }

    onChangeWarehouseSelect(e, newValue) {
        this.setState({
            showAddressModal: this.isCustomAddressSelected(newValue)
        })
    }

    onChangeShipmentSelect(e, newValue) {
        const selectedShipmentStatus = this.props.shipmentStatuses.filter(shipmentStatus=>shipmentStatus.id === parseInt(newValue));
        const newAllowManualDate = selectedShipmentStatus.length > 0 ? selectedShipmentStatus[0].allowManualDate : false;
        const currentAllowManualDate = this.state.allowManualDate;
        if (newAllowManualDate !== currentAllowManualDate){
            this.props.dispatch(change('multipleStatusForm', 'creationDate',isoDate(new Date())));
            this.props.dispatch(change('multipleStatusForm', 'creationTime', time(new Date())))
        }
        this.setState({
            showWarehouses: selectedShipmentStatus.length > 0 ? selectedShipmentStatus[0].warehouseDependent : false,
            allowManualDate:newAllowManualDate,
        })
    }

    submitHandler(formData) {
        const {dispatch, multipleOrders, additionalStatusIdValue} = this.props;
        const submitData = {...formData}
        const searchParams = queryString.parse(this.props.location.search);
        const confirmationCode  = searchParams.code ?  searchParams.code : null;
        let confirmationCodeData = null;
        if (confirmationCode){
            confirmationCodeData = {
                code:confirmationCode,
                id_carrier:loggedUserService.getCarrierId(),
                driver_name: loggedUserService.getFulName(),
                carrier_name:loggedUserService.getCarrierName(),
                id_driver: loggedUserService.getUserId(),
                confirmer_name: formData.signedBy,
                shipments: multipleOrders.map(shipment=>{return {
                    id_shipment:shipment.shipmentId,
                    shipment_number:shipment.number,
                    id_order:shipment.orderId
                }})
            }
        }

        submitData.shipmentStatusId = parseInt(formData.shipmentStatusId);
        submitData.additionalStatusId = formData.additionalStatusId ? formData.additionalStatusId : this.getDefaultWarehouseStatusId();
        if (!this.state.showWarehouses) {
            delete submitData['additionalStatusId'];
        }
        if(this.isCustomAddressSelected(additionalStatusIdValue)){
            if (!isAddressOk(formData.address)){
                let errorObject = {
                    additionalStatusId: translate("Check address"),
                }
                this.setState({showAddressModal:true});
                dispatch(updateSyncErrors('multipleStatusForm', errorObject))
                return;
            }
        } else {
            delete submitData['address'];
        }
        submitData.shipmentIds = [];
        multipleOrders.forEach(order=>submitData.shipmentIds.push(order.shipmentId));
        if (confirmationCode) {
            submitData.code = parseInt(confirmationCode);
            submitData.operationType = searchParams.operationType ?  parseInt(searchParams.operationType) : null;
        }
        submitData.dateAdd = combineDateAndTime(formData.creationDate, formData.creationTime);
        dispatch(addMultipleStatuses(submitData, this.state.remarksData, confirmationCodeData));
    }

    renderTextInput ({ input, meta: {}, ...custom }) {
        return <Input  {...input} {...custom} />
    }

    render() {
        if (this.props.isFetching || Object.keys(this.state.remarksData).length === 0 ) {
            return (
                <div className="content">
                    <Loader/>
                </div>
            )
        }
        const orderData = this.props.multipleOrders;
        const {isAddingStatuses, additionalStatusIdValue, addressValue} = this.props;
        const {packagesToggler, remarksData, selectedOrderId, showWarehouses, allowManualDate} = this.state;
        return <div className="content">
            <Form onSubmit={this.props.handleSubmit(this.submitHandler)}>
        <div className={"multiple-order-form"} style={{position:"relative"}}>
                <Row>
                    <Col md={4}>
                        <Field
                            name="shipmentStatusId"
                            options={this.stateOptions}
                            onChange={this.onChangeShipmentSelect}
                            label={translate("Shipment Status")}
                            component={FieldSelect}
                            props={{emptyValue: false}}
                        />
                        {showWarehouses &&<>
                            <Field
                              name="additionalStatusId"
                              options={this.warehouseStatusesList}
                              onChange={this.onChangeWarehouseSelect}
                              label={translate("Warehouse")}
                              component={FieldSelect}
                              props={{emptyValue: false}}
                            />
                            <MediaQuery query='(max-device-width: 859px)'>
                                <div className="address-button">
                                    {this.isCustomAddressSelected(additionalStatusIdValue) && <Button
                                      onClick={()=>{this.setState({showAddressModal:true})}}
                                      color="info" disabled={isAddingStatuses} size="sm" type="button">
                                        {translate("Show address")}
                                    </Button>}
                                </div>
                            </MediaQuery>
                        </>}
                    </Col>
                    <Col md={3}>
                        <Field
                            inputId="signedBy"
                            name="signedBy"
                            type="text"
                            label={translate("Signed by")}
                            component={FieldInput}
                            placeholder=""
                        />
                        <MediaQuery query='(min-device-width: 860px)'>
                            <div className="address-button">
                                {this.isCustomAddressSelected(additionalStatusIdValue) && <Button
                                  onClick={()=>{this.setState({showAddressModal:true})}}
                                  color="link" disabled={isAddingStatuses} size="sm" type="button">
                                    {translate("Show address")}
                                </Button>}
                            </div>
                        </MediaQuery>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} className="creation-date">
                        <Field
                          inputId="creationDate"
                          name="creationDate"
                          type="date"
                          label={translate("Date")}
                          component={FieldInput}
                          disabled={!allowManualDate}
                        />
                    </Col>
                    <Col md={3} className="creation-time">
                        <Field
                          inputId="creationTime"
                          name="creationTime"
                          type="time"
                          label={translate("Time")}
                          component={FieldInput}
                          disabled={!allowManualDate}
                        />
                    </Col>
                </Row>
            <HelperButton helperId="carrier-multiple-order-status" />
        </div>
        <Row>
            <Col md={7} className={"pr-md-0"}>
                <div className="multiple-order-list">
                    {orderData.map((singleOrder, i) => <div className="single-order" key={i}>
                        <div className="basic-order">
                            <div className="header-number">{translate("Shipment number")}</div>
                            <div className="header-desc">{translate("Shipment content")}</div>
                            <div className="boxes-header">{translate("Boxes no.")}</div>
                            <div className="boxes-no">{singleOrder.packages.length}</div>
                            <div className="boxes-toggle">
                                <i className={singleOrder.number in packagesToggler && packagesToggler[singleOrder.number] === true ? "nc-icon nc-minimal-down":"nc-icon nc-minimal-right"}
                                   onClick={()=>this.toggleBoxes(singleOrder.number)}/>
                            </div>
                            <div className="order-number">{singleOrder.number}</div>
                            <div className="order-description">{singleOrder.description}</div>                            <div className="order-number">{singleOrder.number}</div>
                            <div className="header-comment">{translate("Add comment")}</div>
                            <div className="comment-content">
                                <Field
                                    name={"comments[_" + singleOrder.shipmentId.toString() +"]"}
                                    type="text"
                                    component={this.renderTextInput}
                                />
                            </div>
                        </div>
                        {singleOrder.number in packagesToggler && packagesToggler[singleOrder.number] === true && <>
                            <div className="order-boxes header-boxes">
                                <div />
                                <div>{translate("Package number")}</div>
                                <div>{translate("Height")}</div>
                                <div>{translate("Width")}</div>
                                <div>{translate("Length")}</div>
                                <div>{translate("Weight")}</div>
                            </div>
                            {singleOrder.packages.map((box, i) => <div
                                        className={remarksData[singleOrder.orderId].packages.includes(box.id) ? "order-boxes  selected" : "order-boxes"}
                                        key={i}>
                                        <div><span className={remarksData[singleOrder.orderId].packages.includes(box.id) ? "checked" : "unchecked"}/></div>
                                        <div>{box.number}</div>
                                        <div>{box.height}</div>
                                        <div>{box.width}</div>
                                        <div>{box.length}</div>
                                        <div>{box.weight}</div>
                                    </div>
                            )}</>}
                        <div className="comment-button">
                            <Button color= {!remarksData[singleOrder.orderId].hasRemarks ? "secondary": "warning"}
                            onClick={()=>this.showRemarkModal(singleOrder.orderId)}>
                                {!remarksData[singleOrder.orderId].hasRemarks ? translate("Add remark"): translate("Edit remark")}
                            </Button>
                        </div>
                    </div>)}
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={7} className={"button-col"}>
                <Button color="primary" disabled={isAddingStatuses}>
                    {isAddingStatuses ? <Loader /> : translate("Add status")}
                </Button>
            </Col>
        </Row>

            <FormSection name="address">
                <MultipleAddressModal
                  isOpen={this.state.showAddressModal}
                  clearModal={this.clearAddressModal}
                  addressValue={addressValue}
                />
            </FormSection>

            </Form>
            <MultipleAddRemarkModal
                orderId={selectedOrderId}
                clearModal={this.clearRemarkModal}
                remarksData={this.state.remarksData}
                setRemarkData={this.setRemarkData}
                initialValues={!!selectedOrderId ? this.state.remarksData[selectedOrderId] : {}}
                packagesData={orderData}
                remarkCategories={this.props.remarkCategories}
                setRemarkFiles={this.setRemarkFiles}
                toggleBox={this.toggleBox}
            />
        </div>
    }
}

MultipleOrders.defaultProps = {
    multipleOrders: [],
    isFetching: true,
    isAddingStatuses: false,
    error: false
};

function mapStateToProps(state) {
    const selector = formValueSelector('multipleStatusForm');
    return {
        multipleOrders: state.multipleOrdersReducer.multipleOrders,
        isFetching: state.multipleOrdersReducer.isFetching,
        error: state.multipleOrdersReducer.error,
        remarkCategories: state.remarkCategoryReducer.remarkCategories,
        shipmentStatuses: state.multipleOrdersReducer.possibleStatuses,
        isAddingStatuses: state.multipleStatusesReducer.isAdding,
        shipmentStatusId:selector(state, 'shipmentStatusId'),
        additionalStatusIdValue:selector(state, 'additionalStatusId'),
        addressValue:selector(state, 'address')
    };
}

const decoratedComponent = connect(mapStateToProps)(MultipleOrders);

export default reduxForm({
    form: 'multipleStatusForm',
    validate: multipleOrdersValidation,
    initialValues: {
        address: {
            country: "DE"
        },
    creationDate:isoDate(new Date()),
    creationTime:time(new Date())
    }
})(decoratedComponent);