import {formValueSelector} from "redux-form";
import React from "react";
import {translate} from "services/translationService";
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Loader from "components/Loader/Loader";
import {priceApiService} from "../../../services/priceApiService";
import {fullDate} from "../../../utils/formatters";
import {alertActions} from "../../../redux/actions";
import {connect} from "react-redux";
import {apiFileService} from "../../../services/apiFileService";
import {getPricesByDistanceList} from "../../../redux/actions/surcharges/pricesByDistanceList.actions";
import MediaQuery from "react-responsive";
import SelectFile from "../../../components/FormFields/SelectFile/SelectFile";

class DistanceImportForm extends React.Component {

    constructor(props) {
        super(props);
        this.downloadFile = this.downloadFile.bind(this);
        this.uploadFileByDistance = this.uploadFileByDistance.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            downloadingFile: false,
            uploadingFile: false,
            showModal:false,
            downloadingList:false
        }
    }

    componentDidMount() {
        this.props.dispatch(getPricesByDistanceList());
    }

    toggleModal() {
        this.setState({
            showModal:!this.state.showModal
        })
    }

    uploadFileByDistance(file) {
        const {distanceCountryFromValue, distanceCountryToValue, dispatch} = this.props;
        const queryParams = {
            countryFrom: distanceCountryFromValue,
            countryTo: distanceCountryToValue
        }
        this.setState({
            uploadingFile: true
        });
        apiFileService.uploadPriceListByDistance(queryParams, file).then(response => {
            dispatch(alertActions.success(translate('Prices file uploaded successfully')));
            dispatch(getPricesByDistanceList());
            this.setState({
                uploadingFile: false
            });
        }).catch((apiError) => {
            console.log(apiError);
            dispatch(alertActions.error(translate('An upload error occurred, please check your file and retry')));
            this.setState({
                uploadingFile: false
            });
        })

    }

    downloadFile(priceData, downloadExample = false) {
        const {dispatch} = this.props;
        this.setState({
            downloadingFile: true
        });
        const downloadData = {
            countryFrom:priceData.country_from,
            countryTo:priceData.country_to,
        }
        priceApiService.getPricesByDistance(downloadData, downloadExample).then(response => {
            let blob = new Blob([response], {type: 'text/csv;charset=utf-8;'});
            let a = window.document.createElement("a");
            let filename = `prices_by_distance_${downloadData.countryFrom}_${downloadData.countryTo}_${fullDate()}.csv`;
            if (downloadExample){
                filename = `prices_by_distance_${downloadData.countryFrom}_${downloadData.countryTo}_example.csv`;
            }
            a.href = URL.createObjectURL(blob);
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.setState({
                downloadingFile: false
            });
        })
            .catch((apiError) => {
                console.log(apiError);
                dispatch(alertActions.error(translate('Prices file cannot be downloaded now, try later')));
                this.setState({
                    downloadingFile: false
                });
            })
    }

    render() {
        const {uploadingFile, downloadingFile} = this.state;
        const {distanceCountryFromValue, distanceCountryToValue, isFetching, pricesByDistance} = this.props;
        return <div className="cooperation-form distance-form">
            <Row>
                <Col md={12}>
                    <div className="prices-title">
                        <Button color="primary" size={"sm"} onClick={this.toggleModal} disabled={isFetching}>
                            {isFetching ? <Loader /> : translate("List of prices")}
                        </Button>
                        <Button color="primary" outline size={"sm"} onClick={()=>this.downloadFile({
                            country_from:'DE', country_to:'DE'
                        }, true)} disabled={downloadingFile}>
                            {translate("Example file")}
                        </Button>
                    <div className="centered">
                        <h5 className="cooperation-title">{`${translate("Prices by distance")} ${distanceCountryFromValue} - ${distanceCountryToValue}`} </h5>
                    </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}></Col>
                <Col md={6}>
                    <div className="button-container">
                        {uploadingFile ? <Loader />
                            : <SelectFile
                                handleFile={this.uploadFileByDistance}
                                name={"upload-by-distance"}
                                accept={"text/csv"}
                                disabled={false}
                            />}
                    </div>
                </Col>
            </Row>
            <Modal isOpen={this.state.showModal} toggle={this.toggleModal} size="lg" scrollable={true} className={"prices-import-modal"}>
                <ModalHeader toggle={this.toggleModal}>
                    {translate("List of uploaded prices by distance")}
                </ModalHeader>
                <ModalBody>
                    <MediaQuery query='(min-device-width: 769px)'>
                        <div className="prices-codes-list">
                            <div className="prices-codes-row distance header">
                                <div>{translate("Country from")}</div>
                                <div>{translate("Country to")}</div>
                                <div>{translate("Updated at")}</div>
                                <div></div>
                            </div>
                            {
                                pricesByDistance.map((price,i)=><div className="prices-codes-row distance" key={i}>
                                        <div>{price.country_from}</div>
                                        <div>{price.country_to}</div>
                                        <div>{price.update_time}</div>
                                        <div>
                                            <Button color="primary" size={"sm"} onClick={()=>this.downloadFile(price)} disabled={downloadingFile}>
                                                {translate("Download")}
                                            </Button>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </MediaQuery>
                    <MediaQuery query='(max-device-width: 768px)'>
                        <div className="centered"><h5>{translate("Tap to download:")}</h5></div>
                        <div className="prices-codes-list">
                            {
                                pricesByDistance.map((price,i)=><div className="prices-codes-row"  key={i} onClick={()=>this.downloadFile(price)}>
                                        <div>{translate("Country from")}: <strong>{price.country_from}</strong></div>
                                        <div>{translate("Country to")}: <strong>{price.country_to}</strong></div>
                                        <div>{translate("Updated at")}{'\n'}<strong>{price.update_time}</strong></div>
                                    </div>
                                )
                            }
                        </div>
                    </MediaQuery>
                </ModalBody>
            </Modal>
        </div>
    }
}

const selector = formValueSelector('countriesSelectorForm');

const mapStateToProps = (state) => {
    return {
        distanceCountryToValue: selector(state, 'countryTo'),
        distanceCountryFromValue: selector(state, 'countryFrom'),
        pricesByDistance: state.pricesByDistanceListReducer.pricesByDistance,
        isFetching: state.pricesByDistanceListReducer.isFetching,
        error: state.pricesByDistanceListReducer.error
    }
}

DistanceImportForm = connect(mapStateToProps)(DistanceImportForm)

export default DistanceImportForm;
