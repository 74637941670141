import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import {translate} from "services/translationService";
import './style.scss';


const FieldInput = ({
                        input,
                        label,
                        type,
                        meta: { touched, error, warning },
                        className,
                        placeholder,
                        inputId,
                        disabled,
                        labelClassName,
                        inputClassName,
                        inputValue,
                    }) => {
    return (
        <FormGroup className={`form-input ${className}`}>
            {
                label &&
                <div className={`${labelClassName} labelContainer`}>
                    <Label htmlFor={inputId}>{label}</Label>
                </div>
            }
            <div className={inputClassName}>
                <Input
                    {...input}
                    placeholder={placeholder}
                    type={type}
                    id={inputId}
                    checked={input.value}
                    disabled={disabled}
                    className={touched && (error || warning) ? 'input-invalid form-control' : 'form-control'}
                    {...(inputValue && {value: inputValue})}
                />
                {
                    touched &&
                    (
                        (error && <span className="error invalid-feedback ml-2">{translate(error)}</span>) ||
                        (warning && <span className="warning invalid-feedback ml-2">{translate(warning)}</span>)
                    )
                }
            </div>
        </FormGroup>
    )
};
FieldInput.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.object.isRequired,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    inputId: PropTypes.string,
    inputValue: PropTypes.node,
    disabled: PropTypes.bool,
};

FieldInput.defaultProps = {
    type: 'text',
    placeholder: '',
    inputId: '',
    label: '',
    className: 'row',
    labelClassName: 'col-md-3',
    inputClassName: 'col',
    disabled: false,
    inputValue: '',
};

export default FieldInput;
