import {Col, Row} from "reactstrap";
import {Field} from "redux-form";
import {connect} from 'react-redux';
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import FieldSelect from 'components/FormFields/FieldSelect/FieldSelect';
import React from "react";
import {translate} from 'services/translationService';
import {countriesForSelect, hasProvinces, provincesForSelect} from "helpers/countries"
import {translateFormLabels} from "../../helpers/translateFormLabels";

class Address extends React.Component {

    constructor(props) {
        super(props);
        this.getCountryCode = this.getCountryCode.bind(this);
        this.state = {
            labels: translateFormLabels()
        };
    }

    getCountryCode(formData){
        //countryCode is extracted from reduxForm data - depending on object depth
        let countryCode;
        const {formName} = this.props;
        if (formName.indexOf('.')<0) {
            countryCode = (((formData[formName] || {}).values || {}).address || {}).country;
        }
        else {
            countryCode = ((((formData[formName.split('.')[0]] || {}).values || {})[formName.split('.')[1]] ||{}).address || {}).country;
        }
        if (countryCode === undefined) {
            countryCode = null
        }
        return countryCode;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale){
            this.setState({labels: translateFormLabels()});
        }
    }

    render() {
        const {countries, isFetching, error} = this.props;
        const disabled = (this.props.disabled !== undefined) ? this.props.disabled : false;

        const formData = this.props.form;
        const countryCode = this.getCountryCode(formData);

        const showProvincesList = hasProvinces(countries, countryCode);
        const {labels} = this.state;

        return (
            <div>
                {isFetching === false && !error ?
                    <div>
                        <h5>{translate("Address")}</h5>
                        <Row>
                            <Col sm={6}>
                                <Field
                                    name="street[0]"
                                    inputId="street[0]"
                                    label={labels.addressStreet}
                                    disabled={disabled}
                                    component={FieldInput}
                                />
                                <Field
                                    name="street[1]"
                                    inputId="street[1]"
                                    label=" "
                                    disabled={disabled}
                                    component={FieldInput}
                                />
                                <Field
                                    name="street[2]"
                                    inputId="street[2]"
                                    label=" "
                                    disabled={disabled}
                                    component={FieldInput}
                                />
                            </Col>

                            <Col sm={6}>
                                <Field
                                    name="postCode"
                                    inputId="postCode"
                                    label={labels.addressZip}
                                    disabled={disabled}
                                    component={FieldInput}
                                />

                                <Field
                                    name="city"
                                    inputId="city"
                                    label={labels.addressCity}
                                    disabled={disabled}
                                    component={FieldInput}
                                />

                                <Field
                                    name="country"
                                    inputId="country"
                                    options={countriesForSelect(countries)}
                                    label={labels.addressCountry}
                                    disabled={disabled}
                                    component={FieldSelect}
                                    props={{emptyValue: false}}
                                />

                                {showProvincesList ? <Field
                                    name="state_province"
                                    inputId="state_province"
                                    options={provincesForSelect(countries, countryCode)}
                                    label={labels.addressProvince}
                                    disabled={disabled}
                                    component={FieldSelect}
                                    props={{emptyValue: false}}
                                /> : null}
                            </Col>
                        </Row>
                    </div>
                    : null}
            </div>
        )
    }
}

Address.defaultProps = {
    countries: {},
    isFetching: null,
    error: false,
    formData: {}
};

const mapStateToProps = state => ({
    countries: state.countryReducer.countries,
    isFetching: state.countryReducer.isFetching,
    error: state.countryReducer.error,
    form: state.form,
    locale:state.translationReducer.locale
});

export default connect(mapStateToProps)(Address);
