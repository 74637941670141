import React from "react";
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {connect} from 'react-redux';
import {translate} from 'services/translationService';
import Loader from "components/Loader/Loader";
import {getUserInfo} from "../../../../redux/actions/users";
import MyCarSoloTruckDetails from "./MyCarSoloTruckDetails";
import MyCarSetDetails from "./MyCarSetDetails";
import Button from "reactstrap/es/Button";
import "./style.scss"
import TransferTrailerModal from "./TransferTrailerModal";
import ReceiveTrailerModal from "./ReceiveTrailerModal";
import HelperButton from "../../../../components/Helper/HelperButton";

class MyCar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showTransferTrailer: false,
            showReceiveTrailer:false
        };
        this.showTrailerModal = this.showTrailerModal.bind(this);
        this.toggleTransferTrailerModal = this.toggleTransferTrailerModal.bind(this);
        this.toggleReceiveTrailerModal = this.toggleReceiveTrailerModal.bind(this);
    }

    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(getUserInfo(this.userId));
    }

    showTrailerModal() {
        const hasTrailer = this.props.user.vehicleSet.hasOwnProperty('truckTrailer');
        this.setState({
            showTransferTrailer: hasTrailer,
            showReceiveTrailer:!hasTrailer
        })
    }

    toggleTransferTrailerModal() {
        this.setState({
            showTransferTrailer: !this.state.showTransferTrailer
        })
    }

    toggleReceiveTrailerModal() {
        this.setState({
            showReceiveTrailer: !this.state.showReceiveTrailer
        })
    }

    render() {
        const {isFetching, user} = this.props;
        if (isFetching) {
            return (
                <div className="content">
                    <Loader/>
                </div>
            )
        }
        const vehicleSet = user.vehicleSet ? user.vehicleSet : null;
        const carSetId = vehicleSet && vehicleSet.carSetId ? vehicleSet.carSetId : null;
        const userId = user.id;
        const car = vehicleSet ? vehicleSet.car : null;
        return (
            <div className="content">
                <Row>
                    <Col md={12}>
                        <Card className="my-car">
                            <CardHeader>
                                <CardTitle>
                                    <div className={"my-car-title"}>
                                        <h4><i
                                            className="nc-icon nc-bus-front-12"/> {translate('Assigned vehicle data')}
                                        </h4>
                                        {(car && car.type === 'SOLO_TRUCK') && <Button className={"trailer-button"}
                                            onClick={()=>this.showTrailerModal()}>
                                            <i className={"nc-icon nc-refresh-69"}/>{translate("Trailer")}
                                        </Button>}
                                    </div>
                                    <hr/>
                                </CardTitle>
                                <HelperButton helperId="carrier-my-car-details" />
                            </CardHeader>
                            <CardBody className={"my-car-details"}>
                                {car ? <div>
                                        {carSetId == null ? <MyCarSoloTruckDetails
                                            car={car}
                                        /> : <MyCarSetDetails
                                            carSetId={carSetId}
                                        />}
                                    </div>
                                    :
                                    <h4>{translate("No car assigned at the moment...")}</h4>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <TransferTrailerModal
                    toggleModal = {this.toggleTransferTrailerModal}
                    showModal = {this.state.showTransferTrailer}
                    userId = {userId}
                />
                <ReceiveTrailerModal
                    toggleModal = {this.toggleReceiveTrailerModal}
                    showModal = {this.state.showReceiveTrailer}
                    userId = {userId}
                />
            </div>
        );
    }
}

MyCar.defaultProps = {
    user: {},
    isFetching: true,

};

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        isFetching: state.userReducer.isFetching,
    }
};
export default connect(mapStateToProps)(MyCar);
