import React from "react";
import {Button, Col, Row} from 'reactstrap';
import {translate} from 'services/translationService';
import "./style.scss"
import Loader from "../../components/Loader/Loader";
import {priceApiService} from "../../services/priceApiService";
import {history} from "../../helpers";
import {apiService} from "../../services/apiService";
import orderService from "../../services/orderService";
import HelperButton from "../../components/Helper/HelperButton";

class Code extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      transferCode: "",
      isCheckingCode:false,
      invalidCode:false,
      outdatedCode:false,
      ordersData:[],
      operationType:null
    };
    this.updateCode = this.updateCode.bind(this);
    this.checkCode = this.checkCode.bind(this);
    this.clearCode = this.clearCode.bind(this);
    this.useCode = this.useCode.bind(this);
    this.codeInput = React.createRef();
  }

  componentDidMount() {
    this.codeInput.current.focus();
  }

  updateCode(e) {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, '');
    this.setState({transferCode: inputValue, invalidCode:false, outdatedCode:false});
  }

  clearCode() {
    this.setState({
      transferCode: "",
      isCheckingCode:false,
      invalidCode:false,
      outdatedCode:false,
      ordersData:[],
      operationType:null
    })
    this.codeInput.current.focus();
  }

  async checkCode() {
    const {transferCode} = this.state;
    this.setState({isCheckingCode: true});
    try {
      const codeData = await priceApiService.checkCode(transferCode);
      if(codeData.status === 'success'){
        const shipmentIds = codeData.data.shipments.map(shipment=>shipment.id_shipment);
        if (!codeData.data.active || codeData.data.used ){
          this.setState({
            isCheckingCode:false,
            invalidCode:false,
            outdatedCode:true,
            ordersData:[],
            operationType:null
          })
          this.codeInput.current.focus();
          return
        }
        const orders = await apiService.get('/api/orders/by_shipments', {shipmentIds});
        if (!orders.data.length){
          this.setState({
            isCheckingCode:false,
            invalidCode:true,
            outdatedCode:false,
            ordersData:[],
            operationType:null
          })
        } else {
          this.setState({ordersData:orders.data, invalidCode:null, operationType:codeData.data.purpose_code});
        }

      }
      this.setState({isCheckingCode:false});
    } catch (error) {
      console.log(error);
      this.setState({isCheckingCode:false, invalidCode:transferCode});
      this.codeInput.current.focus();
    }
  }

  useCode() {
    const {transferCode, operationType} = this.state;
    let multipleUrl = new URL(window.location.href);
    this.state.ordersData.forEach(order=>multipleUrl.searchParams.append('ordersIds[]',order.orderId));
    multipleUrl.searchParams.append('code', transferCode)
    multipleUrl.searchParams.append('operationType', operationType)
    multipleUrl.searchParams.append('statusId', orderService.mapPurposeCodeToStatusId(operationType))
    history.push("/multiple-orders/" + multipleUrl.search);
  }

  render() {
    const {transferCode, isCheckingCode, invalidCode, ordersData, outdatedCode} = this.state;
    const codeIsValid = !invalidCode && ordersData.length > 0;
    const transferInputClass = transferCode.length < 10 ? "short" : invalidCode || outdatedCode ? "invalid" : codeIsValid? "valid" : "";

    return (
      <div className="content">
        <Row>
          <Col md={12}>
            <div className={"code-panel"}>
              <p>{translate("Enter confirmation code:")}</p>
              <div className={"code-row"}>
                <input className={`transfer-code ${transferInputClass}`}
                       onChange={this.updateCode} name={"transferCode"}
                       value={transferCode} maxLength={10} ref={this.codeInput} />
                <div className="button-group">
                <span className="barcode" />
                {transferCode.length < 10 || invalidCode || codeIsValid || outdatedCode?
                <Button type="button" color={"warning "} outline onClick={this.clearCode} disabled={transferCode.length === 0}>
                  {translate("Clear")}
                </Button> :
                  <Button type="button" color={"secondary"}  onClick={this.checkCode}>
                {isCheckingCode ? <Loader /> : translate("Check")}
                  </Button>
                }
                </div>
              </div>
              <HelperButton helperId={"carrier-code-enter"} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className={"code-panel"}>
              {invalidCode && transferCode.length === 10 && <div className="error-panel">
                <span className="exclamation" />
                <p>{translate("Your code is not valid")}</p>
              </div>}
              {outdatedCode && transferCode.length === 10 && <div className="error-panel">
                <span className="exclamation" />
                <p>{translate("Your code was already used")}</p>
              </div>}
              {codeIsValid && <div className="orders-panel">
                  <table className="orders-table">
                   <thead>
                    <tr>
                      <th>{translate("Shipment Number")}</th>
                      <th>{translate("Colli")}</th>
                      <th>{translate("Description")}</th>
                    </tr>
                   </thead>
                    <tbody>
                    {ordersData.map((order,key)=><tr key={key}>
                      <td>{order.number}</td>
                      <td>{order.packages.length}</td>
                      <td>{order.description}</td>
                    </tr>)}
                    </tbody>
                  </table>
                <Button type="button" color={"primary"} onClick={this.useCode}>
                  {translate("Go to confirmation")}
                </Button>
              </div>
              }
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

Code.defaultProps = {};

export default Code;
