import {
  FETCH_INQUIRIES_REQUEST,
  FETCH_INQUIRIES_SUCCESS,
  FETCH_INQUIRIES_FAILURE
} from 'redux/actions/inquiries/inquiries.actions.js';

const initialState = {
  message: '',
  inquiries: {},
  isFetching: true,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INQUIRIES_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }

    case FETCH_INQUIRIES_SUCCESS: {
      return Object.assign({}, state, {
        inquiries: action.data,
        isFetching: false,
      });
    }

    case FETCH_INQUIRIES_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: true
      });
    }
    default:
      return state
  }
}