import {
  CREATE_OFFER_REQUEST,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAILURE
} from '../../actions/inquiries/inquiryOffer.actions'

const initialState = {
  inquiryOffer: {},
  isFetching: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_OFFER_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        error: false
      });
    }
    case CREATE_OFFER_SUCCESS: {
      return Object.assign({}, state, {
        inquiryOffer: action.data,
        isFetching: false,
      });
    }
    case CREATE_OFFER_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: true
      });
    }

    default: return state
  }
}
