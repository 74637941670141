import * as jwtDecode from 'jwt-decode'
import {apiService} from "./apiService";
import {history} from "../helpers";
import {store} from "../helpers";
import {getTranslations} from "../redux/actions";
import {isCordova} from "../helpers/cordova";

export const authService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    storeUser
};

function login(email, password) {
    return apiService.post('/auth/login', {email, password})
        .then(response => {
            storeUser(response)
        }, response => {
            return Promise.reject(response)
        });
}

function logout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    localStorage.removeItem('forgotPasswordTime');
    localStorage.removeItem('tokenExp');
    localStorage.removeItem('carsSearchParams');
    localStorage.removeItem('config');
    localStorage.removeItem('locale');
    localStorage.removeItem('inquiriesNewCounter');
    localStorage.removeItem('priceApiToken');
    localStorage.removeItem('carFleetTab');
    localStorage.removeItem('notificationApiToken');
    localStorage.removeItem('hasCooperationAccess');
    localStorage.removeItem('cooperationApiToken');
    localStorage.removeItem('documentsApiToken');
    if (isCordova()) {
        document.location.hash = 'login';
    } else {
        history.replace('/login');
    }
}

function storeUser(response) {
    localStorage.setItem('authToken', response.data.token);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    localStorage.setItem('priceApiToken', response.data.priceApiToken);
    localStorage.setItem('notificationApiToken', response.data.notificationApiToken);
    localStorage.setItem('cooperationApiToken', response.data.cooperationApiToken);
    localStorage.setItem('documentsApiToken', response.data.documentsApiToken);
    let decodedToken = jwtDecode(response.data.token);
    localStorage.setItem('tokenExp', JSON.stringify(decodedToken.exp));
    let user = {
        id: decodedToken.accountId,
        carrierId: decodedToken.carrierId,
        carrierIsActive: decodedToken.carrierIsActive,
        carrierName: decodedToken.carrierName,
        roles: decodedToken.roles,
        status: decodedToken.status,
        name: decodedToken.name,
        surname: decodedToken.surname,
        language: decodedToken.language.toLowerCase(),
        hasInquiriesAccess: decodedToken.hasInquiriesAccess,
        hasCooperationAccess: decodedToken.hasCooperationAccess,
        hasShipmentsConversationsAccess: decodedToken.hasShipmentsConversationsAccess
    };
    localStorage.setItem('user', JSON.stringify(user));
    store.dispatch(getTranslations(user.language));
    return response;
}

function forgotPassword(email) {
    return apiService.put('/forgot-password', email)
        .then(response => {
            return response;
        }, response => {
            return Promise.reject(response);
        });
}

function resetPassword(newPasswordRequest) {
    return apiService.put('/reset-password', newPasswordRequest)
        .then(response => {
            return response;
        }, response => {
            return Promise.reject(response);
        });
}
