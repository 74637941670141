import React from 'react';
import PropTypes from 'prop-types';
import cross from "./cross.svg";

import './style.scss';

const createSrc = (file) => {
    return  URL.createObjectURL(file)
}

const PreviewPhoto = ({file, clearFile}) =><div className={"preview-container"}>
    <img src={cross} className={"delete-cross"} alt={"delete cross"} onClick={clearFile}/>
    <img src={createSrc(file)} className="photo-img-preview" alt={file.name} />
</div>

PreviewPhoto.propTypes = {
    file: PropTypes.object.isRequired,
    clearFile: PropTypes.func.isRequired
};

export default PreviewPhoto;
