import React from "react";
import {Field, reduxForm} from 'redux-form';
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import {passwordValidation} from '../validation.js'
import {translate} from 'services/translationService';
import Button from "components/CustomButton/CustomButton.jsx";
import {translateFormLabels} from "../../../../helpers/translateFormLabels";
import {connect} from "react-redux";
import {apiService} from "../../../../services/apiService";
import {alertActions} from "../../../../redux/actions";
import HelperButton from "../../../../components/Helper/HelperButton";


class PasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.state = {
            labels:  translateFormLabels()
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale){
            this.setState({labels: translateFormLabels()});
        }
    }

    onFormSubmit(formData) {
        apiService.put(`api/change-password`, formData).then(
            response => {
                this.props.dispatch(alertActions.success(translate("Password changed")));
            })
            .catch(error => {
                this.props.dispatch(alertActions.error(translate("Error during password change")));
            });
    }

    render() {
        const {labels} = this.state;
        return (
            <Card className="card-user">
                <CardHeader>
                    <CardTitle>
                        <h4><i className="nc-icon nc-key-25" /> { translate('Password update') } </h4>
                        <hr />
                    </CardTitle>
                </CardHeader>
                    <CardBody className="password-container">
                        <form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
                            <Row>
                                <Col sm={6}>
                                    <Field
                                        name="currentPassword"
                                        inputId="currentPassword"
                                        type="password"
                                        label={labels.passwordCurrent}
                                        component={FieldInput}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Field
                                        name="newPassword"
                                        inputId="newPassword"
                                        type="password"
                                        label={labels.passwordNew}
                                        component={FieldInput}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <div className="update ml-auto mr-auto">
                                    <Button color="primary" round disabled={!this.props.valid}>
                                        {translate("Update Password")}
                                    </Button>
                                </div>
                            </Row>
                        </form>
                    </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    locale:state.translationReducer.locale
});

const decoratedComponent = connect(mapStateToProps)(PasswordForm);

export default reduxForm({
    form: 'passwordForm',
    validate: passwordValidation
})(decoratedComponent);

