import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./styles.scss"
import {translate} from "../../../services/translationService";
import {Button, Input} from "reactstrap";
import MediaQuery from "react-responsive";
import Loader from "../../../components/Loader/Loader";

class SurchargesForm extends Component {

  constructor(props) {
    super(props);
    let surchargesInputs = {};
    props.initialPrices.forEach(surcharge => surchargesInputs[surcharge.service_code] = surcharge.price)
    this.state = {
      surchargesInputs,
      isUploading: false
    }
    this.updateSurcharges = this.updateSurcharges.bind(this);
    this.canSave = this.canSave.bind(this);
  }

  updateSurcharges(e) {
    const  inputIndex = e.target.name.split('-')[1];
    const inputValue = e.target.value;
    let newInputValues = {...this.state.surchargesInputs};
    newInputValues[inputIndex] = inputValue;
    this.setState({
      surchargesInputs: newInputValues
    })
  }

  canSave(){
    const {surchargesInputs} = this.state;
    let isValid = true;
    for (const surchargeCode in surchargesInputs){
          const value =  surchargesInputs[surchargeCode];
          if (value === "" ||  parseFloat(value) < 0) isValid = false;
    }
    return isValid;
  }

  render() {
    const {countryFrom, countryTo, initialPrices, isUpdating, type, updatePrices} = this.props;
    const {surchargesInputs} = this.state;
    const isValueInvalid = (value) => value === "" || parseFloat(value) < 0
    return <div>
      <h5 className="cooperation-title">
        {`${translate(type === "sunday" ? "Sunday delivery surcharges":"Saturday delivery surcharges")} ${countryFrom} - ${countryTo}`} </h5>
      <div className="cargo-options-form">

        <div className="cargo-options-title surcharges-title">
          <div>{translate("Service")}</div>
          <div className="centered">{translate("Price")}</div>
        </div>
        {initialPrices.map((surcharge, key) => <div className="cargo-options-row saturday" key={key}>
          <MediaQuery maxDeviceWidth={859}>
            {(matches) => matches ?
              <div>{surcharge.hasOwnProperty('short_name') ? surcharge.short_name : surcharge.service_name}</div> :
              <div>{surcharge.service_name}</div>}
          </MediaQuery>
          <div className={"option-input-group"}>
            <Input
              name={`${type}-${surcharge.service_code}`}
              value={surchargesInputs[surcharge.service_code]}
              onChange={this.updateSurcharges}
              type="number"
              step="0.01"
              min="0"
              className={isValueInvalid(surchargesInputs[surcharge.service_code]) ? "invalid" :""}
              title={isValueInvalid(surchargesInputs[surcharge.service_code]) ? translate("Enter valid number") : "" }
            />
            <span>€</span>
          </div>
        </div>)}
        <div className="button-container saturday">
          <Button color="primary" size={"sm"} onClick={()=>updatePrices(surchargesInputs)}  disabled={!this.canSave()}>
            {isUpdating ? <Loader /> : translate("Save")}
          </Button>
        </div>
      </div>


    </div>
  }
}

SurchargesForm.propTypes = {
  initialPrices: PropTypes.array,
  countryFrom: PropTypes.string,
  countryTo: PropTypes.string,
  isUpdating:PropTypes.bool,
  updatePrices:PropTypes.func,
  type:PropTypes.oneOf(['saturday', 'sunday'])
};

export default SurchargesForm;
