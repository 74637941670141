import { apiService } from "services/apiService";
import { alertActions } from "redux/actions";
import getErrorText from "services/errorTextService";
import {history} from "../../../helpers";
import {translate} from "../../../services/translationService";

export const FETCH_VEHICLE_REQUEST = 'FETCH_VEHICLE_REQUEST';
export const FETCH_VEHICLE_SUCCESS = 'FETCH_VEHICLE_SUCCESS';
export const FETCH_VEHICLE_FAILURE = 'FETCH_VEHICLE_FAILURE';
export const UPDATE_VEHICLE_REQUEST = 'UPDATE_VEHICLE_REQUEST';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_FAILURE = 'UPDATE_VEHICLE_FAILURE';

export function getVehicle(id) {
    return dispatch => {
        dispatch(request({}));
        apiService.get('/api/vehicles/' + id)
            .then(
                response => {
                    dispatch(success(response));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_VEHICLE_REQUEST, data } }
    function success(data) { return { type: FETCH_VEHICLE_SUCCESS, data } }
    function failure(error) { return { type: FETCH_VEHICLE_FAILURE, error } }
}

export function updateVehicle(id, formData) {
    return dispatch => {
        dispatch(request({}));
        apiService.put('api/vehicles/' + id,formData)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(translate("Vehicle updated successfully")));
                    history.push('/cars');
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: UPDATE_VEHICLE_REQUEST, data } }
    function success(data) { return { type: UPDATE_VEHICLE_SUCCESS, data } }
    function failure(error) { return { type: UPDATE_VEHICLE_FAILURE, error } }
}

