import {ORDERS_TYPES} from "../../redux/actions/orders";

export const orderHelper = {
    getOrderTypeListFromRouteMatch,
    getFirstPageForList,
    getPageNumberFromLocalstorage
};

function getOrderTypeListFromRouteMatch(match){
    let orderType = match.path.split('/')[2];
    switch (orderType) {
        case "ongoing":
            return ORDERS_TYPES.ONGOING;
        case "new":
            return ORDERS_TYPES.NEW;
        case "delivered":
            return ORDERS_TYPES.DELIVERED;
        case "processed":
            return ORDERS_TYPES.PROCESSED;
        case "all":
        default:
            return ORDERS_TYPES.ALL
    }
}

function getFirstPageForList(history){
    if (history.action && history.action === 'PUSH'){
        localStorage.setItem('currentPage','1');
    }
    return orderHelper.getPageNumberFromLocalstorage();
}

function getPageNumberFromLocalstorage(){
    return localStorage.getItem('currentPage') ? parseInt(localStorage.getItem('currentPage')) : 1;
}
