import React from "react";
import {connect} from 'react-redux'
import Loader from "../../../components/Loader/Loader";
import {Card, CardBody, Col, Row} from "reactstrap";
import "./styles.scss"
import {translate} from "../../../services/translationService";
import {formValueSelector} from "redux-form";
import CountriesSelector from "../CountriesSelector";
import PrimaryPrices from "./PrimaryPrices";
import Surcharges from "./Surcharges";
import DedicatedOptions from "./DedicatedOptions";
import DedicatedStop from "./DedicatedStop";
import Aliases from "./Aliases";
import HelperButton from "../../../components/Helper/HelperButton";

class Dedicated extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.isFetching) {
            return (
              <div className="content">
                  <Loader/>
              </div>
            )
        }
        const {countries} = this.props;

        return (
            <div className="content">
                <div className="dedicated-cards">
                    <Card className={"dedicated-card-1"}>
                        <HelperButton helperId="carrier-countries-selector" />
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <div className="centered"><h5 className="cooperation-title">{translate("Select countries")}</h5></div>
                                    <CountriesSelector
                                      countries={countries}
                                      initialValues={this.props.initialValues}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className={"dedicated-card-2"}>
                        <HelperButton helperId="carrier-aliases-selector" />
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <div className="centered"><h5 className="cooperation-title">{translate("Aliases")}</h5></div>
                                    <Aliases
                                      countries={countries}
                                      initialValues={{aliasCountryFrom:"DE", aliasCountryTo:"DE"}}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
                <Card>
                    <HelperButton helperId="carrier-primary-prices" />
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <PrimaryPrices />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <HelperButton helperId="carrier-surcharges-saturday-sunday" />
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <Surcharges />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <HelperButton helperId="carrier-surcharges-transport-options" />
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <DedicatedOptions />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <HelperButton helperId="carrier-surcharges-stop" />
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <DedicatedStop />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

Dedicated.defaultProps = {
    countries: {},
    isFetching: null,
    error: false,
};

const countriesSelector = formValueSelector('countriesSelectorForm');

const mapStateToProps = state => ({
    countries: state.countryReducer.countries,
    isFetching: state.countryReducer.isFetching,
    error: state.countryReducer.error,
    initialValues: {
        countryFrom: "DE",
        countryTo:"DE",
    },
    countryToValue: countriesSelector(state, 'countryTo'),
    countryFromValue: countriesSelector(state, 'countryFrom'),
});

export default connect(mapStateToProps)(Dedicated);
