import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {history} from "../../../helpers";
import {priceApiService} from "../../../services/priceApiService";
import {translate} from "../../../services/translationService";

export const FETCH_FRACHT_CONFIG_REQUEST = 'FETCH_FRACHT_CONFIG_REQUEST';
export const FETCH_FRACHT_CONFIG_SUCCESS = 'FETCH_FRACHT_CONFIG_SUCCESS';
export const FETCH_FRACHT_CONFIG_FAILURE = 'FETCH_FRACHT_CONFIG_FAILURE';
export const UPDATE_FRACHT_CONFIG_REQUEST = 'UPDATE_FRACHT_CONFIG_REQUEST';
export const UPDATE_FRACHT_CONFIG_SUCCESS = 'UPDATE_FRACHT_CONFIG_SUCCESS';
export const UPDATE_FRACHT_CONFIG_FAILURE = 'UPDATE_FRACHT_CONFIG_FAILURE';

export function getFreightConfig(params) {
    return dispatch => {
        dispatch(request({}));
        const {countryFrom, countryTo} = params;
        const url = `${countryFrom}/${countryTo}`;
        priceApiService.getConfiguration(url)
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                history.push('/dashboard');
            });
    };

    function request(data) { return { type: FETCH_FRACHT_CONFIG_REQUEST, data } }
    function success(data) { return { type: FETCH_FRACHT_CONFIG_SUCCESS, data } }
    function failure(error) { return { type: FETCH_FRACHT_CONFIG_FAILURE, error } }
}

export function updateFreightConfig(params) {
  return dispatch => {
    dispatch(request({}));
    const {countryFrom, countryTo, data} = params;
    const url = `${countryFrom}/${countryTo}`;
    priceApiService.updateConfiguration(url, data)
      .then(
        response => {
          dispatch(success(response.data));
          dispatch(alertActions.success(translate("Fracht configuration successfully updated")));
        })
      .catch(errorMessage => {
        dispatch(failure({}));
        dispatch(alertActions.error(translate("Failed to update Fracht configuration")));
        history.push('/dashboard');
      });
  };

  function request(data) { return { type: UPDATE_FRACHT_CONFIG_REQUEST, data } }
  function success(data) { return { type: UPDATE_FRACHT_CONFIG_SUCCESS, data } }
  function failure(error) { return { type: UPDATE_FRACHT_CONFIG_FAILURE, error } }
}
