import React from 'react';
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {translate} from 'services/translationService';


const IsActiveInfo = ({isActive, action}) => {
    const isActiveInfo = isActive ? translate('Car set is active') : translate('Car set is not active');
    return(
                <Card className="set-active-info">
                    <CardBody>
                        <Row>
                            <Col sm={6}>
                                <h5><i
                                className="nc-icon nc-alert-circle-i"/> {isActiveInfo}</h5>
                            </Col>
                            <Col sm={6}>
                                <Button className="btn btn-success btn-sm" color={ isActive ? "danger" : "success"} onClick={() => action(!isActive)}>
                                    {isActive ? translate("deactivate") : translate("activate") }
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
    )
}

export default IsActiveInfo
