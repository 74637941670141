import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {history} from "../../../helpers";
import {priceApiService} from "../../../services/priceApiService";
import {translate} from "../../../services/translationService";

export const FETCH_DEDICATED_OPTIONS_REQUEST = 'FETCH_DEDICATED_OPTIONS_REQUEST';
export const FETCH_DEDICATED_OPTIONS_SUCCESS = 'FETCH_DEDICATED_OPTIONS_SUCCESS';
export const FETCH_DEDICATED_OPTIONS_FAILURE = 'FETCH_DEDICATED_OPTIONS_FAILURE';

export const UPDATE_DEDICATED_OPTIONS_REQUEST = 'UPDATE_DEDICATED_OPTIONS_REQUEST';
export const UPDATE_DEDICATED_OPTIONS_SUCCESS = 'UPDATE_DEDICATED_OPTIONS_SUCCESS';
export const UPDATE_DEDICATED_OPTIONS_FAILURE = 'UPDATE_DEDICATED_OPTIONS_FAILURE';

export function getDedicatedOptions(params) {
    return dispatch => {
        dispatch(request({}));
        const {countryFrom, countryTo} = params;
        const url = `Surcharges/byCargoOptions/${countryFrom}/${countryTo}`;
        priceApiService.getDedicated(url)
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                history.push('/dashboard');
            });
    };

    function request(data) { return { type: FETCH_DEDICATED_OPTIONS_REQUEST, data } }
    function success(data) { return { type: FETCH_DEDICATED_OPTIONS_SUCCESS, data } }
    function failure(error) { return { type: FETCH_DEDICATED_OPTIONS_FAILURE, error } }
}

export function updateDedicatedOptions(pricesData) {
  return dispatch => {
    dispatch(request({}));
    const url = `Surcharges/byCargoOptions`;
    priceApiService.updateDedicated(url, pricesData)
      .then(
        response => {
          dispatch(success(response.data));
          dispatch(alertActions.success(translate("Dedicated options were updated successfully")));
        })
      .catch(errorMessage => {
        dispatch(failure(getErrorText(errorMessage)));
        dispatch(alertActions.error(translate("Dedicated options were not updated, please try again")));
        history.push('/dashboard');
      });
  };

  function request(data) { return { type: UPDATE_DEDICATED_OPTIONS_REQUEST, data } }
  function success(data) { return { type: UPDATE_DEDICATED_OPTIONS_SUCCESS, data } }
  function failure(error) { return { type: UPDATE_DEDICATED_OPTIONS_FAILURE, error } }
}
