import React from "react";
import {Link} from "react-router-dom";
import {Button, Collapse, Container, Label, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem,} from "reactstrap";
import {translate} from 'services/translationService';

import sidebarRoutes from "routes/sidebar.jsx";
import {connect} from "react-redux";
import {authActions} from 'redux/actions';
import loggedUserService from 'services/loggedUserService';
import dashboardRoutes from "routes/dashboard";
import "./style.scss";
import NotificationBell from "./NotificationBell";
import {history} from "../../helpers";
import {notificationApiService} from "../../services/notificationApiService";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent",
      notificationCounter: 0,
      gpsState: 0
    };
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.fetchNotificationCount = this.fetchNotificationCount.bind(this);
    this.showBackButton = this.showBackButton.bind(this);
  }
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent"
      });
    } else {
      this.setState({
        color: "dark"
      });
    }
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  getBrand() {
    var name;
    const allRoutes = sidebarRoutes().concat(dashboardRoutes());
    allRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if ((prop.path === this.props.location.pathname) || (prop.regex && prop.regex.test(this.props.location.pathname))) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    if (name === undefined) {
      name = '';
    }
    return translate(name);
  }

  showBackButton() {
    let showButton = false;
    const allRoutes = sidebarRoutes().concat(dashboardRoutes());
    const currentLocation = document.location.hash.replace('#','')
    allRoutes.forEach((prop, key) => {
      if ((prop.path === currentLocation) || (prop.regex && prop.regex.test(currentLocation))) {
        showButton = prop.showBackButton && history.length() > 1;
      }
    });
    return showButton;
  }

  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.refs.sidebarToggle.classList.toggle("toggled");
  }
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark"
      });
    } else {
      this.setState({
        color: "transparent"
      });
    }
  }

  handleLogout() {
    const { dispatch } = this.props;
    dispatch(authActions.logout());
  }

  fetchNotificationCount() {
    notificationApiService.get('api/simple/notifications/unread/count').then(
      response => {
        this.setState({
          notificationCounter: response.data
        });
      })
      .catch(errorMessage => {
        console.log(errorMessage);
      });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
    const savedGpsState = localStorage.getItem('gpsState');
    
    if(savedGpsState && savedGpsState !== 'false'){
      this.setState({
        gpsState: savedGpsState
      });
    }

    this.fetchNotificationCount();
    this.interval = setInterval(() => {
      this.fetchNotificationCount()
    }, 20000)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.refs.sidebarToggle.classList.toggle("toggled");
    }
  }
  switchGPS(){
    const newVal = !this.state.gpsState;
    this.setState({
      gpsState: newVal
    });
    localStorage.setItem('gpsState', newVal);
  }
  render() {
    const gpsState = this.state.gpsState;
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "dark"
            : this.state.color
        }
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
            (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref="sidebarToggle"
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            {this.showBackButton() &&
              <div>
                <button className="back-button" onClick={history.goBack}>
                  <i className="nc-icon nc-minimal-left" />
                </button>
                <p>&nbsp;</p>
              </div>
            }
            <NavbarBrand href="/">{this.getBrand()}</NavbarBrand>
          </div>
          <div className='navbar-buttons'>
            {(typeof(window.cordova)!=='undefined') && <div className='location-switch'>
                <Label>GPS</Label>
                <Button size="sm" color={(gpsState) ? 'primary' : 'secondary'} onClick={() => this.switchGPS()} active={gpsState}>
                {(gpsState) ? 'On' : 'Off'}
                </Button>
            </div>
            }
            <div className="navbar-bell">
              <NotificationBell counter={this.state.notificationCounter} />
              <NavbarToggler onClick={this.toggle}>
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
              </NavbarToggler>
            </div>
          </div>
          
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end submenu"
          >
            <Nav navbar>
              <NavItem>
                <Link to="/user-page" className="nav-link btn-magnify">
                  <i className="nc-icon nc-single-02" />
                  <p className="navbar-user-text">
                    {loggedUserService.getFulName()}
                  </p>
                </Link>
              </NavItem>
              <NavItem>
                {(!this.state.isOpen) && <NotificationBell counter={this.state.notificationCounter} />}
              </NavItem>
              <NavItem>
                <Link to="" onClick={this.handleLogout} className="nav-link btn-rotate">
                  <i className="nc-icon nc-button-power" />
                  <p className="navbar-user-text">
                    {translate("Logout")}
                  </p>
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}
const mapStateToProps = state => ({
  loggedIn: state.authReducer.loggedIn,
});

export default connect(mapStateToProps)(Header);
