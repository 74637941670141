import React from "react";
import {connect} from "react-redux";
import {translate} from "../../../services/translationService";
import "./styles.scss";
import {acceptInvitation, alertActions} from "../../../redux/actions";
import Loader from "../../../components/Loader/Loader";
import {Button} from "reactstrap";
import {withRouter} from "react-router";
import queryString from "query-string";
import {isEmpty} from "lodash";
import {history} from "../../../helpers";

class AcceptInvitation extends React.Component {

  constructor(props) {
    super(props);
    const uuidRefs = [];
    for (let i = 0; i < 36; i++) {
      uuidRefs.push(React.createRef());
    }
    this.initialUUid = ["", "", "", "", "", "", "", "", "-", "", "", "", "", "-", "", "", "", "", "-", "", "", "", "", "-", "", "", "", "", "", "", "", "", "", "", "", "",];
    this.state = {
      uuid: this.initialUUid,
      uuidRefs: uuidRefs
    }
    this.acceptInvitation = this.acceptInvitation.bind(this);
    this.renderUUID = this.renderUUID.bind(this);
    this.updateInputs = this.updateInputs.bind(this);
    this.onPressed = this.onPressed.bind(this);
    this.onPasted = this.onPasted.bind(this);
    this.testUUid = this.testUUid.bind(this);
  }

  componentDidMount() {
    const {uuidRefs} = this.state;
    uuidRefs[0].current.focus();
    const searchParams = queryString.parse(this.props.location.search);
    if (!isEmpty(searchParams) && 'code' in searchParams) {
      const uuidFromUrl = searchParams.code;
      if (this.testUUid(uuidFromUrl)) {
        this.setState({uuid: uuidFromUrl.split('')})
        this.props.dispatch(acceptInvitation(uuidFromUrl));
      } else {
        this.props.dispatch(alertActions.error(translate("Acceptation link cannot be used")));
        history.push('/cooperation')
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isAcceptingInvitation && !this.props.error && !this.props.isAcceptingInvitation) {
      this.setState({uuid: this.initialUUid});
    }
  }

  updateInputs(e) {
    const {uuidRefs} = this.state;
    const inputIndex = parseInt(e.target.name);
    const inputValue = e.target.value;
    if (!/[0-9a-fA-F]/.test(inputValue)) {
      uuidRefs[inputIndex].current.value = "";
      return;
    }
    let uuid = [...this.state.uuid];
    uuid[inputIndex] = inputValue;
    if (inputIndex < 35) {
      const nextRefIndex = uuid[inputIndex + 1] !== '-' ? inputIndex + 1 : inputIndex + 2
      uuidRefs[nextRefIndex].current.focus();
    }
    this.setState({uuid});
  }

  onPressed(e) {
    if (!(e.key === 'Backspace')) return;
    const {uuidRefs} = this.state;
    const inputIndex = parseInt(e.target.name);
    const inputValue = e.target.value;
    uuidRefs[inputIndex].current.value = '';
    let uuid = [...this.state.uuid];
    if (inputIndex > 0 && inputValue === '') {
      const prevRefIndex = uuid[inputIndex - 1] !== '-' ? inputIndex - 1 : inputIndex - 2
      uuidRefs[prevRefIndex].current.focus();
      uuidRefs[prevRefIndex].current.value = '';
      uuid[prevRefIndex] = '';
    } else {
      uuidRefs[inputIndex].current.value = '';
      uuid[inputIndex] = '';
    }
    this.setState({uuid});
  }

  onPasted(e) {
    if (e.clipboardData && e.clipboardData.getData("text").trim().length === 36) {
      const pastedUuid = e.clipboardData.getData("text").trim();
      if (this.testUUid(pastedUuid)) {
        this.setState({uuid: pastedUuid.split("")});
        e.preventDefault();
        return;
      }
    }
    this.props.dispatch(alertActions.error(translate("You tried to paste an invalid code")));
  }

  testUUid(uuid) {
    const uuidRegex = new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/);
    return uuidRegex.test(uuid)
  }

  acceptInvitation() {
    const {dispatch} = this.props;
    dispatch(acceptInvitation(this.state.uuid.join('')));
  }

  renderUUID() {
    const {uuid, uuidRefs} = this.state;
    const uuidInput = (index) => <input className={"uuid-input"}
                                        value={uuid[index]}
                                        name={index}
                                        maxLength={1}
                                        onChange={this.updateInputs}
                                        onKeyDown={this.onPressed}
                                        ref={uuidRefs[index]}
                                        onPaste={this.onPasted}
    />
    return <div className={"uuid-input-wrapper"}>
      <div className={"uuid-segment"}>
        {uuidInput(0)}{uuidInput(1)}{uuidInput(2)}
        {uuidInput(3)}{uuidInput(4)}{uuidInput(5)}
        {uuidInput(6)}{uuidInput(7)}
      </div>
      <span className={"uuid-minus"}>-</span>
      <div className={"uuid-segment"}>
        {uuidInput(9)}{uuidInput(10)}{uuidInput(11)}{uuidInput(12)}
      </div>
      <span className={"uuid-minus"}>-</span>
      <div className={"uuid-segment"}>
        {uuidInput(14)}{uuidInput(15)}{uuidInput(16)}{uuidInput(17)}
      </div>
      <span className={"uuid-minus"}>-</span>
      <div className={"uuid-segment"}>
        {uuidInput(19)}{uuidInput(20)}{uuidInput(21)}{uuidInput(22)}
      </div>
      <span className={"uuid-minus"}>-</span>
      <div className={"uuid-segment"}>
        {uuidInput(24)}{uuidInput(25)}{uuidInput(26)}
        {uuidInput(27)}{uuidInput(28)}{uuidInput(29)}
        {uuidInput(30)}{uuidInput(31)}
        {uuidInput(32)}{uuidInput(33)}{uuidInput(34)}
        {uuidInput(35)}
      </div>
    </div>
  }

  render() {
    const {isAcceptingInvitation, isSendingInvitation} = this.props;

    return <>
      <div className="invitation-accept">
        {this.renderUUID()}
        <Button color="primary" size={"sm"} onClick={() => this.acceptInvitation()}
                disabled={!this.testUUid(this.state.uuid.join('')) || isSendingInvitation}>
          {isAcceptingInvitation ? <Loader /> : translate("Send code")}
        </Button>
      </div>
    </>
  }
}


AcceptInvitation.defaultProps = {
  isAcceptingInvitation: null,
  error: false,
};

const mapStateToProps = (state) => ({
  isAcceptingInvitation: state.invitationsReducer.isAcceptingInvitation,
  error: state.invitationsReducer.error,
});

const connectedComponent = connect(mapStateToProps)(AcceptInvitation);
export default withRouter(connectedComponent);
