import React from 'react';
import {Badge} from "reactstrap";
import {translate} from 'services/translationService';
import "./style.scss"

const cargoOptionsColors = {
  'Cooler': 'secondary',
  'Lift': 'primary',
  'Edscha': 'warning',
  'Side Loading': 'success',
  'Can Laid Different Cargo On This': 'light',
  'Forklift': 'info',
  'Pallet Truck': 'dark',
  'ADR': 'danger',
}


const CargoOptionBadge = ({cargoOption}) => {

  return (
    <Badge color={cargoOptionsColors[cargoOption]} className="cargo-option-badge">
      {translate(cargoOption)}
    </Badge>
  )
}

export default CargoOptionBadge;