import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {history} from "../../../helpers";
import {priceApiService} from "../../../services/priceApiService";

export const FETCH_TOLL_REQUEST = 'FETCH_TOLL_REQUEST';
export const FETCH_TOLL_SUCCESS = 'FETCH_TOLL_SUCCESS';
export const FETCH_TOLL_FAILURE = 'FETCH_TOLL_FAILURE';

export function getTollSurcharges() {
    return dispatch => {
        dispatch(request({}));
        priceApiService.getSurchargesData('toll')
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                history.push('/dashboard');
            });
    };

    function request(data) { return { type: FETCH_TOLL_REQUEST, data } }
    function success(data) { return { type: FETCH_TOLL_SUCCESS, data } }
    function failure(error) { return { type: FETCH_TOLL_FAILURE, error } }
}
