import React from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import {translate} from 'services/translationService';
import {Form, Input, Modal, ModalBody, ModalHeader, Table} from "reactstrap";
import BodyTypeBadge from "../BodyTypeBadge";
import ModalFooter from "reactstrap/es/ModalFooter";
import MediaQuery from "react-responsive";

class TruckTrailersModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            weightFormOpen: false,
            trailer: {},
            maxWeight: 0,
            linkingEnabled: true
        };
        this.toggle = this.toggle.bind(this);
        this.toggleWeightForm = this.toggleWeightForm.bind(this);
        this.handleWeightChange = this.handleWeightChange.bind(this);
        this.confirmConnect = this.confirmConnect.bind(this);
    }

    handleWeightChange(event) {
        const enteredWeight =  event.target.value.replace(/([^0-9]+)/gi, '');
        this.setState({
            maxWeight: parseInt(enteredWeight),
            linkingEnabled: enteredWeight > 0
        })
    }

    chooseTrailer(selectedTrailer) {
        this.setState({
            trailer: selectedTrailer,
            maxWeight: selectedTrailer.container.maxWeight,
        });
        this.toggleWeightForm();
    }

    toggle() {
        this.props.toggleTrailersModal();
    }

    toggleWeightForm() {
        this.setState({
            weightFormOpen: !this.state.weightFormOpen
        });
    }

    confirmConnect() {
        this.toggleWeightForm();
        this.props.connectToSet(this.state.trailer.id, this.state.maxWeight);
    }

    renderTruckTrailerRow(truckTrailer, i) {
        return(
            <tr key={i}>
                <td>{truckTrailer.brand.name} {truckTrailer.model.name} </td>
                <td>{truckTrailer.registrationNumber}</td>
                <MediaQuery query='(min-device-width: 860px)'>
                    <td>{truckTrailer.productionYear}</td>
                    <td>{<BodyTypeBadge specification={truckTrailer.container}/>}</td>
                    <td>{truckTrailer.container.length} / {truckTrailer.container.width} / {truckTrailer.container.height}</td>
                </MediaQuery>
                <td>
                    <Button onClick={this.chooseTrailer.bind(this, truckTrailer)}>
                        {translate("Connect")}
                    </Button>
                </td>
            </tr>
        )
    }

    render() {
        const {isFetching, error, trailers} = this.props;
        return (
            <div className="trailers-list">
                <Modal isOpen={this.props.showModal} toggle={this.toggle} size="lg">
                    <ModalHeader toggle={this.toggle}>{translate("Connect solo truck with truck trailer")}</ModalHeader>
                    <ModalBody>
                        { (!isFetching && !error && trailers.length) ?
                        <Table>
                            <thead>
                            <tr>
                                <th>{translate('Brand / Model')}</th>
                                <th>{translate('Registration')}</th>
                                <MediaQuery query='(min-device-width: 860px)'>
                                    <th>{translate('Production year')}</th>
                                    <th>{translate('Body type')}</th>
                                    <th>{translate('Dimensions')}</th>
                                </MediaQuery>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                trailers.map((truckTrailer, i) => {
                                    return this.renderTruckTrailerRow(truckTrailer, i);
                                })
                            }
                            </tbody>
                        </Table>
                            : <p>{translate("There are no available trailers right now")}</p> }
                    </ModalBody>
                </Modal>

                <Modal id="trailer-weight" isOpen={this.state.weightFormOpen} toggle={this.toggleWeightForm}>
                    <ModalHeader toggle={this.toggleWeightForm}>
                        {translate("Enter max weight for this set")}
                    </ModalHeader>
                    <ModalBody>
                        <Form inline className="text-center">
                            <Input type="text" name="maxWeigh" value={this.state.maxWeight} onChange={this.handleWeightChange}/>
                            <span> kg</span>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.confirmConnect}
                                disabled={!this.state.linkingEnabled} >
                            {translate("OK")} </Button>
                        <Button onClick={this.toggleWeightForm}>{translate("Cancel")}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}


export default TruckTrailersModal

