import React from "react";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import "./style.scss";

class Paginator extends React.Component {

    constructor(props) {
        super(props);
        this.pageChangeHandler = this.props.pageChangeHandler;
        this.perPageHandler = this.props.perPageHandler;
        this.handlePageChangeClick = this.handlePageChangeClick.bind(this);
        this.handlePerPageChange = this.handlePerPageChange.bind(this);
    }

    handlePageChangeClick(e, pageNumber, indexStart) {
        e.preventDefault();
        this.pageChangeHandler(pageNumber, indexStart);
    }

    handlePerPageChange(e) {
        this.perPageHandler(e.target.value);
    }

    getIndexes() {
        let minIndex = this.props.indexStart;
        let maxIndex;
        if (this.props.pagesCount <= this.props.maxPages) {
            maxIndex = this.props.pagesCount
        } else {
            maxIndex = minIndex + this.props.maxPages - 1;
        }
        let paginationIndexes = [];
        let i = minIndex;
        while (i <= maxIndex) {
            paginationIndexes.push(i);
            i++
        }
        return {minIndex, paginationIndexes};
    }

    render() {
        const {pagesCount, currentPage, maxPages, perPage} = this.props;
        const {minIndex, paginationIndexes} = this.getIndexes();
        const possiblePerPageValues = [3, 5, 10, 15, 25];

        return (
            <div className="Paginator">

                    <Pagination aria-label="OrderList pagination">
                        <PaginationItem disabled={currentPage <= 1}>
                            <PaginationLink
                                onClick={e => this.handlePageChangeClick(e, 1, 1)}
                                first
                                href="#"
                            />
                        </PaginationItem>
                        <PaginationItem disabled={currentPage <= 1}>
                            <PaginationLink
                                onClick={e => this.handlePageChangeClick(e, currentPage - 1,
                                    currentPage === minIndex ? minIndex - 1 : minIndex)}
                                previous
                                href="#"
                            />
                        </PaginationItem>
                        {minIndex > 1 &&
                        <PaginationItem disabled>
                            <PaginationLink href="#">
                                ...
                            </PaginationLink>
                        </PaginationItem>
                        }
                        {paginationIndexes.map((page, i) =>
                            <PaginationItem active={page === currentPage} key={i}>
                                <PaginationLink onClick={e => this.handlePageChangeClick(e, page, minIndex)} href="#">
                                    {page}
                                </PaginationLink>
                            </PaginationItem>
                        )}
                        {pagesCount > minIndex + maxPages - 1 &&
                        <PaginationItem disabled>
                            <PaginationLink href="#">
                                ...
                            </PaginationLink>
                        </PaginationItem>
                        }
                        <PaginationItem disabled={currentPage === pagesCount}>
                            <PaginationLink
                                onClick={e => this.handlePageChangeClick(e, currentPage + 1,
                                    currentPage === minIndex + maxPages - 1 ? minIndex + 1 : minIndex)}
                                next
                                href="#"
                            />
                        </PaginationItem>
                        <PaginationItem disabled={currentPage === pagesCount}>
                            <PaginationLink
                                onClick={e => this.handlePageChangeClick(e, pagesCount,
                                    maxPages>pagesCount ? 1 : pagesCount - maxPages + 1)}
                                last
                                href="#"
                            />
                        </PaginationItem>
                    </Pagination>
                    <div className="elements-per-page">
                        View
                        <select name="perPage" onChange={e => this.handlePerPageChange(e)} value={perPage}>
                            {
                                possiblePerPageValues.map( (perPageValue,i) => {
                                    return (<option value={perPageValue} key={i}>{perPageValue}</option>)
                                })
                            }
                        </select>
                         elements per page
                    </div>

            </div>
        );
    }
}

export default Paginator;
