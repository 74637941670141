import React, {Component} from 'react';
import {translate} from "../../../services/translationService";
import routeService from "../../../services/routeService";
import Badge from "reactstrap/es/Badge";
import {Table} from "reactstrap";
import {Link} from "react-router-dom";
import Button from "reactstrap/es/Button";
import MediaQuery from 'react-responsive';


class RouteAside extends Component {

    constructor(props) {
        super(props);
        this.renderPointInfo = this.renderPointInfo.bind(this);
        this.getOperationLabel = this.getOperationLabel.bind(this);
        this.asideRoutePoints = [];
    }

    componentDidMount() {
        this.asideRoutePoints  = routeService.getAsideListItems(this.props.route);
    }

    getOperationLabel(operationType) {
        const badgeText = translate(operationType);
        switch (operationType) {
            case "Pickup":
            return <Badge color="success">{badgeText}</Badge>;
            case "Midpoint":
            return <Badge color="light">{badgeText}</Badge>;
            case "Delivery":
            default:
            return <Badge color="danger">{badgeText}</Badge>;
        }
    }

    renderPointInfo(routePoint, i) {
        const sideMenuToggler = this.props.sideMenuToggler;
        const {currentOrderId} = this.props;
        return routePoint.type === "Midpoint" ?
            <div className="route-midpoint-box" key={i}>
                <div className="route-midpoint" key={i*10}>
                    <div className={routePoint.status === routeService.PointStatus.default ? "rps-circle" : "rps-circle-visited"}>{routePoint.order}</div>
                    <div>{this.getOperationLabel(routePoint.type)}</div>
                    <div>{" "}</div>
                </div>
            </div> :
            <div className="route-point-set" key={i} onClick={()=>{this.props.toggleMenu(i+1)}}>
            <div className="route-point" key={i*10}>
                <div className={routePoint.status === routeService.PointStatus.default ? "rps-circle" : "rps-circle-visited"}>{routePoint.order}</div>
                <div>{routePoint.shipmentNumber}</div>
                <div>{this.getOperationLabel(routePoint.type)}</div>
            </div>
            {sideMenuToggler[i+1] && <div className="route-point-additional">
                <Table hover>
                    <tbody>
                    <tr>
                        <th colSpan={2} className={"asideButtonContainer"}>
                            <a href={routeService.getNavigationLink(routePoint)} target="_blank" rel="noopener noreferrer">
                                <Button color="primary" size="sm" className="asideButton" onClick={(e)=>e.stopPropagation()}>
                                    <i className="nc-icon nc-map-big mr-1"></i>{translate("Navigate")}
                                </Button>
                            </a>
                        </th>
                        <th colSpan={2} className={"asideButtonContainer"}>
                            <MediaQuery query='(min-device-width: 860px)'>
                            {routePoint.hasOwnProperty('orderId') &&
                            <Link to={`/orders/${routePoint.orderId}`}  target="_blank" rel="noopener noreferrer">
                                <Button color="secondary" size="sm" className="asideButton" onClick={(e)=>e.stopPropagation()}>
                                    <i className="nc-icon nc-zoom-split mr-1"></i>
                                    {translate("Show order")}
                                </Button>
                            </Link>
                            }
                            </MediaQuery>
                            <MediaQuery query='(max-device-width: 859px)'>
                                {(routePoint.hasOwnProperty('orderId') && (routePoint.orderId !== currentOrderId)) && <Link to={`/orders/${routePoint.orderId}`} className="button-link no-link">
                                    <i className="nc-icon nc-zoom-split mr-1"></i>
                                    {translate("Show order")}
                                </Link>
                                }
                            </MediaQuery>
                        </th>
                    </tr>
                    <tr>
                        <th >{translate("Company")}</th>
                        <td colSpan={3}>{routePoint.addressName}</td>
                    </tr>
                    <tr>
                        <th>{translate("Country")}</th>
                        <td>{routePoint.country}</td>
                        <th>{translate("Post Code")}</th>
                        <td>{routePoint.postCode}</td>
                    </tr>
                    <tr>
                        <th>{translate("City")}</th>
                        <td>{routePoint.city}</td>
                        <th>{translate("Address")}</th>
                        <td>{routePoint.address}</td>
                    </tr>
                    <tr>
                        <th>{translate("Date from")}</th>
                        <td>{routePoint.dateOpen}</td>
                        <th>{translate("Date to")}</th>
                        <td>{routePoint.dateClose}</td>
                    </tr>
                    <tr>
                        <th>{translate("Time from")}</th>
                        <td>{routePoint.timeOpen}</td>
                        <th>{translate("Time to")}</th>
                        <td>{routePoint.timeClose}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>}
        </div>
    }

    render() {
        const asideRoutePoints = this.asideRoutePoints;
        return (
            <div className="aside-container">
                {asideRoutePoints.map((routePoint,i)=>this.renderPointInfo(routePoint, i))}
            </div>
        );
    }
}

export default RouteAside