import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, CardBody, CardFooter, Table, CardTitle, Button} from "reactstrap";
import MediaQuery from "react-responsive";
import OrderStatusForm from './OrderStatusForm';
import {getLifecycles} from "redux/actions";
import {translate} from 'services/translationService';
import {stringHelper} from "helpers/stringHelper";
import './OrderStatus.scss';
import Loader from "../../../components/Loader/Loader";
import AddRemarkModal from "../ShipmentRemarks/AddRemarkModal";

class OrderStatus extends Component {

    constructor(props) {
        super(props);
        this.order = this.props.order;
        this.state = {
            showHistory: false,
            lifeCycleFormVisible: false,
            remarkFormVisible: false,
            visibleHistoryDetailsIndex: null
        };
        this.toggleHistory = this.toggleHistory.bind(this);
        this.toggleLifecycleForm = this.toggleLifecycleForm.bind(this);
        this.toggleRemarkForm = this.toggleRemarkForm.bind(this);
        this.showHistoryDetails = this.showHistoryDetails.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getLifecycles(this.order));
    }

    toggleHistory(e) {
        e.preventDefault();
        this.setState({
            showHistory: !this.state.showHistory
        });
    }

    toggleLifecycleForm() {
        this.setState({
            lifeCycleFormVisible: !this.state.lifeCycleFormVisible
        });
    }
    toggleRemarkForm() {
        this.setState({
            remarkFormVisible: !this.state.remarkFormVisible
        });
    }

    showHistoryDetails = (index) => {
        this.setState({
            visibleHistoryDetailsIndex: index
        })
    }

    renderHistory() {
        let lifeCyclesHistory = [...this.props.lifeCycles];
        if (!this.state.showHistory) {
            lifeCyclesHistory = lifeCyclesHistory.slice(0, 1);
        }
        return lifeCyclesHistory.map((lifeCycle, index) => {
            return (
                <tbody key={index} style={{backgroundColor: lifeCycle.shipmentStatus.color}}>
                <tr onClick={() => this.showHistoryDetails(index)}>
                    <td data-title="Date">
                        <strong>{lifeCycle.dateAdd}</strong>
                    </td>
                    <td data-title="Status">
                        <strong>{lifeCycle.shipmentStatus.carrierCode}</strong> - {translate(lifeCycle.shipmentStatus.text)}
                    </td>
                    <td data-title="Creator">
                        {stringHelper.getLifeCycleCreatorName(lifeCycle)}
                    </td>
                    <MediaQuery query='(min-device-width: 860px)'>
                        <td data-title="Signed by">
                            {lifeCycle.signedBy}
                        </td>
                        <td data-title="Comment">
                            {lifeCycle.comment}
                        </td>
                    </MediaQuery>
                    <MediaQuery query='(max-device-width: 860px)'>
                        <td>
                            <i className="nc-icon nc-minimal-down" />
                        </td>
                    </MediaQuery>
                </tr>
                <MediaQuery query='(max-device-width: 860px)'>
                    <tr style={{display: this.state.visibleHistoryDetailsIndex === index ? '' : 'none'}}>
                        <td colSpan="4">{translate('Signed by')}: {lifeCycle.signedBy}</td>
                    </tr>
                    <tr style={{display: this.state.visibleHistoryDetailsIndex === index ? '' : 'none'}}>
                        <td colSpan="4">{translate('Comment')}: {lifeCycle.comment}</td>
                    </tr>
                </MediaQuery>
                </tbody>
            )
        });
    }

    render() {
        const {lifeCycles, isFetching} = this.props;
        const latestLifeCycle = lifeCycles[0];
        return (!isFetching ?

                <Card>
                    <CardTitle>
                        <div className={"separator"}>{translate('Shipment Status')}</div>
                    </CardTitle>
                    <CardBody>
                        {latestLifeCycle ?
                            <Table className="responsive-font">
                                <thead>
                                <tr>
                                    <th>{translate("Date")}</th>
                                    <th>{translate("Status")}</th>
                                    <th>{translate("Creator")}</th>
                                    <MediaQuery query='(min-device-width: 860px)'>
                                        <th>{translate("Signed by")}</th>
                                        <th>{translate("Comment")}</th>
                                    </MediaQuery>
                                    <MediaQuery query='(max-device-width: 860px)'>
                                        <th></th>
                                    </MediaQuery>
                                </tr>
                                </thead>
                                {this.renderHistory()}

                            </Table> :
                            <div className="text-center">
                                <p>{translate("Shipment has no statuses")}</p>
                            </div>
                        }

                    </CardBody>

                    <CardFooter className="text-center status-footer">
                        {lifeCycles.length > 1 && <Button outline color="info" type="button" className={'mr-1'}
                                                          onClick={this.toggleHistory}>{!this.state.showHistory ?
                            translate("Show all") : translate("Hide")
                        } </Button>}
                        {this.props.canEdit && <div className="status-edit-buttons">
                            <Button color="primary" className={'mr-1 pull-left'}
                                    onClick={this.toggleLifecycleForm}>{translate("Add status")}</Button>
                            <Button color="secondary"className={'pull-right'}
                                    onClick={this.toggleRemarkForm}>{translate("Add remark")}</Button>
                        </div>}
                    </CardFooter>
                    <OrderStatusForm
                        showModal={this.state.lifeCycleFormVisible}
                        toggleLifecycleForm={this.toggleLifecycleForm}
                        order={this.order}
                    />
                    <AddRemarkModal
                        showModal={this.state.remarkFormVisible}
                        toggleModal={this.toggleRemarkForm}
                        lifeCycles={this.props.lifeCycles}
                        packages={this.order.shipment.packages}
                        orderId={this.order.id}
                    />
                </Card>
                :
                <Loader />
        );
    }
}

OrderStatus.defaultProps = {
    lifeCycles: {},
    isFetching: null,
    error: false,
};

function mapStateToProps(state) {
    return {
        lifeCycles: state.lifeCyclesReducer.lifeCycles,
        isFetching: state.lifeCyclesReducer.isFetching,
        error: state.lifeCyclesReducer.error,
    };
}

export default connect(
    mapStateToProps,
)(OrderStatus);
