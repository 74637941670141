import { apiService } from "services/apiService";
import {alertActions} from "redux/actions";
import { translate } from 'services/translationService';
import getErrorText from "../../../services/errorTextService";
import {history} from 'helpers';
import {updateSyncErrors} from "redux-form";
import {createApiSyncError} from "../../../helpers/ApiSyncErrors";


export const CREATE_CAR_REQUEST = 'CREATE_CAR_REQUEST';
export const CREATE_CAR_SUCCESS = 'CREATE_CAR_SUCCESS';
export const CREATE_CAR_FAILURE = 'CREATE_CAR_FAILURE';

export function createCar(car) {
    return dispatch => {
        dispatch(request());
        apiService.post('/api/vehicles', car)
            .then(
                response => {
                    dispatch(success(response.data));
                    dispatch(alertActions.success(translate('Car successfully created!')));
                    history.push('/cars');
                })
            .catch(errorMessage => {
                    dispatch(updateSyncErrors('createCarForm',createApiSyncError(errorMessage)));
                    dispatch(failure(getErrorText(errorMessage)));
                    dispatch(alertActions.error(getErrorText(errorMessage)));
                }
            )
    };

    function request() { return { type: CREATE_CAR_REQUEST,  } }
    function success(data) { return { type: CREATE_CAR_SUCCESS, data } }
    function failure(error) { return { type: CREATE_CAR_FAILURE, error } }
}
