import {change, Field, Form, reduxForm} from "redux-form";
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import React from "react";
import {translate} from "../../../../services/translationService";
import {countriesForSelect} from "../../../../helpers/countries"
import FieldSelect from "../../../../components/FormFields/FieldSelect/FieldSelect";
import {Button, Col, Row} from "reactstrap";
import {priceApiService} from "../../../../services/priceApiService";
import {alertActions} from "../../../../redux/actions";
import Loader from "../../../../components/Loader/Loader";

class VehicleLocationForm extends React.Component {

    constructor(props) {
        super(props);
        this.checkLocation = this.checkLocation.bind(this);
        this.renderLocationButton = this.renderLocationButton.bind(this);
        this.state = {
            checkingLocation: false,
        }
    }

    checkLocation(formData) {
        this.setState({
            checkingLocation: true
        });
        const {dispatch, setLocation} = this.props;
        priceApiService.getLocation(formData)
            .then((apiServiceResponse) => {
                if (apiServiceResponse.data.length > 0) {
                    const mapData = apiServiceResponse.data[0];
                    setLocation(mapData.latitude.toString(), mapData.longitude.toString());
                    dispatch(change('vehicleLocationForm', 'postCode', mapData.postCode));
                    dispatch(change('vehicleLocationForm', 'city', mapData.city));
                    dispatch(change('vehicleLocationForm', 'address', mapData.streetLine1));
                    dispatch(change('vehicleLocationForm', 'country', mapData.countryCode));
                    this.setState({
                        checkingLocation: false,
                    });
                } else {
                    dispatch(change('vehicleLocationForm', 'postCode', "?"));
                    dispatch(change('vehicleLocationForm', 'city', "?"));
                    dispatch(change('vehicleLocationForm', 'address', "?"));
                    setLocation(null, null);
                    this.setState({
                        checkingLocation: false,
                    });
                }

            })
            .catch((apiError) => {
                console.log(apiError);
                dispatch(alertActions.error(translate('It is not possible to check location now, try later')));
                this.setState({
                    checkingLocation: false,
                });
            });
    }

    renderLocationButton() {
        const {checkingLocation} = this.state;
        if (checkingLocation) {
            return <Loader/>
        }
        return <Button color="primary" size={"sm"}>
            {translate("Check")}
        </Button>
    }

    render() {
        return <Form onSubmit={this.props.handleSubmit(this.checkLocation)}>
            <Row>
                <Col md={6}>
                    <Field
                        inputId="name"
                        name="name"
                        type="text"
                        label={translate("Company name")}
                        component={FieldInput}
                        placeholder=""
                    />
                    <Field
                        inputId="address"
                        name="address"
                        type="text"
                        label={translate("Street")}
                        component={FieldInput}
                        placeholder=""
                    />
                    <Field
                        inputId="city"
                        name="city"
                        type="text"
                        label={translate("City")}
                        component={FieldInput}
                        placeholder=""
                    />
                    <Field
                        inputId="postCode"
                        name="postCode"
                        type="text"
                        label={translate("Post Code")}
                        component={FieldInput}
                        placeholder=""
                    />
                    <Field
                        inputId="country"
                        name="country"
                        options={countriesForSelect(this.props.countries)}
                        label={translate("Country")}
                        component={FieldSelect}
                        props={{emptyValue: false}}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6} className={"col-google"}>
                    {this.renderLocationButton()}
                </Col>
            </Row>
        </Form>
    }
}

VehicleLocationForm = reduxForm({
    form: 'vehicleLocationForm',
    //validate: LocationValidation,
    enableReinitialize: true
})(VehicleLocationForm);

export default VehicleLocationForm;
