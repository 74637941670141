import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import Loader from "../../components/Loader/Loader";
import {translate} from "../../services/translationService";
import classnames from "classnames";
import {getInquiry} from "../../redux/actions/inquiries/inquiry.actions";
import InquiryData from "./InquiryData";
import "./Inquiry.scss";
import InquiryOffer from "./InquiryOffer";
import AddOfferModal from "./AddInquiryOffer/AddOfferModal";
import HelperButton from "../../components/Helper/HelperButton";

class Inquiry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            error: false,
            activeTab: '1',
            showOfferModal: false
        };
        this.toggleShowOfferModal = this.toggleShowOfferModal.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
    }

    componentDidMount() {
        const {dispatch} = this.props;
        const inquiryId = window.location.hash.match("(\\d+)")[0];
        dispatch(getInquiry(inquiryId));
    }

    toggleTab(tab) {
        this.setState({
            activeTab: tab,
        });
    }

    toggleShowOfferModal() {
        this.setState({
            showOfferModal: !this.state.showOfferModal,
        });
    }

    render() {
        const {isFetching, inquiry, error} = this.props;

        if (isFetching || error){
            return  <Loader />
        }
        const {activeTab} = this.state;
        const offerInitialValues = {
            pickupDate: inquiry.shipmentData.pickupDate,
            deliveryDate: inquiry.shipmentData.deliveryDate
        }
        return (
            <div className="content">
                { !inquiry.hasOwnProperty('offer') &&
                <Row>
                    <Col md={12}>
                        <Button
                            color="primary"
                            className="button-with-icon"
                            onClick={this.toggleShowOfferModal}
                        >
                            <i className="nc-icon nc-simple-add"/>
                            {translate('Create Quote')}
                        </Button>
                    </Col>
                </Row>
                }
                <Row>
                    <Col md={12} className="mt-3">
                        <Nav tabs className={"inquiry-tabs"}>
                            <NavItem>
                                <NavLink
                                    className={classnames({active: activeTab === '1'})}
                                    onClick={() => { this.toggleTab('1') }}
                                >
                                    {translate('Inquiry')}
                                </NavLink>
                            </NavItem>

                            {inquiry.hasOwnProperty('offer') &&
                            <NavItem>
                                <NavLink
                                  className={classnames({active: activeTab === '2'})}
                                  onClick={() => {
                                      this.toggleTab('2');
                                  }}
                                >
                                    {translate('Offer')}
                                </NavLink>
                            </NavItem>
                            }
                         <HelperButton helperId="carrier-inquiry-details" style={{marginRight:'5px'}} />
                         </Nav>
                    </Col>
                </Row>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <InquiryData inquiryData={inquiry} />
                    </TabPane>

                    <TabPane tabId="2">
                        {inquiry.hasOwnProperty('offer') && <InquiryOffer inquiryData={inquiry} />}
                    </TabPane>
                </TabContent>

                <AddOfferModal
                    show={this.state.showOfferModal}
                    toggle={this.toggleShowOfferModal}
                    initialValues={offerInitialValues}
                    inquiry={inquiry}
                    />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isFetching: state.inquiryReducer.isFetching,
    error: state.inquiryReducer.error,
    inquiry: state.inquiryReducer.inquiry,
});

export default connect(mapStateToProps)(Inquiry);
