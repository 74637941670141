import React from "react";
import { Button, Col, Nav, NavItem, NavLink, TabContent, TabPane, Row, ModalHeader, ModalBody, ModalFooter, Modal } from "reactstrap";
import classnames from "classnames";
import {translate} from 'services/translationService';
import {Link} from "react-router-dom";
import Loader from "components/Loader/Loader";
import CarSets from "./CarSets";
import SoloTrucks from "./SoloTrucks";
import TruckTrailers from "./TruckTrailers";
import './style.scss'
import {apiService} from "../../services/apiService";
import {alertActions, getTruckTrailers, getCarSets, getSoloTrucks} from "../../redux/actions";
import getErrorText from "../../services/errorTextService";
import {connect} from "react-redux";
import Drivers from "./Drivers";
import HelperButton from "../../components/Helper/HelperButton";


class Cars extends React.Component {

    constructor(props) {
        super(props);
        let initialActiveTab = localStorage.getItem('carFleetTab');
        if (initialActiveTab == null) {
            localStorage.setItem('carFleetTab','1');
            initialActiveTab = '1';
        }
        this.state = {
            activeTab: initialActiveTab,
            carToDelete: {},
            showDeleteModal: false
        };
        this.toggleTab = this.toggleTab.bind(this);
        this.deleteVehicle = this.deleteVehicle.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    toggleTab(tab) {
        this.setState({
            activeTab: tab,
        });
        localStorage.setItem('carFleetTab',tab);
    }

    deleteVehicle() {
        const {dispatch} = this.props;
        const vehicle = this.state.carToDelete;
        let endpoint = "";
        if (vehicle.type === 'SOLO_TRUCK' || vehicle.type === 'TRUCK_TRAILER') {
            endpoint = 'api/vehicles/';
        } else {
            endpoint = 'api/vehicles/sets/'
        }
        apiService.apiDelete(`${endpoint}${vehicle.id}`)
            .then(() => {
                dispatch(alertActions.success(translate("Vehicle deleted successfully")));
                this.toggleDeleteModal(null);
                this.getNewList(vehicle);
            })
            .catch((apiError) => {
                dispatch(alertActions.error(getErrorText(apiError)));
                this.toggleDeleteModal(null);
            });
        this.setState({
            carToDelete: {},
        })
    }

    onDelete(vehicle) {
        this.setState({
            carToDelete: vehicle
        });
        this.toggleDeleteModal();
    }

    getNewList(vehicle) {
        switch(vehicle.type) {
            case "SOLO_TRUCK":
                this.props.dispatch(getSoloTrucks());
                break;
            case "TRUCK_TRAILER":
                this.props.dispatch(getTruckTrailers());
                break;
            default:
                this.props.dispatch(getCarSets());
                break;
        }
    }

    toggleDeleteModal() {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
        });
    }

    render() {
        const {activeTab, carToDelete} = this.state;
        const selectedCarHasDrivers = carToDelete.car  && carToDelete.car.drivers.length > 0;

        if (this.props.isFetching) {
            return (
                <div className="content">
                    <Loader/>
                </div>
            )
        }
        return (
            <div className="content">
                <Row>
                    <Col md={12}>
                        <Link to="/car/new">
                            <Button color="primary" className="button-with-icon">
                                <i className="nc-icon nc-simple-add"/>
                                {translate('Add new car')}
                            </Button>
                        </Link>
                    </Col>
                </Row>


                <Row>
                    <Col md={12} className="mt-3">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({active: activeTab === '1'})}
                                    onClick={() => {
                                        this.toggleTab('1');
                                    }}
                                >
                                    {translate('Car / Set')}
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({active: activeTab === '2'})}
                                    onClick={() => {
                                        this.toggleTab('2');
                                    }}
                                >
                                    {translate('Solo Truck')}
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({active: activeTab === '3'})}
                                    onClick={() => {
                                        this.toggleTab('3');
                                    }}
                                >
                                    {translate('Truck Trailer')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({active: activeTab === '4'})}
                                    onClick={() => {
                                        this.toggleTab('4');
                                    }}
                                >
                                    {translate('Drivers')}
                                </NavLink>
                            </NavItem>
                            <HelperButton helperId={"carrier-cars-fleet-list"} />
                        </Nav>
                    </Col>
                </Row>


                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <CarSets
                            onDelete={this.onDelete}
                        />
                    </TabPane>

                    <TabPane tabId="2">
                        <SoloTrucks
                            onDelete={this.onDelete}
                        />
                    </TabPane>

                    <TabPane tabId="3">
                        <TruckTrailers
                            onDelete={this.onDelete}
                        />
                    </TabPane>

                    <TabPane tabId="4">
                        <Drivers
                            onDelete={this.onDelete}
                        />
                    </TabPane>
                </TabContent>


                <Modal isOpen={this.state.showDeleteModal} toggle={this.toggle} className="delete-car-modal">
                    <ModalHeader
                        toggle={this.toggleDeleteModal}>{translate("Delete vehicle")}</ModalHeader>
                    <ModalBody>
                        {translate('Are you sure you want o delete this vehicle?')}
                        {selectedCarHasDrivers && <p className={"delete-car-warning"}>
                            <i className="nc-icon nc-alert-circle-i" />
                            {translate('This vehicle has drivers assigned to it. Drivers will be unlinked after deleting vehicle.')}
                        </p>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary"
                                onClick={() => this.toggleDeleteModal(null)}>{translate("Cancel")}</Button>
                        <Button color="danger"
                                onClick={this.deleteVehicle}>{translate("Delete")}</Button>{' '}
                    </ModalFooter>
                </Modal>

            </div>
        )
    }
}


export default connect()(Cars);
