import getErrorText from "../../../services/errorTextService";
import { apiService } from "services/apiService";
import {alertActions} from "redux/actions";
import {getInquiry} from './inquiry.actions'

export const CREATE_MESSAGE_REQUEST = 'CREATE_MESSAGE_REQUEST';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_FAILURE = 'CREATE_MESSAGE_FAILURE';

export function createInquiryOfferMessage(inquiryId, offerId, message) {
  return dispatch => {
    dispatch(request());
    apiService.post(`/api/inquiries/offer/${offerId}/messages`, message)
      .then(
        response => {
          dispatch(success(response.data));
          dispatch(getInquiry(inquiryId))
        })
      .catch(errorMessage => {
          dispatch(failure(getErrorText(errorMessage)));
          dispatch(alertActions.error(getErrorText(errorMessage)));
        }
      )
  };

  function request() { return { type: CREATE_MESSAGE_REQUEST } }
  function success(data) { return { type: CREATE_MESSAGE_SUCCESS, data } }
  function failure(error) { return { type: CREATE_MESSAGE_FAILURE, error } }
}
