import React from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import {translate} from 'services/translationService';
import {Modal, ModalBody, ModalHeader, Table} from "reactstrap";
import RoleBadge from "../../Users/RoleBadge";
import getErrorText from "../../../services/errorTextService";
import {apiService} from "../../../services/apiService";
import {connect} from "react-redux";
import {alertActions, getCarSet, getCarSets, getSoloTrucks, getVehicle} from "../../../redux/actions";

class AssignDriverModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            driversList: [],
            isFetching: true,
            error: false,
            selectedDrivers: []
        };
        this.toggle = this.toggle.bind(this);
        this.connectToCar = this.connectToCar.bind(this);
        this.removeFromCar = this.removeFromCar.bind(this);
        this.renderDriverRow = this.renderDriverRow.bind(this);
        this.renderDriverButtons = this.renderDriverButtons.bind(this);
        this.renderControlButtons = this.renderControlButtons.bind(this);
        this.refreshModalData = this.refreshModalData.bind(this);
        this.assignDrivers = this.assignDrivers.bind(this);
    }

    componentDidMount() {
        this.refreshModalData();
    }

    refreshModalData() {
        const {car, drivers} = this.props;
        let carDrivers = [];
        car.drivers.forEach(carDriver =>
            carDrivers.push(drivers.filter(driver => driver.id === carDriver.id)[0])
        );
        let driversList = drivers.filter(driver => !driver.hasOwnProperty('vehicleSet'));
        this.setState({
            selectedDrivers: carDrivers,
            driversList
        });
    }

    assignDrivers(){
        const {car, dispatch, carSet} = this.props;
        const {selectedDrivers} = this.state;
        const requestData = {
            drivers:selectedDrivers
        };
        this.props.toggleModal();
        apiService.put(`/api/vehicles/${car.id}/drivers`,requestData)
            .then(
                response => {
                    dispatch(alertActions.success(translate('Drivers assignment successfully changed')));
                    carSet ? dispatch(getCarSet(carSet.id)):dispatch(getVehicle(car.id));
                    dispatch(getCarSets({
                            page: this.props.carSetsPageParams.page,
                            perPage: this.props.carSetsPageParams.perPage
                        }
                    ));
                    dispatch(getSoloTrucks({
                        page: this.props.soloTruckPageParams.page,
                        perPage: this.props.soloTruckPageParams.perPage
                    }));
                })
            .catch(errorMessage => {
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    }

    toggle() {
        this.refreshModalData();
        this.props.toggleModal();
    }

    connectToCar(driverToConnect) {
        let {selectedDrivers, driversList} = this.state;
        selectedDrivers.push(driverToConnect);
        driversList = driversList.filter(driver => driver.id !== driverToConnect.id);
        this.setState({
            selectedDrivers,
            driversList
        });
    }

    removeFromCar(driverToRemove) {
        let {selectedDrivers, driversList} = this.state;
        selectedDrivers = selectedDrivers.filter(selectedDriver => driverToRemove.id !== selectedDriver.id);
        driversList.push(driverToRemove);
        this.setState({
            selectedDrivers,
            driversList
        });
    }

    renderDriverButtons() {
        const {selectedDrivers} = this.state;
        return selectedDrivers.length > 0 ? <div className="selected-drivers-area">
            {translate("Assigned drivers: ")}
            {selectedDrivers.map((driver, key) =>
                <Button outline color="secondary" key={key} size="sm" title={translate("Remove driver")}
                        onClick={() => this.removeFromCar(driver)}
                >
                    X {driver.name} {driver.surname}
                </Button>)}
        </div> : <p className="no-drivers-info">{translate("No drivers assigned")}</p>
    }

    renderDriverRow(driver, i) {
        const {selectedDrivers} = this.state;
        return (
            <tr key={i}>
                <td>{driver.name} {driver.surname} </td>
                <td><RoleBadge roles={driver.roles}/></td>
                <td>
                    <Button size="sm" onClick={() => {
                        this.connectToCar(driver)
                    }} disabled={selectedDrivers.length > 1}>
                        <i className="nc-icon nc-simple-add"/>{"  "}
                        {translate("Add")}
                    </Button>
                </td>
            </tr>
        )
    }

    renderControlButtons() {
        return <div>
            <Button color="success" onClick={this.assignDrivers}>
                {translate("OK")} </Button>
            <Button onClick={this.toggle}>{translate("Cancel")}</Button>
        </div>
    }

    render() {
        const {isFetching, error, car} = this.props;
        if (isFetching) {
            return ""
        }
        const {driversList} = this.state;
        return (
            <Modal isOpen={this.props.showModal} size="lg" toggle={this.toggle} scrollable={true}
                   className="drivers-list-modal">
                <ModalHeader toggle={this.toggle}>
                    <div className="addDriverModalTitle">
                        <div>{translate("Assign drivers to car: ")}
                            {car ? <strong>{car.brand.name} {car.model.name} </strong> : ""}</div>
                        {this.renderControlButtons()}
                    </div>
                    {this.renderDriverButtons()}
                </ModalHeader>
                <ModalBody>
                    {(!isFetching && !error && driversList.length > 0) ?
                        <Table className="driver-list-table">
                            <thead>
                            <tr>
                                <th>{translate('Name')}</th>
                                <th>{translate('Role')}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                driversList.map((driver, i) => {
                                    return this.renderDriverRow(driver, i);
                                })
                            }
                            </tbody>
                        </Table>
                        : <p>{translate("There are no available drivers right now")}</p>}
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        carSetsPageParams: state.paginationReducer.carSetsPaginator,
        soloTruckPageParams: state.paginationReducer.soloTrucksPaginator,
    };
};

export default connect(mapStateToProps)(AssignDriverModal)

