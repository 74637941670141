
const invoicingDataValidator = {
    dataName: new RegExp('^[a-zA-Z0-9\\s-]+$'),
    bankName: new RegExp('^[a-zA-Z0-9\\s-]+$'),
    iban: new RegExp('^[a-zA-Z0-9\\s-]+$'),
    swift: new RegExp('^[a-zA-Z0-9\\s-]+$'),
    taxNumberUe: new RegExp('^[a-zA-Z0-9-]+$'),
    taxNumberDe: new RegExp('^[a-zA-Z0-9/-]+$'),
    // address: {
    //     phone: new RegExp('([(+]*[0-9]+[()+. -]*)'),
    //     contact: new RegExp('^[a-zA-Z0-9\\s-]+$'),
    //     email: new RegExp('.+@.+\\..+')
    // }
}

const validate = (values) => {
    const errors = {
        address: {
            street: ['', '', '']
        }
    };

    if (!values.dataName) {
        errors.dataName = 'Invoicing data name is required';
    } else if (invoicingDataValidator.dataName.test(values.name) === false) {
        errors.dataName = 'Invoicing data name can contain only letters, numbers and spaces ';
    }
    if (!values.address) {
        errors.address.street = 'Street is required';
        errors.address.city = 'City is required';
        errors.address.postCode = 'Post Code is required';
        errors.address.country = 'Country is required';
    } else {
        if (!values.address.street || !values.address.street[0]) {
            errors.address.street[0] = 'Street is required';
        }
        if (!values.address.city) {
            errors.address.city = 'City is required';
        }
        if (!values.address.postCode) {
            errors.address.postCode = 'Post Code is required';
        }
        if (!values.address.country) {
            errors.address.country = 'Country is required';
        }
    }

    if (!values.bankName){
        errors.bankName = 'Bank name is required';
    }else if (invoicingDataValidator.bankName.test(values.bankName) === false) {
        errors.bankName = 'Bank Name can contain only letters, numbers and spaces ';
    }
    if (!values.iban){
        errors.iban = "Iban number is required"
    } else if (invoicingDataValidator.iban.test(values.iban) === false) {
        errors.iban = 'IBAN number can contain only letters, numbers and spaces ';
    }

    if (!values.taxNumberUe){
        errors.taxNumberUe = "EU VAT-ID number is required"
    } else if (invoicingDataValidator.taxNumberUe.test(values.taxNumberUe) === false) {
        errors.taxNumberUe = 'EU VAT-ID can contain only letters and numbers';
    }

    if (invoicingDataValidator.taxNumberDe.test(values.taxNumberDe) === false) {
        errors.taxNumberDe = "Tax number can contain only letters,numbers,minuses and /";
    }

    return errors;
};


const invoicingDataValidation = (values) => {
    return validate(values);
};

export {invoicingDataValidation};
