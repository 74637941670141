import React from "react";
import {translate} from 'services/translationService';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Button from "reactstrap/es/Button";
import CodeDisplay from "./CodeDisplay";
import Loader from "../../../../components/Loader/Loader";
import {apiService} from "../../../../services/apiService";
import {connect} from "react-redux";
import {alertActions, getCarSet, getUserInfo} from "../../../../redux/actions";
import getErrorText from "../../../../services/errorTextService";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

class TransferTrailerModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timerKey:0,
            code: "------",
            counterTime: 0,
            isLoading: true,
        };
        this.interval = ()=>{}
        this.toggle = this.toggle.bind(this);
        this.getTransferCode = this.getTransferCode.bind(this);
        this.renderClockValue = this.renderClockValue.bind(this);
        this.finishClock = this.finishClock.bind(this);
        this.checkTransfer = this.checkTransfer.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.showModal === false && prevProps.showModal === true) {
            this.setState({
                timerKey: 0,
                code: "------",
                counterTime: 0,
                isLoading: false,
            });
        }
        if (this.props.showModal === true && prevProps.showModal === false) {
            this.getTransferCode();
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    toggle() {
        this.props.toggleModal();
        clearInterval(this.interval);
    }

    getTransferCode() {
        clearInterval(this.interval);
        this.setState({
            isLoading: true
        });
        apiService.get('/api/vehicles/transfer-code').then(
            result => {
                const code = result.data.code;
                this.setState({
                    isLoading: false,
                    code,
                    counterTime:result.data.expiresInSecs,
                    timerKey:this.state.timerKey+1
                });
                this.interval = setInterval(() => {
                    this.checkTransfer(code)
                }, 2000)
            }
        ).catch(error => {
            console.log(error);
            this.props.dispatch(alertActions.error(getErrorText(error)));
            this.setState({
                isLoading: false,
            });
        })
    }

    checkTransfer(code) {
        apiService.get(`/api/vehicles/transfer-code/${code}/status`).then(result=>{
            this.setState({
                code:"------",
                counterTime:0,
                timerKey:this.state.timerKey+1
            });
            const transferText = translate("Transfer of trailer done. Transferred to:") + result.data.name + ' ' + result.data.surname;
            const {dispatch, userId} = this.props;
            dispatch(alertActions.success(transferText));
            dispatch(getUserInfo(userId));
            this.toggle();
        }).catch(error=>{
            if(error.status !== 400){
                console.log(error);
                this.props.dispatch(alertActions.error(getErrorText(error)));
            }
        })

    }

    renderClockValue(remainingTime){
        return <div className={"clock-digits"}>
            {remainingTime === 0 ? "" :
                Math.floor(remainingTime / 60) + ':' + ('0' + Math.floor(remainingTime % 60)).slice(-2)
            }
        </div>
    }

    finishClock(){
        if(this.state.counterTime !== 0){
            this.setState({
                timerKey:0,
                code: "------",
                counterTime: 0,
                isLoading: false,
            });
            this.toggle();
        }
    }

    render() {
        const {code, isLoading, counterTime, timerKey} = this.state;

        return (
            <div className={"transfer-modal"}>
                <Modal isOpen={this.props.showModal} toggle={this.toggle} size="sm">
                    <ModalHeader toggle={this.toggle}>
                        <i className={"nc-icon nc-refresh-69"}/>&nbsp;
                        <span>{translate("Get a trailer transfer code")}</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className={"code-container"}>
                            <Button color={"primary"} className={"generate-btn"} disabled={isLoading}
                                onClick={()=>this.getTransferCode()}>
                                {isLoading ?
                                <Loader/> : translate("Generuj")}</Button>
                            <CodeDisplay code={code}/>
                                <CountdownCircleTimer
                                    key={timerKey}
                                    isPlaying={true}
                                    duration={counterTime}
                                    colors={[['#004777', 0.33], ['#685404', 0.33], ['#A30000']]}
                                    size={window.innerWidth < 768 ? 80 : 100}
                                    onComplete={() => {
                                        this.finishClock();
                                        return [false, 0]
                                    }}
                                >
                                    {({ remainingTime }) => this.renderClockValue(remainingTime) }
                                        </CountdownCircleTimer>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}


export default connect()(TransferTrailerModal)

