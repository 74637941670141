import React from "react";
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';
import {createCar} from "../../../../redux/actions/car/car.actions";
import {translate} from 'services/translationService';
import CreateCarForm from "./CreateCarForm";
import HelperButton from "../../../../components/Helper/HelperButton";


class CreateCar extends React.Component {

    constructor(props) {
        super(props);
        this.carPageSubmitTrigger = this.carPageSubmitTrigger.bind(this);
    }

    carPageSubmitTrigger(formData) {
        let formDataToSend = Object.assign({}, formData);
        formDataToSend.type = formData.vehicleType.toUpperCase();
        delete formDataToSend.filters;
        this.props.dispatch(createCar(formDataToSend));
    }


    render() {
        const values = {
            container: this.props.selectedSpecification,
            vehicleType: this.props.vehicleType,
            registrationNumber: this.props.registrationNumber,
            productionYear: this.props.productionYear,
            brand: this.props.brand,
            model: this.props.model,
            filters: this.props.filters
        }
        return (
            <div className="content">
                <Row>
                    <Col md={12}>
                        <Card className="card-user">
                            <CardHeader>
                                <CardTitle>
                                    <h4><i className="nc-icon nc-simple-add"/> {translate('Add new car to your fleet')}
                                    </h4>
                                    <hr/>
                                </CardTitle>
                                <HelperButton helperId={"carrier-car-create"} />
                            </CardHeader>
                            <CardBody>
                                <div className="outerForm">
                                    <CreateCarForm
                                        onSubmitHandler={this.carPageSubmitTrigger}
                                        initialValues={values}
                                    />
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

CreateCar.defaultProps = {
    selectedSpecification: {}
}

const createCarValueSelector = formValueSelector('createCarForm');

const mapStateToProps = (state) => {
    return {
        selectedSpecification: state.specificationReducer.specification,
        registrationNumber: createCarValueSelector(state, 'registrationNumber'),
        productionYear: createCarValueSelector(state, 'productionYear'),
        brand: createCarValueSelector(state, 'brand'),
        vehicleType: createCarValueSelector(state, 'vehicleType'),
        model: createCarValueSelector(state, 'model'),
        filters: createCarValueSelector(state, 'filters')
    }
}
export default connect(mapStateToProps)(CreateCar);
