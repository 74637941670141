import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {connect} from 'react-redux';
import {getCarrierInfo, updateCarrierInfo} from 'redux/actions/carrier/carrier.actions';
import CarrierForm from "./CarrierForm";
import CarrierDocuments from './Documents/CarrierDocuments';
import {hasProvinces} from "helpers/countries";
import Loader from "components/Loader/Loader";
import "./style.scss";
import loggedUserService from "services/loggedUserService"
import HelperButton from "../../components/Helper/HelperButton";

class Carrier extends React.Component {

    constructor(props) {
        super(props);

        this.carrierPageSubmitTrigger = this.carrierPageSubmitTrigger.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getCarrierInfo());
    }

    carrierPageSubmitTrigger(formData) {
        const {dispatch, countries} = this.props;
        const country = formData.address.country;
        if (!hasProvinces(countries, country)){
            delete formData.address.state_province;
        }
        dispatch(updateCarrierInfo(formData));
    }

    render() {
        const { isFetching, carrier, billingPeriods} = this.props;
        return (
            <div className="content">
                {!isFetching && carrier ? (
                    <Row>
                        <Col>
                            <Card className="card-user">
                                <CardBody>
                                    <CarrierForm
                                        initialValues={carrier}
                                        billingPeriods={billingPeriods}
                                        onSubmitHandler={this.carrierPageSubmitTrigger}
                                    />
                                </CardBody>
                                <HelperButton helperId="carrier-form" />
                            </Card>
                        </Col>
                    </Row>
                    ) : (
                    <Loader />
                    ) }
                {loggedUserService.isManager() ?
                    <Row>
                        <Col>
                            <CarrierDocuments />
                        </Col>
                    </Row> : null }
            </div>
        )
    }
}

Carrier.defaultProps = {
    carrier: {},
    isFetching: null,
    error: false,
    countries:[],
    billingPeriods: []
};

const mapStateToProps = state => ({
    carrier: state.carrierReducer.carrier,
    isFetching: state.carrierReducer.isFetching,
    error: state.carrierReducer.error,
    countries: state.countryReducer.countries,
    billingPeriods: state.billingPeriodReducer.billingPeriods,

});

export default connect(mapStateToProps)(Carrier);
