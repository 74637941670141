import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {connect} from 'react-redux';
import "./style.scss";
import PropTypes from "prop-types";
import {getHelperText} from "../../redux/actions";
import {translate} from "../../services/translationService";
import Loader from "../Loader/Loader";

class HelperButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        }
        this.toggleModal = this.toggleModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    toggleModal() {
        this.setState({modalOpen: !this.state.modalOpen});
    }

    openModal() {
        this.setState({modalOpen: true});
    }

    componentDidMount() {
        const {helperTexts, helperId, dispatch} = this.props;
        if (!helperTexts.hasOwnProperty(helperId)) {
            dispatch(getHelperText(helperId))
        }
    }

    render() {
        const {helperTexts, helperId, isLoading, style} = this.props;
        return <>
            <div className="helper-wrapper" style={ style ? style : {}}>
                <div className="round-helper-button" onClick={this.openModal}>?</div>
            </div>
            <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal} size={"xl"} className={"helper-modal"}>
                <ModalHeader toggle={this.toggleModal}>
                </ModalHeader>
                <ModalBody>
                    {isLoading ? <Loader /> :
                      <div className="helper-content">
                          <div dangerouslySetInnerHTML={{__html: helperTexts[helperId]}}></div>
                          <div>
                              <Button
                                outline
                                color="primary"
                                onClick={this.toggleModal}>
                                  {translate("Close Window")}
                              </Button>
                          </div>
                      </div>
                    }
                </ModalBody>
            </Modal>
        </>
    }
}

HelperButton.propTypes = {
    helperId: PropTypes.string.isRequired,
    style: PropTypes.object
}

const mapStateToProps = state => ({
    helperTexts: state.helperTextReducer.helperTexts,
    isLoading: state.helperTextReducer.isFetching,
});

export default connect(mapStateToProps)(HelperButton);
