import {
    FETCH_CARRIER_DOCUMENTS_REQUEST,
    FETCH_CARRIER_DOCUMENTS_SUCCESS,
    FETCH_CARRIER_DOCUMENTS_FAILURE,
    CREATE_CARRIER_DOCUMENT_REQUEST,
    CREATE_CARRIER_DOCUMENT_SUCCESS,
    CREATE_CARRIER_DOCUMENT_FAILURE,
    DELETE_CARRIER_DOCUMENT_SUCCESS
} from 'redux/actions/carrier';

const initialState = {
    message: '',
    carrierDocuments: [],
    documentTypes: [],
    isFetching: false,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CARRIER_DOCUMENTS_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
            });
        }

        case FETCH_CARRIER_DOCUMENTS_SUCCESS: {
            return Object.assign({}, state, {
                carrierDocuments: action.data.carrierDocuments,
                documentTypes: action.data.documentTypes,
                isFetching: false,
                error: false
            });
        }

        case FETCH_CARRIER_DOCUMENTS_FAILURE:
        case CREATE_CARRIER_DOCUMENT_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }

        case CREATE_CARRIER_DOCUMENT_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
                error: false
            });
        }

        case CREATE_CARRIER_DOCUMENT_SUCCESS: {
            return Object.assign({}, state, {
                carrierDocuments: [...state.carrierDocuments, action.data],
                isFetching: false,
                error: false
            });
        }

        case DELETE_CARRIER_DOCUMENT_SUCCESS: {
            return Object.assign({}, state, {
                carrierDocuments: removeDocument(state.carrierDocuments, action.documentId),
                isFetching: false,
                error: false
            });
        }

        default:
            return state
    }
}

function removeDocument(stateUserDocuments, documentId) {
    return stateUserDocuments.filter((document) => {
        return document.id !== documentId;
    });
}
