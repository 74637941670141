import Loader from "components/Loader/Loader";
import React, {useEffect, useState, Fragment, useMemo} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Alert} from 'reactstrap';
import {get} from "lodash";
import MediaQuery from "react-responsive";

import {InquiryElement} from './InquiryElement'
import {getInquiries} from 'redux/actions/inquiries';
import {translate} from 'services/translationService';
import {paginationActions} from "../../redux/actions";
import Paginator from "components/Paginator/Paginator";
import InquiriesFilter from "./InquiriesFilter";
import queryString from "query-string";
import {useLocation} from "react-router-dom";

export const INQUIRIES_PAGINATOR = 'inquiriesPaginator';

export const InquiriesList = (props) => {

  const {
    fetchInquiries,
    inquiries,
    isFetching,
    error,
    pageParams: {
      indexStart,
      page,
      perPage
    },
    changePage,
    changePerPage,
    type,
    title,
  } = props;

  const [inquiriesList, setInquiriesList] = useState([])

  const location = useLocation()

  const searchParams = queryString.parse(location.search);

  useEffect(() => {
    fetchInquiries(props.pageParams, searchParams)
  }, [props.pageParams])

  useEffect(() => {
    setInquiriesList(get(inquiries, 'data', []));
  }, [inquiries])

  const pagesCount =  useMemo(() => {
    if (get(inquiries, 'meta.total')) {
      return Math.ceil(inquiries.meta.total / inquiries.meta.perPage);
    }
    return 1;
  }, [inquiries.meta])

  if (isFetching) {
    return (
      <Loader/>
    )
  }

  if (error) {
    return (
      <Alert color="danger">
        {translate('Something went wrong!')}
      </Alert>
    )
  }

  return (
    <Fragment>
      <h4>{title}</h4>

      <InquiriesFilter type={type} resetPage={() => changePage(1, 1)} searchParams={searchParams} />

      <div className="inquiries__table">
        <div className="inquiries__header-row">
          <div className="inquiries__column">{translate('Created at')}</div>
          <div className="inquiries__column">{translate('Pickup date')}</div>
          <div className="inquiries__column">{translate('Delivery date')}</div>
          <div className="inquiries__column">{translate('Pickup place')}</div>
          <div className="inquiries__column">{translate('Delivery place')}</div>
          <div className="inquiries__column">{translate('Extra requirements')}</div>
          <div className="inquiries__column">{translate('Coli')}</div>
          <div className="inquiries__column">{translate('Weight')}</div>
          {type === 'closed' &&
            <div className="inquiries__column">{translate('Status')}</div>
          }
          {/*Obsługa nieprzeczytanych wiadomości*/}
          {/*{type !== 'new' &&*/}
          {/*  <div className="inquiries__column">{translate('Messages')}</div>*/}
          {/*}*/}
        </div>
        {inquiriesList.map(inquiry => (
          <InquiryElement key={inquiry.id} inquiry={inquiry} type={type} />
        ))}
      </div>

      {pagesCount > 1 &&
        <MediaQuery minDeviceWidth={860}>
          {(matches) => {
            let maxPages = matches ? 10 : 3;

            return <Paginator
              pageChangeHandler={changePage}
              perPageHandler={changePerPage}
              pagesCount={pagesCount}
              currentPage={page}
              perPage={perPage}
              indexStart={indexStart}
              maxPages={maxPages}
            />
          }}
        </MediaQuery>
      }
    </Fragment>
  )
}

const mapDispatchToProps = (dispatch, props) => ({
    fetchInquiries: (pageParams, searchParams) => dispatch(getInquiries(props.type, {...pageParams, ...searchParams})),
    changePage: (pageNumber, indexStart) => dispatch(paginationActions.pushChangePage(INQUIRIES_PAGINATOR, pageNumber, indexStart)),
    changePerPage: (perPage) => dispatch(paginationActions.changePerPage(INQUIRIES_PAGINATOR, perPage))
})

const mapStateToProps = state => ({
  inquiries: state.inquiriesReducer.inquiries,
  isFetching: state.inquiriesReducer.isFetching,
  error: state.inquiriesReducer.error,
  pageParams: state.paginationReducer.inquiriesPaginator,
});

InquiriesList.propTypes = {
  fetchInquiries: PropTypes.func,
  inquiries: PropTypes.object,
  isFetching: PropTypes.bool,
  error: PropTypes.bool,
  pageParams: PropTypes.shape({
    indexStart: PropTypes.number,
    page: PropTypes.number,
    perPage: PropTypes.number,
  }),
  changePage: PropTypes.func,
  changePerPage: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(InquiriesList)