import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {Field, Form, FormSection, reduxForm} from "redux-form";
import {translate} from "../../services/translationService";
import FieldInput from "../../components/FormFields/FieldInput/FieldInput";
import Address from "../../components/FormSections/Address";
import Button from "reactstrap/es/Button";
import FieldSelect from "../../components/FormFields/FieldSelect/FieldSelect";
import {billingPeriodsForSelect} from "helpers/billingPeriods";
import {translateFormLabels} from "../../helpers/translateFormLabels";
import {invoicingDataValidation} from "./validation";

class InvoicingDataForm extends Component {

    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.labels = translateFormLabels();
    }

    componentWillMount() {
        if (!this.props.initialValues){
            this.props.initialize({ address: {country: 'DE' }});
        }
    }

    onFormSubmit(formData) {
        this.props.onSubmitHandler(formData);
    }

    render() {
        const labels = this.labels;
        return (
                        <Form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
                                        <FormSection name="address">
                                            <h5>{ translate("Carrier name on invoice") }</h5>
                                            <Row>
                                                <Col sm={6}>
                                                    <Field
                                                        name="name"
                                                        label={labels.carrierName}
                                                        component={FieldInput}
                                                    />
                                                </Col>
                                            </Row>
                                            <Address formName={'invoicingDataForm'}/>
                                        </FormSection>
                                        <h5>{ translate("Bank and tax data") }</h5>
                                        <Row>
                                            <Col sm={6}>
                                                <Field
                                                    name="bankName"
                                                    label={labels.carrierBankName}
                                                    component={FieldInput}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Field
                                                    name="iban"
                                                    label={labels.carrierIban}
                                                    component={FieldInput}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <Field
                                                    name="taxNumberUe"
                                                    label={labels.carrierTaxNumberUe}
                                                    component={FieldInput}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Field
                                                    name="taxNumberDe"
                                                    label={labels.carrierTaxNumberDe}
                                                    component={FieldInput}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <Field
                                                    name="isVatPayer"
                                                    options={[
                                                        {id: 1, name: translate("Yes")},
                                                        {id: 0, name: translate("No")}
                                                    ]}
                                                    label={labels.carrierIsVatPayer}
                                                    component={FieldSelect}
                                                    props={{emptyValue: false}}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Field
                                                    name="billingPeriod"
                                                    options={billingPeriodsForSelect(this.props.billingPeriods)}
                                                    label={labels.carrierBillingPeriod}
                                                    component={FieldSelect}
                                                    props={{emptyValue: false}}
                                                />
                                            </Col>
                                        </Row>
                                    <Row>
                                        <div className="update ml-auto mr-auto mt-1">
                                            <Button color="primary" className={"edit-data-button"} style={{minWidth:"80px"}} round="true">
                                                {this.props.buttonContent}
                                            </Button>
                                        </div>
                                    </Row>
                        </Form>
        );
    }
}

export default reduxForm({
    form: 'invoicingDataForm',
    validate: invoicingDataValidation
})(InvoicingDataForm);