import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

import Header from "components/Header/Header.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FirstLoginInfo from "components/FirstLoginInfo/FirstLoginInfo.jsx";
import sidebarRoutes from "routes/sidebar.jsx";
import { connect } from "react-redux";
import dashboardRoutes from "../routes/dashboard";
import Footer from "../components/Footer/Footer";
import OfflineIndicator from "../components/OfflineIndicator/OfflineIndicator";
import {getDocumentTypes, getRemarkCategories} from "../redux/actions";
var ps;

class HomePage extends React.Component {

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    this.props.dispatch(getRemarkCategories());
    this.props.dispatch(getDocumentTypes());
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  render() {

    let allRoutes = sidebarRoutes().concat(dashboardRoutes());

    return (
    <div className="Site-content">
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={sidebarRoutes()}
        />
        <div className="main-panel" ref="mainPanel">
          <OfflineIndicator />
          <Header {...this.props} />
            <FirstLoginInfo />
          <Switch>
            {allRoutes.map((prop, key) => {
              if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              }
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
    );
  }
}

const mapStateToProps = state => ({
});


export default connect(mapStateToProps)(HomePage);

