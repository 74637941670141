import React from "react";
import {Card, CardBody, CardHeader, CardTitle, Col, Row, Table} from "reactstrap";
import {translate} from "../../services/translationService";
import InquiryShipmentBadges from "./InquiryShipmentBadges";
import InquiryOptionsBadge from "./InquiryOptionsBadges";
import InquiryDetailsPackages from "./InquiryDetailsPackages";
import {timeInterval} from 'utils/formatters';
import Map from "../../components/GoogleMap/Map";
import routeService from "../../services/routeService";

class InquiryData extends React.Component {

    constructor(props) {
        super(props);
        this.onMapLoad = this.onMapLoad.bind(this);
        this.getCarInfo = this.getCarInfo.bind(this);
    }

    onMapLoad = (directionsService, directionsRenderer, map) => {
        const {inquiryData} = this.props;
        const {shipmentData} = inquiryData;
        routeService.drawInquiryRoute(shipmentData, directionsService, directionsRenderer);
        if('suggestedVehicleSet' in inquiryData && 'location' in inquiryData.suggestedVehicleSet){
            routeService.addVehicleIcon(map, shipmentData, inquiryData.suggestedVehicleSet.location);
        }
    }

    getCarInfo = (vehicleSet) => {
        let carData = `${vehicleSet.car.brandName} ${vehicleSet.car.modelName} ${vehicleSet.car.registrationNumber}`;
        if ('truckTrailer' in vehicleSet){
            carData += ` | ${vehicleSet.truckTrailer.brandName} ${vehicleSet.truckTrailer.registrationNumber}`
        }
        return carData;
    }

    render() {
        const {inquiryData} = this.props;
        const {shipmentData} = inquiryData;

        return (
            <div className="content">
            <Row>
                <Col md={12} className={"inquiry-column"}>
                    <Card className={"inquiry-card"}>
                        <CardHeader>
                            <CardTitle className={"separator"}>
                                <span>{translate("Inquiry information")}</span>
                            </CardTitle>
                        </CardHeader>
                        <CardBody className={"inquiry-table"}>
                            <Table hover>
                                <tbody>
                                <tr>
                                    <th>{translate("Basic information")}</th>
                                    <td>{'comment' in inquiryData ? inquiryData.comment : "-"}</td>
                                </tr>
                                <tr>
                                    <th>{translate("Pickup")}</th>
                                    <td>{shipmentData.pickupCountry}{" "}{shipmentData.pickupPostCode} |
                                        {" "}{shipmentData.pickupDate} |
                                         <strong>{" "}{timeInterval(shipmentData.pickupTimeOpen, shipmentData.pickupTimeClose)}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{translate("Delivery")}</th>
                                    <td>{shipmentData.deliveryCountry}{" "}{shipmentData.deliveryPostCode} |
                                        {" "}{shipmentData.deliveryDate} |
                                        <strong>{" "}{timeInterval(shipmentData.deliveryTimeOpen, shipmentData.deliveryTimeClose)}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{translate("Distance")}</th>
                                    <td>{shipmentData.distance}{" "}km</td>
                                </tr>
                                <tr>
                                    <th>{translate("Inquiry options")}</th>
                                    <td><InquiryShipmentBadges shipmentData={inquiryData.shipmentData} /></td>
                                </tr>
                                <tr>
                                    <th>{translate("Car options")}</th>
                                    <td><InquiryOptionsBadge cargoOptions={inquiryData.cargoOptions} /></td>
                                </tr>
                                {
                                    'suggestedVehicleSet' in inquiryData ?
                                    <tr>
                                        <th>{translate("Suggested vehicle")}</th>
                                        <td>{this.getCarInfo(inquiryData.suggestedVehicleSet)}</td>
                                    </tr>  :   <></>

                                }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
                <Col  md={12} className={"inquiry-column"}>
                    <Card className={"inquiry-card"}>
                        <CardHeader>
                            <CardTitle className={"separator"}>
                                <span>{translate("Requested route")}</span>
                            </CardTitle>
                        </CardHeader>
                        <CardBody className={"inquiry-table inquiry-map"}>
                            <div className="map-container" style={{height:"300px"}}>
                                <Map
                                    id="inquiryMap"
                                    options={{
                                        center: { lat: 50.785418, lng: 10.242753 },
                                        zoom: 9,
                                        scaleControl: true,
                                        streetViewControl: false,
                                        mapTypeControl:false
                                    }}
                                    onMapLoad = {this.onMapLoad}
                                    suppressMarkers = {false}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col  md={12} className={"inquiry-column"}>
                    <Card className={"inquiry-card"}>
                        <CardHeader>
                            <CardTitle className={"separator"}>
                                <span>{translate("Packages")} ({inquiryData.packages.length})</span>
                            </CardTitle>
                        </CardHeader>
                        <CardBody className={"inquiry-table"}>
                                <InquiryDetailsPackages boxes = {inquiryData.packages} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </div>
        )
    }
}

export default InquiryData;
