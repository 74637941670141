import React from "react";
import {connect} from "react-redux";
import MediaQuery from 'react-responsive';
import {Col, Row, Table} from "reactstrap";
import {translate} from 'services/translationService';
import Paginator from "components/Paginator/Paginator";
import Loader from "components/Loader/Loader";
import {getCarSets} from "../../redux/actions/car";
import {paginationActions} from "../../redux/actions";
import BodyTypeBadge from "./BodyTypeBadge";
import {Link} from "react-router-dom";
import CheckIcon from "./CheckIcon";
import {getDriverNamesForCar} from "../../helpers/driverHelper";
import {pageDidChange} from "../../helpers/paginator";
import CarsSearchPanel from "./CarsSearchPanel";
import {getSearchParamsForCarsList, storeSearchParamsForCarsList} from "../../helpers/carsListHelper";

class CarSets extends React.Component {

    constructor(props) {
        super(props);
        const storedSearchParams = getSearchParamsForCarsList('carSets');
        this.state = {
            searchParams:{
                search:"",
                ...storedSearchParams
            },
            showAdvancedSearch:!(storedSearchParams==null)
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePerPageChange = this.handlePerPageChange.bind(this);
        this.getRegistrationNumber = this.getRegistrationNumber.bind(this);
        this.getDimensions = this.getDimensions.bind(this);
        this.setSearchParams = this.setSearchParams.bind(this);
        this.didSearchParamsChange = this.didSearchParamsChange.bind(this);
        this.toggleAdvanced = this.toggleAdvanced.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getCarSets({
            page: this.props.pageParams.page,
            perPage: this.props.pageParams.perPage
        }));
    }

    componentDidUpdate(prevProps, prevState) {
        if (pageDidChange(prevProps.pageParams,  this.props.pageParams))
            {
            this.props.dispatch(getCarSets({
                ...this.state.searchParams,
                perPage: this.props.pageParams.perPage,
                page: this.props.pageParams.page
            }));
        }
        if(this.didSearchParamsChange(prevState)){
            this.props.dispatch(getCarSets({
                ...this.state.searchParams,
                perPage: this.props.pageParams.perPage,
                page: this.props.pageParams.page
            }));
        }
    }

    didSearchParamsChange(prevState){
        return JSON.stringify(this.state.searchParams) !== JSON.stringify(prevState.searchParams);
    }

    handlePageChange(pageNumber, indexStart) {
        this.props.dispatch(paginationActions.pushChangePage('carSetsPaginator', pageNumber, indexStart));
    }

    handlePerPageChange(perPage) {
        this.props.dispatch(paginationActions.changePerPage('carSetsPaginator', perPage));
    }

    toggleDeleteModal(truckTrailer) {
        this.props.onDelete(truckTrailer);
    }

    toggleAdvanced(){
        this.setState({
            showAdvancedSearch:!this.state.showAdvancedSearch
        })
    }

    setSearchParams(searchParams) {
        this.setState({
            searchParams
        });
        storeSearchParamsForCarsList('carSets',searchParams);
    }

    getRegistrationNumber(carSet) {
       const hasTrailer = carSet.hasOwnProperty('truckTrailer');
       return hasTrailer ?
           <div>
               {carSet.car.registrationNumber}<br/>{carSet.truckTrailer.registrationNumber}
           </div> :
           <div>
               {carSet.car.registrationNumber}
           </div>
    }

    getDimensions(carSet) {
        const hasTrailer = carSet.hasOwnProperty('truckTrailer');
        const hasCarContainer = carSet.car.hasOwnProperty('container');
        const carContainer = hasCarContainer ? carSet.car.container : null;
        const trailerContainer = hasTrailer? carSet.truckTrailer.container : null;
        return hasTrailer ?
            <div>
                {(() => {
                    return hasCarContainer ?
                        <span>{carContainer.length} / {carContainer.width} / {carContainer.height}<br/></span>
                    : ""
                })()}
                {trailerContainer.length} / {trailerContainer.width}  / {trailerContainer.height}
            </div> :
            <div>
                {carContainer.length} / {carContainer.width}  / {carContainer.height}
            </div>
    }

    renderCarSetRow(carSet, i) {
        const container = carSet.car.hasOwnProperty('container') ? carSet.car.container : carSet.truckTrailer.container;
        const hasTrailer = carSet.hasOwnProperty('truckTrailer');
        return (
            <tr className="car-row" key={i}>
                <td>{carSet.car.brand.name} {carSet.car.model.name} </td>
                <td>{this.getRegistrationNumber(carSet)}</td>
                <MediaQuery query='(min-device-width: 860px)'>
                    <td>{this.getDimensions(carSet)}</td>
                    <td><BodyTypeBadge specification={container}/></td>
                    <td className={"check-trailer"}>{<CheckIcon checkPresent={hasTrailer}/>}</td>
                    <td>{carSet.car.drivers.length > 0 ? getDriverNamesForCar(carSet.car) :
                        <span className={"check-trailer"}><CheckIcon checkPresent={false}/></span>
                    }
                    </td>
                    <td>
                        <span className={"check-trailer"}><CheckIcon checkPresent={carSet.isActive}/></span>
                    </td>
                </MediaQuery>
                <td>
                    <Link to={`/car-set/${carSet.id}`}>
                        <i className="nc-icon nc-zoom-split"/>
                    </Link>
                    {carSet.car.hasOwnProperty('container') && <Link to={`/car/edit/${carSet.car.id}`} className="ml-2">
                        <i className="nc-icon nc-settings-gear-65 setup-icon"/>
                    </Link>}
                    {!(hasTrailer) ?
                        <span>
                            <Link to="#" className="ml-2">
                                <i onClick={() => {
                                    this.toggleDeleteModal(carSet)
                                }} className="nc-icon nc-simple-remove delete-icon"/>
                            </Link>
                        </span>
                        : ""
                    }
                </td>
            </tr>
        )
    }

    render() {
        if (this.props.isFetching) {
            return (
                <div className="content">
                    <Loader/>
                </div>
            )
        } else {
            const {carSets, meta} = this.props;
            const pagesCount = Math.ceil(meta.total / meta.perPage);
            const page = this.props.pageParams.page;
            const indexStart = this.props.pageParams.indexStart;
            return (
                <div className="content">
                    <Row>
                        <Col md={12}>
                            <h4>{translate("Cars / Sets")}</h4>
                            <CarsSearchPanel
                                listType={"carSets"}
                                showAdvancedSearch={this.state.showAdvancedSearch}
                                toggleAdvanced={this.toggleAdvanced}
                                setSearchParams={this.setSearchParams}
                                searchParams={this.state.searchParams}
                            />
                            <Table>
                                <thead>
                                <tr>
                                    <th>{translate('Car Brand / Model')}</th>
                                    <th>{translate('Car Registration')}</th>
                                    <MediaQuery query='(min-device-width: 860px)'>
                                        <th>{translate('Container Dimensions')}</th>
                                        <th>{translate('Body Type')}</th>
                                        <th>{translate('With trailer')}</th>
                                        <th>{translate('Assigned Driver')}</th>
                                        <th>{translate('Active')}</th>
                                    </MediaQuery>
                                    <th>{translate("Action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {carSets.map((carSet, i) => {
                                    return this.renderCarSetRow(carSet, i)
                                })
                                }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    {pagesCount > 1 && <MediaQuery minDeviceWidth={860}>
                        {(matches) => {
                            let maxPages = matches ? 10 : 3;

                            return <Paginator
                                pageChangeHandler={this.handlePageChange}
                                perPageHandler={this.handlePerPageChange}
                                pagesCount={pagesCount}
                                currentPage={page}
                                perPage={this.props.pageParams.perPage}
                                indexStart={indexStart}
                                maxPages={maxPages}
                            />
                        }}
                    </MediaQuery>}

                </div>
            )
        }
    }
}


CarSets.defaultProps = {
    carSets: [],
    meta: {},
    isFetching: null,
    error: false,
};

const mapStateToProps = (state) => {
    return {
        carSets: state.carSetsReducer.carSets,
        isFetching: state.carSetsReducer.isFetching,
        error: state.carSetsReducer.error,
        meta: state.carSetsReducer.meta,
        pageParams: state.paginationReducer.carSetsPaginator,
    };
};


export default connect(mapStateToProps)(CarSets);
