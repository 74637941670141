//  STATUS_DEFAULT = 0;
//  STATUS_CANCELED = 2; // 1
//  STATUS_DHL_MUST_BE_CANCELED_MANUALLY = 4; // 2 - canceled but can't be canceled automatically by API in UPS/DPD
//  STATUS_PICKUP = 8;
//  STATUS_DELIVERED = 16; // 3
//  STATUS_PAYED = 32; // 4
//  STATUS_CONFIRMED = 64;
//  STATUS_DELETED = 128;
//  STATUS_DPD_UNAVAILABLE = 256;
//  STATUS_DPD_CAN_ORDER = 512;
//  STATUS_DPD_ORDERED = 1024;
//  STATUS_CLOSED_FOR_CARRIER = 8192;

const routeService = {
    PointTypes :  {
        1: "Pickup",
        4: "Midpoint",
        9: "Delivery"
    },
    PointStatus : {
        default: 0,
        begin: 2,
        inProgress: 4,
        closed: 8
    },
    showRouteButton: (order) => {
        if (order.partOfRoute === false) {
            return false;
        }
        let buttonAllowed = true;
        const FORBIDDEN_STATUSES = [16, 8192];
        const status = order.status;
        FORBIDDEN_STATUSES.forEach((forbiddenStatus) => {
            let result = status & forbiddenStatus;
            if (result === forbiddenStatus) {
                buttonAllowed = false
            }
        });
        return buttonAllowed;
    },

    createDirectionRequest: (route) => {
        const routePoints = [...route.routePoints];
        const start = routePoints.shift();
        const finish = routePoints.pop();
        let waypoints = [];
        waypoints = routePoints.map(route=> {
            return {
                location: new window.google.maps.LatLng(route.lat, route.lng),
                stopover: true
            }
        });

        return {
            origin: new window.google.maps.LatLng(start.lat, start.lng),
            destination: new window.google.maps.LatLng(finish.lat, finish.lng),
            waypoints,
            optimizeWaypoints: false,
            travelMode: 'DRIVING',
        }
    },

    drawInquiryRoute: (inquiryShipmentData, directionsService, directionsRenderer ) => {
        directionsService.route({
            origin: {placeId: inquiryShipmentData.pickupGooglePlaceId},
            destination: {placeId: inquiryShipmentData.deliveryGooglePlaceId},
            travelMode: 'DRIVING'
        }, function (response, status) {
            if (status === 'OK') {
                directionsRenderer.setDirections(response);
            } else {
                console.log('Directions request failed due to ' + status);
            }
        });
    },

    drawRoute: (route, directionsService, directionsRenderer) => {
        const directionRequest = routeService.createDirectionRequest(route);
        directionsService.route(directionRequest,
            function(response, status) {
                if (status === 'OK') {
                    directionsRenderer.setDirections(response);
                } else {
                    console.log(status);
                }
            });
    },

    addMarkers: (route,map,clickedMarker) => {
        const routePoints = [...route.routePoints];
        const markers = [];
        routePoints.forEach((routePoint,i)=>{
            markers.push(new window.google.maps.Marker({
                position:{lat: routePoint.lat, lng: routePoint.lng},
                map,
                label:(i+1).toString()
            }))
        });
        markers.forEach(marker => marker.addListener('click', ()=>{ clickedMarker(marker)}));
    },

    getPlaceIdDetails: (map, placeId) => {
        return new Promise((resolve, reject) => {
            let service =  new window.google.maps.places.PlacesService(map);
            service.getDetails({placeId, fields: ["geometry"]},
                function (result, status) {
                    if (status != window.google.maps.places.PlacesServiceStatus.OK) {
                        reject(status);
                    }
                    resolve(result.geometry.location);
                });
        })
    },

    addVehicleIcon: async (map, shipmentData, location, image = 'https://janta-logistics.de/kunden_bereich/img/truck-icon.webp' ) => {
        let markerData = {
            position:{lat: location.lat, lng: location.lng},
            map,
        }
        if(image){
            markerData.icon = image
        }
        let bounds = new window.google.maps.LatLngBounds();
        const vehicleMarker = new window.google.maps.Marker(markerData)
        const location1 = await routeService.getPlaceIdDetails(map, shipmentData.pickupGooglePlaceId);
        const location2 = await routeService.getPlaceIdDetails(map, shipmentData.deliveryGooglePlaceId);
        bounds.extend( {lat: vehicleMarker.position.lat(), lng: vehicleMarker.position.lng()});
        bounds.extend( location1 );
        bounds.extend( location2 );
        setTimeout(()=>map.fitBounds(bounds),300);
    },

    getAsideListItems: (route) => {
        const routePoints = route.routePoints;
        let asideRoutePoints = [];
        let pointCounter = 1;
        routePoints.forEach(routePoint=>{
            if (routePoint.hasOwnProperty('routePointShipments')){
                routePoint.routePointShipments.forEach( rps => {
                    let listPoint = {
                        order:pointCounter,
                        type:routeService.PointTypes[rps.type],
                        status:routePoint.status,
                        country: routePoint.country,
                        postCode: routePoint.postCode,
                        city: routePoint.city,
                        address: routePoint.address,
                        addressName: rps.hasOwnProperty('addressName') ? rps.addressName :"-",
                        shipmentNumber:rps.shipmentNumber,
                        timeOpen:rps.timeOpen,
                        timeClose:rps.timeClose,
                        dateOpen:rps.dateOpen,
                        dateClose:rps.dateClose,
                        lat:routePoint.lat,
                        lng:routePoint.lng,
                    };
                    if(rps.hasOwnProperty('orderId')){
                        listPoint = {...listPoint, orderId: rps.orderId}
                    }
                    asideRoutePoints.push(listPoint);
                });
            }
            pointCounter++;
        });
        return asideRoutePoints;
    },

    getMenuToggler: (route) => {
        const routePoints = route.routePoints;
        const noOfPoints = routePoints.length;
        let menuToggler = {};
        for (let i=1; i<=noOfPoints; i++){
            menuToggler[i]=false;
        }
        return menuToggler;
    },

    getAddressText: (routePoint) => {
        return `${routePoint.address}, ${routePoint.postCode} ${routePoint.city}, ${routePoint.country}`
    },

    getNavigationLink: (routePoint) => {
        return `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${routePoint.lat},${routePoint.lng}`
    },

    getFirstOpenPoint: (route) => {
        let firstOpenPoint = route.routePoints.find(routePoint => routePoint.status === routeService.PointStatus.default);
        return firstOpenPoint !== undefined ? firstOpenPoint : null;
    },

    getPointIndex: (route, selectedRoutePoint) => {
        let index = route.routePoints.findIndex(routePoint => routePoint.id === selectedRoutePoint.id);
        return index >= -1 ? index + 1 : 0
    }
};

export default routeService;
