
const multipleOrdersValidationRules = {
    signedBy: new RegExp('^[a-zA-Z0-9\\s-]+$'),
}

const validate = (values) => {
    const errors = {
    };
    if (!values.shipmentStatusId) {
        errors.shipmentStatusId = 'Please select required state';
    }
    // Signature is required only for status '2' - delivered
    if (values.shipmentStatusId === '2' && !values.signedBy) {
        errors.signedBy = 'Signature is required';
    }

    return errors;
};


const multipleOrdersValidation = (values) => {
    return validate(values);
};

const isAddressOk = (address) => {
    let checkAddress = 'country' in address && address.country.length > 0 &&
      'name' in address && address.name.length > 0 &&
      'city' in address && address.city.length > 0 &&
      'lat' in address && address.lat.length > 0 &&
      'lng' in address && 'lat' && address.lng.length > 0;
    return checkAddress;
}

export {multipleOrdersValidation, isAddressOk};
