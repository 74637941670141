import React from "react";
import {connect} from "react-redux";
import {translate} from "../../../services/translationService";
import "./styles.scss";
import Loader from "../../../components/Loader/Loader";
import {Button} from "reactstrap";
import {sendInvitation} from "../../../redux/actions";
import HelperButton from "../../../components/Helper/HelperButton";

class SendInvitation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      language: localStorage.getItem('locale').toUpperCase()
    }
    this.handleChange = this.handleChange.bind(this);
    this.sendInvitation = this.sendInvitation.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isSendingInvitation === true && this.props.error === false){
      this.setState({ email: '' });
    }
  }

  sendInvitation() {
    this.props.dispatch(sendInvitation(this.state.email, this.state.language));
 }

  isValidEmail() {
    return this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) != null;
  }

  render() {
    const {isSendingInvitation} = this.props;
    const {email} = this.state;

    return <>
      <div className="invitation-send">
        <div className="email-input-wrapper">
          <label htmlFor="email" className="control-label">{ translate("User's email") } </label>
          <input type="text" className={(email.length > 3 && !this.isValidEmail()) ? 'invalid form-control' : 'form-control'}
                 value={email} name="email" onChange={this.handleChange}
            />
          <label htmlFor="languageSelect"> { translate('Language') } </label>
          <select id="languageSelect" className="form-control" name="language"
                  defaultValue={this.state.language} onChange={this.handleChange}>
            <option value="EN"> { translate('English') } </option>
            <option value="PL"> { translate('Polish') } </option>
            <option value="DE"> { translate('German') } </option>
          </select>
        </div>
        <Button color="primary" size={"sm"} onClick={this.sendInvitation}
                disabled={!this.isValidEmail() || isSendingInvitation}>
          {isSendingInvitation ? <Loader /> : translate("Send invitation")}
        </Button>
        <HelperButton helperId="carrier-invitations-send" />
      </div>
    </>
  }
}


SendInvitation.defaultProps = {
  isSendingInvitation: null,
  error: false,
};

const mapStateToProps = (state) => ({
  isSendingInvitation: state.invitationsReducer.isSendingInvitation,
  error: state.invitationsReducer.error,
});

export default connect(mapStateToProps)(SendInvitation);
