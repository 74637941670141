import {apiService} from "services/apiService";
import {alertActions} from "redux/actions";
import loggedUserService from 'services/loggedUserService';
import {translate} from "../../../services/translationService";
import {history} from "../../../helpers";
import getErrorText from "../../../services/errorTextService"
import {updateSyncErrors} from "redux-form";
import {createApiSyncError} from "../../../helpers/ApiSyncErrors";
import {isCordova} from "../../../helpers/cordova";

export const FETCH_CARRIER_REQUEST = 'FETCH_CARRIER_REQUEST';
export const FETCH_CARRIER_SUCCESS = 'FETCH_CARRIER_SUCCESS';
export const FETCH_CARRIER_FAILURE = 'FETCH_CARRIER_FAILURE';
export const CREATE_CARRIER_REQUEST = 'CREATE_CARRIER_REQUEST';
export const CREATE_CARRIER_SUCCESS = 'CREATE_CARRIER_SUCCESS';
export const CREATE_CARRIER_FAILURE = 'CREATE_CARRIER_FAILURE';


export function getCarrierInfo() {
    return dispatch => {
        dispatch(request({}));
        apiService.get('/api/carriers/' + loggedUserService.getCarrierId())
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) {
        return {type: FETCH_CARRIER_REQUEST, data}
    }

    function success(data) {
        return {type: FETCH_CARRIER_SUCCESS, data}
    }

    function failure(error) {
        return {type: FETCH_CARRIER_FAILURE, error}
    }
}

export function updateCarrierInfo(carrier) {
    return dispatch => {
        dispatch(request({}));
        apiService.put('/api/carriers/' + loggedUserService.getCarrierId(), carrier)
            .then(
                response => {
                    dispatch(success(response.data));
                    dispatch(alertActions.success(translate('Data successfully saved!')));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) {
        return {type: FETCH_CARRIER_REQUEST, data}
    }

    function success(data) {
        return {type: FETCH_CARRIER_SUCCESS, data}
    }

    function failure(error) {
        return {type: FETCH_CARRIER_FAILURE, error}
    }
}

export function createCarrier(carrier) {
    return dispatch => {
        dispatch(request({}));
        apiService.post('/register', carrier)
            .then(
                response => {
                    dispatch(success(response.data));
                    dispatch(alertActions.clear());
                    if (isCordova()) {
                        document.location.hash = 'register-info';

                    } else {
                        history.push('/register-info');
                    }
                })
            .catch(error => {
                dispatch(updateSyncErrors('registerForm', createApiSyncError(error)));
                dispatch(failure(error.data.message.error));
                dispatch(alertActions.error(translate('Please correct your form')));
            });
    };

    function request(data) {
        return {type: CREATE_CARRIER_REQUEST, data}
    }

    function success(data) {
        return {type: CREATE_CARRIER_SUCCESS, data}
    }

    function failure(error) {
        return {type: CREATE_CARRIER_FAILURE, error}
    }
}
