import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {history} from "../../../helpers";
import {priceApiService} from "../../../services/priceApiService";

export const FETCH_SATURDAY_REQUEST = 'FETCH_SATURDAY_REQUEST';
export const FETCH_SATURDAY_SUCCESS = 'FETCH_SATURDAY_SUCCESS';
export const FETCH_SATURDAY_FAILURE = 'FETCH_SATURDAY_FAILURE';

export function getSurchargesForSaturday(params) {
    return dispatch => {
        dispatch(request({}));
        const {countyFrom, countryTo} = params;
        const url = `forSaturdayDelivery/${countyFrom}/${countryTo}`;
        priceApiService.getSurchargesData(url)
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                history.push('/dashboard');
            });
    };

    function request(data) { return { type: FETCH_SATURDAY_REQUEST, data } }
    function success(data) { return { type: FETCH_SATURDAY_SUCCESS, data } }
    function failure(error) { return { type: FETCH_SATURDAY_FAILURE, error } }
}
