import React from "react";
import {connect} from "react-redux";
import {Modal, Button, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardTitle, Table} from "reactstrap";
import UploadDocument from "./UploadDocument";
import {apiService} from "services/apiService";
import {translate} from "services/translationService";
import {alertActions, getOrder} from 'redux/actions';
import '../OrderDetails.scss';
import getErrorText from "../../../services/errorTextService";

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
class DeliveryDocument extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDeleteModal: false
        };
        this.showDeliveryFile = this.showDeliveryFile.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
    }

    toggleDeleteModal() {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal
        });
    }

    deleteFile() {
        const shipmentId = this.props.order.shipment.id;
        apiService.apiDelete(`/api/shipments/${shipmentId}/delivery_document`)
            .then(() => {
                this.toggleDeleteModal();
                this.props.dispatch(alertActions.success(translate("Document deleted successfully")));
                this.props.dispatch(getOrder(this.props.order.id));
            }).catch(errorMessage => {
            this.props.dispatch(alertActions.error(getErrorText(errorMessage)));
        });
    }

    showDeliveryFile() {
        if(typeof(window.cordova)==='undefined'){
            let previewUrl = `/delivery/document/${this.props.order.shipment.id}`;
            window.open(previewUrl);
        }else{
            let documentUrl = `${BASE_URL}api/shipments/${this.props.order.shipment.id}/delivery_document?token=`+localStorage.getItem('authToken');
            window.cordova.InAppBrowser.open(documentUrl, '_system', 'location=yes');
        }
    }

    render() {
        const {deliveryDocument, id} = this.props.order.shipment;
        const orderId = this.props.order.id;
        const relatedOrders = this.props.order.relatedOrders || null;
        const shipmentNumber = this.props.order.shipment.number;
        return (
            <div>
                <Card>
                    <CardTitle>
                        <div className={"separator"}>{translate("Delivery Document")}</div>
                    </CardTitle>
                    {deliveryDocument ?
                        <div>
                            <CardBody className="table-full-width">
                                <Table hover>
                                    <tbody>
                                    <tr>
                                        <th>{translate("Document added on")}</th>
                                        <td>{deliveryDocument.addedAt}</td>
                                    </tr>
                                    <tr>
                                        <th>{translate("Added by")}</th>
                                        <td>{deliveryDocument.name}</td>
                                    </tr>
                                    <tr>
                                        <th>{translate("File name")}</th>
                                        <td>{deliveryDocument.fileName}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                                <div className="text-center">
                                    <Button
                                        color="primary"
                                        onClick={this.showDeliveryFile}>
                                        {translate("Show document")}
                                    </Button>
                                    {(this.props.canEdit) ?
                                        <Button
                                            color="danger"
                                            outline
                                            onClick={this.toggleDeleteModal}>
                                            {translate("Delete document")}
                                        </Button>
                                        : ""
                                    }
                                </div>
                            </CardBody>
                            <Modal isOpen={this.state.showDeleteModal} toggle={this.toggle}>
                                <ModalHeader
                                    toggle={this.toggleDeleteModal}>{translate("Delete delivery document")}</ModalHeader>
                                <ModalBody>
                                    {translate("Do you really want to delete delivery document for shipment: ")}{shipmentNumber}{' ?'}
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary"
                                            onClick={this.toggleDeleteModal}>{translate("Cancel")}</Button>
                                    <Button color="primary"
                                            onClick={this.deleteFile}>{translate("Delete")}</Button>{' '}
                                </ModalFooter>
                            </Modal>
                        </div>
                        :
                        <div className="text-center">
                            <UploadDocument
                                shipmentId={id}
                                orderId={orderId}
                                relatedOrders = {relatedOrders}
                            />
                        </div>}
                </Card>

            </div>
        )
    }
}

export default connect()(DeliveryDocument);
