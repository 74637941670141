const userDataService = {
    concatPersonalData: user => `${user.firstName} ${user.lastName}`,
    concatPostalData(address, showCountry = 0) {
        let postalData = null;
        if (showCountry) {
            postalData = `${address.zipCode} ${address.city} ${address.country}`;
        } else {
            postalData = `${address.zipCode} ${address.city}`;
        }
        return postalData;
    },
    validateEmail(email) {
        const reg = /\S+@\S+\.\S+/;
        const validatedEmail = reg.test(email);
        if (!validatedEmail) { return null; }
        return email;
    },
    validateName(name) {
        const reg = /[0-9]/;
        const validatedName = reg.test(name);
        if (validatedName) { return null; }
        return name;
    },
    validateDocumentNumber(documentNumber) {
        const reg = /^.{1,100}$/;
        const validatedDocument = reg.test(documentNumber);
        if (!validatedDocument) { return null; }
        return documentNumber;
    },
    validatePhoneNumber(phoneNumber) {
        const reg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
        const validatedDocument = reg.test(phoneNumber);
        if (!validatedDocument) { return null; }
        return phoneNumber;
    },
    isDriver: (roles) => {return roles.includes("ROLE_DRIVER")},
    validatePassword(password) {
        const reg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}/;
        const validatedPassword = reg.test(password);
        if (!validatedPassword) { return null; }
        return password;
    },
};

export default userDataService;
