import React from "react";
import {Field, FormSection, reduxForm} from 'redux-form';
import {Col, Row} from "reactstrap";
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import {userValidation} from '../validation.js'
import {translate} from 'services/translationService';
import Button from "components/CustomButton/CustomButton.jsx";
import Address from 'components/FormSections/Address';
import RoleMultiSelect from "../RoleMultiSelect";
import loggedUserService from "services/loggedUserService";
import FieldSelect from "../../../../components/FormFields/FieldSelect/FieldSelect";
import {languagesForSelect} from 'helpers/languageSelect'
import {connect} from "react-redux";
import {translateFormLabels} from "../../../../helpers/translateFormLabels";
import FieldCheckbox from "../../../../components/FormFields/FieldCheckbox/FieldCheckbox";


class UserForm extends React.Component {

    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.state = {
            labels:  translateFormLabels()
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale){
            this.setState({labels: translateFormLabels()});
        }
    }

    onFormSubmit(formData) {
        this.props.onSubmitHandler(formData);
    }

    render() {
        const {labels} = this.state;
        return (
            <form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
                <Row>
                    <Col sm={6}>
                        <Field
                            name="name"
                            inputId="name"
                            type="text"
                            label={labels.accountName}
                            component={FieldInput}
                        />
                    </Col>

                    <Col sm={6}>
                        <Field
                            name="surname"
                            inputId="surname"
                            label={labels.accountSurname}
                            component={FieldInput}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <Field
                            name="email"
                            inputId="email"
                            label={labels.accountEmail}
                            type="email"
                            component={FieldInput}
                        />
                    </Col>
                    <Col sm={6}>
                        <Field
                            name="documentNumber"
                            inputId="documentNumber"
                            label={labels.accountDocumentNumber}
                            component={FieldInput}
                        />
                    </Col>
                </Row>
                 <Row>
                    <FormSection name="address">
                      <Col sm={6}>
                            <Field
                                name="phone"
                                inputId="phone"
                                label = {labels.accountPhone}
                                type="text"
                                component={FieldInput}
                            />
                      </Col>
                    </FormSection>
                      <Col sm={6}>
                          <Field
                              name="language"
                              inputId="language"
                              options={languagesForSelect()}
                              label={labels.accountLanguage}
                              component={FieldSelect}
                              props={{emptyValue: false}}
                          />
                      </Col>
                    </Row>

                <hr />
                <FormSection name="address">
                    <Address formName={'userForm'}/>
                </FormSection>

                <hr />
                { (loggedUserService.isManager()) ?
                    (
                        <div>
                            <Row>
                                <Col md={12}>
                                    <h5>{translate('Management user information')}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <RoleMultiSelect />
                                </Col>
                                <Col md={6}>
                                    <Field
                                        inputClassName="small"
                                        labelClassName="small"
                                        name="shipmentsConversationsAccess"
                                        component={FieldCheckbox}
                                        label={translate('Shipments conversations access')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    ) : ""
                }

                <Row>
                    <div className="update ml-auto mr-auto">
                        <Button color="primary" round>{translate("Update Profile")}</Button>
                    </div>
                </Row>

            </form>
        )
    }
}

const mapStateToProps = state => ({
    locale:state.translationReducer.locale
});

const decoratedComponent = connect(mapStateToProps)(UserForm);

export default reduxForm({
    form: 'userForm',
    validate: userValidation
})(decoratedComponent);

