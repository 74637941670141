import { apiService } from "services/apiService";
import { alertActions } from "redux/actions";
import getErrorText from "services/errorTextService";

export const FETCH_SOLO_TRUCKS_REQUEST = 'FETCH_SOLO_TRUCKS_REQUEST';
export const FETCH_SOLO_TRUCKS_SUCCESS = 'FETCH_SOLO_TRUCKS_SUCCESS';
export const FETCH_SOLO_TRUCKS_FAILURE = 'FETCH_SOLO_TRUCKS_FAILURE';

export function getSoloTrucks(params) {
    return dispatch => {
        dispatch(request({}));
        apiService.get('/api/vehicles/solo_truck', params)
            .then(
                response => {
                    dispatch(success(response));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_SOLO_TRUCKS_REQUEST, data } }
    function success(data) { return { type: FETCH_SOLO_TRUCKS_SUCCESS, data } }
    function failure(error) { return { type: FETCH_SOLO_TRUCKS_FAILURE, error } }
}

