import React, {Component} from 'react';
import {translate} from "../../../services/translationService";
import "./styles.scss"
import {Input} from "reactstrap";
import {getInputErrorClass, getInputErrorClassInRow} from "../../../helpers/formHelper";

class CargoRules extends Component {

  constructor(props) {
    super(props);
    this.updateCargoRules = this.updateCargoRules.bind(this)
    this.renderCargoInputs = this.renderCargoInputs.bind(this)
  }

  updateCargoRules(e){
    let newInputs = {...this.props.configInputs};
    const inputValue = e.target.value;
    if (e.target.name === 'maxHeightWithoutSurcharge') {
      newInputs['maxHeightWithoutSurcharge'] = inputValue;
    } else {
      const type = e.target.name.split('-')[1];
      const factor = e.target.name.split('-')[2];
      newInputs[type][factor] = inputValue
    }
    this.props.updateInputs(newInputs)
  }

  renderCargoInputs(ruleType){
    const {configInputs} = this.props;

    return <div className="cargo-options-form">
      <Input
        name={`cargoRules-${ruleType}-maxLength`}
        id={`cargoRules-${ruleType}-maxLength`}
        value={configInputs[ruleType].maxLength}
        className={getInputErrorClassInRow(configInputs[ruleType], 'maxLength')}
        onChange={this.updateCargoRules}
        type="number"
        step="1"
        min="0"
      />
      <Input
        name={`cargoRules-${ruleType}-maxWidth`}
        id={`cargoRules-${ruleType}-maxWidth`}
        value={configInputs[ruleType].maxWidth}
        className={getInputErrorClassInRow(configInputs[ruleType], 'maxWidth')}
        onChange={this.updateCargoRules}
        type="number"
        step="1"
        min="0"
      />
      <Input
        name={ruleType === 'liftCargoOptionLimits' ? `cargoRules-${ruleType}-maxWeight`: `cargoRules-${ruleType}-maxHeight`}
        id={ruleType === 'liftCargoOptionLimits' ? `cargoRules-${ruleType}-maxWeight`: `cargoRules-${ruleType}-maxHeight`}
        value={ruleType === 'liftCargoOptionLimits' ? configInputs[ruleType].maxWeight : configInputs[ruleType].maxHeight}
        className={getInputErrorClassInRow(configInputs[ruleType], ruleType === 'liftCargoOptionLimits' ? 'maxWeight' : 'maxHeight')}
        onChange={this.updateCargoRules}
        type="number"
        step="1"
        min="0"
      />
    </div>
  }

  render() {

    const {configInputs} = this.props;
    return <>
      <div className="centered"><h5 className="cooperation-title">{translate("Cargo rules")}</h5></div>
      <div className="cargo-input">
        <span>{translate("Max height without surcharge") + "[cm]" }</span>
        <Input
          name="maxHeightWithoutSurcharge"
          id="maxHeightWithoutSurcharge"
          type="number"
          step="1"
          min="0"
          value={configInputs["maxHeightWithoutSurcharge"]}
          className={getInputErrorClass(configInputs["maxHeightWithoutSurcharge"])}
          onChange={this.updateCargoRules}
        />
      </div>
      <div className="cargo-rules">
        <div className="fracht-subtitle">{translate("Restrictions for cargo option")}</div>
        <div className="fracht-subtitle">{translate("Lift cargo options")}</div>
        <div className="cargo-rules-section">
          <div>{translate("Max length") + " [cm]"}</div>
          <div>{translate("Max width") + " [cm]"}</div>
          <div>{translate("Max weight") + " [kg]"}</div>
        </div>
        {this.renderCargoInputs('liftCargoOptionLimits')}
        <div className="fracht-subtitle">{translate("Laidable options")}</div>
        <div className="cargo-rules-section">
          <div>{translate("Max length") + " [cm]"}</div>
          <div>{translate("Max width") + " [cm]"}</div>
          <div>{translate("Max height") + " [cm]"}</div>
        </div>
        {this.renderCargoInputs('laidableOptionLimits')}
      </div>
    </>
  }
}

export default CargoRules