import React from "react";
import {connect} from "react-redux";
import {Row, Col, Button} from "reactstrap";
import "./style.scss";
import {dateTime} from "../../utils/formatters";
import {notificationApiService} from "../../services/notificationApiService";


class SingleNoification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            read: this.props.notification.read === 1
        };
    }

    componentDidMount() {
        notificationApiService.put(`api/simple/notifications/${this.props.notification.id}`).then();
    }

    render() {
        const notification = this.props.notification;
        const styleClass = this.state.read ? 'read' : 'unread';
        return (
            <Row className={"notification " + styleClass}>
                <Col xs={2}>
                    <i className={"nc-icon nc-email-85 " + styleClass}/>
                </Col>
                <Col xs={10} lg={8}>
                    <div>
                        <span className="message-date">{dateTime(notification.created_at)}</span>
                    </div>
                    <div>
                        <span className="message-content ">{notification.content}</span>
                    </div>
                </Col>
            </Row>
        )
    }
};

export default connect()(SingleNoification);
