import {formValueSelector, reduxForm} from "redux-form";
import React from "react";
import {connect} from "react-redux";
import {getSurchargesByWeight} from "../../../../redux/actions";
import WeightOptionsForm from "./WeightOptionsForm";
import Loader from "../../../../components/Loader/Loader";
import {translate} from "../../../../services/translationService";

class WeightOptions extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        const {countryFromValue, countryToValue, dispatch} = this.props;
        if ((prevProps.countryFromValue !== countryFromValue) || (prevProps.countryToValue !== countryToValue)) {
            const surchargeData = {
                countyFrom: countryFromValue,
                countryTo: countryToValue
            }
            dispatch(getSurchargesByWeight(surchargeData));
        }
    }

    componentDidMount() {
        const {countryFromValue, countryToValue, dispatch} = this.props;
        if (countryFromValue && countryToValue){
            const surchargeData = {
                countyFrom: countryFromValue,
                countryTo: countryToValue
            }
            dispatch(getSurchargesByWeight(surchargeData));
        }
        }

    render() {
        const {countryFromValue, countryToValue, surcharges, isFetching} = this.props;

        return <>
            <div className="centered">
                <h5 className="cooperation-title">{`${translate("Weight surcharges")} ${countryFromValue} - ${countryToValue}`} </h5>
            </div>
            {isFetching ? <Loader /> :
                <WeightOptionsForm
                    initialSurcharges={surcharges}
                    countryFrom={countryFromValue}
                    countryTo={countryToValue}
                />
            }
        </>
    }
}

WeightOptions.defaultProps = {
    surcharges: [],
    isFetching: null,
    error: false,
};

const selector = formValueSelector('countriesSelectorForm');

const mapStateToProps = (state) => {
    return {
        countryFromValue: selector(state, 'countryFrom'),
        countryToValue: selector(state, 'countryTo'),
        surcharges: state.weightSurchargesReducer.surcharges,
        isFetching: state.weightSurchargesReducer.isFetching,
        error: state.weightSurchargesReducer.error,
    };
};

export default connect(mapStateToProps)(WeightOptions);