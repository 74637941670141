import React from "react";
import {translate} from "services/translationService";
import {Button, Input} from "reactstrap";
import {alertActions} from "../../../../redux/actions";
import {priceApiService} from "../../../../services/priceApiService";
import Loader from "../../../../components/Loader/Loader";
import {connect} from "react-redux";
import {parseInt} from "lodash/string";

class WeightOptionsForm extends React.Component {

    constructor(props) {
        super(props);
        let surchargesInputs = {};
        props.initialSurcharges.forEach(surcharge => surchargesInputs[surcharge.service_code] = surcharge.prices)
        this.state = {
            surchargesInputs,
            isUploading: false
        }
        this.uploadSurcharges = this.uploadSurcharges.bind(this);
        this.updateWeightOptions = this.updateWeightOptions.bind(this);
        this.addInputs = this.addInputs.bind(this);
        this.deleteInputs = this.deleteInputs.bind(this);
    }

    addInputs(serviceCode) {
        let newInputValues = {...this.state.surchargesInputs};
        newInputValues[serviceCode].push({
            max_weight: 0, price: "0.00"
        });
        this.setState({
            surchargesInputs: newInputValues
        })
    }

    deleteInputs(serviceCode, i) {
        let newInputValues = {...this.state.surchargesInputs};
        newInputValues[serviceCode].splice(i,1);
        this.setState({
            surchargesInputs: newInputValues
        })
    }

    uploadSurcharges() {
        let newInputValues = {...this.state.surchargesInputs};
        const {countryFrom, countryTo, dispatch} = this.props;
        let weightSurchargesData = [];
        for (let surcharge in newInputValues) {
            const prices = [];
            newInputValues[surcharge].forEach(entry => {
                const weight = Math.abs(parseFloat(entry.max_weight));
                const price = Math.abs(parseFloat(entry.price));
                if (weight > 0 && price >= 0) {
                    prices.push({
                        max_weight: weight,
                        price: price
                    })
                }
            })
            weightSurchargesData.push({
                service_code: parseInt(surcharge),
                country_from: countryFrom,
                country_to: countryTo,
                prices
            });
        }
        this.setState({
            isUploading: true
        });
        priceApiService.uploadSurchargesData('byWeight', weightSurchargesData).then(response => {
            dispatch(alertActions.success(translate("Surcharges for cargo options uploaded successfully")));
            this.setState({
                isUploading: false
            });
        }).catch((apiError) => {
            console.log(apiError);
            dispatch(alertActions.error(translate("Failed to upload surcharges, please retry")));
            this.setState({
                isUploading: false
            });
        })
    }

    updateWeightOptions(e) {
        const [surchargeType, inputType, inputIndex] = e.target.name.split('-');
        const inputValue = e.target.value;
        let newInputValues = {...this.state.surchargesInputs};
        newInputValues[surchargeType][inputIndex][inputType] = inputValue;
        this.setState({
            surchargesInputs: newInputValues
        })
    }


    render() {
        const {initialSurcharges} = this.props;
        const {surchargesInputs, isUploading} = this.state;
        return <div className="cargo-options-form">

            <div className="cargo-options-title surcharges-title">
                <div>{translate("Weight surcharges")}</div>
                <div className="centered">{translate("Maximum Weight | Price")}</div>
            </div>
            {initialSurcharges.map((surcharge, key) => <div className="cargo-options-row" key={key}>
                <div>{surcharge.service_name}</div>
                {surcharge.prices.map((price, i) => {
                    return <div className={"option-input-group"} key={i}>
                        <span className="delete-button"><i className="nc-icon nc-simple-delete"
                                                        onClick={() => this.deleteInputs(surcharge.service_code, i)}
                        /></span>
                        <Input
                            name={`${surcharge.service_code}-max_weight-${i}`}
                            value={surchargesInputs[surcharge.service_code][i].max_weight}
                            onChange={this.updateWeightOptions}
                            type="number"
                            step="1"
                            min="0"
                        />
                        <span>kg</span>
                        <Input
                            name={`${surcharge.service_code}-price-${i}`}
                            value={surchargesInputs[surcharge.service_code][i].price}
                            onChange={this.updateWeightOptions}
                            type="number"
                            step="0.01"
                            min="0"
                        />
                        <span>€</span>
                        {(surcharge.prices.length > 0 && i < surcharge.prices.length - 1) ? <span className="row-divider">|</span> :
                            <span className="row-divider" style={{opacity: 0}}>|</span>}
                    </div>
                })}

                {(surcharge.prices.length < 5) && <span className="add-button"><i className="nc-icon nc-simple-add"
                                                           onClick={() => this.addInputs(surcharge.service_code)}
                /></span>}
            </div>)}
            <div className="button-container">
                <Button color="primary" size={"sm"} onClick={this.uploadSurcharges}>
                    {isUploading ? <Loader /> : translate("Save")}
                </Button>
            </div>
        </div>
    }
}

WeightOptionsForm.defaultProps = {
    surcharges: [],
};

export default connect()(WeightOptionsForm);