import React from "react";
import {Button, Col, Modal, ModalBody, ModalHeader, Row, Table} from "reactstrap";
import {translate} from 'services/translationService';
import {getCarInfo, getDriverNamesForSimplifiedCar} from "../../helpers/driverHelper";
import loggedUserService from "../../services/loggedUserService";
import Badge from "reactstrap/es/Badge";
import ModalFooter from "reactstrap/es/ModalFooter";

class VehicleAssigment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showUnlinkModal: false,
    };
    this.unlinkCar = this.unlinkCar.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(){
    this.setState({showUnlinkModal:!this.state.showUnlinkModal})
  }

  unlinkCar(){
    const {partOfRoute} = this.props.order;
    if(!partOfRoute || this.state.showUnlinkModal){
      this.props.unlinkCar({
        vehicleId:null
      })
      return;
    }
    this.setState({showUnlinkModal: true})
  }

    render() {
        const {vehicle, canEdit, order} = this.props;
        const {showUnlinkModal} = this.state;
        const canUpdateCar = canEdit && loggedUserService.isManager();
        return <>
          {vehicle ?
                <Row style={{alignItems: 'center'}}>
                    <Col>
                        {canUpdateCar && <Button className="car-unlink-button" color="secondary"
                                outline size="sm" title={translate("Unlink car")}
                                onClick={this.unlinkCar}>
                            X  {getCarInfo(vehicle)}
                        </Button>}
                        {!canUpdateCar && getCarInfo(vehicle)}
                    </Col>
                    <Col className="align-right">
                        {vehicle.hasOwnProperty('drivers') ?
                            getDriverNamesForSimplifiedCar(vehicle)
                            : <span><i style={{fontSize: '1em', color: 'black'}}
                                       className="nc-icon nc-simple-remove icon-cross"/></span>}
                    </Col>
                </Row>
                :
                <Row>
                    {canUpdateCar && <Button className="btn btn-success btn-sm ml-4" onClick={this.props.showModal}>
                        {translate("Add car")}
                    </Button>}
                </Row>}
          {showUnlinkModal && <Modal isOpen={this.state.showUnlinkModal} toggle={this.toggle} size="md"
                                     className="route-unlink-modal">
            <ModalHeader toggle={this.toggle}>
              {translate("Warning! You are about to unlink vehicle from order") + " " + order.shipment.number}
            </ModalHeader>
            <ModalBody className="route-unlink-body">
              <p>{translate("Current route contains also the following orders:")}</p>
              <div className="related-orders-list">
                {order.relatedOrders.map((relatedOrder, key)=><Badge color="primary" key={key}>{relatedOrder.shipmentNumber}</Badge>)}
              </div>
              <p>{translate("Vehicles for those orders will also be unlinked.")}</p>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={this.unlinkCar}>
                {translate("OK")} </Button>
              <Button onClick={this.toggle}>{translate("Cancel")}</Button>
            </ModalFooter>
          </Modal>}
        </>
    }
}

export default VehicleAssigment;
