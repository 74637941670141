import React from "react";

export function getDriverNamesForCar(car) {
       const drivers = car.drivers;
       switch (drivers.length) {
              case 1:
              default:
              return <span>{drivers[0].name} {drivers[0].surname}</span>;
              case 2:
                      return <span>{drivers[0].name} {drivers[0].surname}<br/>
                             {drivers[1].name} {drivers[1].surname}</span>;
       }
}

export function getDriverNamesForSimplifiedCar(car) {
       const drivers = car.drivers;
       switch (drivers.length) {
              case 1:
              default:
                     return <span>{drivers[0]}</span>;
              case 2:
                     return <span>{drivers[0]}<br/>
                            {drivers[1]}</span>;
       }
}

export function canUnlinkBeRendered(car){
       let unlinkCarInfo = localStorage.getItem('unlinkCarInfo');
       if (unlinkCarInfo == null){
              return true;
       } else {
              unlinkCarInfo = JSON.parse(unlinkCarInfo);
       };
       const now = Date.now();
       const timeLimit = 5 * 60 * 1000; //5 minutes
       return (car.registrationNumber !==  unlinkCarInfo.registrationNumber) || (now - timeLimit > unlinkCarInfo.time );
}

export function getCarInfo(car){
       return car? <span>{car.brandName} {car.modelName} {car.registrationNumber}</span> :
           <span><i style={{fontSize: '1em', color:'red'}} className="nc-icon nc-simple-remove icon-cross"/></span>
}
