import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {translate} from "../../../services/translationService";
import {Field, Form, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {getFreightConfig, updateFreightConfig} from "../../../redux/actions";
import Loader from "../../../components/Loader/Loader";
import WeightFactors from "./WeightFactors";
import {countriesForSelect} from "../../../helpers/countries";
import FieldSelect from "../../../components/FormFields/FieldSelect/FieldSelect";
import {createFrachtConfigInputs, createFrachtConfigObject} from "../../../helpers/formHelper";
import MaxWeight from "./MaxWeight";
import Services from "./Services";
import ContainerRules from "./ContainerRules";
import Pallets from "./Pallets";
import DeliveryRules from "./DeliveryRules";
import CargoRules from "./CargoRules";
import HelperButton from "../../../components/Helper/HelperButton";

class FrachtConfig extends Component {

  constructor(props) {
    super(props);
    this.state = {
      configInputs:null,
      formIsValid:true,
    };
    this.updateInputs = this.updateInputs.bind(this)
    this.updateInputs = this.updateInputs.bind(this)
    this.saveFrachtConfig = this.saveFrachtConfig.bind(this)
    this.formRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const {configCountryFromValue, configCountryToValue, dispatch, isFetchingFrachtConfig, frachtConfig} = this.props;
    if ((prevProps.configCountryFromValue !== configCountryFromValue) || (prevProps.configCountryToValue !== configCountryToValue)) {
      const configData = {
        countryFrom: configCountryFromValue,
        countryTo: configCountryToValue
      }
      dispatch(getFreightConfig(configData));
    }
    if ((prevProps.isFetchingFrachtConfig !== isFetchingFrachtConfig )) {
      const newFrachtConfig = createFrachtConfigInputs(frachtConfig);
      this.setState({configInputs:newFrachtConfig})
    }
  }

  updateInputs (newInputState) {
    setTimeout(() => {
      let errorInputs = this.formRef.current.querySelectorAll('.input-error');
      let errorRows = this.formRef.current.querySelectorAll('.row-error');
      let  formHasErrors = errorRows.length > 0 || errorInputs.length > 0;
      this.setState({formIsValid:!formHasErrors})
    }, 100);
    this.setState({configInputs:newInputState})
  }


  saveFrachtConfig () {
    const {configCountryFromValue, configCountryToValue, dispatch} = this.props;
    const {configInputs} = this.state;
    let saveConfigData = createFrachtConfigObject(configInputs);
    const configData = {
      countryFrom: configCountryFromValue,
      countryTo: configCountryToValue,
      data:saveConfigData
    };
    dispatch(updateFreightConfig(configData));
  }

  render() {
    const {isFetchingCountries, isFetchingFrachtConfig, isUpdatingFrachtConfig, frachtConfig} = this.props;
    const {configInputs, formIsValid} = this.state;
    if (isFetchingCountries || isFetchingFrachtConfig || configInputs === null) {
      return (
        <div className="content">
          <Loader/>
        </div>
      )
    }

    return (
      <div className="content fracht-config" ref={this.formRef}>
          <Card className={"firstCard"}>
            <HelperButton helperId="carrier-countries-selector" />
            <CardBody>
              <Row>
                <Col md={12}>
                  <div className="centered"><h5 className="cooperation-title">{translate("Select countries")}</h5></div>
                  <Form onSubmit={()=>{}}>
                    <Row className="form-row">
                      <Col md={6}>
                        <Field
                          inputId="configCountryFrom"
                          name="configCountryFrom"
                          options={countriesForSelect(this.props.countries)}
                          label={translate("Country from")}
                          component={FieldSelect}
                          props={{emptyValue: false}}
                        />
                      </Col>
                      <Col md={6}>
                        <Field
                          inputId="configCountryTo"
                          name="configCountryTo"
                          options={countriesForSelect(this.props.countries)}
                          label={translate("Country to")}
                          component={FieldSelect}
                          props={{emptyValue: false}}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        <div className="dedicated-cards">
          <Card className={"dedicated-card-1"}>
            <HelperButton helperId="carrier-available-services" />
            <CardBody>
              <Row>
                <Col md={12}>
                  <Services configInputs={configInputs}
                            updateInputs={this.updateInputs}
                            availableServices = {frachtConfig.availableServices}
                            maxWeightPerService = {frachtConfig.maxWeightPerService}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className={"dedicated-card-2"}>
            <HelperButton helperId="carrier-max-weight-rules" />
            <CardBody>
              <Row>
                <Col md={12}>
                  <MaxWeight configInputs={configInputs} updateInputs={this.updateInputs} availableServices = {frachtConfig.availableServices}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
          <Card className={"dedicated-card-3"}>
            <HelperButton helperId="carrier-general-weight-rules" />
            <CardBody>
              <Row>
                <Col md={12}>
                  <WeightFactors configInputs={configInputs} updateInputs={this.updateInputs} />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className={"dedicated-card-4"}>
            <HelperButton helperId="carrier-container-rules" />
            <CardBody>
              <Row>
                <Col md={12}>
                  <ContainerRules configInputs={configInputs} updateInputs={this.updateInputs} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        <Card className={"dedicated-card-5"}>
          <HelperButton helperId="carrier-pallet-rules" />
          <CardBody>
            <Row>
              <Col md={12}>
                <Pallets configInputs={configInputs} updateInputs={this.updateInputs} />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <div className="dedicated-cards">
          <Card className={"dedicated-card-1"}>
            <HelperButton helperId="carrier-delivery-rules" />
            <CardBody>
              <Row>
                <Col md={12}>
                  <DeliveryRules configInputs={configInputs} updateInputs={this.updateInputs} avoidCountries={frachtConfig.avoidCountries} />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className={"dedicated-card-2"}>
            <HelperButton helperId="carrier-cargo-rules" />
            <CardBody>
              <Row>
                <Col md={12}>
                  <CargoRules configInputs={configInputs} updateInputs={this.updateInputs} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Card className={"save-container"}>
          <CardBody>
            <Row>
              <Col md={12}>
                <span className={!formIsValid ? "form-error" : ""}>{formIsValid ?
                  translate("Store fracht configuration") : translate("Fix configuration data")
                }
                </span>
                <Button color="primary" size={"sm"} onClick={this.saveFrachtConfig} disabled={!formIsValid}>
                  {isUpdatingFrachtConfig ?  <Loader /> : translate("Save")}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

FrachtConfig.defaultProps = {
  countries: {},
  isFetchingCountries: true,
  error: false,
  frachtConfig: {},
  isFetchingFrachtConfig: false,
  frachtConfigError: false,
};


FrachtConfig = reduxForm({
  form: 'configCountryForm',
  enableReinitialize: true
})(FrachtConfig);

const countriesConfigSelector = formValueSelector('configCountryForm');

const mapStateToProps = state => ({
  countries: state.countryReducer.countries,
  isFetchingCountries: state.countryReducer.isFetching,
  error: state.countryReducer.error,
  frachtConfig: state.frachtConfigReducer.frachtConfig,
  isFetchingFrachtConfig: state.frachtConfigReducer.isFetchingFrachtConfig,
  isUpdatingFrachtConfig: state.frachtConfigReducer.isUpdatingFrachtConfig,
  frachtConfigError: state.frachtConfigReducer.error,
  initialValues: {
    configCountryFrom: "DE",
    configCountryTo:"DE",
  },
  configCountryFromValue: countriesConfigSelector(state, 'configCountryFrom'),
  configCountryToValue: countriesConfigSelector(state, 'configCountryTo'),
});

export default connect(mapStateToProps)(FrachtConfig);