import React from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {translate} from "../../services/translationService";
import InvoicingDataForm from "./InvoicingDataForm";
import {getInvoicingData, updateInvoicingData} from "../../redux/actions";
import Loader from "../../components/Loader/Loader";
import HelperButton from "../../components/Helper/HelperButton";

class InvoicingData extends React.Component {

    constructor(props) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.dispatch = this.props.dispatch;
    }

    componentDidMount() {
        this.dispatch(getInvoicingData());
    }

    handleFormSubmit(formData) {
        this.dispatch(updateInvoicingData(formData));
    }

    render() {
        const {isFetching, billingPeriods} = this.props;
        const buttonContent = isFetching ? <Loader /> : translate("Save");

        if (isFetching && this.props.invoicingData.length === 0) {
            return (
                <div className="content">
                    <Loader />
                </div>
            )
        }

        const initialFormData = this.props.invoicingData;


        return (
            <div className="content">
            <Row>
                <Col md={12}>
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle>
                                <h4><i className="nc-icon nc-credit-card"/> {translate('Edit invoicing data')}
                                </h4>
                                <hr/>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div className="outerForm">
                                <InvoicingDataForm
                                    onSubmitHandler={this.handleFormSubmit}
                                    initialValues={initialFormData}
                                    billingPeriods = {billingPeriods}
                                    buttonContent = {buttonContent}
                                />
                            </div>
                        </CardBody>
                        <HelperButton helperId="carrier-invoicing-data-edit" />
                    </Card>
                </Col>
            </Row>
            </div>
        )
    }
}

InvoicingData.defaultProps = {
    isFetching: null,
    error: false,
    billingPeriods: [],
    invoicingData:[]
};

const mapStateToProps = state => ({
    billingPeriods: state.billingPeriodReducer.billingPeriods,
    isFetching: state.invoicingDataReducer.isFetching,
    error: state.invoicingDataReducer.error,
    invoicingData: state.invoicingDataReducer.invoicingData,
});

export default connect(mapStateToProps)(InvoicingData);
