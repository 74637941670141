import React from "react";
import {translate} from "../../../services/translationService";

const PalletWarning = ({showPalletDeliveryReminder}) => {
    const text = showPalletDeliveryReminder ?  translate("Remember to fill pallet balance for delivery address")
        : translate("Remember to fill pallet balance for pickup address");
    return (
        <div className={"w-100 alert-info p-3 mb-2 text-center"}>
            <span>{text}</span>
        </div>
    )
};

export default PalletWarning;