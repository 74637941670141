import { apiService } from "services/apiService";
import { alertActions } from "redux/actions";
import getErrorText from "services/errorTextService"
import {apiFileService} from "services/apiFileService";
import {translate} from 'services/translationService';

export const FETCH_USER_DOCUMENTS_REQUEST = "FETCH_USER_DOCUMENTS_REQUEST";
export const FETCH_USER_DOCUMENTS_SUCCESS = "FETCH_USER_DOCUMENTS_SUCCESS";
export const FETCH_USER_DOCUMENTS_FAILURE = "FETCH_USER_DOCUMENTS_FAILURE";

export const CREATE_USER_DOCUMENT_REQUEST = "CREATE_USER_DOCUMENT_REQUEST";
export const CREATE_USER_DOCUMENT_SUCCESS = "CREATE_USER_DOCUMENT_SUCCESS";
export const CREATE_USER_DOCUMENT_FAILURE = "CREATE_USER_DOCUMENT_FAILURE";

export const DELETE_USER_DOCUMENT_SUCCESS = "DELETE_USER_DOCUMENT_SUCCESS";


export function getUserDocuments(userId) {
    return dispatch => {
        dispatch(request({}));
        apiService.get('/api/document_types/account')
            .then(
                response => {
                    let documentTypes = response.data;
                    apiService.get(`/api/accounts/${userId}/documents`)
                        .then(
                            response => {
                                let userDocuments = response.data;
                                dispatch(success({documentTypes, userDocuments}));
                            }
                        ).catch(errorMessage => {
                        dispatch(failure(getErrorText(errorMessage)));
                        dispatch(alertActions.error(getErrorText(errorMessage)));
                    })
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_USER_DOCUMENTS_REQUEST, data } }
    function success(data) { return { type: FETCH_USER_DOCUMENTS_SUCCESS, data } }
    function failure(error) { return { type: FETCH_USER_DOCUMENTS_FAILURE, error } }
}

export function addUserDocument(userId, formData) {
    return dispatch => {
        dispatch(request({}));

        apiFileService.uploadFile(`/api/accounts/${userId}/documents`, formData)
            .then((response) => {
                if (response.messages){
                    dispatch(success(response.data));
                    dispatch(alertActions.success(response.messages[0]));
                }
            })
            .catch((errorMessage) => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });

    };

    function request(data) { return { type: CREATE_USER_DOCUMENT_REQUEST, data } }
    function success(data) { return { type: CREATE_USER_DOCUMENT_SUCCESS, data } }
    function failure(data) { return { type: CREATE_USER_DOCUMENT_FAILURE, data } }
}

export function deleteUserDocument(documentId) {
    return dispatch => {
        apiService.apiDelete(`/api/accounts/documents/${documentId}`)
            .then(() => {
                dispatch(success(documentId));
                dispatch(alertActions.success(translate("Document deleted successfully")));
            })
            .catch((apiError) => {
                dispatch(alertActions.error(getErrorText(apiError)));
            });

        function success(documentId) { return {type: DELETE_USER_DOCUMENT_SUCCESS, documentId } }
    }
}
