import React from "react";
import ReactDropzone from "react-dropzone";
import {translate} from 'services/translationService';

const FieldDropzone = ({
                              name,
                              input,
                              meta,
                              maxSize,
                              accept,
                          }) => {
    return (
        <div>
            <ReactDropzone
                onDrop={(filesToUpload) => {
                    input.onChange(filesToUpload[0])
                }
                }
                multiple={false}
                maxSize={parseInt(maxSize)}
                accept={accept}
                name={name}
            >
                {({getRootProps, getInputProps, isDragReject, rejectedFiles, acceptedFiles}) => {
                    const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                    return (<div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="dropzone">
                            <p>{translate("Drag your document or click here to add file")}
                                <br /><em>{translate("Possible file type: ") + accept } <br />
                                {translate("Max file size: ") + (maxSize / 1048576).toFixed(0) + " MB"}</em>
                            </p>
                            {(isDragReject ) && <p className="rejected">{translate("File type not accepted")}</p>}
                            {isFileTooLarge && <p className="rejected">{translate("File is too large")}</p>}
                            { (rejectedFiles.length > 0) && <p className="rejected">{translate("File type not accepted") +" / " + translate("File is too large")}</p>}
                            {acceptedFiles[0] &&
                            <div className="fileName">
                                <i className="nc-icon nc-share-66"></i>
                                <p className="accepted">{acceptedFiles[0].name}</p>
                            </div>
                            }
                        </div>
                    </div>)
                }}
            </ReactDropzone>
            {meta.touched &&
            meta.error &&
            <span className="error invalid-feedback ml-2">{translate(meta.error)}</span>}
        </div>
    )
}


export default (FieldDropzone);
