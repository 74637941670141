import {apiService} from "services/apiService";
import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {history} from "../../../helpers";

export const FETCH_INQUIRY_REQUEST = 'FETCH_INQUIRY_REQUEST';
export const FETCH_INQUIRY_SUCCESS = 'FETCH_INQUIRY_SUCCESS';
export const FETCH_INQUIRY_FAILURE = 'FETCH_INQUIRY_FAILURE';

export function getInquiry(id) {
    return dispatch => {
        dispatch(request({}));

        apiService.get('/api/inquiries/' + id)
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                history.push('/dashboard');
            });
    };

    function request(data) { return { type: FETCH_INQUIRY_REQUEST, data } }
    function success(data) { return { type: FETCH_INQUIRY_SUCCESS, data } }
    function failure(error) { return { type: FETCH_INQUIRY_FAILURE, error } }
}
