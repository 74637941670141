import {
    FETCH_STATUSES_FAILURE,
    FETCH_STATUSES_REQUEST,
    FETCH_STATUSES_SUCCESS
} from 'redux/actions/shipments';

const initialState = {
    statuses: {},
    isFetching: true,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STATUSES_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
            });
        }

        case FETCH_STATUSES_SUCCESS: {
            const statuses = action.data.data.reduce((obj, status)=>{
                obj[status.id] = status;
                return obj;
            },{});

            return Object.assign({}, state, {
                statuses: statuses,
                isFetching: false,
            });
        }

        case FETCH_STATUSES_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }
        default:
            return state
    }
}
