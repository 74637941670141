import React from "react";
import {Alert} from 'reactstrap';
import {translate} from "services/translationService";
import loggedUserService from "../../services/loggedUserService";
import "./style.scss";
import {connect} from "react-redux";
import {alertActions} from "../../redux/actions";
import {apiService} from "../../services/apiService";
import {getExpiringDocumentWarningText} from "../../helpers/documentWarningHelper";

class FirstLoginInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: true
        };

        this.onDismiss = this.onDismiss.bind(this);
    }

    componentDidMount() {
        const {dispatch} = this.props;
        apiService.get('/api/accounts/documents/expiring')
            .then(response => {
                const alertText = getExpiringDocumentWarningText(response.data);
                if (alertText !== "") {
                    dispatch(alertActions.warning(translate(alertText)))
                }
            })
            .catch(errorMessage => console.log(errorMessage));
    }

    onDismiss() {
        this.setState({
            visible: !this.state.visible
        })
    }

    render() {
        const driverInfo = (loggedUserService.isDriver() && !loggedUserService.isUserActive()) ?
            translate("Make sure all required documents in your profile are uploaded and wait for administrator to activate your account. ") : "";
        const managerInfo = (loggedUserService.isManager() && !loggedUserService.isCarrierActive()) ?
            translate("Make sure all required company documents are uploaded and wait for administrator to activate your company. ") : "";
        const alert = driverInfo + managerInfo;
        return (
                <div >
                    <Alert className="warning documents-info " isOpen={this.state.visible && !!alert} color="warning" toggle={this.onDismiss}>
                        {alert}
                    </Alert>
                </div>
        );
    }
}

export default connect()(FirstLoginInfo);
