import React from "react";
import {translate} from "services/translationService";
import {Button, Input} from "reactstrap";
import {alertActions} from "../../../../redux/actions";
import {priceApiService} from "../../../../services/priceApiService";
import Loader from "../../../../components/Loader/Loader";
import {connect} from "react-redux";

class HeightOptionsForm extends React.Component {

    constructor(props) {
        super(props);
        let surchargesInputs = [];
        props.initialSurcharges.forEach((surcharge,i) => surchargesInputs[i] = {
            max_height:surcharge.max_height,
            price:surcharge.price,
        });
        this.state = {
            surchargesInputs,
            isUploading: false
        }
        this.uploadSurcharges = this.uploadSurcharges.bind(this);
        this.updateHeightOptions = this.updateHeightOptions.bind(this);
        this.addInputs = this.addInputs.bind(this);
        this.deleteInputs = this.deleteInputs.bind(this);
    }

    addInputs() {
        let newInputValues = [...this.state.surchargesInputs];
        newInputValues.push({
            max_height: 0, price: "0.00"
        });
        this.setState({
            surchargesInputs: newInputValues
        })
    }

    deleteInputs(index) {
        let newInputValues = [...this.state.surchargesInputs]
        newInputValues.splice(index,1);
        this.setState({
            surchargesInputs: newInputValues
        })
    }

    uploadSurcharges() {
        let newInputValues = [...this.state.surchargesInputs];
        const {countryFrom, countryTo, dispatch} = this.props;
        let heightSurchargesData = [];
        newInputValues.forEach(surcharge =>  {
            const height = Math.abs(parseFloat(surcharge.max_height));
            if (height > 0 ){
                heightSurchargesData.push({
                    country_from: countryFrom,
                    country_to: countryTo,
                    max_height: height,
                    price:  Math.abs(parseFloat(surcharge.price))
                })
            }
        })
        this.setState({
            isUploading: true
        });
        priceApiService.uploadSurchargesData('byHeight', heightSurchargesData).then(response => {
            dispatch(alertActions.success(translate("Surcharges for height uploaded successfully")));
            this.setState({
                isUploading: false
            });
        }).catch((apiError) => {
            console.log(apiError);
            dispatch(alertActions.error(translate("Failed to upload surcharges, please retry")));
            this.setState({
                isUploading: false
            });
        })
    }

    updateHeightOptions(e) {
        const [inputType, inputIndex] = e.target.name.split('-');
        const inputValue = e.target.value;
        let newInputValues = [...this.state.surchargesInputs];
        newInputValues[inputIndex][inputType] = inputValue;
        this.setState({
            surchargesInputs: newInputValues
        })
    }

    render() {
        const {surchargesInputs, isUploading} = this.state;
        return <div className="cargo-options-form">

            <div className="cargo-options-title surcharges-title">
                <div></div>
                <div className="centered">{translate("Maximum Height | Price")}</div>
            </div>

            <div className="cargo-options-row">
                <div>{translate("Height surcharges")}</div>
                {surchargesInputs.map((surcharge, i) => {
                    return <div className={"option-input-group"} key={i}>
                        <span className="delete-button"><i className="nc-icon nc-simple-delete"
                                                           onClick={() => this.deleteInputs(i)}
                        /></span>
                        <Input
                            name={`max_height-${i}`}
                            value={surcharge.max_height}
                            onChange={this.updateHeightOptions}
                            type="number"
                            step="1"
                            min="0"
                        />
                        <span>cm</span>
                        <Input
                            name={`price-${i}`}
                            value={surcharge.price}
                            onChange={this.updateHeightOptions}
                            type="number"
                            step="0.01"
                            min="0"
                        />
                        <span>€</span>
                        {(surchargesInputs.length > 0 && i < surchargesInputs.length - 1) ? <span className="row-divider">|</span> :
                            <span className="row-divider" style={{opacity: 0}}>|</span>}
                    </div>
                })}
                {(surchargesInputs.length < 5) && <span className="add-button"><i className="nc-icon nc-simple-add"
                                                                                  onClick={() => this.addInputs()}
                /></span>}
            </div>

            <div className="button-container">
                <Button color="primary" size={"sm"} onClick={this.uploadSurcharges}>
                    {isUploading ? <Loader /> : translate("Save")}
                </Button>
            </div>
        </div>
    }
}

HeightOptionsForm.defaultProps = {
    surcharges: [],
};

export default connect()(HeightOptionsForm);