import React from "react";
import {connect} from 'react-redux'
import SingleNotification from "./SingleNotification";
import Loader from "components/Loader/Loader";
import MediaQuery from "react-responsive";
import Paginator from "../../components/Paginator/Paginator";
import {getNotifications} from "../../redux/actions/notifications/notifications.actions";
import {paginationActions} from "../../redux/actions/pagination.actions";
import {pageDidChange} from "../../helpers/paginator";
import {getApiNotifications} from "../../redux/actions";
import apiNotificationsReducer from "../../redux/reducers/apiNotifications/apiNotifications.reducer";
export const NOTIFICATION_PAGINATOR = 'notificationPaginator';

class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePerPageChange = this.handlePerPageChange.bind(this);
        this.dispatch = this.props.dispatch;
    }

    componentDidMount() {
        this.dispatch(getApiNotifications({
            page:this.props.pageParams.page,
        }));
    }

    componentDidUpdate(prevProps) {
        if (pageDidChange(prevProps.pageParams,  this.props.pageParams)){
            this.dispatch(getApiNotifications({
                n: this.props.pageParams.perPage,
                page: this.props.pageParams.page
            }));
        }
    }

    handlePageChange(pageNumber, indexStart) {
        this.dispatch(paginationActions.pushChangePage(NOTIFICATION_PAGINATOR, pageNumber, indexStart));
    }

    handlePerPageChange(perPage) {
        this.dispatch(paginationActions.changePerPage(NOTIFICATION_PAGINATOR, perPage));
    }

    render() {
        if (this.props.isFetching) {
            return (
                <div className="content">
                    <Loader />
                </div>
            )
        }
        const {notifications, meta} = this.props;
        const pagesCount = Math.ceil(meta.total / meta.perPage);
        const page = this.props.pageParams.page;
        const indexStart = this.props.pageParams.indexStart;
        return (
            <div className="content">

                {notifications.map((notification, i) => {
                            return <SingleNotification notification={notification} key={i}/>
                        })
                    }
                {pagesCount > 1 && <MediaQuery minDeviceWidth={860}>
                    {(matches) => {
                        let maxPages = matches ? 10 : 5;

                        return <Paginator
                            pageChangeHandler={this.handlePageChange}
                            perPageHandler={this.handlePerPageChange}
                            pagesCount={pagesCount}
                            currentPage={page}
                            perPage={this.props.pageParams.perPage}
                            indexStart={indexStart}
                            maxPages={maxPages}
                        />
                    }}
                </MediaQuery>
                }
            </div>
        );
    }
}

Notifications.defaultProps = {
    notifications: [],
    meta:{},
    isFetching: null,
    error: false,
};

const mapStateToProps = state => ({
    notifications: state.apiNotificationsReducer.notifications,
    meta: state.apiNotificationsReducer.meta,
    isFetching: state.apiNotificationsReducer.isFetching,
    error: state.apiNotificationsReducer.error,
    pageParams: state.paginationReducer.notificationPaginator,
});

export default connect(mapStateToProps)(Notifications);
