import React from "react";
import "./OrdersListActions.scss"
import Badge from "reactstrap/es/Badge";
import {Button} from "reactstrap";
import {translate} from "../../../services/translationService";
import { history } from 'helpers';

class OrdersListActions extends React.Component {

    constructor(props) {
        super(props);
        this.renderOrdersBadges = this.renderOrdersBadges.bind(this);
        this.getMultipleRemarksUrl = this.getMultipleRemarksUrl.bind(this);
    }

    renderOrdersBadges(){
        const {selectedOrders} = this.props;
        return Object.values(selectedOrders).map((orderName, i)=><Badge key={i} color={'secondary'}>{orderName}</Badge>)
    }

    getMultipleRemarksUrl(){
        const {selectedOrders} = this.props;
        const ordersIds =  Object.keys(selectedOrders);
        let multipleUrl = new URL(window.location.href);
        ordersIds.forEach(orderId=>multipleUrl.searchParams.append('ordersIds[]',orderId));
        history.push("/multiple-orders/" + multipleUrl.search);
     }

    render() {
        const {clearOrders} = this.props;

        return (
            <div className="order-list-actions">
                <div className="names-area">
                    <div>{translate("Selected orders")}:</div>
                    {this.renderOrdersBadges()}
                </div>
                <div className="buttons-area">
                    <Button className="btn btn-secondary btn-sm" onClick={clearOrders}>
                        {translate("Unselect all")}
                    </Button>
                    <Button className="btn btn-success btn-sm" onClick={this.getMultipleRemarksUrl}>
                        {translate("Set status")}
                    </Button>
                </div>
            </div>
        )
    }
}

export default OrdersListActions;
