import React, {Component} from 'react';

class Map extends Component {
    constructor(props) {
        super(props);
        this.onScriptLoad = this.onScriptLoad.bind(this)
    }

    onScriptLoad() {
        const {suppressMarkers} = this.props;
        const map = new window.google.maps.Map(
            document.getElementById(this.props.id),
            this.props.options);
            const directionsService = new window.google.maps.DirectionsService();
            const directionsRenderer = new window.google.maps.DirectionsRenderer({
                map,
                suppressMarkers,
            });
        this.props.onMapLoad(directionsService, directionsRenderer, map)
    }

    componentDidMount() {
        if (!window.google) {
            let s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = `https://maps.google.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
            let x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
            s.addEventListener('load', e => {
                this.onScriptLoad()
            })
        } else {
            this.onScriptLoad()
        }
    }

    render() {
        return (
            <div style={{ width: '100%', height:'100%'}} id={this.props.id} />
        );
    }
}

Map.defaultProps = {
    suppressMarkers: true
}

export default Map