import {change, Field} from "redux-form";
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import React from "react";
import {translate} from "../../../services/translationService";
import {connect} from "react-redux";
import {countriesForSelect} from "../../../helpers/countries"
import FieldSelect from "../../../components/FormFields/FieldSelect/FieldSelect";
import {Button, Col, Row} from "reactstrap";
import {priceApiService} from "../../../services/priceApiService";
import {alertActions} from "../../../redux/actions";
import Loader from "../../../components/Loader/Loader";

class AddressFormSection extends React.Component {

    constructor(props) {
        super(props);
        this.checkLocation = this.checkLocation.bind(this);
        this.canCheckLocation = this.canCheckLocation.bind(this);
        this.openGoogleMap = this.openGoogleMap.bind(this);
        this.renderLocationButton = this.renderLocationButton.bind(this);
        this.state = {
            checkingLocation: false,
            locationSet: false
        }
    }

    checkLocation() {
        this.setState({
            checkingLocation: true
        });
        const {dispatch} = this.props;
        const {addressValue, targetFormName} = this.props;
        priceApiService.getLocation(addressValue)
            .then((apiServiceResponse) => {
                if (apiServiceResponse.data.length > 0) {
                    const mapData = apiServiceResponse.data[0];
                    dispatch(change(targetFormName, 'address.lat', mapData.latitude.toString()));
                    dispatch(change(targetFormName, 'address.lng', mapData.longitude.toString()));
                    dispatch(change(targetFormName, 'address.postCode', mapData.postCode));
                    dispatch(change(targetFormName, 'address.city', mapData.city));
                    dispatch(change(targetFormName, 'address.address', mapData.streetLine1));
                    dispatch(change(targetFormName, 'address.country', mapData.countryCode));
                    this.setState({
                        checkingLocation: false,
                        locationSet: true
                    });
                } else {
                    dispatch(change(targetFormName, 'address.postCode', "?"));
                    dispatch(change(targetFormName, 'address.city', "?"));
                    dispatch(change(targetFormName, 'address.address', "?"));
                    dispatch(change(targetFormName, 'address.lat', ""));
                    dispatch(change(targetFormName, 'address.lng', ""));
                    this.setState({
                        checkingLocation: false,
                        locationSet: false
                    });
                }

            })
            .catch((apiError) => {
                console.log(apiError);
                dispatch(alertActions.error(translate('It is not possible to check location now, try later')));
                this.setState({
                    checkingLocation: false,
                    locationSet: false
                });
            });
    }

    openGoogleMap(){
        const {addressValue} = this.props;
        if (typeof(window.cordova)!=='undefined'){
        //ToDo sprawdzenie / korekta wersji na apkę mobilną
            const url = `geo:${addressValue.lat},${addressValue.lng}`
            window.open(url, '_blank');
        }else {
            const url = `http://www.google.com/maps/place/${addressValue.lat},${addressValue.lng}`
            const win = window.open(url, '_blank');
            win.focus();
        }
    }

    canCheckLocation() {
        const {addressValue} = this.props;
        return addressValue.name && addressValue.city;
    }

    renderLocationButton() {
        const {checkingLocation, locationSet} = this.state;
        if (checkingLocation){
            return <Loader />
        }
        return <>
            <Button color="primary" size={"sm"} onClick={this.checkLocation}
                disabled={!this.canCheckLocation()}>
            {translate("Check")}
            </Button>{" "}
            {locationSet &&
            <Button color="primary" size={"sm"} onClick={this.openGoogleMap}
                    disabled={!this.canCheckLocation()}>
                <i className="nc-icon nc-map-big mr-1"></i> Google Maps
            </Button>}
        </>
    }

    render() {
        const {isFetching, error, countries} = this.props;
        return (!isFetching && !error ?
                <>
                    <Field
                        inputId="name"
                        name="name"
                        type="text"
                        label={translate("Company name") + " *"}
                        component={FieldInput}
                        placeholder=""
                    />
                    <Field
                        inputId="address"
                        name="address"
                        type="text"
                        label={translate("Street")}
                        component={FieldInput}
                        placeholder=""
                    />
                    <Field
                        inputId="city"
                        name="city"
                        type="text"
                        label={translate("City")  + " *"}
                        component={FieldInput}
                        placeholder=""
                    />
                    <Field
                        inputId="postCode"
                        name="postCode"
                        type="text"
                        label={translate("Post Code")}
                        component={FieldInput}
                        placeholder=""
                    />
                    <Field
                        inputId="country"
                        name="country"
                        options={countriesForSelect(countries)}
                        label={translate("Country")}
                        component={FieldSelect}
                        props={{emptyValue: false}}
                    />
                    <Row className={"hidden"}>
                        <Col md={6} className={"no-margin"}>
                            <Field
                                inputId="lat"
                                name="lat"
                                type="text"
                                label={translate("Latitude")}
                                component={FieldInput}
                                disabled={true}
                            />
                        </Col>
                        <Col md={6}>
                            <Field
                                inputId="lng"
                                name="lng"
                                type="text"
                                label={translate("Longitude")}
                                component={FieldInput}
                                disabled={true}
                                labelClassName={"col-md-4"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className={"col-google"}>
                            {this.renderLocationButton()}

                        </Col>
                    </Row>
                    <Field
                        inputId="phone"
                        name="phone"
                        type="text"
                        label={translate("Phone")}
                        component={FieldInput}
                        placeholder=""
                    />
                    <Field
                        inputId="email"
                        name="email"
                        type="text"
                        label={translate("Email")}
                        component={FieldInput}
                        placeholder=""
                    />
                </> : null
        )
    }
}

AddressFormSection.defaultProps = {
    countries: {},
    isFetching: null,
    error: false,
};

const mapStateToProps = state => ({
    countries: state.countryReducer.countries,
    isFetching: state.countryReducer.isFetching,
    error: state.countryReducer.error,
});

export default connect(mapStateToProps)(AddressFormSection);
