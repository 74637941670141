import {formValueSelector, reduxForm} from "redux-form";
import React from "react";
import {connect} from "react-redux";
import Loader from "../../../components/Loader/Loader";
import {translate} from "../../../services/translationService";
import {getDedicatedPrices, getSurchargesByWeight, updateDedicatedPrices} from "../../../redux/actions";
import PrimaryPricesForm from "./PrimaryPricesForm";

class PrimaryPrices extends React.Component {

    constructor(props) {
        super(props);
        this.updatePrices = this.updatePrices.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {countryFromValue, countryToValue, dispatch} = this.props;
        if ((prevProps.countryFromValue !== countryFromValue) || (prevProps.countryToValue !== countryToValue)) {
            const pricesData = {
                countryFrom: countryFromValue,
                countryTo: countryToValue
            }
            dispatch(getDedicatedPrices(pricesData));
        }
    }

    updatePrices (pricesData)  {
        const {countryFromValue, countryToValue, dispatch} = this.props;
        dispatch(updateDedicatedPrices({
            countryFrom:countryFromValue,
            countryTo:countryToValue,
            pricesData
        }));
    }

    render() {
        const {countryFromValue, countryToValue, prices, isFetching, isUpdating} = this.props;

        return <>
            <div className="centered">
                <h5 className="cooperation-title">{`${translate("Primary prices")} ${countryFromValue} - ${countryToValue}`} </h5>
            </div>
            {isFetching ? <Loader /> :
              <PrimaryPricesForm countryFrom={countryFromValue} countryTo={countryToValue}
                                 initialPrices={prices} isUpdating={isUpdating} updatePrices={this.updatePrices}/>
            }
        </>
    }
}

PrimaryPrices.defaultProps = {
    prices: [],
    isFetching: null,
    error: false,
};

const selector = formValueSelector('countriesSelectorForm');

const mapStateToProps = (state) => {
    return {
        countryFromValue: selector(state, 'countryFrom'),
        countryToValue: selector(state, 'countryTo'),
        prices: state.dedicatedPricesReducer.prices,
        isFetching: state.dedicatedPricesReducer.isFetching,
        error: state.dedicatedPricesReducer.error,
        isUpdating: state.dedicatedPricesReducer.isUpdating
    };
};

export default connect(mapStateToProps)(PrimaryPrices);