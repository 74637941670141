import {
    UPDATE_PALLET_BALANCE_REQUEST,
    UPDATE_PALLET_BALANCE_SUCCESS,
    UPDATE_PALLET_BALANCE_FAILURE
} from 'redux/actions/shipments/palletBalance.actions.js';


const initialState = {
    message: '',
    palletBalance: {},
    isFetching: false,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PALLET_BALANCE_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
                error:false
            });
        }

        case UPDATE_PALLET_BALANCE_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }

        case UPDATE_PALLET_BALANCE_SUCCESS: {
            return Object.assign({}, state, {
                palletBalance: action.data,
                isFetching: false,
                error: false
            });
        }


        default:
            return state
    }
}
