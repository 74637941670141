import React from "react";
import { connect } from "react-redux";
import {translate} from "../../../services/translationService";
import "./styles.scss";
import {getInvitations, sendInvitation} from "../../../redux/actions";
import Loader from "../../../components/Loader/Loader";
import {Button} from "reactstrap";
import MediaQuery from "react-responsive";
import {fullDate} from "../../../utils/formatters";
import HelperButton from "../../../components/Helper/HelperButton";

class InvitationsList extends React.Component {

  constructor(props) {
    super(props);
    this.sendInvitation = this.sendInvitation.bind(this);
    this.state = {
      updatingIndex: null
    }
  }

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getInvitations());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isSendingInvitation === true && this.props.isSendingInvitation === false) {
      this.setState({
        updatingIndex: null
      })
    }
  }

  sendInvitation(index) {
    const {invitations, dispatch} = this.props;
    this.setState({updatingIndex: index})
    dispatch(sendInvitation(invitations[index].email, invitations[index].lang));
  }

  render() {
    const {invitations, isFetchingInvitations, isSendingInvitation} = this.props;
    const {updatingIndex} = this.state;

    if (isFetchingInvitations) return <Loader />

    return <>
      <div className="invitations-list">
        <MediaQuery query='(min-device-width: 860px)'>
          <div className="invitations-row invitations-title">
            <div>{translate("Email")}</div>
            <div>{translate("Invitation sent on")}</div>
            <div />
          </div>
          {invitations.map((invitation, key) => <div className={"invitations-row"} key={key}>
            <div>{invitation.email}</div>
            <div>{invitation.created_at}</div>
            <Button color="primary" size={"sm"} onClick={() => this.sendInvitation(key)} disabled={isSendingInvitation}>
              {isSendingInvitation && updatingIndex === key ? <Loader /> : translate("Send again")}
            </Button>
          </div>)
          }
        </MediaQuery>
        <MediaQuery query='(max-device-width: 859px)'>
          {invitations.map((invitation, key) => <div className={"invitation-box"} key={key}>
            <div className={"label-email"}>{translate("Email address")}</div>
            <div className={"content-email"}>{invitation.email}</div>
            <div className={"label-date"}>{translate("Invitation sent on")}</div>
            <div className={"content-date"}>{fullDate(invitation.created_at)}</div>
            <div className={"button-container"}><Button color="primary" size={"sm"} onClick={() => this.sendInvitation(key)}
                         disabled={isSendingInvitation}>
              {isSendingInvitation && updatingIndex === key ? <Loader /> : translate("Send again")}
            </Button>
            </div>
          </div>)
          }
        </MediaQuery>
        <HelperButton helperId="carrier-invitations-list" />
      </div>
    </>
  }
}


InvitationsList.defaultProps = {
  invitations: [],
  isFetchingInvitations: null,
  isSendingInvitation: null,
  error: false,
};

const mapStateToProps = (state) => ({
  invitations: state.invitationsReducer.invitations,
  isFetchingInvitations: state.invitationsReducer.isFetchingInvitations,
  isSendingInvitation: state.invitationsReducer.isSendingInvitation,
  error: state.invitationsReducer.error,
});

export default connect(mapStateToProps)(InvitationsList);
