import React from "react";
import {translate} from 'services/translationService';
import {Form, Input, Modal, ModalBody, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import Button from "reactstrap/es/Button";
import Loader from "../../../../components/Loader/Loader";
import {apiService} from "../../../../services/apiService";
import {alertActions, getUserInfo} from "../../../../redux/actions";
import CodeInput from "./CodeInput";
import getErrorText from "../../../../services/errorTextService";

class ReceiveTrailerModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            maxWeight:"24500",
            transferCode:"",
            inputFocused:false,
            trailerData:{},
            checkingError:false
        };
        this.toggle = this.toggle.bind(this);
        this.transferTrailer = this.transferTrailer.bind(this);
        this.handleWeightChange = this.handleWeightChange.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.switchFocus = this.switchFocus.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.showModal === false && prevProps.showModal === true) {
            window.removeEventListener('keydown', this.handleKeyDown);
        }
        if (this.props.showModal === true && prevProps.showModal === false) {
            window.addEventListener('keydown', this.handleKeyDown);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    switchFocus() {
        this.setState({
            inputFocused: !this.state.inputFocused,
        })
    }

    handleKeyDown = (event) => {
        const allowedKeys = ['0','1','2','3','4','5','6','7','8','9']
        if(event.target.className !== 'form-control'){
            event.preventDefault();
            const currentCode = this.state.transferCode;
            if (allowedKeys.includes(event.key) && currentCode.length < 6){
                this.setState({
                    transferCode:currentCode + event.key
                })
            }
            if (event.key === 'Backspace' && currentCode.length > 0){
                this.setState({
                    transferCode:currentCode.slice(0,-1)
                })
            }
        }
    }

    toggle() {
        this.setState({
            isLoading: false,
            maxWeight:"24500",
            transferCode:"",
            inputFocused:false,
            checkingError:false
        });
        this.props.toggleModal();
    }

    handleWeightChange(event) {
        const enteredWeight =  event.target.value.replace(/([^0-9]+)/gi, '');
        this.setState({
            maxWeight: enteredWeight
        })
    }

    handleCodeChange(event) {
        let enteredCode =  event.target.value.replace(/([^0-9]+)/gi, '');
        this.setState({
            transferCode: enteredCode
        })
    }

    transferTrailer() {
        this.setState({
            isLoading: true
        });
        const {transferCode, maxWeight} = this.state;
        const {userId, dispatch} = this.props;
        apiService.put(`/api/vehicles/transfer-code/${transferCode}`,{maxWeight}).then(
            result => {
                this.setState({
                    isLoading: false
                });
                dispatch(alertActions.success(translate("Trailer transferred successfully")));
                dispatch(getUserInfo(userId));
                this.toggle()
            }
        ).catch(error => {
            console.log(error);
            this.props.dispatch(alertActions.error(translate("Trailer transfer was not possible")));
            this.setState({
                isLoading: false,
            });
        })
    }

    checkTransfer() {
        this.setState({
            isLoading: true
        });
        const {transferCode} = this.state;
        apiService.get(`/api/vehicles/transfer-code/${transferCode}/trailer`).then(
            result => {
                this.setState({
                    isLoading: false,
                    trailerData:result.data,
                    checkingError:false,
                    inputFocused:false,
                });
            }
        ).catch(error => {
            if(error.status !== 400){
                console.log(error);
                this.props.dispatch(alertActions.error(getErrorText(error)));
            } else{
                this.setState({
                    isLoading: false,
                    trailerData: {},
                    checkingError:true,
                    transferCode:"",
                    inputFocused:false,
                });
            }
        })
    }

    render() {
        const {transferCode, inputFocused, isLoading, trailerData, maxWeight, checkingError} = this.state;
        const enteredCode = transferCode.padEnd(6,'-');
        const completeCode = transferCode.length === 6;
        const trailerDataAvailable = Object.keys(trailerData).length > 0;
        const givenWeight = parseInt(maxWeight);
        return (
            <div className={"receive-modal"}>
                <Modal isOpen={this.props.showModal} toggle={this.toggle} size="sm" id="receive-transfer-modal">
                    <ModalHeader toggle={this.toggle}>
                        <i className={"nc-icon nc-refresh-69"}/>&nbsp;
                        <span>{translate("Enter a trailer transfer code")}</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className={"code-container"}>
                            <CodeInput code={enteredCode} focused={!inputFocused} />
                            <Form inline className="text-center weight-input-form">
                                <label>{translate("Max weight")}</label>
                                <Input id="weight-input" type="text" name="maxWeight"
                                       value={this.state.maxWeight}
                                       onChange={this.handleWeightChange}
                                       onFocus={this.switchFocus}
                                       onBlur={this.switchFocus}
                                />
                                <span> kg</span>
                            </Form>
                            <div className={"transfer-buttons"}>
                                <Button size="sm" color={"info"}  disabled={isLoading || !completeCode}
                                        onClick={()=>this.checkTransfer()}>
                                    {isLoading ?
                                        <Loader/> : translate("Check")}</Button>
                                <Button size="sm" color={"primary"}
                                        disabled={!completeCode || isNaN(givenWeight) || givenWeight < 1 || !trailerDataAvailable}
                                        onClick={()=>this.transferTrailer()}>
                                    {translate("Transfer")}
                                </Button>
                            </div>
                        </div>
                        {trailerDataAvailable && <div className="trailer-display">
                            <h5><i className="nc-icon nc-alert-circle-i"/> {translate('Trailer')}: </h5> <span>{trailerData.brand.name} - {trailerData.model.name} - {trailerData.registrationNumber}</span>
                        </div>}
                        {checkingError && <div className="trailer-error">
                            {translate("Transfer code is not valid")}
                        </div>}
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}


export default connect()(ReceiveTrailerModal)

