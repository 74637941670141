import React  from 'react';
import { connect } from 'react-redux'
import {Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Field, reduxForm} from "redux-form";

import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import Loader from "../../../components/Loader/Loader";
import offerValidation from "./validation";
import { createInquiryOffer } from 'redux/actions/inquiries/inquiryOffer.actions';
import {translate} from "../../../services/translationService";

export const AddOfferModal = (props) => {
  const {
    show,
    toggle,
    isFetching,
    submitOffer,
    handleSubmit
  } = props;

  const onSubmit = (form) => {
    submitOffer(form)
    toggle()
  }

  return (
    <Modal isOpen={show} toggle={toggle}>
      {isFetching ? (
        <Loader />
      ): (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={toggle}>{translate("Add your quote")}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>{translate('Pickup date')}</Label>
              <Field
                component={FieldInput}
                type="date"
                name="pickupDate"
              />
            </FormGroup>
            <FormGroup>
              <Label>{translate('Delivery date')}</Label>
              <Field
                component={FieldInput}
                type="date"
                name="deliveryDate"
              />
            </FormGroup>
            <FormGroup>
              <Label>{translate('Price')}{" €"}</Label>
              <Field
                component={FieldInput}
                type="number"
                name="firstPrice"
              />
            </FormGroup>
            <FormGroup>
              <Label>{translate('Comment')}</Label>
              <Field
                component={FieldInput}
                type="text"
                name="comment"
                placeholder={translate("Type here extra comment")}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary"
                    onClick={toggle}>{translate("Cancel")}</Button>
            <Button color="primary" type="submit">{translate("Create")}</Button>{' '}
          </ModalFooter>
        </Form>
      )}
    </Modal>
  )
}

const mapDispatchToProps = (dispatch, props) => ({
  submitOffer: (offerData) => dispatch(createInquiryOffer(props.inquiry.id, offerData))
})

const mapStateToProps = state => ({
  isFetching: state.inquiryOfferReducer.isFetching,
  error: state.inquiryOfferReducer.error,
  inquiryOffer: state.inquiryOfferReducer.inquiryOffer
})

const decoratedAddOfferModal = connect(mapStateToProps, mapDispatchToProps)(AddOfferModal)

export default  reduxForm({
  form: 'createInquiryOfferForm',
  validate: offerValidation,
})(decoratedAddOfferModal)
