import React from "react";
import {connect} from "react-redux";
import {change, Field, formValueSelector, reduxForm} from 'redux-form';
import {Col, Row} from "reactstrap";
import {translate} from 'services/translationService';
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import Button from "components/CustomButton/CustomButton.jsx";
import {apiService} from "../../../../services/apiService";
import FieldSelect from "../../../../components/FormFields/FieldSelect/FieldSelect";
import Loader from "../../../../components/Loader/Loader";
import {editCarValidation} from "./editValidation";
import EditSpecification from "./EditSpecification";
import {containerHasFeatures, getContainerFeatures} from "../../../../helpers/containerHelper";

class EditCarForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            brandOptions: [],
            modelOptions: [],
            isLoadingBrand: true,
            isLoadingModel: true,
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.isFormReady = this.isFormReady.bind(this);
    }

    async getBrands(vehicleType) {
        return await apiService.get('/api/vehicles/brands/' + vehicleType);
    }

    async getModels(brand, vehicleType) {
        if (typeof (brand) === "object") {
            brand = 1
        }
        return await apiService.get(`/api/vehicles/brands/${brand}/models/${vehicleType}`);
    }

    async componentDidMount() {
        const {initialValues, vehicleType} = this.props;
        const initialBrand = initialValues.brand.id;
        let apiVehicleType = vehicleType === 'solo_truck' ? "car" : vehicleType;
        const brandOptions = await this.getBrands(apiVehicleType);
        const modelOptions = await this.getModels(initialBrand, apiVehicleType);
        await this.setState({
            brandOptions: brandOptions.data,
            modelOptions: modelOptions.data,
            isLoadingBrand: false,
            isLoadingModel: false
        });
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.brandValue !== this.props.brandValue && prevProps.brandValue !== undefined) {
            const {vehicleType} = this.props;
            let apiVehicleType = vehicleType === 'solo_truck' ? "car" : vehicleType;

            let brandValue = parseInt(this.props.brandValue.id);
            this.setState({
                isLoadingModel: true
            });
            const modelOptions = await this.getModels(brandValue, apiVehicleType);
            await this.setState({
                modelOptions: modelOptions.data,
                isLoadingModel: false
            });
            const initialBrandId = this.props.initialValues.brand.id;
            if (brandValue !== initialBrandId) {
                this.props.dispatch(change('editCarForm', 'model', modelOptions.data[0]));
            }
        }
    }

    onFormSubmit(formData) {
        this.props.onSubmitHandler(formData);
    }

    isFormReady() {
        const {vehicleType, containerValue} = this.props;
        switch (vehicleType) {
            case 'car':
            case 'truck_trailer':
                return !(this.props.invalid || Object.keys(containerValue).length === 0);
            case 'solo_truck':
                return !(this.props.invalid);
            default:
                return false;
        }
    }

    render() {
        const {vehicleType, initialValues} = this.props;
        const {brandOptions, modelOptions, isLoadingBrand, isLoadingModel} = this.state;
        return (
            <form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
                <Row>
                    <Col md={12}>
                        <h5>{translate('Basic vehicle data ')}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Field
                            label={translate('Register number')}
                            component={FieldInput}
                            name="registrationNumber"
                        />
                    </Col>
                    <Col md={6}>
                        <Field
                            label={translate("Year")}
                            name="productionYear"
                            component={FieldInput}
                        />
                    </Col>
                    <Col md={6}>
                        {isLoadingBrand ? <Loader/> :
                            <Field
                                label={translate('Brand')}
                                component={FieldSelect}
                                name="brand.id"
                                options={brandOptions}
                                emptyValue={false}
                            />}
                    </Col>
                    <Col md={6}>
                        {isLoadingModel ? <Loader/> :
                            <Field
                                label={translate('Model')}
                                component={FieldSelect}
                                name="model.id"
                                options={modelOptions}
                                emptyValue={false}
                            />}
                    </Col>
                </Row>
                <hr/>
                {(vehicleType === 'solo_truck') &&
                <Row>
                    <Col md={12}>
                        <h5>{translate('This vehicle has no container configuration')}</h5>
                    </Col>
                </Row>
                }
                {(vehicleType !== 'solo_truck') &&
                <Row>
                    <Col md={12}>
                        <h5>{translate('Detailed specification')}</h5>
                        {containerHasFeatures(initialValues.container) &&
                        <div>{translate('Container features: ')}{getContainerFeatures(initialValues.container)}</div>
                        }
                    </Col>
                    <Col md={12}>
                        <EditSpecification/>
                    </Col>
                </Row>
                }
                <Row>
                    <div className="update ml-auto mr-auto">
                        <Button color="primary" disabled={!this.isFormReady()}
                                round>{translate("Update Vehicle")}</Button>
                    </div>
                </Row>
            </form>
        )
    }
}

EditCarForm.defaultProps = {
    containerValue: {},
};

EditCarForm = reduxForm({
    form: 'editCarForm',
    enableReinitialize: true,
    validate: editCarValidation
})(EditCarForm);

const selector = formValueSelector('editCarForm');
EditCarForm = connect(state => {
    const brandValue = selector(state, 'brand');
    const modelValue = selector(state, 'model.id');
    const registrationNumberValue = selector(state, 'registrationNumber');
    const containerValue = selector(state, 'container');
    return {
        registrationNumberValue,
        containerValue,
        brandValue,
        modelValue
    }
})(EditCarForm);

export default EditCarForm;


