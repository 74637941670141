import {
    GET_ORDER_DOCS_FAILURE,
    GET_ORDER_DOCS_REQUEST,
    GET_ORDER_DOCS_SUCCESS
} from "../../actions/documents/orderDocuments.actions";

const initialState = {
    orderDocuments: [],
    isFetchingDocuments: true,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDER_DOCS_REQUEST: {
            return Object.assign({}, state, {
                isFetchingDocuments: true,
                error:false
            });
        }

        case GET_ORDER_DOCS_SUCCESS: {
            return Object.assign({}, state, {
                orderDocuments: action.data,
                isFetchingDocuments: false,
                error:false
            });
        }

        case GET_ORDER_DOCS_FAILURE: {
            return Object.assign({}, state, {
                isFetchingDocuments: false,
                error: true
            });
        }
        default:
            return state
    }
}
