import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AddDocumentForm from './AddDocumentForm';
import {translate} from 'services/translationService';

class AddDocument extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            showFormModal: false
        };
        this.toggleFormModal = this.toggleFormModal.bind(this);
    }

    toggleFormModal() {
        this.setState({
            showFormModal: !this.state.showFormModal
        });
    }

    render() {
            const {documentType, userId} = this.props;
            return(
                <div>
                    <div className="document-add-button mx-auto text-center" onClick={this.toggleFormModal}>
                         +
                    </div>

                    <Modal isOpen={this.state.showFormModal} toggle={this.toggleFormModal}>
                        <ModalHeader toggle={this.toggleFormModal}>
                            {translate("Add new document as")} {translate(documentType.name)} <br />

                        </ModalHeader>

                        <ModalBody>
                            <AddDocumentForm documentType={documentType} userId={userId} onFinish={this.toggleFormModal} />
                        </ModalBody>

                    </Modal>
                </div>
            )
    }
}


export default AddDocument;