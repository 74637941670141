import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import {authActions} from 'redux/actions';
import {translate} from 'services/translationService';
import {Card, CardBody, CardGroup, Col, Container, Row} from 'reactstrap';
import WelcomeHeader from "../components/WelcomeHeader/WelcomeHeader";
import Footer from "components/Footer/Footer";
import Loader from "components/Loader/Loader";
import {Logo} from "../components/Logo/Logo";
import {getConfig} from "../../../redux/actions";
import  "../style.scss"
import {history} from "../../../helpers";

class Login extends Component {

    constructor(props) {
        super(props);
        let possiblePasswordReset = true;
        const forgotPasswordTime = localStorage.getItem('forgotPasswordTime');
        if (forgotPasswordTime && (Date.now() - Date.parse(forgotPasswordTime) < 60000)) {
            possiblePasswordReset = false;
        }

        this.state = {
            email: '',
            password: '',
            submitted: false,
            possiblePasswordReset
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});
        const {email, password} = this.state;
        const {dispatch} = this.props;
        if (email && password) {
            dispatch(authActions.login(email, password));
            dispatch(getConfig());
        }
    }

    isValidEmail() {
        return this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    }

    static checkIfLoggedIn(props) {
        if (props.loggedIn) {
            history.push('/dashboard');
        }
    }

    render() {
        const {loggingIn} = this.props;
        const {email, password, submitted} = this.state;
        Login.checkIfLoggedIn(this.props);

        return (
         <div className="Site-content">
            <WelcomeHeader title={translate("Welcome to JANTA Freight App")}/>
            <div className="jumbotron min-height">
                <div className="flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8">

                                <CardGroup>
                                    <Card className="p-4">
                                        <CardBody>
                                            <form name="form" onSubmit={this.handleSubmit}>

                                                <div
                                                    className={'form-group' + (submitted && !this.isValidEmail() ? ' has-error' : '')}>
                                                    <label htmlFor="email"
                                                           className="control-label">{translate("User's email")} </label>
                                                    <input type="email" className="form-control" name="email"
                                                           value={email} onChange={this.handleChange}/>

                                                    {submitted && !this.isValidEmail(email) &&
                                                    <div
                                                        className="invalid-feedback"> {translate('Email is not valid')} </div>
                                                    }

                                                </div>

                                                <div
                                                    className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                                    <label htmlFor="password"
                                                           className="control-label"> {translate("Password")}</label>
                                                    <input type="password" className="form-control" name="password"
                                                           value={password} onChange={this.handleChange}/>

                                                    {submitted && !password &&
                                                    <div
                                                        className="invalid-feedback">{translate('Password is required')}</div>
                                                    }
                                                </div>

                                                <div className="form-group">
                                                    <button className="btn btn-primary"
                                                            disabled={!email && !password}> {translate('Login')} </button>
                                                    {loggingIn &&
                                                    <Loader/>
                                                    }
                                                    {this.state.possiblePasswordReset && <Link to={"/forgot-password"} className="forgot-password-link">
                                                        <p>{translate('Forgot your password?')}</p>
                                                    </Link>}
                                                </div>

                                            </form>
                                        </CardBody>
                                    </Card>

                                    <Card className="text-white bg-primary py-5 d-md-down-none">
                                        <CardBody className="text-center">
                                            <div>
                                                <h2>{translate("Join us")}</h2>
                                                <p>{translate('Start partnership with us as carrier!')} </p>
                                                <Link to={"/register"}>
                                                    <button type="button" className="btn btn-light register-button">
                                                    {translate('Click here to register')}
                                                    </button>
                                                </Link>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </CardGroup>
                            </Col>
                        </Row>
                    </Container>
                    <Logo />
                </div>
            </div>
            <Footer />
         </div>
        );
    }
}

const mapStateToProps = state => ({
    loggingIn: state.authReducer.loggingIn,
    loggedIn: state.authReducer.loggedIn,
    translation: state.translationReducer
});

export default connect(mapStateToProps)(Login);

