import React from "react";
import {connect} from "react-redux";
import {Card, CardBody, Col, Label, PopoverBody, Row, Table, UncontrolledPopover} from "reactstrap";
import {translate} from 'services/translationService';
import Loader from "../../../../components/Loader/Loader";
import {getCarSet} from "../../../../redux/actions/car";
import BodyTypeBadge from "../../BodyTypeBadge";
import {containerHasFeatures, getContainerFeatures} from "../../../../helpers/containerHelper";
import VehicleLocation from "../CarLocation/VehicleLocation";
import mapService from "../../../../services/mapService";

class MyCarSetDetails extends React.Component {

    constructor(props) {
        super(props);
        this.renderSetDetails = this.renderSetDetails.bind(this);
        this.renderVehicleDetails = this.renderVehicleDetails.bind(this);
        this.renderStorageSpecification = this.renderStorageSpecification.bind(this);
    }

    componentDidMount() {
        const {carSetId, dispatch} = this.props;
        dispatch(getCarSet(carSetId));
    }

    renderSetDetails(carSet){
        const totalLength =
            (carSet.car.hasOwnProperty('container') ? carSet.car.container.length : 0) +
            (carSet.truckTrailer.hasOwnProperty('container') ? carSet.truckTrailer.container.length : 0);
        const maxWeight = carSet.maxWeight;
        return <Card className="storage-details view-only">
            <CardBody>
                <Row>
                    <Col md={12}>
                                <h5>
                                    <i className="nc-icon nc-alert-circle-i"/> {translate('Set specification')}
                                </h5>
                        <hr/>
                    </Col>
                    <Col md={12}>
                        <Table size="sm" borderless>
                            <tbody>
                            <tr>
                                <th>
                                    <label>{translate('Vehicle set length')}: </label>
                                </th>
                                <td className="halfWidth">
                                    {totalLength} cm
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <Label>{translate("Vehicle set max weight")}</Label>
                                </th>
                                <td>{maxWeight} kg</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    }

    renderVehicleDetails(title, vehicle) {
        return <Card className="vehicle-details view-only">
            <CardBody>
                <Row>
                    <Col md={12}>
                        <h5><i
                            className="nc-icon nc-alert-circle-i"/> {title}
                        </h5>
                        <hr/>
                    </Col>
                    <Col md={12}>
                        <Table size="sm" borderless>
                            <tbody>
                            <tr>
                                <th>
                                    <label>{translate('Model / Brand')}: </label>
                                </th>
                                <td className="halfWidth">
                                    {vehicle.brand.name} {vehicle.model.name}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label>{translate('Register number')}: </label>
                                </th>
                                <td>
                                    {vehicle.registrationNumber}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <Label>{translate("Production Year")}</Label>
                                </th>
                                <td>
                                    {vehicle.productionYear}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    }

    renderStorageSpecification(title, container) {
        return <Card className="storage-details view-only">
            <CardBody>
                <Row>
                    <Col md={12}>
                        <h5><i
                            className="nc-icon nc-alert-circle-i"/> {title}
                        </h5>
                        <hr/>
                        <Table size="sm" borderless>
                            <tbody>
                            <tr>
                                <th>
                                    <label>{translate('Dimensions (L/W/H)')}: </label>
                                </th>
                                <td className="halfWidth">
                                    {container.length} / {container.width} / {container.height} cm
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label id="wheel_arch"
                                           style={{cursor: "help"}}>{translate('Wheel Arch')}: </label>
                                    <UncontrolledPopover trigger="legacy" placement="top"
                                                         target="wheel_arch">
                                        <PopoverBody>
                                            {translate("wheel arch distance to front / wheel arch height / wheel arch length / wheel arch width")}
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </th>
                                <td>
                                    {container.wheelArchDistanceToFront} / {container.wheelArchHeight} / {container.wheelArchLength} / {container.wheelArchWidth}
                                    cm
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <Label>{translate("Doors (width/height)")}</Label>
                                </th>
                                <td>{container.doorWidth} / {container.doorHeight} cm</td>
                            </tr>
                            <tr>
                                <th>
                                    <Label>{translate("Max weight")}</Label>
                                </th>
                                <td>{container.maxWeight} kg</td>
                            </tr>
                            <tr>
                                <th>
                                    <label id="maxPressure"
                                           style={{cursor: "help"}}>{translate('Max Pressure')}: </label>
                                    <UncontrolledPopover trigger="legacy" placement="top"
                                                         target="maxPressure">
                                        <PopoverBody>
                                            {translate("Max pressure for m2 in kg")}
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </th>
                                <td>
                                    {container.maxPressure} kg/m2
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <Label>{translate("Body Type")}:</Label>
                                </th>
                                <td><BodyTypeBadge specification={container}/></td>
                            </tr>
                            {containerHasFeatures(container) && <tr>
                                <th>
                                    <Label>{translate("Container features")}</Label>
                                </th>
                                <td>
                                    {getContainerFeatures(container)}
                                </td>
                            </tr>}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    }

    render() {
        const {carSet, isFetching} = this.props;
        if (isFetching) {
            return (
                <div className="content">
                    <Loader/>
                </div>
            )
        }
        const showLocation = mapService.showCarPosition(carSet.car);
        return <div className="content">
            <div>
                <Row>
                    <Col md={6}>
                        {this.renderVehicleDetails(translate('Car details'), carSet.car)}
                    </Col>
                    {carSet.truckTrailer &&
                    <Col md={6}>
                        {this.renderVehicleDetails(translate('Trailer details'), carSet.truckTrailer)}
                    </Col>
                    }
                </Row>
                <Row>
                    {carSet.car.container && <Col md={6}>
                        {this.renderStorageSpecification(translate('Car storage specification'), carSet.car.container)}
                    </Col>
                    }
                    {carSet.truckTrailer &&
                    <Col md={6}>
                        {this.renderStorageSpecification(translate('Trailer storage specification'),carSet.truckTrailer.container)}
                    </Col>
                    }
                </Row>
                <Row>
                {carSet.truckTrailer &&
                        <Col md={6}>
                                {this.renderSetDetails(carSet)}
                        </Col>
                }
                </Row>
                {showLocation && <Row>
                        <Col md={12}>
                            <VehicleLocation carData = {carSet.car} />
                        </Col>
                </Row>}
            </div>
        </div>
    }
}

MyCarSetDetails.defaultProps = {
    isFetching: true,
    carSet: {},
};

const mapStateToProps = (state) => {
    return {
        carSet: state.carSetReducer.carSet,
        isFetching: state.carSetReducer.isFetching,
    }
};

export default connect(mapStateToProps)(MyCarSetDetails);


