import {
    FETCH_MULTIPLE_ORDERS_REQUEST,
    FETCH_MULTIPLE_ORDERS_SUCCESS,
    FETCH_MULTIPLE_ORDERS_FAILURE,

} from 'redux/actions/orders/multipleOrders.actions.js';


const initialState = {
    possibleStatuses:[],
    multipleOrders:[],
    isFetching: true,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MULTIPLE_ORDERS_REQUEST:
        {
            return Object.assign({}, state, {
                isFetching: true,
            });
        }

        case FETCH_MULTIPLE_ORDERS_SUCCESS: {
            return Object.assign({}, state, {
                multipleOrders: action.data.data.simpleOrdersData,
                possibleStatuses:  action.data.data.possibleStatuses,
                isFetching: false,
            });
        }

        case FETCH_MULTIPLE_ORDERS_FAILURE:
        {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }
        default:
            return state
    }
}
