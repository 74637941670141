import { apiService } from "services/apiService";
import { alertActions } from "redux/actions";
import getErrorText from "services/errorTextService";

export const FETCH_CAR_SETS_REQUEST = 'FETCH_CAR_SETS_REQUEST';
export const FETCH_CAR_SETS_SUCCESS = 'FETCH_CAR_SETS_SUCCESS';
export const FETCH_CAR_SETS_FAILURE = 'FETCH_CAR_SETS_FAILURE';

export function getCarSets(params) {
    return dispatch => {
        dispatch(request({}));
        apiService.get('/api/vehicles/sets', params)
            .then(
                response => {
                    dispatch(success(response));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: FETCH_CAR_SETS_REQUEST, data } }
    function success(data) { return { type: FETCH_CAR_SETS_SUCCESS, data } }
    function failure(error) { return { type: FETCH_CAR_SETS_FAILURE, error } }
}
