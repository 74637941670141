import React from "react";
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row, Table} from "reactstrap";
import {stringHelper} from 'helpers/stringHelper';
import {translate} from 'services/translationService';
import {apiService} from 'services/apiService';
import RoleBadge from "../../RoleBadge";
import UserStatus from "../../UserStatus/UserStatus";
import {alertActions} from "redux/actions";
import {connect} from "react-redux";
import {canUnlinkBeRendered, getCarInfo} from "../../../../helpers/driverHelper";
import ModalFooter from "reactstrap/es/ModalFooter";
import getErrorText from "../../../../services/errorTextService";
import {getUserInfo, getUsers} from "../../../../redux/actions/users";
import loggedUserService from "../../../../services/loggedUserService";
import NotificationForm from "./NotificationForm";
import MediaQuery from 'react-responsive';
import CustomInput from "reactstrap/es/CustomInput";
import HelperButton from "../../../../components/Helper/HelperButton";

class UserDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            unlinkFormOpen: false,
            unlinkButtonClicked:false,
            notificationModalOpen:false,
            isDefaultAdmin:props.user.isDefaultAdmin
        });
        this.sendActivationEmail = this.sendActivationEmail.bind(this);
        this.unlinkCar = this.unlinkCar.bind(this);
        this.requestUnlink = this.requestUnlink.bind(this);
        this.toggleUnlinkFormOpen = this.toggleUnlinkFormOpen.bind(this);
        this.toggleNotificationFormOpen = this.toggleNotificationFormOpen.bind(this);
        this.renderUnlinkRequestButton = this.renderUnlinkRequestButton.bind(this);
        this.toggleDefaultAdmin = this.toggleDefaultAdmin.bind(this);
    }

    toggleUnlinkFormOpen(){
        this.setState({
            unlinkFormOpen:!this.state.unlinkFormOpen
        })
    }
    toggleNotificationFormOpen(){
        this.setState({
            notificationModalOpen:!this.state.notificationModalOpen
        })
    }

    sendActivationEmail() {
        apiService.sendActivation(this.props.user.id)
            .then(
                response => {
                    this.props.dispatch(alertActions.success(response.messages[0]));
                })
            .catch(errorMessage => {
                this.props.dispatch(alertActions.error(errorMessage));
            });
    };

    unlinkCar(){
        const userId = this.props.user.id;
        const carId = this.props.user.vehicleSet.car.id;
        const {dispatch} = this.props;
        this.toggleUnlinkFormOpen();
        apiService.apiDelete(`/api/vehicles/${carId}/drivers/${userId}`)
            .then(
                response => {
                    dispatch(alertActions.success(translate('Driver successfully unlinked from car')));
                    dispatch(getUsers({
                        perPage: this.props.pageParams.perPage,
                        page: this.props.pageParams.page
                    }));
                    dispatch(getUserInfo(userId));
                })
            .catch(errorMessage => {
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    }

    toggleDefaultAdmin(){
        const userId = this.props.user.id;
        const {dispatch} = this.props;
        this.setState({
            isDefaultAdmin: true
        })
        apiService.put('/api/accounts/default/' +  userId)
          .then(
            response => {
                dispatch(alertActions.success(translate("Default manager set successfully")));
            })
          .catch(errorMessage => {
              this.setState({
                  isDefaultAdmin: false
              })
              dispatch(alertActions.error(translate("Default manager was not set")));
          });
    }

    requestUnlink(car){
        const {dispatch} = this.props;
        const currentTime = Date.now();
        const unlinkCarInfo = {
            registrationNumber:car.registrationNumber,
            time:currentTime
        };
        localStorage.setItem('unlinkCarInfo',JSON.stringify(unlinkCarInfo));
        this.setState({
            unlinkButtonClicked:true
        });
        apiService.post(`/api/vehicles/driver-request`)
            .then(
                response => {
                    dispatch(alertActions.success(translate('Request for car unlink sent to managers')));
                })
            .catch(errorMessage => {
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    }

    renderUnlinkRequestButton(car){
        const renderButton = (!this.state.unlinkButtonClicked) && canUnlinkBeRendered(car);
        return renderButton ? <Button className="request-unlink-button" size="sm" color="success"
                                      title={translate("Request car unlink")} onClick={()=>this.requestUnlink(car)}>
            <i style={{fontSize: '1.3em'}} className="nc-icon nc-lock-circle-open"/>
        </Button> : ""
    }

    render() {
        const user = this.props.user;
        const showDefaultAdminSwitch = user.hasOwnProperty('isDefaultAdmin') && user.status === 'active' && loggedUserService.isManager();
        const currentDefaultAdmin = user.hasOwnProperty('defaultAdmin') ? stringHelper.personToString(user.defaultAdmin) : "";
        const car = user.vehicleSet ? user.vehicleSet.car : null;
        return(
            <div>
            <Row>
                <Col md={12}>
                    <Card className="user-details">
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <h5><i className="nc-icon nc-single-02" /> { stringHelper.personToString(user) } </h5>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <Table size="sm" borderless>
                                        <tbody>
                                        <tr>
                                            <th>
                                                <i className="nc-icon nc-chat-33" />
                                                <label>{translate('Phone')}: </label>
                                            </th>
                                            <td>
                                                <a className="phone" href={`tel:${user.address.phone}`}>{user.address.phone}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <i className="nc-icon nc-email-85" />
                                                <label>{translate('Email')}: </label>
                                            </th>
                                            <td>
                                                <a className="email" href={`mailto:${user.email}`}>{user.email}</a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>

                                <Col md={4}>
                                    <Table size="sm" borderless>
                                        <tbody>
                                        <tr>
                                            <th>
                                                <i className="nc-icon nc-briefcase-24" />
                                                <label>{translate('Roles')}: </label>
                                            </th>
                                            <td>
                                                <RoleBadge roles={user.roles}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <i className="nc-icon nc-delivery-fast" />
                                                <label>{translate('Assigned Car')}: </label>
                                            </th>
                                            <td>{car?
                                                (loggedUserService.isManager()) ?
                                                    <Button className="car-unlink-button" color="secondary"
                                                        outline size="sm" title={translate("Unlink car")}
                                                        onClick={this.toggleUnlinkFormOpen}>
                                                    X {getCarInfo(car)}
                                                    </Button>
                                                        :
                                                    <span>{getCarInfo(car)}
                                                        {this.renderUnlinkRequestButton(car)}
                                                    </span>
                                                :
                                                <span><i style={{fontSize: '1em', color:'red'}} className="nc-icon nc-simple-remove icon-cross"/></span>
                                            }
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>

                                <Col md={4}>
                                    <Table size="sm" borderless>
                                        <tbody>
                                        <tr>
                                            <th>
                                                <i className="nc-icon nc-alert-circle-i" />
                                                <label>{translate('Status')}</label>
                                            </th>
                                            <td>
                                                <UserStatus status={user.status} id={user.id} />
                                                {user.status === "new" ?
                                                    <Button onClick={this.sendActivationEmail} color="info" size="sm">
                                                        {translate('Send activation email')}
                                                    </Button>
                                                    : ''
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <i className="nc-icon nc-bell-55" />
                                                <label>{translate('Notifications')}</label>
                                            </th>
                                            <td>
                                                <i className="nc-icon nc-settings-gear-65 notifications-setup" onClick={this.toggleNotificationFormOpen}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>

                                {showDefaultAdminSwitch && <MediaQuery query='(max-device-width: 859px)'>
                                    <Col >
                                        <Table size="sm" borderless>
                                            <tbody>
                                            <tr>
                                                <th>
                                                    <i className="nc-icon nc-badge" />
                                                    <label>{translate('Default manager')}</label>
                                                </th>
                                                <td className={"td-admin-switch"}>
                                                    <CustomInput type="switch" id={`admin-switch`}
                                                                 checked={this.state.isDefaultAdmin}
                                                                 onChange={this.toggleDefaultAdmin}
                                                                 disabled={this.state.isDefaultAdmin}
                                                    />
                                                    {!this.state.isDefaultAdmin && <>
                                                        <div className={"td-present-admin"}>
                                                            {translate("Currently") + ': ' + currentDefaultAdmin}
                                                        </div>
                                                    </>
                                                    }
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </MediaQuery>
                                }


                            </Row>
                        </CardBody>
                        <HelperButton helperId="carrier-user-details" />
                    </Card>
                </Col>
            </Row>
                <Modal id="confirm-driver-unlink" isOpen={this.state.unlinkFormOpen} toggle={this.toggleUnlinkFormOpen}>
                    <ModalHeader toggle={this.toggleUnlinkFormOpen}>
                        {translate("Confirm unlink operation")}
                    </ModalHeader>
                    <ModalBody>
                        <div>{translate("Driver: ")}<strong>{stringHelper.personToString(user)}</strong>{"   "}
                            {" <> "}{translate("Car: ")}<strong>{getCarInfo(car)}</strong></div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.unlinkCar}>
                            <i style={{fontSize: '1.3em'}} className="nc-icon nc-lock-circle-open"/>{translate("Unlink")} </Button>
                        <Button onClick={this.toggleUnlinkFormOpen}>{translate("Cancel")}</Button>
                    </ModalFooter>
                </Modal>
                <Modal id="notifications-setup-modal" isOpen={this.state.notificationModalOpen} toggle={this.toggleNotificationFormOpen}>
                    <ModalHeader toggle={this.toggleNotificationFormOpen}>
                        <i className="nc-icon nc-settings-gear-65"/>{" " + translate("Notifications settings")}
                    </ModalHeader>
                    <NotificationForm toggle={this.toggleNotificationFormOpen}/>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    locale: state.translationReducer.locale,
    pageParams: state.paginationReducer.userPaginator
});

export default connect(mapStateToProps)(UserDetails);
