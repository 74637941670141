import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {translate} from "../../../services/translationService";
import {apiService} from "services/apiService";
import loggedUserService from "services/loggedUserService";
import PrivatePolicyDe from "./PrivatePolicyDe";
import "../style.scss";
import PrivatePolicyPl from "./PrivatePolicyPl";
import PrivatePolicyEn from "./PrivatePolicyEn";

class PrivatePolicy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: true
        };

        this.toggle = this.toggle.bind(this);
        this.acceptRules = this.acceptRules.bind(this);
        this.getLanguageComponent = this.getLanguageComponent.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    acceptRules(){
        const newStatus = loggedUserService.isDriver() ? "pending" : "active";
        this.toggle();
        apiService.put("/api/accounts/"+loggedUserService.getUserId(),
            {"status": newStatus}
            ).then( ()=> {
                apiService.getRefreshToken();
        }
        );
    }

    getLanguageComponent(){
        let language = localStorage.getItem('locale').toLowerCase();
        let component;
        switch (language)
        {
            case 'pl':
                component = PrivatePolicyPl;
                break;
            case 'en':
                component = PrivatePolicyEn;
                break;
            case 'de':
            default:
                component = PrivatePolicyDe;
        }
        return component;
    }

    render() {
        const LanguageComponent = this.getLanguageComponent();
            return (
            <div className="content">
                <Modal isOpen={this.state.modal} size="xl" className="GDPR-modal">
                    <ModalBody>
                        {LanguageComponent()}
                    </ModalBody>
                    <ModalFooter className="text-center justify-content-center">
                        <Button color="primary" onClick={this.acceptRules}>{translate("Accept")}</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default PrivatePolicy;

