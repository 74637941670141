import React, {Component} from 'react';
import {translate} from 'services/translationService';
import {Jumbotron} from 'reactstrap';
import {Link} from "react-router-dom";
import WelcomeHeader from "../components/WelcomeHeader/WelcomeHeader";
import {Logo} from "../components/Logo/Logo";
import Footer from "../../../components/Footer/Footer";
import "./style.scss"

class DeletedAccount extends Component {

  render() {
    return (
        <div className="Site-content">
          <WelcomeHeader title={translate("Your account was deleted")} />
          <Jumbotron className="text-center m-5 bg-light">
            <h5>{translate("Data related to your account was removed from the system")}</h5>
            <Link to="/login">← {translate("Back to login page")}</Link>
          </Jumbotron>
          <Logo />
          <Footer />
        </div>
    );
  }
}

export default DeletedAccount;
