import React from 'react';
import {FormGroup, Label} from 'reactstrap';
import Multiselect from 'react-widgets/lib/Multiselect'
import {translate} from 'services/translationService';

import 'react-widgets/dist/css/react-widgets.css'

const FieldMultiSelect = ({
                           input,
                           options,
                           valueField,
                           textField,
                            label,
                           meta: { touched, error, warning },
                           labelClassName,
                           inputClassName,
                           className,
                           itemComponent,
                           disabled,
                       }) => {



    return (
        <FormGroup className={` ${className}`}>

                    {
                        label &&
                        <div className={`${labelClassName} labelContainer`}>
                            <Label>{label}</Label>
                        </div>
                    }
                    <div className={inputClassName}>
                        <Multiselect {...input}
                                     onBlur={() => input.onBlur()}
                                     value={input.value || []} // requires value to be an array
                                     data={options}
                                     valueField={valueField}
                                     textField={textField}
                                     className={touched && (error || warning) ? 'input-invalid form-control' : 'form-control'}

                        />
                        {
                            touched &&
                            (
                                (error && <span className="error invalid-feedback ml-2">{translate(error)}</span>) ||
                                (warning && <span className="warning invalid-feedback ml-2">{translate(warning)}</span>)
                            )
                        }
                    </div>
        </FormGroup>
    );
};


FieldMultiSelect.defaultProps = {
    placeholder: '',
    inputId: '',
    label: '',
    className: 'row',
    labelClassName: 'col-md-3',
    inputClassName: 'col',
    disabled: false,
    inputValue: '',
};

export default FieldMultiSelect;
