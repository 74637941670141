import React from "react";
import {Button, Col, Row} from "reactstrap";
import {translate} from "../../services/translationService";
import {Input} from "reactstrap";


class PalletBalance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false
        };

        this.toggleEdit = this.toggleEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    toggleEdit() {
        this.setState({
            edit: !this.state.edit
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        let data = {};
        let mainKey = '';
        for (const [key, value] of formData.entries()) {
            mainKey = key.substring(0, key.indexOf('['));
            let subKey = key.substring(key.indexOf('[')+1, key.indexOf(']'));
            data[subKey] = parseInt(value || 0);
        }
        let formObject = {};
        formObject[mainKey] = data.left - data.received;
        this.props.onSubmitHandler(formObject);
    }

    render() {
        return (
            !this.state.edit ?
                <Row>
                    <Col>
                        <span>{parseInt(isNaN(this.props.balanceValue) ? 0 : this.props.balanceValue)}</span>
                    </Col>
                    <Col className="align-right">
                            <Button color="success" size="sm"
                                    onClick={this.toggleEdit}>{translate('Change')}
                            </Button>
                    </Col>
                </Row>
                :
                <form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Input
                                type="number"
                                name={this.props.name+"[left]"}
                                placeholder={translate("Were left")}
                                min={0}
                                step={1}
                            >
                            </Input>
                        </Col>
                        <Col>
                            <Input
                                type="number"
                                name={this.props.name+"[received]"}
                                placeholder={translate("Received")}
                                min={0}
                                step={1}
                            >
                            </Input>
                        </Col>
                        <Col className="align-right">
                            <Button color="success" size="sm">{translate('Save')}</Button>
                            <Button onClick={this.toggleEdit} outline color="danger"
                                    size="sm">{translate('Cancel')}</Button>
                        </Col>
                    </Row>
                </form>
        )
    }
}

export default PalletBalance;
