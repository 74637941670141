import React from "react";
import {Badge} from "reactstrap";
import {Link} from "react-router-dom";

const NotificationBell = ({counter}) => {
    return (
        <Link to="/notifications" className="nav-link btn-magnify">
            <i className="nc-icon nc-bell-55"/>
            { counter > 0 ?
                <Badge color="success">{counter}</Badge>
                : ""
            }
        </Link>
    )
};

export default NotificationBell;
