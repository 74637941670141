import React from "react";
import {Field, FormSection, reduxForm} from 'redux-form';
import {Col, Row} from "reactstrap";
import FieldInput from 'components/FormFields/FieldInput/FieldInput';
import {translate} from 'services/translationService';
import Button from "components/CustomButton/CustomButton.jsx";
import Address from 'components/FormSections/Address';
import AddressContact from 'components/FormSections/AddressContact';
import loggedUserService from "../../services/loggedUserService";
import {carrierValidationUpdate} from "./validation";
import FieldSelect from "components/FormFields/FieldSelect/FieldSelect";
import {billingPeriodsForSelect} from "helpers/billingPeriods";
import {connect} from "react-redux";
import {translateFormLabels} from "../../helpers/translateFormLabels";

class CarrierForm extends React.Component {

    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onIsVatPayerChange = this.onIsVatPayerChange.bind(this);
        this.getIsVatPayer = this.getIsVatPayer.bind(this);
        this.state = {
              isVatPayer: props.initialValues.isVatPayer,
              labels: translateFormLabels()
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale){
            this.setState({labels: translateFormLabels()});
        }
    }

    onIsVatPayerChange(event, newValue) {
        this.setState({isVatPayer: newValue});
    }

    onFormSubmit(formData) {
        this.props.onSubmitHandler(formData);
    }

    getIsVatPayer() {
        let isVatPayer = this.state.isVatPayer;
        if (typeof isVatPayer === 'string') {
            isVatPayer = parseInt(isVatPayer, 10);
        }
        return isVatPayer ? 1 : 0;
    }



    render() {
        const {labels} = this.state;
        const disabled = loggedUserService.isCarrierActive() || !loggedUserService.isManager();
        return (
            <form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
                <Row>
                    <Col sm={6}>
                        <Field
                            name="name"
                            type="text"
                            label={labels.carrierName}
                            disabled={disabled}
                            component={FieldInput}
                        />
                    </Col>
                </Row>

                <FormSection name="address">
                    <Address disabled={disabled} formName={'carrierForm'}/>
                </FormSection>

                <FormSection name="address">
                    <AddressContact disabled={disabled}/>
                </FormSection>
                <div>
                    <h5>{ translate("Bank and tax data") }</h5>
                <Row>
                    <Col sm={6}>
                        <Field
                            name="bankName"
                            label={labels.carrierBankName}
                            disabled={disabled}
                            component={FieldInput}
                        />
                    </Col>
                    <Col sm={6}>
                        <Field
                            name="taxNumberUe"
                            label={labels.carrierTaxNumberUe}
                            disabled={disabled}
                            component={FieldInput}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Field
                            name="iban"
                            label={labels.carrierIban}
                            disabled={disabled}
                            component={FieldInput}
                        />
                    </Col>
                    <Col sm={6}>
                        <Field
                            name="taxNumberDe"
                            label={labels.carrierTaxNumberDe}
                            disabled={disabled}
                            component={FieldInput}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Field
                            name="billingPeriod"
                            options={billingPeriodsForSelect(this.props.billingPeriods)}
                            label={labels.carrierBillingPeriod}
                            component={FieldSelect}
                            emptyValue={false}
                            disabled={disabled}

                            />
                    </Col>
                    <Col sm={6}>
                        <Field
                            name="isVatPayer"
                            options={[
                                {id: 0, name: translate("No")},
                                {id: 1, name: translate("Yes")},
                            ]}
                            label={labels.carrierIsVatPayer}
                            component={FieldSelect}
                            emptyValue={false}
                            onChange={this.onIsVatPayerChange}
                            selectedValue={this.getIsVatPayer()}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
                </div>

                {disabled ? null :
                    <Row>
                        <div className="update ml-auto mr-auto">
                            <Button color="primary" round>{translate("Update Carrier")}</Button>
                        </div>
                    </Row>
                }
            </form>
        )
    }
}

const mapStateToProps = state => ({
    locale: state.translationReducer.locale,
});

const decoratedComponent = connect(mapStateToProps)(CarrierForm);

export default reduxForm({
    form: 'carrierForm',
    validate: carrierValidationUpdate
})(decoratedComponent);
