import {apiService} from "services/apiService";
import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {history} from "../../../helpers";
import {translate} from "../../../services/translationService";
import {getRemarkDataForShipmentId, prepareRemarkDataForMultiple} from "../../../helpers/formHelper";
import {apiFileService} from "../../../services/apiFileService";
import {priceApiService} from "../../../services/priceApiService";

export const ADD_MULTIPLE_STATUSES_REQUEST = 'ADD_MULTIPLE_STATUSES_REQUEST';
export const ADD_MULTIPLE_STATUSES_SUCCESS = 'ADD_MULTIPLE_STATUSES_SUCCESS';
export const ADD_MULTIPLE_STATUSES_FAILURE = 'ADD_MULTIPLE_STATUSES_FAILURE';

export function addMultipleStatuses(statusesParams, remarksData = {}, codeData = null) {
    return dispatch => {
        dispatch(request());
        apiService.post('api/shipments/lifecycles/multiple', statusesParams )
            .then(
                async (response) => {
                    const lifecyclesIdsObject = response.data;
                    for (const shipmentId in lifecyclesIdsObject) {
                        const [orderId, remarkData] = getRemarkDataForShipmentId(remarksData, parseInt(shipmentId));
                        let requests = [];
                        if (remarkData.hasRemarks && orderId){
                            const formData = prepareRemarkDataForMultiple(remarkData, lifecyclesIdsObject[shipmentId]);
                          requests.push(apiFileService.uploadFile(`api/shipments/${orderId}/remark`, formData));
                        }
                        await Promise.all(requests);
                    }
                    if (codeData) {
                      codeData.shipments.forEach((shipment,i)=>{
                        codeData.shipments[i] = {...shipment, id_life_cycle:lifecyclesIdsObject[shipment.id_shipment]}
                      })
                      try {
                        await priceApiService.useCode(codeData);
                      } catch (error) {
                        dispatch(alertActions.error(translate('Code was not used, please try again')));
                      }
                      try {
                        const generateDocumentsData = prepareGenerateDocumentsData(statusesParams, lifecyclesIdsObject, codeData );
                        await apiService.post('api/shipments/generate-documents', generateDocumentsData );

                      } catch(errorMessage) {
                        dispatch(alertActions.error(getErrorText(errorMessage)));
                      }
                    }
                    dispatch(success(response));
                    dispatch(alertActions.success(translate('Statuses were successfully set!')));
                    history.push('/dashboard');
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                history.push('/dashboard');
            });
    };

    function request(data) { return { type: ADD_MULTIPLE_STATUSES_REQUEST, data } }
    function success(data) { return { type: ADD_MULTIPLE_STATUSES_SUCCESS, data } }
    function failure(error) { return { type: ADD_MULTIPLE_STATUSES_FAILURE, error } }

  function prepareGenerateDocumentsData(statusesParams, lifecyclesIdsObject, codeData) {
    let shipmentIds = [];
    let orderIds = [];
    codeData.shipments.forEach(shipment => {
      shipmentIds.push(parseInt(shipment.id_shipment));
      orderIds.push(parseInt(shipment.id_order));
    })

    return {
      code: codeData.code,
      operationType: statusesParams.operationType,
      confirmerName: codeData.hasOwnProperty("confirmer_name") ? codeData.confirmer_name : "",
      shipmentIds,
      orderIds
    }
  }
}
