export const getSearchParamsForCarsList = (listType) => {
    let carsSearchParams = localStorage.getItem('carsSearchParams');
    if (carsSearchParams === null) {
        return null
    }
    carsSearchParams = JSON.parse(carsSearchParams);
    return carsSearchParams.hasOwnProperty(listType) ? carsSearchParams[listType] : null
};

export const storeSearchParamsForCarsList = (listType, searchParams) => {
    let carsSearchParams = localStorage.getItem('carsSearchParams');
    if (carsSearchParams == null) {
        carsSearchParams = {}
    } else {
        carsSearchParams = JSON.parse(carsSearchParams)
    }
    carsSearchParams[listType] = searchParams;
    localStorage.setItem('carsSearchParams', JSON.stringify(carsSearchParams));
};

export const clearSearchParamsForCarsList = (listType) => {
    let carsSearchParams = localStorage.getItem('carsSearchParams');
    if (!(carsSearchParams == null)) {
        carsSearchParams = JSON.parse(carsSearchParams);
        delete carsSearchParams[listType];
        localStorage.setItem('carsSearchParams', JSON.stringify(carsSearchParams));
    }
};