import React, {useState} from 'react';
import {Button, FormGroup, Input, Label} from "reactstrap";
import {translate} from "../../services/translationService";
import {connect} from "react-redux";
import {createInquiryOfferMessage} from "../../redux/actions/inquiries/inquiryOfferMessage.actions";

const InquiryMessageForm = (props) => {

    const [offerMessage, setOfferMessage] = useState("");
    const [offerPrice, setOfferPrice] = useState("");

    const changeMessage = (e) => {
        setOfferMessage(e.target.value);
    }

    const changePrice = (e) => {
        setOfferPrice(e.target.value.replace(/([^0-9,.]+)/gi, ''));
    }

    const submitMessage = () => {
        let messageData = {
                message: offerMessage
        };
        if (offerPrice.length > 0){
            messageData.priceNet = parseFloat(offerPrice.replace(/,/g,'.'));
        }
        const {inquiryId, offerId, dispatch} = props;
        dispatch(createInquiryOfferMessage(inquiryId, offerId, messageData))
    }

    return <div className={"message-input"}>
        <div className={"message-text"}>
            <FormGroup>
                <Label for="offer-message">{translate("Your message")}</Label>
                <Input type="textarea"
                       maxLength="250"
                       id="offer-message"
                       name="offer-message"
                       value={offerMessage}
                       onChange={changeMessage}
                       title={translate("Required field")}
                       placeholder={translate("Enter your message here (required)")}
                />
            </FormGroup>
        </div>
        <div className={"message-price"}>
            <FormGroup>
                <Label for="offer-price">{translate("New price")}{": €"}</Label>
                <Input type="text"
                       id="offer-price"
                       name="offer-price"
                       value={offerPrice}
                       onChange={changePrice}
                       placeholder={translate("Optional")}
                       title={translate("Optional field")}
                />
            </FormGroup>
        </div>
        <div className={"message-send"}>
            <Button color="primary" disabled={offerMessage.length === 0} onClick={submitMessage}>
                <span className="send-text">{translate("Send") + " "}</span>
                <i className="nc-icon nc-send"/>
            </Button>
        </div>
    </div>
}

const mapStateToProps = state => ({
    isFetching: state.inquiryOfferMessageReducer.isFetching,
    error: state.inquiryOfferMessageReducer.error,
    inquiryOfferMessage: state.inquiryOfferMessageReducer.inquiryOffer
})

export default connect(mapStateToProps)(InquiryMessageForm)
