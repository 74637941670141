import React from "react";
import {Badge} from "reactstrap";
import {apiService} from "../../services/apiService";
import getErrorText from "../../services/errorTextService";

class NewInquiriesCounter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: 0
        };
        this.fetchInquiriesCount = this.fetchInquiriesCount.bind(this);
    }

    componentDidMount() {
        let oldCounter = localStorage.getItem('inquiriesNewCounter');
        if(oldCounter){
            this.setState({
                counter: oldCounter
            });
        }
        this.fetchInquiriesCount();
        this.interval = setInterval(() => {
            this.fetchInquiriesCount()
        }, 20000)
    }

    componentWillUnmount() {
        localStorage.setItem('inquiriesNewCounter', this.state.counter);
        clearInterval(this.interval);
    }

    fetchInquiriesCount() {
        apiService.get('/api/inquiries/counter/new').then(
            response => {
                const newCounter = response.data.count;
                if (this.state.counter !== newCounter){
                    this.setState({
                        counter: response.data.count
                    });
                }
            })
            .catch(errorMessage => {
                console.log(getErrorText(errorMessage));
            });
    }

    render() {
        const {counter} = this.state;
        return counter > 0 ? <Badge color="success" className="inquiryCounter">{counter}</Badge> : ""
    }
}

export default NewInquiriesCounter;