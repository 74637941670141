import React from "react";

class CodeDisplay extends React.Component{

    render() {
        const {focused, code} = this.props;
        const style = focused ? {borderWidth: "3px", borderColor: "darkred"} : {}
        return <div className={"code-display"} style={style}>
            {typeof code === 'string' ?
                [...code].map((digit, key) => <span className="code-digit" key={key}>{digit}</span>) : ""
            }
        </div>
    }
}

export default CodeDisplay;


