import React, {Component} from 'react';
import {translate} from "../../../services/translationService";
import "./styles.scss"
import {Input} from "reactstrap";
import {getInputErrorClass} from "../../../helpers/formHelper";

class MaxWeight extends Component {

  constructor(props) {
    super(props);
    this.updateMaxWeight = this.updateMaxWeight.bind(this)
  }

  updateMaxWeight(e) {
    let newInputs = {...this.props.configInputs};
    const inputValue = e.target.value;
    if (e.target.name === 'maxCurrentMeterWeight') {
      newInputs['maxCurrentMeterWeight'] = inputValue;
    } else {
      const serviceCode = e.target.name.split('-')[2];
      newInputs.maxWeightPerService[serviceCode] = inputValue
    }
    this.props.updateInputs(newInputs)
  }

  render() {
    const {configInputs, availableServices} = this.props;
    if (Object.keys(configInputs.availableServices).length !== availableServices.length) return null;
    return <>
      <div className="centered"><h5 className="cooperation-title">{translate("Max weight rules")}</h5></div>
      <div className="max-weight-table">
        <div className="max-weight-title">
          <div>{translate("Cargo option")}</div>
          <div className="centered">{translate("Maximum Weight") + " [kg]"}</div>
        </div>
        {availableServices.map((service, key) => <div className="max-weight-row" key={key}>
          <div className={!configInputs.availableServices[service.service_code]  ? "option-disabled" : ""}>
            {service.service_name}
          </div>
          <div>
            <Input
              name={`max-weight-${service.service_code}`}
              id={`max-weight-${service.service_code}`}
              value={configInputs.maxWeightPerService[service.service_code]}
              onChange={this.updateMaxWeight}
              type="number"
              step="1"
              min="0"
              disabled={!configInputs.availableServices[service.service_code] }
              className={getInputErrorClass(configInputs.maxWeightPerService[service.service_code])}
            />
          </div>
        </div>)}
        <div className="max-weight-row meter-weight">
          <div>{translate("Max current meter weight")}</div>
          <div>
            <Input
              name={`maxCurrentMeterWeight`}
              id={`maxCurrentMeterWeight`}
              value={configInputs.maxCurrentMeterWeight}
              onChange={this.updateMaxWeight}
              type="number"
              step="0.1"
              min="0"
              className={getInputErrorClass(configInputs.maxCurrentMeterWeight)}
            />
          </div>
        </div>
      </div>
    </>
  }
}

export default MaxWeight