import {translate} from "../services/translationService";

export const stringHelper = {
    addressToString,
    personToString,
    getLifeCycleCreatorName,
    streetToString
};

function streetToString(streetData) {
    let street = "";
    if (Array.isArray(streetData)){
        street = streetData.join();
    } else if (typeof streetData === "object"){
        for (let prop in streetData) {
            street += streetData[prop] + ',';
        }
        street = street.slice(0, -1);
    }
    return street;
}

function  addressToString(addressObject) {
    const street = stringHelper.streetToString(addressObject.street);
    
    return `${addressObject.name}, ${street}, ${addressObject.postCode} ${addressObject.city}, ${addressObject.country}`;
}

function personToString(personObject) {
    if (personObject) {
        return `${personObject.name} ${personObject.surname}`
    } else {
        return "?";
    }
}

function getLifeCycleCreatorName(lifeCycle) {
    return lifeCycle.hasOwnProperty('carrierAccount') ?
        `${lifeCycle.carrierAccount.name} ${lifeCycle.carrierAccount.surname}` :
        `${translate("Document uploaded by Janta employee")}`;
}

