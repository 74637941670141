import {
    FETCH_DEDICATED_STOP_FAILURE,
    FETCH_DEDICATED_STOP_REQUEST,
    FETCH_DEDICATED_STOP_SUCCESS,
    UPDATE_DEDICATED_STOP_FAILURE,
    UPDATE_DEDICATED_STOP_REQUEST,
    UPDATE_DEDICATED_STOP_SUCCESS
} from "../../actions";

const initialState = {
    stopSurcharges: [],
    isFetchingByStop: true,
    isUpdatingByStop:false,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DEDICATED_STOP_REQUEST: {
            return Object.assign({}, state, {
                isFetchingByStop: true,
                error:false
            });
        }

        case FETCH_DEDICATED_STOP_SUCCESS: {
            return Object.assign({}, state, {
                stopSurcharges: action.data,
                isFetchingByStop: false,
            });
        }

        case FETCH_DEDICATED_STOP_FAILURE: {
            return Object.assign({}, state, {
                isFetchingByStop: false,
                error: true
            });
        }

        case UPDATE_DEDICATED_STOP_REQUEST: {
            return Object.assign({}, state, {
                isUpdatingByStop: true,
                error:false
            });
        }

        case UPDATE_DEDICATED_STOP_SUCCESS: {
            return Object.assign({}, state, {
                isUpdatingByStop: false,
                error:false
            });
        }

        case UPDATE_DEDICATED_STOP_FAILURE: {
            return Object.assign({}, state, {
                isUpdatingByStop: false,
                error:true
            });
        }

        default:
            return state
    }
}
