import React from "react";
import {Card, CardBody, Button, Label, Col, Row, Table} from "reactstrap";
import { connect } from 'react-redux'
import { translate } from 'services/translationService';
import {getVehicle, getTruckTrailers} from "redux/actions/car";
import Loader from "components/Loader/Loader";
import CheckIcon from "../../CheckIcon";
import TruckTrailersModal from "../TruckTrailersModal";
import {apiService} from "../../../../services/apiService";
import {getCarSets} from "../../../../redux/actions/car";
import {history} from "../../../../helpers";
import AssignDriverModal from "../AssignDriverModal";
import {getUsers} from "../../../../redux/actions/users";
import {getDriverNamesForCar} from "../../../../helpers/driverHelper";
import mapService from "../../../../services/mapService";
import VehicleLocation from "../CarLocation/VehicleLocation";


class SoloTruck extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            trailersListVisible: false,
            driversListVisible:false
        };
        this.soloTruckId = this.props.match.params.soloTruckId;
        this.toggleTrailersModal = this.toggleTrailersModal.bind(this);
        this.toggleDriversModal = this.toggleDriversModal.bind(this);
        this.connectToSet = this.connectToSet.bind(this);
        this.showTrailer = this.showTrailer.bind(this);
        this.showSet = this.showSet.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getVehicle(this.soloTruckId));
        this.props.dispatch(getTruckTrailers({
            page: 1,
            perPage: 999,
            isConnectedToSet: 0
        }));
        this.props.dispatch(getCarSets(
            {
                page: 1,
                perPage: 999,
            }
        ));
        this.props.dispatch(getUsers({
            role: "ROLE_DRIVER",
            perPage:999
        }));
    }

    toggleTrailersModal() {
        this.setState({
            trailersListVisible: !this.state.trailersListVisible
        });
    }

    toggleDriversModal() {
        this.setState({
            driversListVisible: !this.state.driversListVisible
        });
    }

    connectToSet(trailerId, maxWeight) {
        apiService.post('api/vehicles/sets', {
            maxWeight: maxWeight,
            car: {
                id: this.soloTruckId,
            },
            truckTrailer: {
                id: trailerId
            }
        })
            .then(response => {
                history.push('/cars');
            })
    }

    showTrailer() {
        const trailer = this.props.carSets.filter((carSet)=>carSet.car.id === this.props.soloTruck.id)[0];
        if (trailer && trailer.hasOwnProperty('truckTrailer')) {
            history.push('/trailer/' + trailer.truckTrailer.id);
        };
    }

    showSet() {
        const carSet = this.props.carSets.filter((carSet)=>carSet.car.id === this.props.soloTruck.id)[0];
        if (carSet) {
            history.push('/car-set/' + carSet.id);
        };
    }

    renderDriversInformation(vehicle) {
        const numberOfDrivers = vehicle.drivers.length;
        return <div className={"drivers-information"}>
            {numberOfDrivers > 0 ? <span className = "drivers-names">{getDriverNamesForCar(vehicle)}
                                </span> : ""}
            <Button className="btn btn-success btn-sm"
                    onClick={this.toggleDriversModal}>
                {translate("Change")}
            </Button>
        </div>
    }

    render() {
        const {isFetching, error, soloTruck, isFetchingTrailers, truckTrailers, isFetchingDrivers, errorDrivers} = this.props;
        const showLocation = mapService.showCarPosition(soloTruck);
        return (
            <div className="content">
                {!(isFetching) && !(error) ? (
                    <>
                    <Row>
                        <Col md={12}>
                            <Card className="vehicle-details">
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <h5><i className="nc-icon nc-alert-circle-i" /> {translate('Solo Truck details') } </h5>
                                            <hr />
                                        </Col>
                                        <Col md={4}>
                                            <Table size="sm" borderless>
                                                <tbody>
                                                <tr>
                                                    <th>
                                                        <label>{translate('Model / Brand')}: </label>
                                                    </th>
                                                    <td>
                                                        {soloTruck.brand.name} {soloTruck.model.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label>{translate('Register number')}: </label>
                                                    </th>
                                                    <td>
                                                        {soloTruck.registrationNumber}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <Label>{translate("Production Year")}</Label>
                                                    </th>
                                                    <td>
                                                        {soloTruck.productionYear}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <Label>{translate("Drivers")}</Label>
                                                    </th>
                                                    <td>
                                                        {this.renderDriversInformation(soloTruck)}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>

                                        <Col md={4}>
                                            <Table size="sm" borderless style={{tableLayout:"fixed"}}>
                                                <tbody>
                                                <tr>
                                                    <th>
                                                        <label>{translate('Is part of set')}: </label>
                                                    </th>
                                                    <td>
                                                        <CheckIcon checkPresent={soloTruck.isPartOfSet} />
                                                    </td>
                                                </tr>
                                                </tbody>
                                                </Table>
                                            {soloTruck.isPartOfSet ?
                                            <div className="truck-buttons">
                                                <Button color="secondary" onClick={this.showSet}> {translate("Show set")} </Button>
                                                <Button color="primary" onClick={this.showTrailer}> {translate("Show trailer")} </Button>
                                            </div>
                                                :
                                                <div>
                                                    <Button color="primary" onClick={this.toggleTrailersModal}> + {translate("Add truck trailer")}</Button>
                                                </div>
                                            }
                                        </Col>
                                    </Row>


                                </CardBody>
                            </Card>
                        </Col>
                        <TruckTrailersModal
                            toggleTrailersModal={this.toggleTrailersModal}
                            showModal={this.state.trailersListVisible}
                            trailers={truckTrailers}
                            isFetching={isFetchingTrailers}
                            connectToSet = {this.connectToSet}
                        />
                        {this.props.drivers.length && <AssignDriverModal
                            toggleModal={this.toggleDriversModal}
                            showModal={this.state.driversListVisible}
                            drivers={this.props.drivers}
                            car={soloTruck}
                            carSet={null}
                            isFetching={isFetchingDrivers}
                            error={errorDrivers}
                        />}
                    </Row>
                    <Row>
                        {showLocation && <Col md={12}>
                            <VehicleLocation carData={soloTruck}/>
                        </Col>}
                    </Row>
                    </>
                ) : (
                    <Loader />
                )}
            </div>
        );
    }
}

SoloTruck.defaultProps = {
    isFetching: true,
    soloTruck: {},
    isFetchingTrailers: false,
    truckTrailers: [],
    carSets:{},
    drivers: [],
    isFetchingDrivers: true,
    errorDrivers: false,
}

const mapStateToProps = (state) => {
    return {
        soloTruck: state.vehicleReducer.vehicle,
        isFetching: state.vehicleReducer.isFetching,
        error: state.vehicleReducer.error,
        truckTrailers: state.truckTrailersReducer.truckTrailers,
        errorTrailers: state.truckTrailersReducer.error,
        isFetchingTrailers: state.truckTrailersReducer.isFetching,
        carSets: state.carSetsReducer.carSets,
        drivers: state.usersReducer.accounts,
        isFetchingDrivers: state.usersReducer.isFetching,
        errorDrivers: state.usersReducer.error,
    }
}
export default connect(mapStateToProps)(SoloTruck);
