import {CARRIER_IS_ACTIVE, USER_IS_ACTIVE, USER_IS_MANAGER} from "../../constants/routesRestrictions";
import loggedUserService from "../../services/loggedUserService";
import {translate} from "../../services/translationService";

const dashboardTiles = () => {
    const allTiles =
    [
        {
            cardIndex: 1,
            cardTitle: translate('New orders'),
            iconClass: 'nc-icon nc-alert-circle-i text-warning',
            linkTo: '/orders/new',
            apiUrl: '/api/orders/list/new/count',
            linkText: translate('Show new orders'),
            tag: translate('Shows all recently assigned orders'),
            restrictions: [CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            cardIndex: 2,
            cardTitle: translate('Orders in transit'),
            iconClass: 'nc-icon nc-delivery-fast text-danger',
            linkTo: '/orders/ongoing',
            apiUrl: '/api/orders/list/ongoing/count',
            linkText: translate('Show orders in transit'),
            tag: translate('Shows confirmed orders'),
            restrictions: [CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            cardIndex: 3,
            cardTitle: translate('Delivered orders'),
            iconClass: 'nc-icon nc-check-2 text-primary',
            linkTo: '/orders/delivered',
            apiUrl: '/api/orders/list/delivered/count',
            linkText: translate('Show delivered orders'),
            tag: translate('Shows delivered orders - delivery document upload needed'),
            restrictions: [CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            cardIndex: 4,
            cardTitle: translate('Processed orders'),
            iconClass: 'nc-icon nc-single-copy-04 text-gray',
            linkTo: '/orders/processed',
            apiUrl: '/api/orders/list/processed/count',
            linkText: translate('Show processed orders'),
            tag: translate('Shows delivered orders with uploaded delivery documents'),
            restrictions: [USER_IS_MANAGER, CARRIER_IS_ACTIVE, USER_IS_ACTIVE]
        },
        {
            cardIndex: 5,
            cardTitle: translate('All orders'),
            iconClass: 'nc-icon nc-paper text-default',
            linkTo: '/orders/all',
            apiUrl: '/api/orders/list/all/count',
            linkText: translate('Show all orders'),
            tag: translate('Shows all orders'),
            restrictions: [USER_IS_MANAGER, USER_IS_ACTIVE]
        },
        {
            cardIndex: 6,
            cardTitle: translate('New Notifications'),
            iconClass: 'nc-icon nc-bell-55 text-new-orange',
            linkTo: '/notifications',
            apiUrl: null,
            notificationApiUrl: '/api/simple/notifications/unread/count',
            linkText: translate('Show notifications'),
            tag: translate('Show notifications')
        },
    ];
    return loggedUserService.restrictRoutesForUser(allTiles);
}

export default dashboardTiles;
