import {
    FETCH_LIFECYCLES_FAILURE,
    FETCH_LIFECYCLES_REQUEST,
    FETCH_LIFECYCLES_SUCCESS,
    ADD_LIFECYCLE_FAILURE,
    ADD_LIFECYCLE_REQUEST,
    ADD_LIFECYCLE_SUCCESS
} from 'redux/actions/shipments';

const initialState = {
    lifeCycles: {},
    isFetching: true,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LIFECYCLES_REQUEST:
        case ADD_LIFECYCLE_REQUEST:{
            return Object.assign({}, state, {
                isFetching: true,
            });
        }

        case FETCH_LIFECYCLES_SUCCESS:
        case ADD_LIFECYCLE_SUCCESS: {
            return Object.assign({}, state, {
                lifeCycles: action.data.data,
                isFetching: false,
            });
        }

        case FETCH_LIFECYCLES_FAILURE:
        case ADD_LIFECYCLE_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }

        default:
            return state
    }
}
