import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import {translate} from "../../../services/translationService";
import {Button} from "reactstrap";

class SelectFile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null
        }
    }

    render(){
        const {name, accept, handleFile, disabled} = this.props;
        const {file} = this.state;
        let className = "btn btn-outline-primary btn-sm";
        return <div className={"upload-button"}>
            <label htmlFor={name}>
                <div className={className} title={file ? file.name : ""}>
                    <span className="file-name"> {file ? file.name : translate("Select file")} </span>
                </div>
            </label>
            <input
                name={name}
                accept={accept}
                type="file"
                autoComplete="off"
                tabIndex="-1"
                onChange={(e)=>this.setState({file:e.target.files[0]})}
                id={name}
            />
            <Button color="primary" size={"sm"} onClick={()=>handleFile(file)} disabled={!file}>
                {translate("Upload") }
            </Button>
        </div>
    }

}

SelectFile.defaultProps = {
    accept: "image/png, image/jpeg, image/webp",
    disabled: false
};

SelectFile.propTypes = {
    name: PropTypes.string.isRequired,
    accept: PropTypes.string.isRequired,
    handleFile: PropTypes.func.isRequired
};

export default SelectFile;
