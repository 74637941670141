import React, {Component} from 'react';
import {translate} from "../../../services/translationService";
import "./styles.scss"
import {Input} from "reactstrap";

class Services extends Component {

  constructor(props) {
    super(props);
    this.updateServices = this.updateServices.bind(this)
  }

  updateServices(e) {
    let newInputs = {...this.props.configInputs};
    const {maxWeightPerService} = this.props;
    const inputValue = e.target.checked;
    const serviceCode = e.target.name.split('-')[1];
    newInputs.availableServices[serviceCode] = inputValue;
    if (inputValue === false){
      newInputs.maxWeightPerService[serviceCode] = maxWeightPerService.hasOwnProperty(serviceCode) ?
        maxWeightPerService[serviceCode].max_weight : 0
   }
    this.props.updateInputs(newInputs)
  }

  render() {
    const {configInputs, availableServices} = this.props;
    return <>
      <div className="centered"><h5 className="cooperation-title">{translate("Available Services")}</h5></div>
        <div className="services-title">
          <div className="centered">{translate("Active")}</div>
          <div>{translate("Service type")}</div>
        </div>
        {availableServices.map((service, key) => <div className="services-row" key={key}>
          <div>
            <Input
              name={`service-${service.service_code}`}
              id={`service-${service.service_code}`}
              checked={configInputs.availableServices[service.service_code]}
              type="checkbox"
              onChange={this.updateServices}
            />
          </div>
          <div>{service.service_name}</div>
        </div>)}
    </>
  }
}

export default Services