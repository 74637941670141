import {
    FETCH_API_NOTIFICATIONS_FAILURE,
    FETCH_API_NOTIFICATIONS_REQUEST,
    FETCH_API_NOTIFICATIONS_SUCCESS
} from "../../actions/apiNotifications";

const initialState = {
    notifications: [],
    meta: {},
    isFetching: true,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_API_NOTIFICATIONS_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
            });
        }

        case FETCH_API_NOTIFICATIONS_SUCCESS: {
            return Object.assign({}, state, {
                notifications: action.data.data,
                meta: action.data.meta,
                isFetching: false,
            });
        }

        case FETCH_API_NOTIFICATIONS_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }
        default:
            return state
    }
}
