import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {connect} from 'react-redux';
import {getCarrierDocuments} from 'redux/actions/carrier';
import {translate} from 'services/translationService';
import DocumentTypeCard from 'components/Documents/DocumentTypeCard';
import Loader from "components/Loader/Loader";
import Badge from "reactstrap/es/Badge";
import HelperButton from "../../../components/Helper/HelperButton";

class CarrierDocuments extends React.Component {

    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(getCarrierDocuments());
    }

    getDocumentsByType(documentType, documents) {
        return documents.filter((document) => {
            return document.documentType.id === documentType.id;
        })
    }

    sortDocumentsByRelevance = (documentTypes) => {
        return documentTypes.sort((doc1, doc2) => doc2.isRequired - doc1.isRequired);
    }

    render() {

        let {documentTypes} = this.props;
        const {isFetching, carrierDocuments, error} = this.props;
        if (!isFetching && !error && documentTypes.length > 0) {
            documentTypes = this.sortDocumentsByRelevance(documentTypes);
        }
        return (
            <Card>
                <CardBody>
                    <h4> {translate('Documents')}{"  "}
                        <Badge color="warning">{translate('Required')}</Badge>{"  "}
                        <Badge color="secondary">{translate('Additional')}</Badge>{"  "}
                    </h4>
                    <Row>
                        {
                            (!isFetching && !error && documentTypes.length > 0) ?
                                documentTypes.map((documentType, i) => {
                                    return (
                                        <Col lg={3} md={4} key={i}>
                                            <DocumentTypeCard documentType={documentType}
                                                              documents={this.getDocumentsByType(documentType, carrierDocuments)}
                                                              key={i}/>
                                        </Col>
                                    )
                                })
                                :
                                <Loader/>
                        }
                    </Row>
                </CardBody>
                <HelperButton helperId="carrier-documents" />
            </Card>
        )
    }
}

CarrierDocuments.defaultProps = {
    carrierDocuments: [],
    documentTypes: [],
    isFetching: null,
    error: false,
};

const mapStateToProps = state => ({
    carrierDocuments: state.carrierDocumentsReducer.carrierDocuments,
    documentTypes: state.carrierDocumentsReducer.documentTypes,
    isFetching: state.carrierDocumentsReducer.isFetching,
    error: state.carrierDocumentsReducer.error,
});

export default connect(mapStateToProps)(CarrierDocuments);
