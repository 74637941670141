import {
    FETCH_INVITATIONS_REQUEST,
    FETCH_INVITATIONS_SUCCESS,
    FETCH_INVITATIONS_FAILURE,
    ACCEPT_INVITATION_REQUEST,
    ACCEPT_INVITATION_SUCCESS,
    ACCEPT_INVITATION_FAILURE,
    ADD_INVITATION_REQUEST,
    ADD_INVITATION_SUCCESS,
    ADD_INVITATION_FAILURE
} from 'redux/actions/invitation/invitations.actions.js';

const initialState = {
    invitations: [],
    isFetchingInvitations: true,
    isSendingInvitation:false,
    isAcceptingInvitation:false,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INVITATIONS_REQUEST: {
            return Object.assign({}, state, {
                isFetchingInvitations: true,
                error:false
            });
        }

        case FETCH_INVITATIONS_SUCCESS: {
            return Object.assign({}, state, {
                invitations: action.data,
                isFetchingInvitations: false,
                error:false
            });
        }

        case FETCH_INVITATIONS_FAILURE: {
            return Object.assign({}, state, {
                isFetchingInvitations: false,
                error: true
            });
        }

        case ACCEPT_INVITATION_REQUEST: {
            return Object.assign({}, state, {
                isAcceptingInvitation: true,
                error:false
            });
        }

        case ACCEPT_INVITATION_SUCCESS: {
            return Object.assign({}, state, {
                isAcceptingInvitation: false,
                error:false
            });
        }

        case ACCEPT_INVITATION_FAILURE: {
            return Object.assign({}, state, {
                isAcceptingInvitation: false,
                error: true
            });
        }

        case ADD_INVITATION_REQUEST: {
            return Object.assign({}, state, {
                isSendingInvitation: true,
                error:false
            });
        }

        case ADD_INVITATION_SUCCESS: {
            return Object.assign({}, state, {
                isSendingInvitation: false,
                error:false
            });
        }

        case ADD_INVITATION_FAILURE: {
            return Object.assign({}, state, {
                isSendingInvitation: false,
                error: true
            });
        }

        default:
            return state
    }
}
