import React from "react";
import { connect } from 'react-redux';
import { getTranslations } from "../../redux/actions";
import { translate } from 'services/translationService';
import './language.scss';
import {getFormValues} from "redux-form";

class Language extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locale: this.props.locale
        };

        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event) {
        const serializedFormValues = JSON.stringify(this.props.registerFormFields);
        localStorage.setItem('registerFormValues', serializedFormValues);
        localStorage.setItem('registerFormValuesChanged', "true");
        this.props.dispatch(getTranslations(event.target.value));
    }


    render() {
        return (
            <div className="languageSelectorContainer">
                <div className="formGroup">
                    <label htmlFor="languageSelect"> { translate('Language') } </label>
                    <select id="languageSelect" className="form-control" defaultValue={this.props.locale} onChange={this.handleChange}>
                        <option value="en"> { translate('English') } </option>
                        <option value="pl"> { translate('Polish') } </option>
                        <option value="de"> { translate('German') } </option>
                    </select>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    locale: state.translationReducer.locale,
    translate: state.translationReducer,
    registerFormFields: getFormValues('registerForm')(state)
})

export default connect(mapStateToProps)(Language);
