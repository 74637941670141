import {
    ADD_MULTIPLE_STATUSES_FAILURE,
    ADD_MULTIPLE_STATUSES_REQUEST,
    ADD_MULTIPLE_STATUSES_SUCCESS
} from "../../actions/shipments/multipleStatuses.actions";

const initialState = {
    isAdding: false,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_MULTIPLE_STATUSES_REQUEST:
            return Object.assign({}, state, {
                isAdding: true,
            });

        case ADD_MULTIPLE_STATUSES_SUCCESS: {
            return Object.assign({}, state, {
                isAdding: false,
            });
        }

        case ADD_MULTIPLE_STATUSES_FAILURE: {
            return Object.assign({}, state, {
                isAdding: false,
                error: true
            });
        }
        default:
            return state
    }
}
