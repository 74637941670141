import moment from "moment";

const validate = (values) => {

  const errors = {}

  let today = moment()
  let pickupDate = moment(values.pickupDate);
  let deliveryDate = moment(values.deliveryDate);

  if (!values.pickupDate) {
    errors.pickupDate = 'Pickup date is required';
  }
  if (pickupDate.isBefore(today,'day') ){
    errors.pickupDate = 'Pickup date is in the past';
  }

  if (!values.deliveryDate) {
    errors.deliveryDate = 'Delivery date is required';
  }

  if (deliveryDate.isBefore(pickupDate,'day')){
    errors.deliveryDate = 'Delivery date is before pickup date';
  }

  if (!values.firstPrice) {
    errors.firstPrice = 'Price is required';
  }

  return errors;
}

export default validate;
