import React from "react";
import {Button, Col, Row} from "reactstrap";
import {stringHelper} from "helpers/stringHelper";
import {translate} from 'services/translationService';
import SelectAccount from "components/SelectAccount/SelectAccount";

class AccountAssigment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false
        };

        this.toggleEdit = this.toggleEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    toggleEdit() {
        this.setState({
            edit: !this.state.edit
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        let formObject = {};
        for (const [key, value] of formData.entries()) {
            if (value) {
                formObject[key] = value;
            }
        }
        this.props.onSubmitHandler(formObject);
    }


    render() {
        let accountId = (this.props.account) ? this.props.account.id : '';
        return (
            !this.state.edit ?
                <Row style={{alignItems: 'center'}}>
                    <Col>
                        {stringHelper.personToString(this.props.account)}
                    </Col>
                    <Col className="align-right">
                        {(this.props.canEdit) ?
                            <Button color="success" size="sm"
                                onClick={this.toggleEdit}>{translate('Change')}
                            </Button>
                            : ""}
                    </Col>
                </Row>
                :
                <form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <SelectAccount userRole={this.props.userRole} name={this.props.name}
                                           withEmptyValue={true}
                                           defaultValue={accountId}/>
                        </Col>
                        <Col className="align-right">
                            <Button color="success" size="sm">{translate('Save')}</Button>
                            <Button onClick={this.toggleEdit} outline color="danger"
                                    size="sm">{translate('Cancel')}</Button>
                        </Col>
                    </Row>
                </form>
        )
    }
}

export default AccountAssigment;
