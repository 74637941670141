import {apiService} from "services/apiService";
import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {history} from "../../../helpers";

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';
export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export function getOrder(id) {
    return dispatch => {
        dispatch(request({}));

        apiService.get('/api/orders/' + id)
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                history.push('/dashboard');
            });
    };

    function request(data) { return { type: FETCH_ORDER_REQUEST, data } }
    function success(data) { return { type: FETCH_ORDER_SUCCESS, data } }
    function failure(error) { return { type: FETCH_ORDER_FAILURE, error } }
}

export function updateOrder(id, formData) {
    return dispatch => {
        dispatch(request({}));

        apiService.put('/api/orders/' + id, formData)
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
            });
    };

    function request(data) { return { type: UPDATE_ORDER_REQUEST, data } }
    function success(data) { return { type: UPDATE_ORDER_SUCCESS, data } }
    function failure(error) { return { type: UPDATE_ORDER_FAILURE, error } }
}
