import React, {Component} from 'react';
import {translate} from "../../../services/translationService";
import "./styles.scss"
import {Input} from "reactstrap";
import {getInputErrorClassInRow} from "../../../helpers/formHelper";

class Pallets extends Component {

  constructor(props) {
    super(props);
    this.updatePallets = this.updatePallets.bind(this)
    this.addPalletsRow = this.addPalletsRow.bind(this)
    this.deletePalletsRow = this.deletePalletsRow.bind(this)
    this.renderPalletsInputs = this.renderPalletsInputs.bind(this)
  }

  updatePallets(e){
    let newInputs = {...this.props.configInputs};
    const inputValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const row = e.target.name.split('-')[1];
    const type = e.target.name.split('-')[2];
    newInputs['pallets'][row][type] = inputValue
    this.props.updateInputs(newInputs)
  }

  addPalletsRow() {
    let newInputs = {...this.props.configInputs};
    const emptyRow = {
      length: "",
      width: "",
      weight: "",
      laidable_weight_limit: "",
      is_gitterbox: false
    }
    newInputs['pallets'].push(emptyRow);
    this.props.updateInputs(newInputs)
  }

  deletePalletsRow(row){
    let newInputs = {...this.props.configInputs};
    if(newInputs.pallets.length > 0){
      newInputs['pallets'].splice(row, 1);;
    }
    this.props.updateInputs(newInputs)
  }


  renderPalletsInputs(factorsObject, row){
    const {configInputs} = this.props;
    return <>
      <Input
        name={`pallets-${row}-length`}
        id={`pallets-${row}-length`}
        value={configInputs.pallets[row].length}
        className={getInputErrorClassInRow(configInputs.pallets[row], 'length')}
        onChange={this.updatePallets}
        type="number"
        step="1"
        min="0"
      />
      <Input
        name={`pallets-${row}-width`}
        id={`pallets-${row}-width`}
        value={configInputs.pallets[row].width}
        className={getInputErrorClassInRow(configInputs.pallets[row], 'width')}
        onChange={this.updatePallets}
        type="number"
        step="1"
        min="0"
      />
      <Input
        name={`pallets-${row}-weight`}
        id={`pallets-${row}-weight`}
        value={configInputs.pallets[row].weight}
        className={getInputErrorClassInRow(configInputs.pallets[row], 'weight')}
        onChange={this.updatePallets}
        type="number"
        step="1"
        min="0"
      />
      <Input
        name={`pallets-${row}-laidable_weight_limit`}
        id={`pallets-${row}-laidable_weight_limit`}
        value={configInputs.pallets[row].laidable_weight_limit}
        className={getInputErrorClassInRow(configInputs.pallets[row], 'laidable_weight_limit')}
        onChange={this.updatePallets}
        type="number"
        step="1"
        min="0"
      />
      <Input
        name={`pallets-${row}-is_gitterbox`}
        id={`pallets-${row}-is_gitterbox`}
        checked={configInputs.pallets[row].is_gitterbox}
        onChange={this.updatePallets}
        type="checkbox"
      />
      {configInputs.pallets.length > 1 && <span className="round-button delete-button" title={translate("Delete row")}>
        <i className="nc-icon nc-simple-delete" onClick={()=>this.deletePalletsRow(row)} />
      </span>}
    </>
  }

  render() {

    const {configInputs} = this.props;
    return <>
      <div className="centered"><h5 className="cooperation-title">{translate("Pallets")}</h5></div>
      <div className="pallets">
        <div className="pallets-header">
          <div>{translate("Length") + " [cm]"}</div>
          <div>{translate("Width") + " [cm]"}</div>
          <div>{translate("Weight") + " [kg]"}</div>
          <div>{translate("Laidable weight limit") + " [kg]"}</div>
          <div>{translate("Gitterbox")}</div>
          <div></div>
        </div>
        <div className="pallets-form">
          {configInputs.pallets.map((pallet,key) =><div className="pallets-row" key={key}>
            {this.renderPalletsInputs(pallet, key)}
          </div>)
          }
        </div>
          {(configInputs.pallets.length < 5) && <div className={"add-factors"} onClick={this.addPalletsRow}>
            <span className="round-button add-button">
              <i className="nc-icon nc-simple-add" />
            </span>
            <span>{translate("Add pallets")}</span></div>}
      </div>
    </>
  }
}

export default Pallets