import React from "react";
import {connect} from 'react-redux'
import {translate} from 'services/translationService';
import {Table, Row, Button, Col, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import UserSingleRow from "./UserSingleRow";
import Loader from "components/Loader/Loader";
import MediaQuery from 'react-responsive';
import "./style.scss";
import {getUsers} from "redux/actions/users";
import {Link} from "react-router-dom";
import Paginator from "../../../components/Paginator/Paginator";
import {paginationActions} from "../../../redux/actions/pagination.actions";
import {apiService} from "../../../services/apiService";
import getErrorText from "../../../services/errorTextService";
import {alertActions, getCars} from "../../../redux/actions";
import {pageDidChange} from "../../../helpers/paginator";
import AssignCarModal from "../../Cars/Car/AssignCarModal";
import HelperButton from "../../../components/Helper/HelperButton";
export const USER_PAGINATOR = 'userPaginator';

class Users extends React.Component {

    constructor(props) {
        super(props);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePerPageChange = this.handlePerPageChange.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.toggleAssignCarModal = this.toggleAssignCarModal.bind(this);
        this.prepareModalText = this.prepareModalText.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.connectCarToDriver = this.connectCarToDriver.bind(this);
        this.dispatch = this.props.dispatch;
        this.state = {
            showDeleteModal: false,
            accountToDelete: null,
            selectedDriver:null,
            showAssignCarModal:false
        }
    }

    componentDidMount() {
        this.dispatch(getUsers({
            page: this.props.pageParams.page,
            perPage: this.props.pageParams.perPage
        }));
        this.props.dispatch(getCars('NO_TRUCK_TRAILER',{
            MaxOneDriverAssigned:true,
            perPage:999
        }));
    }

    componentDidUpdate(prevProps, prevState) {
        if (pageDidChange(prevProps.pageParams,  this.props.pageParams)
            || (prevState.accountToDelete !== this.state.accountToDelete && prevState.showDeleteModal === true)) {
            this.dispatch(getUsers({
                perPage: this.props.pageParams.perPage,
                page: this.props.pageParams.page
            }));
        }
    }

    toggleDeleteModal(account) {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            accountToDelete: account
        });
    }

    toggleAssignCarModal(account){
        this.setState({
            showAssignCarModal: !this.state.showAssignCarModal,
            selectedDriver: account
        });
    }

    handlePageChange(pageNumber, indexStart) {
        this.dispatch(paginationActions.pushChangePage(USER_PAGINATOR,pageNumber, indexStart));
    }

    handlePerPageChange(perPage) {
        this.dispatch(paginationActions.changePerPage(USER_PAGINATOR,perPage));
    }

    prepareModalText(account) {
        return account === null ? "" :
            `${translate("Do you really want to delete account of")} ${account.name} ${account.surname}?`;
    }

    deleteUser() {
        const {dispatch} = this.props;
        apiService.apiDelete('/api/accounts/' + this.state.accountToDelete.id)
            .then(() => {
                dispatch(alertActions.success(translate("User account deleted successfully")));
                this.toggleDeleteModal(null);
            })
            .catch((apiError) => {
                dispatch(alertActions.error(getErrorText(apiError)));
                this.toggleDeleteModal(null);
            });
    }

    connectCarToDriver(car){
        apiService.post('api/vehicles/drivers', {
            car: {
                id: car.id,
            },
            driver: {
                id: this.state.selectedDriver.id
            }
        })
            .then(response => {
                this.setState({
                    showAssignCarModal: false,
                    selectedDriver: null
                });
                this.props.dispatch(alertActions.success(translate('Car added successfully!')));
                this.dispatch(getUsers({
                    page: this.props.pageParams.page,
                    perPage: this.props.pageParams.perPage
                }));
                this.props.dispatch(getCars('NO_TRUCK_TRAILER',{
                    MaxOneDriverAssigned:true,
                    perPage:999
                }));
            }).catch(error => {
            this.setState({
                showAssignCarModal: false,
                selectedDriver: null
            });
            this.props.dispatch(alertActions.error(getErrorText(error)));
        });
    }

    render() {
        if (this.props.isFetching || this.props.isFetchingCars) {
            return (
                <div className="content">
                    <Loader/>
                </div>
            )
        }
        const {accounts, meta, cars, isFetchingCars, carsError, dispatch, pageParams} = this.props;
        const {selectedDriver} = this.state;
        const pagesCount = Math.ceil(meta.total / meta.perPage);
        const page = this.props.pageParams.page;
        const indexStart = this.props.pageParams.indexStart;
        return (
            <div className="content" style={{position:"relative"}}>
                <Row>
                    <Col md={12}>
                        <Link to="/user/new">
                            <Button color="primary" className="button-with-icon">
                                <i className="nc-icon nc-simple-add"/>
                                {translate('Add new')}
                            </Button>
                        </Link>
                    </Col>
                    <HelperButton helperId="carrier-users-list" />
                </Row>
                <Table>
                    <thead>
                    <tr>
                        <th>{translate('Name')}</th>
                        <MediaQuery query='(min-device-width: 860px)'>
                            <th>{translate('Roles')}</th>
                            <th>{translate('Default manager')}</th>
                        </MediaQuery>
                        <th>{translate('Phone')}</th>
                        <MediaQuery query='(min-device-width: 860px)'>
                            <th>{translate('Assigned car')}</th>
                            <th>{translate('Email')}</th>
                            <th>{translate('Status')}</th>
                        </MediaQuery>
                        <th>{translate('Action')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {accounts.map((account, i) => {
                        return <UserSingleRow account={account} key={i}
                                toggleDeleteModal={this.toggleDeleteModal}
                                assignCar={this.toggleAssignCarModal}
                                dispatch={dispatch}
                                pageParams={pageParams}
                               />
                    })}
                    </tbody>
                </Table>

                {pagesCount > 1 && <MediaQuery minDeviceWidth={860}>
                    {(matches) => {
                        let maxPages = matches ? 10 : 3;

                        return <Paginator
                            pageChangeHandler={this.handlePageChange}
                            perPageHandler={this.handlePerPageChange}
                            pagesCount={pagesCount}
                            currentPage={page}
                            perPage={this.props.pageParams.perPage}
                            indexStart={indexStart}
                            maxPages={maxPages}
                        />
                    }}
                </MediaQuery>}
                <Modal isOpen={this.state.showDeleteModal} toggle={this.toggle}>
                    <ModalHeader
                        toggle={this.toggleDeleteModal}>{translate("Delete user account")}</ModalHeader>
                    <ModalBody>
                        {this.prepareModalText(this.state.accountToDelete)}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary"
                                onClick={()=>this.toggleDeleteModal(null)}>{translate("Cancel")}</Button>
                        <Button color="primary"
                                onClick={this.deleteUser}>{translate("Delete")}</Button>{' '}
                    </ModalFooter>
                </Modal>
                <AssignCarModal
                    toggleModal={this.toggleAssignCarModal}
                    showModal={this.state.showAssignCarModal}
                    cars={cars}
                    driver={selectedDriver}
                    isFetching={isFetchingCars}
                    error={carsError}
                    connectToDriver = {this.connectCarToDriver}
                />
            </div>
        );
    }
}

Users.defaultProps = {
    accounts: [],
    meta: {},
    isFetching: null,
    error: false,
    cars:[],
    isFetchingCars: true,
    carsError: null
};

const mapStateToProps = (state) => {
    return {
        accounts: state.usersReducer.accounts,
        isFetching: state.usersReducer.isFetching,
        error: state.usersReducer.error,
        meta: state.usersReducer.meta,
        pageParams: state.paginationReducer.userPaginator,
        cars: state.carsReducer.cars,
        isFetchingCars: state.carsReducer.isFetching,
        carsError: state.carsReducer.error,
    };
};

export default connect(mapStateToProps)(Users);
