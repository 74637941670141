import React, {Component} from 'react';
import {translate} from 'services/translationService';
import {Table} from 'reactstrap';
import {dateTime} from "../../../utils/formatters";
import CheckIcon from "../../Cars/CheckIcon";
import ShowRemarkModal from "./ShowRemarkModal";

class ShipmentRemarksList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showRemarkModal: false,
            remarkId: null,
        }
        this.toggleRemarkModal = this.toggleRemarkModal.bind(this);
        this.showRemarkModal = this.showRemarkModal.bind(this);
    }

    toggleRemarkModal() {
        this.setState({
            showRemarkModal: !this.state.showRemarkModal
        },);
    }

    showRemarkModal(remarkId) {
        this.setState({
            showRemarkModal: true,
            remarkId
        });
    }

    render() {
        const {remarks} = this.props;
        const {showRemarkModal, remarkId} = this.state;
        return <>
            <Table hover size="sm" className="full-width responsive-font remarks-table">
                <thead>
                    <tr>
                            <th>{translate("Category")}</th>
                            <th>{translate("Comment")}</th>
                            <th>{translate("Added on")}</th>
                            <th><i className="nc-icon nc-camera-compact"></i></th>
                    </tr>
                </thead>
                <tbody>
                {remarks.map( (remark, key) => {
                    const remarkText = remark.description.length > 99 ? remark.description + " (...)" : remark.description;
                    return <tr key={key} className={"remark-row"} onClick={()=>this.showRemarkModal(remark.id)}>
                            <td>{remark.categoryName}</td>
                            <td>{remarkText}</td>
                            <td>{dateTime(remark.createdDate)}</td>
                            <td><CheckIcon checkPresent={remark.hasPicture}/></td>
                        </tr>  
                    }
                )}
                </tbody>
            </Table>
            {remarkId && <ShowRemarkModal
                toggleModal={this.toggleRemarkModal}
                showModal={showRemarkModal}
                remarkId = {remarkId}
            />}
        </>
    }
}

export default ShipmentRemarksList;
