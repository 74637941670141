import React from "react";


const CheckIcon = (props) => {

    if (props.checkPresent) {
        return (
            <i className="nc-icon nc-check-2 icon-check"  />
            )
    } else {
        return (
            <i className="nc-icon nc-simple-remove icon-cross" />
            )
    }
}

export default CheckIcon;


