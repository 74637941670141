import {CLEAR_SPECIFICATION, SELECT_SPECIFICATION} from "../../actions/car/specification.actions";

const initialState = {
    specification: {}
};

export default function specificationReducer (state = initialState, action) {
    switch (action.type) {
        case SELECT_SPECIFICATION: {
            return Object.assign({}, state, {
                specification: action.data,
            });
        }
        case CLEAR_SPECIFICATION: {
            return Object.assign({}, state, {
                specification: {},
            });
        }
        default:
            return state
    }
}
