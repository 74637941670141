import React from "react";
import {Col, Row} from "reactstrap";
import {Field, FormSection, formValues} from "redux-form";
import FieldCheckbox from "components/FormFields/FieldCheckbox/FieldCheckbox";
import FieldRadioGroup from "components/FormFields/FieldRadioGroup/FieldRadioGroup";
import {translate} from "../../../../services/translationService";

class SpecificationFilterForm extends React.Component {

    render() {
        const bodyType = this.props.bodyType;
        return (
            <div className="specification-filters">
                <FormSection name="filters">
                <Row>
                    <Col md={4} xs={12}>
                        <Field
                            inputClassName="small"
                            name="bodyType"
                            label={translate("Body Type")}
                            component={FieldRadioGroup}
                            options={[
                                {
                                    id: "isTilt",
                                    name: translate("Tilt")
                                },
                                {
                                    id: "isBox",
                                    name: translate("Box")
                                },
                                {
                                    id: "isPlatform",
                                    name: translate("Platform")
                                }
                            ]}
                            selectedValue={bodyType}
                        />
                    </Col>
                    <Col md={8} xs={12}>
                       <Row className={"car-form-row"}>
                            <label className="filter-label">{translate('Features:')} </label>
                            <Field
                                inputClassName="small"
                                name="hasLift"
                                label={translate("Lift")}
                                component={FieldCheckbox}
                            />
                            <Field
                                inputClassName="small"
                                name="hasCooler"
                                label={translate("Cooler")}
                                component={FieldCheckbox}
                            />
                            <Field
                                inputClassName="small"
                                name="isEdscha"
                                label={translate("Edscha")}
                                component={FieldCheckbox}
                            />
                       </Row>
                    </Col>
                </Row>
                </FormSection>
            </div>
        )
    }
}

SpecificationFilterForm.defaultProps = {
    bodyType:'isTilt'
};

export default formValues({bodyType:'filters.bodyType'})(SpecificationFilterForm);
