import React from "react";
import {translate} from 'services/translationService';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import "./Route.scss"
import Map from "../../../components/GoogleMap/Map";
import routeService from "../../../services/routeService";
import RouteAside from "./RouteAside";
import {connect} from "react-redux";
import {getRoute} from "../../../redux/actions/map/route.actions";

class ShowRouteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openRoutePoint:null,
            sideMenuToggler:{}
        };
        this.toggle = this.toggle.bind(this);
        this.onMapLoad = this.onMapLoad.bind(this);
        this.clickedMarker = this.clickedMarker.bind(this);
        this.toggleAside = this.toggleAside.bind(this);
    }

    componentDidMount() {
        const routeId = this.props.order.routeId;
        const {dispatch} = this.props;
        dispatch(getRoute(routeId));
    }

    toggleAside(index){
        let newSideMenuToggler = {...this.state.sideMenuToggler};
        newSideMenuToggler[index] = !newSideMenuToggler[index];
        this.setState({
            sideMenuToggler: newSideMenuToggler
        })
    }

    clickedMarker(marker) {
        const routeIndex = parseInt(marker.getLabel());
        this.toggleAside(routeIndex);
    }

    onMapLoad(directionsService, directionsRenderer, map) {
        const {route} = this.props;
        if (route) {
            routeService.drawRoute(route, directionsService, directionsRenderer);
            routeService.addMarkers(route,map,this.clickedMarker);
            const initialToggler = routeService.getMenuToggler(route);
            this.setState({
                sideMenuToggler: initialToggler
            })
        }
    }

    toggle() {
        this.props.toggleModal();
    }

    render() {
        const {route, isFetching} = this.props;
        const {sideMenuToggler} = this.state;
        if (isFetching) {
            return <Loader/>
        }
        const navigatePoint = routeService.getFirstOpenPoint(route);
        return (
            <div className="route-modal">
                <Modal isOpen={this.props.showModal} toggle={this.toggle} size="lg" scrollable={true} className="route-main">
                    <ModalHeader toggle={this.toggle}>{translate("Route details")}
                    </ModalHeader>
                    <ModalBody>
                      <div className="route-modal-display">
                          <div className="route-map">
                              <Map
                                  id="routeMap"
                                  options={{
                                      center: { lat: 50.785418, lng: 10.242753 },
                                      zoom: 8,
                                      scaleControl: true,
                                      streetViewControl: false,
                                      mapTypeControl:false
                                  }}
                                  onMapLoad = {this.onMapLoad}
                              />
                          </div>
                          <div className="route-menu">
                              <RouteAside
                                  route = {route}
                                  sideMenuToggler = {sideMenuToggler}
                                  toggleMenu = {this.toggleAside}
                                  currentOrderId = {this.props.order.id}
                              />
                          </div>
                          {navigatePoint && <div className="navigate-tab">
                              <div className="rps-circle">{routeService.getPointIndex(route, navigatePoint)}</div>
                              <div className={"nav-address"}>&nbsp;{routeService.getAddressText(navigatePoint)}</div>
                              <a href={routeService.getNavigationLink(navigatePoint)} className="btn btn-success btn-sm ml-4" target="_blank" rel="noopener noreferrer">
                                  <i className="nc-icon nc-map-big mr-1"></i>
                                  {translate("Navigate")}
                              </a>
                          </div>}
                      </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

ShowRouteModal.defaultProps = {
    route:null,
    isFetching: true,
    error: false
};

function mapStateToProps(state) {
    return {
        route:state.routeReducer.route,
        isFetching: state.routeReducer.isFetching,
        error: state.routeReducer.error
    };
}

export default connect(
    mapStateToProps,
)(ShowRouteModal);