import {
    CREATE_INVOICING_DATA_FAILURE,
    CREATE_INVOICING_DATA_REQUEST, CREATE_INVOICING_DATA_SUCCESS,
    DELETE_INVOICING_DATA_FAILURE,
    DELETE_INVOICING_DATA_REQUEST, DELETE_INVOICING_DATA_SUCCESS,
    FETCH_INVOICING_DATA_FAILURE,
    FETCH_INVOICING_DATA_REQUEST,
    FETCH_INVOICING_DATA_SUCCESS,
    UPDATE_INVOICING_DATA_FAILURE,
    UPDATE_INVOICING_DATA_REQUEST, UPDATE_INVOICING_DATA_SUCCESS
} from "../../actions";

const initialState = {
    invoicingData: [],
    isFetching: true,
    error: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INVOICING_DATA_REQUEST:
        case UPDATE_INVOICING_DATA_REQUEST:
        case CREATE_INVOICING_DATA_REQUEST:
        case DELETE_INVOICING_DATA_REQUEST:
            {
            return Object.assign({}, state, {
                isFetching: true,
            });
        }

        case FETCH_INVOICING_DATA_SUCCESS: {
            return Object.assign({}, state, {
                invoicingData: action.data.data,
                isFetching: false,
                error:false
            });
        }

        case UPDATE_INVOICING_DATA_SUCCESS: {
            return Object.assign({}, state, {
                invoicingData: action.data.data,
                isFetching: false,
                error:false
            });
        }

        case CREATE_INVOICING_DATA_SUCCESS: {
            return Object.assign({}, state, {
                invoicingData: [...state.invoicingData, action.data.data],
                isFetching: false,
                error:false
            });
        }

        case DELETE_INVOICING_DATA_SUCCESS: {
            return Object.assign({}, state, {
                invoicingData: state.invoicingData.filter(invoicingDataset => {
                    return !invoicingDataset.hasOwnProperty('id') || invoicingDataset.id !== action.data
                }),
                isFetching: false,
                error:false
            });
        }

        case FETCH_INVOICING_DATA_FAILURE:
        case UPDATE_INVOICING_DATA_FAILURE:
        case CREATE_INVOICING_DATA_FAILURE:
        case DELETE_INVOICING_DATA_FAILURE:
            {
            return Object.assign({}, state, {
                isFetching: false,
                error: true
            });
        }

        default:
            return state
    }
}
