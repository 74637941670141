import orderDetails from "../views/OrderDetails/OrderDetails";
import loggedUserService from '../services/loggedUserService';
import {translate} from 'services/translationService';
import {
    CARRIER_IS_ACTIVE,
    USER_IS_MANAGER,
    USER_IS_ACTIVE,
    USER_HAS_INQUIRIES_ACCESS, USER_HAS_COOPERATION_ACCESS
} from "../constants/routesRestrictions";
import UserPage from "../views/Users/User/Details/UserPage";
import CreateUser from "views/Users/User/Create/CreateUser";
import Notifications from "views/Notifications/Notifications";
import CreateCar from "../views/Cars/Car/CreateCar/CreateCar";
import EditCar from "../views/Cars/Car/EditCar/EditCar";
import TruckTrailer from "../views/Cars/Car/Details/TruckTrailer";
import SoloTruck from "../views/Cars/Car/Details/SoloTruck";
import CarSet from "../views/Cars/Car/Details/CarSet";
import {Inquiries} from "../views/InquiriesList/Inquiries";
import Inquiry from "../views/Inquiry/Inquiry";
import MultipleOrders from "../views/MultipleOrders/MultipleOrders";
import Dedicated from "../views/Cooperation/Dedicated/Dedicated";
import Cooperation from "../views/Cooperation/Cooperation";

const dashboardRoutes = () => {
    const allDashboardRoutes =  [
        // {
        //     path: "/invoice-dataset/new",
        //     component: AddInvoicingData,
        //     name: translate("Add new invoicing data"),
        //     regex: new RegExp('^/invoice-dataset/new$'),
        //     restrictions:[CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE],
        //     showBackButton:true
        // },
        // {
        //     path: "/invoice-dataset/clone/:dataSetId",
        //     component: CloneInvoicingData,
        //     name: translate("Duplicate invoicing data"),
        //     regex: new RegExp('^/invoice-dataset/clone/(\\d+)$'),
        //     restrictions:[CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE],
        //     showBackButton:true
        // },
        // {
        //     path: "/invoice-dataset/edit/:dataSetId",
        //     component: EditInvoicingData,
        //     name: translate("Edit invoicing data"),
        //     regex: new RegExp('^/invoice-dataset/edit/(\\d+)$'),
        //     restrictions:[CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE],
        //     showBackButton:true
        // },
        {
            path: "/orders/:orderId",
            component: orderDetails,
            name: translate("Order details"),
            regex: new RegExp('^/orders/(\\d+)$'),
            restrictions:[CARRIER_IS_ACTIVE, USER_IS_ACTIVE],
            showBackButton:true
        },
        {
            path: "/user/new",
            component: CreateUser,
            name: translate("New User"),
            //  regex: new RegExp('^\/users\/(\\d+)$'),
            restrictions:[CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE],
            showBackButton:true
        },
        {
            path: "/user/:userId",
            component: UserPage,
            name: translate("User details"),
            regex: new RegExp('^/users/(\\d+)$'),
            restrictions:[CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE],
            showBackButton:true
        },
        {
            path: "/car/new",
            component: CreateCar,
            name: translate("Add new car to your fleet"),
            restrictions:[CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE],
            showBackButton:true
        },
        {
            path: "/car/edit/:vehicleId",
            component: EditCar,
            name: translate("Edit vehicle data"),
            regex: new RegExp('^\/car\/edit\/(\\d+)$'),
            restrictions:[CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE],
            showBackButton:true
        },
        {
            path: "/trailer/edit/:vehicleId",
            component: EditCar,
            name: translate("Edit trailer data"),
            regex: new RegExp('^\/trailer\/edit\/(\\d+)$'),
            restrictions:[CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE],
            showBackButton:true
        },
        {
            path: "/trailer/:trailerId",
            component: TruckTrailer,
            name: translate("Truck Trailer details"),
            regex: new RegExp('^\/trailer\/(\\d+)$'),
            restrictions:[CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE],
            showBackButton:true
        },
        {
            path: "/solo/:soloTruckId",
            component: SoloTruck,
            name: translate("Solo Truck Details"),
            regex: new RegExp('^/solo/(\\d+)$'),
            restrictions: [CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE],
            showBackButton:true
        },
        {
            path: "/car-set/:carSetId",
            component: CarSet,
            name: translate("Car Set Details"),
            regex: new RegExp('^/car-set/(\\d+)$'),
            restrictions: [CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE],
            showBackButton:true
        },
        {
            path: "/inquiries/pending",
            component: Inquiries,
            name: translate("Pending inquiries"),
            restrictions: [CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE, USER_HAS_INQUIRIES_ACCESS],
            showBackButton:false
        },
        {
            path: "/inquiries/closed",
            component: Inquiries,
            name: translate("Closed inquiries"),
            restrictions: [CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE, USER_HAS_INQUIRIES_ACCESS],
            showBackButton:false
        },
        {
            path: "/notifications",
            component: Notifications,
            name: translate("Notifications"),
            restrictions:[]
        },
        {
            path: "/inquiry/:inquiryId",
            component: Inquiry,
            name: translate("Inquiry details"),
            regex: new RegExp('^/inquiry/(\\d+)$'),
            restrictions:[CARRIER_IS_ACTIVE, USER_IS_MANAGER, USER_IS_ACTIVE, USER_HAS_INQUIRIES_ACCESS],
            showBackButton:true
        },
        {
            path: "/multiple-orders/",
            component: MultipleOrders,
            name: translate("Multiple orders"),
            restrictions:[CARRIER_IS_ACTIVE, USER_IS_ACTIVE],
            showBackButton:false
        },
        {
            path: "/cooperation/invitations/accept",
            name: translate("Cooperation"),
            icon: "nc-icon nc-refresh-69",
            component: Cooperation,
            restrictions:[USER_IS_MANAGER, CARRIER_IS_ACTIVE, USER_IS_ACTIVE, USER_HAS_COOPERATION_ACCESS]
        },
        {redirect: true, path: "/", pathTo: "/dashboard", name: translate("Dashboard")}
    ];
    return loggedUserService.restrictRoutesForUser(allDashboardRoutes);
};
export default dashboardRoutes;
