import userDataService from 'services/userDataService';
import loggedUserService from "services/loggedUserService";
import {translate} from "../../../services/translationService";

const validate = (values) => {
    const errors = {
        address: {
            street: ['', '', '']
        }
    };

    if (!values.email) {
        errors.email = 'Email is required';
    } else if (userDataService.validateEmail(values.email) === null) {
        errors.email = 'Invalid email address!';
    }

    if (!values.name) {
        errors.name = 'First name is required';
    } else if (userDataService.validateName(values.name) === null) {
        errors.name = 'First name cannot contain numbers';
    }

    if (!values.surname) {
        errors.surname = 'Surname is required';
    } else if (userDataService.validateName(values.surname) === null) {
        errors.surname = 'Surname cannot contain numbers';
    }

    if (values.documentNumber) {
        if (userDataService.validateDocumentNumber(values.documentNumber) === null) {
            errors.documentNumber = 'Document must be maximum 100 characters long';
        }
    }

    if (!values.address) {
        errors.address.street = 'Street is required';
        errors.address.city = 'City is required';
        errors.address.postCode = 'Post Code is required';
        errors.address.country = 'Country is required';
    } else {
        if (!values.address.street || !values.address.street[0]) {
            errors.address.street[0] = 'Street is required';
        }
        if (!values.address.city) {
            errors.address.city = 'City is required';
        }

        if (!values.address.postCode) {
            errors.address.postCode = 'Post Code is required';
        }

        if (!values.address.country) {
            errors.address.country = 'Country is required';
        }

        if (values.address.phone) {
            if (userDataService.validatePhoneNumber(values.address.phone) === null) {
                errors.address.phone = 'Invalid phone number';
            }
        }
    }
    return errors;
};

const validationUpdate = (values) => {
    const errors = validate(values);

   /* if (values.password && values.password.length < 4) {
        errors.password = 'New password is too short';
    }*/

    return errors;
};

const userValidation = (values) => {
    const errors = validate(values);
    if (loggedUserService.isManager()) {
        if (!values.roles || !values.roles.length) {
            errors.roles = 'Role is required';
        }
    }
    return errors;
}

const passwordValidation = (values) => {
    const errors = {};
    if (!values.currentPassword) {
        errors.currentPassword = translate('Current password is required');
    } else if (userDataService.validatePassword(values.currentPassword) === null) {
        errors.currentPassword = translate("Password must be between 8-20 characters, include digit and capital letter");
    }
    if (!values.newPassword) {
        errors.newPassword = translate('New password is required');
    } else if (userDataService.validatePassword(values.newPassword) === null) {
        errors.newPassword = translate("Password must be between 8-20 characters, include digit and capital letter");
    }
    return errors
}

const remarkValidation = (values) => {
    const errors = {};
    if (!values.description){
        errors.description = translate("Remark description cannot be empty");
    }
    return errors
}


export { validationUpdate, userValidation, passwordValidation, remarkValidation };
