import {
    FETCH_HELPER_TEXT_REQUEST,
    FETCH_HELPER_TEXT_SUCCESS,
    FETCH_HELPER_TEXT_FAILURE
} from 'redux/actions/dictionary';

const initialState = {
    helperTexts: {},
    isFetching: true,
    error: false,
};

export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_HELPER_TEXT_REQUEST: {
            return Object.assign({}, state, {
                isFetching: true,
                helperTexts:{...state.helperTexts},
                error: false
            });
        }

        case FETCH_HELPER_TEXT_SUCCESS: {
            const newHelperTexts = {...state.helperTexts, [action.data.textId]: action.data.data};

            return Object.assign({}, state, {
                helperTexts: newHelperTexts,
                isFetching: false,
                error: false
            });
        }

        case FETCH_HELPER_TEXT_FAILURE: {
            return Object.assign({}, state, {
                isFetching: false,
                error: true,
                helperTexts:{...state.helperTexts}
            });
        }
        default:
            return state
    }
}
