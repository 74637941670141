import {alertActions} from "redux/actions";
import getErrorText from "../../../services/errorTextService";
import {history} from "../../../helpers";
import {documentsApiService} from "../../../services/documentsApiService";
import {arrayToQueryParam} from "../../../utils/formatters";
import {requiredDocumentTypes} from "./documentTypes.actions";

export const GET_ORDER_DOCS_REQUEST = 'GET_ORDER_DOCS_REQUEST';
export const GET_ORDER_DOCS_SUCCESS = 'GET_ORDER_DOCS_SUCCESS';
export const GET_ORDER_DOCS_FAILURE = 'GET_ORDER_DOCS_FAILURE';

export function getOrderDocuments(orderId) {
    return dispatch => {
        dispatch(request({}));
      const queryParam = arrayToQueryParam(requiredDocumentTypes, 'document_types');
      documentsApiService.get('api/documents' + queryParam + `&id_shipment_carrier_order=${orderId}`)
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(errorMessage => {
                dispatch(failure(getErrorText(errorMessage)));
                dispatch(alertActions.error(getErrorText(errorMessage)));
                history.push('/dashboard');
            });
    };

    function request(data) { return { type: GET_ORDER_DOCS_REQUEST, data } }
    function success(data) { return { type: GET_ORDER_DOCS_SUCCESS, data } }
    function failure(error) { return { type: GET_ORDER_DOCS_FAILURE, error } }
}
