import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {translate} from 'services/translationService';
import {Route, Switch, NavLink} from "react-router-dom";
import PropTypes from 'prop-types';

import InquiriesList, {INQUIRIES_PAGINATOR} from "./InquiriesList";
import {paginationActions} from "../../redux/actions";
import './style.scss'
import NewInquiriesCounter from "../../components/InquiryCounter/NewInquiriesCounter";
import HelperButton from "../../components/Helper/HelperButton";

export const Inquiries = (props) => {

  const {resetPage} = props;

  const tabs = [
    {
      name: translate("Pending"),
      route: '/inquiries/pending',
      title: translate("Pending quoted inquiries"),
      type: 'pending'
    },
    {
      name: translate("New"),
      route: '/inquiries/new',
      title: translate("New inquiries to quote"),
      type: 'new'
    },
    {
      name: translate("Closed"),
      route: '/inquiries/closed',
      title: translate('Closed inquiries with offers'),
      type: 'closed'
    },
  ];

  return (
    <div className="content">
      <div className="nav nav-tabs" style={{position:"relative"}}>
        {tabs.map((tab, index) => (
          <div className="nav item" key={index}>
            <NavLink
              key={index}
              to={tab.route}
              className="nav-link"
              activeClassName="active"
              onClick={resetPage}
            >
              <span>{tab.name}</span>
              {tab.type === 'new' ? <NewInquiriesCounter />: ""}
            </NavLink>
          </div>
        ))}
        <HelperButton helperId={"carrier-inquiries-list"}style={{right:"5px"}}/>
      </div>
      <div className="invoicing__section">
        <Switch>
          {tabs.map((tab, index) => (
            <Route
              key={index}
              path={tab.route}
              render={() => <InquiriesList type={tab.type} title={tab.title }/>}
            />
          ))}
        </Switch>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  resetPage: () => dispatch(paginationActions.pushChangePage(INQUIRIES_PAGINATOR, 1, 1)),
})

Inquiries.propTypes = {
  resetPage: PropTypes.func
}

export default connect(null, mapDispatchToProps)(Inquiries)